import { NgModule } from '@angular/core';
import { CategoriesFacade } from './categories.facade';


@NgModule({
  providers: [
    CategoriesFacade
  ]
})
export class CategoriesModule {
}
