import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RedemptionItem } from '~features/redemptions/redemption';
import { EnrichedCategory, EnrichedRedemption } from '~features/redemptions/redemptions.selectors';
import { fade } from '../../util/animations/fade.animation';
import { VendorInventoryProduct } from '~features/vendor-inventory-items/vendor-inventory-product.model';

@Component({
  selector: 'wic-redemption-list',
  templateUrl: './redemption-list.component.html',
  styleUrls: ['./redemption-list.component.scss'],
  animations: [fade()],
})
export class RedemptionListComponent {
  @Input() loading = false;
  @Input() offsetMonths: number;
  @Input() redemptions: EnrichedRedemption[];
  @Input() currentLanguage: string;
  @Output() selected = new EventEmitter<RedemptionItem & { product?: VendorInventoryProduct }>();
  @Output() loadMore = new EventEmitter();

  trackByRedemption(index: number, redemption: EnrichedRedemption) {
    return redemption.id;
  }

  trackByCategory(index: number, category: EnrichedCategory) {
    return category.categoryId;
  }
}
