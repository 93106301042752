<ion-header>
  <ion-toolbar color="offices">
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false, 'cancel')">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-wrap">{{'offices.detailTitle' | transloco}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="full-map" [ngStyle]="{'background-image': 'url(' + (settings.currentProviderMap$ | async) + ')'}"></div>

  <ion-card *ngIf="!!office" data-cy="detail">
    <ion-card-header translucent mode="ios">
      <ion-card-title>{{office.name}}</ion-card-title>
    </ion-card-header>
    <ion-card-content class="office-details">
      <ion-item>
        <ion-label>
          <h2><strong>
            {{office.address1}}</strong></h2>
          <h2 *ngIf="!!office.address2">{{office.address2}}</h2>
          <h2>{{office.city}}, {{office.state}} {{office.zip}}</h2>
        </ion-label>
        <ion-button
          slot="end"
          color="offices"
          size="default"
          fill="clear"
          class="office-action"
          (click)="offices.getDirections(office)"
        >
          <ion-icon slot="icon-only" icon="navigate"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-item *ngIf="!!office.webAddress">
        <a (click)="offices.viewWebSite(office)">{{office.webAddress}}</a>
        <ion-note slot="end">
          <fa-icon icon="link"></fa-icon>
        </ion-note>
      </ion-item>
      <ion-item *ngIf="!!office.emailAddress">
        <a href="mailto:{{office.emailAddress}}">{{office.emailAddress}}</a>
        <ion-note slot="end">
          <fa-icon icon="envelope"></fa-icon>
        </ion-note>
      </ion-item>
      <ion-item *ngIf="!!office.phoneNumber1">
        <a href="tel:{{office.phoneNumber1}}">{{office.phoneNumber1}}</a>
        <ion-note slot="end">
          <fa-icon icon="phone"></fa-icon>
        </ion-note>
      </ion-item>
      <ion-item *ngIf="!!office.phoneNumber2">
        <a href="tel:{{office.phoneNumber2}}">{{office.phoneNumber2}}</a>
        <ion-note slot="end">
          <fa-icon icon="phone"></fa-icon>
        </ion-note>
      </ion-item>
      <ion-item *ngIf="!!office.locationDetails">
        <p>{{office.locationDetails}}</p>
        <ion-note slot="end">
          <fa-icon icon="info-circle"></fa-icon>
        </ion-note>
      </ion-item>

      <ion-item class="icon-bar" lines="none">
        <fa-icon slot="start" icon="building"></fa-icon>

        <ion-button slot="end"
                    color="offices"
                    size="default"
                    shape="round"
                    fill="clear"
                    class="office-action favorite-button"
                    (click)="offices.toggleFavorite({office: office, favorites: favorites})">
          <fa-icon slot="icon-only" data-cy="favorite-button"
                   [icon]="(office | isFavorite:favorites) ? faHeart : faHeartOpen"
                   [styles]="(office | isFavorite:favorites) ? {color: 'var(--soft-red)'} : {color: 'var(--med-grey)'}"
                   size="lg">
          </fa-icon>
        </ion-button>
      </ion-item>


      <ion-button class="directions-button" expand="block" color="misty-moss" shape="round" (click)="offices.getDirections(office)" data-cy="directions-button">
        <fa-icon slot="start" icon="directions" [fixedWidth]="true" size="2x"></fa-icon>
        <span class="ion-text-uppercase ion-text-wrap">{{'offices.directionsButton' | transloco}}</span>
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
