import { Component, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'wic-explain-permission-modal',
  templateUrl: './explain-permission-modal.component.html',
  styleUrls: ['./explain-permission-modal.component.scss'],
})
export class ExplainPermissionModalComponent {
  @Input() backgroundColor = 'light';
  @Input() headerImage: string;
  @Input() buttonColor = 'tufts-blue';
  @Input() textColor = 'black';
  @Input() message: string;
  @Input() icon: string;
  @Input() popover = false;

  constructor(public modal: ModalController, public popovers: PopoverController) {
  }
}
