import { Component, OnInit } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { RedemptionsFacade } from '~features/redemptions/redemptions.facade';
import { SettingsFacade } from '~features/settings/settings.facade';
import { SubCatConfigFacade } from '~features/subcat-configs/subcat-config.facade';

@Component({
  selector: 'wic-redemption-history',
  templateUrl: './redemption-history.page.html',
  styleUrls: ['./redemption-history.page.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'subcategory-translations' }],
})
export class RedemptionHistoryPage implements OnInit {
  constructor(
    private analytics: AnalyticsFacade,
    public enhancedMode: EnhancedModeFacade,
    public redemptions: RedemptionsFacade,
    public subCatConfig: SubCatConfigFacade,
    public settings: SettingsFacade,
  ) {}

  ngOnInit() {
    this.analytics.pageView('redemptions');
  }
}
