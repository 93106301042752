<form [formGroup]="form" novalidate>
  <ion-list>
<!--    <ion-item lines="none" class="section-label">-->
<!--      <ion-label position="stacked" class="card-number">-->
<!--        {{ 'registration.cardSectionLabel' | transloco }}-->
<!--      </ion-label>-->
<!--    </ion-item>-->
    <ion-list-header class="card-number">{{ 'registration.cardSectionLabel' | transloco }}</ion-list-header>
    <ion-item mode="md" fill="outline" class="form-input">
      <ion-label position="floating">
        {{ 'registration.cardNumberPlaceholder' | transloco: { len: LEN_CARD_DIGITS } }}
      </ion-label>
      <ion-input
        data-cy="registration-card-number"
        name="cardNumber"
        formControlName="cardNumber"
        [maskito]="cardMask"
        [maskitoElement]="maskPredicate"
        inputmode="numeric"
        type="tel"
        [placeholder]="authority?.binCodes?.[0] | slice: 0:4"
        (ionFocus)="focused.emit($event)"
      ></ion-input>
    </ion-item>
    <ion-item mode="md" fill="outline" class="form-input ion-margin-top">
      <ion-label position="floating">
        {{ 'registration.cardNumberConfirmPlaceholder' | transloco: { len: LEN_CARD_DIGITS } }}
      </ion-label>
      <ion-input
        data-cy="registration-card-number-confirm"
        name="cardNumberConfirm"
        formControlName="cardNumberConfirm"
        [maskito]="cardMask"
        [maskitoElement]="maskPredicate"
        inputmode="numeric"
        type="tel"
        [placeholder]="authority?.binCodes?.[0] | slice: 0:4"
        (ionFocus)="focused.emit($event)"
      ></ion-input>
    </ion-item>
    <ion-item lines="none" *ngIf="form.get('cardNumber').invalid || form.get('cardNumberConfirm').invalid">
      <ion-label
        color="danger"
        position="stacked"
        *ngIf="isFieldValid(form, 'cardNumber', 'required') || isFieldValid(form, 'cardNumberConfirm', 'required')"
      >
        {{ 'registration.cardNumberRequiredError' | transloco }}
      </ion-label>
      <ion-label
        color="danger"
        position="stacked"
        *ngIf="
          isFieldValid(form, 'cardNumber', 'exactLength') || isFieldValid(form, 'cardNumberConfirm', 'exactLength')
        "
      >
        {{ 'registration.cardNumberLengthError' | transloco: { len: LEN_CARD_DIGITS } }}
      </ion-label>
      <ion-label color="danger" position="stacked" *ngIf="isFieldValid(form, 'cardNumberConfirm', 'validateEqual')">
        {{ 'registration.cardNumberMatchError' | transloco }}
      </ion-label>
    </ion-item>

    <ng-container *ngIf="authority?.integration === 'wem'">
      <ion-item lines="none" class="section-label">
        <ion-label position="stacked">{{ 'registration.householdSectionLabel' | transloco }}</ion-label>
      </ion-item>
      <ion-item lines="none" class="form-input">
        <ion-input
          name="householdId"
          formControlName="householdId"
          type="tel"
          placeholder="{{
            'registration.householdPlaceholder' | transloco: { min: MIN_HHID_DIGITS, max: MAX_HHID_DIGITS }
          }}"
          (ionFocus)="focused.emit($event)"
        ></ion-input>
      </ion-item>
      <ion-item lines="none" class="form-input">
        <ion-input
          name="householdIdConfirm"
          formControlName="householdIdConfirm"
          type="tel"
          placeholder="{{
            'registration.householdConfirmPlaceholder' | transloco: { min: MIN_HHID_DIGITS, max: MAX_HHID_DIGITS }
          }}"
          (ionFocus)="focused.emit($event)"
        ></ion-input>
      </ion-item>

      <ion-item lines="none">
        <ion-label
          color="danger"
          position="stacked"
          *ngIf="isFieldValid(form, 'householdId', 'required') || isFieldValid(form, 'householdIdConfirm', 'required')"
        >
          {{ 'registration.householdIdRequiredError' | transloco }}
        </ion-label>
        <ion-label
          color="danger"
          position="stacked"
          *ngIf="
            isFieldValid(form, 'householdId', 'minlength') || isFieldValid(form, 'householdIdConfirm', 'minlength')
          "
        >
          {{ 'registration.householdIdLengthError' | transloco: { min: MIN_HHID_DIGITS, max: MAX_HHID_DIGITS } }}
        </ion-label>
        <ion-label color="danger" position="stacked" *ngIf="isFieldValid(form, 'householdIdConfirm', 'validateEqual')">
          {{ 'registration.householdIdsMatchError' | transloco }}
        </ion-label>
      </ion-item>
    </ng-container>
  </ion-list>
</form>
