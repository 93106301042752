import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { ComponentsModule } from '../../components/components.module';

import { RedemptionHistoryPageRoutingModule } from './redemption-history-routing.module';

import { RedemptionHistoryPage } from './redemption-history.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RedemptionHistoryPageRoutingModule,
    ComponentsModule,
    TranslocoModule,
    FontAwesomeModule
  ],
  declarations: [RedemptionHistoryPage]
})
export class RedemptionHistoryPageModule {}
