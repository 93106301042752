import { createSelector } from '@ngrx/store';
import { categoryEntities } from '../categories/categories.state';
import { Category } from '../categories/models';
import { SubCatConfig } from '../subcat-configs/models';
import { allSubCatConfigs, subCatConfigEntities } from '../subcat-configs/subcat-configs.state';
import { SubCategory } from '../subcategories/models';
import { subCategoryEntities } from '../subcategories/subcategories.state';
import { Redemption, RedemptionItem } from './redemption';
import { allRedemptions } from './redemptions.state';
import { VendorInventoryProduct } from '~features/vendor-inventory-items/vendor-inventory-product.model';

export const createUniqueSubCats = <T extends { categoryId: number; subCategoryId?: number }>(subCats: T[]): T[] => {
  // console.log(subCats);
  const map = new Map<string, T>();
  subCats.forEach(subCat => map.set(subCat?.categoryId + '_' + subCat?.subCategoryId, subCat));
  const newSubs = Array.from(map.values());
  // console.log(newSubs);
  return newSubs;
};

export const redemptionsWithSubCats = createSelector(allRedemptions, categoryEntities, (redemptions, cats) =>
  redemptions.map(redemption => ({
    ...redemption,
    categories: createUniqueSubCats(
      redemption.redemptionItems.map(item => cats[+item.categoryId])
    ),
  }))
);

export type EnrichedCategory = Category & { config: SubCatConfig; items: (RedemptionItem & { product?: VendorInventoryProduct })[] };
export type EnrichedRedemption = Redemption & { categories: EnrichedCategory[] };

export const redemptionsWithConfigs = createSelector(
  redemptionsWithSubCats,
  allSubCatConfigs,
  (redemptions, subCatConfigs): EnrichedRedemption[] =>
    redemptions.map(redemption => ({
      ...redemption,
      categories: redemption.categories.map(category => ({
        ...category,
        config: subCatConfigs.find(config => +config.categoryId === category.categoryId),
        items: redemption.redemptionItems.filter(
          item => +item.categoryId === category?.categoryId
        ),
      })),
    }))
);
