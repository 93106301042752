import { Injectable } from '@angular/core';
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { Capacitor } from '@capacitor/core';
import { TranslocoService } from '@ngneat/transloco';
import { DialogsService } from './dialogs.service';

export const targetedFormats = [
  SupportedFormat.UPC_A,
  SupportedFormat.UPC_E,
  SupportedFormat.UPC_EAN_EXTENSION,
  SupportedFormat.EAN_8,
  SupportedFormat.EAN_13,
  SupportedFormat.CODE_39,
  SupportedFormat.CODE_39_MOD_43,
  SupportedFormat.CODE_93,
  SupportedFormat.CODE_128,
  SupportedFormat.CODABAR,
  SupportedFormat.ITF,
  SupportedFormat.ITF_14,

  // Disable 2D barcodes
  //
  // SupportedFormat.AZTEC,
  // SupportedFormat.DATA_MATRIX,
  // SupportedFormat.MAXICODE,
  // SupportedFormat.PDF_417,
  // SupportedFormat.QR_CODE,
  // SupportedFormat.RSS_14,
  // SupportedFormat.RSS_EXPANDED,
];

@Injectable()
export class BarcodeScannerService {
  isBackgroundVisible = true;

  async prepare() {
    if (Capacitor.isNativePlatform()) {
      await BarcodeScanner.prepare();
      await BarcodeScanner.hideBackground();
      this.isBackgroundVisible = false;
    }
  }

  async startScan() {
    if (Capacitor.isNativePlatform()) {
      if (this.isBackgroundVisible) {
        await BarcodeScanner.hideBackground();
      }
      if (await this.hasPermission()) {
        const result = await BarcodeScanner.startScan({ targetedFormats });
        if (result.hasContent) {
          return result.content;
        }
        return undefined;
      } else {
        throw new Error('Barcode scanner does not have permission.')
      }
    } else {
      return '4011';
    }
  }

  async stopScan() {
    if (Capacitor.isNativePlatform()) {
      if (!this.isBackgroundVisible) {
        await BarcodeScanner.showBackground();
      }
      await BarcodeScanner.stopScan();
    }
  }

  openSettings() {
    return BarcodeScanner.openAppSettings();
  }

  async hasPermission() {
    if (!Capacitor.isNativePlatform()) {
      return false;
    }
    const status = await BarcodeScanner.checkPermission();
    return status.granted ? true : status.denied ? false : null;
  }

  async askPermission() {
    if (!Capacitor.isNativePlatform()) {
      return true;
    }
    const status = await BarcodeScanner.checkPermission({ force: true });
    /*
     * If force is set to `true`, the user will be prompted for the permission.
     * The prompt will only show if the permission was not yet granted and also not denied completely yet.
     * For more information see: https://github.com/capacitor-community/barcode-scanner#permissions
     */
    return status.granted ? true : status.denied ? false : null;
  }
}
