import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { CalculatorEntry } from '~features/calculators/models';

@Component({
  selector: 'wic-calculator-entry-list',
  templateUrl: './calculator-entry-list.component.html',
  styleUrls: ['./calculator-entry-list.component.scss', '../../features/calculators/calculators.scss'],
})
export class CalculatorEntryListComponent {
  @Input() uom: TemplateRef<any>;

  @Input() entries: CalculatorEntry[];
  @Input() remaining: number;
  @Input() showList = true;
  @Input() deleteMode = false;
  @Input() mode: 'edit' | 'quantity' = 'quantity';

  @Output() toggled = new EventEmitter<CalculatorEntry>();
  @Output() incremented = new EventEmitter<CalculatorEntry>();
  @Output() decremented = new EventEmitter<CalculatorEntry>();
  @Output() edited = new EventEmitter<CalculatorEntry>();
  @Output() removed = new EventEmitter<CalculatorEntry>();
}
