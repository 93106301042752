<ion-grid>
  <ion-row>
    <ion-col size="4">
      <wic-authority-logo
          [authority]="provider">
      </wic-authority-logo>
    </ion-col>
    <ion-col>
      <div class="instructions">
      <h4>{{instructions}}</h4>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
