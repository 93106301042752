import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { currentProviderId } from '~features/registration/registration.selectors';
import {
  vendorChangedForShoppingList,
  viewShoppingList,
} from '~features/shopping-lists/shopping-lists.actions';
import { selectDay } from './vendor-time-slots.actions';
import {
  currentVendorTimeSlot,
  deselectVendorTimeSlot,
  loadAllVendorTimeSlots,
} from './vendor-time-slots.state';

@Injectable()
export class VendorTimeSlotsEffects {
  constructor(private readonly actions$: Actions, private readonly store: Store) {}

  loadChangedVendorTimeSlots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorChangedForShoppingList),
      withLatestFrom(this.store.select(currentProviderId)),
      map(([{ vendor }, authorityId]) =>
        loadAllVendorTimeSlots({
          criteria: {
            parents: {
              authorities: authorityId,
              vendors: vendor.entryId,
            },
          },
        })
      )
    )
  );

  loadVendorTimeSlots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewShoppingList),
      filter(({ shoppingList }) => !!shoppingList.vendorId && shoppingList.isMobileOrder),
      withLatestFrom(this.store.select(currentProviderId)),
      map(([{ shoppingList }, authorityId]) =>
        loadAllVendorTimeSlots({
          criteria: {
            parents: {
              authorities: authorityId,
              vendors: shoppingList.vendorId,
            },
          },
        })
      )
    )
  );

  deselectTimeSlotOnDayChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectDay),
      withLatestFrom(this.store.select(currentVendorTimeSlot)),
      filter(([, timeSlot]) => !!timeSlot),
      map(() => deselectVendorTimeSlot())
    )
  );
}
