import { Injectable } from '@angular/core';
import { VendorInventoryItemsFacadeBase } from './vendor-inventory-items.state';
import { Store } from '@ngrx/store';
import { VendorInventoryItem } from './vendor-inventory-item.entity';
import {
  productsAvailableBasedOnVendor,
  productsAvailableBasedOnVendorCount,
  productsInVendorsInventory,
} from './vendor-inventory-items.selectors';

@Injectable()
export class VendorInventoryItemsFacade extends VendorInventoryItemsFacadeBase {
  productsInVendorsInventory$ = this.store.select(productsInVendorsInventory);
  productsAvailableBasedOnVendor$ = this.store.select(productsAvailableBasedOnVendor);
  productsAvailableBasedOnVendorCount$ = this.store.select(productsAvailableBasedOnVendorCount);

  constructor(private store: Store) {
    super(VendorInventoryItem, store);
  }
}
