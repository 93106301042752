import { Authority } from '../authorities/models';
import { Card } from '../registration/models';
import { HomeButton, MenuButton } from './models';

export const buildStandardButtons = (authority: Authority, cards: Card[]): HomeButton[] => !authority ? [] : [
  {
    name: 'scan',
    show: authority.showScan && authority.allowScan,
    imgSrc: 'assets/images/menu/main_scan_product_barcode.png',
    label: 'buttons.scanBarcode',
    linkUrl: 'wic$://scan-barcode',
    order: -999
  }, {
    name: 'upc',
    show: authority.showScan && authority.allowScan,
    imgSrc: 'assets/images/menu/main_key_product_barcode.png',
    label: 'buttons.searchUPC',
    linkUrl: 'wic://search-upc',
    order: -998
  }, {
    name: 'benefits',
    show: authority.isProvider,
    imgSrc: 'assets/images/menu/main_view_benefits.png',
    label: 'buttons.myBenefits',
    linkUrl: 'wic://benefits',
    order: -997
  }, {
    name: 'receipts',
    show: !authority.isProvider && authority.allowCaptureBenefits,
    imgSrc: 'assets/images/menu/main_view_benefits.png',
    label: 'buttons.captureBenefits',
    linkUrl: 'wic://receipts',
    order: -997
  }, {
    name: 'redemptions',
    show: authority.enableRedemptionHistory,
    imgSrc: 'assets/images/menu/past_purchases.png',
    label: 'buttons.redemptionHistory',
    linkUrl: 'wic://redemption-history',
    order: -996
  }, {
    name: 'cardQRCode',
    show: authority.enableCardQRCode,
    imgSrc: 'assets/images/menu/main_scan_kiosk_barcode.png',
    label: 'buttons.cardQRCode',
    linkUrl: 'wic://qr-code',
    order: -995
  }, {
    name: 'shoppingLists',
    show: authority.enableShoppingList,
    imgSrc: 'assets/images/menu/main_manage_vouchers.png',
    label: 'buttons.shoppingLists',
    linkUrl: 'wic://shopping-lists',
    order: -994
  }, {
    name: 'cantbuythis',
    show: authority.enableCantBuyThis,
    imgSrc: 'assets/images/menu/main_cant_buy_this.png',
    label: 'buttons.cantBuyThis',
    linkUrl: 'wic://cant-buy-this',
    order: 994
  }, {
    name: 'appointments',
    show: authority.hasApptReminders,
    imgSrc: 'assets/images/menu/main_my_appointments.png',
    label: 'buttons.myAppointments',
    linkUrl: 'wic://appointments',
    order: 995
  }, {
    name: 'vendors',
    show: authority.showVendors,
    imgSrc: 'assets/images/locations/WIC_VendorLocations.svg',
    label: 'buttons.wicVendors',
    linkUrl: 'wic://vendors',
    order: 996
  }, {
    name: 'offices',
    show: authority.showOffices && authority.hasLocations,
    imgIdx: '',
    imgSrc: 'assets/images/locations/WIC_OfficeLocations.svg',
    label: 'buttons.wicOffices',
    linkUrl: 'wic://offices',
    order: 997
  }, {
    name: 'cards',
    show: authority.isProvider && !!cards && cards.length > 1,
    imgSrc: 'assets/images/menu/icon_card.png',
    label: 'buttons.manageCards',
    linkUrl: 'wic://manage-cards',
    order: 998
  }, {
    name: 'forceerror',
    show: null,
    imgSrc: 'assets/images/menu/error-5-xxl.png',
    label: 'buttons.forceError',
    linkUrl: 'wic$://force-error',
    order: 999
  },
  {
    name: 'launchwicsmart',
    show: null,
    imgSrc: '',
    label: 'buttons.launchWicsmart',
    linkUrl: 'wic$://launch-wicsmart',
    order: 1000
  },
  {
    name: 'enhancedIssues',
    show: authority.enableEnhancedIssues,
    imgSrc: 'assets/images/menu/main_question_mark_circle.png',
    label: 'Get Help',
    linkUrl: 'wic://enhanced-issues',
    order: 1001
  }
];

// add new endpoint
export const convertStandardToHomeButton = (
  gateway,
  button: MenuButton,
  customButtons: MenuButton[]
): HomeButton => ({
  name: 'dynamic',
  show: button.isActive, // TODO: Factor in activated/deactivated dates?
  imgIdx: `i${customButtons.indexOf(button)}`,
  imgSrc: `${gateway.baseUrl}/get-button-image/${button.linkId}?subscription-key=${gateway.subscriptionKey}`,
  label: button.text,
  linkUrl: (button.url || '').trim(),
  order: button.order,
});

export const mergeMenuButtons = (
  gateway,
  standardButtons: HomeButton[],
  customButtons: MenuButton[]
): HomeButton[] => [
  ...standardButtons.map((button) => ({
    ...button,
    imgSrc:
      button.imgSrc ||
      `${gateway.baseUrl}/get-button-image/${button.linkId}?subscription-key=${gateway.subscriptionKey}`,
  })),
  ...customButtons.map((button) =>
    convertStandardToHomeButton(gateway, button, customButtons)
  ),
];

const BUTTON_NAME_FILTERS = ['I couldn\'t buy this!'];

export const sortAndFilterMenu = (buttons: HomeButton[]): HomeButton[] => buttons
  .filter(button => button.show && !BUTTON_NAME_FILTERS.includes(button.label))
  .sort((a, b) => a.order - b.order);

export const buildMenuGrid = (buttons: HomeButton[]): HomeButton[][] => buttons
  .reduce((grid, button, index) => {
    const row = Math.floor(index / 3);
    if (!(index % 3)) {
      grid[row] = [];
    }
    grid[row] = [
      ...grid[row],
      button
    ];
    return grid;
  }, []);
