import { SoftError } from './../error/error.state';
import { launchWicSmartApp, wicSmartLaunched, wicSmartLaunchError } from './ionic.actions';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { LogService } from '~core/services/log.service';
import { NetworkStatusService } from '~core/services/network-status.service';
import { driveToAppointment } from '~features/appointments/appointments.state';
import { driveToOffice } from '~features/offices/offices.state';
import { driveToVendor } from '~features/vendors/vendors.state';

import { AppFacade } from '../app/app.facade';
import { appInitialized, cloudSettingsError, initializationCompleted } from '../app/app.actions';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { driveToAddress, driveToLocation, viewWebSite, openExternalBrowser } from '~features/ionic/ionic.actions';
import { ThemeableBrowserService } from '~core/services/themeable-browser.service';
import { from, of } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';
import { Store } from '@ngrx/store';
import { currentCard } from '~features/registration/registration.selectors';
import { Card } from '~features/registration/models';
import { AppLauncherService } from '~core/services/app-launcher.service';
import { MapNavigatorService } from '~core/services/map-navigator.service';

const TAGS = ['Effects', 'Ionic'];

export const replaceCardNumberLinkVariable = (url: string, card?: Card) =>
  url.includes('$cardNumber') ? url.replace('$cardNumber', card?.cardNumber ?? '') : url;

export class WICSmartError extends Error {
  constructor(public override message: string, originalError?: Error) {
    super(message);
  }
}

@Injectable()
export class IonicEffects {
  constructor(
    private actions$: Actions,
    private log: LogService,
    private appFacade: AppFacade,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    private platform: Platform,
    private appLauncher: AppLauncherService,
    private themableBrowser: ThemeableBrowserService,
    private launchNavigator: LaunchNavigator,
    private mapNavigator: MapNavigatorService,
    private networkStatus: NetworkStatusService,
    private store: Store
  ) {
  }

  watchNetwork$ = createEffect(
    () => this.actions$.pipe(
      ofType(appInitialized),
      tap(() => this.networkStatus.initialize())
    ),
    {dispatch: false}
  );

  finalizeInitialization$ = createEffect(
    () => this.actions$.pipe(
      ofType(initializationCompleted, cloudSettingsError), // TODO: Add more initialization completion actions here
      tap(() => this.log.trace(TAGS, 'Checking to see if app is initialized...')),
      switchMap(() => this.appFacade.isInitialized$),
      filter(isInitialized => isInitialized),

      // tap(() => this.log.trace(TAGS, 'Setting device status bar style to default (black text):')),
      // tap(() => this.platform.is('android') ? this.statusBar.styleLightContent() : this.statusBar.styleDefault()),
      // tap(() => this.log.trace(TAGS, 'Device status bar style set to default.')),

      tap(() => this.log.trace(TAGS, 'Hiding splash screen...')),
      tap(() => SplashScreen.hide()),
      tap(() => this.log.trace(TAGS, 'Splash screen hidden.'))
    ),
    {dispatch: false}
  );

  showDrivingDirectionsToLocation$ = createEffect(
    () => this.actions$.pipe(
      ofType(driveToLocation, driveToVendor, driveToOffice),
      debounceTime(200),
      tap(({latitude, longitude}) => this.log.trace(TAGS, `Getting driving directions to lat: ${latitude}, long: ${longitude}`)),
      switchMap(({latitude, longitude}) => this.launchNavigator.navigate([latitude, longitude])),
      tap({
        next: (res) => this.log.info(TAGS, 'Launched navigator'),
        error: (err) => this.log.error(TAGS, 'Error launching navigator', err),
      })
    ),
    {dispatch: false}
  );

  showDrivingDirectionsToAddress$ = createEffect(
    () => this.actions$.pipe(
      ofType(driveToAddress, driveToAppointment),
      debounceTime(200),
      tap(({address}) => this.log.trace(TAGS, `Getting driving directions to address: ${address}`)),
      switchMap(({address}) => this.launchNavigator.navigate(address)),
      tap({
        next: (res) => this.log.info(TAGS, 'Launched navigator'),
        error: (err) => this.log.error(TAGS, 'Error launching navigator', err),
      })
    ),
    {dispatch: false}
  );

  viewWebSite$ = createEffect(
    () => this.actions$.pipe(
      ofType(viewWebSite),
      tap(({webAddress}) => this.log.trace(TAGS, `User is viewing web address ${webAddress}`)),
      map(({webAddress}) => webAddress.replace(/^(https?:\/\/)?(.*)/ig, 'https://$2')),
      withLatestFrom(this.store.select(currentCard)),
      map(([url, card]) => replaceCardNumberLinkVariable(url, card)),
      tap(webAddress => this.log.trace(TAGS, `Browsing to ${webAddress}`)),
      switchMap(webAddress => this.themableBrowser.openBrowser(webAddress))
    ),
    {dispatch: false}
  );

  openExternalBrowser$ = createEffect(
    () => this.actions$.pipe(
      ofType(openExternalBrowser),
      tap(({url}) => this.log.trace(TAGS, `Request to browser to ${url}`)),
      withLatestFrom(this.store.select(currentCard)),
      map(([{url}, card]) => replaceCardNumberLinkVariable(url, card)),
      tap(url => this.log.trace(TAGS, `Browsing to ${url}`)),
      switchMap(url => this.themableBrowser.openBrowser(url))
    ),
    {dispatch: false}
  );

  launchWicSmartApp$ = createEffect(
    () => this.actions$.pipe(
      ofType(launchWicSmartApp),
      map(() => this.platform.is('ios')),
      tap(isIOS => isIOS
        ? this.log.trace(TAGS, `This is iOS`)
        : this.log.trace(TAGS, `This is not iOS`)
      ),
      map(isIOS => isIOS
        ? 'jpmawicsmart://'
        : 'com.jpma.wicsmart'
      ),
      tap(uri => this.log.trace(TAGS, `Configured AppLauncher uri is:`, uri)),
      switchMap(uri =>
        from(this.appLauncher.canLaunch(uri)).pipe(
          catchError(() => of(false)),
          map(canLaunch => canLaunch === null ? true : canLaunch),
          tap(canLaunch => canLaunch
            ? this.log.trace(TAGS, `WICSmart can launch`, canLaunch)
            : this.log.trace(TAGS, 'WICSmart unable to launch', canLaunch)
          ),
          switchMap(canLaunch => canLaunch
            ? from(this.appLauncher.launch(uri)).pipe(
              tap(result => this.log.trace(TAGS, `WICSmart has been launched.`, result)),
              map(() => wicSmartLaunched()),
              catchError(() => of(wicSmartLaunchError({error: new WICSmartError('Cannot launch WICSmart application.')})))
            )
            : of(wicSmartLaunchError({error: new WICSmartError('Cannot launch WICSmart application.')}))
          )
        )
      ),
      catchError(error => of(wicSmartLaunchError({error})).pipe(
        tap(({error: err}) => this.log.error(TAGS, `WICSmart application error launching`, err))
      ))
    )
  );

  wicSmartAppUnavailable$ = createEffect(
    () => this.actions$.pipe(
      ofType(wicSmartLaunchError),
      filter(({error}) => error instanceof WICSmartError),
      map(() => this.platform.is('ios')),
      tap(isIOS => isIOS
        ? this.log.trace(TAGS, `This is iOS`)
        : this.log.trace(TAGS, `This is not iOS`)
      ),
      map(isIOS => ({ uri: isIOS ? 'itms-apps://apps.apple.com/us/app/wicsmart/id904005619' : 'market://details?id=com.jpma.wicsmart'})),
      switchMap(({uri}) => from(this.appLauncher.launch(uri)).pipe(
        map(() => wicSmartLaunched()),
      )),
      // eslint-disable-next-line max-len
      catchError(error => of(wicSmartLaunchError({error: new SoftError('Failed to launch WICSmart', 'WICSmart Launch Failed!', 'The WICSmart app failed to launch because the application is not installed on your device. Please install WICSmart from your device app store or marketplace.', error)})).pipe(
        tap(({error: err}) => this.log.error(TAGS, `Error launching app store`, err))
      ))
    ),
  );
}
