import { Pipe, PipeTransform } from '@angular/core';
import { AlertOptions } from '~features/settings/models';

@Pipe({name: 'alertTime'})
export class AlertTimePipe implements PipeTransform {
  transform(alert: AlertOptions, offset: boolean = false): any {
    const timeOffset = offset ? new Date().getTimezoneOffset() / 60 : 0;
    return alert ? new Date(2000, 0, 1, alert.hour - timeOffset, alert.minute, 0, 0).toISOString() : '';
  }
}
