import { Injectable } from '@angular/core';
import { VendorTimeSlotFacadeBase, deselectVendorTimeSlot } from './vendor-time-slots.state';
import { Store } from '@ngrx/store';
import { VendorTimeSlot } from './vendor-time-slot.entity';
import { selectDay } from './vendor-time-slots.actions';
import { dayOptionsForTimeSlots, selectedDay, trimmedTimeSlotsForSelectedDay } from './vendor-time-slots.selectors';
import { TimeSlotDayOption } from '~features/vendor-time-slots/time-slot-day-option.model';

@Injectable()
export class VendorTimeSlotsFacade extends VendorTimeSlotFacadeBase {
  selectedDay$ = this.store.select(selectedDay);
  dayOptionsForTimeSlots$ = this.store.select(dayOptionsForTimeSlots);
  timeSlotsForSelectedDay$ = this.store.select(trimmedTimeSlotsForSelectedDay);


  constructor(private store: Store) {
    super(VendorTimeSlot, store)
  }

  selectDay(day: TimeSlotDayOption) {
    this.store.dispatch(selectDay({ day }));
  }

  deselectVendorTimeSlot() {
    this.store.dispatch(deselectVendorTimeSlot());
  }
}
