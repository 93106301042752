import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunkArray',
})
export class ChunkArrayPipe implements PipeTransform {
  transform<T>(value: T[], size: number): T[][] {
    return value.reduce((acc, cur, i) => {
      const index = Math.floor(i / size);
      const chunk = acc[index] || (acc[index] = []);
      chunk.push(cur);
      return acc;
    }, []);
  }
}
