import { Pipe, PipeTransform } from '@angular/core';
import { LoggingLevel } from '~core/services/log.service';

@Pipe({name: 'levelToColor'})
export class LevelToColorPipe implements PipeTransform {
  transform(level: LoggingLevel | string): any {
    level = typeof level === 'string' ? LoggingLevel[level] : level;
    switch (level) {
      case LoggingLevel.error: return 'danger';
      case LoggingLevel.warn: return 'warning';
      case LoggingLevel.info: return 'secondary';
      case LoggingLevel.debug: return 'medium';
      case LoggingLevel.trace: return 'light';
      default: return 'medium';
    }
  }
}
