import { Action, createAction, createReducer, createSelector, on, props } from '@ngrx/store';
import cloneDeep from 'clone-deep';
import mergeDeep from 'deepmerge';

import { environment } from '~env';

import { State } from '../state';

import { AdminSettings, AlertOptions, LanguageCode, UserSettings } from './models';

export const showSelectInitialLanguage = createAction('[Settings] Show Select Initial Language');
export const initialLanguageSelected = createAction('[Settings] Initial Language Selected');

export const adminSettingsChanged = createAction(
  '[Settings] Admin Settings: Changed',
  props<{ settings: AdminSettings }>()
);

export const userSettingsChanged = createAction(
  '[Settings] User Settings: Changed',
  props<{ settings: UserSettings }>()
);

export const userSettingsEdited = createAction(
  '[Settings] User Settings: Edited',
  props<{ settings: UserSettings }>()
);

export const startEditingUserSettings = createAction(
  '[Settings] User Settings: Edit'
);

export const selectLanguage = createAction(
  '[Settings] User Settings: Select Edited Language',
  props<{ lang: string }>()
);

export const editBenefitOptions = createAction(
  '[Settings] User Settings: Edit Benefit Options',
  props<{ options: AlertOptions }>()
);

export const editAppointmentOptions = createAction(
  '[Settings] User Settings: Edit Alert Options',
  props<{ options: AlertOptions }>()
);

export const saveEditedUserSettings = createAction(
  '[Settings] User Settings: Save'
);

export const languageReset = createAction(
  '[Settings] User Settings: Refresh Language'
);

export const languageResetComplete = createAction(
  '[Settings] Reset: Complete'
);

export const settingsReset = createAction(
  '[Settings] Reset'
);

export const settingsResetComplete = createAction(
  '[Settings] Reset: Complete'
);

export const userSettingsMigrated = createAction(
  '[Settings] Migrated from legacy storage',
  props<{ settings: UserSettings }>()
);

export interface ISettingsState {
  admin?: AdminSettings;
  user?: UserSettings;
  editedUser?: UserSettings;
  migratedSettings?: UserSettings;
}

export const initialSettingsState: ISettingsState = {
  admin: {
    logging: {
      enableLocal: environment.logging.enableLocal,
      enableShipping: false
    },
    hosts: {
      api: environment.hosts.api,
      cdn: environment.hosts.cdn,
      azureGateway: {
        baseUrl: environment.hosts.azureGateway.baseUrl,
        subscriptionKey: environment.hosts.azureGateway.subscriptionKey
      }
    },
    analytics: {
      key: environment.analytics.gaKey,
      appId: environment.analytics.gaAppId
    }
  },
  user: {
    lang: LanguageCode.English,
    benefits: {
      allowAlerts: true,
      daysBefore: 5,
      hour: 9,
      minute: 0
    },
    appointments: {
      allowAlerts: true,
      daysBefore: 7,
      hour: 18,
      minute: 0
    },
    preload: {
      apl: {
        enable: true,
        categories: true,
        products: false
      }
    }
  }
};

export const migrateUserSettings = (state, {settings}) => ({
  ...state,
  migratedSettings: settings
});

export const updateAdminSettings = (state, {settings}) => ({
  ...state,
  admin: settings
    ? mergeDeep(state.admin, settings)
    : state.admin
});

export const updateUserSettings = (state, {settings}) => ({
  ...state,
  user: settings ? {...state.admin, ...settings} : state.user
});

export const editUserSettings = (state, {settings}) => ({
  ...state,
  editedUser: settings ? {...state.editedUser, ...settings} : state.editedUser
});

export const startSettingsEdit = (state) => ({
  ...state,
  editedUser: cloneDeep(state.user)
});

export const resetSettings = () => ({
  ...initialSettingsState
});

const reducer = createReducer(
  initialSettingsState,
  on(userSettingsMigrated, migrateUserSettings),
  on(adminSettingsChanged, updateAdminSettings),
  on(userSettingsChanged, updateUserSettings),
  on(userSettingsEdited, editUserSettings),
  on(startEditingUserSettings, showSelectInitialLanguage, startSettingsEdit),
  on(settingsReset, resetSettings)
);

export function settingsReducer(state = initialSettingsState, action: Action): ISettingsState {
  return reducer(state, action);
}

const getSettingsState = (rootState: State) => rootState.settings;

const mapToAllSettings = (state: ISettingsState) => state;

const mapToAdminSettings = (state: ISettingsState) => state.admin;
const mapToLoggingSettings = (settings: AdminSettings) => settings.logging;
const mapToHostsSettings = (settings: AdminSettings) => settings.hosts;
const mapToAnalyticsSettings = (settings: AdminSettings) => settings.analytics;
const mapToAPIHost = (settings: AdminSettings) => settings.hosts.api;
const mapToCDNHost = (settings: AdminSettings) => settings.hosts.cdn;
const mapToAzureGateway = (settings: AdminSettings) => settings.hosts.azureGateway;
const mapToProductCDN = (host: string) => `${host}/products/`;
const mapToMapsCDN = (host: string) => `${host}/maps/`;
const mapToAnalyticsAppId = (settings: AdminSettings) => settings.analytics.appId;

const mapToUserSettings = (state: ISettingsState): UserSettings => state.user;
const mapToEditedUserSettings = (state: ISettingsState): UserSettings => state.editedUser;
const mapToUserLanguage = (settings: UserSettings) => !!settings ? (settings.lang || 'en').toString() : null;
const mapToHasSelectedInitialLanguage = (settings: UserSettings) => !!settings?.hasSelectedInitialLanguage
const mapToBenefitsOptions = (settings: UserSettings) => !!settings ? settings.benefits : null;
const mapToAppointmentOptions = (settings: UserSettings) => !!settings ? settings.appointments : null;

const mapToMigrated = (state: ISettingsState): UserSettings =>
  state.migratedSettings;

export const allSettings = createSelector(getSettingsState, mapToAllSettings);

export const adminSettings = createSelector(getSettingsState, mapToAdminSettings);
export const loggingSettings = createSelector(adminSettings, mapToLoggingSettings);
export const hostsSettings = createSelector(adminSettings, mapToHostsSettings);
export const analyticsSettings = createSelector(adminSettings, mapToAnalyticsSettings);
export const apiHost = createSelector(adminSettings, mapToAPIHost);
export const cdnHost = createSelector(adminSettings, mapToCDNHost);
export const azureGateway = createSelector(adminSettings, mapToAzureGateway);
export const analyticsAppId = createSelector(adminSettings, mapToAnalyticsAppId);
export const productCDN = createSelector(cdnHost, mapToProductCDN);
export const mapsCDN = createSelector(cdnHost, mapToMapsCDN);

export const userSettings = createSelector(getSettingsState, mapToUserSettings);
export const editedUserSettings = createSelector(getSettingsState, mapToEditedUserSettings);
export const currentLanguage = createSelector(userSettings, mapToUserLanguage);
export const hasSelectedInitialLanguage = createSelector(userSettings, mapToHasSelectedInitialLanguage);
export const selectedLanguage = createSelector(editedUserSettings, mapToUserLanguage);
export const benefitOptions = createSelector(userSettings, mapToBenefitsOptions);
export const editedBenefitOptions = createSelector(editedUserSettings, mapToBenefitsOptions);
export const appointmentOptions = createSelector(userSettings, mapToAppointmentOptions);
export const editedAppointmentOptions = createSelector(editedUserSettings, mapToAppointmentOptions);

export const migratedUserSettings = createSelector(getSettingsState, mapToMigrated);
