import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NavController, Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, concat, defer, from, iif, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { currentProvider } from '~features/registration/registration.selectors';
import { appInitialized, appResumed } from '../app/app.actions';
import { ProductsService } from '../products/products.service';
import { State } from '../state';
import { allSubCategories } from '../subcategories/subcategories.state';
import {
  aplDatabaseInitialized,
  applySubCategoryProductsUpdates,
  isAPLDbInitialized,
  populateSubCategoryProducts,
  ProductLoadMode,
  resetProductCollection,
  resetProductCollectionComplete,
  resetTrackingCollection,
  resetTrackingCollectionComplete,
  retrieveSubCategoryProducts,
  retrieveSubCategoryProductsWithLoadDate,
  subCategoryProductsLoaded,
  subCategoryProductsRetrievalFailed,
  subCategoryProductsRetrieved,
} from './product.state';

const TAGS = ['Effects', 'Products'];

export const createSubCatKey = ({ categoryId, subCategoryId }: { categoryId: number; subCategoryId: number }) =>
  categoryId * 1000 + subCategoryId + '';

export const createSubCatFromKey = (key: string): { categoryId: number; subCategoryId: number } => ({
  // eslint-disable-next-line no-bitwise
  categoryId: ~~(parseInt(key, 10) / 1000),
  subCategoryId: parseInt(key, 10) % 1000,
});

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private log: LogService,
    private products: ProductsService,
    private nav: NavController,
    private platform: Platform
  ) {}

  initializeSqliteDatabase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialized),
      switchMap(() => this.products.initializeCollection()),
      tap(r => this.log.debug(TAGS, 'Table Info', r)),
      map(() => aplDatabaseInitialized())
    )
  );

  resetProductCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetProductCollection),
      tap(() => this.log.warn(TAGS, 'Resetting local products collection!')),
      switchMap(() => this.products.clearProducts()),
      map(() => resetProductCollectionComplete()),
      tap(
        () => this.log.warn(TAGS, 'Local products collection has been reset!'),
        err => this.log.error(TAGS, 'Error removing local products collection!', err)
      )
    )
  );

  resetTrackingCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetTrackingCollection),
      tap(() => this.log.warn(TAGS, 'Resetting local products load tracking collection!')),
      switchMap(() => this.products.clearProductTracking()),
      map(() => resetTrackingCollectionComplete()),
      tap(
        () => this.log.warn(TAGS, 'Local products load tracking collection has been removed!'),
        err => this.log.error(TAGS, 'Error removing local load tracking collection!', err)
      )
    )
  );

  determineRemainingSubCats$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(aplDatabaseInitialized)),
      this.store.select(allSubCategories).pipe(filter(subCats => !!subCats.length)),
    ]).pipe(
      take(1),
      tap(() => this.log.trace(TAGS, 'Determining Loaded Products')),
      switchMap(([, allSubCats]) =>
        from(this.products.getAllProductUpdates()).pipe(
          tap(tracking => this.log.debug(TAGS, 'Loaded Products determined', tracking)),
          map(productUpdates => productUpdates.map(update => update.id)),
          map(trackingSubCats => ({
            nonCachedSubCats: allSubCats.filter(subCat => !trackingSubCats.includes(createSubCatKey(subCat))),
            cachedSubCats: trackingSubCats.map(createSubCatFromKey),
          })),
          tap(cache => this.log.debug(TAGS, 'Loaded Products determined', cache))
        )
      ),
      map(({ cachedSubCats, nonCachedSubCats }) => [
        ...nonCachedSubCats.map(({ categoryId, subCategoryId }) =>
          retrieveSubCategoryProducts({
            categoryId,
            subCategoryId,
            mode: ProductLoadMode.Full,
          })
        ),
        ...cachedSubCats.map(({ categoryId, subCategoryId }) =>
          retrieveSubCategoryProducts({
            categoryId,
            subCategoryId,
            mode: ProductLoadMode.Differential,
          })
        ),
      ]),
      switchMap(actions => concat(...actions.map(action => of(action).pipe(delay(1000)))))
    )
  );

  retrieveSubCategoryProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retrieveSubCategoryProducts),
      tap(({ categoryId, subCategoryId }) =>
        this.log.trace(TAGS, `Attempting to check for updated products for ${categoryId}:${subCategoryId}...`)
      ),
      // Commented out now that cat 19 is being loaded like other products. TODO: Remove comment after testing
      // filter(({ categoryId }) => categoryId !== 19 && categoryId !== 97), // Exclude category 19, cash voucher!!
      withLatestFrom(this.store.select(currentProvider)),
      tap(([{ categoryId, subCategoryId }]) =>
        this.log.trace(TAGS, `Checking for updated products for ${categoryId}:${subCategoryId}...`)
      ),
      concatMap(([{ categoryId, subCategoryId, mode }, authority]) =>
        from(this.products.getProductUpdate(categoryId, subCategoryId)).pipe(
          tap(doc => this.log.debug(TAGS, `Retrieved tracking info for ${categoryId}:${subCategoryId}...`, doc)),
          take(1),
          map(doc =>
            retrieveSubCategoryProductsWithLoadDate({
              categoryId,
              subCategoryId,
              mode: !!doc ? mode : ProductLoadMode.Full, // Override mode to full if no last loaded doc
              authority,
              lastLoadDate: !!doc ? doc.lastLoadDate : null,
            })
          ),
          tap(action => this.log.debug(TAGS, 'retrieveSubCategoryProductsWithLoadDate', action)),
          tap(({ lastLoadDate }) =>
            this.log.trace(TAGS, `Last load date for ${categoryId}:${subCategoryId} is '${lastLoadDate}'.`)
          ),
          catchError(err => of(null)),
          filter(action => !!action)
        )
      )
    )
  );

  retrieveSubCategoryProductsWithLoadDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(retrieveSubCategoryProductsWithLoadDate),
      tap(({ categoryId, subCategoryId, mode }) =>
        this.log.debug(
          TAGS,
          `Retrieving ${
            mode === ProductLoadMode.Full ? 'all' : 'changed'
          } products for ${categoryId}:${subCategoryId} from server...`
        )
      ),
      concatMap(({ categoryId, subCategoryId, mode, authority, lastLoadDate }) =>
        this.products
          .loadBySubCategory({
            authorityId: authority.id,
            categoryId,
            subCategoryId,
            lastLoadDate,
            changesOnly: mode === ProductLoadMode.Differential,
          })
          .pipe(
            map(products =>
              subCategoryProductsRetrieved({
                categoryId,
                subCategoryId,
                mode,
                products,
              })
            ),
            catchError(error =>
              of(
                subCategoryProductsRetrievalFailed({
                  categoryId,
                  subCategoryId,
                  mode,
                  error,
                })
              )
            )
          )
      )
    )
  );

  subCategoryProductsRetrievalFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(subCategoryProductsRetrievalFailed),
        tap(({ categoryId, subCategoryId, error }) =>
          this.log.error(TAGS, `Error loading products for ${categoryId}:${subCategoryId}`, error)
        )
      ),
    { dispatch: false }
  );

  handleRetrievedProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subCategoryProductsRetrieved),
      tap(({ categoryId, subCategoryId, products }) =>
        !!products && !!products.length
          ? false
          : this.log.info(TAGS, `No products for ${categoryId}:${subCategoryId} were retrieved.`)
      ),
      filter(({ products }) => !!products && !!products.length),
      tap(({ categoryId, subCategoryId, mode }) =>
        this.log.debug(
          TAGS,
          `Populating ${
            mode === ProductLoadMode.Full ? 'all' : 'changed'
          } products for ${categoryId}:${subCategoryId} into local db...`
        )
      ),
      mergeMap(({ categoryId, subCategoryId, mode, products }) =>
        iif(
          () => mode === ProductLoadMode.Differential,
          of(applySubCategoryProductsUpdates({ categoryId, subCategoryId, products })),
          of(populateSubCategoryProducts({ categoryId, subCategoryId, products }))
        )
      )
    )
  );

  trackLastLoadDateIfNoProductChangesFound$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subCategoryProductsRetrieved),
      filter(({ products, mode }) => !products && !products.length
        // && ProductLoadMode.Differential === mode
      ),
      tap(({ categoryId, subCategoryId, products }) =>
        !products && !products.length && this.log.info(TAGS, `Tracking load date for ${categoryId}:${subCategoryId} since no product changes were found.`)
      ),
      concatMap(({ categoryId, subCategoryId, mode, products }) =>
        this.products.trackProductLoadTime(categoryId, subCategoryId)
      )
    ),
    {dispatch: false}
  );

  applySubCategoryProductUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(applySubCategoryProductsUpdates),
      tap(({ categoryId, subCategoryId, products }) =>
        this.log.trace(TAGS, `Synchronizing ${products.length} changed products for ${categoryId}:${subCategoryId}...`)
      ),
      concatMap(({ categoryId, subCategoryId, products }) =>
        from(this.products.updateProducts(categoryId, subCategoryId, products)).pipe(
          take(1),
          map(() => subCategoryProductsLoaded({ categoryId, subCategoryId })),
          tap(
            () => this.log.debug(TAGS, `Synchronized ${products.length} products for ${categoryId}:${subCategoryId}.`),
            err => this.log.error(TAGS, `Error synchronizing product category ${categoryId}:${subCategoryId}:`, err)
          ),
          catchError(() =>
            of(
              retrieveSubCategoryProducts({
                categoryId,
                subCategoryId,
                mode: ProductLoadMode.Full,
              })
            )
          )
        )
      )
    )
  );

  populateSubCategoryProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(populateSubCategoryProducts),
      tap(({ categoryId, subCategoryId, products }) =>
        this.log.trace(TAGS, `Inserting all ${products.length} products for ${categoryId}:${subCategoryId}...`)
      ),
      concatMap(({ categoryId, subCategoryId, products }) =>
        from(this.products.populateProducts(categoryId, subCategoryId, products)).pipe(
          take(1),
          map(() => subCategoryProductsLoaded({ categoryId, subCategoryId })),
          tap(
            () => this.log.debug(TAGS, `Inserted all ${products.length} products for ${categoryId}:${subCategoryId}.`),
            err => this.log.error(TAGS, `Error inserting products for  ${categoryId}:${subCategoryId}:`, err)
          )
        )
      )
    )
  );

  subCategoryProductsLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(subCategoryProductsLoaded),
        tap(({ categoryId, subCategoryId }) =>
          this.log.info(TAGS, `Products loaded for ${categoryId}:${subCategoryId}.`)
        )
      ),
    { dispatch: false }
  );
}
