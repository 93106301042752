import { Injectable } from '@angular/core';
import { InAppReview } from '@capacitor-community/in-app-review';

@Injectable()
export class RatingService {

  requestRating() {
    return InAppReview.requestReview();
  }
}
