<ion-header data-cy="redemptions-header">
  <ion-toolbar color="blue">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{'redemptions.contentTitle' | transloco}}</h3>
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon
          icon="fingerprint"
          size="2x"
          [styles]="{opacity: '0.03', color: 'var(--white)'}"
        ></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="redemptions.refreshRedemptions($any($event))">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <section
    *ngIf="(redemptions.isLoading$ | async) && (redemptions.hasNoEntities$ | async); else currentRedemptions"
  >
    <ion-list>
      <ion-item lines="none">
        <ion-label><h2>{{'redemptions.loadingLabel' | transloco}}</h2></ion-label>
        <ion-spinner slot="end" name="dots"></ion-spinner>
      </ion-item>
    </ion-list>
  </section>

  <ng-template #currentRedemptions>
    <wic-redemption-list
      *ngIf="redemptions.hasEntities$ | async; else noRedemptions"
      [redemptions]="redemptions.redemptionsWithConfigs$ | async"
      [loading]="redemptions.isLoading$ | async"
      [offsetMonths]="redemptions.offsetMonths$ | async"
      [currentLanguage]="settings.selectedLanguage$ | async"
      (loadMore)="redemptions.loadOlderMonthsRedemptions()"
    ></wic-redemption-list>

    <ng-template #noRedemptions>
      <ion-card class="no-redemptions-card">
        <ion-card-header>
          <ion-card-subtitle>{{ 'common.sorry' | transloco }}</ion-card-subtitle>
          <ion-card-title> {{ 'redemptions.noRedemptionsTitle' | transloco }} </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <p>{{ 'redemptions.noRedemptionsInfo' | transloco }}</p>
        </ion-card-content>
      </ion-card>
    </ng-template>
  </ng-template>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>
