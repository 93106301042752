import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'MissingProduct',
  uriName: 'missing'
})
export class MissingProduct {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  @Key _id?: string;
  authorityId: number;
  name?: string;
  email: string;
  phone?: string;
  storeName?: string;
  storeLocNum?: string;
  who: 'participant' | 'staff' | 'store' | 'other';
  brand: string;
  productName: string;
  packageSize: string;
  upc: string;
  addtlInfo: string;
  productImage: string;
  labelImage: string;
  upcImage: string;
  guid?: string;
  timestamp?: string;
  cardNumber?: string;
}
