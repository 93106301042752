import { Entity, Key } from '@briebug/ngrx-auto-entity';

export enum SubstitutionType {
  Disabled,
  Allowed,
  Auto
}


@Entity('SubstitutionConfig', {uriName: 'substitution-configs'})
export class SubstitutionConfig {
  @Key authorityId: number;
  @Key category: number;

  substitutionType: SubstitutionType;
}
