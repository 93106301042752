import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  clearCategoryEntries,
  createAdHocEntry,
  decrementEntry,
  editEntry,
  incrementEntry,
  removeEntry,
  restoreCalculatorEntries,
  tryAddProduct,
  tryToggleEntry,
  viewCalculator,
} from '~features/calculators/calculators.actions';
import {
  calculatorEntries,
  currentBalance,
  hasEntries,
  remainingBalance,
  remainingBalanceEmpty,
  remainingQuantity,
  remainingQuantityEmpty,
  totalCost,
  totalCount,
  totalQuantity,
} from '~features/calculators/calculators.selectors';
import { CalculatorEntry, IWithCategory } from '~features/calculators/models';
import { EnrichedProduct } from '~features/products/models';
import { State } from '~features/state';

import { negate } from '../../util/rxjs-util';
import { setBenefitBalanceForCategories } from './calculators.actions';


@Injectable()
export class CalculatorsFacade {
  entries$(categoryId: number): Observable<CalculatorEntry[]> {
    return this.store.select(calculatorEntries(categoryId));
  }

  hasEntries$(categoryId: number): Observable<boolean> {
    return this.store.select(hasEntries(categoryId));
  }

  noEntries$(categoryId: number): Observable<boolean> {
    return this.hasEntries$(categoryId).pipe(negate());
  }

  selectedTotalCount$(categoryId: number): Observable<number> {
    return this.store.select(totalCount(categoryId));
  }

  selectedTotalQuantity$(categoryId: number): Observable<number> {
    return this.store.select(totalQuantity(categoryId));
  }

  remainingQuantity$(categoryId: number): Observable<number> {
    return this.store.select(remainingQuantity(categoryId));
  }

  remainingQuantityEmpty$(categoryId: number): Observable<boolean> {
    return this.store.select(remainingQuantityEmpty(categoryId));
  }

  selectedTotalCost$(categoryId: number): Observable<number> {
    return this.store.select(totalCost(categoryId));
  }

  currentBalance$(categoryId: number): Observable<number> {
    return this.store.select(currentBalance(categoryId));
  }

  remainingBalance$(categoryId: number): Observable<number> {
    return this.store.select(remainingBalance(categoryId));
  }

  remainingBalanceEmpty$(categoryId: number): Observable<boolean> {
    return this.store.select(remainingBalanceEmpty(categoryId));
  }

  constructor(private store: Store<State>) {
  }

  restoreEntries(): void {
    this.store.dispatch(restoreCalculatorEntries());
  }

  addToCalculator(product: EnrichedProduct): void {
    this.store.dispatch(tryAddProduct({product}));
  }

  viewCalculator<T extends IWithCategory>(category: T): void {
    this.store.dispatch(viewCalculator({category}));
  }

  clearCategoryEntries(categoryId: number): void {
    this.store.dispatch(clearCategoryEntries({categoryId}));
  }

  createAdHocEntry(categoryId: number): void {
    this.store.dispatch(createAdHocEntry({entry: {categoryId, isSelected: true}}));
  }

  setBenefitBalance(categoryId: number): void {
    this.store.dispatch(setBenefitBalanceForCategories({categoryId}));
  }

  toggle(entry: CalculatorEntry): void {
    this.store.dispatch(tryToggleEntry({entry}));
  }

  increment(entry: CalculatorEntry): void {
    this.store.dispatch(incrementEntry({entry}));
  }

  decrement(entry: CalculatorEntry): void {
    this.store.dispatch(decrementEntry({entry}));
  }

  edit(entry: CalculatorEntry): void {
    this.store.dispatch(editEntry({entry}));
  }

  remove(entry: CalculatorEntry): void {
    this.store.dispatch(removeEntry({entry}));
  }
}
