import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'wic-no-wic-warning',
  templateUrl: './no-wic-warning.component.html',
})
export class NoWicWarningComponent {
  constructor(public popover: PopoverController) {}
}
