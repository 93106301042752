import { Action, createAction, createReducer, createSelector, on, props } from '@ngrx/store';
import * as fuzzy from 'fuzzy';
import { Authority } from '../authorities/models';
import { EnrichedBenefit, EnrichedBenefitInfo } from '../benefits/models';

import { State } from '../state';
import { keyEnterUPC } from '../upc/upc.state';

import { EnrichedProduct, Product } from './models';
import {SQLiteError} from '~features/products/products.service';

export enum ProductLoadMode {
  Differential,
  Full
}


export const initializeAPLDatabase = createAction(
  '[Products] Initialize APL Database'
);

export const aplDatabaseInitialized = createAction(
  '[Products] APL Database Initialized',
);

export const aplDatabaseInitializationFailure = createAction(
  '[Products] APL Database Initialization Failure',
  props<{ error: any }>()
);

export const resetProductCollection = createAction(
  '[Products] Reset products collection'
);

export const resetProductCollectionComplete = createAction(
  '[Products] Reset products collection: Complete'
);

export const resetTrackingCollection = createAction(
  '[Products] Reset tracking collection'
);

export const resetTrackingCollectionComplete = createAction(
  '[Products] Reset tracking collection: Complete'
);

export const checkProduct = createAction(
  '[Products] Check product',
  props<{ product: Product; shouldNavigate: boolean }>()
);

export const lookupProduct = createAction(
  '[Products] Look up UPC',
  props<{ upc: string; upcType?: string }>()
);

export const localLookupProduct = createAction(
  '[Products] Local Look Up UPC',
  props<{ upc: string; upcType?: string }>()
);

export const onlineLookupProduct = createAction(
  '[Products] Look up UPC (online fallback)',
  props<{ upc: string }>()
);

export const productsFound = createAction(
  '[Products] Found for UPC lookup',
  props<{ upc: string; products: Product[] }>()
);

export const productFound = createAction(
  '[Products] Found match for UPC lookup',
  props<{ products: EnrichedProduct[] }>()
);

export const productStatusComputed = createAction(
  '[Products] Product status computed for UPC lookup',
  props<{ product: EnrichedProduct }>()
);

export const loadRemainingProducts = createAction(
  '[Products] Load remaining products',
  props<{ subCats: { categoryId: number; subCategoryId: number }[] }>()
);

export const retrieveSubCategoryProducts = createAction(
  '[Products] Retrieve products for sub category',
  props<{ categoryId: number; subCategoryId: number; mode?: ProductLoadMode }>()
);

export const retrieveSubCategoryProductsWithLoadDate = createAction(
  '[Products] Retrieve products for sub category w/ last load date',
  props<{ categoryId: number; subCategoryId: number; mode?: ProductLoadMode; authority: Authority; lastLoadDate: string | null }>()
);

export const subCategoryProductsRetrieved = createAction(
  '[Products] Sub category products retrieved',
  props<{ categoryId: number; subCategoryId: number; mode?: ProductLoadMode; products: Product[] }>()
);

export const subCategoryProductsRetrievalFailed = createAction(
  '[Products] Sub category product retrieval failure',
  props<{ categoryId: number; subCategoryId: number; mode?: ProductLoadMode; error: any }>()
);

export const applySubCategoryProductsUpdates = createAction(
  '[Products] Apply differential product updates for sub category',
  props<{ categoryId: number; subCategoryId: number; products: Product[] }>()
);

export const populateSubCategoryProducts = createAction(
  '[Products] Populate full products for sub category',
  props<{ categoryId: number; subCategoryId: number; products: Product[] }>()
);

export const subCategoryProductsLoaded = createAction(
  '[Products] Sub category products loaded',
  props<{ categoryId: number; subCategoryId: number }>()
);

export const selectProducts = createAction(
  '[Products] Select products',
  props<{ categoryId: number; subCategoryId: number; retryCount?: number}>()
);

export const selectAllProducts = createAction(
  '[Products] Select all products'
);

export const productsSelected = createAction(
  '[Products] Products selected',
  props<{ products: Product[]; totalCount: number }>()
);

export const productSelectionFailed = createAction(
  '[Products] Product selection failed',
  props<{ error: any }>()
);

export const deselectProducts = createAction(
  '[Products] Deselect products'
);

export const setProductFilter = createAction(
  '[Products] Set product filter',
  props<{ criteria: string }>()
);

export const itemDetailViewed = createAction(
  '[Products] Item detail viewed',
  props<{ benefit: EnrichedBenefit }>()
);

export interface IProductState {
  dbInitialized: boolean;
  dbInitializationError?: any;
  lookupUPC?: string;
  filterCriteria?: string;
  currentProducts: Product[];
  totalCount?: number;
  foundProducts: Product[];
  statusProduct: EnrichedProduct;
  selectedCriteria: {
    categoryId: number;
    subCategoryId: number;
  };
  isSelecting: boolean;
  error?: any;
}

export const initialProductState: IProductState = {
  dbInitialized: false,
  currentProducts: [],
  foundProducts: [],
  statusProduct: {
    status: 'chk',
    description: 'Please wait...'
  } as EnrichedProduct,
  selectedCriteria: {
    categoryId: null,
    subCategoryId: null
  },
  isSelecting: false,
};

const reducer = createReducer(
  initialProductState,
  on(initializeAPLDatabase, state => ({
    ...state
  })),
  on(aplDatabaseInitialized, (state) => ({
    ...state,
    dbInitializationError: undefined,
    dbInitialized: true
  })),
  on(aplDatabaseInitializationFailure, (state, {error}) => ({
    ...state,
    dbInitializationError: error
  })),
  on(resetProductCollection, (state) => ({
    ...state,
    collectionInitialized: false,
  })),
  on(productsSelected, (state, {products, totalCount}) => ({
    ...state,
    isSelecting: false,
    currentProducts: products,
    totalCount
  })),
  on(productSelectionFailed, (state, {error}) => ({
    ...state,
    isSelecting: false,
    error
  })),
  on(deselectProducts, (state) => ({
    ...state,
    currentProducts: [],
    selectedCriteria: {
      categoryId: null,
      subCategoryId: null
    }
  })),
  on(keyEnterUPC, (state) => ({
    ...state,
    statusProduct: {
      status: 'chk',
      description: 'Please wait...'
    } as EnrichedProduct
  })),
  on(productsFound, (state, {products}) => ({
    ...state,
    foundProducts: products
  })),
  on(productStatusComputed, (state, {product}) => ({
    ...state,
    statusProduct: product
  })),
  on(setProductFilter, (state, {criteria}) => ({
    ...state,
    filterCriteria: criteria
  })),
  on(selectProducts, (state, {categoryId, subCategoryId}) => ({
    ...state,
    currentProducts:
      state.selectedCriteria.categoryId === categoryId && state.selectedCriteria.subCategoryId === subCategoryId
        ? state.currentProducts
        : [],
    selectedCriteria: {
      categoryId,
      subCategoryId
    },
    isSelecting: true,
    error: null
  })),
  on(selectAllProducts, state => ({
    ...state,
    currentProducts: state.currentProducts || [],
    selectedCriteria: {
      categoryId: null,
      subCategoryId: null
    },
    isSelecting: true,
    error: null
  }))
);

export function productReducer(state = initialProductState, action: Action): IProductState {
  return reducer(state, action);
}

export const getProductState = (state: State): IProductState =>
  state.product;

export const determineIsDbInitialized = (state: IProductState): boolean =>
  state.dbInitialized;

export const mapToCurrentProducts = (state: IProductState): Product[] =>
  state.currentProducts || [];

export const mapToFilterCriteria = (state: IProductState): string =>
  state.filterCriteria;

export const mapToCurrentProductsFiltered = (products: Product[], criteria: string): Product[] =>
  (!!products && !!criteria)
    ? fuzzy.filter(criteria, products, {
      extract: product => `${product.itemNumber} ${product.description}`
    }).map(m => m.original)
    : products || [];

export const mapToIsSelecting = (state: IProductState) =>
  state.isSelecting;

export const getError = (state: IProductState) =>
  state.error;

export const mapToIsSQLiteError = (state: IProductState) =>
  state.error instanceof SQLiteError;

export const isAPLDbInitialized = createSelector(
  getProductState,
  determineIsDbInitialized
);

export const isSQLiteError = createSelector(
  getProductState,
  mapToIsSQLiteError
);

export const productError = createSelector(
  getProductState,
  getError
);

export const currentProducts = createSelector(
  getProductState,
  mapToCurrentProducts
);

export const totalAvailableCount = createSelector(
  getProductState,
  state => state.totalCount
);

export const filterCriteria = createSelector(
  getProductState,
  mapToFilterCriteria,
);

export const hasFilterCriteria = createSelector(
  filterCriteria,
  criteria => !!criteria
);

export const currentProductsFiltered = createSelector(
  currentProducts,
  filterCriteria,
  mapToCurrentProductsFiltered
);

export const isSelectingProducts = createSelector(
  getProductState,
  mapToIsSelecting
);
