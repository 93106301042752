export class HomeButton {
  name: string;
  order: number;
  show: boolean;
  imgIdx?: string;
  imgSrc: string;
  label: string;
  linkUrl?: string;
  linkId?: string;
}
