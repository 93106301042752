import { AbstractControl, ValidatorFn } from '@angular/forms';
import IMask from 'imask';

/* eslint-disable max-len */
const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export class BBValidators {
  static email(control: AbstractControl): { [key: string]: boolean } {
    return !control.value
      ? null
      : EMAIL_REGEXP.test(control.value)
        ? null
        : {email: true};
  }

  static compareEqual(validateEqual: string, reverse: boolean) {
    return (control: AbstractControl): { [key: string]: any } => {
      const rootValue = control.value;
      const compareField = control.root.get(validateEqual);

      if (compareField && rootValue !== compareField.value && !reverse) {
        return {validateEqual: true};
      }

      if (compareField && rootValue === compareField.value && reverse) {
        if (compareField.errors && compareField.errors['validateEqual']) {
          delete compareField.errors['validateEqual'];

          if (!Object.keys(compareField.errors).length) {
            compareField.setErrors(null);
          }
        }
      }

      if (compareField && rootValue !== compareField.value && reverse) {
        compareField.setErrors({validateEqual: true});
      }

      return null;
    };
  }

  static exactLength(length: number, mask: string): ValidatorFn {
    const maskResolver = IMask.createMask({ mask });
    return (control: AbstractControl) => (
      maskResolver.resolve(control.value), maskResolver.unmaskedValue.length === length ? null : { exactLength: true }
    );
  }
}
