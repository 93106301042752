import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { enableNewHomeScreen } from '~features/registration/registration.selectors';
import { apiHost } from '../settings/settings.state';
import { Banner } from './models';

export const loadBanners = createAction('[Banners] Load All');

export const openBannerLink = createAction('[Banners] Open Link', props<{ banner: Banner }>());

export const resetBanners = createAction('[Banners] Reset');

export type IBannerState = IEntityState<Banner>;

export const {
  initialState: initialBannerState,
  facade: BannersFacadeBase,
  selectors: { selectAll: allBanners, selectTotal: bannerCount },
} = buildState(Banner, {} as IBannerState);

const reduce = createReducer(
  initialBannerState,
  on(resetBanners, state => ({
    ...state,
    nextBannerIndex: 0,
  }))
);

export function bannerReducer(state: IBannerState, action: Action): IBannerState {
  return reduce(state, action);
}

export const bannersState = createFeatureSelector<IBannerState>('banner');

export const mapToHasBanner = (banners: Banner[]): boolean => !!banners.length;
export const mapToHasMany = (count: number): boolean => count > 1;
export const mapToBannerImage = (banner: Banner, cdn: string): string =>
  !banner
    ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg=='
    : `${cdn}/v1/authorities/banners/${banner.BannerID}`;

export const hasBanner = createSelector(allBanners, mapToHasBanner);
export const hasManyBanners = createSelector(bannerCount, mapToHasMany);
export const detailedBanners = createSelector(allBanners, apiHost, (banners, host) =>
  banners.map(banner => ({
    ...banner,
    imageUrl: mapToBannerImage(banner, host),
  }))
);
export const detailedPriorityBanners = createSelector(detailedBanners, enableNewHomeScreen, (banners, enable) =>
  enable ? banners.filter(banner => banner.IsPriority) : banners
);

export const hasManyPriorityBanners = createSelector(detailedPriorityBanners, banners => banners.length > 1);
