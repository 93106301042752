import { Injectable } from '@angular/core';
import { Camera, CameraDirection, CameraResultType } from '@capacitor/camera';

@Injectable()
export class CameraService {
  async getPicture(options: { quality?: number; width?: number; height?: number} = { quality: 90 }) {
    const image = await Camera.getPhoto({
      ...options,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      direction: CameraDirection.Rear
    });
    return image.base64String;
  }

  async hasCameraPermission() {
    const status = await Camera.checkPermissions();
    return status.camera === 'granted';
  }
}
