import { Component, OnInit } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { MenuController } from '@ionic/angular';
import { combineLatest, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { LanguagesFacade } from '~features/languages/languages.facade';
import { RegistrationFacade } from '~features/registration/registration.facade';

import { SettingsFacade } from '~features/settings/settings.facade';

@Component({
  selector: 'wic-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {
  version$: Observable<string>;

  constructor(
    public appVersion: AppVersion,
    public settings: SettingsFacade,
    public enhancedMode: EnhancedModeFacade,
    public registration: RegistrationFacade,
    public menu: MenuController,
    private analytics: AnalyticsFacade,
    public languages: LanguagesFacade,
  ) {
  }

  ngOnInit() {
    this.analytics.pageView('settings');
    this.settings.startEditingUserSettings();
    this.version$ = combineLatest([
      from(this.appVersion.getVersionNumber()),
      from(this.appVersion.getVersionCode())
    ]).pipe(
      map(([version, build]) => `${version} / ${build}`),
      catchError(() => of('unknown'))
    );
  }
}
