import { Component, OnInit } from '@angular/core';

import { RegistrationFacade } from '~features/registration/registration.facade';
import { HomeFacade } from '~features/home/home.facade';
import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';

@Component({
  selector: 'wic-manage-cards',
  templateUrl: './manage-cards.page.html',
  styleUrls: ['./manage-cards.page.scss'],
})
export class ManageCardsPage implements OnInit {
  constructor(
    public benefits: BenefitsFacade,
    public home: HomeFacade,
    public registration: RegistrationFacade,
    private analytics: AnalyticsFacade) {
  }

  ngOnInit() {
    this.analytics.pageView('manage-cards');
  }
}
