import { Pipe, PipeTransform } from '@angular/core';

const SHORT_DISTANCES = {
  [0.75]: '~3/4',
  [0.6]: '~2/3',
  [0.45]: '~1/2',
  [0.3]: '~1/3',
  [0.2]: '~1/4',
  [0]: '< 1/4'
};

export const formatShortDistance = (distance: number): string =>
  Object.keys(SHORT_DISTANCES)
    .sort((a, b) => +b - +a)
    .reduce((friendly, minDist) =>
      friendly || (
        (distance >= +minDist)
          ? SHORT_DISTANCES[minDist]
          : null
      ),
      null
    );


@Pipe({name: 'prettyDistance'})
export class PrettyDistancePipe implements PipeTransform {
  transform(distance: number | null): any {
    return !distance
      ? '???'
      : distance >= 1
        ? distance
        : formatShortDistance(distance);
  }
}
