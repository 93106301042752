import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, from } from 'rxjs';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ShoppingList } from '~features/shopping-lists/shopping-list.entity';
import { vendorChangedForShoppingList } from '~features/shopping-lists/shopping-lists.actions';
import {
  currentShoppingList,
  updateShoppingListSuccess,
} from '~features/shopping-lists/shopping-lists.state';
import { loadAllVendorInventoryItemsSuccess } from '~features/vendor-inventory-items/vendor-inventory-items.state';
import { keyEnterUPC } from '../../upc/upc.state';
import { ShoppingListsUIService } from '../shopping-lists-ui.service';
import {
  allItemsFoundForNewVendor,
  scannedBarcodeMatchesShoppingListItem,
  submitShoppingListItemQuantityEdit,
  toggleShoppingListItemCheckedOffStatus,
  unavailableItemsCanceled,
  unavailableItemsConfirmed,
  unavailableItemsFoundForNewVendor,
  uncheckAllShoppingListItems,
} from './shopping-list-items.actions';
import {
  itemsForCurrentShoppingList,
  itemsNotInVendorsInventory,
  quantityEditItem,
} from './shopping-list-items.selectors';
import {
  allShoppingListItems, createShoppingListItemSuccess,
  deleteManyShoppingListItemsSuccess, selectShoppingListItem,
  updateManyShoppingListItemsSuccess,
  updateShoppingListItemSuccess,
} from './shopping-list-items.state';

@Injectable()
export class ShoppingListItemsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly shoppingListsUI: ShoppingListsUIService
  ) {}

  selectShoppingListItemOnCreate$ = createEffect(
    () => this.actions$.pipe(
      ofType(createShoppingListItemSuccess),
      map(({ entity }) => selectShoppingListItem({ entity }))
    )
  )

  updateShoppingListItemQuantity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitShoppingListItemQuantityEdit),
      withLatestFrom(this.store.select(quantityEditItem)),
      map(([{ purchaseQuantity }, item]) => ({ ...item, purchaseQuantity })),
      map(entity => updateShoppingListItemSuccess({ entity }))
    )
  );

  toggleShoppingListItemCheckedOffStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleShoppingListItemCheckedOffStatus),
      map(({ shoppingListItem }) => ({
        ...shoppingListItem,
        checkedOff: !shoppingListItem.checkedOff,
      })),
      map(entity => updateShoppingListItemSuccess({ entity }))
    )
  );

  uncheckAllShoppingListItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uncheckAllShoppingListItems),
      withLatestFrom(this.store.select(itemsForCurrentShoppingList)),
      filter(([, items]) => !!items.length),
      map(([, items]) => items.map(item => ({ ...item, checkedOff: false }))),
      map(entities => updateManyShoppingListItemsSuccess({ entities }))
    )
  );

  checkScannedItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(keyEnterUPC),
      withLatestFrom(this.store.select(allShoppingListItems)),
      map(([{ upc }, items]) => items.find(item => item.itemNumber === upc)),
      filter(item => !!item),
      map(shoppingListItem => scannedBarcodeMatchesShoppingListItem({ shoppingListItem }))
    )
  );

  confirmScannedItemCheckOff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(scannedBarcodeMatchesShoppingListItem),
      switchMap(({ shoppingListItem }) =>
        from(this.shoppingListsUI.confirmCheckOffForScannedItem()).pipe(
          filter(result => result),
          map(() => ({ ...shoppingListItem, checkedOff: !shoppingListItem.checkedOff }))
        )
      ),
      map(entity => updateShoppingListItemSuccess({ entity }))
    )
  );

  checkChangedVendorItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorChangedForShoppingList),
      switchMap(({ shoppingList }) =>
        this.actions$.pipe(
          ofType(loadAllVendorInventoryItemsSuccess),
          first(),
          map(() => shoppingList.vendorId)
        )
      ),
      withLatestFrom(this.store.select(itemsNotInVendorsInventory)),
      map(([previousVendorId, shoppingListItems]) =>
        !!shoppingListItems.length
          ? unavailableItemsFoundForNewVendor({ shoppingListItems, previousVendorId })
          : allItemsFoundForNewVendor({ shoppingListItems, previousVendorId })
      )
    )
  );

  warnUnavailableItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unavailableItemsFoundForNewVendor),
      exhaustMap(({ shoppingListItems, previousVendorId }) =>
        from(this.shoppingListsUI.openWarnUnavailableItemsModal(shoppingListItems)).pipe(
          map(result =>
            result.role === 'cancel'
              ? unavailableItemsCanceled({ previousVendorId })
              : unavailableItemsConfirmed({ shoppingListItems })
          )
        )
      )
    )
  );

  unavailableItemsConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unavailableItemsConfirmed),
      map(({ shoppingListItems }) =>
        deleteManyShoppingListItemsSuccess({ entities: shoppingListItems })
      )
    )
  );

  unavailableItemsCanceled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unavailableItemsCanceled),
      withLatestFrom(this.store.select(currentShoppingList)),
      map(
        ([{ previousVendorId }, shoppingList]): ShoppingList => ({
          ...shoppingList,
          vendorId: previousVendorId,
        })
      ),
      map(entity => updateShoppingListSuccess({ entity }))
    )
  );
}
