<ion-item lines="none">
  <ion-thumbnail slot="start" *ngIf="image">
    <img [hidden]="!image" [src]="image" [alt]="imageAlt" />
  </ion-thumbnail>
  <ion-label class="ion-text-wrap">
    <p *ngIf="!image" class="text-center text-muted">
      {{ capturePlaceholder }}
    </p>

    <ion-button
      class="m-20 ion-text-wrap"
      expand="block"
      color="dark-red"
      *ngIf="image"
      (click)="clearImage.emit()"
    >
      <fa-icon icon="times-circle" class="pr-5" slot="start"></fa-icon>
      {{ clearButton }}
    </ion-button>
    <ion-button
      class="m-20 ion-text-wrap"
      expand="block"
      color="tufts-blue-shaded"
      *ngIf="!image"
      (click)="captureImage.emit()"
    >
      <fa-icon icon="camera" class="pr-5" slot="start"></fa-icon>
      {{ captureButton }}
    </ion-button>
  </ion-label>
</ion-item>
<!--<ion-item lines="none">-->
<!--  <ion-button-->
<!--    class="m-20"-->
<!--    expand="block"-->
<!--    color="dark-red"-->
<!--    *ngIf="image"-->
<!--    (click)="clearImage.emit()"-->
<!--  >-->
<!--    <fa-icon icon="times-circle"></fa-icon>-->
<!--    {{ clearButton }}-->
<!--  </ion-button>-->
<!--  <ion-button-->
<!--    class="m-20"-->
<!--    expand="block"-->
<!--    color="tufts-blue-shaded"-->
<!--    *ngIf="!image"-->
<!--    (click)="captureImage.emit()"-->
<!--  >-->
<!--    <fa-icon icon="camera" class="pr-5"></fa-icon>-->
<!--    {{ captureButton }}-->
<!--  </ion-button>-->
<!--</ion-item>-->
