import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '~env';


(window as any).skipLocalNotificationReady = true;

console.log('[WIC] [v5] [Bootstrap] Initial app startup: ');

if (environment.production) {
  enableProdMode();
  console.log('[WIC] [v5] [Bootstrap] Production mode: ENABLED');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() =>
    console.log('[WIC] [v5] [Bootstrap] Angular has been bootstrapped!')
  )
  .catch(err => console.log(err));
