import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ToastController} from '@ionic/angular';
import {from, Observable} from 'rxjs';
import {TranslocoService} from '@ngneat/transloco';

@Injectable()
export class DialogsService {
  constructor(
    private alerts: AlertController,
    private toasts: ToastController,
    private loading: LoadingController,
    private transloco: TranslocoService
  ) {
  }

  alert({message, title}: { message: string; title?: string }): Observable<true> {
    const p = async (): Promise<true> => {
      const alert = await this.alerts.create({
        subHeader: title,
        message,
        buttons: [{
          text: this.transloco.translate('ok'),
          role: 'ok'
        }]
      });
      await alert.present();
      await alert.onDidDismiss();
      return true;
    };

    return from(p());
  }

  confirm(
    {message, title, okText, cancelText}: { message: string; title?: string; okText?: string; cancelText?: string }
  ): Observable<boolean> {
    const p = async (): Promise<boolean> => {
      const alert = await this.alerts.create({
        subHeader: title,
        message,
        buttons: [{
          text: this.transloco.translate(okText || 'ok'),
          role: 'ok'
        }, {
          text: this.transloco.translate(cancelText || 'cancel'),
          role: 'cancel'
        }]
      });
      await alert.present();
      const result = await alert.onDidDismiss();
      return result.role === 'ok';
    };

    return from(p());
  }

  toast({message, title, cssClass}: { message: string; title?: string; cssClass?: string }): Observable<void> {
    const p = async (): Promise<void> => {
      const toast = await this.toasts.create({
        cssClass,
        header: title,
        message,
        duration: 2300
      });
      await toast.present();
      return;
    };

    return from(p());
  }

  showLoading({message}: { message: string }): Observable<void> {
    const p = async (): Promise<void> => {
      const loadScreen = await this.loading.create({message});
      await loadScreen.present();
      return;
    };

    return from(p());
  }

  dismissLoading(): Observable<boolean> {
    return from(this.loading.dismiss());
  }
}
