import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vendor, VendorAndFavorites, VendorFavorite } from '~features/vendors/models';
import { faHeart as faHeartOpen } from '@fortawesome/free-regular-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'wic-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss'],
})
export class VendorListComponent  {
  @Input() vendors: Vendor[];
  @Input() favorites: VendorFavorite[];

  @Output() selected = new EventEmitter<VendorAndFavorites>();
  @Output() directions = new EventEmitter<Vendor>();
  @Output() favoriteToggled = new EventEmitter<VendorAndFavorites>();

  faHeart = faHeart;
  faHeartOpen = faHeartOpen;

  trackVendorBy(index: number, item: Vendor) {
    return item.entryId;
  }
}
