<ion-header mode="ios">
  <ion-toolbar mode="ios" color="tufts-blue-shaded">
    <ion-title>{{ 'shoppingListsSelectBenefitSubCategory.contentTitle' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" data-cy="home-button" (click)="modals.dismiss(null, 'cancel')">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="shoppingLists.current$ | async as shoppingList">
    <ion-list-header>{{ shoppingList.name }}</ion-list-header>
  </ion-list>

  <ion-accordion-group>
    <ion-accordion
      *ngFor="
        let benefit of shoppingLists.benefitsWithShoppingListItems$ | async;
        trackBy: trackByCategory
      "
      [value]="benefit.categoryId + '_' + benefit.subCategoryId"
    >
      <ion-item slot="header" lines="none">
        <ion-avatar slot="start">
          <ion-img [src]="benefit.config?.image"></ion-img>
        </ion-avatar>
        <ion-label>
          <h1>{{ benefit.subCategory }}</h1>
          <p *ngIf="benefit.categoryId === 19 || benefit.categoryId === 97">
            {{ (benefit.availableQuantity - (benefit | benefitShoppingListTotal)) | currency }} {{ 'shoppingLists.availableLabel' | transloco }}
          </p>
          <p *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97">
            {{ benefit.availableQuantity - (benefit | benefitShoppingListTotal) }} {{ benefit.uom }} {{ 'shoppingLists.availableLabel' | transloco }}
          </p>
        </ion-label>
      </ion-item>

      <ion-list slot="content">
        <ion-item *ngFor="let item of benefit.shoppingListItems">
          <ion-label *ngIf="item.categoryId !== 19 && item.categoryId !== 97">
            <h3>{{ item.purchaseQuantity }} x {{ item.units }} {{ item.uom }}</h3>
            <p>{{ item.description }}</p>
          </ion-label>
          <ion-label *ngIf="item.categoryId === 19 || item.categoryId === 97">
            <h3>{{ item.description }}</h3>
          </ion-label>
        </ion-item>

        <ion-item
          *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97"
          lines="none"
          class="add-shopping-item"
        >
          <ion-label *ngIf="benefit | isBenefitFilled" color="vivid-orange-peel" class="ion-text-wrap">
            {{ 'shoppingListsSelectBenefitSubCategory.notEnoughBenefitsWarning' | transloco }}
          </ion-label>

          <ion-button
            slot="end"
            color="primary"
            size="default"
            [disabled]="benefit | isBenefitFilled"
            (click)="shoppingLists.addItem(benefit)"
          >
            {{ 'shoppingLists.addItem' | transloco }}
          </ion-button>
        </ion-item>

        <ion-item
          *ngIf="benefit.categoryId === 19 || benefit.categoryId === 97"
          lines="none"
          class="add-shopping-item"
        >
          <ion-button
            slot="end"
            color="primary"
            size="default"
            (click)="shoppingLists.addItem(benefit)"
          >
            {{ 'shoppingLists.addItem' | transloco }}
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</ion-content>
