import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'wic-item-entry-popover',
  templateUrl: './item-entry-popover.component.html',
  styleUrls: ['./item-entry-popover.component.scss'],
})
export class ItemEntryPopoverComponent {
  @Input() title: string;
  @Input() label: string;
  @Input() placeholder: string;


  constructor(public readonly popovers: PopoverController) {}
}
