import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Banner',
  comparer: (a: Banner, b: Banner) => a.PlayOrder - b.PlayOrder
})
export class Banner {
  @Key BannerID: string;
  PlayOrder: number;
  LinkURL: string;
  IsPriority?: boolean;
}
