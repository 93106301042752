<ion-header mode="ios">
  <ion-toolbar color="orange" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{'future-benefits.contentTitle' | transloco}}</h3>
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon icon="fingerprint" size="2x" [styles]="{opacity: '0.075', color: 'var(--white)'}"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <wic-future-benefits-header
    [dateRange]="benefits.currentFutureGroup$ | async"
    [latestDate]="benefits.latestBenefitsEndDateFormatted$ | async"
    [loading]="benefits.isLoading$ | async"
    data-cy="future-benefits-header"
  ></wic-future-benefits-header>

  <section *ngIf="benefits.isLoading$ | async">
    <ion-list>
      <ion-item>
        <ion-label><h2>{{'future-benefits.loadingLabel' | transloco}}</h2></ion-label>
        <ion-spinner slot="end" name="dots"></ion-spinner>
      </ion-item>
    </ion-list>
    <wic-benefits-loading-progress [findBuffer]="1.0"> </wic-benefits-loading-progress>
  </section>

  <div class="mt-10" *ngIf="(benefits.isLoading$ | async) === false">
    <wic-benefit-list
      *ngIf="benefits.currentFutureGroup$ | async as future; else noFutureBenefits"
      [authority]="registration.currentProvider$ | async"
      [benefits]="future.benefits"
      [isFuture]="true"
      [nonInteractive]="true"
      [pendingExpirationMode]="false"
    >
    </wic-benefit-list>

    <ng-template #noFutureBenefits>
      <wic-no-future-benefits-warning></wic-no-future-benefits-warning>
    </ng-template>
  </div>
</ion-content>

<ion-footer translucent>
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button
            expand="block"
            color="primary"
            *ngIf="benefits.atFirstGroup$ | async"
            (click)="benefits.backToCurrent()"
            data-cy="future-benefits-current-button"
          >
            <fa-icon icon="arrow-left"></fa-icon>
            <span class="px-5">{{'future-benefits.currentButton' | transloco}}</span>
          </ion-button>
          <ion-button
            expand="block"
            color="orange"
            *ngIf="benefits.afterFirstGroup$ | async"
            (click)="benefits.previousFutureGroup()"
            data-cy="future-benefits-previous-button"
          >
            <fa-icon icon="arrow-left"></fa-icon>
            <span class="px-5">{{'future-benefits.previousButton' | transloco}}</span>
          </ion-button>
        </ion-col>
        <ion-col *ngIf="benefits.futureGroupExists$ | async">
          <ion-button
            expand="block"
            color="orange"
            [disabled]="(benefits.afterThirdMonth$ | async)"
            (click)="benefits.nextFutureGroup()"
            data-cy="future-benefits-next-button"
          >
            <span class="px-5">{{'future-benefits.nextButton' | transloco}}</span>
            <fa-icon icon="arrow-right"></fa-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
