import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject, DbTransaction, SQLiteDatabaseConfig } from '@awesome-cordova-plugins/sqlite/ngx';
import { format } from 'date-fns';
import { LogService } from '~core/services/log.service';
import { getColumns, getPrimaryKey, getTable } from '~features/database/database.decorators';
import { createInsertString, keyEqualsParamOf, RawSQLiteResult, toColumnDefinition } from '~features/database/sqlite.service';
import { Product } from '~features/products/models';
import { Tracking } from '~features/products/models/tracking';
import { ProductsDbService } from '~features/products/products-db.service';

const TAGS = ['Service', 'Products', 'Products-SQLite'];

const resolveConfict = (i) => Object.keys(i).map(k => `${k}=excluded.${k}`);

export const resolveProducts = (products: (Omit<Product, 'purchaseIndicator'> & { purchaseIndicator: string | boolean })[]): Product[] =>
  products.map((product): Product => ({
    ...product,
    purchaseIndicator: product.purchaseIndicator === true || product.purchaseIndicator === 'true'
  }));

@Injectable()
export class ProductsSqliteService extends ProductsDbService {
  db: SQLiteObject;
  config: SQLiteDatabaseConfig = {
    name: 'wicshopper.db',
    location: 'default'
  };

  constructor(private readonly sqlite: SQLite, private readonly log: LogService) {
    super();
  }

  override async initialize() {
    try {
      const test = await this.sqlite.selfTest();
      this.log.trace(TAGS, 'SQLite Self Test', test);

      this.db = await this.sqlite.create(this.config);
      this.log.trace(TAGS, 'DB Created', this.db);
    } catch (err) {
      try {
        console.error(JSON.stringify({...err, message: err.message, stack: err.stack}));
        this.log.error(TAGS, 'Error Creating Connection to SQLite database', err);
      } catch (e) {
        console.error('Error logging error!');
      }
      throw err;
    }
  }

  override async createTable<T>(entity: new () => T): Promise<any> {
    this.log.debug(TAGS, `Creating table...`);
    const table = getTable(entity);
    this.log.debug(TAGS, `Creating table ${table}:`);
    const columns = getColumns(entity);
    this.log.debug(TAGS, `With columns ${columns}:`);
    const pkColumnName = getPrimaryKey(entity);
    this.log.debug(TAGS, `Having pk ${pkColumnName}:`);
    const sql = `CREATE TABLE IF NOT EXISTS ${table} (${toColumnDefinition(columns, pkColumnName).join(', ')})`;
    this.log.info(TAGS, sql);
    return this.executeSqlRaw(sql);
  }

  override async removeTable<T>(entity: new () => T): Promise<any> {
    this.log.debug(TAGS, `Removing table...`);
    const table = getTable(entity);
    this.log.debug(TAGS, `Removing table ${table}:`);
    const sql = `DROP TABLE IF EXISTS ${table}`;
    this.log.info(TAGS, sql);
    return this.executeSqlRaw(sql);
  }

  override async getTableInfo<T>(entity: new () => T): Promise<any> {
    const table = getTable(entity);
    return this.executeSqlRaw(`PRAGMA table_info(${table})`);
  }

  override async lookup(upc: string) {
    const now = format(Date.now(), 'yyyyMMdd');
    const sql =
      `SELECT *
      FROM products
      WHERE
      (
        effectiveDate <= '${now}' OR
        effectiveDate = '00000000' OR
        effectiveDate = NULL
      ) AND (
        endDate >= '${now}' OR
        endDate = '00000000' OR
        endDate = NULL
      ) AND (
         itemNumber LIKE ?
      )`;

    const products = await this.executeSql<Product & { purchaseIndicator: string }>(sql, [`%${upc}%`]);
    this.log.debug(TAGS, 'Local Product Lookup Found:', products);

    return resolveProducts(products);
  }

  override async selectTotalCount(categoryId: number, subCategoryId: number) {
    const now = format(Date.now(), 'yyyyMMdd');
    const sql =
      `SELECT COUNT(*)
      FROM products
      WHERE
      (
        effectiveDate <= '${now}' OR
        effectiveDate = '00000000' OR
        effectiveDate = NULL
      ) AND (
        endDate >= '${now}' OR
        endDate = '00000000' OR
        endDate = NULL
      ) AND (
         categoryId = ? AND subCategoryId = ?
      )`;
    const res = await this.executeSql<{ 'COUNT(*)': number }>(sql, [categoryId, subCategoryId]);
    return res[0]?.['COUNT(*)'];
  }

  override select(categoryId: number, subCategoryId: number, allowedCount?: number) {
    const now = format(Date.now(), 'yyyyMMdd');
    const sql =
      `SELECT *
      FROM products
      WHERE
      (
        effectiveDate <= '${now}' OR
        effectiveDate = '00000000' OR
        effectiveDate = NULL
      ) AND (
        endDate >= '${now}' OR
        endDate = '00000000' OR
        endDate = NULL
      ) AND (
         categoryId = ? AND subCategoryId = ?
      )`;

    if (allowedCount === 0 || allowedCount > 0) {
      const fullSql = `${sql} AND (
          (? / (size * 1.0 / packageSize)) >= 1
        )`;
      return this.executeSql<Product>(fullSql, [categoryId, subCategoryId, allowedCount]);
    }
    return this.executeSql<Product>(sql, [categoryId, subCategoryId]);
  }

  override async selectAllTotalCount(): Promise<number> {
    const now = format(Date.now(), 'yyyyMMdd');
    const sql =
      `SELECT COUNT(*)
      FROM products
      WHERE
      (
        effectiveDate <= '${now}' OR
        effectiveDate = '00000000' OR
        effectiveDate = NULL
      ) AND (
        endDate >= '${now}' OR
        endDate = '00000000' OR
        endDate = NULL
      )`;
    const res = await this.executeSql<{ 'COUNT(*)': number }>(sql);
    return res[0]?.['COUNT(*)'];
  }

  override selectAll(): Promise<Product[]> {
    const now = format(Date.now(), 'yyyyMMdd');
    const sql =
      `SELECT *
      FROM products
      WHERE
      (
        effectiveDate <= '${now}' OR
        effectiveDate = '00000000' OR
        effectiveDate = NULL
      ) AND (
        endDate >= '${now}' OR
        endDate = '00000000' OR
        endDate = NULL
      )`;
      
      return this.executeSql<Product>(sql);
  }

  private remove(product: Product, tx: DbTransaction) {
    const sql = `DELETE FROM products WHERE id = ?`;
    tx.executeSql(sql, [product.id],
      (t, res) => this.log.trace(TAGS, `Removed product record for ${product.categoryId}:${product.subCategoryId}`, res),
      (t, err) => (this.log.error(TAGS, `Error removing product record for ${product.categoryId}:${product.subCategoryId}`, err), false)
    );
  }

  private upsert(product: Product, tx: DbTransaction) {
    const sql = `${createInsertString('products', product)} ON CONFLICT(id) DO UPDATE SET ${resolveConfict(product).join(', ')}`;
    tx.executeSql(sql, Object.values(product),
      (t, res) => this.log.trace(TAGS, `Added product record for ${product.categoryId}:${product.subCategoryId}`, res),
      (t, err) => (this.log.error(TAGS, `Error adding product record for ${product.categoryId}:${product.subCategoryId}`, err), false)
    );
  }

  private update(product: Product, tx: DbTransaction) {
    const sql = `UPDATE products SET ${keyEqualsParamOf(product).join(', ')} WHERE id = ?`;
    tx.executeSql(sql, [product.id],
      (t, res) => this.log.trace(TAGS, `Updated product record for ${product.categoryId}:${product.subCategoryId}`, res),
      (t, err) => (this.log.error(TAGS, `Error updating product record for ${product.categoryId}:${product.subCategoryId}`, err), false)
    );
  }

  private track(categoryId: number, subCategoryId: number, tx: DbTransaction, isFull = false, date = new Date()) {
    const trackingId = ((categoryId * 1000 + subCategoryId).toString() + '');
    const sql = 'SELECT id, lastLoadDate, lastFullLoadDate FROM tracking WHERE id = ?';
    tx.executeSql(sql, [trackingId],
      (t, res) => {
        this.log.debug(TAGS, `Retrieved tracking record for ${categoryId}:${subCategoryId}`, res);

        if (res.rows && res.rows.length) {
          this.log.debug(TAGS, `Tracking record for ${categoryId}:${subCategoryId}`, res.rows.item(0));
        }

        const dsql = 'DELETE FROM tracking WHERE id = ?';
        tx.executeSql(dsql, [trackingId],
          (_, resd) => this.log.debug(TAGS, `Removed old tracking record for ${categoryId}:${subCategoryId}`, resd),
          (_, err) => (this.log.error(TAGS, `Error updating tracking record for ${categoryId}:${subCategoryId}`, err), false)
        );

        const loadDate = date.toISOString();
        const fullDate = res.rows && res.rows.length ? res.rows.item(0).lastFullLoadDate : loadDate;
        const isql = 'INSERT INTO tracking (id, lastLoadDate, lastFullLoadDate) VALUES (?, ?, ?)';
        const params = [trackingId, loadDate, fullDate];
        this.log.debug(TAGS, `SQL For insert tracking: ${isql} ${params}`);
        tx.executeSql(isql, params,
          (_, resi) => this.log.debug(TAGS, `Inserted new tracking record for ${categoryId}:${subCategoryId}`, resi),
          (_, err) => (this.log.error(TAGS, `Error updating tracking record for ${categoryId}:${subCategoryId}`, err), false)
        );
      },
      (t, err) => (this.log.error(TAGS, `Error updating tracking record for ${categoryId}:${subCategoryId}`, err), false)
    );
  }

  override updateProducts(categoryId: number, subCategoryId: number, products: Product[]) {
    this.log.debug(TAGS, `Creating transaction to update products for ${categoryId}:${subCategoryId}:`);

    const deleted = products.filter(product => !!product.deletedAt);
    this.log.debug(TAGS, `Deleting items: `, deleted);

    const added = products.filter(product => !product.updatedAt && !product.deletedAt);
    this.log.debug(TAGS, `Adding items: `, added);

    const updated = products.filter(product => !!product.updatedAt && !product.deletedAt);
    this.log.debug(TAGS, `Updating items: `, updated);

    return this.db.transaction(tx => {
      deleted.forEach(product => this.remove(product, tx));
      added.forEach(product => this.upsert(product, tx));
      updated.forEach(product => this.upsert(product, tx));

      this.track(categoryId, subCategoryId, tx, false,);
    }).then(result => {
      this.log.debug(TAGS, `Successfully updated products for ${categoryId}:${subCategoryId}`, result);
    }, err => {
      this.log.error(TAGS, `Error occurred updating products for ${categoryId}:${subCategoryId}:`, err);
      throw err;
    });
  }

  override async populateProducts(categoryId: number, subCategoryId: number, products: Product[]): Promise<void> {
    return this.db.transaction(tx => {
      const sql = `DELETE FROM products WHERE categoryId = ? AND subCategoryId = ?`;
      const params = [categoryId, subCategoryId];
      this.log.trace(TAGS, `SQL for clearing product category ${categoryId}:${subCategoryId}: ${sql} ${params}`);
      tx.executeSql(sql, params,
        (t, res) => this.log.debug(TAGS, `Updated tracking record for ${categoryId}:${subCategoryId}`, res),
        (t, err) => (this.log.error(TAGS, `Error updating tracking record for ${categoryId}:${subCategoryId}`, err), false)
      );
      products.forEach(product => this.upsert(product, tx));

      this.track(categoryId, subCategoryId, tx, true);
    }).then(result => {
      this.log.debug(TAGS, `Successfully populated products for ${categoryId}:${subCategoryId}`, result);
    }, err => {
      this.log.error(TAGS, `Error occurred populating products for ${categoryId}:${subCategoryId}:`, err);
      throw err;
    });
  }

  override clearProducts() {
    const sql = 'DELETE FROM products';
    return this.executeSql(sql).then(items => items.length);
  }

  override clearProductTracking() {
    const sql = 'DELETE FROM tracking';
    return this.executeSql(sql).then(items => items.length);
  }

  override async getProductUpdate(categoryId: number, subCategoryId: number): Promise<Tracking> {
    const sql =
      `SELECT *
      FROM tracking
      WHERE id = ?`;

    const params = [((categoryId * 1000 + subCategoryId) + '')];

    this.log.trace(TAGS, 'Product info sql:', sql);
    this.log.trace(TAGS, 'Product info params:', params);

    const items = await this.executeSql<Tracking>(sql, params);
    this.log.debug(TAGS, `Product update info ${categoryId}:${subCategoryId} (exists? ${(!!items && !!items.length)}:`, items);
    return (!!items && !!items.length) ? items[0] : undefined;
  }

  override async setProductUpdateTime(categoryId: number, subCategoryId: number, date: Date): Promise<Tracking> {
    return this.db.transaction(tx => {
      this.track(categoryId, subCategoryId, tx, false, date)
    }).then(result => {
      this.log.debug(TAGS, `Successfully set product update time for ${categoryId}:${subCategoryId}`, result);
      return this.getProductUpdate(categoryId, subCategoryId);
    }, err => {
      this.log.error(TAGS, `Error occurred setting product update time for ${categoryId}:${subCategoryId}:`, err);
      throw err;
    })
  }

  override async getAllProductUpdates(): Promise<Tracking[]> {
    const sql = `SELECT * FROM ${getTable(Tracking)} ORDER BY lastLoadDate ASC`;

    this.log.trace(TAGS, 'Product info sql:', sql);

    const items = await this.executeSql<Tracking>(sql);
    this.log.debug(TAGS, `Showing ${items.length} product updates`, items);
    return items;
  }

  private _executeSql<U>(sql: string, params, tx: DbTransaction): Promise<U[]> {
    return new Promise((resolve, reject) =>
      tx.executeSql(sql, params,
        (transaction, res) => resolve(this.resolveRawResult(res)),
        (transaction, err) => (reject(this.handleError('Error Executing SQL in Transaction', err)), false)
      )
    );
  }

  private executeSql<U>(sql: string, params?, tx?: DbTransaction): Promise<U[]> {
    // eslint-disable-next-line no-underscore-dangle
    return tx ? this._executeSql(sql, params, tx) : this.executeSqlRaw(sql, params);
  }

  private executeSqlRaw<U>(sql: string, params?): Promise<U[]> {
    return new Promise((resolve, reject) =>
      this.db.executeSql(sql, params,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (suc) => resolve(this.resolveRawResult(suc)),
        (err) => (reject(this.handleError('Error Executing SQL', err)), false)
      )
    );
  }

  private resolveRawResult<U>(result: RawSQLiteResult<U>): U[] {
    return Array.from({length: result.rows.length}).map((_, i) => result.rows.item(i));
  }

  handleError(context: string, err: any) {
    this.log.error(TAGS, context, err);
    return err;
  }
}
