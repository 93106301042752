import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { CategoriesFacade } from '~features/categories/categories.facade';
import { SubCategoriesFacade } from '~features/subcategories/subcategories.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';

@Component({
  selector: 'wic-post-registration',
  templateUrl: './post-registration.page.html',
  styleUrls: ['./post-registration.page.scss'],
})
export class PostRegistrationPage implements OnInit {
  findBuffer = 0.0;

  constructor(
    public benefits: BenefitsFacade,
    public categories: CategoriesFacade,
    private registration: RegistrationFacade,
    public subCategories: SubCategoriesFacade,
    public enhancedMode: EnhancedModeFacade,
    private analytics: AnalyticsFacade
  ) {
  }

  ngOnInit() {
    this.analytics.pageView('post-registration');

    this.benefits.reset();
    this.categories.clear();
    this.subCategories.clear();

    const token = setInterval(() => {
      this.findBuffer += 0.1;
      if (this.findBuffer > 0.5) {
        combineLatest([
          this.registration.currentProvider$,
          this.registration.pendingCard$
        ]).pipe(
          filter(([authority, card]) => !!authority && !!card),
          take(1)
        ).subscribe(([authority, card]) =>
          this.benefits.load({
            cardNumber: card.cardNumber,
            householdId: card.householdId,
            authority
          })
        );
        clearInterval(token);
        this.findBuffer = 1;
      }
    }, 200);
  }

}
