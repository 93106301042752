import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  DefaultTranspiler,
  TRANSLOCO_CONFIG,
  TRANSLOCO_MISSING_HANDLER,
  TRANSLOCO_TRANSPILER, translocoConfig,
  TranslocoModule,
} from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { LocalMissingTranslationHandler } from '~core/i18n/missing-translation-handler';

import { environment } from '~env';
import { ServicesModule } from '../services/services.module';
import { CustomMessageFormatTranspiler } from './message-format.transpiler';

import { RemoteFallbackHttpLoaderService, remoteFallbackLoader } from './remote-fallback-http-loader.service';
import { TranslocoConfig } from '@ngneat/transloco/lib/transloco.config';
import { TranslocoFallbackPipe } from './transloco-fallback.pipe';

const config: TranslocoConfig = translocoConfig({
  reRenderOnLangChange: true,
  defaultLang: 'en',
  fallbackLang: 'en',
  availableLangs: ['en', 'es'],
  // availableLangs: ['en', 'es', 'ar', 'my', 'ne', 'so', 'fr', 'pt', 'ln', 'ru', 'sw', 'zh-CN'],
  missingHandler: {
    useFallbackTranslation: true
  },
  prodMode: environment.production
})

@NgModule({
  declarations: [
    TranslocoFallbackPipe
  ],
  imports: [
    TranslocoModule,
    TranslocoLocaleModule.forRoot({ defaultLocale: 'en'  }),
    TranslocoMessageFormatModule.forRoot(),
    HttpClientModule,
    ServicesModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {...config, logMissingKey: !environment.production }
    },
    RemoteFallbackHttpLoaderService,
    LocalMissingTranslationHandler,
    remoteFallbackLoader,
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useExisting: LocalMissingTranslationHandler
    },
    { provide: TRANSLOCO_TRANSPILER, useClass: CustomMessageFormatTranspiler }
  ],
  exports: [
    TranslocoFallbackPipe
  ]
})
export class I18nModule {}
