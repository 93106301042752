import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';

import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CameraService } from '~core/services/camera.service';
import { DialogsService } from '~core/services/dialogs.service';
import { LogService } from '~core/services/log.service';

import { State } from '../state';

import {
  acquireReceiptPhoto,
  allReceipts,
  deleteReceipt,
  deleteReceiptConfirmed,
  priorReceiptsRestored,
  receiptPhotoAcquired,
  restorePriorReceipts
} from './receipts.state';

const CAPTURED_RECEIPTS_KEY = 'wic::captured_receipts';

const TAGS = ['Effects', 'Receipts'];

@Injectable()
export class ReceiptsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private log: LogService,
    private storage: Storage,
    private camera: CameraService,
    private dialogs: DialogsService) {
  }

  restorePriorReceipts$ = createEffect(
    () => this.actions$.pipe(
      ofType(restorePriorReceipts),
      tap(() => this.log.trace(TAGS, 'Restoring prior captured receipt...')),
      switchMap(() => from(this.storage.get(CAPTURED_RECEIPTS_KEY))),
      filter(json => !!json),
      map(json => JSON.parse(json)),
      tap(receipts => this.log.trace(TAGS, 'Prior captured receipts loaded: ', receipts.map(receipt => ({
        ...receipt,
        receiptImage: '[redacted]'
      })))),
      map(receipts => priorReceiptsRestored({receipts})),
      tap(() => this.log.trace(TAGS, 'Restored prior captured receipts.')),
    )
  );

  acquireReceiptImage$ = createEffect(
    () => this.actions$.pipe(
      ofType(acquireReceiptPhoto),
      tap(() => this.log.trace(TAGS, 'Acquiring benefits receipt (awaiting camera photo)...')),
      switchMap(options => from(this.camera.getPicture())),
      map(image => receiptPhotoAcquired({image})),
      tap(
        () => this.log.trace(TAGS, 'Image acquired for receipt.'),
        err => this.log.error(TAGS, 'Error occurred acquiring receipt image:', err)
      ),
    )
  );

  confirmReceiptDeletion$ = createEffect(
    () => this.actions$.pipe(
      ofType(deleteReceipt),
      switchMap(({index}) =>
        this.dialogs.confirm({
          message: 'Are you sure you wish to delete this receipt?',
          title: 'Delete Receipt'
        }).pipe(
          filter(result => !!result),
          map(() => deleteReceiptConfirmed({index}))
        )
      )
    )
  );

  saveCurrentReceipts$ = createEffect(
    () => this.actions$.pipe(
      ofType(receiptPhotoAcquired, deleteReceiptConfirmed),
      tap(() => this.log.trace(TAGS, 'Saving current receipts...')),
      withLatestFrom(this.store.select(allReceipts)),
      map(([, all]) => JSON.stringify(all)),
      switchMap(json =>
        from(this.storage.set(CAPTURED_RECEIPTS_KEY, json))
      ),
      tap(
        () => this.log.trace(TAGS, 'All receipts saved to storage!'),
        err => this.log.error(TAGS, 'Error encountered saving receipts to storage.', err)
      )
    ),
    {dispatch: false, resubscribeOnError: true}
  );
}
