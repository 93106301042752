import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Authority',
  uriName: 'authorities'
})
export class Authority {
  @Key id: number;
  name: string;
  shortName: string;
  identityName: string;
  type: AuthorityType | number | string;
  flags: number | string;
  bannerPlaylistId?: number;
  proxyId: number;

  binCodes: string[];
  isActive?: boolean;
  isProvider?: boolean;
  isOfflineOrVerifyOnly?: boolean;
  // Based on Agency type
  showScan?: boolean;
  // Based on Authority Flags
  allowScan?: boolean;
  showVendors?: boolean;
  showOffices?: boolean;
  hasLocations?: boolean;
  benefitsHaveNoExpiration?: boolean;
  allowCaptureBenefits: boolean;
  hasApptReminders?: boolean;
  hideFutureBenefits?: boolean;
  showLastShoppingTrip?: boolean;
  showLastImport?: boolean;
  nonRealTimeBenefits?: boolean;
  useHHIDForAppts?: boolean;
  disableCVVCalc?: boolean;
  disableCerealCalc?: boolean;
  disableGrainCalc?: boolean;
  enableCantBuyThis?: boolean;
  enableRedemptionHistory?: boolean;
  enableNewHomeScreen?: boolean;
  enableCardQRCode?: boolean;
  enableShoppingList?: boolean;
  enableMobileOrdering?: boolean;
  enableEnhancedIssues?: boolean;
  enableAplSearchNoBenefits?: boolean;
  integration?: string;
}

export const enum AuthorityType {
  VerificationOnly = 0,
  Offline = 1,
  Online = 2,
  Voucher = 3,
  NonSubscriber = 4
}
