import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { createAction, props } from '@ngrx/store';

import { Authority } from './models';

export const authorityRefreshed = createAction(
  '[Authority] Authority Refreshed',
  props<{ authority: Authority }>(),
);

export const preloadAuthorities = createAction('[Authority] Preload Authorities');

export const {
  initialState,
  selectors: {
    selectAll: allAuthorities,
    selectEntities: authorityEntities,
  },
  actions: {
    loadAll: loadAllAuthorities,
    loadAllSuccess: loadAllAuthoritiesSuccess,
    loadAllFailure: loadAllAuthoritiesFailure,
  },
  facade: AuthoritiesFacadeBase,
} = buildState(Authority);

export function authorityReducer(state = initialState): IEntityState<Authority> {
  return state;
}
