import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'SubCatConfig',
  uriName: 'subcategoryconfigs'
})
export class SubCatConfig {
  @Key categoryId: number | string;
  @Key subCategoryId: number | string;
  image: string;
  linkDetails: LinkDetail[];
}

export interface LinkDetail {
  icon: string;
  url: string;
  label: string;
}
