import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { EMPTY, from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, timeout, withLatestFrom } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import {
  boolToString,
  ensureSettings,
  generateParticipantToken,
  numberOrNull,
  stringOrNull,
} from '~features/analytics/util';
import { openBannerLink } from '~features/banners/banners.state';
import {
  benefitsViewed,
  openCustomBenefitLink,
  viewFutureBenefits,
} from '~features/benefits/benefits.actions';
import { microBlogViewed } from '~features/micro-blogs/micro-blogs.state';
import { driveToOffice, officeDetailViewed, officesViewed } from '~features/offices/offices.state';
import {
  addCardRegistration,
  cardRegistrationCompleted,
  deleteCardConfirmed,
  editCard,
  skipCardRegistration,
} from '~features/registration/registration.actions';
import {
  currentProvider,
  currentRegistration,
} from '~features/registration/registration.selectors';
import { driveToVendor, vendorDetailViewed, vendorsViewed } from '~features/vendors/vendors.state';
import { appInitialized, hardReset, initializationCompleted, softReset } from '../app/app.actions';
import { driveToAppointment, loadAppointmentForCard } from '../appointments/appointments.state';
import { allCategories } from '../categories/categories.state';
import {
  functionProtocolButtonActivated,
  predefinedButtonActivated,
  protocolButtonActivated,
  shortCodeButtonActivated,
  webButtonActivated,
} from '../home/home.state';
import { productStatusComputed, selectProducts, setProductFilter } from '../products/product.state';
import {
  selectLanguage,
  startEditingUserSettings,
  userSettings,
  userSettingsChanged,
} from '../settings/settings.state';

import { submitMissingProductReport } from '~features/missing-product/missing-product.state';
import { State } from '../state';
import { allSubCategories } from '../subcategories/subcategories.state';
import { barcodeScanReceived, keyEnterUPC } from '../upc/upc.state';
import { pageView } from './analytics.actions';
import { WICShopperEvents } from './analytics.facade';
import { AnalyticsService } from './analytics.service';
import { openExternalBrowser } from '~features/ionic/ionic.actions';

const TAGS = ['Effects', 'Analytics'];

@Injectable()
export class AnalyticsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private analytics: AnalyticsService,
    private log: LogService,
    private platform: Platform
  ) {}

  enableAnalytics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appInitialized),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.debug(TAGS, 'Enabling Firebase Analytics...')),
        switchMap(() => this.analytics.enable()),
        tap(
          () => this.log.debug(TAGS, 'Firebase Analytics enabled!'),
          err => this.log.error(TAGS, 'Error enabling Firebase Analytics!', err)
        ),
        catchError(err => of({}))
      ),
    { dispatch: false }
  );

  updateUserProperties$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          initializationCompleted,
          cardRegistrationCompleted,
          skipCardRegistration,
          userSettingsChanged
        ),
        filter(() => this.platform.is('capacitor')),
        tap(action =>
          this.log.trace(
            TAGS,
            `Updating user properties for Firebase Analytics. (action: ${action.type})...`
          )
        ),
        withLatestFrom(
          this.store.select(currentRegistration).pipe(
            timeout(2000),
            catchError(() => of(null))
          ),
          this.store.select(userSettings).pipe(
            timeout(2000),
            catchError(() => of(null))
          )
        ),
        switchMap(([, registration, settings]) =>
          from(
            generateParticipantToken(
              registration && registration.currentCard && registration.currentCard.cardNumber
            )
          ).pipe(map(token => ({ token, registration, settings })))
        ),
        switchMap(({ token, registration, settings }) =>
          from(
            this.analytics.registerUserProperties(
              {
                name: 'authority',
                value:
                  '' +
                  stringOrNull(
                    !!registration && !!registration.authority,
                    () => registration.authority.name
                  ),
              },
              {
                name: 'authorityId',
                value:
                  '' +
                  numberOrNull(
                    !!registration && !!registration.authority,
                    () => registration.authority.id
                  ),
              },
              { name: 'participantToken', value: '' + token },
              {
                name: 'hasCard',
                value: '' + boolToString(registration && !!registration.currentCard),
              },
              {
                name: 'isMultiCard',
                value:
                  '' +
                  boolToString(registration && registration.cards && registration.cards.length > 1),
              },
              { name: 'lang', value: '' + ensureSettings(settings).lang }
            )
          ).pipe(
            tap(
              () => this.log.trace(TAGS, 'Finished setting Firebase Analytics user properties.'),
              err => this.log.error(TAGS, 'Error setting Firebase Analytics user properties:', err)
            )
          )
        ),
        tap({
          error: err =>
            this.log.error(TAGS, 'Error setting Firebase Analytics user properties:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackPageViews$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pageView),
        filter(() => this.platform.is('capacitor')),
        tap(({ pageName }) => this.log.info(TAGS, `Page View tracking requested: ${pageName}`)),
        switchMap(({ pageName, params }) =>
          from(this.analytics.setScreenName(pageName)).pipe(map(() => ({ pageName, params })))
        ),
        switchMap(({ params, pageName }) =>
          from(this.analytics.logEvent('screen_view_detail', { params: params?.join(',') })).pipe(
            map(() => pageName)
          )
        ),
        tap(
          pageName => this.log.trace(TAGS, `Page view tracking succeeded: ${pageName}`),
          error => this.log.error(TAGS, 'Error trying to log page view: ', error)
        ),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackAppUsed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializationCompleted),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking app usage...')),
        tap(() =>
          this.analytics.logEvent(WICShopperEvents.AppUsed, {
            launchedAt: format(Date.now(), "yyyy-MM-dd'T'HH:mm:ss"),
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking app usage event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackWebLinkActivated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(webButtonActivated),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking web page view...')),
        tap(({ button }) =>
          this.analytics.logEvent(WICShopperEvents.ViewPage, {
            label: button.label,
            url: button.linkUrl,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking web page view event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackRegisterCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cardRegistrationCompleted),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking card registration...')),
        withLatestFrom(
          this.store.select(currentRegistration).pipe(
            timeout(2000),
            catchError(() => of(null))
          )
        ),
        tap(([, registration]) =>
          this.analytics.logEvent(WICShopperEvents.Register, {
            authority: stringOrNull(
              !!registration && !!registration.authority,
              () => registration.authority.name
            ),
            authorityId: numberOrNull(
              !!registration && !!registration.authority,
              () => registration.authority.id
            ),
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking card registration event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackSkipRegistration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(skipCardRegistration),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking skip registration...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.SkipRegistration)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking skip registration event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackAddCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addCardRegistration),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking add card...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.AddCard)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking add card event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackEditCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editCard),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking edit card...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.EditCard)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking app usage event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackRemoveCard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteCardConfirmed),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking remove card...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.RemoveCard)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking remove card event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackBarcodeScanned$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(barcodeScanReceived),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking scan barcode...')),
        tap(({ content }) =>
          this.analytics.logEvent(WICShopperEvents.ScanProduct, {
            upc: content,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking scan barcode event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackSearchUPC$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(keyEnterUPC),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking search upc...')),
        tap(({ upc }) =>
          this.analytics.logEvent(WICShopperEvents.SearchUPC, {
            upc,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking app usage event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackOpenBanner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openBannerLink),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking open banner...')),
        tap(({ banner }) =>
          this.analytics.logEvent(WICShopperEvents.OpenBanner, {
            url: banner.LinkURL,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking open banner event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewMicroBlog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(microBlogViewed),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view microblog...')),
        tap(({ microBlog }) =>
          this.analytics.logEvent(WICShopperEvents.ViewMicroBlog, {
            title: microBlog.title,
            type: microBlog.type,
            activationDate: microBlog.activationDate,
            expirationDate: microBlog.expirationDate,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking view microblog event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackOpenCustomBenefitLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openCustomBenefitLink),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking open custom benefit link...')),
        tap(({ linkUrl }) =>
          this.analytics.logEvent(WICShopperEvents.OpenCustomBenefitLink, {
            url: linkUrl,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking open custom benefit link event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewBenefits$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(benefitsViewed),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view benefits...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.ViewBenefits)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking view benefits event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewBenefit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectProducts),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view benefit...')),
        withLatestFrom(this.store.select(allCategories), this.store.select(allSubCategories)),
        map(([{ categoryId, subCategoryId }, categories, subCategories]) => ({
          category: categories.find(cat => cat.categoryId === categoryId),
          subCategory: subCategories.find(
            cat => cat.categoryId === categoryId && cat.subCategoryId === subCategoryId
          ),
        })),
        tap(({ category, subCategory }) =>
          this.analytics.logEvent(WICShopperEvents.ViewBenefit, {
            categoryId: category.categoryId,
            subCategoryId: subCategory.subCategoryId,
            category: category.description,
            subCategory: subCategory.description,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking view benefit event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewFutureBenefits$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewFutureBenefits),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view future benefits...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.ViewFutureBenefits)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking view future benefits event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackSearchItems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setProductFilter),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking search items...')),
        tap(({ criteria }) =>
          this.analytics.logEvent(WICShopperEvents.SearchItems, {
            criteria: criteria || '',
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking search items event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productStatusComputed),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view item...')),
        tap(({ product }) =>
          this.analytics.logEvent(WICShopperEvents.ViewItem, {
            categoryId: product.categoryId,
            subCategoryId: product.subCategoryId,
            category: product.category,
            subCategory: product.subCategory,
            itemNumber: product.itemNumber,
            normalizedItemNumber: product.normalizedItemNumber,
            description: product.description,
            allowed: product.allowed,
            status: product.status,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking view item event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewAppointment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAppointmentForCard),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view appointment...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.ViewAppointment)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking view appointment event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackGetAppointmentDirections$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(driveToAppointment),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking get appointment directions...')),
        tap(({ appointment }) =>
          this.analytics.logEvent(WICShopperEvents.GetAppointmentDirections, {
            locationName: appointment.locationName,
            appointmentTime: appointment.appointmentTime,
            city: appointment.city,
            state: appointment.state,
            zip: appointment.zip,
          })
        ),
        tap({
          error: err =>
            this.log.error(TAGS, 'Error tracking get appointment directions event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewOffices$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(officesViewed),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view offices...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.ViewOffices)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking view offices event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewOffice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(officeDetailViewed),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view office...')),
        tap(({ office }) =>
          this.analytics.logEvent(WICShopperEvents.ViewOffice, {
            locationId: office.locationId,
            name: office.name,
            clinicGroup: office.clinicGroup,
            status: office.status,
            city: office.city,
            state: office.state,
            zip: office.zip,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking view office event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackGetOfficeDirections$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(driveToOffice),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking get office directions...')),
        tap(({ office }) =>
          this.analytics.logEvent(WICShopperEvents.GetOfficeDirections, {
            locationId: office.locationId,
            name: office.name,
            clinicGroup: office.clinicGroup,
            status: office.status,
            city: office.city,
            state: office.state,
            zip: office.zip,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking get office directions event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewVendors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorsViewed),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view vendors...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.ViewVendors)),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewVendor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorDetailViewed),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view vendor...')),
        tap(({ vendor }) =>
          this.analytics.logEvent(WICShopperEvents.ViewVendor, {
            entryId: vendor.entryId,
            name: vendor.name,
            groupCode: vendor.groupCode,
            city: vendor.city,
            state: vendor.state,
            zip: vendor.zip,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking view vendor event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackGetVendorDirections$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(driveToVendor),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking get vendor directions...')),
        tap(({ vendor }) =>
          this.analytics.logEvent(WICShopperEvents.GetVendorDirections, {
            entryId: vendor.entryId,
            name: vendor.name,
            groupCode: vendor.groupCode,
            city: vendor.city,
            state: vendor.state,
            zip: vendor.zip,
          })
        ),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking get vendor directions event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackViewSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startEditingUserSettings),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking view settings...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.ViewSettings)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking view settings event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackLanguageChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectLanguage),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking language changed...')),
        tap(({ lang }) => this.analytics.logEvent(WICShopperEvents.LanguageChanged, { lang })),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking language changed event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackSoftReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(softReset),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking soft reset...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.SoftReset)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking soft reset event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackHardReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hardReset),
        filter(() => this.platform.is('capacitor')),
        tap(() => this.log.trace(TAGS, 'Tracking hard reset...')),
        tap(() => this.analytics.logEvent(WICShopperEvents.HardReset)),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking hard reset event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackHomeButtonClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          protocolButtonActivated,
          functionProtocolButtonActivated,
          predefinedButtonActivated,
          shortCodeButtonActivated,
        ),
        withLatestFrom(this.store.select(currentProvider)),
        filter(() => this.platform.is('capacitor')),
        tap(([button]) => this.log.trace(TAGS, `Tracking ${button.button.name} click...`)),
        tap(([button, authority ]) => this.analytics.logEvent(WICShopperEvents.HomeButtonClick, {
          authorityId: authority.id,
          authorityName: authority.name,
          buttonName: button.button.name,
          linkUrl: button.button.linkUrl,
          linkId: button.button.linkId,
        })),
        tap({
          error: err => this.log.error(TAGS, `Error tracking home button click event:`, err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackOpenExternalBrowser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openExternalBrowser),
        withLatestFrom(this.store.select(currentProvider)),
        filter(() => this.platform.is('capacitor')),
        tap(([url]) => this.log.trace(TAGS, `Tracking open external browser with url: ${url}`)),
        tap(([url, authority]) => this.analytics.logEvent(WICShopperEvents.OpenExternalBrowser, {
          authorityId: authority.id,
          authorityName: authority.name,
          url: url
        })),
        tap({
          error: err => this.log.error(TAGS, 'Error tracking open external browser event:', err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );

  trackICBTSubmit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(submitMissingProductReport),
        withLatestFrom(this.store.select(currentProvider)),
        filter(() => this.platform.is('capacitor')),
        tap(([missingProduct, authority]) =>
          this.analytics.logEvent(WICShopperEvents.ICBTSubmit, {
            authorityId: authority.id,
            authorityName: authority.name,
            upc: missingProduct.missing.upc
          })
        ),
        tap({
          error: err => this.log.error(TAGS, `Error tracking ICBT submit event:`, err),
        }),
        catchError(() => EMPTY)
      ),
    { dispatch: false, resubscribeOnError: true }
  );
}
