import { Authority } from './../../../features/authorities/models/authority';
import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'wic-cvv-calculator-commands',
  templateUrl: './cvv-calculator-commands.component.html',
  styleUrls: ['./cvv-calculator-commands.component.scss'],
})
export class CvvCalculatorCommandsComponent {
  @Input() authority: Authority;
  @Input() balanceEmpty: boolean;
  @Output() added = new EventEmitter();
  @Output() balanceSet = new EventEmitter();
}
