<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false)">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'log-detail-modal.title' | transloco}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-list *ngIf="!!entry">
    <ion-item>
      <ion-label>
        <h2>{{entry.timestamp | date:'hh:mm:ss'}}</h2>
        <p>
          <ion-text color="medium">{{'log-detail-modal.timestampLabel'}}</ion-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>
        <h2><ion-badge slot="start" [color]="entry.level | levelToColor">{{entry.level}}</ion-badge></h2>
        <p>
          <ion-text color="medium">{{'log-detail-modal.logLevelLabel'}}</ion-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-label>
        <h2><span *ngFor="let tag of entry.tags"><ion-badge color="light">{{tag}}</ion-badge>&nbsp;</span></h2>
        <p>
          <ion-text color="medium">{{'log-detail-modal.tagsLavel'}}</ion-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>

  <p class="m-15" *ngIf="entry?.message">{{entry.message | json}}</p>
</ion-content>
