import { NgModule } from '@angular/core';
import { AppointmentNotificationsService } from '~features/notifications/appointment-notifications.service';
import { BenefitNotificationsService } from '~features/notifications/benefit-notifications.service';
import { NotificationsService } from '~features/notifications/notifications.service';
import { NotificationRegistrationService } from './notification-registration.service';
import { NotificationDetailsService } from './notification-details.service';


@NgModule({
  providers: [
    NotificationsService,
    NotificationDetailsService,
    NotificationRegistrationService,
    AppointmentNotificationsService,
    BenefitNotificationsService,
  ]
})
export class NotificationsModule {
}
