import { IEntityOptions } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import * as fuzzy from 'fuzzy';
import { GroupCode, GroupCodeFilterOption, Vendor, VendorFavorite } from '~features/vendors/models';
import { allVendors, getVendorState, IVendorState } from '~features/vendors/vendors.state';
import { LocationSearchCriteria } from '~models';
import { filter } from 'rxjs/operators';

export const getFilterCriteria = (state: IVendorState): string =>
  state.filterCriteria;

export const getSearchCriteria = (state: IVendorState): LocationSearchCriteria =>
  state.searchCriteria;

export const mapToSearchedVendors = (criteria: LocationSearchCriteria, vendors: Vendor[], favorites: VendorFavorite[]): Vendor[] =>
  vendors.filter(vendor =>
    (criteria.anywhere || vendor.distance <= criteria.radius) &&
    (!criteria.groupCodeFilter || criteria.groupCodeFilter === vendor.groupCode) &&
    (criteria.onlyFavorites ? favorites.some(favorite => favorite.entryId === vendor.entryId) : true)
  );

export const filterVendorsByCriteria = (vendors: Vendor[], criteria: string): Vendor[] =>
  !!vendors && !!criteria
    ? fuzzy.filter(criteria, vendors, {
      extract: vendor => `${vendor.name} ${vendor.address1} ${vendor.address2} ${vendor.city}`
    }).map(m => m.original)
    : vendors || [];

export const mapToSortedVendors = (vendors: Vendor[]): Vendor[] =>
  vendors.sort((Vendor['__nae_entity_opts'] as IEntityOptions).comparer); // eslint-disable-line

export const mapToFavoriteVendors = (state: IVendorState): VendorFavorite[] =>
  state.favorites;

export const getCountOfVendors = (vendors: Vendor[]): number =>
  vendors ? vendors.length : 0;

export const getHasVendors = (count: number): boolean =>
  !!count;

export const getClosestVendor = (vendors: Vendor[]): Vendor =>
    vendors.length ? vendors?.reduce((prev, curr) => prev.distance < curr.distance ? prev : curr) : null;

export const mapToError = (state: IVendorState): any =>
  state.error;

export const groupCodesToLabel = {
  [GroupCode.None]: { label: 'vendors.anyTypeLabel' },
  [GroupCode.eWic]: { label: 'vendors.ewicTypeLabel' },
  [GroupCode.Vouchers]: { label: '' },
  [GroupCode.Farmers]: { label: 'vendors.farmersTypeLabel' },
  [GroupCode.Pharmacy]: { label: 'vendors.pharmacyTypeLabel' },
  [GroupCode.SelfCheckout]: { label: '' },
  [GroupCode.Mobile]: { label: 'vendors.mobileTypeLabel' },
  [GroupCode.Commissary]: { label: 'vendors.commissaryTypeLabel' },
  [GroupCode.FarmStand]: { label: 'vendors.farmStandTypeLabel' },
  [GroupCode.Honey]: { label: 'vendors.honeyTypeLabel' },
}

export const groupCodesToColor = {
  [GroupCode.None]: { color: 'med-gray' },
  [GroupCode.eWic]: { color: 'green' },
  [GroupCode.Vouchers]: { color: '' },
  [GroupCode.Farmers]: { color: 'yellow' },
  [GroupCode.Pharmacy]: { color: 'tufts-blue' },
  [GroupCode.SelfCheckout]: { color: '' },
  [GroupCode.Mobile]: { color: 'tufts-blue' },
  [GroupCode.Commissary]: { color: 'tufts-blue' },
  [GroupCode.FarmStand]: { color: 'tufts-blue' },
  [GroupCode.Honey]: { color: 'tufts-blue' },
}

export const checkMapForGroupCodeLabel = (code: GroupCode) =>
  !!groupCodesToLabel[code]?.label;

export const getUniqueGroupCodes = (vendors: Vendor[]): GroupCode[] =>
   Array.from(new Set<GroupCode>(vendors?.map((vendor) => vendor.groupCode) || []));


export const uniqueGroupCodes = createSelector(
  allVendors,
  getUniqueGroupCodes
);

export const groupCodeFilterOptions = createSelector(
  uniqueGroupCodes,
  (codes) => codes.filter(code => checkMapForGroupCodeLabel(code))
    .map((groupCode): GroupCodeFilterOption => ({
      code: groupCode,
      label: groupCodesToLabel[groupCode].label,
      color: groupCodesToColor[groupCode].color
    })
  )
);

export const favoriteVendors = createSelector(
  getVendorState,
  mapToFavoriteVendors
);

export const filterCriteria = createSelector(
  getVendorState,
  getFilterCriteria
);

export const searchCriteria = createSelector(
  getVendorState,
  getSearchCriteria
);

export const searchedVendors = createSelector(
  searchCriteria,
  allVendors,
  favoriteVendors,
  mapToSearchedVendors
);

export const filteredVendors = createSelector(
  allVendors,
  filterCriteria,
  filterVendorsByCriteria
);

export const sortedFilteredVendors = createSelector(
  filteredVendors,
  mapToSortedVendors
);

export const filteredSearchedVendors = createSelector(
  searchedVendors,
  filterCriteria,
  filterVendorsByCriteria
);

export const sortedFilteredSearchedVendors = createSelector(
  filteredSearchedVendors,
  mapToSortedVendors
);

export const totalSortedFilteredSearchedVendors = createSelector(
  sortedFilteredSearchedVendors,
  getCountOfVendors
);

export const hasSortedFilteredSearchedVendors = createSelector(
  totalSortedFilteredSearchedVendors,
  getHasVendors
);

export const sortedFilteredSearchedVendorsWithInventory = createSelector(
  sortedFilteredSearchedVendors,
  (vendors) => vendors.filter(vendor => vendor.hasActiveInventory)
)

export const totalSortedFilteredSearchedVendorsWithInventory = createSelector(
  sortedFilteredSearchedVendorsWithInventory,
  getCountOfVendors
);

export const hasSortedFilteredSearchedVendorsWithInventory = createSelector(
  totalSortedFilteredSearchedVendorsWithInventory,
  getHasVendors
);

export const vendorError = createSelector(
  getVendorState,
  mapToError
);

export const closestVendor = createSelector(
  allVendors,
  getClosestVendor
);

export const vendorUserLocation = createSelector(
  getVendorState,
  state => state.location
);
