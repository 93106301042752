import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Appointment } from '~features/appointments/models';

@Component({
  selector: 'wic-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss'],
})
export class AppointmentDetailComponent {
  @Input() appointment: Appointment;
  @Output() directions = new EventEmitter<Appointment>();
  @Output() showDetails = new EventEmitter<Appointment>();
}
