import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LogService } from '~core/services/log.service';

import { HostsSettingsMode } from '~models';
import { AdminToolsModalComponent } from '../../components/admin-tools-modal/admin-tools-modal.component';
import { DebugSettingsModalComponent } from '../../components/debug-settings-modal/debug-settings-modal.component';

import { DebuggingModalComponent } from '../../components/debugging-modal/debugging-modal.component';
// eslint-disable-next-line max-len
import { EnhancedModeRegistrationModalComponent } from '../../components/enhanced-mode-registration-modal/enhanced-mode-registration-modal.component';
import { HostSettingsModalComponent } from '../../components/host-settings-modal/host-settings-modal.component';
// eslint-disable-next-line max-len
import { NotificationInvestigatorModalComponent } from '../../components/notification-investigator-modal/notification-investigator-modal.component';
import { EnhancedDevice } from './models';


const TAGS = ['Service', 'Enhanced Mode'];

@Injectable()
export class EnhancedModeService {
  constructor(private modals: ModalController, private log: LogService) {
  }

  async showRegistrationResult(registration: EnhancedDevice) {
    const modal = await this.modals.create({
      component: EnhancedModeRegistrationModalComponent,
      componentProps: {
        registration
      }
    });
    await modal.present();
    await modal.onDidDismiss();
  }

  async showDebugging(): Promise<boolean> {
    const modal = await this.modals.create({
      component: DebuggingModalComponent
    });
    await modal.present();
    const result = await modal.onDidDismiss();
    this.log.trace(TAGS, 'Debugging modal dismissed', result);
    return true;
  }

  async showDebugSettings() {
    const modal = await this.modals.create({
      component: DebugSettingsModalComponent
    });
    await modal.present();
    const result = await modal.onDidDismiss();
    this.log.trace(TAGS, 'Debug settings modal dismissed', result);
    return true;
  }

  async showHostSettings(mode: HostsSettingsMode) {
    const modal = await this.modals.create({
      component: HostSettingsModalComponent,
      componentProps: {
        mode
      }
    });
    await modal.present();
    const result = await modal.onDidDismiss();
    this.log.trace(TAGS, 'Host settings modal dismissed', result);
    return true;
  }

  async showNotificationInvestigator() {
    const modal = await this.modals.create({
      component: NotificationInvestigatorModalComponent,
    });
    await modal.present();
    const result = await modal.onDidDismiss();
    this.log.trace(TAGS, 'Notification Investigator modal dismissed', result);
    return true;
  }

  async showAdminTools() {
    const modal = await this.modals.create({
      component: AdminToolsModalComponent
    });
    await modal.present();
    const result = await modal.onDidDismiss();
    this.log.trace(TAGS, 'Admin tools modal dismissed', result);
    return true;
  }
}
