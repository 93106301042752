import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { ShoppingListItem } from './shopping-list-item.entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { editShoppingListItemQuantity, submitShoppingListItemQuantityEdit } from './shopping-list-items.actions';
import { Product } from '~features/products/models';
import { addItemToShoppingList } from '~features/shopping-lists/shopping-lists.actions';

export interface ShoppingListItemsState extends IEntityState<ShoppingListItem> {
  quantityEditId?: string;
  currentProduct?: Product;
}

export const {
  actions: {
    createSuccess: createShoppingListItemSuccess,
    updateSuccess: updateShoppingListItemSuccess,
    updateManySuccess: updateManyShoppingListItemsSuccess,
    deleteByKeySuccess: deleteShoppingListItemByKeySuccess,
    deleteManySuccess: deleteManyShoppingListItemsSuccess,
    select: selectShoppingListItem
  },
  selectors: {
    selectAll: allShoppingListItems,
    selectEntities: shoppingListItemEntities,
    selectCurrentEntity: currentShoppingListItem,
    selectCurrentEntityKey: currentShoppingListItemKey
  },
  initialState: initialShoppingListItemsState,
  facade: ShoppingListItemsFacadeBase
} = buildState(ShoppingListItem, { quantityEditId: null } as ShoppingListItemsState);

export const shoppingListItemsReducer = createReducer(
  initialShoppingListItemsState,
  on(editShoppingListItemQuantity, (state, { id }) => ({ ...state, quantityEditId: id })),
  on(addItemToShoppingList, (state, { product }) => ({ ...state, currentProduct: product })),
  on(deleteShoppingListItemByKeySuccess, updateShoppingListItemSuccess, ({ quantityEditId, ...state}) => ({ ...state })),
);

export const SHOPPING_LIST_ITEMS_FEATURE_KEY = 'shoppingListItem';

export const shoppingListItemsState = createFeatureSelector<ShoppingListItemsState>(SHOPPING_LIST_ITEMS_FEATURE_KEY);
