<ion-card color="white" data-cy="appointment-warning">
  <ion-card-header translucent mode="ios">
    <ion-card-subtitle>{{ 'common.missingData' | transloco }}</ion-card-subtitle>
    <ion-card-title>
      {{ 'appointments.noAppointmentTitle' | transloco }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <p>{{ 'appointments.noAppointmentWarning' | transloco }}</p>
        </ion-col>
      </ion-row>
      <ion-row class="pt-20">
        <ion-col>
          <ion-button color="appointments" expand="block" (click)="retry.emit()" data-cy="retry-button">
            <ion-icon name="refresh" class="pr-5"></ion-icon>
            {{'appointments.retryButton' | transloco}}
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row class="pt-20">
        <ion-col>
          <ion-button color="appointments" expand="block" (click)="changeMember.emit()" data-cy="change-member-button">
            <fa-icon icon="id-card" class="pr-5"></fa-icon>
            {{'appointments.changeMemberButton' | transloco}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
