import { Component, OnInit } from '@angular/core';
import { ShoppingListsFacade } from '~features/shopping-lists/shopping-lists.facade';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'wic-select-benefit-subcategory-modal',
  templateUrl: './select-benefit-subcategory-modal.component.html',
  styleUrls: ['./select-benefit-subcategory-modal.component.scss'],
})
export class SelectBenefitSubcategoryModalComponent {
  constructor(public modals: ModalController, public readonly shoppingLists: ShoppingListsFacade) {}

  trackByCategory(index: number, subCat: { categoryId: number; subCategoryId: number }) {
    return `${subCat.categoryId}_${subCat.subCategoryId}`;
  }
}
