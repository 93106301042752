import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distanceGrade'
})
export class DistanceGradePipe implements PipeTransform {
  transform(distance: number, near: number = 3, far: number = 9): any {
    return !distance
      ? 'dist-far'
      : distance <= near ? 'dist-near' : distance <= far ? 'dist-far' : 'dist-very-far';
  }
}
