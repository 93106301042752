<ion-header mode="ios">
  <ion-toolbar color="independence" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button [disabled]="benefits.isLoading$ | async"></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{'manage-cards.contentTitle' | transloco}}</h3>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="home.goHome()" [disabled]="benefits.isLoading$ | async" data-cy="home-button">
        <ion-icon name="home"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content fullscreen>
  <ng-container *ngIf="!registration || (registration.hasCurrentCard$ | async) === false; else registered">
    <wic-no-cards-warning
    [hasCard]="registration.hasCurrentCard$ | async"
    [hasCards]="registration.hasCards$ | async">
    <ng-container ngProjectAs="[slot=info]">
      <p>{{ 'benefits.noCardsRegisteredInfo' | transloco }}</p>
    </ng-container>
      <ng-container ngProjectAs="[slot=buttons]">
        <ion-row>
        <ion-col>
          <ion-button (click)="registration.addCard()" expand="block" class="btn-green" [disabled]="benefits.isLoading$ | async" data-cy="add-card-button">
            <fa-stack>
              <fa-icon icon="credit-card" stackItemSize="1x"></fa-icon>
              <fa-icon icon="plus" stackItemSize="1x" transform="shrink-4 right-8 down-10" style="color: dimgray"></fa-icon>
              <fa-icon icon="plus" stackItemSize="1x" transform="shrink-4 right-7 down-9" style="color: gold"></fa-icon>
            </fa-stack>
            <span class="ion-text-uppercase">{{'manage-cards.addButton' | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
      </ng-container>
    </wic-no-cards-warning>
  </ng-container>
  

  <ng-template #registered>
    <wic-registered-cards-list
      *ngIf="registration.hasCards$ | async"
      [cards]="registration.currentCards$ | async"
      [currentCard]="registration.currentCard$ | async"
      [isLoading]="benefits.isLoading$ | async"
      (edited)="registration.editCard($event)"
      (selected)="registration.selectCard($event)"
      (deleted)="registration.deleteCard($event)">
    </wic-registered-cards-list>
  </ng-template>
</ion-content>

<ion-footer translucent mode="ios">
  <ion-toolbar>
    <ion-grid>
      <ion-row *ngIf="!!registration && !!(registration.hasCurrentCard$ | async)">
        <ion-col>
          <ion-button (click)="registration.addCard()" expand="block" class="btn-green" [disabled]="benefits.isLoading$ | async" data-cy="add-card-button">
            <fa-stack>
              <fa-icon icon="credit-card" stackItemSize="1x"></fa-icon>
              <fa-icon icon="plus" stackItemSize="1x" transform="shrink-4 right-8 down-10" style="color: dimgray"></fa-icon>
              <fa-icon icon="plus" stackItemSize="1x" transform="shrink-4 right-7 down-9" style="color: gold"></fa-icon>
            </fa-stack>
            <span class="ion-text-uppercase">{{'manage-cards.addButton' | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!registration || (registration.hasCurrentCard$ | async) === false">
        <ion-col>
          <div class="ion-text-center text-muted">
            <ion-icon name="sad-outline"></ion-icon> {{'manage-cards.noCardsFound' | transloco}}
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
