import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Office } from '../offices/models';
import { State } from '../state';
import { OfficeAndFavorites } from './models/types';
import {
  favoriteOffices,
  filterCriteria,
  hasSortedFilteredSearchedOffices,
  officeError, officeUserLocation,
  searchCriteria,
  sortedFilteredSearchedOffices,
} from './offices.selectors';

import {
  configureOfficeSearch,
  driveToOffice,
  loadAllOffices,
  loadIfNeeded,
  officeDetailViewed,
  OfficeFacadeBase,
  officeFavoriteToggled,
  officesViewed,
  officeWebsiteViewed,
  setOfficesFilter
} from './offices.state';
import { Position } from '@capacitor/geolocation';

@Injectable()
export class OfficesFacade extends OfficeFacadeBase {
  filtered$ = this.store.select(sortedFilteredSearchedOffices);
  hasFiltered$ = this.store.select(hasSortedFilteredSearchedOffices);
  favorites$ = this.store.select(favoriteOffices);
  filterCriteria$ = this.store.select(filterCriteria);
  searchCriteria$ = this.store.select(searchCriteria);
  error$ = this.store.select(officeError);
  officeUserLocation$ = this.store.select(officeUserLocation);

  constructor(private store: Store<State>) {
    super(Office, store);
  }

  viewed(): void {
    this.store.dispatch(officesViewed());
  }

  configureSearch(): void {
    this.store.dispatch(configureOfficeSearch());
  }

  getDirections(office: Office): void {
    this.store.dispatch(driveToOffice({office, latitude: office.latitude, longitude: office.longitude}));
  }

  viewWebSite(office: Office): void {
    this.store.dispatch(officeWebsiteViewed({office}));
  }

  viewDetail({office, favorites}: OfficeAndFavorites): void {
    this.store.dispatch(officeDetailViewed({office, favorites}));
  }

  toggleFavorite({office, favorites}: OfficeAndFavorites): void {
    this.store.dispatch(officeFavoriteToggled({office, favorites}));
  }

  setFilter(criteria: string): void {
    this.store.dispatch(setOfficesFilter({criteria}));
  }

  loadIfNeeded(force = false) {
    this.store.dispatch(loadIfNeeded({ force }));
  }

  loadOffices(location: Position, id: string | number) {
    this.store.dispatch(loadAllOffices({
      criteria: {
        options: {
          location
        },
        parents: {
          authorities: id
        },
        version: 2
      }
    }));
  }
}
