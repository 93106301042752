import {createSelector} from '@ngrx/store';
import {Authority} from '~features/authorities/models';
import {Card, Registration} from '~features/registration/models';
import { IRegistrationState, registrationState } from '~features/registration/registration.state';
import {State} from '~features/state';

export const getRegistrationState = (state: State): IRegistrationState =>
  state.registration;

export const mapToCurrentProvider = (state: IRegistrationState): Authority =>
  state.authority;

export const mapToCurrentProviderId = (provider: Authority): string | number =>
  provider ? provider.id : '';

export const mapToCurrentProviderName = (provider: Authority): string =>
  provider ? (provider.name || provider.shortName) : '';

export const mapToIsProvider = (provider: Authority): boolean =>
  provider ? provider.isProvider : false;

export const mapToHasAppointmentReminders = (provider: Authority): boolean =>
  provider ? provider.hasApptReminders : false;

export const mapToHidesFutureBenefits = (provider: Authority): boolean =>
  provider ? provider.hideFutureBenefits : false;

export const mapToShowLastShoppingTrip = (provider: Authority): boolean =>
  provider ? provider.showLastShoppingTrip : false;

export const mapToShowLastImport = (provider: Authority): boolean =>
  provider ? (provider.showLastImport || provider.nonRealTimeBenefits) : false;

export const mapToNonRealTimeBenefits = (provider: Authority): boolean =>
  provider ? provider.nonRealTimeBenefits : false;

export const mapToEnableNewHomeScreen = (provider: Authority): boolean =>
  provider?.enableNewHomeScreen ?? false;

export const mapToIsOfflineOrVerifyOnly = (provider: Authority): boolean =>
  provider.isOfflineOrVerifyOnly;

export const mapToCurrentCards = (state: IRegistrationState): Card[] =>
  state.cards || [];

export const mapToHasCards = (cards: Card[]): boolean =>
  !!cards && !!cards.length;

export const mapToCurrentCard = (state: IRegistrationState): Card =>
  state.currentCard; // || null;

export const mapToHasCurrentCard = (card: Card): boolean =>
  !!card;

export const mapToNoCurrentCard = (card: Card): boolean =>
  !card;

export const mapToPendingCard = (state: IRegistrationState): Card | null =>
  state.pendingCard || null;

export const mapToMigrated = (state: IRegistrationState): Registration =>
  state.migratedRegistration;

export const mapToShowFutureBenefits = (noCurrCard: boolean, hideFutureBenes: boolean): boolean =>
  !(noCurrCard || hideFutureBenes);

export const currentRegistration = createSelector(registrationState, (state): Registration => state);
export const currentProvider = createSelector(registrationState, mapToCurrentProvider);
export const currentProviderId = createSelector(currentProvider, mapToCurrentProviderId);
export const currentProviderName = createSelector(currentProvider, mapToCurrentProviderName);
export const isProvider = createSelector(currentProvider, mapToIsProvider);
export const hasAppointmentReminders = createSelector(currentProvider, mapToHasAppointmentReminders);
export const hidesFutureBenefits = createSelector(currentProvider, mapToHidesFutureBenefits);
export const showLastShoppingTrip = createSelector(currentProvider, mapToShowLastShoppingTrip);
export const showLastImport = createSelector(currentProvider, mapToShowLastImport);
export const nonRealTimeBenefits = createSelector(currentProvider, mapToNonRealTimeBenefits);
export const enableNewHomeScreen = createSelector(currentProvider, mapToEnableNewHomeScreen);
export const isOfflineOrVerifyOnly = createSelector(currentProvider, mapToIsOfflineOrVerifyOnly);

export const currentCards = createSelector(registrationState, mapToCurrentCards);
export const hasCards = createSelector(currentCards, mapToHasCards);
export const currentCard = createSelector(registrationState, mapToCurrentCard);
export const hasCurrentCard = createSelector(currentCard, mapToHasCurrentCard);
export const noCurrentCard = createSelector(currentCard, mapToNoCurrentCard);
export const pendingCard = createSelector(registrationState, mapToPendingCard);

export const showFutureBenefits = createSelector(noCurrentCard, hidesFutureBenefits, mapToShowFutureBenefits);
export const migratedRegistration = createSelector(registrationState, mapToMigrated);
