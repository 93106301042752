import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShoppingListItem } from '~features/shopping-lists/shopping-list-items/shopping-list-item.entity';

@Component({
  selector: 'wic-warn-unavailable-items-modal',
  templateUrl: './warn-unavailable-items-modal.component.html',
  styleUrls: ['./warn-unavailable-items-modal.component.scss'],
})
export class WarnUnavailableItemsModalComponent {
  @Input() shoppingListItems: ShoppingListItem[]

  constructor(public modal: ModalController,) {}
}
