<ng-container *ngIf="!loading || redemptions.length; else skeletons">
  <div @fade class="redemptions-card-container">
    <ion-card *ngFor="let redemption of redemptions; trackBy: trackByRedemption">
      <ion-card-header>
        <ion-card-subtitle style="display: flex" class="ion-justify-content-between">
          <span>{{ redemption.redemptionDate | date : 'mediumDate' }}</span>
          <span>{{ redemption.redemptionDate | date : 'shortTime' }}</span>
        </ion-card-subtitle>
        <ion-card-title style="display: flex" class="ion-justify-content-between">
          <span>{{ redemption.vendorName }}</span>
          <span>
            <ion-text *ngIf="redemption.isVoided" color="danger">{{ 'redemptions.voided' | transloco }}</ion-text>
          </span>
        </ion-card-title>
      </ion-card-header>
      <ion-accordion-group>
        <ion-accordion
          *ngFor="let category of redemption.categories; trackBy: trackByCategory"
          [value]="category.categoryId"
        >
          <ion-item slot="header" lines="none">
            <ion-avatar slot="start">
              <ion-img [src]="category.config?.image"></ion-img>
            </ion-avatar>
            <div *ngIf="currentLanguage === 'en'">
              <ion-label>{{ category.description }}</ion-label>
            </div>
            <div *ngIf="currentLanguage !== 'en'">
              <ion-label>{{ 'subcategoryTranslations.' + category.categoryId + '.description' | translocoFallback: category.description | async }}</ion-label>
            </div>
          </ion-item>

          <ion-list slot="content">
            <ion-item *ngFor="let item of category.items" (click)="selected.emit(item)">
              <ion-label>

                <h3 *ngIf="category.categoryId !== 19 && category.categoryId !== 97">
                  {{ item.purchaseQuantity }} x {{ item.units }} {{ item.uom }}
                </h3>
                <h3 *ngIf="category.categoryId === 19 || category.categoryId === 97">
                  {{ item.purchaseQuantity | currency }}
                </h3>
                <p>{{ item.description }}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-accordion>
      </ion-accordion-group>
    </ion-card>

    <div @fade *ngIf="offsetMonths < 2" class="ion-text-center">
      <ion-button (click)="loadMore.emit()">
        {{ 'redemptions.loadMore' | transloco }}
        <ion-spinner *ngIf="loading" slot="end"></ion-spinner>
      </ion-button>
    </div>
  </div>
</ng-container>

<ng-template #skeletons>
  <div @fade class="redemptions-card-container">
    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-card-subtitle>
        <ion-card-title>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-card-title>
      </ion-card-header>
      <ion-accordion-group>
        <ion-accordion *ngFor="let i of [1, 2, 3]" [value]="i">
          <ion-item slot="header" lines="none">
            <ion-avatar slot="start">
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-avatar>
            <ion-label>
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-label>
          </ion-item>
        </ion-accordion>
      </ion-accordion-group>
    </ion-card>
  </div>
</ng-template>


