import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {APLMessage} from '~features/aplmessages/models';
import {Authority} from '~features/authorities/models';
import {EnrichedBenefit} from '~features/benefits/models';

import {EnrichedProduct} from '~features/products/models';
import {SubCatConfig} from '../../../features/subcat-configs/models';

const CALC_CATEGORIES: Array<{ id: number; flag: string }> = [
  {id: 5, flag: 'disableCerealCalc'},
  {id: 16, flag: 'disableGrainCalc'},
  {id: 19, flag: 'disableCVVCalc'},
  {id: 97, flag: 'disableCVVCalc'}
];

@Component({
  selector: 'wic-item-status',
  templateUrl: './item-status.component.html',
  styleUrls: ['./item-status.component.scss'],
})
export class ItemStatusComponent {
  @Input() authority: Authority;
  @Input() aplMessage: APLMessage;
  @Input() product: EnrichedProduct;
  @Input() benefit: EnrichedBenefit;
  @Input() config: SubCatConfig;
  @Input() currentLanguage: string;

  @Output() addedToCalc = new EventEmitter<EnrichedProduct>();
  @Output() viewCalc = new EventEmitter<EnrichedProduct>();
  @Output() cantBuyThis = new EventEmitter<EnrichedProduct>();
  @Output() messageViewed = new EventEmitter<APLMessage>();
  @Output() warningViewed = new EventEmitter();

  @ViewChild('wic', {static: true}) wic: TemplateRef<any>;
  @ViewChild('yes', {static: true}) yes: TemplateRef<any>;
  @ViewChild('cvv', {static: true}) cvv: TemplateRef<any>;
  @ViewChild('no', {static: true}) no: TemplateRef<any>;
  @ViewChild('norx', {static: true}) norx: TemplateRef<any>;
  @ViewChild('nowic', {static: true}) nowic: TemplateRef<any>;
  @ViewChild('unk', {static: true}) unk: TemplateRef<any>;
  @ViewChild('chk', {static: true}) chk: TemplateRef<any>;
  @ViewChild('indeterminate', {static: true}) indeterminate: TemplateRef<any>;

  @ViewChild('wicicon', {static: true}) wicicon: TemplateRef<any>;
  @ViewChild('yesicon', {static: true}) yesicon: TemplateRef<any>;
  @ViewChild('cvvicon', {static: true}) cvvicon: TemplateRef<any>;
  @ViewChild('noicon', {static: true}) noicon: TemplateRef<any>;
  @ViewChild('norxicon', {static: true}) norxicon: TemplateRef<any>;
  @ViewChild('nowicicon', {static: true}) nowicicon: TemplateRef<any>;
  @ViewChild('unkicon', {static: true}) unkicon: TemplateRef<any>;
  @ViewChild('chkicon', {static: true}) chkicon: TemplateRef<any>;
  @ViewChild('indeterminateicon', {static: true}) indeterminateicon: TemplateRef<any>;

  constructor(private sanitizer: DomSanitizer) {
  }

  createIconUrl(dataUrl): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(dataUrl);
  }

  getStatusTemplate(status: string): TemplateRef<any> {
    const templateRef = this[status];
    return templateRef || this.unk;
  }

  getStatusIconTemplate(status: string): TemplateRef<any> {
    const templateRef = this[status + 'icon'];
    return templateRef;
  }

  hasCalcs(item: EnrichedProduct) {
    return !!this.authority &&
      !!item &&
      CALC_CATEGORIES.some(({id, flag}) =>
        item.categoryId === id &&
        !this.authority[flag]
      ) &&
      (item.subCategoryId === 0 || item.purchaseIndicator);
  }
}
