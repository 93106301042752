<ion-list class="pt-10">
  <ion-item>
    <ion-thumbnail slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <h4>
      <ion-skeleton-text animated style="width: 10vh"></ion-skeleton-text>
    </h4>
  </ion-item>
  <ion-item>
    <ion-thumbnail slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <h4>
      <ion-skeleton-text animated style="width: 25vh"></ion-skeleton-text>
    </h4>
  </ion-item>
  <ion-item>
    <ion-thumbnail slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <h4>
      <ion-skeleton-text animated style="width: 20vh"></ion-skeleton-text>
    </h4>
  </ion-item>
  <ion-item>
    <ion-thumbnail slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <h4>
      <ion-skeleton-text animated style="width: 35vh"></ion-skeleton-text>
    </h4>
  </ion-item>
  <ion-item>
    <ion-thumbnail slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <h4>
      <ion-skeleton-text animated style="width: 30vh"></ion-skeleton-text>
    </h4>
  </ion-item>
  <ion-item>
    <ion-thumbnail slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <h4>
      <ion-skeleton-text animated style="width: 20vh"></ion-skeleton-text>
    </h4>
  </ion-item>
  <ion-item>
    <ion-thumbnail slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <h4>
      <ion-skeleton-text animated style="width: 8vh"></ion-skeleton-text>
    </h4>
  </ion-item>
  <ion-item>
    <ion-thumbnail slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <h4>
      <ion-skeleton-text animated style="width: 10vh"></ion-skeleton-text>
    </h4>
  </ion-item>
  <ion-item>
    <ion-thumbnail slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <h4>
      <ion-skeleton-text animated style="width: 9vh"></ion-skeleton-text>
    </h4>
  </ion-item>
  <ion-item>
    <ion-thumbnail slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-thumbnail>
    <h4>
      <ion-skeleton-text animated style="width: 30vh"></ion-skeleton-text>
    </h4>
  </ion-item>
</ion-list>
