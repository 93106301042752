import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { State } from '../state';
import { Vendor, VendorAndFavorites } from './models';
import { closestVendor, groupCodeFilterOptions,
  hasSortedFilteredSearchedVendors,
  hasSortedFilteredSearchedVendorsWithInventory,
  searchCriteria,
  sortedFilteredSearchedVendorsWithInventory,
  totalSortedFilteredSearchedVendorsWithInventory,
  vendorUserLocation,
} from './vendors.selectors';
import { loadAllVendors, loadVendors } from './vendors.state';

import {
  configureVendorSearch,
  driveToVendor,
  loadIfNeeded,
  setVendorsFilter,
  vendorDetailViewed,
  VendorFacadeBase,
  vendorFavoriteToggled,
  vendorsViewed
} from './vendors.state';
import {
  favoriteVendors,
  filterCriteria,
  sortedFilteredSearchedVendors,
  totalSortedFilteredSearchedVendors,
  vendorError
} from './vendors.selectors';
import { Position } from '@capacitor/geolocation';
import { combineLatest, Observable } from 'rxjs';

@Injectable()
export class VendorFacade extends VendorFacadeBase {
  filtered$ = this.store.select(sortedFilteredSearchedVendors);
  filteredCount$ = this.store.select(totalSortedFilteredSearchedVendors);
  hasFiltered$ = this.store.select(hasSortedFilteredSearchedVendors);
  filteredWithInventory$ = this.store.select(sortedFilteredSearchedVendorsWithInventory);
  filteredCountWithInventory$ = this.store.select(totalSortedFilteredSearchedVendorsWithInventory);
  hasFilteredWithInventory$ = this.store.select(hasSortedFilteredSearchedVendorsWithInventory);
  favorites$ = this.store.select(favoriteVendors);
  filterCriteria$ = this.store.select(filterCriteria);
  searchCriteria$ = this.store.select(searchCriteria);
  error$ = this.store.select(vendorError);
  groupCodes$ = this.store.select(groupCodeFilterOptions);
  closest$ = this.store.select(closestVendor);
  vendorUserLocation$ = this.store.select(vendorUserLocation);


  get isLoadingOrEmpty$(): Observable<boolean> {
    return combineLatest([this.total$, this.error$, this.isLoading$]).pipe(
      map(([count, error, isLoading]) => (!count && !error) || isLoading)
    );
  }


  constructor(private store: Store<State>) {
    super(Vendor, store);
  }

  viewed(): void {
    this.store.dispatch(vendorsViewed());
  }

  configureSearch(): void {
    this.store.dispatch(configureVendorSearch());
  }

  getDirections(vendor: Vendor): void {
    this.store.dispatch(driveToVendor({vendor, latitude: vendor.latitude, longitude: vendor.longitude}));
  }

  viewDetail({vendor, favorites}: VendorAndFavorites): void {
    this.store.dispatch(vendorDetailViewed({vendor, favorites}));
  }

  toggleFavorite({vendor, favorites}: VendorAndFavorites): void {
    this.store.dispatch(vendorFavoriteToggled({vendor, favorites}));
  }

  setFilter(criteria: string): void {
    this.store.dispatch(setVendorsFilter({criteria}));
  }

  loadIfNeeded(force = false) {
    this.store.dispatch(loadIfNeeded({ force }));
  }

  loadVendors(location: Position, id: string | number) {
    this.store.dispatch(loadAllVendors({
      criteria: {
        options: {
          location
        },
        parents: {
          authorities: id
        }
      }
    }));
  }
}
