import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EntityService } from '~core/services/entity.service';
import { MobileOrder } from '~features/shopping-lists/mobile-orders/mobile-order.entity';
import { MobileOrderEffects } from './mobile-orders/mobile-order.effects';
import {
  mobileOrdersReducer,
  MOBILE_ORDERS_FEATURE_KEY,
} from './mobile-orders/mobile-orders.state';
import { ShoppingListItemsEffects } from './shopping-list-items/shopping-list-items.effects';
import { ShoppingListItemsFacade } from './shopping-list-items/shopping-list-items.facade';
import {
  shoppingListItemsReducer,
  SHOPPING_LIST_ITEMS_FEATURE_KEY,
} from './shopping-list-items/shopping-list-items.state';
import { ShoppingListsUIService } from './shopping-lists-ui.service';
import { ShoppingListsEffects } from './shopping-lists.effects';
import { ShoppingListsFacade } from './shopping-lists.facade';
import { shoppingListsReducer, SHOPPING_LISTS_FEATURE_KEY } from './shopping-lists.state';
import { SubstitutionsEffects } from './substitutions/substitutions.effects';
import {
  substitutionsReducer,
  SUBSTITUTIONS_FEATURE_KEY,
} from './substitutions/substitutions.state';
import { SubstitutionsFacade } from './substitutions/substitutions.facade';

@NgModule({
  imports: [
    StoreModule.forFeature(SHOPPING_LISTS_FEATURE_KEY, shoppingListsReducer),
    StoreModule.forFeature(SHOPPING_LIST_ITEMS_FEATURE_KEY, shoppingListItemsReducer),
    StoreModule.forFeature(SUBSTITUTIONS_FEATURE_KEY, substitutionsReducer),
    StoreModule.forFeature(MOBILE_ORDERS_FEATURE_KEY, mobileOrdersReducer),
    EffectsModule.forFeature([
      ShoppingListsEffects,
      ShoppingListItemsEffects,
      MobileOrderEffects,
      SubstitutionsEffects,
    ]),
  ],
  providers: [
    ShoppingListsFacade,
    ShoppingListItemsFacade,
    SubstitutionsFacade,
    ShoppingListsUIService,
    { provide: MobileOrder, useClass: EntityService },
  ],
})
export class ShoppingListsStateModule {}
