import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from '../state';


import {
  functionProtocolButtonActivated,
  dynamicButtonActivated,
  HomeFacadeBase,
  homeMenu,
  homePageLoading,
  navigateHome,
  predefinedButtonActivated,
  protocolButtonActivated, homePageReloading
} from './home.state';
import { HomeButton, MenuButton } from './models';

@Injectable()
export class HomeFacade extends HomeFacadeBase {
  get menu$(): Observable<HomeButton[]> {
    return this.store.select(homeMenu);
  }

  get hasMenu$(): Observable<boolean> {
    return this.menu$.pipe(map(menu => !!menu && !!menu.length));
  }

  constructor(private store: Store<State>) {
    super(MenuButton, store);
  }

  loading() {
    this.store.dispatch(homePageLoading());
  }

  reload() {
    this.store.dispatch(homePageReloading());
  }

  activateButton(button: HomeButton) {
    if (!button) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    button.linkUrl.startsWith('wic:/')
      ? this.store.dispatch(protocolButtonActivated({button}))
      : button.linkUrl.startsWith('wic$:/')
      ? this.store.dispatch(functionProtocolButtonActivated({button}))
      : button.name === 'dynamic'
      ? this.store.dispatch(dynamicButtonActivated({button}))
      : this.store.dispatch(predefinedButtonActivated({button}));
  }

  goHome() {
    this.store.dispatch(navigateHome());
  }
}
