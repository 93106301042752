import { parseISO } from 'date-fns';

const extractPartialDate = (filename: string) => {
  const underscorePos = filename.indexOf('_');
  const dashPos = filename.indexOf('-');
  const startIndex = underscorePos === -1 ? dashPos : underscorePos;

  const extensionIndex = filename.indexOf('.') === -1 ? undefined : filename.indexOf('.');

  return filename.substring(startIndex + 1, extensionIndex);
};

/* Returns Date as ISO String */
export const extractDateFromFileName = (filename: string) => parseISO(extractPartialDate(filename));
