import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'wic-no-appointment-warning',
  templateUrl: './no-appointment-warning.component.html',
  styleUrls: ['./no-appointment-warning.component.scss'],
})
export class NoAppointmentWarningComponent {
  @Output() retry = new EventEmitter();
  @Output() changeMember = new EventEmitter();
}
