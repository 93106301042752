import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';

import { LocationSearchCriteria } from '../../../models/location-search-criteria';
import { OfficesFacade } from '~features/offices/offices.facade';

@Component({
  selector: 'wic-offices-search-modal',
  templateUrl: './offices-search-modal.component.html',
  styleUrls: ['./offices-search-modal.component.scss'],
})
export class OfficesSearchModalComponent implements OnInit {
  @Input() criteria: LocationSearchCriteria;

  form: UntypedFormGroup;

  constructor(
    public modal: ModalController, 
    private fb: UntypedFormBuilder, 
    private analytics: AnalyticsFacade,
    public offices: OfficesFacade) {
  }

  ngOnInit() {
    this.analytics.pageView('offices-search-settings');

    this.form = this.fb.group({
      radius: [5, [Validators.required, Validators.min(1), Validators.max(100)]],
      anywhere: [false],
      onlyFavorites: [false],
      highlightTopPick: [true]
    });

    this.form.patchValue(this.criteria || {});
  }
}
