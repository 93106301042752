import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, from } from 'rxjs';
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ConfigService } from '~core/config/config.service';
import { WebCacheService } from '~core/interceptors/web-cache.service';
import { EntityCriteria } from '~core/services/entity.service';
import { LogService } from '~core/services/log.service';
import { registrationRestored } from '~features/registration/registration.actions';
import { apiHost } from '~features/settings/settings.state';
import { State } from '~features/state';
import { homePageReloading } from '../home/home.state';
import { currentProviderId } from '../registration/registration.selectors';
import {
  authorityEntities,
  authorityRefreshed,
  loadAllAuthorities,
  loadAllAuthoritiesFailure,
  loadAllAuthoritiesSuccess,
  preloadAuthorities,
} from './authorities.state';

const TAGS = ['Effects', 'Authority'];

// Prevents Errors with wicshopper fetching authorities from prod api
// since /v2/authorities is identical to /v1/apps/1/authorities
export const resolveAuthorityCriteria = (appId: () => number) =>
  map(
    (): EntityCriteria =>
      appId() === 1 ? { version: 2 } : { deescalateError: true, parents: { apps: appId() } }
  );

@Injectable()
export class AuthoritiesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly config: ConfigService,
    private readonly store: Store<State>,
    private readonly log: LogService,
    private readonly webCache: WebCacheService
  ) {}

  preloadAuthorities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(preloadAuthorities),
      tap(() => this.log.debug(TAGS, 'Preloading authorities.')),
      resolveAuthorityCriteria(() => this.config.appId),
      map(criteria => loadAllAuthorities({ criteria }))
    )
  );

  reloadAuthorities$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(loadAllAuthoritiesSuccess), first()),
      this.actions$.pipe(ofType(registrationRestored), first()),
    ]).pipe(
      withLatestFrom(this.store.select(apiHost)),
      filter(([, host]) => host && host.includes('dev')),
      tap(() =>
        this.log.debug(
          TAGS,
          'Force-reloading authorities from proper environment on cloud settings loaded'
        )
      ),
      switchMap(() =>
        from(this.webCache.purge('authorities')).pipe(
          resolveAuthorityCriteria(() => this.config.appId),
          map(criteria => loadAllAuthorities({ criteria }))
        )
      ),
      first()
    )
  );

  reloadAuthorityOnMenuRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(homePageReloading),
      tap(() => this.log.debug(TAGS, 'Force-reloading authority from on menu reload')),
      switchMap(() =>
        from(this.webCache.purge('authorities')).pipe(
          resolveAuthorityCriteria(() => this.config.appId),
          map(criteria => loadAllAuthorities({ criteria }))
        )
      )
    )
  );

  retryAuthorityLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllAuthoritiesFailure),
      filter(({ criteria }) => !!criteria?.parents?.apps),
      map(() => loadAllAuthorities({ criteria: { version: 2 } }))
    )
  );

  refreshAuthority$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllAuthoritiesSuccess),
      tap(() => this.log.debug(TAGS, 'Authorities reloaded, refreshing')),
      withLatestFrom(this.store.select(authorityEntities), this.store.select(currentProviderId)),
      filter(([, entities, id]) => !!id),
      map(([action, entities, id]) => authorityRefreshed({ authority: entities[id] }))
    )
  );
}
