import { Component, OnInit } from '@angular/core';
import { LogService } from '~core/services/log.service';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { VendorFacade } from '~features/vendors/vendor.facade';
import { AppFacade } from '~features/app/app.facade';

const TAGS = ['Tab', 'Vendors', 'List'];

@Component({
  selector: 'wic-vendors-list-tab',
  templateUrl: './vendors-list-tab.component.html',
  styleUrls: ['./vendors-list-tab.component.scss'],
})
export class VendorsListTabComponent implements OnInit {
  constructor(
    public app: AppFacade,
    public vendors: VendorFacade,
    private analytics: AnalyticsFacade,
    private log: LogService
  ) {
    this.log.trace(TAGS, 'Constructing Vendors List Tab');
  }

  ngOnInit() {
    this.analytics.pageView('vendors-list-tab');
    this.vendors.loadIfNeeded();
  }
}
