<ion-item-divider>
  <ion-label>{{ groupLabel }}</ion-label>
</ion-item-divider>

<ion-item-sliding #itemSliding *ngFor="let shoppingList of shoppingLists">
  <ion-item (click)="selected.emit(shoppingList)" [detail]="true">
    <ion-label>{{ shoppingList.name }}</ion-label>

    <ion-button (click)="toggleItem(itemSliding); $event.stopPropagation()" slot="end" fill="clear" shape="round" color="medium" mode="md" class="item-open-button">
      <ion-icon slot="icon-only" name="ellipsis-vertical-outline"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-item-options side="end">
    <ion-item-option (click)="removed.emit(shoppingList); $event.stopPropagation();" color="danger">
      <ion-icon slot="icon-only" name="trash"></ion-icon>
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>
