<cdk-virtual-scroll-viewport itemSize="145" minBufferPx="870" maxBufferPx="1740" class="ion-content-scroll-host">
  <ion-item *cdkVirtualFor="let vendor of vendors; trackBy: trackVendorBy" data-cy="list-item">
    <ion-label (click)="selected.emit({ vendor: vendor, favorites: favorites })">
      <h1 class="store-name">
        <strong>{{ vendor.name }}</strong>

        <wic-vendor-icon [groupCode]="vendor.groupCode"></wic-vendor-icon>
      </h1>

      <p class="ion-padding-bottom">
        {{ vendor.address1 }}
        <br />
        <span *ngIf="vendor.address2">{{ vendor.address2 }} <br /></span>
        {{ vendor.city }}, {{ vendor.state }} {{ vendor.zip }}
      </p>

<!--      <h6 *ngIf="vendor.groupCode | shouldShowMessage">-->
<!--        <strong [class]="vendor.groupCode === 2 ? 'text-red' : 'text-green'">-->
<!--          {{ vendor.groupCode | groupCodedMessage | transloco }}-->
<!--        </strong>-->
<!--      </h6>-->

      <h4 class="mt-10 additional-info" [ngClass]="vendor.distance | distanceGrade">
        <span>
          <strong>
            {{ vendor.distance | prettyDistance }}
            {{ !!vendor.distance ? 'mi' : ('' | transloco) }}
          </strong>

          <ion-chip
            class="ion-margin-start"
            [outline]="true"
            [color]="vendor.hasActiveInventory ? 'success' : 'danger'"
          >
<!--            <ion-text>-->
<!--              {{-->
<!--                (vendor.hasActiveInventory-->
<!--                  ? 'vendors.hasActiveInventoryChip'-->
<!--                  : 'vendors.noActiveInventoryChip'-->
<!--                ) | transloco-->
<!--              }}-->
<!--            </ion-text>-->
            <ion-text>
              {{
                (vendor.hasActiveInventory
                  ? 'Has Inventory Info'
                  : 'No Inventory Info'
                ) | transloco
              }}
            </ion-text>
          </ion-chip>
        </span>

        <ion-button
          slot="end"
          fill="clear"
          shape="round"
          class="favorite-button"
          (click)="favoriteToggled.emit({ vendor: vendor, favorites: favorites }); $event.stopPropagation()"
        >
          <ion-icon
            *ngIf="vendor | isFavorite : favorites"
            slot="icon-only"
            name="heart"
            color="danger"
          ></ion-icon>
          <ion-icon
            *ngIf="!(vendor | isFavorite : favorites)"
            slot="icon-only"
            name="heart-outline"
            color="medium"
          ></ion-icon>
        </ion-button>
      </h4>
    </ion-label>
  </ion-item>
</cdk-virtual-scroll-viewport>
