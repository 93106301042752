<ion-grid *ngIf="product" data-cy="item-status">
  <ion-row>
    <ion-col class="product-status" [ngClass]="product.status | altCVVStyle">
      <ng-container [ngTemplateOutlet]="getStatusTemplate(product.status)"></ng-container>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col></ion-col>
  </ion-row>

  <ion-row>
    <ion-col class="product-status-icon">
      <ng-container [ngTemplateOutlet]="getStatusIconTemplate(product.status)"></ng-container>
    </ion-col>
    <ion-col
        *ngIf="product.status === 'wic' || product.status === 'yes' || product.status === 'cvv' || product.status === 'norx' || product.status === 'no'"
        [size]="6" class="product-image">
      <wic-product-image [product]="product" size="large"></wic-product-image>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col></ion-col>
  </ion-row>
  <ion-row *ngIf="product.status !== 'chk' && product.status !== 'nowic'">
    <ion-col class="product-upc" [ngClass]="product.status">
      <ion-icon name="barcode"></ion-icon>
      {{product.itemNumber}}
      <ion-icon name="barcode"></ion-icon>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="product.status !== 'indeterminate'">
    <ion-col class="product-description" [ngClass]="product.status">
      {{product.description || product.itemNumber}}
    </ion-col>
  </ion-row>
</ion-grid>

<ion-grid>
  <ion-row>
    <ion-col></ion-col>
  </ion-row>
  <!-- Add to Calculator Button -->
  <ion-row *ngIf="hasCalcs(product)">
    <ion-col>
      <ion-button expand="block" size="large" class="btn-add-calc"
                  (click)="addedToCalc.emit(product)"
                  [ngClass]="'btn-' + product.status"
                  data-cy="calculator-button">
        <fa-stack>
          <fa-icon icon="calculator" stackItemSize="1x" transform="grow-6" style="color: var(--white)"></fa-icon>
          <fa-icon icon="plus-circle" stackItemSize="1x" transform="shrink-2 right-10 down-10"
                   style="color: var(--yellow)"
                   *ngIf="product.status === 'cvv'"></fa-icon>
          <fa-icon icon="plus-circle" stackItemSize="1x" transform="shrink-2 right-10 down-10"
                   style="color: var(--green)"
                   *ngIf="product.status !== 'cvv'"></fa-icon>
        </fa-stack>
        {{'buttons.addToCalc' | transloco}}
      </ion-button>
    </ion-col>
  </ion-row>

  <!-- View Calculator Button -->
  <ion-row *ngIf="hasCalcs(product)">
    <ion-col>
      <ion-button expand="block" size="large" class="btn-add-calc"
                  (click)="viewCalc.emit(product)"
                  [ngClass]="'btn-' + product.status">
        <fa-icon icon="calculator" transform="grow-6 left-10" style="color: var(--white)"></fa-icon>
        {{'buttons.viewCalc' | transloco}}
      </ion-button>
    </ion-col>
  </ion-row>

  <!-- Cant Buy This Button -->
  <ion-row *ngIf="product?.status === 'nowic' && authority?.enableCantBuyThis">
    <ion-col>
      <ion-button expand="block" color="gold"
                  (click)="cantBuyThis.emit(product)">
        <fa-icon icon="hand-holding-medical" style="color: var(--white)"></fa-icon>
        <span class="ml-5">{{'item-detail.cantBuyThisButton' | transloco}}</span>
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="product?.status === 'nowic'">
    <ion-col>
      <ion-button class="button-padding" expand="block" size="large" color="green" (click)="warningViewed.emit($event)">
        {{'item-detail.warn-produce-button' | transloco}}
        <ion-icon slot="start" src="assets/images/product/healthy-food.svg"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="product.status === 'indeterminate'">
    <ion-col>
      <ion-item lines="none">
        <ion-label class="ion-text-wrap ion-text-center">
          <ion-text color="medium">
            {{ 'item-detail.explain-status-indeterminate' | transloco }}
          </ion-text>
        </ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>

<ng-template #wicicon>
  <fa-icon icon="check-square" size="7x" style="color: var(--green)"></fa-icon>
  <img class="apl-message-icon" *ngIf="aplMessage?.onDetail && aplMessage?.isActive"
       [src]="createIconUrl(aplMessage.icon)" (click)="messageViewed.emit(aplMessage)">
</ng-template>

<ng-template #yesicon>
  <fa-icon icon="check-square" size="7x" style="color: var(--green)"></fa-icon>
  <img class="apl-message-icon" *ngIf="aplMessage?.onDetail && aplMessage?.isActive"
       [src]="createIconUrl(aplMessage.icon)" (click)="messageViewed.emit(aplMessage)">
</ng-template>

<ng-template #cvvicon>
  <fa-icon icon="dollar-sign" mask="check-square" size="7x" transform="shrink-10 left-1.25 up-2.75"
           style="color: var(--yellow)"></fa-icon>
</ng-template>

<ng-template #norxicon>
  <fa-icon icon="exclamation-triangle" size="7x" style="color: var(--lava)"></fa-icon>
</ng-template>

<ng-template #noicon>
  <fa-icon icon="minus-circle" size="7x" style="color: var(--orange)"></fa-icon>
</ng-template>

<ng-template #nowicicon>
  <fa-layers size="9x">
    <fa-layers-text style="color: black; font-weight: bold" content="WIC" transform="shrink-10"></fa-layers-text>
    <fa-icon icon="ban" stackItemSize="1x" style="color: var(--dark-red)" transform="left-2 flip-h"></fa-icon>
  </fa-layers>
</ng-template>

<ng-template #chkicon>
  <fa-icon icon="search" size="7x" style="color: var(--med-grey)"></fa-icon>
</ng-template>

<ng-template #indeterminateicon>
  <fa-layers size="9x">
    <fa-icon icon="circle" stackItemSize="1x" style="color: var(--black)" transform="left-2"></fa-icon>
    <fa-icon icon="wifi" stackItemSize="1x" style="color: var(--light-grey)" transform="left-2 shrink-8"></fa-icon>
    <fa-icon icon="ban" stackItemSize="1x" style="color: var(--dark-red)" transform="left-2 flip-h"></fa-icon>
  </fa-layers>
</ng-template>

<ng-template #wic>
  {{'item-detail.status-wic' | transloco:{
  allowed: product.allowed,
  isProvider: authority.isProvider && product.allowed ? 'true' : 'false',
  qty: benefit ? benefit.availableQuantity : 0,
  uom: currentLanguage === 'en' ? product.uom : 'subcategoryTranslations.' + product.categoryId + '.' + product.subCategoryId + '.uom' | translocoFallback: product.uom | async,
  fractionalSize: product.size < product.packageSize ? 'true' : 'false'
} }}
</ng-template>

<ng-template #yes>
  <span class="text-all-caps">{{'item-detail.status-yes' | transloco:{
    allowed: product.allowed,
    isProvider: authority.isProvider && product.allowed ? 'true' : 'false',
    qty: benefit ? benefit.availableQuantity : 0,
    uom: currentLanguage === 'en' ? product.uom : 'subcategoryTranslations.' + product.categoryId + '.' + product.subCategoryId + '.uom' | translocoFallback: product.uom | async,
    fractionalSize: product.size < product.packageSize ? 'true' : 'false'
  } }}</span>
</ng-template>

<ng-template #cvv>
  <span class="text-all-caps">{{'item-detail.status-cvv' | transloco:{
    allowed: '$' + product.allowed,
    isProvider: authority.isProvider && product.allowed ? 'true' : 'false',
    qty: benefit ? benefit.availableQuantity : 0,
    uom: currentLanguage === 'en' ? product.uom : 'subcategoryTranslations.' + product.categoryId + '.' + product.subCategoryId + '.uom' | translocoFallback: product.uom | async,
    fractionalSize: product.size < product.packageSize ? 'true' : 'false'
  } }}</span>
</ng-template>

<ng-template #no>
  {{'item-detail.status-no' | transloco:{
  allowed: product.allowed,
  isProvider: authority.isProvider && product.allowed ? 'true' : 'false',
  qty: benefit ? benefit.availableQuantity : 0,
  uom: currentLanguage === 'en' ? product.uom : 'subcategoryTranslations.' + product.categoryId + '.' + product.subCategoryId + '.uom' | translocoFallback: product.uom | async,
  fractionalSize: product.size < product.packageSize ? 'true' : 'false'
} }}
</ng-template>

<ng-template #norx>
  {{'item-detail.status-norx' | transloco:{
  allowed: product.allowed,
  isProvider: authority.isProvider && product.allowed ? 'true' : 'false',
  qty: benefit ? benefit.availableQuantity : 0,
  uom: currentLanguage === 'en' ? product.uom : 'subcategoryTranslations.' + product.categoryId + '.' + product.subCategoryId + '.uom' | translocoFallback: product.uom | async,
  fractionalSize: product.size < product.packageSize ? 'true' : 'false'
} }}
</ng-template>

<ng-template #nowic>
  <span>
    {{'item-detail.status-nowic' | transloco:{
    allowed: product.allowed,
    isProvider: authority.isProvider && product.allowed ? 'true' : 'false',
    qty: benefit ? benefit.availableQuantity : 0,
    uom: currentLanguage === 'en' ? product.uom : 'subcategoryTranslations.' + product.categoryId + '.' + product.subCategoryId + '.uom' | translocoFallback: product.uom | async,
    fractionalSize: product.size < product.packageSize ? 'true' : 'false'
  } }}
  </span>
</ng-template>

<ng-template #unk>
  {{product.itemNumber}}
</ng-template>

<ng-template #chk>
  {{'item-detail.status-chk' | transloco}}
</ng-template>

<ng-template #indeterminate>
  {{ 'item-detail.status-indeterminate' | transloco }}
</ng-template>
