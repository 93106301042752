import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MobileOrderItemSubstitution } from './substitution.entity';
import { deleteSubstitutionByKeySuccess, SubstitutionsFacadeBase } from './substitutions.state';
import {
  currentSubstitutionType,
  substitutionsForCurrentShoppingListItem
} from '~features/shopping-lists/substitutions/substitutions.selectors';

@Injectable()
export class SubstitutionsFacade extends SubstitutionsFacadeBase {
  substitutionsForCurrentShoppingListItem$ = this.store.select(substitutionsForCurrentShoppingListItem);
  currentSubstitutionType$ = this.store.select(currentSubstitutionType);
  constructor(private store: Store) {
    super(MobileOrderItemSubstitution, store);
  }

  removeSubstitution(key: string) {
    this.store.dispatch(deleteSubstitutionByKeySuccess({ key }))
  }
}
