import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { formatISO } from 'date-fns';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DeviceService } from '~core/services/device.service';
import { LogService } from '~core/services/log.service';
import { initializationCompleted } from '~features/app/app.actions';
import { currentBenefits } from '~features/benefits/benefits.selectors';
import { productStatusComputed } from '~features/products/product.state';
import { cardRegistrationCompleted, skipCardRegistration } from '~features/registration/registration.actions';
import { currentProvider } from '~features/registration/registration.selectors';
import { LegacyReportingService } from '~features/reporting/legacy-reporting.service';
import { LegacyDeviceInfo } from '~features/reporting/models/legacy-device-info';
import { State } from '~features/state';

const TAGS = ['Effects', 'Reporting', 'Legacy'];

@Injectable()
export class LegacyReportingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private log: LogService,
    private device: DeviceService,
    private platform: Platform,
    private reporting: LegacyReportingService) {
  }

  logAppUsed$ = createEffect(
    () => this.actions$.pipe(
      ofType(initializationCompleted, cardRegistrationCompleted, skipCardRegistration),
      withLatestFrom(
        this.device.deviceInfo$,
        this.device.uniqueId$,
        this.store.select(currentProvider),
        of(this.platform.is('android') ? 1 : 2)
      ),
      filter(([, deviceInfo, deviceId, authority]) => !!deviceInfo && !!authority && !!authority.id),
      map(([, deviceInfo, deviceId, authority, type]): LegacyDeviceInfo => ({
        authorityId: authority.id,
        deviceId,
        type,
        os: deviceInfo.platform,
        model: deviceInfo.model,
        manufacturer: deviceInfo.manufacturer,
        extraDetails: deviceInfo.appVersion
      })),
      tap(info => this.log.debug(TAGS, `Registering device...`)),
      switchMap(info => this.reporting.registerDevice(info)),
      tap({
        error: err => this.log.error(TAGS, 'Error occurred logging device registration', err)
      }),
      catchError(() => EMPTY)
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  logViewItem$ = createEffect(
    () => this.actions$.pipe(
      ofType(productStatusComputed),
      withLatestFrom(
        this.store.select(currentProvider),
        this.store.select(currentBenefits)
      ),
      map(([{product}, authority, benefits]) => ({
        entryGUID: (benefits && benefits.entryGUID) || '00000000-0000-0000-0000-000000000000',
        queryDate: formatISO(Date.now()),
        authorityId: authority.id,
        categoryId: product.categoryId,
        subCategoryId: product.subCategoryId,
        itemNumber: product.itemNumber,
        status: product.status
      })),
      tap(activity => this.log.debug(TAGS, `Logging activity...`)),
      switchMap(activity => this.reporting.logActivity(activity)),
      tap({
        error: err => this.log.error(TAGS, 'Error occurred logging activity', err)
      }),
      catchError(() => EMPTY)
    ),
    {dispatch: false, resubscribeOnError: true}
  );
}
