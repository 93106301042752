import { Injectable } from '@angular/core';
import { deleteShoppingListItemByKeySuccess, ShoppingListItemsFacadeBase } from './shopping-list-items.state';
import { Store } from '@ngrx/store';
import { ShoppingListItem } from './shopping-list-item.entity';
import {
  editShoppingListItemQuantity,
  submitShoppingListItemQuantityEdit,
  toggleShoppingListItemCheckedOffStatus, uncheckAllShoppingListItems,
} from './shopping-list-items.actions';
import {
  customItemsForCurrentShoppingList,
  hasCheckedOffItem,
  quantityEditId,
  quantityEditItem,
} from './shopping-list-items.selectors';

@Injectable()
export class ShoppingListItemsFacade extends ShoppingListItemsFacadeBase {
  quantityEditId$ = this.store.select(quantityEditId);
  quantityEditItem$ = this.store.select(quantityEditItem);
  hasCheckedOffItem$ = this.store.select(hasCheckedOffItem);
  customItemsForCurrentShoppingList$ = this.store.select(customItemsForCurrentShoppingList);

  constructor(private store: Store) {
    super(ShoppingListItem, store);
  }

  removeShoppingListItem(key: string) {
    this.store.dispatch(deleteShoppingListItemByKeySuccess({ key }));
  }

  toggleShoppingListItemCheckedOffStatus(shoppingListItem: ShoppingListItem) {
    this.store.dispatch(toggleShoppingListItemCheckedOffStatus({ shoppingListItem }))
  }

  uncheckAllShoppingListItems() {
    this.store.dispatch(uncheckAllShoppingListItems());
  }

  editShoppingListItemQuantity(id: string) {
    this.store.dispatch(editShoppingListItemQuantity({ id }));
  }

  submitShoppingListItemQuantityEdit(purchaseQuantity: number) {
    this.store.dispatch(submitShoppingListItemQuantityEdit({ purchaseQuantity }))
  }
}
