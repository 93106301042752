import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { Vendor, VendorAndFavorites, VendorFavorite } from '~features/vendors/models';
import { Position } from '@capacitor/geolocation';

@Component({
  selector: 'wic-vendors-map',
  templateUrl: './vendors-map.component.html',
  styleUrls: ['./vendors-map.component.scss'],
})
export class VendorsMapComponent {
  @Input() vendors: Vendor[];
  @Input() favorites: VendorFavorite[];
  @Input() location: Position;
  @Input() userLocation: Position;
  @Output() selected = new EventEmitter<VendorAndFavorites>();
  @Output() geocoded = new EventEmitter();

  userIcon: google.maps.Symbol = {
    fillColor: '#8B0000',
    fillOpacity: 1,
    strokeColor: '#8B0000',
    scale: 0.06,
    // TODO: Check this?
    path: typeof faDotCircle.icon[4] === 'string' ? faDotCircle.icon[4] : faDotCircle.icon[4][0]
  };
}
