import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { searchCriteria } from '~features/offices/offices.selectors';
import { applyOfficeSearch, configureOfficeSearch, officeDetailViewed, returnToHome } from '~features/offices/offices.state';
import { State } from '~features/state';
import { OfficesService } from './offices.service';
import { NavController } from '@ionic/angular';
import { NativePermissionsService } from '~core/services/native-permissions.service';

@Injectable()
export class OfficesEffects {
  constructor(
    private actions$: Actions, 
    private store: Store<State>, 
    private log: LogService, 
    private offices: OfficesService,
    private navcontroller: NavController,
    private permissions: NativePermissionsService
  ) {}
  showSearch$ = createEffect(
    () => this.actions$.pipe(
      ofType(configureOfficeSearch),
      withLatestFrom(this.store.select(searchCriteria)),
      exhaustMap(([, currentCriteria]) =>
        from(this.offices.showSearch(currentCriteria)).pipe(
          map(criteria => applyOfficeSearch({criteria})),
          catchError(() => of(applyOfficeSearch({criteria: currentCriteria})))
        )
      )
    )
  );

  viewDetail$ = createEffect(
    () => this.actions$.pipe(
      ofType(officeDetailViewed),
      exhaustMap(({office, favorites}) =>
        from(this.offices.showDetailModal(office, favorites))
      )
    ),
    {dispatch: false}
  );

  returnToHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(returnToHome),
      tap(() => this.permissions.openLocationSettings()),
      tap(() => this.navcontroller.back())
    ), {dispatch: false}
  );
}
