import { NgModule } from '@angular/core';
import { BannersFacade } from '~features/banners/banners.facade';


@NgModule({
  providers: [
    BannersFacade
  ]
})
export class BannersModule {
}
