import { Injectable } from '@angular/core';
import { Clear, LoadAll } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { webLinkOpened } from '~features/app/app.actions';
import { benefitsViewed } from '~features/benefits/benefits.actions';
import { homePageLoading } from '~features/home/home.state';
import { currentProviderId } from '~features/registration/registration.selectors';
import { State } from '../state';
import { openBannerLink, resetBanners } from './banners.state';
import { Banner } from './models';

const TAGS = ['Effects', 'Banners'];

@Injectable()
export class BannersEffects {
  constructor(private actions$: Actions, private store: Store<State>, private log: LogService) {}

  loadBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(homePageLoading, benefitsViewed),
      withLatestFrom(this.store.select(currentProviderId)),
      filter(([, authorityId]) => !!authorityId),
      tap(([, authorityId]) => this.log.trace(TAGS, `Loading banners for authority ${authorityId}...`)),
      map(
        ([, authorityId]) =>
          new LoadAll(Banner, {
            parents: {
              authorities: authorityId,
            },
            version: 2,
          })
      )
    )
  );

  openBannerLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openBannerLink),
      tap(() => this.log.trace(TAGS, `Activating banner...`)),
      map(({ banner }) => webLinkOpened({ linkUrl: banner.LinkURL }))
    )
  );

  resetBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetBanners),
      map(() => new Clear(Banner))
    )
  );
}
