import { Injectable } from '@angular/core';
import { TextZoom } from '@capacitor/text-zoom';
import { LogService } from '~core/services/log.service';

const TAGS = ['Service', 'TextZoom'];

@Injectable()
export class TextZoomService {
  constructor(private log: LogService) {
  }

  // Replaces accessibility text zoom with browser default font size to make sure rem units scale properly.
  async adjustFontSize() {
    const current = await TextZoom.get();
    this.log.debug(TAGS, `[TextZoom] Current text zoom: ${current.value}`);
    const preferred = await TextZoom.getPreferred();
    this.log.debug(TAGS, `[TextZoom] Text zoom preferred result: ${preferred.value}`);
    const zoomLevel = preferred.value > current.value ? preferred.value : current.value;

    if (zoomLevel > 1) {
      this.log.debug(TAGS, `[TextZoom] Text zoom level is larger than standard. Adjusting default font-size...`);

      await TextZoom.set({ value: 1.0 });
      const fontSize = {
        1.3: '22px',
        1.2: '20px',
        1.1: '18px'
      }[zoomLevel] || '20px';
      this.log.debug(TAGS, `[TextZoom] New default font size: ${fontSize}`);

      document.documentElement.style['font-size'] = fontSize;
    }
  }
}
