import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Authority } from '~features/authorities/models';
import { DemandApptIdentityModalComponent } from '../../components/demand-appt-identity-modal/demand-appt-identity-modal.component';

export interface AppointmentRequest {
  hhid: boolean;
  cardNumber: string;
}

@Injectable()
export class AppointmentsService {
  constructor(private modals: ModalController) {}

  async showDemandAppointmentIdentity(authority: Authority): Promise<AppointmentRequest | null> {
    const modal = await this.modals.create({
      component: DemandApptIdentityModalComponent,
      componentProps: {
        authority,
      },
    });

    await modal.present();
    const result = await modal.onDidDismiss();
    return result.role === 'ok' ? result.data : null;
  }
}
