import { Product } from '~features/products/models';
import { Tracking } from '~features/products/models/tracking';

export class ProductsDbService {
  async initialize() {
    throw new Error('Not Implemented');
  }

  async createTable<T>(entity: new () => T): Promise<any>  {
    throw new Error('Not Implemented');
  }

  async removeTable<T>(entity: new () => T): Promise<any> {
    throw new Error('Not Implemented');
  }

  async getTableInfo<T>(entity: new () => T): Promise<any>  {
    throw new Error('Not Implemented');
  }

  async lookup(upc: string): Promise<Product[]> {
    throw new Error('Not Implemented');
  }

  async selectTotalCount(categoryId: number, subCategoryId: number): Promise<number> {
    throw new Error('Not Implemented');
  }

  async select(categoryId: number, subCategoryId: number, allowedCount: number): Promise<Product[]> {
    throw new Error('Not Implemented');
  }

  async selectAllTotalCount(): Promise<number> {
    throw new Error('Not Implemented');
  }
  
  async selectAll(): Promise<Product[]> {
    throw new Error('Not Implemented');
  }

  async updateProducts(categoryId: number, subCategoryId: number, products: Product[]): Promise<void> {
    throw new Error('Not Implemented');
  }

  async populateProducts(categoryId: number, subCategoryId: number, products: Product[]): Promise<void> {
    throw new Error('Not Implemented');
  }

  async clearProducts(): Promise<number> {
    throw new Error('Not Implemented');
  }

  async clearProductTracking(): Promise<number> {
    throw new Error('Not Implemented');
  }

  async getProductUpdate(categoryId: number, subCategoryId: number): Promise<Tracking> {
    throw new Error('Not Implemented');
  }

  async setProductUpdateTime(categoryId: number, subCategoryId: number, date: Date): Promise<Tracking> {
    throw new Error('Not Implemented');
  }

  async getAllProductUpdates(): Promise<Tracking[]> {
    throw new Error('Not Implemented');
  }
}
