import { Pipe, PipeTransform } from '@angular/core';
import { LogEntry, LoggingLevel } from '~core/services/log.service';

@Pipe({name: 'filterByLevel'})
export class FilterByLevelsPipe implements PipeTransform {
  transform(entries: LogEntry[], levels: string[]): LogEntry[] {
    return (!!levels && !!levels.length && !!entries)
      ? entries.filter(entry =>
        levels.some(level => entry.level === LoggingLevel[level])
      )
      : entries;
  }
}
