import { createAction } from '@ngrx/store';

export const checkNotificationPermissions = createAction('[Permissions] Check Notification Permissions');

export const notificationPermissionDemanded = createAction(
  '[Permissions] Notifications demanded'
);

export const explainNotificationDemand = createAction(
  '[Permissions] Explain Notification demand'
);
