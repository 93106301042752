import { Inject, Injectable, Optional } from '@angular/core';
import { TRANSLOCO_CONFIG, TranslocoConfig } from '@ngneat/transloco';
import {
  MessageformatConfig,
  MessageFormatTranspiler,
  TRANSLOCO_MESSAGE_FORMAT_CONFIG
} from '@ngneat/transloco-messageformat';
import { LogService } from '../services/log.service';

const TAGS = ['Transloco', 'Message Format'];

@Injectable()
export class CustomMessageFormatTranspiler extends MessageFormatTranspiler {
  constructor(
    private log: LogService,
    @Optional()
    @Inject(TRANSLOCO_MESSAGE_FORMAT_CONFIG)
      config: MessageformatConfig,
    @Optional() @Inject(TRANSLOCO_CONFIG) userConfig?: TranslocoConfig
  ) {
    super({}, userConfig);
  }

  override onLangChanged(lang: string) {
    try {
      super.onLangChanged(lang);
    } catch (e) {
      this.log.warn(TAGS, 'Locale Not Found. Using "en"', e);
      super.onLangChanged('en');
    }
  }
}
