import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { VendorsComponentsModule } from '../vendors/vendors-components.module';
import { AddItemFromPastPurchasesModalComponent } from './add-item-from-past-purchases-modal/add-item-from-past-purchases-modal.component';
import { AddItemFromPopoverComponent } from './add-item-from-popover/add-item-from-popover.component';
import { AddItemModalComponent } from './add-item-modal/add-item-modal.component';
import { OrderableProductListComponent } from './add-item-modal/orderable-product-list/orderable-product-list.component';
import { BenefitShoppingListTotalPipe } from './benefit-shopping-list-total.pipe';
import { ConfirmItemModalComponent } from './confirm-item-modal/confirm-item-modal.component';
import { IsBenefitFilledPipe } from './is-benefit-filled.pipe';
import { ItemEntryPopoverComponent } from './item-entry-popover/item-entry-popover.component';
import { SelectBenefitSubcategoryModalComponent } from './select-benefit-subcategory-modal/select-benefit-subcategory-modal.component';
import { SelectTimeSlotModalComponent } from './select-time-slot-modal/select-time-slot-modal.component';
import { SelectVendorModalComponent } from './select-vendor-modal/select-vendor-modal.component';
import { VendorListComponent } from './select-vendor-modal/vendor-list/vendor-list.component';
import { ShoppingListGroupComponent } from './shopping-list-group/shopping-list-group.component';
import { ShoppingListCustomItemsCardComponent } from './shopping-list-view/shopping-list-custom-items-card/shopping-list-custom-items-card.component';
import { ShoppingListItemGroupComponent } from './shopping-list-view/shopping-list-item-group/shopping-list-item-group.component';
import { ShoppingListViewPage } from './shopping-list-view/shopping-list-view.page';
import { ShoppingListsPage } from './shopping-lists.page';
import { SubstitutionInfoModalComponent } from './substitution-info-modal/substitution-info-modal.component';
import { TimeSlotHourFormatPipe } from './time-slot-hour-format.pipe';
import { WarnUnavailableItemsModalComponent } from './warn-unavailable-items-modal/warn-unavailable-items-modal.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ChunkArrayPipe } from './chunk-array.pipe';
import { MobileOrderItemGroupComponent } from './shopping-list-view/mobile-order-item-group/mobile-order-item-group.component';

const routes: Routes = [
  {
    path: '',
    component: ShoppingListsPage,
  },
  {
    path: ':listId',
    component: ShoppingListViewPage,
  },
];

@NgModule({
  declarations: [
    ShoppingListsPage,
    ShoppingListViewPage,

    AddItemModalComponent,
    ShoppingListItemGroupComponent,
    MobileOrderItemGroupComponent,

    SelectBenefitSubcategoryModalComponent,

    IsBenefitFilledPipe,
    BenefitShoppingListTotalPipe,
    TimeSlotHourFormatPipe,
    ChunkArrayPipe,

    AddItemFromPopoverComponent,
    AddItemFromPastPurchasesModalComponent,
    ItemEntryPopoverComponent,
    ShoppingListCustomItemsCardComponent,
    SelectVendorModalComponent,
    SubstitutionInfoModalComponent,
    VendorListComponent,
    WarnUnavailableItemsModalComponent,
    ShoppingListGroupComponent,
    SelectTimeSlotModalComponent,
    OrderableProductListComponent,
    ConfirmItemModalComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslocoModule,
    PipesModule,
    ComponentsModule,
    VendorsComponentsModule,
    FontAwesomeModule,
    ScrollingModule
  ],
})
export class ShoppingListsModule {}
