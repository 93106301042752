import { Injectable } from '@angular/core';

import { EnrichedProduct } from '../products/models';

export enum BarcodeType {
  UPC_E,
  UPC_A,
  GS1_DataBar
}

interface ParsedUPCInfoBase {
  upc: string;
  type: BarcodeType;
  subType?: string;
}

export interface UnknownUPCInfo {
  upc?: string;
  type: 'Unknown';
}

export interface ParsedUPCAInfo extends ParsedUPCInfoBase {
  type: BarcodeType.UPC_A;
  subType: 'Type2' | 'IFPS' | 'other' ;
}

export interface ParsedUPCEInfo extends ParsedUPCInfoBase {
  type: BarcodeType.UPC_E;
  // Less Sure on the subtypes here
  subType?: 'IFPS' | 'other' ;
}

export interface ParsedGS1Info extends ParsedUPCInfoBase {
  type: BarcodeType.GS1_DataBar;
  // Less Sure on the subtypes here
  subType?: 'IFPS' | 'other' ;
}

export type ParsedUPCInfo = ParsedUPCAInfo | ParsedUPCEInfo | ParsedGS1Info | UnknownUPCInfo;

export const isUPCAInfo = (upcInfo: ParsedUPCInfo): upcInfo is ParsedUPCAInfo =>
  upcInfo.type === BarcodeType.UPC_A;


export type RankedStatus = 'yes' | 'no' | 'cvv' | 'wic' | 'nowic' | 'norx' | 'unk';

export const pad = (value: string, length: number): string =>
  (value.length < length) ? pad('0' + value, length) : value;

export const getBarcodeType = (rawUPC: string): BarcodeType =>
  rawUPC.length === 8
    ? BarcodeType.UPC_E
    : rawUPC.length === 16 && rawUPC[0] === '0' && rawUPC[1] === '1'
    ? BarcodeType.GS1_DataBar
    : BarcodeType.UPC_A;

export const parseUPCE = (rawUPC: string): string => {
  let itemNumber = rawUPC;

  // convert the short code to a long code
  // get the inner 6 digits of the UPC-E code (ignore the leading digit and ending/check digit)
  const upcE = rawUPC.substr(1, 6);

  // determine how we will expand the barcode based on the last digit of the UPC-E code
  switch (upcE[5]) {
    case '0':   //  XXNNN0 -> XX00000NNN
      itemNumber = upcE.substr(0, 2) + '00000' + upcE.substr(2, 3);
      break;
    case '1':   //  XXNNN1 -> XX10000NNN
    case '2':   //  XXNNN2 -> XX20000NNN
      itemNumber = upcE.substr(0, 2) + upcE.substr(5, 1) + '0000' + upcE.substr(2, 3);
      break;
    case '3':   //  XXXNN3 -> XXX00000NN
      itemNumber = upcE.substr(0, 3) + '00000' + upcE.substr(3, 2);
      break;
    case '4':   //  XXXXN4 -> XXXX00000N
      itemNumber = upcE.substr(0, 4) + '00000' + upcE.substr(4, 1);
      break;
    case '5':   //  XXXXX5 -> XXXXX00005
    case '6':   //  XXXXX6 -> XXXXX00006
    case '7':   //  XXXXX7 -> XXXXX00007
    case '8':   //  XXXXX8 -> XXXXX00008
    case '9':   //  XXXXX9 -> XXXXX00009
      itemNumber = upcE.substr(0, 5) + '0000' + upcE.substr(5, 1);
      break;
    default:
      // invalid barcode - do nothing
      return itemNumber;
  }
  // append the check digit to the end of the expanded item number
  itemNumber += rawUPC[7];

  return itemNumber;
};

export const tryParseUPCE = (rawUPC: string): ParsedUPCEInfo =>
  rawUPC.length === 8 ? { type: BarcodeType.UPC_E, upc: parseUPCE(rawUPC), } : { type: BarcodeType.UPC_E, upc: rawUPC, subType: 'other' };

export const stripLeadingZeros = (rawUPC: string): string =>
  rawUPC.replace(/^0*(\d+)$/, '$1');

export const parseUPCType2 = (rawUPC: string): string =>
  // item number is a UPC (type 2), extract the item number
  // barcode format: 2NNNNNXXXXXC, where
  //  NNNNN = item number
  //  XXXXX = embedded price/weight
  //  C = check digit
  '1' + pad(rawUPC.substr(1, 5), 15);

export const normalizeIFPS = (rawUPC: string): string =>
  // item number must be an IFPS number, format it as such
  // APL IFPS format: 0100000000000NNNNC, where
  //  NNNNN = IFPS number (4-5 digits)
  //  C = check digit (subsitute with the '%' character for SQL wildcard lookup)
  '1' + pad(rawUPC, 15);

export const parseRSS14 = (rawUPC: string): ParsedGS1Info =>
// item number must be an IFPS number, format it as such
// APL IFPS format: 0XYZZZZZZZNNNNNC, where
//  0X = GS1 element AI code
//  Y = GS1 ?????
//  ZZZZZZZ = Unique trade identifier
//  NNNNN = IFPS number (4-5 digits)
//  C = check digit (subsitute with the '%' character for SQL wildcard lookup)
({
  type: BarcodeType.GS1_DataBar,
  upc: normalizeIFPS(rawUPC.length === 14 ? rawUPC.substr(8, 5) : rawUPC.substr(10, 5)),
  subType: 'IFPS'
});

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const tryParseUPCA = (rawUPC: string): ParsedUPCAInfo => {
  // for all other barcode types we will be removing leading zeros
  const stripped = stripLeadingZeros(rawUPC);

  // check to make sure the item is a price embedded barcode (UPC-type 2)
  const subType = (stripped.length === 12 && stripped[0] === '2')
    ? 'Type2'
    // check to see if the item number is an IFPS number (for produce)
    : (stripped.length < 6)
      ? 'IFPS'
      : 'other';

  return {
    type: BarcodeType.UPC_A,
    subType,
    upc: {
      Type2: parseUPCType2,
      IFPS: normalizeIFPS,
      other: (upc) => upc
    }[subType](stripped)
  };
};

export const parseKnownUPC = (rawUPC: string, upcType: string): ParsedUPCInfo => 
  upcType === 'RSS_14'
    ? parseRSS14(rawUPC)
    : upcType === 'UPC_E' || upcType === 'EAN_8'
    ? tryParseUPCE(rawUPC)
    : tryParseUPCA(rawUPC);

export const parseUnknownUPC = (rawUPC: string): ParsedUPCInfo => {
  const barcodeType = getBarcodeType(rawUPC);

  const parsed = {
    [BarcodeType.GS1_DataBar]: parseRSS14,
    [BarcodeType.UPC_E]: tryParseUPCE,
    [BarcodeType.UPC_A]: tryParseUPCA,
  }[barcodeType](rawUPC);

  return parsed;
};

export const parseUPC = (rawUPC: string, upcType?: string): ParsedUPCInfo => {
  if (!rawUPC) {
    return { upc: rawUPC, type: 'Unknown'};
  }

  return !!upcType
    ? parseKnownUPC(rawUPC, upcType.toUpperCase())
    : parseUnknownUPC(rawUPC);
};

@Injectable()
export class UpcUtilService {
  constructor() {
  }

  mergeItems(products: EnrichedProduct[]): EnrichedProduct {
    return products.reduce((merged, item) =>
        merged ? {
          ...merged,
          quantity: (merged.quantity || 0) + (item.quantity || 0),
          allowed: (merged.allowed || 0) + (item.allowed || 0),
          subCategoryId: ((item.allowed || 0) > (merged.allowed || 0)) ? item.subCategoryId : merged.subCategoryId,
          status: this.getRankedStatus(merged, item)
        } : item
      , null);
  }

  getRankedStatus(base: EnrichedProduct, item: EnrichedProduct): RankedStatus {
    return (base.status === 'yes' || item.status === 'yes') ? 'yes' :
      (base.status === 'no' || item.status === 'no') ? 'no' :
        (base.status === 'cvv' || item.status === 'cvv') ? 'cvv' :
          (base.status === 'wic' || item.status === 'wic') ? 'wic' :
            (base.status === 'nowic' || item.status === 'nowic') ? 'nowic' :
              (base.status === 'norx' || item.status === 'norx') ? 'norx' : 'unk';
  }
}
