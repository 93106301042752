export class BenefitError extends Error {
  constructor(
    public override message: string,
    public title?: string,
    public detailedMessage?: string,
    public originalError?: Error,
    public retryable?: boolean
  ) {
    super(message);
  }
}
