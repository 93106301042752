import { NgModule } from '@angular/core';
import { VendorFacade } from '~features/vendors/vendor.facade';


@NgModule({
  providers: [
    VendorFacade
  ]
})
export class VendorsModule {
}
