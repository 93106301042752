import { Component, Input, Output, EventEmitter } from '@angular/core';

import { allowedQuantity } from '../../pipes/compute-allowed.pipe';
import { Product } from '~features/products/models';
import { EnrichedBenefit } from '~features/benefits/models';

@Component({
  selector: 'wic-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent {
  @Input() benefit: EnrichedBenefit;
  @Input() products: Product[];
  @Input() totalCount: number;
  @Input() hideTitle = false;
  @Input() showNoProductsWarning = false;
  @Input() hasFilterCriteria = false;
  @Output() selected = new EventEmitter<Product>();
  @Output() retry = new EventEmitter<EnrichedBenefit>();

  errorUrl = null;

  productImageError($event) {
    this.errorUrl = 'assets/images/product/barcode.png';
    $event.target.src = this.errorUrl;
  }

  getAllowedStatus(benefit: EnrichedBenefit, product: Product) {
    const allowed = allowedQuantity(benefit.quantity, product.size, product.packageSize);
    return allowed === 0 ? 'red' : allowed === 1
        ? 'yellow'
        : 'green';
  }
}
