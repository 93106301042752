import { Injectable } from '@angular/core';
import { RefresherCustomEvent } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Redemption } from './redemption';
import { redemptionsWithConfigs } from './redemptions.selectors';
import {
  loadOlderMonthsRedemptions,
  offsetMonths,
  RedemptionsFacadeBase,
  refreshRedemptions
} from './redemptions.state';

@Injectable()
export class RedemptionsFacade extends RedemptionsFacadeBase {
  get redemptionsWithConfigs$() {
    return this.store.select(redemptionsWithConfigs);
  }

  get offsetMonths$() {
    return this.store.select(offsetMonths);
  }

  constructor(private store: Store) {
    super(Redemption, store);
  }

  refreshRedemptions(refreshEvent: RefresherCustomEvent) {
    this.store.dispatch(refreshRedemptions({ refreshEvent: refreshEvent.detail }));
  }

  loadOlderMonthsRedemptions() {
    this.store.dispatch(loadOlderMonthsRedemptions());
  }
}
