import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { MobileOrderItemSubstitution } from '../substitutions/substitution.entity';

@Entity('ShoppingListItem')
export class ShoppingListItem {
  @Key id: string;
  shoppingListId: string;
  productId: string;
  itemId?: string;
  itemNumber: string;

  categoryId: number;
  subCategoryId: number;
  purchaseQuantity: number;
  uom: string;
  units: number;
  description: string;

  price: number;
  salePrice?: number;
  parWeight?: number;
  costUnit?: string;

  checkedOff: boolean;
  customItem?: boolean;

  substitutions?: MobileOrderItemSubstitution[];
}
