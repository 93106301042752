import { parse } from 'date-fns';
import { format } from 'date-fns/fp';

export const BENEFIT_DATE_FORMAT = 'yyyy-MM-dd';
export const BENEFIT_HEADER_DATE_FORMAT = 'yyyyMMdd';
export const PRETTY_BENEFIT_DATE_FORMAT = 'MMM dd yyyy';

/* Parses with format yyyy-MM-dd */
export const parseBenefitDate = (date: string) => parse(date, BENEFIT_DATE_FORMAT, new Date());

/* Parses with format yyyyMMdd */
export const parseBenefitHeaderDate = (date: string) => parse(date, BENEFIT_HEADER_DATE_FORMAT, new Date());

/* Formats date with yyyy-MM-dd */
export const formatBenefitDate = format(BENEFIT_DATE_FORMAT);
export const prettyFormatBenefitDate = format(PRETTY_BENEFIT_DATE_FORMAT);

export const isProduceCategory = (category: number) => category === 19 || category === 97;
