import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { sortByDistance } from '../../../util/model-comparers';
import { calculateDistance } from '../../../util/model-transformers';

export const fillShortName = {
  fromServer: vendor => (vendor.shortName = vendor.shortName || vendor.name, vendor)
};

export enum GroupCode {
  None = 0,
  eWic = 1,
  Vouchers = 2,
  Farmers = 3,
  Pharmacy = 4,
  SelfCheckout = 5,
  Mobile = 6,
  Commissary = 7,
  FarmStand = 101,
  Honey = 102,
}

@Entity({
  modelName: 'Vendor',
  comparer: sortByDistance,
  transform: [calculateDistance, fillShortName]
})
export class Vendor {
  @Key entryId: number;
  sponsorId: number;
  groupCode: GroupCode;
  isException: boolean;
  allowsMobileOrdering: boolean;
  hasActiveInventory: boolean;
  name: string;
  shortName?: string;
  locationDetails?: string;
  longitude: number;
  latitude: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;

  distance?: number;
}
