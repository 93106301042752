import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WebCacheService } from '~core/interceptors/web-cache.service';
import { AppFacade } from '~features/app/app.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';

@Component({
  selector: 'wic-admin-tools-modal',
  templateUrl: './admin-tools-modal.component.html',
  styleUrls: ['./admin-tools-modal.component.scss'],
})
export class AdminToolsModalComponent {
  constructor(
    public webCache: WebCacheService,
    public app: AppFacade,
    public enhancedMode: EnhancedModeFacade,
    public modal: ModalController) {
  }
}
