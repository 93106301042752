import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { Authority } from '~features/authorities/models';
import { Card } from '~features/registration/models';

@Component({
  selector: 'wic-edit-card-modal',
  templateUrl: './edit-card-modal.component.html',
  styleUrls: ['./edit-card-modal.component.scss'],
})
export class EditCardModalComponent implements OnInit {
  @Input() card: Card;
  @Input() provider: Authority;

  form: UntypedFormGroup;

  constructor(private builder: UntypedFormBuilder, public modal: ModalController) {
  }

  ngOnInit() {
    this.form = this.builder.group({
      nickname: [null]
    });

    if (this.card) {
      this.form.patchValue(this.card);
    }
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    this.modal.dismiss({
      ...this.card,
      ...this.form.value
    });
  }
}
