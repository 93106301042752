import { createAction, props } from '@ngrx/store';
import { Card } from '~features/registration/models';
import { UserSettings } from '~features/settings/models';
import { NotificationDetails } from './models/notificationDetails';

export const getNotifications = createAction(
  '[Notifications] Get Notifications',
  props<{ cards: Card[] }>()
);

export const scheduleNotifications = createAction(
  '[Notifications] Schedule Notifications',
  props<{ details: Array<NotificationDetails> }>()
);

export const scheduleBenefitNotification = createAction(
  '[Notifications] Schedule Notification',
  props<{ benefitsEndDate: string; settings: UserSettings; force: boolean }>()
);

export const resetPushNotifications = createAction(
  '[Notifications] Reset Push Notifications',
);
