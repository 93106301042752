import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFingerprint, faQuestion } from '@fortawesome/free-solid-svg-icons';

import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';

import { SearchUpcPage } from './search-upc.page';
import { ComponentsModule } from '../../components/components.module';
import { KeyEnterUpcComponent } from './key-enter-upc/key-enter-upc.component';
import { RecentUpcsComponent } from './recent-upcs/recent-upcs.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ScrollingModule } from '@angular/cdk/scrolling';

const routes: Routes = [
  {
    path: '',
    component: SearchUpcPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ComponentsModule,
    RouterModule.forChild(routes),
    TranslocoModule,
    FontAwesomeModule,
    PipesModule,
    ScrollingModule
  ],
  declarations: [SearchUpcPage, KeyEnterUpcComponent, RecentUpcsComponent]
})
export class SearchUpcPageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(faFingerprint, faQuestion);
  }
}
