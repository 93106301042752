import { Component, Input } from "@angular/core";
import { ModalController, PopoverController } from "@ionic/angular";
import { NativePermissionsService } from "~core/services/native-permissions.service";

@Component({
  selector: 'wic-network-status-modal',
  templateUrl: './network-status-modal.component.html',
  styleUrls: ['./network-status-modal.component.scss'],
})
export class NetworkStatusModalComponent {
  @Input() backgroundColor = 'light';
  @Input() buttonColor = 'tufts-blue';
  @Input() message: string;

  constructor(
    public modal: ModalController
  ) {}
}