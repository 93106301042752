import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CalculatorBalance } from '~features/calculators/models';

@Component({
  selector: 'wic-calculator-benefit-balance-modal',
  templateUrl: './calculator-benefit-balance-modal.component.html',
  styleUrls: ['./calculator-benefit-balance-modal.component.scss'],
})
export class CalculatorBenefitBalanceModalComponent implements OnInit {
  @Input() balance: CalculatorBalance;
  @Input() color: string;

  form: UntypedFormControl;

  constructor(public modal: ModalController, private builder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.builder.control(null, Validators.required);
  }

  async submit() {
    if (this.form.invalid) {
      return;
    }
    const updatedBalance: CalculatorBalance = {
      ...this.balance,
      balance: this.form.value || this.balance.balance,
    };
    await this.modal.dismiss(updatedBalance, 'ok', 'calc-benefit-balance-modal');
  }
}
