import { Component, OnInit, Input } from '@angular/core';
import { EnrichedBenefit } from '~features/benefits/models';

@Component({
  selector: 'wic-item-list-header',
  templateUrl: './item-list-header.component.html',
  styleUrls: ['./item-list-header.component.scss'],
})
export class ItemListHeaderComponent {
  @Input() benefit: EnrichedBenefit;
}
