import { Component, OnInit } from '@angular/core';

import { ProductFacade } from '~features/products/product.facade';
import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { HomeFacade } from '~features/home/home.facade';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'wic-item-list',
  templateUrl: './item-list.page.html',
  styleUrls: ['./item-list.page.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'subcategory-translations' }],
})
export class ItemListPage implements OnInit {
  constructor(
    public product: ProductFacade,
    public benefits: BenefitsFacade,
    public home: HomeFacade,
    private analytics: AnalyticsFacade) {
  }

  ngOnInit() {
    this.product.setProductFilter(null);
    this.analytics.pageView('items-list');
  }
}
