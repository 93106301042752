import { Component, Input } from '@angular/core';
import { EnrichedBenefitInfo } from '~features/benefits/models';

@Component({
  selector: 'wic-last-shopping-trip',
  templateUrl: './last-shopping-trip.component.html',
  styleUrls: ['./last-shopping-trip.component.scss'],
})
export class LastShoppingTripComponent {
  @Input() benefitInfo: EnrichedBenefitInfo;

}
