<div class="map" *ngIf="!!location">
  <google-map
    height="100%"
    width="100%"
    [center]="location | locationToLatLng"
    [zoom]="12"
    [options]="{ disableDefaultUI: true, clickableIcons: false }"
  >
    <map-marker
      *ngIf="userLocation"
      [position]="userLocation | locationToLatLng"
      [icon]="userIcon"
      data-cy="map-user-icon"
    ></map-marker>
    <map-marker
      *ngFor="let office of offices"
      [position]="office | officeAsGeoposition | locationToLatLng"
      [icon]="buildingIcon"
      (mapClick)="selected.emit({ office: office, favorites: favorites })"
      data-cy="map-offices-icon"
    ></map-marker>
  </google-map>
</div>
