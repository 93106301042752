import { NgModule } from '@angular/core';
import { MissingProductFacade } from '~features/missing-product/missing-product.facade';


@NgModule({
  providers: [
    MissingProductFacade,
  ]
})
export class MissingProductModule {
}
