<ion-card>
  <ion-card-header>
    <ion-card-subtitle>
      {{ title }}
    </ion-card-subtitle>
  </ion-card-header>

  <ion-accordion-group>
    <ion-accordion *ngFor="let notification of notifications" [value]="notification.id">
      <ion-item slot="header">
        <ion-note slot="start">{{ notification.id }}</ion-note>
        <ion-label class="ion-text-wrap">
          <p>{{ notification.schedule.at | date: 'short' }}</p>
          <h2>{{ notification.title }}</h2>
        </ion-label>
      </ion-item>
      <ion-list slot="content">
        <ion-item lines="none">
          <ion-label class="ion-text-wrap">
            {{ notification.body }}
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label color="medium" class="ion-text-wrap">
            {{ notification.extra | json }}
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</ion-card>
