<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false)">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'host-settings-modal.title' | transloco}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-list>
    <ion-list-header>
      {{'host-settings-modal.apiSection' | transloco}}
      {{ selectedApi }}
    </ion-list-header>
    <ion-radio-group [value]="selectedApi" (ionChange)="setSelectedApi($event)">
      <ion-item *ngFor="let host of apiHosts | hostSettingsModeFilter:mode; index as i">
        <ion-radio slot="start" [color]="host.color" [value]="i" ></ion-radio>
        <ion-label>
          <h2>{{'host-settings-modal.apiOptionLabel' | transloco:{env: host.name} }}</h2>
          <p><ion-text color="medium">{{host.url}}</ion-text></p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-radio slot="start" [value]="-1"></ion-radio>
        <ion-label position="floating">
          <h2>{{'host-settings-modal.customAPILabel' | transloco}}</h2>
        </ion-label>
        <ion-input [(ngModel)]="customApi" (ionFocus)="selectedApi = -1" placeholder="{{'host-settings-modal.customAPIPlaceholder' | transloco}}"></ion-input>
      </ion-item>
    </ion-radio-group>
  </ion-list>

  <ion-list *ngIf="mode === HostsSettingsMode.Full">
    <ion-list-header>
      {{'host-settings-modal.cdnSection' | transloco}}
    </ion-list-header>
    <ion-radio-group [value]="selectedCdn" (ionChange)="selectedCdn = $event.detail.value">
      <ion-item *ngFor="let host of cdnHosts; let i = index">
        <ion-radio slot="start" [color]="host.color" [value]="i"></ion-radio>
        <ion-label>
          <h2>{{'host-settings-modal.cdnOptionLabel' | transloco:{env: host.name} }}</h2>
          <p><ion-text color="medium">{{host.url}}</ion-text></p>
        </ion-label>
      </ion-item>
    </ion-radio-group>
  </ion-list>

  <ion-list *ngIf="mode === HostsSettingsMode.Full">
    <ion-list-header>
      {{'host-settings-modal.azureImagesSection' | transloco}}
    </ion-list-header>
    <ion-radio-group [value]="selectedAzureEndpoint" (ionChange)="selectedAzureEndpoint = $event.detail.value">
      <ion-item *ngFor="let host of azureHosts; let i = index">
        <ion-radio slot="start" [color]="host.color" [value]="i"></ion-radio>
        <ion-label>
          <h2>{{'host-settings-modal.azureOptionsLabel' | transloco:{env: host.name} }}</h2>
          <p><ion-text color="medium">{{host.url}}</ion-text></p>
        </ion-label>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>

<ion-footer translucent>
  <ion-toolbar>
    <ion-button (click)="save(selectedApi)" expand="block">
      <span class="ion-text-uppercase">{{'host-settings-modal.saveButton' | transloco}}</span>
    </ion-button>
  </ion-toolbar>
</ion-footer>
