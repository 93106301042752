<ion-content [scrollY]="false">
  <ion-card-header>
    <ion-card-subtitle>{{ 'shoppingLists.addItem' | transloco }}</ion-card-subtitle>
  </ion-card-header>

  <ion-card-content>
    <div style="display: flex" class="ion-justify-content-between ion-margin-top">
      <ion-button shape="round" (click)="popover.dismiss('redemptions', 'dismiss')">
        {{ 'shoppingLists.addItemFromPastPurchases' | transloco }}
      </ion-button>
      <ion-button shape="round" (click)="popover.dismiss('', 'dismiss')">
        {{ 'shoppingLists.addItemFromAPL' | transloco }}
      </ion-button>
    </div>
  </ion-card-content>
</ion-content>
