<section *ngFor="let benefit of (benefitInfo?.benefits || benefits) | nonZeroBalance:pendingExpirationMode"
         id="benefit-{{benefit.categoryId}}-{{benefit.subCategoryId}}">
  <wic-benefit-item
      *ngIf="!pendingExpirationMode || (pendingExpirationMode && benefit.availableQuantity > 0)"
      [authority]="authority"
      [aplMessage]="benefit | aplMessage:aplMessages"
      [benefit]="benefit"
      [config]="benefit | findConfig | async"
      [pendingExpirationMode]="pendingExpirationMode"
      [nonInteractive]="nonInteractive"
      [isFuture]="isFuture"
      [currentLanguage]="currentLanguage"
      (viewed)="viewed.emit($event)"
      (messageViewed)="messageViewed.emit($event)"
      (linkOpened)="linkOpened.emit($event)"
      (calcOpened)="calcOpened.emit($event)">
  </wic-benefit-item>
</section>
