import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faCog,
  faDirections,
  faList,
  faLocationArrow,
  faMap,
  faPhone,
  faPrescriptionBottle,
  faSearch,
  faShoppingBasket,
  faShoppingCart,
  faStar,
  faTimes,
  faTractor,
} from '@fortawesome/free-solid-svg-icons';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { ComponentsModule, PipesModule } from '~jpma-wicshopper-imports-mono/mobile/core';
import { GroupCodedColorPipe } from './group-coded-color.pipe';
import { GroupCodedIconPipe } from './group-coded-icon.pipe';
import { GroupCodedMessagePipe } from './group-coded-message.pipe';
import { IsFavoritePipe } from './is-favorite.pipe';
import { ShouldShowMessagePipe } from './should-show-message.pipe';
import { StoreLogoPipe } from './store-logo.pipe';
import { VendorAsGeopositionPipe } from './vendor-as-geoposition.pipe';
import { VendorDetailModalComponent } from './vendor-detail-modal/vendor-detail-modal.component';
import { VendorIconComponent } from './vendor-icon/vendor-icon.component';
import { VendorSearchModalComponent } from './vendor-search-modal/vendor-search-modal.component';
import { VendorsListComponent } from './vendors-list/vendors-list.component';
import { VendorsEffects } from './vendors.effects';
import { VendorsService } from './vendors.service';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FontAwesomeModule,
    TranslocoModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([VendorsEffects]),
    ComponentsModule,
    ScrollingModule
  ],
  declarations: [
    VendorDetailModalComponent,
    VendorSearchModalComponent,

    VendorsListComponent,
    VendorIconComponent,

    IsFavoritePipe,
    StoreLogoPipe,
    GroupCodedIconPipe,
    GroupCodedColorPipe,
    VendorAsGeopositionPipe,
    GroupCodedMessagePipe,
    ShouldShowMessagePipe,
  ],
  providers: [VendorsService],
  exports: [
    VendorsListComponent,
    VendorIconComponent,

    IsFavoritePipe,
    StoreLogoPipe,
    GroupCodedIconPipe,
    GroupCodedColorPipe,
    VendorAsGeopositionPipe,
    GroupCodedMessagePipe,
    ShouldShowMessagePipe,
  ],
})
export class VendorsComponentsModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(
      faSearch,
      faDirections,
      faShoppingBasket,
      faShoppingCart,
      faStar,
      faTimes,
      faMap,
      faList,
      faCog,
      faCheck,
      faPrescriptionBottle,
      faTractor,
      faDotCircle,
      faPhone,
      faLocationArrow
    );
  }
}
