import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from '../state';
import { AuthoritiesFacadeBase } from './authorities.state';
import { Authority } from './models';

@Injectable()
export class AuthoritiesFacade extends AuthoritiesFacadeBase {
  constructor(private store: Store<State>) {
    super(Authority, store);
  }
}
