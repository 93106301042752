<ion-header mode="ios">
  <ion-toolbar mode="ios" color="tufts-blue-shaded">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>{{'shoppingListsView.contentTitle' | transloco}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="shoppingLists.current$ | async as shoppingList">
    <ion-list-header>
      <ion-label>{{ shoppingList.name }}</ion-label>
      <ion-button fill="clear" *ngIf="shoppingList.isMobileOrder" color="dark">
        <ion-icon name="cart-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-list-header>

    <ion-item>
      <ion-label *ngIf="shoppingLists.currentShoppingListVendor$ | async as vendor; else loading">
        <h3>{{ vendor.name }}</h3>
        <p>{{ vendor.address1 }}</p>
      </ion-label>
      <ng-template #loading>
        <ion-label>
          <ion-skeleton-text style="width: 60%; height: 14px; margin-top: 1px;" [animated]="true"></ion-skeleton-text>
          <ion-skeleton-text style="height: 13px;" [animated]="true"></ion-skeleton-text>
        </ion-label>
      </ng-template>
      <ion-button slot="end" (click)="shoppingLists.showSelectVendor(shoppingList)">
        {{ 'shoppingListsView.selectVendor' | transloco }}
      </ion-button>
    </ion-item>

    <ion-item *ngIf="shoppingList.isMobileOrder">
      <ion-label *ngIf="shoppingList.vendorTimeSlot">
        <h3>{{ 'shoppingListsView.orderDate' | transloco }}</h3>
        <p>{{ (shoppingList.vendorTimeSlot.hour | timeSlotHourFormat: shoppingList.orderDate) | date: dateFormat }}</p>
      </ion-label>
      <ion-button slot="end" (click)="shoppingLists.changeOrderDate()">
        {{ 'shoppingListsView.changeOrderDate' | transloco }}
      </ion-button>
    </ion-item>

    <div #addItems>
      <ion-card>
        <ion-item lines="none" class="item-group-header" color="light" style="--min-height: 76px">
          <ion-label>
            <h1>{{ 'shoppingListsView.addItemFromBenefits' | transloco }}</h1>
          </ion-label>
        </ion-item>
        <ion-fab vertical="bottom" horizontal="end">
          <ion-fab-button color="success" (click)="shoppingLists.openSelectBenefitSubCategory()">
            <ion-icon name="add"></ion-icon>
          </ion-fab-button>
        </ion-fab>
      </ion-card>
    </div>

    <ng-container *ngIf="shoppingLists.hasItemsInShoppingList$ | async">
      <ng-container *ngIf="!shoppingList.isMobileOrder; else mobileOrder">
        <wic-shopping-list-item-group
          *ngFor="let benefit of shoppingLists.filteredBenefitsWithShoppingListItems$ | async; trackBy: trackByCategory"
          [benefit]="benefit"
          [editingId]="shoppingListItems.quantityEditId$ | async"
          [form]="quantityEditForm$ | async"
          [isMobileOrder]="shoppingList.isMobileOrder"
          (edit)="shoppingListItems.editShoppingListItemQuantity($event)"
          (save)="shoppingListItems.submitShoppingListItemQuantityEdit($event)"
          (remove)="shoppingListItems.removeShoppingListItem($event)"
          (checkOff)="shoppingListItems.toggleShoppingListItemCheckedOffStatus($event)"
          (addSubstitution)="shoppingLists.addSubstitution($event, benefit)"
          (removeSubstitution)="substitutions.removeSubstitution($event)"
          (showNonSubInfo)="shoppingLists.showSubstitutionInfo($event)"
        ></wic-shopping-list-item-group>
      </ng-container>
      
      <ng-template #mobileOrder>
        <wic-mobile-order-item-group
          *ngFor="let benefit of shoppingLists.filteredBenefitsWithShoppingListItems$ | async; trackBy: trackByCategory"
          [benefit]="benefit"
          [editingId]="shoppingListItems.quantityEditId$ | async"
          [form]="quantityEditForm$ | async"
          [isMobileOrder]="shoppingList.isMobileOrder"
          (edit)="shoppingListItems.editShoppingListItemQuantity($event)"
          (save)="shoppingListItems.submitShoppingListItemQuantityEdit($event)"
          (remove)="shoppingListItems.removeShoppingListItem($event)"
          (checkOff)="shoppingListItems.toggleShoppingListItemCheckedOffStatus($event)"
          (addSubstitution)="shoppingLists.addSubstitution($event, benefit)"
          (removeSubstitution)="substitutions.removeSubstitution($event)"
          (showNonSubInfo)="shoppingLists.showSubstitutionInfo($event)"
        ></wic-mobile-order-item-group>
      </ng-template>
    </ng-container>

    <wic-shopping-list-custom-items-card
      *ngIf="!shoppingList.isMobileOrder"
      [items]="shoppingListItems.customItemsForCurrentShoppingList$ | async"
      (remove)="shoppingListItems.removeShoppingListItem($event)"
      (checkOff)="shoppingListItems.toggleShoppingListItemCheckedOffStatus($event)"
      (addItem)="shoppingLists.addCustomItem()"
    ></wic-shopping-list-custom-items-card>

    <ion-button
      @fade
      *ngIf="shoppingListItems.hasCheckedOffItem$ | async"
      expand="full"
      color="secondary"
      (click)="shoppingListItems.uncheckAllShoppingListItems()"
    >
      {{ 'shoppingListsView.uncheckAllItems' | transloco }}
    </ion-button>

    <ion-button
      @fade
      *ngIf="(shoppingLists.filteredBenefitsWithShoppingListItems$ | async).length && shoppingList.isMobileOrder"
      expand="full"
      color="primary"
      (click)="shoppingLists.submitMobileOrder()"
    >
      {{ 'mobileOrdering.submitOrder' | transloco }}
    </ion-button>
  </ion-list>

  <ion-fab
    @fade
    *ngIf="showAddItemFab$ | async"
    slot="fixed"
    vertical="top"
    horizontal="end"
    edge="true"
  >
    <ion-fab-button (click)="shoppingLists.openSelectBenefitSubCategory()" color="success">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
