<ion-header>
  <ion-toolbar>
    <ion-title>
      <img src="assets/images/img_main.png" alt="{{ 'settings.initialLanguageSelect' | transloco }}" height="50" />
    </ion-title>
    <ion-buttons>
      <ion-button (click)="modal.dismiss(null, 'cancel')">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <swiper-container #slides 
    speed="400"
    initial-slide="0"
    allow-touch-move="false"
  >
    <swiper-slide>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h2>{{ 'settings.initialLanguageSelect' | transloco }}</h2>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-img src="/assets/images/settings/ChangeLang.png"></ion-img>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button color="success" shape="round" expand="block" (click)="modal.dismiss(null, 'dismiss')">
              {{ 'settings.continueWithCurrentLang' | transloco }}
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <a (click)="continueToSelectLang()">
              {{ 'settings.changeLanguage' | transloco }}
            </a>
          </ion-col>
        </ion-row>
      </ion-grid>
    </swiper-slide>
    <swiper-slide>
      <ion-list>
        <wic-language-item
          *ngFor="let language of languages.all$ | async"
          (click)="settings.selectLanguage(language.code)"
          [language]="language"
          [selected]="languages.selectedLanguage$ | async"
        ></wic-language-item>
      </ion-list>
    </swiper-slide>
  </swiper-container>
</ion-content>

<ion-footer *ngIf="isSelectingLang">
  <ion-button class="ion-margin"  color="success" expand="block" (click)="modal.dismiss(null, 'dismiss')">
    {{ 'settings.saveButton' | transloco }}
  </ion-button>
</ion-footer>
