import { Component, Input, OnInit } from '@angular/core';
import { ModalController, RadioGroupCustomEvent } from '@ionic/angular';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { EnvironmentService } from '~features/environment.service';
import { AdminSettingsFacade } from '~features/settings/admin-settings.facade';
import { APIHostOption, HostsSettingsMode } from '~models';
import { HostSettingsModeFilterPipe } from '../../pipes/host-settings-mode-filter.pipe';

@Component({
  selector: 'wic-host-settings-modal',
  templateUrl: './host-settings-modal.component.html',
  styleUrls: ['./host-settings-modal.component.scss'],
})
export class HostSettingsModalComponent implements OnInit {
  @Input() mode: HostsSettingsMode = HostsSettingsMode.Restricted;
  HostsSettingsMode = HostsSettingsMode;

  apiHosts: APIHostOption[] = [
    {
      url: 'http://localhost:3001/api',
      name: 'local',
      color: 'success',
      modes: [HostsSettingsMode.Full],
    },
    {
      url: 'https://ws-dev-api.jpma.com/api',
      name: 'dev',
      color: 'success',
      modes: [HostsSettingsMode.Full],
    },
    {
      url: 'https://ws-stage-api.jpma.com/api',
      name: 'stage',
      color: 'warning',
      modes: [HostsSettingsMode.Full, HostsSettingsMode.Restricted],
    },
    {
      url: 'https://ws-api.jpma.com/api',
      name: 'prod',
      color: 'danger',
      modes: [HostsSettingsMode.Full, HostsSettingsMode.Restricted],
    },
  ];

  cdnHosts = [
    { url: 'https://wicshopper-images-endpoint.azureedge.net/products/:itemNumber', name: 'cloud', color: 'danger' },
  ];

  azureHosts = [
    { url: 'https://wicshopper-gateway.azure-api.net/add-images-function', name: 'dev', color: 'danger' },
    { url: 'https://wicshopper-gateway.azure-api.net/wicshopper-integrations', name: 'prod', color: 'danger' },
  ];

  selectedApi = 0;
  customApi = '';

  selectedCdn = 0;
  customCdn = '';

  selectedAzureEndpoint = 0;
  customAzureEndpoint = '';

  constructor(
    public enhancedMode: EnhancedModeFacade,
    public modal: ModalController,
    public adminSettings: AdminSettingsFacade,
    public env: EnvironmentService,
    private modeFilter: HostSettingsModeFilterPipe
  ) {
    this.customApi = env.apiHost;
  }

  ngOnInit(): void {
    this.selectedApi = this.modeFilter
      .transform(this.apiHosts, this.mode)
      .findIndex(option => option.url === this.env.apiHost);
    this.selectedAzureEndpoint = this.azureHosts.findIndex(({ url }) => url === this.env.azureGateway.baseUrl);
  }

  setSelectedApi(event: RadioGroupCustomEvent) {
    const value = event.detail.value;
    this.selectedApi = typeof value === 'number' ? value : this.selectedApi;
  }

  save(apiIndex): void {
    const api = apiIndex > -1 && apiIndex < this.apiHosts.length ? this.apiHosts[apiIndex].url : this.customApi;
    const baseUrl = this.azureHosts[this.selectedAzureEndpoint].url;

    this.adminSettings.updateSettings({
      hosts: {
        api,
        azureGateway: { baseUrl, subscriptionKey: this.env.azureGateway.subscriptionKey },
      },
    });
  }
}
