import { Component, OnInit } from '@angular/core';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'wic-qr-code',
  templateUrl: './qr-code.page.html',
  styleUrls: ['./qr-code.page.scss']
})

export class QRCodePage implements OnInit {
  sizeDefault = '60vw';
  sizeLarge = '80vw';
  expand = false;

  constructor(
    public nav: NavController,
    public registration: RegistrationFacade,
    private analytics: AnalyticsFacade
  ) {

  }

  ngOnInit() {
    this.analytics.pageView('qr-code')
  }
}
