import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LogService } from '~core/services/log.service';

import { Vendor, VendorFavorite } from '~features/vendors/models';
import { LocationSearchCriteria } from '../../models/location-search-criteria';
import { VendorDetailModalComponent } from './vendor-detail-modal/vendor-detail-modal.component';
import { VendorSearchModalComponent } from './vendor-search-modal/vendor-search-modal.component';

const TAGS = ['Service', 'Vendors'];

@Injectable()
export class VendorsService {
  constructor(private modals: ModalController, private log: LogService) {}

  async showDetailModal(vendor: Vendor, favorites: VendorFavorite[]): Promise<void> {
    const modal = await this.modals.create({
      component: VendorDetailModalComponent,
      componentProps: {
        vendor,
        favorites,
      },
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  async showSearch(criteria: LocationSearchCriteria): Promise<LocationSearchCriteria> {
    const modal = await this.modals.create({
      component: VendorSearchModalComponent,
      componentProps: {
        criteria,
      },
    });

    await modal.present();
    const { data, role } = await modal.onDidDismiss();
    if (role === 'ok') {
      return data;
    } else {
      throw role;
    }
  }
}
