import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AdminSettings, AnalyticsSettings, HostsSettings, LoggingSettings } from './models';
import { State } from '../state';
import { adminSettingsChanged, analyticsSettings, hostsSettings, loggingSettings } from './settings.state';

@Injectable()
export class AdminSettingsFacade {
  get logging$(): Observable<LoggingSettings> {
    return this.store.select(loggingSettings);
  }

  get hosts$(): Observable<HostsSettings> {
    return this.store.select(hostsSettings);
  }

  get analytics$(): Observable<AnalyticsSettings> {
    return this.store.select(analyticsSettings);
  }

  constructor(private store: Store<State>) {
  }

  updateSettings(settings: AdminSettings): void {
    this.store.dispatch(adminSettingsChanged({settings}));
  }
}
