import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Product } from '~features/products/models';
import { EnrichedBenefit } from '~features/benefits/models';
import { allowedQuantity } from '../../../../pipes/compute-allowed.pipe';
import { VendorInventoryProduct } from '~features/vendor-inventory-items/vendor-inventory-product.model';
import { calculateShoppingListBenefitTotal } from '../../utils';
import { ShoppingListBenefit } from '~features/shopping-lists/models/shopping-list-benefit.model';
import { AlertController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'wic-orderable-product-list',
  templateUrl: './orderable-product-list.component.html',
  styleUrls: ['./orderable-product-list.component.scss'],
})
export class OrderableProductListComponent {
  @Input() benefit: EnrichedBenefit;
  @Input() set products(products: VendorInventoryProduct[]) {
    this.chunkedProducts = products.reduce((acc, cur, i) => {
      const index = Math.floor(i / 2);
      const chunk = acc[index] || (acc[index] = []);
      chunk.push(cur);
      return acc;
    }, []);
  };
  @Input() totalCount: number;
  @Input() hideTitle = false;
  @Input() showNoProductsWarning = false;
  @Input() hasFilterCriteria = false;
  @Output() selected = new EventEmitter<VendorInventoryProduct>();
  @Output() retry = new EventEmitter<EnrichedBenefit>();

  chunkedProducts: VendorInventoryProduct[][] = [];
  errorUrl = null;

  constructor(
    private alert: AlertController,
    private transloco: TranslocoService,
  ) {}

  productImageError($event) {
    this.errorUrl = 'assets/images/product/barcode.png';
    $event.target.src = this.errorUrl;
  }

  getAllowedStatus(benefit: ShoppingListBenefit, product: Product) {
    const allowed = allowedQuantity(benefit.quantity - calculateShoppingListBenefitTotal(benefit), product.size, product.packageSize);
    return allowed === 0 ? 'red' : allowed === 1
        ? 'yellow'
        : 'green';
  }

  getAllowedAmount(benefit: ShoppingListBenefit, product: Product) {
    const allowed = allowedQuantity(benefit.quantity - calculateShoppingListBenefitTotal(benefit), product.size, product.packageSize);
    return allowed;
  }

  async presentAlert() { 
    const alert = await this.alert.create({
      header: this.transloco.translate('mobileOrdering.noBenefitsAlertHeader'),
      message: this.transloco.translate('mobileOrdering.noBenefitsAlertMessage'),
      buttons: [this.transloco.translate('Ok')],
      cssClass: 'no-benefit-alert'
    });

    await alert.present();
  }
}
