<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modal.dismiss()">
        <ion-icon name="close-circle"></ion-icon>
        {{'buttons.close' | transloco}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="warning-bg">
    <img src="/assets/warning.svg" alt="Warning Watermark">
  </div>
  <div class="warning-header">
    <h1 class="ion-text-center">{{'benefit-error.warningTitle' | transloco}}</h1>
  </div>
  <div class="warning-detail">
    <div class="warning-detail-content">
      <h3>
        <ion-icon name="bug"></ion-icon>
        {{'card-non-active-error.title' | transloco}}
      </h3>
      <h4>{{'card-non-active-error.message' | transloco}}</h4>
    </div>
  </div>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-button
            color="green" strong="true" expand="block"
            (click)="registration.addCard(); modal.dismiss()"
            *ngIf="(registration.hasCards$ | async) === false">
          <fa-stack>
            <fa-icon icon="credit-card" stackItemSize="1x"></fa-icon>
            <fa-icon icon="plus" stackItemSize="1x" transform="shrink-4 right-8 down-10"
                     style="color: dimgray"></fa-icon>
            <fa-icon icon="plus" stackItemSize="1x" transform="shrink-4 right-7 down-9" style="color: gold"></fa-icon>
          </fa-stack>
          {{'card-non-active-error.registerButton' | transloco}}
        </ion-button>
        <ion-button
            color="independence" strong="true" expand="block"
            (click)="registration.manageCards(true); modal.dismiss()"
            *ngIf="registration.hasCards$ | async">
          <fa-stack>
            <fa-icon icon="credit-card" stackItemSize="1x"></fa-icon>
            <fa-icon icon="asterisk" stackItemSize="1x" transform="shrink-4 right-8 down-10" style="color: dimgray"></fa-icon>
            <fa-icon icon="asterisk" stackItemSize="1x" transform="shrink-4 right-7 down-9" style="color: gold"></fa-icon>
          </fa-stack>
          {{'card-non-active-error.manageCardsButton' | transloco}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
