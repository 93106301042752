import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LogService } from '~core/services/log.service';
import { Card } from '../registration/models';
import { State } from '../state';
import { appointmentIsPending, appointmentsForCurrentCard, hasAppointment } from './appointments.selectors';
import {
  AppointmentsFacadeBase,
  changeAppointmentMemberInfo,
  driveToAppointment,
  listAppointments,
  loadAppointment,
} from './appointments.state';
import { Appointment } from './models';

const TAGS = ['Facade', 'Appointments'];

@Injectable()
export class AppointmentsFacade extends AppointmentsFacadeBase {
  get isPending$(): Observable<boolean> {
    return this.store.select(appointmentIsPending);
  }

  get appointmentsForCurrentCard$(): Observable<Appointment[]> {
    return this.store.select(appointmentsForCurrentCard);
  }

  get hasAppointment$(): Observable<boolean> {
    return this.store.select(hasAppointment);
  }

  constructor(private store: Store<State>, private log: LogService) {
    super(Appointment, store);
  }

  changeAppointmentMember(): void {
    this.log.trace(TAGS, 'Dispatching: changeAppointmentMemberInfo()');
    this.store.dispatch(changeAppointmentMemberInfo());
  }

  loadAppointment(): void {
    this.log.trace(TAGS, 'Dispatching: loadAppointment()');
    this.store.dispatch(loadAppointment());
  }

  list(cards: Card[]): void {
    this.log.trace(TAGS, 'Dispatching: loadAppointments({cards})', cards);
    this.store.dispatch(listAppointments({ cards }));
  }

  directions(appointment: Appointment): void {
    this.log.trace(TAGS, 'Dispatching: driveToAddress({address})', appointment);
    this.store.dispatch(
      driveToAppointment({
        appointment,
        address: `${appointment.address1} ${appointment.address2} ${appointment.city}, ${appointment.state} ${appointment.zip}`,
      })
    );
  }
}
