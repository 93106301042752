<ion-grid class="future-benefits-button p-0">
  <ion-row>
    <ion-col class="px-0">
      <ion-button class="btn-orange m-0 my-5" expand="block" (click)="clicked.emit()" data-cy="future-benefits-button">
        <fa-icon icon="history" flip="horizontal"></fa-icon>
        <span class="px-5">{{"benefits.futureBenefitsButton" | transloco}}</span>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
