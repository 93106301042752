export interface AdminSettings {
  logging?: LoggingSettings;
  hosts?: HostsSettings;
  analytics?: AnalyticsSettings;
}

export interface LoggingSettings {
  enableLocal?: boolean;
  enableShipping?: boolean;
}

export interface HostsSettings {
  api?: string;
  cdn?: string;
  azureGateway?: {
    baseUrl: string;
    subscriptionKey: string;
  };
}

export interface AnalyticsSettings {
  key?: string;
  appId?: string;
}

export interface UserSettings {
  lang: string | LanguageCode;
  hasSelectedInitialLanguage?: boolean;
  benefits?: AlertOptions;
  appointments?: AlertOptions;
  preload?: PreloadOptions;
}

export interface AlertOptions {
  allowAlerts: boolean;
  daysBefore: number;
  hour: number;
  minute: number;
}

export interface PreloadOptions {
  apl: {
    enable: boolean;
    categories: boolean | number[];
    products: boolean | number[];
  };
}

export enum LanguageCode {
  English = 'en',
  Spanish = 'es',
  Arabic = 'ar',
  Burmese = 'my',
  Nepali = 'ne',
  Somali = 'so',
  French = 'fr',
  Portuguese = 'pt',
  Lingala = 'ln',
  Russian = 'ru',
  Swahili = 'sw',
  Chinese = 'zh'
}
