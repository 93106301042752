import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { filter, tap } from 'rxjs/operators';
import { DialogsService } from '~core/services/dialogs.service';
import { CalculatorEntry } from '~features/calculators/models';
import { nonzero } from '~validators';

@Component({
  selector: 'wic-edit-calculator-entry-modal',
  templateUrl: './edit-calculator-entry-modal.component.html',
  styleUrls: ['./edit-calculator-entry-modal.component.scss'],
})
export class EditCalculatorEntryModalComponent implements OnInit {
  @Input() item: CalculatorEntry;
  @Input() isAdding = true;
  @Input() remainingBalance: number;

  form: UntypedFormGroup;
  total: number;
  submitted = false;

  constructor(
    public modal: ModalController,
    private dialogs: DialogsService,
    private builder: UntypedFormBuilder,
    private transloco: TranslocoService) {
  }

  ngOnInit() {
    this.form = this.buildForm(this.builder);
    if (this.item) {
      this.populateForm(this.form, this.item);
    } else {
      this.clearForm(this.form);
    }
  }

  submit() {
    const item = this.save(this.form, this.item || {} as CalculatorEntry);

    if (item) {
      this.clearForm(this.form);
      this.modal.dismiss(item, 'ok', 'edit-calc-entry-modal');
    }
  }

  isByCount() {
    return this.form.value.byCount === 'true';
  }

  incrementPrice(): void {
    const price = this.form.value.unitPrice || 0;
    if (price >= 1000) {
      return;
    }

    this.form.patchValue({
      unitPrice: price + 1
    });
  }

  decrementPrice(): void {
    const price = this.form.value.unitPrice || 0;
    if (price <= 0) {
      return;
    }

    this.form.patchValue({
      unitPrice: price - 1
    });
  }

  incrementQty(byCount: boolean): void {
    const quantity = this.form.value.quantity || 0;
    if (quantity >= 150) {
      return;
    }

    this.form.patchValue({
      quantity: quantity + (byCount ? 10 : 1)
    });
  }

  decrementQty(byCount: boolean): void {
    const quantity = this.form.value.quantity || 0;
    if (quantity <= 0) {
      return;
    }

    this.form.patchValue({
      quantity: quantity - (byCount ? 10 : 1)
    });
  }

  roundQuantity(form: UntypedFormGroup) {
    form.patchValue({
      quantity: Math.round(form.value.quantity / 10) * 10
    });
  }

  private buildForm(fb: UntypedFormBuilder): UntypedFormGroup { // Pure
    const group = fb.group({
      name: [null, [Validators.required, Validators.maxLength(64)]],
      quantity: [null, [Validators.required, nonzero]],
      quantityNum: [null],
      unitPrice: [null, [Validators.required, nonzero]],
      unitPriceNum: [null],
      byCount: ['false', Validators.required]
    });

    group.controls['quantity'].valueChanges.pipe(
      filter(newValue => group.controls['quantityNum'].value !== newValue / 10),
      tap(quantity => this.total = (quantity / 10) * group.controls['unitPriceNum'].value)
    ).subscribe(
      quantity => group.patchValue({quantityNum: +(quantity / 10).toFixed(this.isByCount() ? 0 : 1)})
    );

    group.controls['quantityNum'].valueChanges.pipe(
      filter(newValue => group.controls['quantity'].value !== newValue * 10),
      tap(quantity => this.total = quantity * group.controls['unitPriceNum'].value)
    ).subscribe(
      quantity => group.patchValue({quantity: quantity * 10})
    );

    group.controls['unitPrice'].valueChanges.pipe(
      filter(newValue => group.controls['unitPriceNum'].value !== newValue / 100),
      tap(unitPrice => this.total = (unitPrice / 100) * group.controls['quantityNum'].value)
    ).subscribe(
      unitPrice => group.patchValue({unitPriceNum: +(unitPrice / 100).toFixed(2)})
    );

    group.controls['unitPriceNum'].valueChanges.pipe(
      filter(newValue => group.controls['unitPrice'].value !== newValue * 100),
      tap(unitPrice => this.total = unitPrice * group.controls['quantityNum'].value)
    ).subscribe(
      unitPrice => group.patchValue({unitPrice: unitPrice * 100})
    );

    return group;
  }

  private populateForm(form: UntypedFormGroup, item: CalculatorEntry): void { // Impure
    form.patchValue({
      ...item,
      byCount: item.byCount == null ? 'false' : (!!item.byCount).toString(),
      unitPrice: (item.unitPrice || 0) * 100,
      quantity: (item.quantity || 0) * 10,
      quantityNum: (item.quantity || 0),
      unitPriceNum: (item.unitPrice || 0)
    });
  }

  private clearForm(form: UntypedFormGroup) { // Impure
    form.reset();
    form.patchValue({
      byCount: false
    });
  }

  private alertValidationErrors(form: UntypedFormGroup) {
    const messages = Object
      .keys(form.controls)
      .map(key => ({name: key, errors: form.controls[key].errors}))
      .filter(ctrl => !!ctrl.errors)
      .map(ctrl => ({...ctrl, errors: Object.keys(ctrl.errors)}))
      .map(ctrl =>
        ctrl.errors.map(err => this.transloco.translate(`calculator-errors.${ctrl.name}_${err}`))
      );

    /* eslint-disable */
    const messageIntro = this.transloco.translate('calculator-errors.addValidationErrorsMessage');
    const message = `${messageIntro}<br><br>
          ${messages.join('<br><br>')}
    `;
    /* eslint-enable */

    this.dialogs.alert({
      message,
      title: this.transloco.translate('calculator-errors.addErrorTitle')
    });
  }


  private alertAddBalanceError(entry: CalculatorEntry & { unitPriceNum: number; quantityNum: number}) {
    this.dialogs.alert({
      message: this.transloco.translate('calculator-errors.addBalanceErrorMessage', {
        amount: (entry.unitPriceNum * entry.quantityNum).toFixed(2),
        name: entry.name
      }),
      title: this.transloco.translate('calculator-errors.addErrorTitle')
    });
  }

  private alertEditBalanceError(entry: CalculatorEntry & { unitPriceNum: number; quantityNum: number}) {
    this.dialogs.alert({
      message: this.transloco.translate('calculator-errors.editBalanceErrorMessage', {
        amount: (entry.unitPriceNum * entry.quantityNum).toFixed(2),
        name: entry.name
      }),
      title: this.transloco.translate('calculator-errors.editErrorTitle')
    });
  }


  private save(form: UntypedFormGroup, item: CalculatorEntry): CalculatorEntry { // Pure
    if (!form.valid) {
      this.alertValidationErrors(form);
      return;
    }

    const value = form.value;
    const totalCost = value.unitPriceNum * value.quantityNum;

    // if (this.isAdding && this.remainingBalance - totalCost < 0) {
    //   this.alertAddBalanceError(value);
    //   return;
    // }
    //
    // if (!this.isAdding && this.remainingBalance + (this.item.unitPrice * this.item.quantity) - totalCost < 0) {
    //   this.alertEditBalanceError(value);
    //   return;
    // }

    return {
      ...item,
      ...value,
      byCount: value.byCount === 'true',
      unitPrice: value.unitPrice == null ? item.unitPrice : value.unitPrice / 100,
      quantity: value.quantity == null ? item.quantity : value.quantity / 10,
      quantityNum: undefined,
      unitPriceNum: undefined
    };
  }
}
