import { NgModule } from '@angular/core';
import { EnhancedIssuesPage } from './enhanced-issues.page';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from '@angular/router';
import { PipesModule } from "../../pipes/pipes.module";
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';

const routes: Routes = [
  {
    path: '',
    component: EnhancedIssuesPage,
  }
]

@NgModule({
    declarations: [EnhancedIssuesPage],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild(routes),
        PipesModule,
        TranslocoModule,
    ]
})
export class EnhancedIssuesPageModule {
}
