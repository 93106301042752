import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { QRCodePage } from './qr-code.page';
import { ComponentsModule } from '../../components/components.module';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule, Routes } from '@angular/router';
import { PipesModule } from "../../pipes/pipes.module";

const routes: Routes = [
  {
    path: '',
    component: QRCodePage,
  }
]

@NgModule({
    declarations: [QRCodePage],
    imports: [
        CommonModule,
        IonicModule,
        ComponentsModule,
        RouterModule.forChild(routes),
        TranslocoModule,
        PipesModule
    ]
})
export class QRCodePageModule {
}
