import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MicroBlog } from '~features/micro-blogs/models/micro-blog';

@Component({
  selector: 'wic-micro-blog-post',
  templateUrl: './micro-blog-post.component.html',
  styleUrls: ['./micro-blog-post.component.scss'],
})
export class MicroBlogPostComponent {
  @Input() microBlog: MicroBlog;
  @Input() lang = 'en';
  @Input() showExit = true;

  @Output() closed = new EventEmitter();
}
