import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { ShoppingListItem } from './shopping-list-items/shopping-list-item.entity';
import { VendorTimeSlot } from '~features/vendor-time-slots/vendor-time-slot.entity';

@Entity('ShoppingList')
export class ShoppingList {
  @Key id: string;
  cardNumber: string;
  isMobileOrder: boolean;
  name: string;
  vendorId?: number;
  items?: ShoppingListItem[];
  vendorTimeSlot?: VendorTimeSlot;
  orderDate?: Date;
}
