import { Injectable } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogService } from '~core/services/log.service';
import { networkStatusChanged } from '~features/app/app.actions';
import { State } from '~features/state';

const TAGS = ['Service', 'NetworkStatus'];

@Injectable()
export class NetworkStatusService {
  private status$$ = new BehaviorSubject<boolean>(null);

  get status$(): Observable<boolean> {
    return this.status$$.asObservable();
  }

  get status(): boolean {
    return this.status$$.getValue();
  }

  constructor(private log: LogService, private store: Store<State>) {}

  private emitStatusChanges(status: ConnectionStatus) {
    this.status$$.next(status.connected);
    this.store.dispatch(networkStatusChanged({ status: status.connected }));
  }

  initialize() {
    Network.getStatus().then(status => {
      this.log.trace(TAGS, `Initial network status: ${status.connectionType}`);
      this.emitStatusChanges(status);
    });

    Network.addListener('networkStatusChange', status => {
      this.log.trace(TAGS, `Network status has changed: ${status.connectionType}`);
      this.emitStatusChanges(status);
    });

    this.log.info(TAGS, 'Watching network status changes...');
  }
}
