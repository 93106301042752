import { Injectable } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { EnrichedBenefit } from '~features/benefits/models';
import { ShoppingListItem } from '~features/shopping-lists/shopping-list-items/shopping-list-item.entity';
import { AddItemFromPastPurchasesModalComponent } from '../../pages/shopping-lists/add-item-from-past-purchases-modal/add-item-from-past-purchases-modal.component';
import { AddItemFromPopoverComponent } from '../../pages/shopping-lists/add-item-from-popover/add-item-from-popover.component';
import { AddItemModalComponent } from '../../pages/shopping-lists/add-item-modal/add-item-modal.component';
import { ItemEntryPopoverComponent } from '../../pages/shopping-lists/item-entry-popover/item-entry-popover.component';
import { SelectBenefitSubcategoryModalComponent } from '../../pages/shopping-lists/select-benefit-subcategory-modal/select-benefit-subcategory-modal.component';
import { SelectVendorModalComponent } from '../../pages/shopping-lists/select-vendor-modal/select-vendor-modal.component';
import { WarnUnavailableItemsModalComponent } from '../../pages/shopping-lists/warn-unavailable-items-modal/warn-unavailable-items-modal.component';
import { ShoppingList } from '~features/shopping-lists/shopping-list.entity';
import {
  SelectTimeSlotModalComponent
} from '../../pages/shopping-lists/select-time-slot-modal/select-time-slot-modal.component';
import { SubstitutionType } from '~features/substitution-configs/substitution-config.entity';
import {
  SubstitutionInfoModalComponent
} from '../../pages/shopping-lists/substitution-info-modal/substitution-info-modal.component';
import { ConfirmItemModalComponent } from '../../pages/shopping-lists/confirm-item-modal/confirm-item-modal.component';
import { Product } from '~features/products/models';

@Injectable()
export class ShoppingListsUIService {
  selectBenefitSubcategoryModal?: HTMLIonModalElement;

  constructor(
    private modals: ModalController,
    private popovers: PopoverController,
    private alerts: AlertController,
    private transloco: TranslocoService
  ) {}

  async openSubstitutionInfoModal(substitutionType: SubstitutionType) {
    const modal = await this.modals.create({
      component: SubstitutionInfoModalComponent,
      componentProps: { substitutionType }
    });

    await modal.present();

    return modal.onDidDismiss();
  }

  async openSelectVendorModal(shoppingList: ShoppingList) {
    const modal = await this.modals.create({
      component: SelectVendorModalComponent,
      componentProps: { shoppingList }
    });

    await modal.present();

    return modal.onDidDismiss();
  }

  async openSelectBenefitSubCategoryModal() {
    this.selectBenefitSubcategoryModal = await this.modals.create({
      component: SelectBenefitSubcategoryModalComponent,
    });

    await this.selectBenefitSubcategoryModal.present();

    this.selectBenefitSubcategoryModal.onDidDismiss().then(() => {
      this.selectBenefitSubcategoryModal = null;
    });
  }

  async dismissSelectBenefitSubCategoryModal() {
    return this.selectBenefitSubcategoryModal?.dismiss();
  }

  async openAddItemModal(benefit: EnrichedBenefit) {
    const modal = await this.modals.create({
      component: AddItemModalComponent,
      componentProps: { benefit },
    });

    await modal.present();

    return modal.onDidDismiss();
  }

  async openConfirmItemModal(benefit: EnrichedBenefit, product: Product) {
    const modal = await this.modals.create({
      component: ConfirmItemModalComponent,
      componentProps: { benefit, product },
    });

    await modal.present();

    return modal.onDidDismiss();
  }

  async openAddItemFromPastPurchases(benefit: EnrichedBenefit) {
    const modal = await this.modals.create({
      component: AddItemFromPastPurchasesModalComponent,
      componentProps: { benefit },
    });

    await modal.present();

    return modal.onDidDismiss();
  }

  async openWarnUnavailableItemsModal(shoppingListItems: ShoppingListItem[]) {
    const modal = await this.modals.create({
      component: WarnUnavailableItemsModalComponent,
      componentProps: { shoppingListItems },
    });

    await modal.present();

    return modal.onDidDismiss();
  }

  async openSelectVendorTimeSlotModal() {
    const modal = await this.modals.create({
      component: SelectTimeSlotModalComponent,
    });

    await modal.present();

    return modal.onDidDismiss();
  }

  async openGenericAddItemPopover(
    componentProps: { title: string; label: string; placeholder: string },
    cssClass: string = 'add-shopping-list-item'
  ) {
    const popover = await this.popovers.create({
      component: ItemEntryPopoverComponent,
      cssClass,
      componentProps,
    });

    await popover.present();

    return popover.onDidDismiss();
  }

  // async openAddShoppingListModal() {
  //   return this.openGenericAddItemPopover(
  //     {
  //       title: this.transloco.translate('shoppingLists.addShoppingListTitle'),
  //       label: this.transloco.translate('shoppingLists.addShoppingListLabel'),
  //       placeholder: this.transloco.translate('shoppingLists.addShoppingListPlaceholder'),
  //     },
  //     'new-shopping-list-entry'
  //   );
  // }

  async openAddCVVItemModal() {
    return this.openGenericAddItemPopover({
      title: this.transloco.translate('shoppingLists.addCVVItemTitle'),
      label: this.transloco.translate('shoppingLists.addCVVItemLabel'),
      placeholder: this.transloco.translate('shoppingLists.addCVVItemPlaceholder'),
    });
  }

  async openAddCustomItemModal() {
    return this.openGenericAddItemPopover({
      title: this.transloco.translate('shoppingLists.addCustomItemTitle'),
      label: this.transloco.translate('shoppingLists.addCustomItemLabel'),
      placeholder: this.transloco.translate('shoppingLists.addCustomItemPlaceholder'),
    });
  }

  async openSelectItemFrom(benefit: EnrichedBenefit) {
    const popover = await this.popovers.create({
      component: AddItemFromPopoverComponent,
      cssClass: 'add-shopping-list-item-from',
      componentProps: { benefit },
    });

    await popover.present();

    return popover.onDidDismiss();
  }

  async confirmCheckOffForScannedItem(): Promise<boolean> {
    // TODO: Add all the transloco keys needed.
    const alert = await this.alerts.create({
      subHeader: this.transloco.translate('shoppingLists.scannedItemMatchTitle'),
      message: this.transloco.translate('shoppingLists.scannedItemMatchMessage'),
      buttons: [
        {
          text: this.transloco.translate('Ok'),
        },
        {
          role: 'cancel',
          text: this.transloco.translate('Cancel'),
        },
      ],
    });

    await alert.present();

    const result = await alert.onDidDismiss();
    return result.role !== 'cancel';
  }
}
