import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Appointment',
  uriName: 'appointments'
})
export class Appointment {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  @Key id?: string;
  authorityId: number;
  cardNumber: string;
  locationName: string;
  appointmentTime: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  phone?: string;
  email?: string;
  apptRequirements: string;
  householdId?: string;
  aptType?: string;
}
