import { NgModule } from '@angular/core';
import { AnalyticsFacade } from './analytics.facade';
import { AnalyticsService } from './analytics.service';


@NgModule({
  providers: [
    AnalyticsFacade,
    AnalyticsService
  ]
})
export class AnalyticsModule {
}
