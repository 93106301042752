import { NgModule } from '@angular/core';
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { LogService } from '~core/services/log.service';
import { ProductsDbService } from '~features/products/products-db.service';
import { ProductsSqliteService } from '~features/products/products-sqlite.service';
import { DatabaseModule } from '../database/database.module';
import { ProductFacade } from './product.facade';
import { ProductsService } from './products.service';
import { ProductsIndexedDBService } from './products-indexedDB.service';

const dbServiceFactory = (platform: Platform, sqlite: SQLite, log: LogService) =>
  platform.is('cordova') ? new ProductsSqliteService(sqlite, log) : new ProductsIndexedDBService(log);

@NgModule({
  imports: [
    DatabaseModule
  ],
  providers: [
    ProductFacade,
    ProductsService,
    SQLite,
    {
      provide: ProductsDbService,
      useFactory: dbServiceFactory,
      deps: [Platform, SQLite, LogService]
    },
  ]
})
export class ProductsModule {
}
