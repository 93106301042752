import { createSelector } from "@ngrx/store";
import { redemptionsWithConfigs } from "~features/redemptions/redemptions.selectors";
import { productsInVendorsInventory } from "~features/vendor-inventory-items/vendor-inventory-items.selectors";
import { currentShoppingListBenefit, isShoppingListMobileOrder } from "./shopping-lists.selectors";

export const redemptionCategoriesForCurrentBenefit = createSelector(
  redemptionsWithConfigs,
  currentShoppingListBenefit,
  (redemptions, benefit) =>
    redemptions.map(redemption => ({
      ...redemption,
      categories: redemption.categories.filter(cat => cat.categoryId === benefit.categoryId),
    }))
);

// TODO: Maybe switch from filtering to constructing from ground up. Could be more efficient/simpler
export const redemptionSubCategoriesForCurrentBenefit = createSelector(
  redemptionCategoriesForCurrentBenefit,
  currentShoppingListBenefit,
  (redemptions, benefit) =>
    redemptions.map(redemption => ({
      ...redemption,
      categories: redemption.categories.map(cat => ({
        ...cat,
        items: cat.items.filter(item => +item.subCategoryId === benefit.subCategoryId),
      })),
    }))
);

export const redemptionForCurrentBenefitSubCatForVendor = createSelector(
  isShoppingListMobileOrder,
  redemptionSubCategoriesForCurrentBenefit,
  //vendorInventoryItemEntities,
  productsInVendorsInventory,
  (isMobileOrder, redemptions, productsInInventory) =>
    isMobileOrder
      ? redemptions.map(redemption => ({
          ...redemption,
          categories: redemption.categories.map(cat => ({
            ...cat,
            items: cat.items.map(
              item => ({
                ...item,
                product: productsInInventory.find(product => product.itemNumber === `${item.upcPluData.upcPluCode}${item.upcPluData.upcPluCheckDigit}`),
                //carriedByVendor: !!vendorInventoryItems[`${item.upcPluData.upcPluCode}${item.upcPluData.upcPluCheckDigit}`]
              })
            ),
          })),
        }))
      : redemptions
);