import { NgModule } from '@angular/core';
import { EnhancedIssuesFacade } from './enhanced-issues.facade'

@NgModule({
  providers: [
    EnhancedIssuesFacade
  ]
})
export class EnhancedIssuesModule {
}
