import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { EnrichedBenefit } from '~features/benefits/models';

@Pipe({name: 'formatBenefit'})
export class FormatBenefitPipe implements PipeTransform {
  constructor(private transloco: TranslocoService) {
  }

  transform(benefit: EnrichedBenefit): string {
    const uomTranslation = this.transloco.translate('subcategoryTranslations.' + benefit.categoryId + '.' + benefit.subCategoryId + '.uom');
    const uomDisplay = uomTranslation.startsWith('subcategoryTranslations') ? benefit.uom : uomTranslation;

    const subcatTranslation = this.transloco.translate('subcategoryTranslations.' + benefit.categoryId + '.' + benefit.subCategoryId + '.description');
    const subcatDisplay = subcatTranslation.startsWith('subcategoryTranslations') ? benefit.subCategory : subcatTranslation;

    return `${benefit.quantity} ${uomDisplay} ${subcatDisplay}`;
  }
}
