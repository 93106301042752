import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import {
  backToCurrentBenefits,
  clearFuture,
  criteriaRestored,
  enrichBenefitsCompleted,
  loadBenefits,
  loadBenefitsFailure,
  loadBenefitsForCriteria,
  loadBenefitsProgress,
  loadBenefitsSuccess,
  loadFutureBenefits,
  nonActiveBenefitsLoaded,
  resetBenefits,
  selectBenefit,
  setCurrentFutureGroupIndex,
  viewFutureBenefits,
} from './benefits.actions';
import { BenefitCriteria, EnrichedBenefit, EnrichedBenefitInfo } from './models';

export interface IBenefitsState {
  pendingBenefits?: 'current' | 'future';
  currentBenefits?: EnrichedBenefitInfo;
  currentBenefit?: EnrichedBenefit;
  currentCriteria?: BenefitCriteria;
  futureBenefitsCache: Record<string, EnrichedBenefitInfo>;
  currentFutureGroupIndex?: number;
  lastLoadedAt?: number; // Date
  enrichedAt?: number; // Date
  isLoading: boolean;
  loadingProgress?: number;
  loadError?: Error | any;
}

export const initialState: IBenefitsState = {
  isLoading: false,
  currentFutureGroupIndex: -1,
  futureBenefitsCache: {},
};

const addBenefitsToState = state => ({
  ...state,
  pendingBenefits: 'current',
});

const restoreCriteria = (state, { criteria }) => ({
  ...state,
  currentCriteria: criteria,
});

const setToFutureBenefits = state => ({
  ...state,
  pendingBenefits: 'future',
  currentFutureGroupIndex: state.currentFutureGroupIndex + 1,
});

const addCriteriaSpecificBenefitsInState = (state, { criteria }) => ({
  ...state,
  currentCriteria: state.pendingBenefits === 'current' && criteria ? criteria : state.currentCriteria,
  isLoading: true,
  loadingProgress: 0,
  loadError: null,
});

const updateBenefitsProgressInState = (state, { percentage }) => ({
  ...state,
  loadingProgress: percentage,
});

const trackBenefitsLoadFailureInState = (state, { error }) => ({
  ...state,
  isLoading: false,
  loadingProgress: null,
  loadError: error,
});

const trackBenefitsLoadSuccessInState = state => ({
  ...state,
  lastLoadedAt: Date.now(),
  loadingProgress: 1.0,
});

const updateEnrichedBenefitsInState = (state, { enrichedBenefits }) => ({
  ...state,
  isLoading: false,
  enrichedAt: Date.now(),
  ...(state.pendingBenefits === 'future'
    ? {
        futureBenefitsCache: {
          ...state.futureBenefitsCache,
          [`${enrichedBenefits.startDate}_${enrichedBenefits.endDate}`]: enrichedBenefits,
        },
      }
    : {
        currentBenefits: enrichedBenefits,
      }),
});

const clearFutureBenefitsFromState = state => ({
  ...state,
  futureBenefits: null,
  futureCriteria: null,
  currentFutureGroupIndex: -1,
});

const resetBenefitsState = state => ({
  ...state,
  currentBenefits: null,
  currentBenefit: null,
  futureBenefits: null,
  currentCriteria: null,
  futureCriteria: null,
  lastLoadedAt: null,
  isLoading: false,
  loadingProgress: null,
  loadError: null,
});

const trackSelectedBenefitInState = (state, { benefit }) => ({
  ...state,
  currentBenefit: benefit,
});

const clearIsLoadingFlag = state => ({
  ...state,
  isLoading: false,
});

const reduce = createReducer(
  initialState,
  on(criteriaRestored, restoreCriteria),
  on(loadBenefits, addBenefitsToState),
  on(loadFutureBenefits, setToFutureBenefits),
  on(loadBenefitsForCriteria, addCriteriaSpecificBenefitsInState),
  on(loadBenefitsProgress, updateBenefitsProgressInState),
  on(loadBenefitsFailure, trackBenefitsLoadFailureInState),
  on(loadBenefitsSuccess, trackBenefitsLoadSuccessInState),
  on(nonActiveBenefitsLoaded, clearIsLoadingFlag),
  on(enrichBenefitsCompleted, updateEnrichedBenefitsInState),
  on(clearFuture, backToCurrentBenefits, viewFutureBenefits, clearFutureBenefitsFromState),
  on(resetBenefits, resetBenefitsState),
  on(selectBenefit, trackSelectedBenefitInState),
  on(setCurrentFutureGroupIndex, (state, { index }) => ({ ...state, currentFutureGroupIndex: index }))
);

export function benefitsReducer(state = initialState, action: Action): IBenefitsState {
  return reduce(state, action);
}

export const benefitsState = createFeatureSelector<IBenefitsState>('benefits');
