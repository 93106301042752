import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MicroBlog } from '~features/micro-blogs/models/micro-blog';

@Component({
  selector: 'wic-micro-blog-deck',
  templateUrl: './micro-blog-deck.component.html',
  styleUrls: ['./micro-blog-deck.component.scss'],
})
export class MicroBlogDeckComponent {
  @ViewChild('ionSlides') slides?: ElementRef | undefined;
  @Input() microBlog: MicroBlog;
  @Input() lang = 'en';
  @Input() showExit = true;

  @Output() slideIndexChanged = new EventEmitter<number>();
  @Output() closed = new EventEmitter();

  slideDidChange() {
    this.slideIndexChanged.emit(this.slides?.nativeElement.swiper?.activeIndex);
  }
}
