<ion-content [scrollY]="false">
  <ion-card-header>
    <ion-card-subtitle>{{ title }}</ion-card-subtitle>
  </ion-card-header>

  <ion-card-content>
    <ion-item mode="md" fill="outline">
      <ion-label position="floating">{{ label }}</ion-label>
      <ion-input #nameInput [placeholder]="placeholder" [autofocus]="true"></ion-input>
    </ion-item>

    <div style="display: flex;" class="ion-justify-content-between ion-margin-top">
      <ion-button shape="round" color="danger" (click)="popovers.dismiss(null, 'cancel')">{{ 'Cancel' | transloco }}</ion-button>
      <ion-button shape="round" [disabled]="!nameInput.value" (click)="popovers.dismiss(nameInput.value, 'dismiss')">{{ 'Ok' | transloco }}</ion-button>
    </div>
  </ion-card-content>
</ion-content>
