<ion-header>
  <ion-toolbar color="burgundy">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{ 'enhanced-issues.pageTitle' | transloco }}</h3>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="bottom-pad">
    <ion-item lines="none">
      <ion-label class="ion-text-wrap" >
        <h2>{{ 'enhanced-issues.pageInfo' | transloco }} </h2>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-accordion-group>
    <ion-accordion *ngFor="let ei of (enhancedIssues.all$ | async)" [value]="ei.issueId">
        <ion-item slot="header" color="light">
          <ion-label class="bold-text"><h1>{{ 'enhancedIssues.' + ei.issueName + '.label' | transloco }}</h1></ion-label>
        </ion-item>
        <ion-list slot="content">     
          <ion-item lines="none" *ngIf="('enhancedIssues.' + ei.issueName + '.info' | transloco) !== 'enhancedIssues.' + ei.issueName + '.info'">
            <ion-label class="ion-text-wrap"><h2>{{ 'enhancedIssues.' + ei.issueName + '.info' | transloco }}</h2></ion-label>
          </ion-item>
          <ion-item lines="none" class="bottom-pad">
            <ion-label>
              <ion-button expand="block" size="large" color="dark-green" (click)="navigateToPage(ei)">{{ 'enhancedIssues.' + ei.issueName + '.buttonText' | transloco }}</ion-button>
            </ion-label>
          </ion-item> 
        </ion-list>
    </ion-accordion>
  </ion-accordion-group>
</ion-content>