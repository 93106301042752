import { Injectable } from '@angular/core';
import { NavController,Platform } from '@ionic/angular';
import { Actions,createEffect,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isAfter,parseISO } from 'date-fns';
import { defer,from,iif,of } from 'rxjs';
import { catchError,delay,filter,map,switchMap,tap,withLatestFrom } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { isParsedUPCType2A } from '~features/upc/upc.state';
import { match } from '~jpma-wicshopper-imports-mono/utils/browser';
import { allowedQuantity } from '../../pipes/compute-allowed.pipe';
import { networkStatus } from '../app/app.reducer';
import { Authority } from '../authorities/models';
import { currentBenefit,currentBenefits } from '../benefits/benefits.selectors';
import { EnrichedBenefit } from '../benefits/models';
import { Card } from '../registration/models';
import { currentCard,currentProvider,currentProviderId } from '../registration/registration.selectors';
import { State } from '../state';
import { EnrichedProduct,Product,ProductStatus } from './models';
import {
  checkProduct, localLookupProduct,
  lookupProduct,
  onlineLookupProduct,
  productFound,
  productSelectionFailed,
  productsFound,
  productsSelected,
  productStatusComputed,
  selectAllProducts,
  selectProducts,
} from './product.state';
import { ProductsService, SelectAllError, SelectError } from './products.service';

export const broadbandUseAllowed = (product: Product, benefit: EnrichedBenefit): boolean =>
  benefit.subCategoryId === 0 && (product.subCategoryId === 0 || product.purchaseIndicator);

export const matchBenefitByProduct = (product: Product) => (benefit: EnrichedBenefit): boolean =>
  benefit.categoryId === product.categoryId &&
  (broadbandUseAllowed(product, benefit) || benefit.subCategoryId === product.subCategoryId);

export const matchProductByBenefit = (benefit: EnrichedBenefit) => (product: Product): boolean =>
  benefit.categoryId === product.categoryId &&
  (broadbandUseAllowed(product, benefit) || benefit.subCategoryId === product.subCategoryId);

export const matchBenefitByProducts = (products: Product[]) => (benefit: EnrichedBenefit): boolean =>
  products.some(matchProductByBenefit(benefit));

export const determineBaseStatus = (product: EnrichedProduct, authority: Authority): ProductStatus =>
  product.status ||
  (!!product.deletedAt
    ? 'nowic'
    : product.categoryId === 19 || product.categoryId === 97
    ? 'cvv'
    : authority.isOfflineOrVerifyOnly
    ? 'wic'
    : 'norx');

export const determineVerifyOnlyStatus = (product: EnrichedProduct, authority: Authority, card: Card): ProductStatus =>
  !card && product.status === 'norx' ? 'wic' : determineBaseStatus(product, authority);

// eslint-disable-next-line max-len
export const determineEnrichedStatus = (
  product: EnrichedProduct,
  benefits: EnrichedBenefit[],
  authority: Authority,
  card: Card
): ProductStatus =>
  // eslint-disable-next-line max-len
  !benefits || !benefits.length
    ? determineVerifyOnlyStatus(product, authority, card)
    : product.allowed <= 0
    ? 'no'
    : product.categoryId === 19 || product.categoryId === 97
    ? product.status
    : 'yes';

export const computePackageSize = (product: Product): number => product.size / product.packageSize;

export const computeAllowed = (product: EnrichedProduct, benefit: EnrichedBenefit): number =>
  !benefit || !product
    ? 0
    : product.categoryId === 19 || product.categoryId === 97
    ? benefit.quantity
    : broadbandUseAllowed(product, benefit) || benefit.subCategoryId > 0
    ? allowedQuantity(benefit.quantity, product.size, product.packageSize)
    : 0;

export const findBestMatchProduct = (products: EnrichedProduct[], benefit: EnrichedBenefit): EnrichedProduct =>
  products.find(matchProductByBenefit(benefit));

export const computeTotalAllowed = (products: EnrichedProduct[], benefits: EnrichedBenefit[]): number =>
  benefits && benefits.length
    ? benefits.reduce((total, benefit) => total + computeAllowed(findBestMatchProduct(products, benefit), benefit), 0)
    : 0;

export const makeNoWicProduct = (itemNumber: string): EnrichedProduct =>
  ({
    itemNumber,
    allowed: 0,
    status: 'nowic',
    category: 'N/A',
    subCategory: 'N/A',
  } as EnrichedProduct);

export const makeIndeterminateProduct = (itemNumber: string): EnrichedProduct =>
  ({
    itemNumber,
    allowed: 0,
    status: 'indeterminate',
    category: 'N/A',
    subCategory: 'N/A',
  } as EnrichedProduct);

const TAGS = ['Effects', 'ProductSelection'];

@Injectable()
export class ProductSelectionEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private log: LogService,
    private products: ProductsService,
    private nav: NavController,
    private platform: Platform
  ) {}

  selectProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectProducts),
      tap(({ categoryId, subCategoryId }) =>
        this.log.debug(TAGS, `Selecting products for ${categoryId}:${subCategoryId}...`)
      ),
      // Commented out now that cat 19 is being loaded like other products. TODO: Remove comment after testing
      // filter(({ categoryId }) => categoryId !== 19 && categoryId !== 97), // Exclude category 19
      withLatestFrom(this.store.select(currentBenefit)),
      switchMap(([{ categoryId, subCategoryId, retryCount }, benefit]) =>
        from(this.products.select(categoryId, subCategoryId, benefit?.quantity)).pipe(
          tap(
            ({ products }) =>
              this.log.trace(
                TAGS,
                `Selected products for ${categoryId}:${subCategoryId}. Product count: ${
                  products ? products.length : 0
                }`
              ),
            err =>
              retryCount >= 20
                ? this.log.error(
                    TAGS,
                    `Error selecting products for ${categoryId}:${subCategoryId}.`,
                    err
                  )
                : void 0
          ),
          map(({ products, totalCount }) => productsSelected({ products, totalCount })),
          tap({
            error: err =>
              this.log.warn(TAGS, `Unable to select products, waiting 2 seconds and retrying...`),
          }),
          catchError(error =>
            iif(
              () => error instanceof SelectError && (retryCount || 0) < 20,
              defer(() =>
                of(
                  selectProducts({
                    categoryId,
                    subCategoryId,
                    retryCount: (retryCount || 0) + 1,
                  })
                ).pipe(
                  delay(2000),
                  tap(() => this.log.debug(TAGS, 'Retrying Product Selection'))
                )
              ),
              of(productSelectionFailed({ error }))
            )
          )
        )
      )
    )
  );

  // Commented out now that cat 19 is being loaded like other products. TODO: Remove comment after testing
  //
  // selectCVBProductsForShoppingList$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(selectProducts),
  //     filter(({ categoryId }) => categoryId === 19 || categoryId === 97), // Include only cvb category 19 or 97
  //     withLatestFrom(this.store.select(currentProviderId)),
  //     switchMap(([{ categoryId, subCategoryId }, authorityId]) =>
  //       this.products.loadBySubCategory({
  //         authorityId: +authorityId,
  //         categoryId,
  //         subCategoryId,
  //         lastLoadDate: new Date().toISOString(),
  //         changesOnly: false,
  //       })
  //     ),
  //     map(products => productsSelected({ products, totalCount: products.length }))
  //   )
  // );

  selectAllProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectAllProducts),
      tap(() => this.log.debug(TAGS, 'Selecting all products...')),
      switchMap(() =>
        from(this.products.selectAll()).pipe(
          tap(({ products }) =>
              this.log.trace(TAGS,`Selected all products. Product count: ${products ? products.length : 0}`)
          ),
          map(({ products, totalCount }) => productsSelected({ products, totalCount })),
          tap({ error: err => this.log.warn(TAGS, `Unable to select products, waiting 2 seconds and retrying...`) }),
          catchError(error =>
            iif(
              () => error instanceof SelectAllError,
              defer(() =>
                of(
                  selectAllProducts()
                ).pipe(
                  delay(2000),
                  tap(() => this.log.debug(TAGS, 'Retrying Product Selection'))
                )
              ),
              of(productSelectionFailed({ error }))
            )
          )
        )
      )
    )
  );

  //
  // Commented out for now due to change in apl sync to load subcategories in ascending order of
  // lastLoadDate, in order to prioritize synchronizing ones with the oldest load date first.
  // If that doesn't work, then this code may be another solution to the problem, however it could
  // increase the server load by a non-negligible amount.
  //
  //
  // verifyProductIfOnline$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(lookupProduct),
  //     tap(({ upc }) => this.log.debug(TAGS, `Online lookup of product status for '${upc}'...`)),
  //     withLatestFrom(this.store.select(currentProvider)),
  //     switchMap(([{ upc, upcType }, authority]) =>
  //       this.products.lookupOnline(authority.id, upc).pipe(
  //         map(products => productsFound({ upc, products })),
  //         tap({
  //           error: () => this.log.warn(TAGS, `Error during online lookup of upc '${upc}'. Falling back to local db.`)
  //         }),
  //         catchError(() =>
  //           of(localLookupProduct({ upc, upcType }))
  //         )
  //       )
  //     ),
  //   )
  // );
  //
  // lookupLocalProduct$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(localLookupProduct),
  //     tap(({ upc }) => this.log.debug(TAGS, `Local lookup of product status for '${upc}'...`)),
  //     switchMap(({ upc }) =>
  //       from(this.products.lookup(upc)).pipe(
  //         map((products) => productsFound({ upc, products })),
  //         catchError(() => of(productsFound({ upc, products: [] })))
  //       )
  //     )
  //   )
  // );

  lookupProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lookupProduct),
      tap(({ upc }) => this.log.debug(TAGS, `Local lookup of product status for '${upc}'...`)),
      switchMap(({ upc }) =>
        from(this.products.lookup(upc)).pipe(
          switchMap(products =>
            iif(
              () => !products || !products.length,
              of(onlineLookupProduct({ upc })),
              of(productsFound({ upc, products }))
            )
          ),
          catchError(() => of(onlineLookupProduct({ upc })))
        )
      )
    )
  );

  onlineLookupProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(onlineLookupProduct),
      tap(({ upc }) => this.log.debug(TAGS, `Online lookup of product status for '${upc}'...`)),
      withLatestFrom(this.store.select(currentProvider)),
      switchMap(([{ upc }, authority]) =>
        this.products.lookupOnline(authority.id, upc).pipe(
          map(products => productsFound({ upc, products })),
          catchError(() => of(productsFound({ upc, products: [] })))
        )
      )
    )
  );

  findProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsFound),
      tap(({ upc, products }) =>
        this.log.debug(TAGS, `Found candidate products for '${upc}'...`, products)
      ),
      withLatestFrom(
        this.store.select(currentBenefits),
        this.store.select(networkStatus),
        this.store.select(isParsedUPCType2A)
      ),
      map(([{ upc, products }, benefitInfo, online, isUPCAType2]) => ({
        upc,
        products,
        online,
        isUPCAType2,
        benefits: !!benefitInfo ? benefitInfo.benefits : [],
      })),
      tap(({ online }) =>
        this.log.debug(
          TAGS,
          `Product calculating while network is ${online ? 'online' : 'offline'}`
        )
      ),
      map(({ products, isUPCAType2, ...rest }) =>
        // Only cat 19 items with a UPC A Type 2 barcode are eligible WIC items.
        // (Fresh baked bread uses Type 2, but is not an eligible WIC item)
        isUPCAType2
          ? {
              ...rest,
              products: products.filter(
                product => product.categoryId === 19 || product.categoryId === 97
              ),
            }
          : { ...rest, products }
      ),
      switchMap(({ upc, products, benefits, online }) =>
        iif(
          () => !products || !products.length,
          // If we did not find any products, return a no-wic product placeholder
          of([makeNoWicProduct(upc)]),
          // If we found products, filter down to just the ones that match our benefit
          of(
            [...products]
              .sort(
                (a, b) =>
                  b.categoryId * 1000 + b.subCategoryId - (a.categoryId * 1000 + a.subCategoryId)
              )
              .filter(
                // If no benefits, take first product
                product =>
                  !!benefits && !!benefits.length
                    ? benefits.some(matchBenefitByProduct(product))
                    : true
              )
          )
        ).pipe(
          // If we don't have a single match to benefits or a fallback unknown,
          // Use first products to prevent nowic status instead of ineligible
          map(matchingProducts => (matchingProducts.length ? matchingProducts : products)),
          tap((foundProducts: EnrichedProduct[]) =>
            this.log.debug(
              TAGS,
              `Found matching products for '${upc}': ${foundProducts.map(
                ({ description }) => description
              )}`,
              foundProducts
            )
          ),
          switchMap(foundProducts =>
            match(
              // Make sure that the current date is after the product's effective date:
              () =>
                !!foundProducts?.filter(product =>
                  isAfter(Date.now(), parseISO(product.effectiveDate))
                ).length,
              isEffective => [true, defer(() => of(productFound({ products: foundProducts })))],
              // Return a no-wic product placeholder if the effective date has not yet been reached
              isEffective => [
                false,
                defer(() =>
                  of(
                    productFound({
                      products: [online ? makeNoWicProduct(upc) : makeIndeterminateProduct(upc)],
                    })
                  ).pipe(
                    tap(() =>
                      this.log.warn(
                        TAGS,
                        `Product found for '${upc}' is not yet effective! Returning ${
                          online ? 'no-wic' : 'indeterminate'
                        } instead!`
                      )
                    )
                  )
                ),
              ]
            )
          )
        )
      )
    )
  );

  viewItemDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(checkProduct),
        filter(({ shouldNavigate }) => !!shouldNavigate),
        tap(({ product }) =>
          this.log.info(
            TAGS,
            `Showing item detail for ${product.categoryId}:${product.subCategoryId}...`
          )
        ),
        tap(() => this.nav.navigateForward('/item-detail'))
      ),
    { dispatch: false }
  );

  checkProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkProduct),
      filter(
        ({ product }) => !!product && product.categoryId != null && product.subCategoryId != null
      ),
      tap(({ product }) =>
        this.log.info(
          TAGS,
          `Checking WIC availability of ${product.categoryId}:${product.subCategoryId}...`
        )
      ),
      map(({ product }) => productFound({ products: [product as EnrichedProduct] }))
    )
  );

  computeProductStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productFound),
      tap(({ products }) =>
        this.log.debug(
          TAGS,
          `Found matching product for '${products && products[0].itemNumber}'...`,
          products
        )
      ),
      withLatestFrom(
        this.store.select(currentBenefits),
        this.store.select(currentProvider),
        this.store.select(currentCard)
      ),
      map(([{ products }, benefitInfo, authority, card]) => ({
        products,
        benefits: !!benefitInfo
          ? benefitInfo.benefits
              .filter(matchBenefitByProducts(products))
              .sort(
                (a, b) =>
                  b.categoryId * 1000 + b.subCategoryId - (a.categoryId * 1000 + a.subCategoryId)
              )
          : null,
        authority,
        card,
      })),
      tap(({ products, benefits }) =>
        this.log.debug(
          TAGS,
          `Found matching benefits for '${products && products[0].itemNumber}'...`,
          benefits
        )
      ),
      map(({ products, benefits, authority, card }) => ({
        product: {
          ...products[0],
          status: determineBaseStatus(products[0], authority),
          packSize: computePackageSize(products[0]),
          allowed: computeTotalAllowed(products, benefits),
        },
        benefits,
        card,
        authority,
      })),
      map(({ product, benefits, card, authority }) => ({
        ...product,
        status: determineEnrichedStatus(product, benefits, authority, card),
      })),
      tap(product =>
        this.log.debug(
          TAGS,
          `Computed status of '${product.status}' for '${product.itemNumber}'...`,
          product
        )
      ),
      map(product => productStatusComputed({ product }))
    )
  );
}
