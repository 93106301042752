<ion-header>
  <ion-toolbar color="vendors">
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false, 'cancel')">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-wrap">{{'vendors.detailTitle' | transloco}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="full-map" [ngStyle]="{'background-image': 'url(' + (settings.currentProviderMap$ | async) + ')'}"></div>

  <ion-card *ngIf="!!vendor" data-cy="detail">
    <ion-card-header translucent mode="ios">
      <ion-card-title>{{vendor.name}}</ion-card-title>
    </ion-card-header>
    <ion-card-content class="vendor-details">
      <ion-item>
        <ion-label>
          <h2>
            <strong>
              {{vendor.address1}}
            </strong>
          </h2>
          <h2 *ngIf="!!vendor.address2">{{vendor.address2}}</h2>
          <h2>{{vendor.city}}, {{vendor.state}} {{vendor.zip}}</h2>
        </ion-label>
        <ion-button
          slot="end"
          color="vendors"
          size="default"
          fill="clear"
          class="vendor-action"
          (click)="vendors.getDirections(vendor)"
        >
          <ion-icon slot="icon-only" icon="navigate"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-item *ngIf="vendor.groupCode | shouldShowMessage">
        <ion-label class="ion-text-wrap">
          <h2 [class]="vendor.groupCode === 2 ? 'text-red' : 'text-green'">
            <strong>
              {{ vendor.groupCode | groupCodedMessage | transloco }}
            </strong>
          </h2>
        </ion-label>
        <ion-note slot="end">
          <fa-icon
              [icon]="vendor.groupCode === 2 ? 'times' : 'check'"
              [styles]="vendor.groupCode === 2 ? {color: 'var(--dark-red)'} : {color: 'var(--yellow)'}">
          </fa-icon>
        </ion-note>
      </ion-item>
      <ion-item *ngIf="!!vendor.phoneNumber">
        <a href="tel:{{vendor.phoneNumber}}">{{vendor.phoneNumber}}</a>
        <ion-note slot="end">
          <fa-icon icon="phone"></fa-icon>
        </ion-note>
      </ion-item>
      <ion-item *ngIf="!!vendor.locationDetails">
        <p>{{vendor.locationDetails}}</p>
      </ion-item>
      <ion-item class="icon-bar" lines="none">
        <wic-vendor-icon slot="start" [groupCode]="vendor.groupCode"></wic-vendor-icon>
        <ion-button slot="end"
                  color="vendors"
                  size="default"
                  shape="round"
                  fill="clear"
                  class="vendor-action favorite-button"
                    (click)="vendors.toggleFavorite({vendor: vendor, favorites: favorites})">
          <fa-icon slot="icon-only" data-cy="favorite-button"
                   [icon]="(vendor | isFavorite:favorites) ? faHeart : faHeartOpen"
                   [styles]="(vendor | isFavorite:favorites) ? {color: 'var(--soft-red)'} : {color: 'var(--med-grey)'}"
                   size="lg">
          </fa-icon>
        </ion-button>
      </ion-item>

      <ion-button class="directions-button" expand="block" color="secondary" shape="round" (click)="vendors.getDirections(vendor)" data-cy="directions-button">
        <fa-icon slot="start" icon="directions" [fixedWidth]="true" size="2x"></fa-icon>
        <ion-label class="ion-text-uppercase ion-text-wrap">{{'vendors.directionsButton' | transloco}}</ion-label>
      </ion-button>
    </ion-card-content>
  </ion-card>

</ion-content>
