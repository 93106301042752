<ion-header>
  <ion-toolbar color="green">
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(undefined, undefined, 'edit-calc-entry-modal')" data-cy="entry-exit-button">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'edit-calculator-entry.contentEditTitle' | transloco}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="submit()">
        <ion-icon name="card"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <form [formGroup]="form" (submit)="$event.stopPropagation(); submit()" novalidate data-cy="calculator-entry-form">
    <ion-segment formControlName="byCount" color="yellow">
      <ion-segment-button value="false">
        {{'edit-calculator-entry.byWeightTitle' | transloco}}
      </ion-segment-button>
      <ion-segment-button value="true" (click)="roundQuantity(form)">
        {{'edit-calculator-entry.byCountTitle' | transloco}}
      </ion-segment-button>
    </ion-segment>
    <ion-list>
      <ion-item>
        <ion-input
            type="text" placeholder="{{'edit-calculator-entry.itemNamePlaceholder' | transloco}}"
            formControlName="name" [autofocus]="true"></ion-input>
      </ion-item>
    </ion-list>
    <ion-list>
      <ion-list-header>
        <ion-item>
          <ion-label>
            <h2>{{ (isByCount() ? 'edit-calculator-entry.byCountLabel' : 'edit-calculator-entry.byWeightLabel') | transloco}}</h2>
          </ion-label>
          <ion-badge slot="end" color="secondary">
            <strong class="text-monospaced">
              {{ form.value.quantityNum || 0}}
              <span *ngIf="!isByCount()">lb</span>
            </strong>
          </ion-badge>
        </ion-item>
      </ion-list-header>
      <ion-item lines="none">
        <ion-range formControlName="quantity" min="0" [max]="isByCount() ? 150 : 50" [step]="isByCount() ? 10 : 1">
          <ion-icon slot="start" name="remove" (click)="decrementQty(isByCount())"></ion-icon>
          <ion-icon slot="start" size="small" name="nutrition"></ion-icon>
          <ion-icon slot="end" name="nutrition"></ion-icon>
          <ion-icon slot="end" name="add" (click)="incrementQty(isByCount())"></ion-icon>
        </ion-range>
      </ion-item>
      <ion-item>
        <ion-input
            type="number" class="manual-input"
            [placeholder]="isByCount() ? 'edit-calculator-entry.quantityPlaceholder' : 'edit-calculator-entry.weightPlaceholder' | transloco"
            formControlName="quantityNum"
            min="0" max="1000"></ion-input>
        <fa-icon slot="end" icon="keyboard"></fa-icon>
      </ion-item>
    </ion-list>
    <ion-list>
      <ion-list-header>
        <ion-item>
          <ion-label>
            <h2>{{(isByCount() ? 'edit-calculator-entry.priceCountLabel' : 'edit-calculator-entry.priceWeightLabel') | transloco}}</h2>
          </ion-label>
          <ion-badge slot="end" color="danger">
            <strong class="text-monospaced">${{form.value.unitPriceNum || 0}}</strong>
          </ion-badge>
        </ion-item>
      </ion-list-header>
      <ion-item lines="none">
        <ion-range formControlName="unitPrice" min="0" max="300">
          <ion-icon slot="start" name="remove" (click)="decrementPrice()"></ion-icon>
          <ion-icon slot="start" size="small" name="logo-usd"></ion-icon>
          <ion-icon slot="end" name="logo-usd"></ion-icon>
          <ion-icon slot="end" name="add" (click)="incrementPrice()"></ion-icon>
        </ion-range>
      </ion-item>
      <ion-item>
        <fa-icon slot="start" icon="dollar-sign"></fa-icon>
        <ion-input
            type="number" class="manual-input"
            [placeholder]="isByCount() ? 'edit-calculator-entry.priceCountPlaceholder' : 'edit-calculator-entry.priceWeightPlaceholder' | transloco"
            formControlName="unitPriceNum"
            min="0" max="1000"></ion-input>
        <fa-icon slot="end" icon="keyboard"></fa-icon>
      </ion-item>
    </ion-list>
    <ion-list>
      <ion-item data-cy="entry-total">
        <h1><strong>{{'edit-calculator-entry.totalLabel' | transloco}} <span
            [class.negative]="">{{total | currency}}</span></strong>
        </h1>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer translucent>
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <ion-button (click)="submit()" color="green" expand="block" data-cy="entry-confirm-button">
            <fa-icon [icon]="isAdding ? 'plus' : 'check'"></fa-icon>
            <span
                class="pl-5">{{(isAdding ? "edit-calculator-entry.addButton" : "edit-calculator-entry.saveButton") | transloco}}</span>
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button (click)="modal.dismiss(null, 'cancel', 'edit-calc-entry-modal')" color="dark-red" expand="block" data-cy="entry-cancel-button">
            <fa-icon icon="times"></fa-icon>
            <span class="pl-5">{{"edit-calculator-entry.cancelButton" | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
