import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { GeoLocationService } from '~core/services/geo-location.service';

import { LogService } from '~core/services/log.service';
import { AppFacade } from '~features/app/app.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { EnvironmentService } from '~features/environment.service';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { TextZoomService } from '~core/services/text-zoom.service';
import { register } from 'swiper/element/bundle';

register();
const TAGS = ['Component', 'App'];

@Component({
  selector: 'wic-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private log: LogService,
    private platform: Platform,
    public appFacade: AppFacade,
    private geolocation: GeoLocationService,
    public enhancedMode: EnhancedModeFacade,
    public registration: RegistrationFacade,
    public env: EnvironmentService,
    private storage: Storage,
    private textZoom: TextZoomService,
  ) {
    this.initializeApp().then(() =>
      this.log.debug(TAGS, 'App startup completed.')
    );
  }

  async initializeApp() {
    this.log.info(TAGS, 'Initializing application...');
    await this.platform.ready();
    await this.storage.create();
    this.log.info(TAGS, 'Ionic/Cordova platform is ready!');
    this.log.info(TAGS, `Ionic Storage Driver is: ${this.storage.driver}`);
    await this.storage.forEach((value, key) =>
      this.log.debug(TAGS, `Default Storage Key found: ${key}`, value)
    );

    // Handled here to prevent resizing after first render
    if(this.platform.is('capacitor')) {
      await this.textZoom.adjustFontSize();
    }

    // this.geolocation.initialize();

    // await this.checkIXDB();
    // await this.checkLSDB();

    this.appFacade.initialize();
  }
}
