import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFingerprint } from '@fortawesome/free-solid-svg-icons';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { NotificationChooserComponent } from './notification-chooser/notification-chooser.component';
import { ScheduleChooserModalComponent } from './schedule-chooser-modal/schedule-chooser-modal.component';

import { SettingsPage } from './settings.page';

const routes: Routes = [
  {
    path: '',
    component: SettingsPage
  }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        HttpClientModule,
        RouterModule.forChild(routes),
        ComponentsModule,
        PipesModule,
        InlineSVGModule.forRoot(),
        TranslocoModule,
        FontAwesomeModule
    ],
    declarations: [
        SettingsPage,
        NotificationChooserComponent,
        ScheduleChooserModalComponent
    ]
})
export class SettingsPageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(faFingerprint);
  }
}
