import { Component } from '@angular/core';
import { PendingLocalNotificationSchema } from '@capacitor/local-notifications';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { AppointmentNotificationsService } from '~features/notifications/appointment-notifications.service';
import { BenefitNotificationsService } from '~features/notifications/benefit-notifications.service';
import { Card } from '~features/registration/models';
import { RegistrationFacade } from '~features/registration/registration.facade';

export interface CardNotifications extends Card {
  benefitNotifications: PendingLocalNotificationSchema[];
  appointmentNotifications: PendingLocalNotificationSchema[];
}

@Component({
  selector: 'wic-notification-investigator-modal',
  templateUrl: './notification-investigator-modal.component.html',
  styleUrls: ['./notification-investigator-modal.component.scss'],
})
export class NotificationInvestigatorModalComponent {
  cardNotifications$: Observable<CardNotifications> = this.registration.currentCard$.pipe(
    switchMap(async card => ({
      ...card,
      benefitNotifications: await this.benefitNotifications.getCurrentNotifications(card.cardNumber),
      appointmentNotifications: await this.appointmentNotifications.getCurrentNotifications(card.cardNumber)
    })),
  );

  constructor(
    public log: LogService,
    public modal: ModalController,
    public registration: RegistrationFacade,
    private benefitNotifications: BenefitNotificationsService,
    private appointmentNotifications: AppointmentNotificationsService
  ) {
  }

  selectCard(cards: Card[], event) {
    this.registration.selectCard(cards.find(card => card.cardNumber === event.detail.value));
  }
}
