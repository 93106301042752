import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { iif, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  first,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';
import { DialogsService } from '~core/services/dialogs.service';
import { GoogleMapsApiLoaderService } from '~core/services/google-maps-api-loader.service';
import { LogService } from '~core/services/log.service';
import { ThemeableBrowserService } from '~core/services/themeable-browser.service';
import { restoreCalculatorBalances, restoreCalculatorEntries } from '~features/calculators/calculators.actions';
import {
  cardEdited,
  deleteCard,
  deleteCardConfirmed,
  restoreRegistration,
  selectCard,
  setCurrentCard,
  skipCardRegistration,
} from '~features/registration/registration.actions';
import {
  currentCard,
  currentCards,
  currentProvider,
  currentRegistration,
} from '~features/registration/registration.selectors';
import { UPCInfo } from '~features/upc/models';
import { isInitialized } from '../app/app.reducer';
import {
  loadBenefits,
  loadBenefitsForCriteria,
  loadBenefitsWhenInitialized,
  resetBenefits,
  selectBenefit,
  viewBenefit,
} from '../benefits/benefits.actions';
import { currentBenefitsCriteria } from '../benefits/benefits.selectors';
import { BenefitCriteria } from '../benefits/models';
import { loadEnhancedDevice } from '../enhanced-mode/enhanced-device.state';
import { aplDatabaseInitialized, selectProducts } from '../products/product.state';
import { Card } from '../registration/models';
import { RegistrationFacade } from '../registration/registration.facade';
import { CloudSettingsService } from '../settings/cloud-settings-storage.service';
import { SettingsFacade } from '../settings/settings.facade';
import { State } from '../state';
import { RECENT_UPCS_KEY, restoreRecentUPCs } from '../upc/upc.state';
import {
  appInitialized,
  appResumed,
  cloudSettingsError,
  cloudSettingsLoaded,
  googleMapsApiLoaded,
  initializationCompleted,
  webLinkOpened,
} from './app.actions';
import { replaceCardNumberLinkVariable } from '~features/ionic/ionic.effects';

const TAGS = ['Effects', 'Application'];

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private cloudSettings: CloudSettingsService,
    private dialogs: DialogsService,
    private log: LogService,
    private nav: NavController,
    private registrationFacade: RegistrationFacade,
    private settingsFacade: SettingsFacade,
    private browser: ThemeableBrowserService,
    private transloco: TranslocoService,
    private storage: Storage,
    private store: Store<State>,
    private googleMapsApiLoader: GoogleMapsApiLoaderService
  ) {}

  loadGoogleMapsApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => ['vendors', 'offices'].some(route => payload.routerState.url.includes(route))),
      // withLatestFrom(this.store.select(mapsApiLoaded)),
      // filter(([, apiLoaded]) => !apiLoaded),
      take(1),
      switchMap(() => this.googleMapsApiLoader.loadGoogleMapsApi()),
      map(() => googleMapsApiLoaded())
    )
  );

  loadEnhancedDeviceRegistrationOnStartup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialized, appResumed),
      switchMap(() => this.actions$.pipe(ofType(cloudSettingsLoaded), first())),
      tap(() => this.log.debug(TAGS, 'Restored enhanced device mode')),
      map(() => loadEnhancedDevice())
    )
  );

  loadBenefitsOnRegistrationRestoration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(restoreRegistration),
      filter(({ registration }) => !!registration && !!registration.authority && !!registration.currentCard),
      tap(() => this.log.debug(TAGS, 'Restored registration has an authority and current card')),
      withLatestFrom(this.store.select(currentBenefitsCriteria)),
      tap(([{ registration }, criteria]) =>
        this.log.trace(TAGS, 'Current registration and criteria: ', registration, criteria)
      ),
      switchMap(([{ registration }, criteria]) =>
        iif(
          () => !!criteria,
          of(criteria),
          of({
            authority: registration.authority,
            cardNumber: registration.currentCard.cardNumber,
            householdId: registration.currentCard.householdId,
          })
        )
      ),
      map(criteria => loadBenefitsWhenInitialized({ criteria }))
    )
  );

  loadCloudSettingsOnStartup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialized, appResumed),
      tap(() => this.log.info(TAGS, 'Loading cloud settings...')),
      tap(() => this.cloudSettings.load()),
      switchMap(() =>
        this.cloudSettings.loaded$.pipe(
          first(settings => !!settings),
          timeout(5000),
          catchError(err => {
            this.log.error(TAGS, 'Settings could not be loaded! Error was:', err);
            return of(new Error('Settings could not be loaded!'));
          })
        )
      ),
      concatMap(result =>
        iif(
          () => result instanceof Error,
          of(cloudSettingsError({ error: result })),
          of(cloudSettingsLoaded({ settings: result }))
        )
      )
    )
  );

  fakeRestoreRegistrationOnCloudSettingsError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cloudSettingsError),
      tap(() => this.log.error(TAGS, 'Error loading cloud settings!')),
      map(() => restoreRegistration({ registration: null }))
    )
  );

  restoreCalculatorEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialized, appResumed),
      map(() => restoreCalculatorEntries())
    )
  );

  restoreCalculatorBalances$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialized, appResumed),
      map(() => restoreCalculatorBalances())
    )
  );

  setCurrentBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewBenefit),
      tap(({ benefit }) => this.log.info(TAGS, `Selecting benefit ${benefit.category}/${benefit.subCategory}...`)),
      map(({ benefit }) => selectBenefit({ benefit }))
    )
  );

  loadProductsForBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewBenefit),
      tap(({ benefit }) =>
        this.log.info(TAGS, `Loading products for benefit ${benefit.category}/${benefit.subCategory}...`)
      ),
      map(({ benefit }) => selectProducts({ categoryId: benefit.categoryId, subCategoryId: benefit.subCategoryId }))
    )
  );

  restoreRecentUPCs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialized, appResumed),
      tap(() => this.log.debug(TAGS, 'Restoring recently checked UPCs...')),
      map(() => JSON.parse(localStorage.getItem(RECENT_UPCS_KEY))),
      map((recentUPCs: UPCInfo[]) => restoreRecentUPCs({ recentUPCs }))
    )
  );

  // TODO: Add effect to handle background resumes

  // TODO: Add effect to handle app initialization or resumes

  finalizeInitialization$ = createEffect(() =>
    this.actions$.pipe(
      // TODO: Add more initialization completion actions here
      ofType(cloudSettingsLoaded, restoreRegistration, aplDatabaseInitialized),
      tap(action =>
        this.log.trace(
          TAGS,
          'Initializing: Checking if initialization complete...',
          'Initialization action: ' + action.type
        )
      ),
      withLatestFrom(this.store.select(isInitialized)),
      filter(([, canContinue]) => !!canContinue),
      tap(() => this.log.info(TAGS, 'Initialization is complete!')),
      withLatestFrom(this.store.select(currentRegistration)),
      tap(([, { authority, pendingCard }]) =>
        !!pendingCard
          ? this.log.warn(TAGS, `A pending card was found, redirecting to registration.`)
          : !!authority
          ? this.log.info(TAGS, `An existing registration was found, redirecting to home.`)
          : this.log.warn(TAGS, `No current authority, redirecting to select provider.`)
      ),
      delay(700),
      map(([, { authority, pendingCard }]) => initializationCompleted({ authority, pendingCard }))
    )
  );

  redirectToStartingPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializationCompleted),
        tap(({ authority, pendingCard }) =>
          !!pendingCard
            ? (this.nav.navigateRoot('/select-provider'),
              setTimeout(() => this.nav.navigateForward(['/registration']), 100))
            : !!authority
            ? this.nav.navigateRoot('/home')
            : this.nav.navigateRoot('/select-provider')
        )
      ),
    { dispatch: false }
  );

  loadBenefitsWhenInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBenefitsWhenInitialized),
      tap(() => this.log.debug(TAGS, 'Benefits load is pending, awaiting completion of app initialization...')),
      switchMap(({ criteria, force }) =>
        this.store.select(isInitialized).pipe(
          filter(canContinue => !!canContinue),
          takeUntil(this.actions$.pipe(ofType(loadBenefits))),
          map(() => loadBenefits({ criteria, force }))
        )
      )
    )
  );

  webLinkOpened$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(webLinkOpened),
        tap(({ linkUrl }) => this.log.trace(TAGS, `Web link opened: ${linkUrl}`)),
        withLatestFrom(this.store.select(currentCard)),
        map(([{linkUrl}, card]) => replaceCardNumberLinkVariable(linkUrl, card)),
        tap(linkUrl => this.log.debug(TAGS, `Processed link to be opened: ${linkUrl}`)),
        switchMap(linkUrl => this.browser.openWebLink(linkUrl))
      ),
    { dispatch: false }
  );

  matchCurrentCardToCurrentBenefits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBenefitsForCriteria),
      withLatestFrom(this.store.select(currentCards)),
      tap(() => this.log.trace(TAGS, 'Finding card that matches current benefits criteria...')),
      map(([{ criteria }, cards]) => cards.find(card => card.cardNumber === criteria.cardNumber)),
      filter(card => !!card),
      tap(() => this.log.trace(TAGS, 'Found card that matches current benefits criteria!')),
      map(card => setCurrentCard({ card }))
    )
  );

  syncRegistrationOnCardChanges$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(skipCardRegistration, setCurrentCard, deleteCardConfirmed, cardEdited),
        withLatestFrom(this.store.select(currentRegistration)),
        tap(([, registration]) =>
          this.log.debug(TAGS, 'Syncing current registration to cloud settings:', registration)
        ),
        tap(([, registration]) => this.cloudSettings.save({ registration }))
      ),
    { dispatch: false }
  );

  selectCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectCard),
      withLatestFrom(this.store.select(currentProvider), this.store.select(currentCard)),
      filter(([{ card }, , current]) => card.cardNumber !== current.cardNumber),
      tap(([{ card }]) => this.log.trace(TAGS, 'Selecting card: ', card.cardNumber)),
      switchMap(([{ card }, authority]) =>
        of({
          authority,
          cardNumber: card.cardNumber,
          householdId: card.householdId,
        } as BenefitCriteria)
      ),
      tap(criteria => this.log.trace(TAGS, 'Loading benefits for card: ', criteria.cardNumber)),
      map(criteria => loadBenefits({ criteria, force: true }))
    )
  );

  deleteCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCard),
      switchMap(({ card }) =>
        this.dialogs
          .confirm({
            message: 'Are you sure you wish to delete this card?',
            title: 'Delete WIC Card',
            okText: 'Yes',
            cancelText: 'No',
          })
          .pipe(map((result): [Card, boolean] => [card, result]))
      ),
      filter(([, result]) => !!result),
      tap(([card, result]) => console.log(card, result)),
      tap(([card]) => this.log.warn(TAGS, `User has deleted card ${card.cardNumber}`)),
      map(([card]) => deleteCardConfirmed({ card }))
    )
  );

  clearBenefitsWhenLastCardDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCardConfirmed),
      withLatestFrom(this.store.select(currentCards)),
      tap(([, remainingCards]) => console.log('remaining cards', remainingCards)),
      filter(([, remainingCards]) => !!remainingCards && !!remainingCards.length),
      tap(() => this.log.info(TAGS, 'Last WIC card deleted. Clearing benefits!')),
      map(() => resetBenefits())
    )
  );
}
