import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LogService } from '~core/services/log.service';
import { currentProviderMap } from '~features/settings/settings.selectors';

import { State } from '../state';
import { AdminSettings, AlertOptions, UserSettings } from './models';
import {
  adminSettings,
  adminSettingsChanged,
  analyticsAppId,
  apiHost,
  appointmentOptions,
  benefitOptions,
  cdnHost,
  currentLanguage,
  editAppointmentOptions,
  editBenefitOptions,
  editedAppointmentOptions,
  editedBenefitOptions,
  editedUserSettings,
  initialSettingsState,
  mapsCDN,
  productCDN,
  saveEditedUserSettings,
  selectedLanguage,
  selectLanguage,
  settingsReset,
  startEditingUserSettings,
  userSettings,
  userSettingsChanged,
  userSettingsEdited
} from './settings.state';

const TAGS = ['Facade', 'Settings'];

@Injectable()
export class SettingsFacade {
  get adminSettings$(): Observable<AdminSettings> {
    return this.store.select(adminSettings);
  }

  get apiHost$(): Observable<string> {
    return this.store.select(apiHost);
  }

  get cdnHost$(): Observable<string> {
    return this.store.select(cdnHost);
  }

  get productCDN$(): Observable<string> {
    return this.store.select(productCDN);
  }

  get mapsCDN$(): Observable<string> {
    return this.store.select(mapsCDN);
  }

  get currentProviderMap$(): Observable<string> {
    return this.store.select(currentProviderMap).pipe(
      // tap(url => this.log.debug(TAGS, `Current provider map uri is: ${url}`))
    );
  }

  get analyticsAppId$(): Observable<string> {
    return this.store.select(analyticsAppId);
  }


  get userSettings$(): Observable<UserSettings> {
    return this.store.select(userSettings);
  }

  get editedUserSettings$(): Observable<UserSettings> {
    return this.store.select(editedUserSettings);
  }

  get currentLanguage$(): Observable<string> {
    return this.store.select(currentLanguage);
  }

  get selectedLanguage$(): Observable<string> {
    return this.store.select(selectedLanguage);
  }

  get benefitOptions$(): Observable<AlertOptions> {
    return this.store.pipe(select(benefitOptions));
  }

  get appointmentOptions$(): Observable<AlertOptions> {
    return this.store.pipe(select(appointmentOptions));
  }

  get editedBenefitOptions$(): Observable<AlertOptions> {
    return this.store.pipe(select(editedBenefitOptions));
  }

  get editedAppointmentOptions$(): Observable<AlertOptions> {
    return this.store.pipe(select(editedAppointmentOptions));
  }


  constructor(private log: LogService, private store: Store<State>) {
  }


  setAdminSettings(settings: AdminSettings): void {
    this.log.debug(TAGS, 'Admin settings set to:', settings);
    this.store.dispatch(adminSettingsChanged({settings}));
  }

  setUserSettings(settings: UserSettings): void {
    this.log.debug(TAGS, 'User settings set to: ', settings);
    settings = settings || initialSettingsState.user; // Use defaults if provided settings are falsy
    this.store.dispatch(userSettingsChanged({settings}));
  }

  selectLanguage(lang: string): void {
    this.store.dispatch(selectLanguage({lang}));
  }

  editBenefitOptions(options: AlertOptions): void {
    this.store.dispatch(editBenefitOptions({options}));
  }

  editAppointmentOptions(options: AlertOptions): void {
    this.store.dispatch(editAppointmentOptions({options}));
  }

  editUserSettings(settings: UserSettings): void {
    this.log.debug(TAGS, 'User settings edited: ', settings);
    this.store.dispatch(userSettingsEdited({settings}));
  }

  startEditingUserSettings(): void {
    this.log.debug(TAGS, 'Begin editing user settings.');
    this.store.dispatch(startEditingUserSettings());
  }

  saveEditedUserSettings(): void {
    this.store.dispatch(saveEditedUserSettings());
  }

  reset() {
    this.log.debug(TAGS, 'Settings have been reset!');
    this.store.dispatch(settingsReset());
  }
}
