import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {EMPTY, Observable} from 'rxjs';
import {catchError, filter, map, startWith, take, tap} from 'rxjs/operators';

import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { CalculatorsFacade } from '~features/calculators/calculators.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { SubCategoriesFacade } from '~features/subcategories/subcategories.facade';
import { UpcFacade } from '~features/upc/upc.facade';
import { CalculatorEntryListComponent } from '../../components/calculator-entry-list/calculator-entry-list.component';
import { EmptyKey } from '../../core/services/entity.service';
import { SubCategory } from '../../features/subcategories/models';
import { RegistrationFacade } from './../../features/registration/registration.facade';

@Component({
  selector: 'wic-calculator-uom',
  templateUrl: './calculator-uom.page.html',
  styleUrls: ['./calculator-uom.page.scss'],
})
export class CalculatorUomPage implements OnInit {
  @ViewChild('entryList', {static: false}) entryList: CalculatorEntryListComponent;

  uomCategory: number;
  uomSubCategory: number;
  uom$: Observable<string>;
  category$: Observable<SubCategory>;

  constructor(
    public benefits: BenefitsFacade,
    public calculators: CalculatorsFacade,
    public enhancedMode: EnhancedModeFacade,
    public registration: RegistrationFacade,
    public upc: UpcFacade,
    private subCats: SubCategoriesFacade,
    private route: ActivatedRoute,
    private analytics: AnalyticsFacade
  ) {
  }

  ngOnInit() {
    this.uomCategory = +this.route.snapshot.paramMap.get('categoryId');
    this.registration.currentProvider$.pipe(
      take(1),
      tap(authority =>
        this.subCats.load(
          0,
          {
            parents: {
              authorities: authority.id,
              apl: EmptyKey,
              categories: this.uomCategory
            }
          })
      ),
      catchError(() => EMPTY)
    ).subscribe();

    this.analytics.pageView('calculator', ['uom', `${this.uomCategory || 0}`, `${0}`]);
    this.category$ = this.subCats.all$.pipe(
      map(subCats =>
        subCats.find(subCat => subCat.categoryId === this.uomCategory && subCat.subCategoryId === 0)
      ),
      filter(subCat => !!subCat),
    );
    this.uom$ = this.category$.pipe(
      map(subCat => subCat.uom),
      startWith('oz'),
    );
  }
}
