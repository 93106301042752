import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { format, subMonths } from 'date-fns';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { EntityCriteria } from '~core/services/entity.service';
import { LogService } from '~core/services/log.service';
import { currentBenefitsCriteria } from '../benefits/benefits.selectors';
import {
  clearRedemptions,
  loadAllRedemptions,
  loadAllRedemptionsFailure,
  loadAllRedemptionsSuccess,
  loadManyRedemptions,
  loadOlderMonthsRedemptions,
  loadRedemptionsByBenefitCriteria,
  offsetMonths,
  refreshRedemptions,
  resetRedemptions,
} from './redemptions.state';
import { ConfigService } from '~core/config/config.service';
import { openAddItemFromPastPurchases } from '~features/shopping-lists/shopping-lists.actions';

const TAGS = ['Effects', 'Redemption'];

@Injectable()
export class RedemptionsEffects {
  constructor(private readonly actions$: Actions, private readonly config: ConfigService, private readonly log: LogService, private readonly store: Store) {}

  resetRedemptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetRedemptions),
      map(() => clearRedemptions())
    )
  );

  loadRedemptionsOnNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => payload.routerState.url.includes('redemption-history')),
      map(() => loadRedemptionsByBenefitCriteria())
    )
  );

  loadRedemptionsOnRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshRedemptions, openAddItemFromPastPurchases),
      map(() => loadRedemptionsByBenefitCriteria())
    )
  );

  loadRedemptionsByBenefitCriteria = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRedemptionsByBenefitCriteria),
      withLatestFrom(this.store.select(currentBenefitsCriteria)),
      map(([, criteria]) => criteria),
      tap(criteria => this.log.debug(TAGS, 'Loading redemptions...', criteria)),
      filter(criteria => criteria?.authority?.enableRedemptionHistory),
      map(
        (criteria): EntityCriteria => ({
          parents: {
            cards: criteria.cardNumber,
          },
          query: {
            authorityId: criteria.authority.id,
            appId: this.config.appId,
            startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
            endDate: format(new Date(), 'yyyy-MM-dd'),
          },
        })
      ),
      map(criteria => loadAllRedemptions({ criteria }))
    )
  );

  loadRedemptionsForOlderMonths$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOlderMonthsRedemptions),
      withLatestFrom(this.store.select(currentBenefitsCriteria), this.store.select(offsetMonths)),
      map(
        ([, criteria, offset]): EntityCriteria => ({
          parents: {
            cards: criteria.cardNumber,
          },
          query: {
            authorityId: criteria.authority.id,
            appId: this.config.appId,
            startDate: format(subMonths(new Date(), offset + 1), 'yyyy-MM-dd'),
            endDate: format(subMonths(new Date(), offset), 'yyyy-MM-dd'),
          },
        })
      ),
      map(criteria => loadManyRedemptions({ criteria }))
    )
  );

  completeRefresh$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshRedemptions),
        switchMap(({ refreshEvent }) =>
          this.actions$.pipe(
            ofType(loadAllRedemptionsSuccess, loadAllRedemptionsFailure),
            take(1),
            tap(() => refreshEvent.complete())
          )
        )
      ),
    { dispatch: false }
  );
}
