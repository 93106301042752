import { NgModule } from '@angular/core';
import { AplmessagesModule } from '~features/aplmessages/aplmessages.module';
import { BannersModule } from '~features/banners/banners.module';
import { CalculatorsModule } from '~features/calculators/calculators.module';
import { MicroBlogsModule } from '~features/micro-blogs/micro-blogs.module';
import { MissingProductModule } from '~features/missing-product/missing-product.module';
import { ReportingModule } from '~features/reporting/reporting.module';


import { AnalyticsModule } from './analytics/analytics.module';
import { AppFacade } from './app/app.facade';
import { AppointmentsModule } from './appointments/appointments.module';
import { AuthoritiesModule } from './authorities/authorities.module';
import { BenefitsModule } from './benefits/benefits.module';
import { CategoriesModule } from './categories/categories.module';
import { EnhancedModeModule } from './enhanced-mode/enhanced-mode.module';
import { EnvModule } from './env.module';
import { ErrorModule } from './error/error.module';
import { HomeModule } from './home/home.module';
import { IonicModule } from './ionic/ionic.module';
import { LanguagesModule } from './languages/languages.module';
import { NotificationsModule } from './notifications/notifications.module';
import { OfficesModule } from './offices/offices.module';
import { ProductsModule } from './products/products.module';
import { ReceiptsModule } from './receipts/receipts.module';
import { RedemptionsModule } from './redemptions/redemptions.module';
import { RegistrationModule } from './registration/registration.module';
import { SettingsModule } from './settings/settings.module';
import { StateModule } from './state.module';
import { SubcatConfigsModule } from './subcat-configs/subcat-configs.module';
import { SubCategoriesModule } from './subcategories/subcategories.module';
import { UpcModule } from './upc/upc.module';
import { VendorsModule } from './vendors/vendors.module';
import { EnhancedIssuesModule } from './enhanced-issues/enhanced-issues.module';


@NgModule({
  imports: [
    StateModule,

    EnvModule,

    IonicModule,
    AnalyticsModule,
    AppointmentsModule,
    AplmessagesModule,
    AuthoritiesModule,
    BannersModule,
    BenefitsModule,
    ReceiptsModule,
    CalculatorsModule,
    CategoriesModule,
    EnhancedIssuesModule,
    EnhancedModeModule,
    ErrorModule,
    HomeModule,
    LanguagesModule,
    MicroBlogsModule,
    MissingProductModule,
    NotificationsModule,
    OfficesModule,
    ProductsModule,
    RedemptionsModule,
    RegistrationModule,
    ReportingModule,
    SettingsModule,
    SubcatConfigsModule,
    SubCategoriesModule,
    UpcModule,
    VendorsModule
  ],
  providers: [
    AppFacade
  ]
})
export class FeaturesModule {
}
