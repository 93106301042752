<ion-header translucent>
  <ion-toolbar color="vendors">
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false, 'cancel')">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'vendors.searchTitle' | transloco}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <wic-custom-error-toolbar *ngIf="(vendor.vendorUserLocation$ | async) === undefined"
    [title]="('vendors.noLocationTitle' | transloco)" 
    [message]="('vendors.noLocationMessage' | transloco)"
  >   
  </wic-custom-error-toolbar>
  <form [formGroup]="form">
    <ion-list>
      <ion-list-header>
        {{'vendors.maxDistanceHeader' | transloco }} {{form.get('radius').value}} {{'vendors.distanceLabel' | transloco}}
      </ion-list-header>
      <ion-item>
        <ion-range min="1" max="100" formControlName="radius" color="secondary" data-cy="distance-range-filter">
          <ion-label slot="start">{{'vendors.closeLabel' | transloco}}</ion-label>
          <ion-label slot="end">{{'vendors.farLabel' | transloco}}</ion-label>
        </ion-range>
      </ion-item>
      <ion-item>
        <ion-checkbox slot="start" formControlName="anywhere" color="secondary" data-cy="distance-anywhere-filter"></ion-checkbox>
        <ion-label>{{'vendors.storesAnywhereLabel' | transloco}}</ion-label>
      </ion-item>
    </ion-list>
    <ion-list>
      <ion-radio-group formControlName="groupCodeFilter">
        <ion-list-header>
          {{'vendors.vendorTypesHeader' | transloco}}
        </ion-list-header>
        <ion-item>
          <ion-radio slot="start" [value]="0" color="med-gray" data-cy="any-filter"></ion-radio>
          <ion-label>{{'vendors.anyTypeLabel' | transloco}}</ion-label>
        </ion-item>
        <ng-container *ngIf="vendor.groupCodes$ | async as groupCodes">
          <ion-item *ngFor="let groupCode of groupCodes">
            <ion-radio slot="start" [value]="groupCode.code" [color]="groupCode.color" data-cy="any-filter"></ion-radio>
            <ion-label>{{ groupCode.label | transloco }}</ion-label>
          </ion-item>
        </ng-container>
      </ion-radio-group>
    </ion-list>
    <ion-list>
      <ion-list-header>
        {{'vendors.favsPicksHeader' | transloco}}
      </ion-list-header>
      <ion-item>
        <ion-checkbox slot="start" formControlName="onlyFavorites" color="soft-red" data-cy="favorite-filter"></ion-checkbox>
        <ion-label>{{'vendors.favsOnlyLabel' | transloco}}</ion-label>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer translucent>
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button expand="block" color="vendors" (click)="modal.dismiss(form.value, 'ok')" data-cy="apply-search-button">
            <fa-icon icon="check" [fixedWidth]="true"></fa-icon>
            <span class="ion-text-uppercase">{{'vendors.applySearchButton' | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
