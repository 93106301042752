import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { protocolButtonActivated } from '~features/home/home.state';
import { HomeButton } from '~features/home/models';
import { MissingProduct } from '~features/missing-product/models';
import { EnrichedProduct, Product } from '~features/products/models';
import { State } from '~features/state';
import {
  captureMissingProductPhoto,
  clearCapturedLabelPhoto,
  clearCapturedProductPhoto,
  clearCapturedUpcPhoto,
  clearCurrentStore,
  clearUPC,
  currentStore,
  currentUPC,
  displayIsThisYourStore,
  hasLabelPhoto,
  hasProductPhoto,
  hasUpcImage,
  labelImage,
  MissingProductFacadeBase,
  productImage, reportMissingProduct,
  scanMissingProductBarcode,
  submitMissingProductReport,
  upcImage
} from './missing-product.state';

const TAGS = ['Facade', 'Missing Product'];

@Injectable()
export class MissingProductFacade extends MissingProductFacadeBase {
  productImage$ = this.store.select(productImage);
  labelImage$ = this.store.select(labelImage);
  upcImage$ = this.store.select(upcImage);
  currentStore$ = this.store.select(currentStore);

  hasProductImage$ = this.store.select(hasProductPhoto);
  hasLabelImage$ = this.store.select(hasLabelPhoto);
  hasUpcImage$ = this.store.select(hasUpcImage);

  currentUPC$ = this.store.select(currentUPC).pipe(
    tap(upc => this.log.warn(TAGS, 'Current missing product upc: ' + upc))
  );

  constructor(private store: Store<State>, private log: LogService) {
    super(MissingProduct, store);
  }

  displayIsThisYourStore(): void {
    this.store.dispatch(displayIsThisYourStore());
  }

  reportProduct(product: EnrichedProduct): void {
    this.store.dispatch(reportMissingProduct({product}));
  }

  captureProductPhoto(): void {
    this.store.dispatch(captureMissingProductPhoto({isLabel: false, isUpc: false}));
  }

  captureLabelPhoto(): void {
    this.store.dispatch(captureMissingProductPhoto({isLabel: true, isUpc: false}));
  }

  captureUpcPhoto(): void {
    this.store.dispatch(captureMissingProductPhoto({isLabel: false, isUpc: true}));
  }

  clearCapturedPhoto(): void {
    this.store.dispatch(clearCapturedProductPhoto());
  }

  clearCapturedLabel(): void {
    this.store.dispatch(clearCapturedLabelPhoto());
  }

  clearCapturedUpcPhoto(): void {
    this.store.dispatch(clearCapturedUpcPhoto());
  }

  scanBarcode(): void {
    this.store.dispatch(scanMissingProductBarcode());
  }

  clearUPC(): void {
    this.store.dispatch(clearUPC());
  }

  clearCurrentStore() :void {
    this.store.dispatch(clearCurrentStore());
  }

  send(missing: MissingProduct): void {
    this.store.dispatch(submitMissingProductReport({missing}));
  }
}
