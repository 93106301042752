import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { LogService } from '~core/services/log.service';
import { Receipt } from '~features/receipts/models';
import { State } from '../state';

import {
  acquireReceiptPhoto,
  currentReceipt,
  currentReceiptImage, deleteReceipt,
  hasReceiptImage,
  hasReceiptsHistory,
  receiptsHistory,
  restorePriorReceipts,
  selectedReceiptIndex,
  selectReceipt
} from './receipts.state';

const TAGS = ['Facade', 'Receipts'];

@Injectable()
export class ReceiptsFacade {
  currentReceipt$ = this.store.select(currentReceipt);
  receiptImage$ = this.store.select(currentReceiptImage);
  receiptsHistory$ = this.store.select(receiptsHistory);
  hasReceiptImage$ = this.store.select(hasReceiptImage);
  hasReceiptsHistory$ = this.store.select(hasReceiptsHistory);
  selectedIndex$ = this.store.select(selectedReceiptIndex);

  constructor(private store: Store<State>, private log: LogService) {
  }

  restorePriorReceipts(): void {
    this.store.dispatch(restorePriorReceipts());
  }

  captureReceipt(): void {
    this.store.dispatch(acquireReceiptPhoto());
  }

  select(receipt: Receipt): void {
    this.store.dispatch(selectReceipt({receipt}));
  }

  delete(index: number): void {
    this.store.dispatch(deleteReceipt({index}));
  }
}
