import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { BBValidators } from '~validators';
import { Authority } from '~features/authorities/models';

export interface BuildValidatorOptions {
  compareControlName?: string;
  reverseCompare: boolean;
  shouldValidate: boolean;
  minLength?: number;
  maxLength?: number;
}

export const buildValidators = ({compareControlName, reverseCompare, shouldValidate, minLength, maxLength}: BuildValidatorOptions = {
  reverseCompare: false,
  shouldValidate: false
}) => shouldValidate ? [
    BBValidators.compareEqual(compareControlName, reverseCompare),
    Validators.required,
    Validators.minLength(minLength || 1),
    Validators.maxLength(maxLength || 99)
  ] : [];


@Component({
  selector: 'wic-demand-appt-identity-modal',
  templateUrl: './demand-appt-identity-modal.component.html',
  styleUrls: ['./demand-appt-identity-modal.component.scss'],
})
export class DemandApptIdentityModalComponent implements OnInit {
  @Input() authority: Authority;

  LEN_CARD_DIGITS = 16;
  MIN_HHID_DIGITS = 1;
  MAX_HHID_DIGITS = 10;

  form: UntypedFormGroup;
  submitted = false;

  constructor(public modal: ModalController, private builder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.builder.group({
      householdId: [undefined, buildValidators({
        shouldValidate: this.authority.useHHIDForAppts,
        compareControlName: 'householdIdConfirm',
        reverseCompare: false,
        minLength: this.MIN_HHID_DIGITS,
        maxLength: this.MAX_HHID_DIGITS
      })],
      householdIdConfirm: [undefined, buildValidators({
        shouldValidate: this.authority.useHHIDForAppts,
        compareControlName: 'householdId',
        reverseCompare: true,
        minLength: this.MIN_HHID_DIGITS,
        maxLength: this.MAX_HHID_DIGITS
      })],
      cardNumber: [undefined, buildValidators({
        shouldValidate: !this.authority.useHHIDForAppts,
        compareControlName: 'cardNumberConfirm',
        reverseCompare: false,
        minLength: this.LEN_CARD_DIGITS,
        maxLength: this.LEN_CARD_DIGITS
      })],
      cardNumberConfirm: [undefined, buildValidators({
        shouldValidate: !this.authority.useHHIDForAppts,
        compareControlName: 'cardNumber',
        reverseCompare: true,
        minLength: this.LEN_CARD_DIGITS,
        maxLength: this.LEN_CARD_DIGITS
      })],
    });
  }

  isFieldValid(form: UntypedFormGroup, fieldName: string, errorName: string) {
    const control = form.controls[fieldName];
    const show = control.getError(errorName) && (!control.pristine || this.submitted);
    return show;
  }

  submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.modal.dismiss(
      this.authority.useHHIDForAppts
        ? {hhid: true, cardNumber: this.form.value.householdId}
        : {hhid: false, cardNumber: this.form.value.cardNumber}
      , 'ok');
  }
}
