import { createSelector } from '@ngrx/store';
import { currentShoppingListItemKey } from '~features/shopping-lists/shopping-list-items/shopping-list-items.state';
import { allSubstitutions } from '~features/shopping-lists/substitutions/substitutions.state';
import { allSubstitutionConfigs } from '~features/substitution-configs/substitution-configs.state';
import { currentShoppingListBenefit } from '../shopping-lists.selectors';

export const substitutionsForCurrentShoppingListItem = createSelector(
  currentShoppingListItemKey,
  allSubstitutions,
  (itemId, substitutions) => substitutions.filter(sub => sub.mobileOrderItemId === itemId) || []
)

export const currentSubstitutionType = createSelector(
  currentShoppingListBenefit,
  allSubstitutionConfigs,
  (shoppingListBenefit, substitutionConfigs) => substitutionConfigs.find(config => config.category === shoppingListBenefit.categoryId)?.substitutionType
)