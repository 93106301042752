import { Component, OnInit } from '@angular/core';
import { UpcFacade } from '~features/upc/upc.facade';

@Component({
  selector: 'wic-barcode-scanner',
  templateUrl: './barcode-scanner.page.html',
  styleUrls: ['./barcode-scanner.page.scss'],
})
export class BarcodeScannerPage implements OnInit {

  constructor(public upcs: UpcFacade) { }

  ngOnInit() {
  }

}
