<ion-list data-cy="benefits-loading">
  <ion-list-header>
    <ion-label *ngIf="(benefits.loadingProgress$ | async) !== 1 && (benefits.hasLoadError$ | async) === false">
      {{ ((benefits.isLoading$ | async) ? 'benefits.findingBenefitsHeader' : 'benefits.loadingBenefitsHeader') | transloco}}
    </ion-label>
    <ion-label *ngIf="(benefits.loadingProgress$ | async) === 1 && (benefits.hasLoadError$ | async) === false" color="primary">
      {{'benefits-loading-progress.loadedBenefitsHeader' | transloco}}
    </ion-label>
    <ion-label *ngIf="benefits.hasLoadError$ | async" color="danger">
      {{'benefits-loading-progress.errorBenefitsHeader' | transloco}}
    </ion-label>
  </ion-list-header>
  <ion-progress-bar [buffer]="findBuffer" [value]="benefits.loadingProgress$ | async"
                    [color]="(benefits.hasLoadError$ | async) ? 'danger' : 'primary'"></ion-progress-bar>
</ion-list>

<ion-list *ngIf="findBuffer > 0.5 && (benefits.hasLoadError$ | async) === false && (benefits.loadingProgress$ | async) > 0.2">
  <ion-list-header>
    <ion-label *ngIf="(categories.loadingProgress$ | async) !== 1 && (categories.hasLoadError$ | async) === false">
      {{'benefits-loading-progress.loadingCategoriesHeader' | transloco}}
    </ion-label>
    <ion-label *ngIf="(categories.loadingProgress$ | async) === 1 && (categories.hasLoadError$ | async) === false"
               color="primary">
      {{'benefits-loading-progress.loadedCategoriesHeader' | transloco}}
    </ion-label>
    <ion-label *ngIf="!!(categories.hasLoadError$ | async)" color="danger">
      {{'benefits-loading-progress.errorCategoriesHeader' | transloco}}
    </ion-label>
  </ion-list-header>
  <ion-progress-bar
      [buffer]="benefits.loadingProgress$ | async" [value]="subCategories.loadingProgress$ | async"
      [color]="(categories.hasLoadError$ | async) ? 'danger' : 'primary'">
  </ion-progress-bar>
</ion-list>
<ion-list *ngIf="findBuffer > 0.5 && (benefits.hasLoadError$ | async) === false && (benefits.loadingProgress$ | async) > 0.2">
  <ion-list-header>
    <ion-label *ngIf="(subCategories.loadingProgress$ | async) !== 1 && (subCategories.hasLoadError$ | async) === false">
      {{'benefits-loading-progress.loadingSubCategoriesHeader' | transloco}}
    </ion-label>
    <ion-label *ngIf="(subCategories.loadingProgress$ | async) === 1 && (subCategories.hasLoadError$ | async) === false"
               color="primary">
      {{'benefits-loading-progress.loadedSubCategoriesHeader' | transloco}}
    </ion-label>
    <ion-label *ngIf="!!(subCategories.hasLoadError$ | async)" color="danger">
      {{'benefits-loading-progress.errorSubCategoriesHeader' | transloco}}
    </ion-label>
  </ion-list-header>
  <ion-progress-bar
      [buffer]="benefits.loadingProgress$ | async" [value]="subCategories.loadingProgress$ | async"
      [color]="(categories.hasLoadError$ | async) ? 'danger' : 'primary'">
  </ion-progress-bar>
</ion-list>
