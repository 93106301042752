<swiper-container
  #slides
  [pagination]="detailedBanners?.length > 1"
  centered-slides="true"
  rewind="true"
  speed="400"
  autoplay-delay="4500"
  class="banner-slides"
  [ngClass]="{ 'many-slides': detailedBanners?.length > 1 }"
>
  <swiper-slide *ngFor="let banner of detailedBanners">
    <div class="container" (click)="viewBanner.emit(banner)">
      <img [src]="banner.imageUrl" alt="current banner image" />
    </div>
  </swiper-slide>
</swiper-container>

<ion-button *ngIf="showButtons" class="banner-button banner-button-left" fill="clear" (click)="swiperSlidePrev()">
  <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
</ion-button>

<ion-button *ngIf="showButtons" class="banner-button banner-button-right" fill="clear" (click)="swiperSlideNext()">
  <ion-icon slot="icon-only" name="chevron-forward"></ion-icon>
</ion-button>
