import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'wic-qr-warn-modal',
  templateUrl: './qr-warn-modal.component.html',
  styleUrls: ['./qr-warn-modal.component.scss'],
})
export class QRWarnModalComponent {

  constructor(public modal: ModalController) {
  }
}
