<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'admin-tools.title' | transloco}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-list>
    <ion-list-header>
      {{'admin-tools.resetSubtitle' | transloco}}
    </ion-list-header>
    <ion-item>
      <ion-label>
        <h2>{{'admin-tools.softLabel' | transloco}}</h2>
        <p>
          <ion-text color="medium">{{'admin-tools.softDescription' | transloco}}</ion-text>
        </p>
      </ion-label>
      <ion-icon slot="end" name="power" (click)="app.softReset(); modal.dismiss()"></ion-icon>
    </ion-item>
    <ion-item>
      <ion-label>
        <h2>{{'admin-tools.hardLabel' | transloco}}</h2>
        <p>
          <ion-text color="medium">{{'admin-tools.hardDescription' | transloco}}</ion-text>
        </p>
      </ion-label>
      <ion-icon slot="end" name="trash" (click)="app.hardReset(); modal.dismiss()"></ion-icon>
    </ion-item>
  </ion-list>
  <ion-list>
    <ion-list-header>
      {{'admin-tools.cacheSubtitle' | transloco}}
    </ion-list-header>
    <ion-item *ngFor="let group of webCache.configGroups$ | async">
      <ion-label>
        <h2>{{group.key}}</h2>
        <p>
          <ion-text
              color="medium">{{'admin-tools.purgeCacheDescription' | transloco:{group: group.config.name} }}</ion-text>
        </p>
      </ion-label>
      <ion-icon slot="end" name="refresh" (click)="webCache.purge(group.key);"></ion-icon>
    </ion-item>
  </ion-list>
</ion-content>
