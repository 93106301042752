import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFingerprint } from '@fortawesome/free-solid-svg-icons';

import { IonicModule } from '@ionic/angular';

import { HomePage } from './home.page';
import { ComponentsModule } from '../../components/components.module';
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    TranslocoModule,
    FontAwesomeModule
  ],
  declarations: [HomePage]
})
export class HomePageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(faFingerprint);
  }
}
