import { NgModule } from '@angular/core';
import { ErrorsFacade } from './errors.facade';
import { ErrorsService } from './errors.service';

@NgModule({
  providers: [
    ErrorsFacade,
    ErrorsService,
  ]
})
export class ErrorModule {
}
