import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { protocolButtonActivated } from '~features/home/home.state';
import { UPCInfo } from '~features/upc/models';

import { HomeButton } from '~features/home/models';
import { State } from '../state';
import {
currentUPC,
initiateBarcodeScan,
keyEnterUPC,
parsedUPC,
recentUPCs,
scanBarcode,
scannedUPC,stopBarcodeScan,
warnNoWicProduce
} from './upc.state';

@Injectable()
export class UpcFacade {
  scannedUPC$ = this.store.select(scannedUPC);

  get currentUPC$(): Observable<string> {
    return this.store.select(currentUPC);
  }

  get parsedUPC$(): Observable<string> {
    return this.store.select(parsedUPC);
  }

  get recentUPCs$(): Observable<UPCInfo[]> {
    return this.store.select(recentUPCs);
  }

  constructor(private store: Store<State>, private platform: Platform) {
  }

  warnNoWicProduce() {
    this.store.dispatch(warnNoWicProduce());
  }

  lookUpUPC(upc: string): void {
    this.store.dispatch(keyEnterUPC({upc}));
  }

  scanBarcode(): void {
    this.store.dispatch(scanBarcode({}));
  }

  initiateScanBarcode(): void {
    this.store.dispatch(initiateBarcodeScan());
  }

  stopScanBarcode(): void {
    this.store.dispatch(stopBarcodeScan());
  }

  navToKeyEnterUPC() {
    this.store.dispatch(protocolButtonActivated({ button: { linkUrl: 'wic://search-upc' } as HomeButton }));
  }
}
