import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { DeviceInfo } from '~models';
import { EnhancedDevice } from './models';
import { LogService } from '~core/services/log.service';

import { EnvironmentService } from '~features/environment.service';

const TAGS = ['Service', 'EnhancedDevice'];

@Injectable()
export class EnhancedDeviceService {
  constructor(private http: HttpClient, private env: EnvironmentService, private log: LogService) {
  }

  register(device: DeviceInfo): Observable<EnhancedDevice> {
    return this.env.apiHost$.pipe(
      first(),
      tap(url => this.log.trace(TAGS, `Url trace: ${url}`)),
      switchMap(apiHost =>
        this.http.post<EnhancedDevice>(
          `${apiHost}/v1/devices/enhanced`,
          device
        )
      )
    );
  }

  load(udid: string): Observable<EnhancedDevice> {
    return this.env.apiHost$.pipe(
      first(),
      map(apiHost => `${apiHost}/v1/devices/${udid}/enhanced`),
      tap(url => this.log.trace(TAGS, `Url trace: ${url}`)),
      switchMap(url =>
        this.http.get<EnhancedDevice>(url)
      )
    );
  }
}
