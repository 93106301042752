import * as geolib from 'geolib';
import { exists, has } from './func-util';

const METERS_PER_MILE = 1609.34; // meters per mile

export interface ILatLong {
  latitude: number;
  longitude: number;
}

export const hasLatLong = (a: ILatLong): boolean =>
  has(a) && has(a.latitude) && has(a.longitude);

export const makeLatLong = (a: ILatLong): ILatLong => ({
  latitude: a.latitude, longitude: a.longitude
});

export const metersToMiles = (meters?: number): number | null =>
  exists(meters) ? meters / METERS_PER_MILE : null;

export const roundToNearestMile = (miles?: number): number | null =>
  !exists(miles)
    ? null
    : miles >= 1
    ? Math.round(miles)
    : miles;

export const tryGetDistanceMeters = (a: ILatLong, b: ILatLong): number | null =>
  hasLatLong(a) && hasLatLong(b)
    ? geolib.getDistance(makeLatLong(a), makeLatLong(b))
    : null;

export const getDistanceMiles = (a: ILatLong, b: ILatLong): number => {
  const distM = tryGetDistanceMeters(a, b);
  const distMi = metersToMiles(distM);
  return roundToNearestMile(distMi);
};

export const calculateDistance = {
  fromServer: (withDistance, {options}) =>
    (withDistance.distance = getDistanceMiles(options?.location?.coords, withDistance), withDistance)
};
