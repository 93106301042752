import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { APLMessage } from '~features/aplmessages/models';
import { State } from '~features/state';
import { APLMessagesFacadeBase, aplMessageViewed } from './aplmessages.state';

@Injectable()
export class APLMessagesFacade extends APLMessagesFacadeBase {
  constructor(private store: Store<State>) {
    super(APLMessage, store);
  }

  view(message: APLMessage): void {
    this.store.dispatch(aplMessageViewed({message}));
  }
}

