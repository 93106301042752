import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HostsSettingsMode } from '~models';
import { EnhancedDevice } from './models';

import { LogService } from '~core/services/log.service';
import { State } from '../state';
import {
  enhancedDeviceRegistration,
  enhancedToolsHidden,
  hideEnhancedTools,
  isEnhanced,
  registerEnhancedDevice,
  showAdminTools,
  showDebuggingTools,
  showDebugSettings,
  showEnhancedTools,
  showHostSettings, showNotificationInvestigator
} from './enhanced-device.state';

const TAGS = ['Facade', 'Enhanced Mode'];

@Injectable()
export class EnhancedModeFacade {
  get isEnhanced$(): Observable<boolean> {
    return this.store.select(isEnhanced);
  }

  get registration$(): Observable<EnhancedDevice> {
    return this.store.select(enhancedDeviceRegistration);
  }

  get toolsHidden$(): Observable<boolean> {
    return this.store.select(enhancedToolsHidden);
  }

  get tapCount(): number {
    return this.secretTaps;
  }

  private secretTaps = 0;
  private secretTimeout = null;

  constructor(private store: Store<State>, private alerts: AlertController, private log: LogService) {
  }

  register(): void {
    this.store.dispatch(registerEnhancedDevice());
  }

  showTools(): void {
    this.store.dispatch(showEnhancedTools());
  }

  hideTools(): void {
    this.store.dispatch(hideEnhancedTools());
  }

  showDebugging(): void {
    this.store.dispatch(showDebuggingTools());
  }

  showDebugSettings(): void {
    this.store.dispatch(showDebugSettings());
  }

  showHostSettings(mode: HostsSettingsMode): void {
    this.store.dispatch(showHostSettings({mode}));
  }

  showAdminTools(): void {
    this.store.dispatch(showAdminTools());
  }

  showNotificationInvestigator(): void {
    this.store.dispatch(showNotificationInvestigator());
  }

  async codeChallenge(): Promise<boolean> {
    const alert = await this.alerts.create({
      subHeader: 'Please enter code:',
      inputs: [{
        type: 'text',
        id: 'code'
      }],
      buttons: [{
        text: 'Ok'
      }]
    });

    await alert.present();
    const result = await alert.onDidDismiss();
    return (result.data && result.data.values[0] || '').toUpperCase() === '0T79VI3X';
  }

  secretButton(): void {
    this.secretTaps = this.secretTaps + 1;
    if (this.secretTaps > 20) {
      clearTimeout(this.secretTimeout);
      this.secretTimeout = null;
    }

    if (!this.secretTimeout) {
      this.secretTimeout = setTimeout(
        () => {
          if (this.secretTaps >= 7) {
            this.log.debug(TAGS, `Saw 7+ taps, enhanced mode: ${this.secretTaps}`);
            this.secretTaps = 0;
            clearTimeout(this.secretTimeout);
            this.secretTimeout = null;
            this.register();
          } else if (this.secretTaps >= 3) {
            this.log.debug(TAGS, `Saw 3+ taps, displaying log: ${this.secretTaps}`);
            this.secretTaps = 0;
            clearTimeout(this.secretTimeout);
            this.secretTimeout = null;
            this.showDebugging();
          } else {
            this.log.debug(TAGS, `Enhanced mode registration attempt canceled. Taps: ${this.secretTaps}`);
            this.secretTaps = 0;
            clearTimeout(this.secretTimeout);
            this.secretTimeout = null;
          }
        },
        3000
      );
    }
  }
}
