import { Pipe, PipeTransform } from '@angular/core';
import { APLMessage } from '~features/aplmessages/models';

@Pipe({name: 'aplMessage'})
export class AplMessagePipe implements PipeTransform {
  transform<TCategorized extends { categoryId: number; subCategoryId: number }>(
    categorized: TCategorized, aplMessages: APLMessage[]
  ): any {
    return (aplMessages || []).find(message =>
      message.categoryId === categorized.categoryId && message.subCategoryId === categorized.subCategoryId
    );
  }
}
