import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';


import { Category } from './models';
import { State } from '../state';
import {
  categoriesHasLoadError,
  categoriesLoadError,
  categoriesLoadingProgress,
  CategoryFacadeBase,
  hasCategories
} from './categories.state';

@Injectable()
export class CategoriesFacade extends CategoryFacadeBase {
  get loadingProgress$(): Observable<number> {
    return this.store.select(categoriesLoadingProgress);
  }

  get loadError$(): Observable<Error | any> {
    return this.store.select(categoriesLoadError);
  }

  get hasLoadError$(): Observable<Error | any> {
    return this.store.select(categoriesHasLoadError);
  }

  get hasCategories$(): Observable<boolean> {
    return this.store.select(hasCategories);
  }

  constructor(private store: Store<State>) {
    super(Category, store);
  }
}
