<div [class.content-header]="!slim" [class.content-header-slim]="!!slim" [ngClass]="color">
  <ion-grid>
    <ion-row>
      <ion-col>
        <h1 class="content-header-title">{{title}}</h1>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <h3 class="content-header-subtitle">{{subtitle}}</h3>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!!othertitle">
      <ion-col>
        <h3 class="content-header-subtitle">{{othertitle}}</h3>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
