<ion-header data-cy="benefits-header">
  <ion-toolbar color="tufts-blue-shaded">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{'benefits.contentTitle' | transloco}}</h3>
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon
          icon="fingerprint"
          size="2x"
          [styles]="{opacity: '0.03', color: 'var(--white)'}"
        ></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <wic-benefit-header
    *ngIf="benefits.hasBenefits$ | async"
    [registration]="registration.currentRegistration$ | async"
    [benefitInfo]="benefits.currentBenefits$ | async"
    [isStale]="benefits.areCurrentBenefitsStale$ | async"
    (refreshed)="benefits.refresh()"
  >
  </wic-benefit-header>

  <section *ngIf="benefits.isLoading$ | async; else currentBenefits">
    <ion-list>
      <ion-item>
        <ion-label><h2>{{'benefits.loadingLabel' | transloco}}</h2></ion-label>
        <ion-spinner slot="end" name="dots"></ion-spinner>
      </ion-item>
    </ion-list>
    <wic-benefits-loading-progress [findBuffer]="1.0">
    </wic-benefits-loading-progress>
  </section>

  <ng-template #currentBenefits>
    <ng-container
      *ngIf="benefits.hasCurrentNonExpiredBenefits$ | async; else noCurrentNonExpiredBenefits"
    >
      <wic-last-shopping-trip
        *ngIf="registration.showLastShoppingTrip$ | async"
        [benefitInfo]="benefits.currentBenefits$ | async"
      >
      </wic-last-shopping-trip>

      <wic-last-updated-at
        *ngIf="registration.showLastImport$ | async"
        [hideUpdatedAt]="registration.nonRealTimeBenefits$ | async"
        [benefitInfo]="benefits.currentBenefits$ | async"
      >
      </wic-last-updated-at>

      <wic-future-benefits-button
        *ngIf="registration.showFutureBenefits$ | async"
        (clicked)="benefits.viewFuture()"
      >
      </wic-future-benefits-button>

      <wic-benefit-list
        [authority]="registration.currentProvider$ | async"
        [aplMessages]="aplMessages.all$ | async"
        [benefitInfo]="benefits.currentBenefits$ | async"
        [pendingExpirationMode]="pendingExpirationMode$ | async"
        [currentLanguage]="settings.selectedLanguage$ | async"
        (viewed)="benefits.view($event)"
        (messageViewed)="aplMessages.view($event)"
        (linkOpened)="benefits.openLink($event)"
        (calcOpened)="calculators.viewCalculator($event)"
      >
      </wic-benefit-list>
    </ng-container>

    <ng-template #noCurrentNonExpiredBenefits>
      <wic-future-benefits-button
        *ngIf="(registration.noCurrentCard$ | async) === false"
        (clicked)="benefits.viewFuture()"
      >
      </wic-future-benefits-button>

      <wic-expired-benefits-warning
        *ngIf="benefits.areExpired$ | async"
        [benefits]="benefits.currentBenefits$ | async"
      >
      </wic-expired-benefits-warning>

      <wic-no-cards-warning
        *ngIf="(benefits.areExpired$ | async) === false"
        [hasCard]="registration.hasCurrentCard$ | async"
        [hasCards]="registration.hasCards$ | async"
      >
        <ng-container ngProjectAs="[slot=info]">
          <p>
            {{ ((registration.hasCurrentCard$ | async) ?
            'benefits.noBenefitsFoundInfo' : (registration.hasCards$ | async) ?
            'benefits.noCurrentCardInfo' : 'benefits.noCardsRegisteredInfo') |
            transloco }}
          </p>
        </ng-container>
        <ng-container ngProjectAs="[slot=buttons]">
          <ion-row>
            <ion-col>
              <ion-button
                (click)="registration.manageCards(true)"
                class="btn-green"
                expand="block"
              >
                <fa-stack>
                  <fa-icon icon="credit-card" stackItemSize="1x"></fa-icon>
                  <fa-icon
                    icon="asterisk"
                    stackItemSize="1x"
                    transform="shrink-4 right-8 down-10"
                    style="color: dimgray"
                  ></fa-icon>
                  <fa-icon
                    icon="asterisk"
                    stackItemSize="1x"
                    transform="shrink-4 right-7 down-9"
                    style="color: gold"
                  ></fa-icon>
                </fa-stack>
                <span class="ion-text-uppercase"
                  >{{ 'buttons.manageCards' | transloco }}</span
                >
              </ion-button>
            </ion-col>
          </ion-row>
        </ng-container>
      </wic-no-cards-warning>
    </ng-template>
  </ng-template>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>

<ion-footer>
  <ion-toolbar>
    <ion-grid>
      <ion-row
        *ngIf="!!registration && !!(registration.hasCurrentCard$ | async)"
      >
        <ion-col>
          <ion-button
            data-cy="manage-cards-button"
            (click)="registration.manageCards()"
            class="btn-independence"
            expand="block"
          >
            <ion-icon slot="start" name="card"></ion-icon>
            <span class="ion-text-uppercase"
              >{{(registration.currentCard$ | async)?.cardNumber | mask}}</span
            >
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button
            data-cy="search-upc-button"
            (click)="upc.scanBarcode()"
            class="btn-maroon"
            expand="block"
          >
            <ion-icon slot="start" name="barcode"></ion-icon>
            <span class="ion-text-uppercase"
              >{{'buttons.scanBarcode' | transloco }}</span
            >
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!registration || (registration.hasCurrentCard$ | async) === false">
        <ion-col>
          <div class="ion-text-center text-muted">
            <ion-icon name="sad-outline"></ion-icon> {{'benefits.noBenefitsFound' | transloco}}
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
