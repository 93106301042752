import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { VendorTimeSlotsFacade } from '~features/vendor-time-slots/vendor-time-slots.facade';
import { TimeSlotDayOption } from '~features/vendor-time-slots/time-slot-day-option.model';
import { VendorTimeSlot } from '~features/vendor-time-slots/vendor-time-slot.entity';
import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { ShoppingListsFacade } from '~features/shopping-lists/shopping-lists.facade';

@Component({
  selector: 'wic-select-time-slot-modal',
  templateUrl: './select-time-slot-modal.component.html',
  styleUrls: ['./select-time-slot-modal.component.scss'],
})
export class SelectTimeSlotModalComponent {
  dateFormat = 'EEEE, MMMM d, h:mm a';
  constructor(
    public modal: ModalController, 
    public vendorTimeSlots: VendorTimeSlotsFacade, 
    public benefits: BenefitsFacade,
    public readonly shoppingLists: ShoppingListsFacade,
  ) {}

  submit(dayOption: TimeSlotDayOption, timeSlot: VendorTimeSlot) {
    return this.modal.dismiss({ dayOption, timeSlot }, 'dismiss')
  }

  dismiss() {
    this.vendorTimeSlots.deselectVendorTimeSlot();
    this.modal.dismiss(null, 'cancel');
  }
}
