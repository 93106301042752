<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(null)">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'edit-card-modal.title' | transloco}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="save()" data-cy="edit-card-save-button">
        <ion-icon name="checkmark-circle"></ion-icon> {{'edit-card-modal.submitButton' | transloco}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-card *ngIf="!!card">
    <wic-provider-card [provider]="provider"></wic-provider-card>
    <ion-card-header>
      <ion-card-subtitle>{{'edit-card-modal.headerSubtitleCNum' | transloco}} {{ card.cardNumber | mask}}</ion-card-subtitle>
      <ion-card-subtitle *ngIf="provider?.integration === 'wem'">{{'edit-card-modal.headerSubtitleHHID' | transloco}} {{ card.householdId }}</ion-card-subtitle>
      <ion-card-title>{{'edit-card-modal.headerTitle' | transloco}}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <form [formGroup]="form">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-input formControlName="nickname" placeholder="{{'edit-card-modal.nicknamePlaceholder' | transloco}}" data-cy="edit-card-nickname"></ion-input>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-card-content>
  </ion-card>
</ion-content>
