import { Component, Input } from '@angular/core';
import { EnvironmentService } from '~features/environment.service';
import { Authority } from '~features/authorities/models';

@Component({
  selector: 'wic-provider-card',
  templateUrl: './provider-card.component.html',
  styleUrls: ['./provider-card.component.scss'],
})
export class ProviderCardComponent {
  @Input() provider: Authority;

  constructor(public env: EnvironmentService) { }
}
