import { IEntityState } from '@briebug/ngrx-auto-entity';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '~env';
import { aplMessageReducer, IAPLMessageState } from '~features/aplmessages/aplmessages.state';
import { bannerReducer } from '~features/banners/banners.state';
import { Banner } from '~features/banners/models';
import { calculatorReducer, ICalculatorState } from '~features/calculators/calculators.state';
import { IMicroBlogState, microBlogReducer } from '~features/micro-blogs/micro-blogs.state';
import { IMissingProductState, missingProductReducer } from '~features/missing-product/missing-product.state';
import { appReducer, IAppState } from './app/app.reducer';
import { appointmentReducer, IAppointmentState } from './appointments/appointments.state';
import { authorityReducer } from './authorities/authorities.state';
import { Authority } from './authorities/models';
import { benefitsReducer, IBenefitsState } from './benefits/benefits.state';
import { categoryReducer, ICategoryState } from './categories/categories.state';
import { enhancedDeviceReducer, IEnhancedDeviceState } from './enhanced-mode/enhanced-device.state';
import { IMenuButtonState, menuButtonReducer } from './home/home.state';
import { languageReducer, LanguageState } from './languages/languages.state';
import { INotificationState, notificationsReducer } from './notifications/notifications.state';
import { IOfficeState, officeReducer } from './offices/offices.state';
import { IProductState, productReducer } from './products/product.state';
import { IReceiptsState, receiptsReducer } from './receipts/receipts.state';
import { redemptionReducer, RedemptionsState } from './redemptions/redemptions.state';
import { IRegistrationState, registrationReducer } from './registration/registration.state';
import { ISettingsState, settingsReducer } from './settings/settings.state';
import { SubCatConfig } from './subcat-configs/models';
import { subCatConfigReducer } from './subcat-configs/subcat-configs.state';
import { ISubCategoryState, subCategoryReducer } from './subcategories/subcategories.state';
import { IUPCState, upcReducer } from './upc/upc.state';
import { IVendorState, vendorReducer } from './vendors/vendors.state';
import { EnhancedIssue } from './enhanced-issues/models/enhanced-issue';
import { enhancedIssueReducer } from './enhanced-issues/enhanced-issues.state';
import { storageSyncReducer } from '@jpma-wicshopper-imports-mono/mobile/storage-sync';

export interface State {
  app: IAppState;
  appointment: IAppointmentState;
  aplMessage: IAPLMessageState;
  authority: IEntityState<Authority>;
  banner: IEntityState<Banner>;
  benefits: IBenefitsState;
  receipts: IReceiptsState;
  calculators: ICalculatorState;
  category: ICategoryState;
  enhancedDevice: IEnhancedDeviceState;
  enhancedIssues: IEntityState<EnhancedIssue>;
  language: LanguageState;
  menuButton: IMenuButtonState;
  microBlog: IMicroBlogState;
  missingProduct: IMissingProductState;
  notifications: INotificationState;
  office: IOfficeState;
  product: IProductState;
  redemption: RedemptionsState;
  registration: IRegistrationState;
  router: RouterReducerState;
  settings: ISettingsState;
  subCatConfig: IEntityState<SubCatConfig>;
  subCategory: ISubCategoryState;
  upc: IUPCState;
  vendor: IVendorState;
}

export const reducers: ActionReducerMap<State> = {
  app: appReducer,
  appointment: appointmentReducer,
  aplMessage: aplMessageReducer,
  authority: authorityReducer,
  banner: bannerReducer,
  benefits: benefitsReducer,
  receipts: receiptsReducer,
  calculators: calculatorReducer,
  category: categoryReducer,
  enhancedDevice: enhancedDeviceReducer,
  enhancedIssues: enhancedIssueReducer,
  language: languageReducer,
  menuButton: menuButtonReducer,
  microBlog: microBlogReducer,
  missingProduct: missingProductReducer,
  notifications: notificationsReducer,
  office: officeReducer,
  product: productReducer,
  redemption: redemptionReducer,
  registration: registrationReducer,
  router: routerReducer,
  settings: settingsReducer,
  subCatConfig: subCatConfigReducer,
  subCategory: subCategoryReducer,
  upc: upcReducer,
  vendor: vendorReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [storageSyncReducer] // Development Mode
  : [storageSyncReducer]; // Production Mode
