import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DeviceService } from '~core/services/device.service';
import { LogService } from '~core/services/log.service';
import { WICShopperEvents } from '~features/analytics/analytics.facade';
import { initializationCompleted } from '~features/app/app.actions';
import { viewBenefit } from '~features/benefits/benefits.actions';
import { allCategories } from '~features/categories/categories.state';
import { benefitsButtonActivated } from '~features/home/home.state';
import { productStatusComputed } from '~features/products/product.state';
import { cardRegistrationCompleted, providerConfirmed } from '~features/registration/registration.actions';
import { currentCard, currentProvider } from '~features/registration/registration.selectors';
import { UserActivity } from '~features/reporting/models/user-activity';
import { ReportingService } from '~features/reporting/reporting.service';
import { State } from '~features/state';
import { allSubCategories } from '~features/subcategories/subcategories.state';
import { barcodeScanReceived, keyEnterUPC } from '~features/upc/upc.state';
import { userActivityOccurred } from './reporting.state';

const TAGS = ['Effects', 'Reporting'];

@Injectable()
export class ReportingEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private log: LogService,
    private device: DeviceService,
    private reporting: ReportingService) {
  }

  logUserActivity$ = createEffect(
    () => this.actions$.pipe(
      ofType(userActivityOccurred),
      withLatestFrom(
        this.device.deviceInfo$,
        this.store.select(currentCard),
        this.store.select(currentProvider)
      ),
      filter(([, deviceInfo, , authority]) => !!deviceInfo && !!authority && !!authority.id),
      map(([{activityType, activityInfo}, deviceInfo, card, authority]): UserActivity => ({
        activityType,
        authorityId: authority.id,
        authorityName: authority.name,
        deviceId: deviceInfo.uuid,
        deviceUdid: deviceInfo.udid,
        appVersion: deviceInfo.appVersion,
        cardNumber: card ? card.cardNumber : null,
        householdId: card ? card.householdId : null,
        deviceOs: deviceInfo.platform,
        deviceModel: deviceInfo.model,
        deviceManufacturer: deviceInfo.manufacturer,
        deviceVersion: deviceInfo.version,
        activityInfo
      })),
      tap(activity => this.log.debug(TAGS, `Logging user activity. Type = ${activity.activityType}; UDID = ${activity.deviceUdid}; `)),
      switchMap(activity => this.reporting.logActivity(activity)),
      tap({
        error: err => this.log.error(TAGS, 'Error occurred logging activity', err)
      }),
      catchError(() => EMPTY)
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  logAppUsed$ = createEffect(
    () => this.actions$.pipe(
      ofType(initializationCompleted, providerConfirmed),
      map(() => userActivityOccurred({
        activityType: WICShopperEvents.AppUsed, activityInfo: {
          timestamp: format(Date.now(), 'yyyy-MM-dd\'T\'HH:mm:ss')
        }
      }))
    )
  );

  logRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(cardRegistrationCompleted),
      map(() => userActivityOccurred({
        activityType: WICShopperEvents.Register, activityInfo: {
          timestamp: format(Date.now(), 'yyyy-MM-dd\'T\'HH:mm:ss')
        }
      }))
    )
  );

  logBarcodeScanned$ = createEffect(
    () => this.actions$.pipe(
      ofType(barcodeScanReceived),
      map(({content}) => content),
      map(upc => userActivityOccurred({
        activityType: WICShopperEvents.ScanProduct, activityInfo: {
          timestamp: format(Date.now(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
          upc
        }
      }))
    )
  );

  logSearchUPC$ = createEffect(
    () => this.actions$.pipe(
      ofType(keyEnterUPC),
      map(({upc}) => upc),
      map(upc => userActivityOccurred({
        activityType: WICShopperEvents.SearchUPC, activityInfo: {
          timestamp: format(Date.now(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
          upc
        }
      }))
    )
  );

  logViewBenefits$ = createEffect(
    () => this.actions$.pipe(
      ofType(benefitsButtonActivated),
      map(() => userActivityOccurred({
        activityType: WICShopperEvents.ViewBenefits, activityInfo: {
          timestamp: format(Date.now(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
        }
      }))
    )
  );

  logViewBenefit$ = createEffect(
    () => this.actions$.pipe(
      ofType(viewBenefit),
      withLatestFrom(this.store.select(allCategories), this.store.select(allSubCategories)),
      map(([{benefit: {categoryId, subCategoryId}}, categories, subCategories]) => ({
        category: categories.find(cat => cat.categoryId === categoryId),
        subCategory: subCategories.find(cat => cat.categoryId === categoryId && cat.subCategoryId === subCategoryId)
      })),
      map(({category, subCategory}) => userActivityOccurred({
        activityType: WICShopperEvents.ViewBenefit, activityInfo: {
          timestamp: format(Date.now(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
          categoryId: category.categoryId,
          subCategoryId: subCategory.subCategoryId
        }
      }))
    )
  );

  logViewItem$ = createEffect(
    () => this.actions$.pipe(
      ofType(productStatusComputed),
      map(({product}) => userActivityOccurred({
        activityType: WICShopperEvents.ViewItem, activityInfo: {
          timestamp: format(Date.now(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
          categoryId: product.categoryId,
          subCategoryId: product.subCategoryId,
          itemNumber: product.itemNumber,
          normalizedItemNumber: product.normalizedItemNumber,
          description: product.description,
          allowed: product.allowed,
          status: product.status
        }
      }))
    )
  );
}
