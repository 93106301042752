import { Component, Input } from '@angular/core';
import { EnvironmentService } from '~features/environment.service';
import { Language } from '~features/languages/models/language';

@Component({
  selector: 'wic-language-item',
  templateUrl: './language-item.component.html',
  styleUrls: ['./language-item.component.scss'],
})
export class LanguageItemComponent {
  isSelected = false;

  @Input() language: Language;
  @Input() edit = false;

  @Input() set selected(val: Language) {
    this.isSelected = val.code === this.language.code;
  }

  constructor(public env: EnvironmentService) {
  }

  getLangCode() {
    return { lang: this.language.code.replace('-', '') };
  }
}
