import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { ShoppingList } from '~features/shopping-lists/shopping-list.entity';

@Component({
  selector: 'wic-shopping-list-group',
  templateUrl: './shopping-list-group.component.html',
  styleUrls: ['./shopping-list-group.component.scss'],
})
export class ShoppingListGroupComponent {
  @Input() groupLabel: string;
  @Input() shoppingLists: ShoppingList[];

  @Output() selected = new EventEmitter<ShoppingList>();
  @Output() removed = new EventEmitter<ShoppingList>();

  async toggleItem(item: IonItemSliding) {
    const open = await item.getOpenAmount();
    return open !== 0 ? item.close() : item.open('end');
  }
}
