import { createAction, props } from '@ngrx/store';
import { ShoppingListItem } from './shopping-list-item.entity';
import { ShoppingList } from '../shopping-list.entity';
import { Vendor } from '~features/vendors/models';

export const editShoppingListItemQuantity = createAction(
  '[ShoppingListItem] Edit Quantity',
  props<{ id: string }>()
);

export const submitShoppingListItemQuantityEdit = createAction(
  '[ShoppingListItem] Submit Quantity Edit',
  props<{ purchaseQuantity: number; }>()
);

export const toggleShoppingListItemCheckedOffStatus = createAction(
  '[ShoppingListItem] Toggle Checked Off Status',
  props<{ shoppingListItem: ShoppingListItem }>()
);

export const uncheckAllShoppingListItems = createAction(
  '[ShoppingListItem] Uncheck All Items'
);

export const scannedBarcodeMatchesShoppingListItem = createAction(
  '[ShoppingListItem] Scanned Barcode Matches Item',
  props<{ shoppingListItem: ShoppingListItem }>()
);

export const unavailableItemsFoundForNewVendor = createAction(
  '[ShoppingListItem] Unavailable Items For New Vendor',
  props<{ shoppingListItems: ShoppingListItem[], previousVendorId: number }>()
);

export const allItemsFoundForNewVendor = createAction(
  '[ShoppingListItem] All Items Found For New Vendor',
  props<{ shoppingListItems: ShoppingListItem[], previousVendorId: number }>()
);

export const unavailableItemsConfirmed = createAction(
  '[ShoppingListItem] Unavailable Items Confirmed',
  props<{ shoppingListItems:  ShoppingListItem[] }>()
);

export const unavailableItemsCanceled = createAction(
  '[ShoppingListItem] Unavailable Items Canceled',
  props<{  previousVendorId: number }>()
);
