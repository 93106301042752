import { Inject, Injectable, InjectionToken } from '@angular/core';

export const CONFIG_TOKEN = new InjectionToken<AppConfig>('app-config-token');

const WEB_CACHE_KEY_GROUP = 'wic_web_cache';

export interface VendorsConfig {
  searchAnywhere?: boolean;
  colors?: Record<number, string>;
}

export interface OfficesConfig {
  searchAnywhere?: boolean;
  colors?: Record<number, string>;
}

export interface AppConfig {
  // appId indicating which app to query authorities by.
  // Should only be 1 or 3.
  appId: number;
  webCacheKey?: string;
  vendors?: VendorsConfig;
  offices?: OfficesConfig;
}

@Injectable()
export class ConfigService {
  constructor(
    @Inject(CONFIG_TOKEN)
    private config: AppConfig
  ) {}

  get appId() {
    return this.config.appId;
  }

  get webCacheKey() {
    return this.config.webCacheKey || WEB_CACHE_KEY_GROUP;
  }

  get vendors() {
    return this.config.vendors || {};
  }

  get vendorColors() {
    return this.config?.vendors?.colors || {}
  }

  get offices() {
    return this.config.offices || {};
  }

  get officeColors() {
    return this.config?.offices?.colors || {}
  }
}
