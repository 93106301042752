import { NgModule } from '@angular/core';
import { RegistrationFacade } from './registration.facade';
import { RegistrationService } from './registration.service';


@NgModule({
  providers: [
    RegistrationFacade,
    RegistrationService
  ]
})
export class RegistrationModule {
}
