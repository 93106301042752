import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { homePageLoading } from '~features/home/home.state';
import { currentProviderId } from '~features/registration/registration.selectors';
import { userSettings } from '~features/settings/settings.state';
import { State } from '~features/state';
import { MicroBlogsService } from './micro-blogs.service';
import { latestMicroBlogsLoaded, microBlogViewed, noMicroBlogFound } from './micro-blogs.state';

const VIEWED_MICRO_BLOGS_KEY = 'wic::viewed_micro_blogs';
const TAGS = ['Effects', 'Micro Blog'];

@Injectable()
export class MicroBlogsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private microBlogs: MicroBlogsService,
    private storage: Storage,
    private log: LogService) {
  }

  loadLatestBlog$ = createEffect(
    () => this.actions$.pipe(
      ofType(homePageLoading),
      withLatestFrom(
        this.store.select(currentProviderId),
        from(this.storage.get(VIEWED_MICRO_BLOGS_KEY))
      ),
      map(([, authorityId, json]) => ({
        authorityId,
        viewed: json ? JSON.parse(json) as string[] : null
      })),
      switchMap(({authorityId, viewed}) =>
        this.microBlogs.loadLatestMicroBlog(+authorityId, viewed).pipe(
          map(microBlog => !!microBlog ? latestMicroBlogsLoaded({microBlog}) : noMicroBlogFound()),
          catchError(() => of(noMicroBlogFound()))
        )
      )
    )
  );

  showLatestBlog$ = createEffect(
    () => this.actions$.pipe(
      ofType(latestMicroBlogsLoaded),
      withLatestFrom(this.store.select(userSettings)),
      switchMap(([{microBlog}, {lang}]) =>
        from(this.microBlogs.showBlog(microBlog, lang)).pipe(
          map(() => microBlogViewed({microBlog}))
        )
      )
    )
  );

  trackViewedMicroBlogs$ = createEffect(
    () => this.actions$.pipe(
      ofType(microBlogViewed),
      withLatestFrom(from(this.storage.get(VIEWED_MICRO_BLOGS_KEY))),
      map(([{microBlog}, json]) => ({
        microBlog,
        viewed: json ? JSON.parse(json) as string[] : []
      })),
      // eslint-disable-next-line no-underscore-dangle
      map(({microBlog, viewed}) => [...viewed.filter(id => id !== microBlog._id), microBlog._id]),
      map(viewed => JSON.stringify(viewed)),
      tap(json => this.storage.set(VIEWED_MICRO_BLOGS_KEY, json))
    ),
    {dispatch: false}
  );
}
