import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { UniqueDeviceID } from '@awesome-cordova-plugins/unique-device-id/ngx';
import { GeoLocationService } from '~core/services/geo-location.service';
import { NativePermissionsService } from '~core/services/native-permissions.service';
import { APLMessage } from '~features/aplmessages/models';
import { Appointment } from '~features/appointments/models';

import { Authority } from '~features/authorities/models';
import { Banner } from '~features/banners/models';
import { Category } from '~features/categories/models';
import { MenuButton } from '~features/home/models';
import { Language } from '~features/languages/models/language';
import { MissingProduct } from '~features/missing-product/models';
import { Office } from '~features/offices/models';
import { Redemption } from '~features/redemptions/redemption';
import { SubCatConfig } from '~features/subcat-configs/models';
import { SubCategory } from '~features/subcategories/models';
import { Vendor } from '~features/vendors/models';
import { BarcodeScannerService } from './barcode-scanner.service';
import { CameraService } from './camera.service';

import { DeviceService } from './device.service';
import { DialogsService } from './dialogs.service';
import { EntityService } from './entity.service';
import { GoogleMapsApiLoaderService } from './google-maps-api-loader.service';
import { LocalNotificationsService } from './local-notifications.service';
import { LogService, StaticLog } from './log.service';
import { NetworkStatusService } from './network-status.service';
import { PushNotificationsService } from './push-notifications.service';
import { RemoteLogService } from './remote-log.service';
import { ServerSettingsService } from './server-settings.service';
import { ThemeableBrowserService } from './themeable-browser.service';
import { RatingService } from './rating.service';
import { KeyboardService } from '~core/services/keyboard.service';
import { TextZoomService } from '~core/services/text-zoom.service';
import { AppLauncherService } from '~core/services/app-launcher.service';
import { MapNavigatorService } from '~core/services/map-navigator.service';
import { EnhancedIssue } from '~features/enhanced-issues/models';


@NgModule({
  imports: [
    HttpClientModule,
    HttpClientJsonpModule,
  ],
  providers: [
    { provide: Appointment, useClass: EntityService },
    { provide: APLMessage, useClass: EntityService },
    { provide: Authority, useClass: EntityService },
    { provide: Banner, useClass: EntityService },
    { provide: Category, useClass: EntityService },
    { provide: EnhancedIssue, useClass: EntityService },
    { provide: Language, useClass: EntityService },
    { provide: MenuButton, useClass: EntityService },
    { provide: MissingProduct, useClass: EntityService },
    { provide: Redemption, useClass: EntityService },
    { provide: SubCatConfig, useClass: EntityService },
    { provide: SubCategory, useClass: EntityService },
    { provide: Vendor, useClass: EntityService },
    { provide: Office, useClass: EntityService },

    LogService,
    AppLauncherService,
    DeviceService,
    DialogsService,
    GeoLocationService,
    KeyboardService,
    StaticLog,
    NativePermissionsService,
    NetworkStatusService,
    RemoteLogService,
    ServerSettingsService,
    ThemeableBrowserService,
    CameraService,
    LocalNotificationsService,
    PushNotificationsService,
    BarcodeScannerService,
    RatingService,
    TextZoomService,
    GoogleMapsApiLoaderService,
    MapNavigatorService,

    AppVersion,
    AndroidPermissions,
    Device,
    Network,
    UniqueDeviceID
  ]
})
export class ServicesModule {
  constructor(staticLog: StaticLog) {
  }
}
