import { createSelector } from '@ngrx/store';
import { currentCard, currentProvider } from '~features/registration/registration.selectors';
import { Authority } from '../authorities/models';
import { Card } from '../registration/models';
import { allAppointments, appointmentsState, IAppointmentState } from './appointments.state';
import { Appointment } from './models';

export const mapToIsPending = (state: IAppointmentState): boolean => state.isPending;

export const mapToAppointments = (appointments: Appointment[], authority: Authority, card: Card): Appointment[] =>
  appointments?.filter(appointment =>
    +appointment.authorityId === +authority.id && !card.householdId
      ? appointment.cardNumber === card.cardNumber
      : appointment.householdId === card.householdId
  );

export const mapToHasAppointment = (appointments: Appointment[]): boolean => !!appointments?.length;

export const appointmentIsPending = createSelector(appointmentsState, mapToIsPending);

export const appointmentsForCurrentCard = createSelector(
  allAppointments,
  currentProvider,
  currentCard,
  mapToAppointments
);

export const hasAppointment = createSelector(appointmentsForCurrentCard, mapToHasAppointment);
