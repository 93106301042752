<cdk-virtual-scroll-viewport itemSize="113" minBufferPx="678" maxBufferPx="1356">
  <ion-item *cdkVirtualFor="let vendor of vendors" data-cy="list-item">
    <ion-item-sliding #item>
      <ion-item>
        <ion-label (click)="selected.emit({vendor: vendor, favorites: favorites})">
          <h2>
            <strong>
              <wic-vendor-icon [groupCode]="vendor.groupCode"></wic-vendor-icon>
              {{vendor.name}}
            </strong>
          </h2>
          <h2>{{vendor.address1}}</h2>
          <h2>{{vendor.address2}}</h2>
          <h2>{{vendor.city}}, {{vendor.state}} {{vendor.zip}}</h2>
          <p *ngIf="vendor.groupCode | shouldShowMessage">
            <strong [class]="vendor.groupCode === 2 ? 'text-red' : 'text-green'">
              {{ vendor.groupCode | groupCodedMessage | transloco }}
            </strong>
          </p>
          <h4 class="mt-10" [ngClass]="vendor.distance | distanceGrade" data-cy="distance">
            Distance: <strong>{{vendor.distance | prettyDistance}} {{!!vendor.distance ? 'mi' : '' | transloco}}</strong>
          </h4>
        </ion-label>
        <ion-note slot="end">
          <fa-icon data-cy="favorite-button"
              [icon]="(vendor | isFavorite:favorites) ? faHeart : faHeartOpen" size="2x"
              [styles]="(vendor | isFavorite:favorites) ? {'color': 'var(--soft-red)'} : {'color': 'var(--med-grey)'}"
              (click)="favoriteToggled.emit({vendor: vendor, favorites: favorites})">
          </fa-icon>
        </ion-note>
      </ion-item>
      <ion-item-options side="end">
        <ion-item-option color="white" (click)="directions.emit(vendor)" data-cy="directions-button">
          <fa-icon icon="directions" size="2x" [styles]="{'color': 'var(--ion-color-secondary)'}"></fa-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-item>
</cdk-virtual-scroll-viewport>