import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { EditCardModalComponent } from '../../components/edit-card-modal/edit-card-modal.component';

import { ProviderSelectedModalComponent } from '../../components/provider-selected-modal/provider-selected-modal.component';
import { Authority } from '../authorities/models';
import { Card } from './models';

@Injectable()
export class RegistrationService {

  constructor(private alerts: AlertController, private modals: ModalController, private transloco: TranslocoService) {
  }

  async confirmSwitchProvider(selected: Authority, current: Authority): Promise<boolean> {
    const alert = await this.alerts.create({
      header: this.transloco.translate('registration.confirmSwitchTitle'),
      message: this.transloco.translate('registration.confirmSwitchMessage', {currentName: current.name, selectedName: selected.name}),
      buttons: [{
        text: this.transloco.translate('Ok')
      }, {
        role: 'cancel',
        text: this.transloco.translate('Cancel')
      }]
    });

    await alert.present();
    const result = await alert.onDidDismiss();
    return result.role !== 'cancel';
  }

  async confirmProviderSelection(authority: Authority): Promise<boolean> {
    const modal = await this.modals.create({
      component: ProviderSelectedModalComponent,
      componentProps: {
        authority
      }
    });

    await modal.present();
    const result = await modal.onDidDismiss();
    return result.data;
  }

  async editCard(card: Card, provider: Authority): Promise<Card> {
    const modal = await this.modals.create({
      component: EditCardModalComponent,
      componentProps: {
        card,
        provider
      }
    });

    await modal.present();
    const result = await modal.onDidDismiss();
    return result.data;
  }
}
