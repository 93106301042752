import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {NavController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {TranslocoService} from '@ngneat/transloco';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {combineLatest, defer, from, of, pipe} from 'rxjs';
import {catchError, concatMap, exhaustMap, filter, first, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {DialogsService} from '~core/services/dialogs.service';
import {LogService} from '~core/services/log.service';
import {match} from '~jpma-wicshopper-imports-mono/utils/browser';
import {currentBenefits, hasCurrentBenefits} from '~features/benefits/benefits.selectors';
import {
  addEntry,
  addProduct,
  allowanceInsufficient,
  balanceInsufficientToToggle,
  calculatorBalancesRestored,
  calculatorEntriesRestored,
  checkAllowance,
  checkDollarAllowance,
  checkQuantityAllowance,
  clearCategoryEntries,
  clearCategoryEntriesConfirmed,
  clearEntries,
  createAdHocEntry,
  decrementEntry,
  editEntry,
  editNewEntry,
  incrementEntry,
  mergeEntry,
  noBenefitsAvailable,
  removeEntry,
  restoreCalculatorBalances,
  restoreCalculatorEntries,
  setBenefitBalance,
  setBenefitBalanceForCategories,
  setBenefitBalanceForProduct,
  toggleEntry,
  toggleEntryFailed,
  tryAddProduct,
  tryToggleEntry,
  viewCalculator
} from '~features/calculators/calculators.actions';
import {
  allBalances,
  allEntries,
  calculatorBalancesHaveBeenRestored,
  calculatorEntriesHaveBeenRestored,
  remainingBalance,
  remainingBalanceEmpty,
  remainingQuantity,
  totalCost,
  totalQuantity,
  userEnteredBalance
} from '~features/calculators/calculators.selectors';
import {CalculatorsService} from '~features/calculators/calculators.service';
import { CVV_CATEGORIES, CVV_CATEGORY } from '~features/calculators/calculators.state';
import {CalculatorBalance, CalculatorEntry} from '~features/calculators/models';
import {EnrichedProduct} from '~features/products/models';
import {isProvider} from '~features/registration/registration.selectors';
import {State} from '~features/state';
import {allExist} from '../../util/func-util';
import {ifExists, neverCompletes} from '../../util/rxjs-util';

const TAGS = ['Effects', 'Calculators'];

const EDITING = false;

const CALCULATOR_ENTRIES_STORAGE_KEY = 'wic::calculator_entries';
const CALCULATOR_BALANCES_STORAGE_KEY = 'wic::calculator_balances';

const ROUTE_MAP = {
  5: '/calculator/uom/5',
  16: '/calculator/uom/16',
  19: '/calculator/cvv/19',
  97: '/calculator/cvv/97',
};

export const isValidEntry = () => pipe(
  filter((entry: CalculatorEntry) => allExist(entry.name, entry.categoryId, entry.quantity, entry.unitPrice))
);

export const makeEntryFromProduct = () => pipe(
  map((product: EnrichedProduct): CalculatorEntry => ({
    itemNumber: product.itemNumber,
    name: product.description,
    categoryId: product.categoryId,
    subCategoryId: product.subCategoryId,
    unitPrice: product.price / 100,
    quantity: 1,
    size: product.size / 100,
    byCount: false,
    isSelected: true
  }))
);

export const withRemainingBalanceEmpty = (store: Store<State>) => pipe(
  mergeMap(({entry}) => of(entry).pipe(
    withLatestFrom(store.select(remainingBalanceEmpty(entry.categoryId)))
  )),
  neverCompletes()
);

export const withRemainingBalance = (store: Store<State>) => pipe(
  mergeMap(({entry}) => of(entry).pipe(
    withLatestFrom(store.select(remainingBalance(entry.categoryId)))
  )),
  neverCompletes()
);

export const noRemainingBalance = (store: Store<State>) => pipe(
  withRemainingBalanceEmpty(store),
  filter(([, balanceEmpty]) => !!balanceEmpty),
  map(([entry]) => ({entry}))
);

export const hasRemainingBalance = (store: Store<State>) => pipe(
  withRemainingBalanceEmpty(store),
  filter(([, balanceEmpty]) => !balanceEmpty),
  map(([entry]) => ({entry}))
);

export const withRemainingQuantity = (store: Store<State>) => pipe(
  mergeMap(({entry}) => of(entry).pipe(
    withLatestFrom(store.select(remainingQuantity(entry.categoryId)))
  )),
  neverCompletes()
);

export const showEditCalculatorEntry = (calculators: CalculatorsService, store: Store<State>, isAdding = true) => pipe(
  mergeMap(({entry}) => of(entry).pipe(
    withLatestFrom(store.select(remainingBalance(entry.categoryId)).pipe(first())
    ))),
  exhaustMap(([entry, balance]: [CalculatorEntry, number]) =>
    from(calculators.showEditCalculatorEntry(entry, balance, isAdding)),
  ),
  neverCompletes(),
  catchError(() => of(null))
);

export const showEditCalculatorBalance = (calculators: CalculatorsService, store: Store<State>) => pipe(
  mergeMap(({categoryId}) => store.select(userEnteredBalance(categoryId)).pipe(
    first(),
    map(balance => ({
      balance: {categoryId, balance: balance || 0},
      color: CVV_CATEGORIES.includes(categoryId) ? 'yellow' : 'green'
    }))
  )),
  exhaustMap(({balance, color}) =>
    from(calculators.showEditCalculatorBalance(balance, color)),
  ),
  neverCompletes(),
  catchError(() => of(null))
);

export const alertNoCurrentBenefitsToAdd = (dialogs: DialogsService) => pipe(
  exhaustMap(({entry}) =>
    dialogs.alert({
      message:
        `You do not have any current benefit balance to add '${entry.name}'. Refresh your benefits or update your balance and try again.`,
      title: 'No Benefit Balance'
    })
  ),
  neverCompletes() // Prevent resolution of promise from dialogs.alert() from completing this effect!
);

export const alertNotEnoughBenefitsToAdd = (dialogs: DialogsService) => pipe(
  exhaustMap(({entry}) =>
    dialogs.alert({
      message: `You do not have enough benefit balance left to add '${entry.name}'.`,
      title: 'Not Enough Remaining'
    })
  ),
  neverCompletes()
);

export const alertNotEnoughBenefitsToToggle = (dialogs: DialogsService) => pipe(
  exhaustMap(({entry}) =>
    dialogs.alert({
      message: `You do not have enough remaining benefit balance left to include '${entry.name}'.`,
      title: 'Not Enough Remaining'
    })
  ),
  neverCompletes()
);

@Injectable()
export class CalculatorsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private log: LogService,
    private storage: Storage,
    private calculators: CalculatorsService,
    private dialogs: DialogsService,
    private transloco: TranslocoService,
    private nav: NavController,
    private router: Router) {
  }

  restoreCalculatorEntries$ = createEffect(
    () => this.actions$.pipe(
      ofType(restoreCalculatorEntries),
      withLatestFrom(this.store.select(calculatorEntriesHaveBeenRestored)),
      filter(([, wereRestored]) => !wereRestored),
      tap(() => this.log.debug(TAGS, 'Restoring previous calculator entries...')),
      switchMap(() =>
        from(this.storage.get(CALCULATOR_ENTRIES_STORAGE_KEY)).pipe(
          map(json => !!json ? JSON.parse(json) : []),
          tap({
            error: err => this.log.error(TAGS, 'Error encountered getting calculator entries: ', err)
          }),
          map((entries) => entries.map(entry => ({...entry, subCategoryId: undefined})))
        )
      ),
      neverCompletes(),
      map((entries: CalculatorEntry[]) => calculatorEntriesRestored({entries})),
      tap(() => this.log.debug(TAGS, 'Previous calculator entries restored.')),
    ),
  );

  restoreCalculatorBalances$ = createEffect(
    () => this.actions$.pipe(
      ofType(restoreCalculatorBalances),
      withLatestFrom(this.store.select(calculatorBalancesHaveBeenRestored)),
      filter(([, wereRestored]) => !wereRestored),
      tap(() => this.log.debug(TAGS, 'Restoring previous calculator balances...')),
      switchMap(() =>
        from(this.storage.get(CALCULATOR_BALANCES_STORAGE_KEY)).pipe(
          map(json => !!json ? JSON.parse(json) : []),
          tap({
            error: err => this.log.error(TAGS, 'Error encountered getting calculator balances: ', err)
          }),
          map((balances) => balances.map(balance => ({...balance, subCategoryId: undefined})))
        )
      ),
      neverCompletes(),
      map((balances: CalculatorBalance[]) => calculatorBalancesRestored({balances})),
      tap(() => this.log.debug(TAGS, 'Previous calculator balances restored.')),
    )
  );

  confirmClearCategoryEntries$ = createEffect(
    () => this.actions$.pipe(
      ofType(clearCategoryEntries),
      switchMap(({categoryId}) =>
        this.dialogs.confirm({
          title: 'Clear Calculator?',
          message: 'Are you sure you wish to clear the calculator?',
          okText: 'Yes',
          cancelText: 'No'
        }).pipe(
          filter(result => !!result),
          map(() => ({categoryId}))
        )
      ),
      neverCompletes(),
      map(({categoryId}) => clearCategoryEntriesConfirmed({categoryId}))
    )
  );

  storeCalculatorEntries$ = createEffect(
    () => this.actions$.pipe(
      ofType(mergeEntry, toggleEntry, incrementEntry, decrementEntry, removeEntry, clearEntries, clearCategoryEntriesConfirmed),
      withLatestFrom(this.store.select(allEntries)),
      tap(() => this.log.debug(TAGS, 'Saving all calculator entries...')),
      map(([, entries]) => JSON.stringify(entries)),
      switchMap(json =>
        from(this.storage.set(CALCULATOR_ENTRIES_STORAGE_KEY, json)).pipe(
          tap({
            error: err => this.log.error(TAGS, 'Error encountered saving calculator entries: ', err)
          })
        )
      ),
      tap(() => this.log.debug(TAGS, 'All calculator entries saved.')),
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  storeCalculatorBalances$ = createEffect(
    () => this.actions$.pipe(
      ofType(setBenefitBalance, clearCategoryEntriesConfirmed),
      withLatestFrom(this.store.select(allBalances)),
      tap(() => this.log.debug(TAGS, 'Saving all calculator balances...')),
      map(([, balances]) => JSON.stringify(balances)),
      switchMap(json =>
        from(this.storage.set(CALCULATOR_BALANCES_STORAGE_KEY, json)).pipe(
          tap({
            error: err => this.log.error(TAGS, 'Error encountered saving calculator balances: ', err)
          })
        )
      ),
      tap(() => this.log.debug(TAGS, 'All calculator balances saved.')),
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  verifyCanAddProduct$ = createEffect(
    () => this.actions$.pipe(
      ofType(tryAddProduct),
      mergeMap(({product}) =>
        this.store.select(userEnteredBalance(product.categoryId)).pipe(
          withLatestFrom(this.store.select(isProvider)),
          take(1),
          map(([balance, isAProvider]) => ({
            product,
            balance,
            isAProvider
          }))
        )
      ),
      switchMap(({product, balance, isAProvider}) =>
        match(
          () => ({isAProvider, balance}),
          check => [{isAProvider: false, balance: match.gt(0)}, of(addProduct({product}))],
          check => [{isAProvider: true}, of(addProduct({product}))],
          check => [{isAProvider: false, balance: match.falsy}, of(setBenefitBalanceForProduct({product}))],
        )
      )
    )
  );

  addProduct$ = createEffect(
    () => this.actions$.pipe(
      ofType(addProduct),
      map(({product}) => product),
      ifExists(),
      makeEntryFromProduct(),
      switchMap(entry =>
        match(
          () => CVV_CATEGORIES.includes(entry.categoryId),
          isCVV => [
            true,
            of(editNewEntry({entry})).pipe(
              tap(() => this.log.debug(TAGS, `Editing new entry ${entry.name} to calculator...`, entry))
            )
          ],
          isCVV => [
            false,
            of(addEntry({entry})).pipe(
              tap(() => this.log.debug(TAGS, `Adding entry ${entry.name} to calculator...`, entry))
            )
          ]
        )
      )
    )
  );

  editEntry$ = createEffect(
    () => this.actions$.pipe(
      ofType(editEntry),
      tap(({entry}) => this.log.debug(TAGS, `Editing entry ${entry.name}...`, entry)),
      showEditCalculatorEntry(this.calculators, this.store, EDITING),
      filter(entry => !!entry),
      map(entry => mergeEntry({entry, replace: true}))
    )
  );

  warnCantCreateEntry$ = createEffect(
    () => this.actions$.pipe(
      ofType(createAdHocEntry, editNewEntry),
      noRemainingBalance(this.store),
      tap(() => this.dialogs.alert({
        title: this.transloco.translate('calculator-errors.createErrorTitle'),
        message: this.transloco.translate('calculator-errors.noBenefitErrorMessage')
      }))
    ),
    {dispatch: false}
  );

  createEntry$ = createEffect(
    () => this.actions$.pipe(
      ofType(createAdHocEntry),
      hasRemainingBalance(this.store),
      tap(({categoryId}) => this.log.trace(TAGS, `Creating CVV item for ${categoryId}...`)),
      showEditCalculatorEntry(this.calculators, this.store),
      filter(entry => !!entry),
      map(entry => mergeEntry({entry, replace: false}))
    )
  );

  showEditBalance$ = createEffect(
    () => this.actions$.pipe(
      ofType(setBenefitBalanceForCategories),
      tap(({categoryId}) => this.log.trace(TAGS, `Editing benefit balance for ${categoryId}`)),
      showEditCalculatorBalance(this.calculators, this.store),
      filter(balance => !!balance),
      map(balance => setBenefitBalance({balance}))
    )
  );

  setBalanceForProduct$ = createEffect(
    () => this.actions$.pipe(
      ofType(setBenefitBalanceForProduct),
      tap(({product: categoryId}) => this.log.trace(TAGS, `Editing benefit balance for ${categoryId}`)),
      switchMap(({product}) =>
        of({categoryId: product.categoryId}).pipe(
          showEditCalculatorBalance(this.calculators, this.store),
          switchMap(balance => [setBenefitBalance({balance}), addProduct({product})])
        )
      )
    )
  );

  editNewEntry$ = createEffect(
    () => this.actions$.pipe(
      ofType(editNewEntry),
      tap(({entry}) => this.log.debug(TAGS, `Editing entry ${entry.name} in calculator...`, entry)),
      showEditCalculatorEntry(this.calculators, this.store),
      filter(entry => !!entry),
      map(entry => addEntry({entry}))
    )
  );

  addEntry$ = createEffect(
    () => this.actions$.pipe(
      ofType(addEntry),
      withLatestFrom(
        this.store.select(hasCurrentBenefits),
        this.store.select(isProvider)
      ),
      tap(([{entry}]) => this.log.debug(TAGS, `Trying to add entry ${entry.name} to calculator...`, entry)),
      exhaustMap(([{entry}, hasBenefits, isAProvider]) =>
        match(
          () => ({hasBenefits, isAProvider}),
          check => [{hasBenefits: true, isAProvider: true}, defer(() =>
            of(checkAllowance({entry}))
          )],
          check => [{hasBenefits: false, isAProvider: false}, defer(() =>
            of(checkAllowance({entry}))
          )],
          check => [{hasBenefits: false, isAProvider: true}, defer(() =>
            of(noBenefitsAvailable({entry}))
          )]
        )
      )
    )
  );

  warnNoBenefitsAvailable$ = createEffect(
    () => this.actions$.pipe(
      ofType(noBenefitsAvailable),
      tap(({entry}) => this.log.warn(TAGS, `Not enough benefit balance available to add '${entry.name}!'`)),
      alertNoCurrentBenefitsToAdd(this.dialogs)
    ),
    {dispatch: false}
  );

  warnAllowanceInsufficient$ = createEffect(
    () => this.actions$.pipe(
      ofType(allowanceInsufficient),
      tap(({entry}) => this.log.warn(TAGS, `Not enough benefit balance available to add '${entry.name}!'`)),
      alertNotEnoughBenefitsToAdd(this.dialogs)
    ),
    {dispatch: false}
  );

  checkAllowance$ = createEffect(
    () => this.actions$.pipe(
      ofType(checkAllowance),
      withLatestFrom(
        this.store.select(currentBenefits),
        this.store.select(isProvider),
      ),
      mergeMap(([{entry}, benefits, isAProvider]) =>
        of({}).pipe(
          withLatestFrom(
            this.store.select(remainingBalance(entry.categoryId)),
            this.store.select(remainingQuantity(entry.categoryId)),
            this.store.select(userEnteredBalance(entry.categoryId))
          ),
          map(([, balance, quantity, userBalance]) => ({
            entry,
            benefit: isProvider && benefits && benefits.benefits && benefits.benefits.find(benefit =>
              benefit.categoryId === entry.categoryId
            ),
            remaining: CVV_CATEGORIES.includes(entry.categoryId) ? balance : quantity,
            userBalance,
            isAProvider
          }))
        )
      ),
      tap(({entry}) => this.log.trace(TAGS, `Determining allowance check type for '${entry.name}'...`)),
      concatMap(({entry, benefit, remaining, userBalance, isAProvider}) =>
        match(
          () => ({
            categoryId: entry.categoryId,
            benefitQuantity: benefit && benefit.quantity,
            benefit,
            isAProvider,
            remaining
          }),
          check => [
            {isAProvider: true, benefit: match.nullish},
            of(noBenefitsAvailable({entry})).pipe(tap(() => this.log.trace(TAGS, 'Balance check failed. Is a provider and no benefits.')))
          ],
          check => [
            {isAProvider: true, benefitQuantity: match.falsy},
            of(noBenefitsAvailable({entry})).pipe(tap(() => this.log.trace(TAGS, 'Balance check failed. Is a provider and no quantity.')))
          ],
          check => [
            {isAProvider: false, remaining: match.falsy},
            of(noBenefitsAvailable({entry})).pipe(tap(() => this.log.trace(TAGS, 'Balance check failed. Not a provider and no balance.')))
          ],
          check => [
            () => CVV_CATEGORIES.includes(check.categoryId),
            of(mergeEntry({entry, replace: true})).pipe(tap(() => this.log.trace(TAGS, 'Balance check passed. Is CVV.')))
          ],
          check => [
            match.anything,
            of(checkQuantityAllowance({entry, balance: isAProvider ? benefit.availableQuantity : userBalance})).pipe(
              tap(() => this.log.trace(TAGS, 'Balance check pending, other...'))
            )
          ]
        )
      ),
      tap({
        next: ({entry, balance}) => this.log.trace(TAGS, `Entry was ${entry.name} and balance was ${balance}.`),
        error: err => this.log.error(TAGS, 'Error occurred handling checkAllowance', err)
      })
    )
  );

  checkQuantityAllowance$ = createEffect(
    () => this.actions$.pipe(
      ofType(checkQuantityAllowance),
      concatMap(({entry, balance}) => combineLatest([
        of({entry, balance}),
        this.store.pipe(select(totalQuantity(entry.categoryId)), first()),
      ])),
      map(([{entry, balance}, usedQuantity]) => ({
        entry,
        available: balance,
        required: (entry.quantity * entry.size) + usedQuantity
      })),
      tap(({entry}) => this.log.trace(TAGS, `Checking if qty allowance sufficient for '${entry.name}'...`)),
      concatMap(({entry, available, required}) =>
        match(
          () => available >= required,
          canAdd => [true, of(mergeEntry({entry, replace: false}))],
          canAdd => [false, of(allowanceInsufficient({entry}))]
        )
      )
    )
  );

  checkDollarAllowance$ = createEffect(
    () => this.actions$.pipe(
      ofType(checkDollarAllowance),
      concatMap(({entry, balance}) => combineLatest([
        of({entry, balance}),
        this.store.pipe(select(totalCost(entry.categoryId)), first()),
      ])),
      map(([{entry, balance}, cost]) => ({
        entry,
        available: balance,
        required: (entry.quantity * entry.unitPrice) + cost
      })),
      tap(({entry}) => this.log.trace(TAGS, `Checking if dollar allowance sufficient for '${entry.name}'...`)),
      concatMap(({entry, available, required}) =>
        match(
          () => available >= required,
          canAdd => [true, of(mergeEntry({entry, replace: true}))],
          canAdd => [false, of(allowanceInsufficient({entry}))]
        )
      )
    )
  );

  mergeEntry$ = createEffect(
    () => this.actions$.pipe(
      ofType(mergeEntry),
      tap(({entry}) => this.log.debug(TAGS, `Added/updated ${entry.name} in calculator.`)),
      filter(({entry}) => this.router.url !== ROUTE_MAP[entry.categoryId]),
      tap(({entry}) => this.nav.navigateForward(ROUTE_MAP[entry.categoryId]))
    ),
    {dispatch: false}
  );

  viewCalculator$ = createEffect(
    () => this.actions$.pipe(
      ofType(viewCalculator),
      filter(({category}) =>
        this.router.url !== ROUTE_MAP[category.categoryId]
      ),
      tap(({category}) =>
        this.log.trace(TAGS, `Viewing calculator for benefit category ${category.categoryId}`
        )
      ),
      tap(({category}) => this.nav.navigateForward(ROUTE_MAP[category.categoryId])
      )
    ),
    {dispatch: false}
  );

  toggleCVV$ = createEffect(
    () => this.actions$.pipe(
      ofType(tryToggleEntry),
      tap(({entry}) => this.log.debug(TAGS, `Toggling CVV entry ${entry.name} in calculator.`)),
      filter(({entry}) => CVV_CATEGORIES.includes(entry.categoryId)),
      map(({entry}) => toggleEntry({entry}))
    )
  );

  // checkBalanceLowForToggle$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(tryToggleEntry),
  //     filter(({entry}) => entry.categoryId === CVV_CATEGORY),
  //     withRemainingBalance(this.store),
  //     mergeMap(([entry, balance]) =>
  //       match(
  //         () => ({hasEnough: balance - (entry.unitPrice * entry.quantity) > 0, entry}),
  //         check => [{entry: {isSelected: true}}, of(toggleEntry({entry}))],
  //         check => [{hasEnough: true}, of(toggleEntry({entry}))],
  //         check => [{hasEnough: false}, of(toggleEntryFailed({entry, reason: 'balance'}))]
  //       )
  //     )
  //   )
  // );

  checkQuantityLowForToggle$ = createEffect(
    () => this.actions$.pipe(
      ofType(tryToggleEntry),
      filter(({entry}) => !CVV_CATEGORIES.includes(entry.categoryId)),
      withRemainingQuantity(this.store),
      mergeMap(([entry, quantity]) =>
        match(
          () => ({hasEnough: quantity - (entry.size * entry.quantity) > 0, entry}),
          check => [{entry: {isSelected: true}}, of(toggleEntry({entry}))],
          check => [{hasEnough: true}, of(toggleEntry({entry}))],
          check => [{hasEnough: false}, of(toggleEntryFailed({entry, reason: 'quantity'}))]
        )
      )
    )
  );

  warnBenefitLowWhenToggled$ = createEffect(
    () => this.actions$.pipe(
      ofType(toggleEntryFailed),
      exhaustMap(({entry, reason}) =>
        match(
          () => reason,
          () => ['balance', of(balanceInsufficientToToggle({entry}))]
        )
      )
    )
  );

  warnBalanceInsufficientToToggle$ = createEffect(
    () => this.actions$.pipe(
      ofType(balanceInsufficientToToggle),
      alertNotEnoughBenefitsToToggle(this.dialogs)
    ),
    {dispatch: false, resubscribeOnError: true}
  );
}
