<ion-header>
  <ion-toolbar color="offices"></ion-toolbar>
</ion-header>

<ion-content>
  <wic-custom-error-toolbar *ngIf="(offices.officeUserLocation$ | async) === undefined"
    [title]="('vendors.noLocationTitle' | transloco)" 
    [message]="('vendors.noLocationMessage' | transloco)"
  >
  </wic-custom-error-toolbar>

  <ion-list *ngIf="(officesSvc.isLoading$ | async) || ((app.mapsApiLoaded$ | async) === false); else map">
    <ion-item>
      <ion-label><h2>{{'offices.loadingLabel' | transloco}}</h2></ion-label>
      <ion-spinner slot="end" name="dots"></ion-spinner>
    </ion-item>
  </ion-list>

  <ng-template #map>
    <wic-offices-map
        [location]="originalLocation$ | async"
        [offices]="offices.filtered$ | async"
        [favorites]="offices.favorites$ | async"
        [userLocation]="geolocation.location$ | async"
        (selected)="offices.viewDetail($event)">
    </wic-offices-map>
  </ng-template>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-searchbar data-cy="search"
        [value]="offices.filterCriteria$ | async"
        [placeholder]="'offices.filterLabel' | transloco"
        (ionChange)="offices.setFilter($event.target.value)">
    </ion-searchbar>
  </ion-toolbar>
</ion-footer>
