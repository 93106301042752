export const nonNull = (value: any) => value != null;
export const pipe = (...fns) => value => fns.reduce((v, fn) => fn(v), value);
export const tpipe = <
  TValue,
  TOut1, TOut2, TOut3,
  TOut4, TOut5, TOut6,
  TOut7, TOut8, TOut9,
  TOut10, TOut11, TOut12,
  TOut13, TOut14,
  TResult
>(
  fn1: (input: TValue) => TOut1 | TResult,
  fn2?: (input: TOut1) => TOut2 | TResult,
  fn3?: (input: TOut2) => TOut3 | TResult,
  fn4?: (input: TOut3) => TOut4 | TResult,
  fn5?: (input: TOut4) => TOut5 | TResult,
  fn6?: (input: TOut5) => TOut6 | TResult,
  fn7?: (input: TOut6) => TOut7 | TResult,
  fn8?: (input: TOut7) => TOut8 | TResult,
  fn9?: (input: TOut8) => TOut9 | TResult,
  fn10?: (input: TOut9) => TOut10 | TResult,
  fn11?: (input: TOut10) => TOut11 | TResult,
  fn12?: (input: TOut11) => TOut12 | TResult,
  fn13?: (input: TOut12) => TOut13 | TResult,
  fn14?: (input: TOut13) => TOut14 | TResult,
  ...fns: ((input: any) => any | TResult)[]
) =>
  (value: TValue): TResult =>
    [fn1, fn2, fn3, fn4, fn5, fn6, fn7, fn8, fn9, fn10, fn11, fn12, fn13, fn14, ...(fns || [])]
      .filter(fn => !!fn)
      .reduce((v, fn) => fn(v), value);

export const has = (a?: any): boolean => !!a;
export const hasAny = (...aa: any[]): boolean =>
  aa.reduce((does, a) => does || has(a), false);

export const exists = (a?: any): boolean => a != null;
export const anyExist = (...aa: any[]): boolean =>
  aa.reduce((does, a) => does || exists(a), false);
export const allExist = (...aa: any[]): boolean =>
  aa.reduce((does, a) => does && exists(a), true);

/* eslint-disable */
export const uuid = (a?, b?) => {
  for (
    b = a = '';
    a++ < 36;
    b += (a * 51) & 52 ? (a ^ 15 ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4)) : 4).toString(16) : '-'
  );
  return b;
};
/* eslint-enable */
