import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Banner } from '~features/banners/models';
import { State } from '../state';
import {
  BannersFacadeBase,
  detailedBanners, detailedPriorityBanners,
  hasBanner,
  hasManyBanners, hasManyPriorityBanners,
  loadBanners,
  openBannerLink,
} from './banners.state';

@Injectable()
export class BannersFacade extends BannersFacadeBase {
  detailedBanners$ = this.store.select(detailedBanners);
  detailedPriorityBanners$ = this.store.select(detailedPriorityBanners);
  hasManyBanners$ = this.store.select(hasManyBanners);
  hasManyPriorityBanners$ = this.store.select(hasManyPriorityBanners);
  hasBanner$ = this.store.select(hasBanner);

  constructor(private store: Store<State>) {
    super(Banner, store);
  }

  loadForCurrentAuthority(): void {
    this.store.dispatch(loadBanners());
  }

  openBannerLink(banner: Banner) {
    this.store.dispatch(openBannerLink({ banner }));
  }
}
