import { NgModule } from '@angular/core';
import { UPCService } from '~features/upc/upc.service';
import { UpcFacade } from './upc.facade';
import { UpcUtilService } from './upc-util.service';


@NgModule({
  providers: [
    UpcFacade,
    UpcUtilService,
    UPCService
  ]
})
export class UpcModule {
}
