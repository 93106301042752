import { Injectable } from '@angular/core';
import { EntityActionTypes, ofEntityType } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, from, iif, of } from 'rxjs';
import { catchError, delay, exhaustMap, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { LogService } from '~core/services/log.service';
import { viewWebSite } from '~features/ionic/ionic.actions';
import { State } from '~features/state';
import { Office, OfficeFavorite } from './models';
import {
  allOffices, applyOfficeSearch,
  clearOffices,
  loadAllOffices,
  loadIfNeeded,
  loadOffices,
  officeFavorited,
  officeFavoriteToggled,
  officeLoadFailed,
  officesLoaded,
  officesLoadedAt,
  officeUnfavorited, officeUserLocationChanged,
  officeWebsiteViewed,
  requestLocationPermission,
  resetOffices,
  returnToHome,
} from './offices.state';
import { NativePermissionsService } from '~core/services/native-permissions.service';
import { GeoLocationService } from '~core/services/geo-location.service';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { add, isAfter } from 'date-fns';
import { searchCriteria } from '~features/offices/offices.selectors';
import { ConfigService } from '~core/config/config.service';

const TAGS = ['Effects', 'Offices'];

export const isFavorite = (office: Office, favorites: OfficeFavorite[]) => (): boolean =>
  !!favorites.find(favorite => favorite.locationId === office.locationId);

@Injectable()
export class OfficesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private log: LogService,
    private config: ConfigService,
    private permissions: NativePermissionsService,
    private geolocationService: GeoLocationService,
    private registration: RegistrationFacade,
  ) {}

  toggleFavorite$ = createEffect(
    () => this.actions$.pipe(
      ofType(officeFavoriteToggled),
      exhaustMap(({office, favorites}) =>
        iif(
          isFavorite(office, favorites),
          of(officeUnfavorited({office})),
          of(officeFavorited({office}))
        )
      )
    )
  );

  favorite$ = createEffect(
    () => this.actions$.pipe(
      ofType(officeFavorited),
      tap(({office}) => this.log.trace(TAGS, `User has favorited office ${office.name}.`))
    ),
    {dispatch: false}
  );

  unfavorite$ = createEffect(
    () => this.actions$.pipe(
      ofType(officeUnfavorited),
      tap(({office}) => this.log.trace(TAGS, `User has un-favorited office ${office.name}.`))
    ),
    {dispatch: false}
  );

  viewWebsite$ = createEffect(
    () => this.actions$.pipe(
      ofType(officeWebsiteViewed),
      tap(({office}) => this.log.trace(TAGS, `User is viewing office website ${office.webAddress}`)),
      map(({office}) => viewWebSite({webAddress: office.webAddress}))
    )
  );

  trackLoadError$ = createEffect(
    () => this.actions$.pipe(
      ofEntityType(Office, EntityActionTypes.LoadAllFailure),
      map(() => officeLoadFailed({}))
    )
  );

  officesLoaded$ = createEffect(
    () => this.actions$.pipe(
      ofEntityType(Office, EntityActionTypes.LoadAllSuccess),
      map(() => officesLoaded())
    )
  );

  setDefaultSearchCriteria$ = createEffect(
    () => this.actions$.pipe(
      ofType(officesLoaded),
      withLatestFrom(this.store.select(searchCriteria)),
      map(([, criteria]) => ({
        ...criteria,
        anywhere: this.config.offices.searchAnywhere || criteria.anywhere,
      })),
      map((criteria) => applyOfficeSearch({ criteria }))
    )
  );

  loadIfNeeded$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadIfNeeded),
      withLatestFrom(
        this.store.select(officesLoadedAt),
        this.store.select(allOffices)
      ),
      filter(([{force}, loadedAt, offices]) => force || !loadedAt || !offices.length || isAfter(new Date(), add(loadedAt, {
        minutes: 1
      }))),
      delay(500),
      switchMap(() =>
        from(this.geolocationService.hasLocationPermission()).pipe(
          catchError(() => of(true)),
        )),
      map((hasPermission) => hasPermission ?
        loadOffices() :
        requestLocationPermission()
      )
    )
  );

  requestLocationPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestLocationPermission),
      switchMap(() => this.permissions.explainGeolocationPermission('misty-moss', 'secondary')),
      switchMap(() => this.geolocationService.requestLocationPermission()),
      map((hasPermission) => hasPermission === 'granted' ?
        loadOffices() :
        returnToHome()
      )
    )
  );

  loadOffices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOffices),
      switchMap(() =>
        combineLatest([
          this.registration.currentProviderId$.pipe(first(id => !!id)),
          this.geolocationService.getLocation().pipe(first(location => !!location),
          catchError(() => of(null)))
        ])
      ),
      map(([id, location]) => loadAllOffices({
        criteria: {
          deescalateError: true,
          options: {
            location
          },
          parents: {
            authorities: id
          },
          version: 2
        }
      }))
    )
  );

  trackLocationChange$ = createEffect(() =>
    this.geolocationService.location$.pipe(
      map((location) => officeUserLocationChanged({ location }))
    )
  );

  resetOffices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetOffices),
      map(() => clearOffices())
    )
  );
}
