import { Component, OnInit } from '@angular/core';

import { scrollToEventTarget } from '~core/util/scrollToElement';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';

import { UpcFacade } from '~features/upc/upc.facade';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { ProductFacade } from '~features/products/product.facade';
import { HomeFacade } from '~features/home/home.facade';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ViewWillEnter } from '@ionic/angular';

@Component({
  selector: 'wic-search-upc',
  templateUrl: './search-upc.page.html',
  styleUrls: ['./search-upc.page.scss'],
})
export class SearchUpcPage implements OnInit, ViewWillEnter {
  segment = 'upc';

  get formValue(): string {
    return this.form.value.upc;
  }

  form: UntypedFormGroup;
  textSearchForm = new FormControl('');

  constructor(public upc: UpcFacade, private analytics: AnalyticsFacade, 
    public products: ProductFacade,
    public home: HomeFacade,
    public registration: RegistrationFacade,
    public enhancedMode: EnhancedModeFacade,
    private builder: UntypedFormBuilder) {

  }
  
  ionViewWillEnter(): void {
    this.products.selectAllProducts();
    this.textSearchForm.reset('');
  }
  
  ngOnInit() {
    this.analytics.pageView('search-upc');

    this.form = this.builder.group({
      upc: [null, [Validators.required, Validators.maxLength(14)]]
    });
  }

  scrollIntoView(event: Event): void {
    setTimeout(
      () => {
        scrollToEventTarget(event);
      }, 300
    );
  }
}
