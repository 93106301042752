// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// noinspection JSUnusedLocalSymbols
const API_LOCAL = 'http://localhost:3001/api';
// noinspection JSUnusedLocalSymbols
const API_DEV = 'https://ws-dev-api.jpma.com/api';
// noinspection JSUnusedLocalSymbols
const API_STAGE = 'https://ws-dev-api.jpma.com/api';
// noinspection JSUnusedLocalSymbols
const API_PROD = 'https://ws-api.jpma.com/api';
// noinspection JSUnusedLocalSymbols
const AZURE_GATEWAY_DEV =
  'https://wicshopper-gateway.azure-api.net/add-images-function';
// noinspection JSUnusedLocalSymbols
const AZURE_GATEWAY_PROD =
  'https://wicshopper-gateway.azure-api.net/wicshopper-integrations';

export const environment = {
  production: false,
  hosts: {
    api: API_DEV,
    cdn: 'https://wicshopper-images-endpoint.azureedge.net',
    azureGateway: {
      baseUrl: AZURE_GATEWAY_DEV,
      subscriptionKey: '66f5271149b94a63b732b4bf88483345'
    },
  },
  analytics: {
    gaKey: 'AIzaSyDXhevAfw48Ojvr7NZZnE5Z6kYSEIgvQww',
    gaAppId: 'UA-89743076-1',
  },
  mapping: {
    apiKey: 'AIzaSyDXhevAfw48Ojvr7NZZnE5Z6kYSEIgvQww',
  },
  logging: {
    enableLocal: true,
    level: 'trace', // SEE: log.service.ts for logging levels
  },
  support: {
    to: ['support@jpma.com'],
    partnersUrl: 'http://www.ebtshopper.com/partners',
    aboutUrl: 'http://www.ebtshopper.com/wicshopper-about/',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
