import { Action, createAction, createReducer, createSelector, on, props } from '@ngrx/store';

import { formatISO } from 'date-fns';

import { State } from '../state';
import { Receipt } from './models';

export const restorePriorReceipts = createAction(
  '[Receipts] Restore Prior Receipt'
);

export const priorReceiptsRestored = createAction(
  '[Receipts] Prior Receipt Restored',
  props<{ receipts: Receipt[] }>()
);

export const acquireReceiptPhoto = createAction(
  '[Receipts] Acquire Receipt Photo'
);

export const receiptPhotoAcquired = createAction(
  '[Receipts] Receipt Photo Acquired',
  props<{ image: string }>()
);

export const selectReceipt = createAction(
  '[Receipts] Select',
  props<{ receipt: Receipt }>()
);

export const deleteReceipt = createAction(
  '[Receipts] Delete',
  props<{ index: number}>()
);

export const deleteReceiptConfirmed = createAction(
  '[Receipts] Deletion Confirmed',
  props<{ index: number}>()
);

export interface IReceiptsState {
  receiptIndex?: number;
  receipts?: Receipt[];
}

export const initialState: IReceiptsState = {};

const reduce = createReducer(
  initialState,
  on(priorReceiptsRestored, (state, {receipts}) => ({
    ...state,
    receiptIndex: (!!receipts && !!receipts.length) ? 0 : null,
    receipts
  })),
  on(receiptPhotoAcquired, (state, {image}) => ({
      ...state,
      receiptIndex: 0,
      receipts: [{
        dateCaptured: formatISO(new Date()),
        receiptImage: image
      }, ...(state.receipts || []).slice(0, 6)]
    })
  ),
  on(selectReceipt, (state, {receipt}) => ({
    ...state,
    receiptIndex: state.receipts.indexOf(receipt)
  })),
  on(deleteReceiptConfirmed, (state, {index}) => ({
    ...state,
    receiptIndex: state.receipts.length <= 1
      ? null // If there are no receipts left, don't select anything
      : index > state.receiptIndex
      ? state.receiptIndex // If the deleted index was higher than the selected, leave it
      : state.receiptIndex - 1, // If the deleted index was lower than the selected, shift selected down one (keep the same selection)
    receipts: state.receipts.filter((_, existingIndex) => index !== existingIndex)
  }))
);

export function receiptsReducer(state: IReceiptsState, action: Action): IReceiptsState {
  return reduce(state, action);
}

export const getReceiptsState = (state: State): IReceiptsState =>
  state.receipts;

export const mapToAllReceipts = (state: IReceiptsState): Receipt[] =>
  (!!state.receipts && !!state.receipts.length) ? state.receipts : null;

export const mapToCurrentReceipt = (state: IReceiptsState): Receipt =>
  (!!state.receipts && !!state.receipts.length) ? state.receipts[state.receiptIndex || 0] : null;

export const mapToHasReceiptImage = (receipt: Receipt): boolean =>
  !!receipt && !!receipt.receiptImage;

export const mapToHasReceiptsHistory = (state: IReceiptsState): boolean =>
  !!state.receipts && !!state.receipts.length;

export const mapToReceiptsHistory = (state: IReceiptsState): Receipt[] =>
  !!state.receipts && !!state.receipts.length ? state.receipts : [];

export const mapToReceiptImage = (receipt: Receipt, hasImage: boolean): string =>
  hasImage ? 'data:image/jpeg;base64,' + receipt.receiptImage : null;

export const mapToSelectedIndex = (state: IReceiptsState): number =>
  state.receiptIndex == null ? -1 : state.receiptIndex;


export const allReceipts = createSelector(
  getReceiptsState,
  mapToAllReceipts
);

export const currentReceipt = createSelector(
  getReceiptsState,
  mapToCurrentReceipt
);

export const hasReceiptImage = createSelector(
  currentReceipt,
  mapToHasReceiptImage
);

export const hasReceiptsHistory = createSelector(
  getReceiptsState,
  mapToHasReceiptsHistory
);

export const receiptsHistory = createSelector(
  getReceiptsState,
  mapToReceiptsHistory
);

export const currentReceiptImage = createSelector(
  currentReceipt,
  hasReceiptImage,
  mapToReceiptImage
);

export const selectedReceiptIndex = createSelector(
  getReceiptsState,
  mapToSelectedIndex
);
