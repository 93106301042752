import { Pipe, PipeTransform } from '@angular/core';
import { GROUP_CODED_MESSAGE_MAP } from './group-coded-message.pipe';

@Pipe({
  name: 'shouldShowMessage'
})
export class ShouldShowMessagePipe implements PipeTransform {
  transform(groupCode: number): boolean {
    return !!GROUP_CODED_MESSAGE_MAP[groupCode]
  }
}
