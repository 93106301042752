import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'EnhancedIssues',
  uriName: 'enhanced-issues'
})
export class EnhancedIssue {
  @Key issueId: number;
  authorityId: number;
  displayOrder: number;
  link?: string;
  issueName: string;
}