import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { NetworkStatusService } from '~core/services/network-status.service';
import { match } from '~jpma-wicshopper-imports-mono/utils/browser';
import { EnvironmentService } from '~features/environment.service';
import { UserActivity } from '~features/reporting/models/user-activity';

const PENDING_ACTIVITIES_KEY = 'wic::pending_activities';
const TAGS = ['Service', 'Reporting', 'Modern'];


@Injectable()
export class ReportingService {
  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private network: NetworkStatusService,
    private storage: Storage,
    private log: LogService) {
    network.status$.subscribe(async isOnline => {
      if (isOnline) {
        await this.flushActivities();
      }
    });
  }

  logActivity(activity: UserActivity): Observable<any> {
    return from(this.storage.get(PENDING_ACTIVITIES_KEY)).pipe(
      map(json => !json ? [] : JSON.parse(json)),
      map(pendingActivities => pendingActivities || []),
      switchMap(pendingActivities =>
        match(
          () => !pendingActivities || !pendingActivities.length,
          hasPending => [false, of([activity])],
          hasPending => [true, of(pendingActivities).pipe(
            map(activities => [
              ...activities,
              activity
            ])
          )]
        )
      ),
      switchMap(activities =>
        match(
          () => this.network.status,
          isOnline => [true, this.postActivities(activities)],
          isOnline => [false, EMPTY.pipe(
            tap(() => this.storage.set(PENDING_ACTIVITIES_KEY, JSON.stringify(activities))),
            tap(() => this.log.warn(TAGS, 'Network status is offline. Collecting activities in storage until network comes online again.'))
          )]
        )
      ),
      tap({
        error: err => this.log.error(TAGS, 'Error sending reporting activities:', err)
      })
    );
  }

  private async flushActivities() {
    const pendingActivities = await this.storage.get(PENDING_ACTIVITIES_KEY);
    if (!pendingActivities) {
      return;
    }
    const activities = JSON.parse(pendingActivities);
    this.log.debug(TAGS, 'Flushing all pending reporting activities...');
    await this.postActivities(activities).toPromise();
  }

  private postActivities(activities: UserActivity[]) {
    return this.http.post<any>(`${this.env.apiHost}/v1/reporting/activity`, [
      ...activities.map(activity =>
        (activity.activityInfo = activity.activityInfo != null ? JSON.stringify(activity.activityInfo) : null, activity)
      ),
    ]).pipe(
      tap(
        () => this.storage.remove(PENDING_ACTIVITIES_KEY),
        () => this.storage.set(JSON.stringify(PENDING_ACTIVITIES_KEY), activities)
      ),
      tap({
        error: err => this.log.error(TAGS, 'Error posting reporting activities:', err)
      }),
      catchError(() => EMPTY)
    );
  }
}
