import { Component, Input } from '@angular/core';
import { PendingLocalNotificationSchema } from '@capacitor/local-notifications';

@Component({
  selector: 'wic-notifications-detail',
  templateUrl: './notifications-detail.component.html',
  styleUrls: ['./notifications-detail.component.scss'],
})
export class NotificationsDetailComponent {
  @Input() title: string;
  @Input() notifications: PendingLocalNotificationSchema[];
}
