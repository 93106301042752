import { Pipe, PipeTransform } from '@angular/core';

export const GROUP_CODED_MESSAGE_MAP = {
  1: 'vendors.ewicDescription',
  2: 'vendors.nonEwicDescription',
  3: 'vendors.farmersDescription',
  4: 'vendors.pharmacyDescription',
  5: 'vendors.selfCheckoutDescription',
  6: 'vendors.mobileDescription',
  7: 'vendors.commissaryDescription',
  101: 'vendors.farmStandDescription',
  102: 'vendors.honeyDescription',
};

@Pipe({
  name: 'groupCodedMessage'
})
export class GroupCodedMessagePipe implements PipeTransform {
  transform(groupCode: number): any {
    return GROUP_CODED_MESSAGE_MAP[groupCode];
  }
}
