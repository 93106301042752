import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'MenuButton',
  uriName: 'menu'
})
export class MenuButton {
  @Key linkId: string;
  text: string;
  url: string;
  isActive: boolean;
  activateOn: string;
  deactivateOn: string;
  flags: string;
  backgroundColor: string;
  textColor: string;
  order: number;
  hasIcon: boolean;
}
