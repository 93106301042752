import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ThemeableBrowserService {
  constructor(private readonly transloco: TranslocoService) {
  }

  async openWebLink(link: string) {
    return /^http/.test(link)
      ? this.openBrowser(link)
      : this.useWindow(link)
  }

  useWindow(link: string) {
    window.open(link);
  }

  async openBrowser(link: string) {
    if (!link) {
      return;
    }

    const url = link.includes('$lang') ? link.replace('$lang', this.transloco.getActiveLang()) : link;

    return Browser.open({ url });
  }
}
