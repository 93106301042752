import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { add, format, isAfter, isBefore, isEqual, parse, set } from 'date-fns';
import { LocalNotificationsService } from '~core/services/local-notifications.service';
import { LogService } from '~core/services/log.service';
import { BENE_EXP_KIND } from '~features/notifications/notifications.state';
import { UserSettings } from '~features/settings/models';

const TAGS = ['Service', 'Notifications', 'Benefit'];

@Injectable()
export class BenefitNotificationsService {
  constructor(
    private localNotifications: LocalNotificationsService,
    private transloco: TranslocoService,
    private log: LogService,
    private platform: Platform) {
  }

  async getCurrentNotifications(cardNumber?: string) {
    try {
      const pendingNotifications = await this.localNotifications.getPending();
      this.log.trace(TAGS, `Current scheduled ids: ${JSON.stringify(pendingNotifications.map(n => n.id))}`);

      const notifications = pendingNotifications.filter(({ extra }) =>
        extra && extra.kind === BENE_EXP_KIND && (!cardNumber || extra.cardNumber === cardNumber)
      );
      return notifications;
    } catch (err) {
      this.log.error(TAGS, '! Error getting benefit notifications: ' +
        (err && err.message ? err.message : err ? err : ''));
      return [];
    }
  }

  async cancelNotifications(cardNumber?: string) {
    // Refactored: Cancel and clear only benefit expiration notifications!!
    // Do not cancelAll or clearAll, as there are other notifications scheduled by WICShopper!
    const notifications = await this.getCurrentNotifications(cardNumber);
    for (const notification of notifications) {
      this.log.trace(TAGS, `Clearing notification with id ${notification.id}...`);
      try {
        await this.localNotifications.cancel(notification.id);
        this.log.trace(TAGS, `Notification with id ${notification.id} cleared.`);
      } catch (err) {
        this.log.trace(TAGS, `! Notification with id ${notification.id} errored while clearing.`);
      }
    }
  }

  async registerNotifications(cardNumber: string, endDate: string, settings: UserSettings, force = false) {
    try {
      const now = Date.now();
      const expirationDate = parse(endDate, 'yyyyMMdd', now);
      this.log.debug(TAGS, `Benefits expire on: ${format(expirationDate, 'M-dd HH:mm')}`);

      if (!force) {
        this.log.trace(TAGS, `Checking currently scheduled notifications...`);
        const current = await this.getCurrentNotifications(cardNumber);
        if (current && current.length) {
          this.log.trace(TAGS, `Found ${current.length} current notifications, validating...`);
          const withDates = current.map(ln => {
            const date = new Date(ln.schedule.at);
            this.log.trace(TAGS, `Scheduled for: ${format(date, 'M-dd HH:mm')}`);
            return {
              id: ln.id,
              date
            };
          });

          const areScheduled = withDates.some(cur => {
            const isSame = expirationDate.getDate() === cur.date.getDate();
            this.log.trace(TAGS, `IsSame: ${isSame}`);
            return isSame;
          });
          if (areScheduled) {
            this.log.debug(TAGS, `Currently scheduled notifications match current benefits period.`);
            return; // Bail out here, as we do not want to re-schedule notifications if they are already scheduled properly
          }
        } else {
          this.log.trace(TAGS, `No currently scheduled notifications...`);
        }
      }

      this.log.trace(TAGS, `Clearing out old notifications...`);
      await this.cancelNotifications(cardNumber);
      this.log.trace(TAGS, `Old notifications cleared...`);

      if (!settings.benefits.allowAlerts) {
        this.log.info(TAGS, `Notifications have been disabled! Not scheduling new benefit notifications!`);
        return;
      }

      const startOfNotifications = set(
        add(expirationDate, { days: -settings.benefits.daysBefore || 0 }),
        { hours: 0, minutes: 0, seconds: 0 }
      );

      const notificationDate = set(
        startOfNotifications,
        { hours: settings.benefits.hour, minutes: settings.benefits.minute }
      );
      this.log.trace(TAGS, `Initial notification date is: ${format(notificationDate, 'yyyy-MM-dd HH:mm:ss')}`);

      // Check if current date is between the start date of notifications and the date of the end of benefits
      if (isEqual(now, expirationDate) || isBefore(now, expirationDate)) {
        await this.scheduleNotifications(cardNumber, endDate, settings, notificationDate, settings.benefits.daysBefore);
      } else {
        this.log.trace(TAGS, `Expiration date ${format(expirationDate, 'yyyy-MM-dd HH:mm:ss')} has passed! Skipping notifications!`);
      }
    } catch (err) {
      this.log.error(TAGS, `! Error configuring benefit notifications: ` +
        (err && err.message ? err.message : err ? err : ''));
    }
  }

  protected async scheduleNotifications(
    cardNumber: string, endDate: string, settings: UserSettings, notificationDate: Date, count: number) {
    const formattedEndDate = format(parse(endDate, 'yyyyMMdd', Date.now()), 'MM/dd/yyyy');
    const text = this.transloco.translate('benefit-notifications.expirationMessage', { endDate: formattedEndDate });
    const lastText = this.transloco.translate('benefit-notifications.lastDayMessage');
    const title = this.transloco.translate('benefit-notifications.expirationTitle');
    const subTitle = this.transloco.translate('benefit-notifications.expirationMessageInternal');

    const now = Date.now();
    this.log.trace(TAGS, `Now = ${format(now, 'yyyy-MM-dd HH:mm:ss')}`);
    for (let i = 0; i <= count; i++) {
      if (i === 0 || i === count) { // 2018-09-11: Only show the first and last (minor change to avoid regressions/bugs)
        this.log.trace(TAGS, `NotificationDate = ${format(notificationDate, 'yyyy-MM-dd HH:mm:ss')}`);

        if (isEqual(notificationDate, now) || isAfter(notificationDate, now)) {
          this.log.trace(
            TAGS, `Scheduling expiration notification for ${cardNumber} on: ${format(notificationDate, 'yyyy-MM-dd HH:mm:ss')}...`
          );
          await this.scheduleNotification(cardNumber, i + 1, notificationDate, title, subTitle, i === count ? lastText : text);

        }
      }
      notificationDate = set(add(notificationDate, { days: 1 }), {
        hours: settings.benefits.hour,
        minutes: settings.benefits.minute
      });
    }
  }

  protected async scheduleNotification(
    cardNumber: string, id: number, notificationDate: Date, title: string, subTitle: string, text: string) {
    try {
      await this.localNotifications.schedule({
        id,
        title,
        body: text,
        schedule: {
          at: notificationDate,
        },
        extra: { cardNumber, displayData: text, title, subTitle, kind: BENE_EXP_KIND }
      });
      this.log.trace(TAGS, `Scheduled expiration notification for ${cardNumber} on: ${format(notificationDate, 'yyyy-MM-dd HH:mm:ss')}`);
    } catch (err) {
      this.log.warn(
        TAGS, `! Error scheduling an expiration notification for ${cardNumber} on: ${format(notificationDate, 'yyyy-MM-dd HH:mm:ss')}`
      );
    }
  }
}
