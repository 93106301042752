import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, ModalController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { DeviceService } from '~core/services/device.service';
import { LogService } from '~core/services/log.service';
import { EnvironmentService } from '~features/environment.service';
import { BenefitErrorComponent } from '../../components/benefit-error/benefit-error.component';

import { ErrorModalComponent } from '../../components/error-modal/error-modal.component';
import { GetSupportModalComponent } from '../../components/get-support-modal/get-support-modal.component';
import { BenefitError } from '../benefits/models';

import { HardError, SoftError } from './error.state';
import { Registration } from '~features/registration/models';
import { cardNumberFound } from '../../../../../../../apps/document-upload/src/app/state/registration/registration.state';

const TAGS = ['Service', 'Errors'];

@Injectable()
export class ErrorsService {
  constructor(
    private device: DeviceService,
    private modals: ModalController,
    private toasts: ToastController,
    private popovers: PopoverController,
    private alerts: AlertController,
    private transloco: TranslocoService,
    private platform: Platform,
    private log: LogService,
    private env: EnvironmentService,
    private http: HttpClient,
  ) {
  }

  async showBenefitErrorModal(error: BenefitError): Promise<boolean> {
    try {
      const modal = await this.popovers.create({
        cssClass: ['popover-benefit-error', 'popover-centered'],
        component: BenefitErrorComponent,
        componentProps: {
          error
        },
        showBackdrop: true,
        alignment: 'center',
      });
      await modal.present();
      await modal.onDidDismiss();
      return true;
    } catch (err) {
      return false;
    }
  }

  async showErrorModal(error: SoftError | HardError, screenshot?: string): Promise<boolean> {
    try {
      const modal = await this.modals.create({
        cssClass: 'modal-error',
        component: ErrorModalComponent,
        componentProps: {
          error,
          screenshot
        }
      });
      await modal.present();
      return true;
    } catch (err) {
      return false;
    }
  }

  async showWarningToast(error: SoftError): Promise<boolean> {
    try {
      const toast = await this.toasts.create({
        header: error.title || this.transloco.translate('error.defaultTitle'),
        message: error.message,
        position: 'bottom',
        cssClass: 'toast-warning',
        buttons: [{
          side: 'end',
          icon: 'close',
          role: 'cancel'
        }]
      });
      await toast.present();
      return true;
    } catch (err) {
      return false;
    }
  }

  async showErrorToast(error: SoftError, screenshot?: string): Promise<boolean> {
    try {
      const toast = await this.toasts.create({
        header: error.title || this.transloco.translate('error.defaultTitle'),
        message: error.message,
        position: 'bottom',
        cssClass: 'toast-error',
        buttons: [{
          side: 'end',
          icon: 'eye',
          handler: () => this.showErrorModal(error, screenshot)
        }, {
          side: 'end',
          icon: 'close',
          role: 'cancel'
        }]
      });
      await toast.present();
      return true;
    } catch (err) {
      return false;
    }
  }

  async captureErrorScreenshot(error: SoftError | HardError): Promise<[SoftError | HardError, string]> {
    if (!this.platform.is('cordova')) {
      this.log.trace(TAGS, 'Platform is not cordova. Skipping screenshot.');
      return [error, undefined];
    }

    try {
      this.log.trace(TAGS, 'Capturing screen shot...');
      return [error, ''];
    } catch (err) {
      this.log.trace(TAGS, 'Error capturing screen shot: ', err);
      return [error, undefined];
    }
  }

  sendErrorReport(error: SoftError | HardError, screenshot?: string, registration?: Registration): Observable<{ ticketNo: string }> {
    return this.http.post<{ ticketNo: string }>(`${this.env.apiHost}/v1/support/errors/reports`, {
      timestamp: new Date().toISOString(),
      title: error.title || (error instanceof SoftError ? 'Soft Error' : 'Hard Error'),
      message: error.message,
      detailedMessage: error.detailedMessage,
      originalError: error.originalError && {
        message: error.originalError.message,
        stackTrace: error.originalError.stack
      },
      uniqueDeviceId: this.device.uniqueId,
      deviceInfo: this.device.deviceInfo,
      sessionId: this.device.sessionId,
      screenshot: '',
      authorityId: registration.authority.id,
      cardNumber: registration.currentCard?.cardNumber
    });
  }


  async confirmErrorReported(): Promise<boolean> {
    try {
      const alert = await this.alerts.create({
        header: this.transloco.translate('error.confirmReportedTitle'),
        message: this.transloco.translate('error.confirmReportedMessage'),
        buttons: [{
          text: this.transloco.translate('Ok')
        }]
      });
      await alert.present();
      return true;
    } catch (err) {
      console.warn("ERROR IN CONFIRM")
      return false;
    }
  }

  async warnErrorReportingFailed(): Promise<boolean> {
    try {
      const alert = await this.alerts.create({
        cssClass: 'alert-error',
        header: this.transloco.translate('error.warnReportFailedTitle'),
        message: this.transloco.translate('error.warnReportFailedMessage'),
        buttons: [{
          text: this.transloco.translate('Ok')
        }]
      });
      await alert.present();
      return true;
    } catch (err) {
      return false;
    }
  }

  async requestSupport(): Promise< OverlayEventDetail<{ name: string; email: string; message: string }>> {
    const modal = await this.modals.create({
      component: GetSupportModalComponent,

    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async warnEmailSharingNotAvailable(): Promise<boolean> {
    try {
      const alert = await this.alerts.create({
        cssClass: 'alert-error',
        header: this.transloco.translate('error.warnNoEmailTitle'),
        message: this.transloco.translate('error.warnNoEmailMessage'),
        buttons: [{
          text: this.transloco.translate('Ok')
        }]
      });
      await alert.present();
      return true;
    } catch (err) {
      return false;
    }
  }
}
