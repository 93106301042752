import { Component,OnInit } from '@angular/core';
import { combineLatest,defer,iif,Observable,of } from 'rxjs';
import { catchError,map,switchMap,tap,timeout,withLatestFrom } from 'rxjs/operators';
import { APLMessagesFacade } from '~features/aplmessages/aplmessages.facade';
import { CalculatorsFacade } from '~features/calculators/calculators.facade';

import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { EnrichedBenefit } from '~features/benefits/models';
import { HomeFacade } from '~features/home/home.facade';
import { MissingProductFacade } from '~features/missing-product/missing-product.facade';
import { matchBenefitByProduct } from '~features/products/product-selection.effects';
import { ProductFacade } from '~features/products/product.facade';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { SettingsFacade } from '~features/settings/settings.facade';
import { SubCatConfigFacade } from '~features/subcat-configs/subcat-config.facade';
import { UpcFacade } from '~features/upc/upc.facade';

@Component({
  selector: 'wic-item-detail',
  templateUrl: './item-detail.page.html',
  styleUrls: ['./item-detail.page.scss'],
})
export class ItemDetailPage implements OnInit {
  benefit$: Observable<EnrichedBenefit>;

  constructor(
    public home: HomeFacade,
    public aplMessages: APLMessagesFacade,
    public benefits: BenefitsFacade,
    public product: ProductFacade,
    public subCategory: SubCatConfigFacade,
    public upc: UpcFacade,
    public calculators: CalculatorsFacade,
    public missingProduct: MissingProductFacade,
    public registration: RegistrationFacade,
    public settings: SettingsFacade,
    private analytics: AnalyticsFacade,
  ) {}

  ngOnInit() {
    this.analytics.pageView('item-detail');

    this.benefit$ = combineLatest([
      this.benefits.currentBenefits$,
      this.product.statusProduct$,
    ]).pipe(
      switchMap(([info, product]) => of(product).pipe(
         withLatestFrom(
            this.subCategory.all$.pipe(timeout(3000), catchError(() => of([])))
          ),
          map(([prod, configs]) => prod.status !== 'chk' && configs.find(config =>
             // eslint-disable-next-line eqeqeq
             config.categoryId == prod.categoryId &&
             // eslint-disable-next-line eqeqeq
             config.subCategoryId == prod.subCategoryId
          ) || undefined ),
          tap(config => config ?  this.subCategory.select(config) : false),
          map(() => ({info, product}))
      )),
      withLatestFrom(this.registration.currentProvider$),
      switchMap(([{info, product}, authority ]) =>
        iif(
          () =>
            !!info && !!info.benefits && !!info.benefits.length &&
            !!product && product.categoryId != null && product.subCategoryId != null,
          defer(() =>
            of(info.benefits.find(matchBenefitByProduct(product)))
          ),
          of(null)
        ).pipe(
          tap(benefit => 
            !!benefit
              ? this.product.selectProducts(benefit.categoryId, benefit.subCategoryId)
              : authority.enableAplSearchNoBenefits 
                ? this.product.selectProducts(product.categoryId, product.subCategoryId) 
                : this.product.deselectProducts()
          ),
          tap(benefit => this.product.viewItemDetail(benefit)),
        )
      ),
    );
  }
}
