import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { from, race, Subscription, timer } from 'rxjs';
import { delay, first, map, switchMap } from 'rxjs/operators';
import { KeyboardService } from '~core/services/keyboard.service';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { EnvironmentService } from '~features/environment.service';
import { RegistrationFacade } from '~features/registration/registration.facade';

@Component({
  selector: 'wic-registration',
  templateUrl: './registration.page.html',
  styleUrls: ['./registration.page.scss'],
})
export class RegistrationPage implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  sub: Subscription;

  constructor(
    public registration: RegistrationFacade,
    public enhancedMode: EnhancedModeFacade,
    public env: EnvironmentService,
    private analytics: AnalyticsFacade,
    private keyboard: KeyboardService
  ) {}

  ngOnInit() {
    this.analytics.pageView('registration');
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  scrollIntoView(event: Event) {
    const el = event.target as HTMLElement;

    this.sub?.unsubscribe();

    // Could also use an IntersectionObserver to detect when input is not visible and then scroll to.
    this.sub = race(
      this.keyboard.keyboardDidShow$.pipe(
        first(),
        map(() => 'keyboard')
      ),
      timer(800).pipe(map(() => 'failsafe'))
    )
      .pipe(
        first(),
        switchMap(type => from(this.content.scrollToBottom(500)).pipe(map(() => type))),
        delay(200),
        switchMap(type =>
          type === 'keyboard'
            ? this.content.scrollToPoint(0, el.getBoundingClientRect().bottom + 150, 500) 
            : this.content.scrollToBottom(300)
        )
      )
      .subscribe();
  }
}
