import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '~features/environment.service';
import { NotificationDetails, NotificationDetailsQuery } from './models/notificationDetails';

@Injectable()
export class NotificationDetailsService {
  constructor(private http: HttpClient, private env: EnvironmentService) {
  }

  load(query: NotificationDetailsQuery): Observable<NotificationDetails[]> {
    return this.http.post<NotificationDetails[]>(`${this.env.apiHost}/v3/cards/benefit-headers`, query);
  }
}
