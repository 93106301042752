import { Component, OnInit } from "@angular/core";
import { AnalyticsFacade } from "~features/analytics/analytics.facade";
import { EnhancedIssuesFacade } from "~features/enhanced-issues/enhanced-issues.facade";
import { EnhancedIssue } from "~features/enhanced-issues/models";
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'wic-enhanced-issues',
  templateUrl: './enhanced-issues.page.html',
  styleUrls: ['./enhanced-issues.page.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'enhanced-issues' }],
})
export class EnhancedIssuesPage implements OnInit {

  constructor(
    private analytics: AnalyticsFacade,
    public enhancedIssues: EnhancedIssuesFacade,
    public transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.analytics.pageView('enhanced-issues');
  }

  navigateToPage(enhancedIssue: EnhancedIssue) {
    this.enhancedIssues.openLink(enhancedIssue);
  }
}