import { NgModule } from '@angular/core';
import { CloudSettingsService } from '~features/settings/cloud-settings-storage.service';
import { AdminSettingsFacade } from './admin-settings.facade';
import { SettingsFacade } from './settings.facade';
import { SettingsUIService } from '~features/settings/settings-ui.service';

// import { CloudSettings } from '@ionic-native/cloud-settings/ngx';


@NgModule({
  providers: [
    CloudSettingsService,
    // {provide: CloudSettingsService, useClass: CloudSettingsStorageService},

    AdminSettingsFacade,
    SettingsFacade,
    SettingsUIService,

    // CloudSettings
  ]
})
export class SettingsModule {
}
