import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { pageView } from './analytics.actions';

const TAGS = ['Facade', 'Analytics'];

export enum WICShopperEvents {
  AppUsed = 'app_used',
  Register = 'register',
  SkipRegistration = 'skip_registration',
  RemoveCard = 'remove_card',
  AddCard = 'add_card',
  EditCard = 'edit_card',
  ViewPage = 'view_external_page',
  ViewBenefits = 'view_benefits',
  ViewBenefit = 'view_benefit',
  ViewFutureBenefits = 'view_future_benefits',
  ViewItem = 'view_item',
  ScanProduct = 'scan_product',
  SearchUPC = 'search_upc',
  SearchItems = 'search_items',
  ViewAppointment = 'view_appointment',
  GetAppointmentDirections = 'get_appointment_directions',
  ViewOffices = 'view_offices',
  ViewOffice = 'view_office',
  GetOfficeDirections = 'get_office_directions',
  LanguageChanged = 'language_changed',
  ViewVendors = 'view_vendors',
  ViewVendor = 'view_vendor',
  GetVendorDirections = 'get_vendor_directions',
  ViewSettings = 'view_settings',
  SoftReset = 'reset_soft',
  HardReset = 'reset_hard',
  OpenBanner = 'open_banner',
  ViewMicroBlog = 'view_microblog',
  OpenCustomBenefitLink = 'open_benefit_custom_link',
  ICBTSubmit = 'icbt_submit',
  HomeButtonClick = 'home_button_click',
  OpenExternalBrowser = 'open_external_browser',
}

@Injectable()
export class AnalyticsFacade {
  constructor(private store: Store) {
  }

  pageView(pageName: string, params?: string[]): void {
    this.store.dispatch(pageView({ pageName, params }));
  }
}
