import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { Badge } from '@capawesome/capacitor-badge';
import { combineLatest, EMPTY, from, merge, of, OperatorFunction, pipe } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  scan,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { DialogsService } from '~core/services/dialogs.service';
import { LogService } from '~core/services/log.service';
import { PushNotificationsService } from '~core/services/push-notifications.service';
import { dynamicButtonActivated } from '~features/home/home.state';
import { HomeButton } from '~features/home/models';
import {
  cardRegistrationCompleted,
  deleteCardConfirmed,
  nonProviderRegistrationCompleted,
  registrationChanged,
  registrationResetComplete,
  skipCardRegistration
} from '~features/registration/registration.actions';
import { currentRegistration } from '~features/registration/registration.selectors';
import { onNativePlatformOnly } from '../../util/rxjs-util';
import { appInitialized, hardReset, initializationCompleted, softReset } from '../app/app.actions';
import { Registration } from '../registration/models';

import { userSettings, userSettingsChanged } from '../settings/settings.state';

import { State } from '../state';

import { NotificationRegistration } from './models';
import { NotificationRegistrationService } from './notification-registration.service';
import { resetPushNotifications } from './notifications.actions';
import {
  firebaseToken,
  firebaseTokenAcquired,
  firebaseTokenRefreshed,
  notificationBasisChanged,
  registerNotificationsForCard
} from './notifications.state';

const TAGS = ['Effects', 'Notifications', 'Push'];

const ALL_TOPIC = 'ALL';
const AUTHORITY_TOPIC = 'AUTHORITY-';

const IGNORE = () => {
};

export const mapNotificationToDynamicButton = (
  log: LogService,
  badgeClear: () => any
): OperatorFunction<PushNotificationSchema, {button: HomeButton} & TypedAction<'[Home] Dynamic Button Activated'>> => pipe(
  tap(
    () => badgeClear(),
    () => badgeClear(),
    () => badgeClear()
  ),
  filter(message => !!message.link || !!message.data?.Link || !!message.data?.link),
  map(message => dynamicButtonActivated({
    button: {
      linkUrl: message.link || message.data?.Link || message.data?.link,
      label: 'Notification Link',
      name: 'dynamic'
    } as HomeButton
  })),
  tap(
    button => log.debug(TAGS, `Push notification link activated:`, button),
    err => log.error(TAGS, 'Error handling push notification message received:', err)
  ),
);

@Injectable()
export class PushNotificationsEffects {
  constructor(
    private actions$: Actions,
    private platform: Platform,
    private store: Store<State>,
    private log: LogService,
    private dialogs: DialogsService,
    private pushNotifications: PushNotificationsService,
    private notifications: NotificationRegistrationService
  ) {
  }

  acquireFirebaseToken$ = createEffect(
    () => this.actions$.pipe(
      ofType(appInitialized),
      onNativePlatformOnly(),
      tap(() => this.log.info(TAGS, `Acquiring firebase messaging token...`)),
      concatMap(() => from(this.pushNotifications.getToken())),
      tap({
        error: err => this.log.error(TAGS, 'Error acquiring firebase messaging token:', err)
      }),
      catchError(() => of(null)), // Convert any errors to null, an effectively ignore them
      filter(token => !!token), // Token will be null if Firebase has not yet established it
      tap(token => this.log.info(TAGS, `Firebase token acquired: ${token}`)),
      map(token => firebaseTokenAcquired({ token }))
    )
  );

  // TODO: Determine if we need to track prior token, and update registrations to switch to new token?
  firebaseTokenRefreshed$ = createEffect(
    () => this.actions$.pipe(
      ofType(appInitialized),
      onNativePlatformOnly(),
      tap(
        () => this.log.info(TAGS, `Observing firebase token refresh notifications...`)
      ),
      concatMap(() => this.pushNotifications.registrationToken$$),
      tap(
        () => this.log.warn(TAGS, 'Firebase token for device has been refreshed!'),
        err => this.log.error(TAGS, 'Error during firebase token refresh:', err)
      ),
      catchError(() => of(null)),
      filter(token => !!token),
      tap(token => this.log.info(TAGS, `Refreshed firebase token: ${token}`)),
      map(token => firebaseTokenRefreshed({ token }))
    )
  );

  checkNotificationBasis$ = createEffect(
    () => this.actions$.pipe(
      onNativePlatformOnly(),
      ofType(
        userSettingsChanged,
        cardRegistrationCompleted,
        skipCardRegistration,
        nonProviderRegistrationCompleted,
        firebaseTokenAcquired,
        firebaseTokenRefreshed
      ),
      tap(action => this.log.trace(TAGS, `[For Action: ${action.type}] Checking whether to update notification registration...`)),
      withLatestFrom(
        this.store.select(userSettings).pipe(
          tap(settings => this.log.trace(TAGS, `Got settings: ${JSON.stringify(settings)}`))
        ),
        this.store.select(currentRegistration).pipe(
          tap(reg => this.log.trace(TAGS, `Got registration: ${JSON.stringify(reg)}`))
        ),
        this.store.select(firebaseToken).pipe(
          tap(token => this.log.trace(TAGS, `Got token: ${JSON.stringify(token)}`))
        )
      ),
      switchMap(
        regStuff => of(regStuff).pipe(
          tap(([, , registration, token]) => this.log.trace(TAGS,
            `Has Registration: ${!!registration}; Has authority: ${!!registration.authority};
            Has cards: ${!!registration.cards && !!registration.cards.length};
            Has token: ${!!token}`)
          ),
          filter(([, , registration, token]) =>
            !!registration && !!registration.authority && !!token
          )
        )
      ),
      map(([, settings, registration, token]) => ({ settings, registration, token })),
      distinctUntilChanged((a, b) => a !== b, v => JSON.stringify(v)),
      tap({
        next: () => this.log.debug(TAGS, 'Basis for push notifications has changed!'),
        error: err => this.log.error(TAGS, 'Error handling notification registration changes: ' + err.message, err)
      }),
      map(basis => notificationBasisChanged(basis))
    )
  );

  subscribeToTopics$ = createEffect(
    () => this.actions$.pipe(
      ofType(notificationBasisChanged),
      onNativePlatformOnly(),
      tap(({ registration: basis }) =>
        this.log.info(TAGS, `Subscribing to push notification topics for authority ${basis.authority.id}`
        )
      ),
      tap(() => this.pushNotifications.subscribe(ALL_TOPIC).then(
        result => this.log.info(TAGS, 'Subscribed to ALL topic', result),
        err => this.log.error(TAGS, 'Error subscribing to ALL topic:', err)
      )),
      scan((acc, { registration }): Registration[] => [acc[1], registration], []),
      tap(([prevReg]) => prevReg?.authority
        ? this.pushNotifications.unsubscribe(AUTHORITY_TOPIC + prevReg.authority.id).then(
          result => this.log.info(TAGS, `Unsubscribed to authority specific topic AUTHORITY-${prevReg.authority.id}`, result),
          err => this.log.error(TAGS, `Error unsubscribing to AUTHORITY-${prevReg.authority.id} topic:`, err)
        )
        : false
      ),
      tap(([prevReg, registration]) => registration?.authority
        ? this.pushNotifications.subscribe(AUTHORITY_TOPIC + registration.authority.id).then(
          result => this.log.info(TAGS, `Subscribed to authority specific topic AUTHORITY-${registration.authority.id}`, result),
          err => this.log.error(TAGS, `Error subscribing to AUTHORITY-${registration.authority.id} topic:`, err)
        )
        : false
      )
    ),
    { dispatch: false, resubscribeOnErrors: true }
  );

  resetPushNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationBasisChanged),
      switchMap(({ registration }) => this.actions$.pipe(
        ofType(resetPushNotifications),
        tap(() => registration?.authority
          ? this.pushNotifications.unsubscribe(AUTHORITY_TOPIC + registration.authority.id).then(
            result => this.log.info(TAGS, `Resetting Notifications for topic AUTHORITY-${registration.authority.id}`, result),
            err => this.log.error(TAGS, `Error Resetting Notifications for AUTHORITY-${registration.authority.id} topic:`, err)
          )
          : false
        )
      )),
    ), { dispatch: false }
  );

  handleInAppNotifications$ = createEffect(
    () => this.actions$.pipe(
      ofType(initializationCompleted),
      switchMap(() => this.pushNotifications.pushNotificationReceived$$),
      tap(message => this.log.info(TAGS, `Push notification message:`, message)),
      switchMap(message =>
        from(this.dialogs.alert({
          message: message.body,
          title: message.title
        })).pipe(map(() => message))
      ),
      mapNotificationToDynamicButton(this.log, () => Badge.clear())
    )
  );

  handleBackgroundNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializationCompleted),
      switchMap(() => this.pushNotifications.pushNotificationActionPerformed$$.pipe(
        tap(action => this.log.info(TAGS, `Push notification action:`, action)),
        map(action => action.notification)
      )),
      mapNotificationToDynamicButton(this.log, () => Badge.clear())
    )
  );

  aggregateNotificationOptions$ = createEffect(
    () => this.actions$.pipe(
      ofType(notificationBasisChanged),
      filter(({ registration: basis }) => this.platform.is('capacitor') && !!basis.cards),
      tap(({ registration: basis }) =>
        this.log.info(TAGS, `Generating push registration options for ${basis.authority.id}/${
          basis.cards.reduce((formatted, card) => `${formatted}${card.cardNumber} `, '')}`
        )
      ),
      map(({ settings, registration, token }) =>
        registration.cards.map(card => ({
          lang: settings.lang,
          timezoneOffset: new Date().getTimezoneOffset(),
          token,
          authorityId: registration.authority.id,
          cardNumber: card.cardNumber,
          householdId: card.householdId
        }))
      ),
      filter(registrations => !!registrations.length),
      tap(registrations => this.log.trace(TAGS, `Registrations are:`, registrations)),
      concatMap((registrations: NotificationRegistration[]) =>
        from(registrations).pipe(
          map(registration => registerNotificationsForCard({ registration }))
        )
      )
    )
  );

  registerForDirectNotifications$ = createEffect(
    () => this.actions$.pipe(
      ofType(registerNotificationsForCard),
      concatMap(({ registration }) =>
        this.notifications.register(registration).pipe(
          tap(
            result =>
              this.log.info(TAGS, `Registered for direct notifications with CardNumber: ${registration.cardNumber}`),
            err =>
              this.log.error(
                TAGS,
                `Error registering for direct notifications with CardNumber: ${registration.cardNumber}`,
                err
              ),
          ),
          catchError(err => of(null)),
        )
      )
    ),
    { dispatch: false }
  );

  unregisterForAllNotifications$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset, registrationResetComplete, registrationChanged),
      filter(() => this.platform.is('capacitor')),
      withLatestFrom(
        this.store.select(firebaseToken),
        this.store.select(currentRegistration)
      ),
      filter(([, token]) => !!token),
      tap(([, token]) => this.log.debug(TAGS, `Unsubscribing from all notifications for this device ${token}...`)),
      tap(() => this.pushNotifications.unsubscribe(ALL_TOPIC).then(
        result => this.log.debug(TAGS, `Unsubscribed from ALL topic...`, result),
        err => this.log.error(TAGS, 'Error unsubscribing from ALL topic:', err)
      )),
      tap(([, , registration]) => registration && registration.authority
        ? this.pushNotifications.unsubscribe(AUTHORITY_TOPIC + registration.authority.id).then(
          result => this.log.info(TAGS, `Unsubscribed from authority specific topic AUTHORITY-${registration.authority.id}`, result),
          err => this.log.error(TAGS, `Error unsubscribing from AUTHORITY-${registration.authority.id} topic:`, err)
        )
        : false
      ),
      mergeMap(([, token]) =>
        this.notifications.unregisterToken(token).pipe(
          tap(
            () => this.log.trace(TAGS, `Removed all notifications registration for ${token}`),
            err => this.log.error(TAGS, 'Error encountered while removing notification registrations: ' + err.message, err)
          ),
          catchError(() => EMPTY)
        )
      )
    ),
    { dispatch: false, resubscribeOnError: true }
  );
}
