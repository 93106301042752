import { Pipe, PipeTransform } from '@angular/core';
import { APIHostOption, HostsSettingsMode } from '../models/api-host-option';

@Pipe({name: 'hostSettingsModeFilter'})
export class HostSettingsModeFilterPipe implements PipeTransform {
  transform(options: APIHostOption[], currentMode: HostsSettingsMode): any {
    const filtered = options.filter(option => option.modes.includes(currentMode));
    return filtered;
  }
}
