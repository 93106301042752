import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../state';
import { LanguagesFacadeBase, selectedLanguageEntity } from './languages.state';
import { Language } from './models/language';

@Injectable()
export class LanguagesFacade extends LanguagesFacadeBase {
  selectedLanguage$ = this.store.select(selectedLanguageEntity);

  constructor(private store: Store<State>) {
    super(Language, store);
  }
}
