import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'statusToColor'})
export class StatusToColorPipe implements PipeTransform {
  transform(status: string): any {
    return status === 'yes' || status === 'wic'
      ? 'green'
      : status === 'cvv'
        ? 'green'
        : status === 'no'
          ? 'orange'
          : status === 'norx'
            ? 'lava'
            : status === 'nowic'
              ? 'dark-red'
              : 'dark-grey';
  }
}
