import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { defer, EMPTY, from, iif, of } from 'rxjs';
import { catchError, delay, exhaustMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BarcodeScannerService } from '~core/services/barcode-scanner.service';
import { DialogsService } from '~core/services/dialogs.service';

import { LogService } from '~core/services/log.service';
import { NativePermissionsService } from '~core/services/native-permissions.service';
import { UPCInfo } from '~features/upc/models';
import { UPCService } from '~features/upc/upc.service';
import {
  barcodeQRScanned,
  barcodeScanReceived, initiateBarcodeScan,
  navToKeyEnterUPC,
  resetRecentUPCsComplete, stopBarcodeScan,
  warnNoWicProduce
} from '~features/upc/upc.state';
import { SoftError } from '../error/error.state';
import { lookupProduct, productFound } from '../products/product.state';


import { State } from '../state';
import {
  barcodeScanError,
  keyEnterUPC,
  parsedUPC,
  RECENT_UPCS_KEY,
  recentUPCs,
  resetRecentUPCs,
  restoreRecentUPCs,
  scanBarcode
} from './upc.state';
import { protocolButtonActivated } from '~features/home/home.state';
import { HomeButton } from '~features/home/models';

const TAGS = ['Effects', 'UPC'];

@Injectable()
export class UpcEffects {
  constructor(
    private actions$: Actions,
    private dialogs: DialogsService,
    private transloco: TranslocoService,
    private store: Store<State>,
    private log: LogService,
    private nav: NavController,
    private upcService: UPCService,
    private scanner: BarcodeScannerService,
    private nativePermissions: NativePermissionsService
  ) {}

  startScanBarcode$ = createEffect(
    () => this.actions$.pipe(
      ofType(scanBarcode),
      switchMap(() => this.scanner.hasPermission()),
      switchMap(granted =>
        // Check permission, if granted continue, else friendly permission
        iif(() => granted,
          defer(() => this.scanner.prepare()).pipe(
            switchMap(() => this.nav.navigateForward('/barcode-scanner')),
          ),
          defer(() =>
            this.nativePermissions.explainCameraPermission('gold', 'orange')
          ).pipe(
            switchMap(() => this.scanner.hasPermission()),
            switchMap(hasPermission =>
              // If permission denied, show settings, if not denied or asked, prompt user
              hasPermission === false ? this.scanner.openSettings() : this.scanner.askPermission()
            ),
            filter(v => !!v),
            switchMap(() => this.nav.navigateForward('/barcode-scanner')),
          )
        )
      ),
    ),
    { dispatch: false }
  );

  startScanBarcodeOnNavigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => payload.routerState.url === '/barcode-scanner'),
      switchMap(() => this.scanner.prepare()),
      map(() => initiateBarcodeScan()),
    )
  );

  handleScanBarcode$ = createEffect(
    () => this.actions$.pipe(
      ofType(initiateBarcodeScan),
      delay(500),
      switchMap(() => this.scanner.startScan()),
      map( content => barcodeScanReceived({ content })),
      catchError(error =>
        of(barcodeScanError({
          error: new SoftError(
            error && (error.message || error),
            'Error scanning barcode',
            `There was an error scanning a barcode. The scanner returned: ${error && (error.message || error)}`,
            error)
        }))
      )
    ),
  );

  lookupScannedBarcode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(barcodeScanReceived),
      map(({content}) => keyEnterUPC({ upc: content }))
    )
  );

  handleStopScanBarcode$ = createEffect(
    () => this.actions$.pipe(
      ofType(stopBarcodeScan, keyEnterUPC),
      switchMap(() => this.scanner.stopScan()),
    ),
    {dispatch: false}
  );

  barcodeQRScanned$ = createEffect(
    () => this.actions$.pipe(
      ofType(barcodeQRScanned),
      exhaustMap(() =>
        this.upcService.warnQR()
      ),
      filter(data => data),
      map(data => scanBarcode({}))
    ),
  );

  persistRecentUPCs$ = createEffect(
    () => this.actions$.pipe(
      ofType(keyEnterUPC),
      withLatestFrom(this.store.select(recentUPCs)),
      map(([, upcs]) => JSON.stringify(upcs)),
      tap(upcs => localStorage.setItem(RECENT_UPCS_KEY, upcs))
    ),
    { dispatch: false }
  );

  upcLookedUp$ = createEffect(
    () => this.actions$.pipe(
      ofType(keyEnterUPC),
      tap(({ upc }) => this.log.info(TAGS, `User has looked up UPC: ${upc}`)),
      tap(({ upc }) => this.nav.navigateForward('/item-detail', {
        queryParams: { upc }
      })),
      withLatestFrom(this.store.select(parsedUPC)),
      tap(([, upc]) => this.log.info(TAGS, `Looking up parsed UPC: ${upc}`)),
      map(([, upc]) => lookupProduct({ upc }))
    )
  );

  updateRecentUPCProductName$ = createEffect(
    () => this.actions$.pipe(
      ofType(productFound),
      filter(({ products }) => !!products && !!products.length),
      map(({ products }) => products[0]),
      filter(product => !!product && !!product.itemNumber),
      withLatestFrom(this.store.select(recentUPCs)),
      map(([product, upcs]) =>
        !upcs || !upcs.length || !upcs.find(info => (product.itemNumber.includes(info.upc) || info.upc.includes(product.itemNumber)))
          ? [{
            upc: product.itemNumber,
            itemNumber: product.itemNumber,
            description: product.description,
            dateChecked: Date.now()
          } as UPCInfo].concat(upcs || [])
          : upcs
            .filter(info => typeof info !== 'string' && !!info.upc)
            .map(info => (product.itemNumber.includes(info.upc) || info.upc.includes(product.itemNumber)) ? {
              ...info,
              itemNumber: product.itemNumber,
              description: product.description,
              dateChecked: Date.now()
            } : info)
      ),
      tap(upcs => localStorage.setItem(RECENT_UPCS_KEY, JSON.stringify(upcs))),
      map(upcs => restoreRecentUPCs({ recentUPCs: upcs }))
    )
  );

  resetRecentUPCs$ = createEffect(
    () => this.actions$.pipe(
      ofType(resetRecentUPCs),
      tap(() => localStorage.removeItem(RECENT_UPCS_KEY)),
      map(() => resetRecentUPCsComplete())
    )
  );

  warnNoWicProduce$ = createEffect(
    () => this.actions$.pipe(
      ofType(warnNoWicProduce),
      switchMap(() => from(this.upcService.showNoWicWarning()).pipe(
        filter(result => result === 'lookup'),
        map(() => navToKeyEnterUPC())
      ))
    )
  );

  navToKeyEnterUPC$ = createEffect(
    () => this.actions$.pipe(
      ofType(navToKeyEnterUPC),
      tap(() => console.warn("in navToKeyEnter")),
      map(() => protocolButtonActivated({ button: { linkUrl: 'wic://search-upc' } as HomeButton }))
    )
  );
}