<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <p class="ion-text-center">
          <ion-text color="medium">
            {{ 'item-detail.warn-produce-title' | transloco }}
          </ion-text>
        </p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <p class="ion-text-center">
          <ion-text>
            {{ 'item-detail.warn-produce-message' | transloco }}
          </ion-text>
        </p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button expand="block" color="gold" (click)="popover.dismiss('lookup')">
          <ion-icon slot="start" src="assets/images/product/pin.svg"></ion-icon>
          {{ 'buttons.searchUPC' | transloco }}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button expand="block" fill="clear" (click)="popover.dismiss()">
          {{ 'ok' | transloco }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>