import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { MobileOrder } from './mobile-order.entity';

export interface MobileOrdersState extends IEntityState<MobileOrder> {
}

export const {
  actions: {
    create: createMobileOrder,
    createSuccess: createMobileOrderSuccess,
  },
  initialState: initialMobileOrdersState,
  facade: MobileOrdersFacadeBase
} = buildState(MobileOrder, {  } as MobileOrdersState);

export const mobileOrdersReducer = createReducer(
  initialMobileOrdersState,
);

export const MOBILE_ORDERS_FEATURE_KEY = 'mobileOrder';

export const mobileOrdersState = createFeatureSelector<MobileOrdersState>(MOBILE_ORDERS_FEATURE_KEY);
