<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false)">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'enhanced-mode-registration-modal.title' | transloco}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="enhanced-bg">
    <img src="/assets/enhanced.svg" [alt]="'enhanced-mode-registration-modal.watermarkAlt' | transloco">
  </div>
  <div class="enhanced-header">
    <h2 class="ion-text-center">{{(registration?.allowed
      ? 'enhanced-mode-registration-modal.enabledHeader'
      : 'enhanced-mode-registration-modal.pendingHeader') | transloco}}</h2>
  </div>
  <div class="enhanced-detail">
    <div class="enhanced-content token">
      <h3>{{ registration?.udid }}</h3>
    </div>
  </div>
  <div class="enhanced-detail">
    <div class="enhanced-content">
      <h4 *ngIf="registration?.allowed">
        <p><strong>{{'enhanced-mode-registration-modal.allowedInfo1' | transloco}}</strong></p>
        <p>{{'enhanced-mode-registration-modal.allowedInfo2' | transloco}}</p>
      </h4>
      <h4 *ngIf="!registration?.allowed">{{'enhanced-mode-registration-modal.pendingInfo1' | transloco}}</h4>
    </div>
  </div>
</ion-content>
