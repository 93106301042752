<ion-header mode="ios">
  <ion-toolbar color="vendors" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{ 'vendors.contentTitle' | transloco }}</h3>
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon icon="fingerprint" size="2x" [styles]="{opacity: '0.03', color: 'var(--white)'}"></fa-icon>
      </ion-button>
      <ion-button fill="clear" (click)="geolocation.getLocation()">
        <fa-icon icon="location-arrow"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-tabs #tabs>
  <ion-tab-bar slot="bottom" color="vendors">
    <ion-tab-button tab="map" data-cy="map-button">
      <fa-icon icon="map" size="2x"></fa-icon>
      <ion-label>{{'vendors.mapTabLabel' | transloco}}</ion-label>
    </ion-tab-button>
    <ion-tab-button tab="list" data-cy="list-button">
      <fa-icon icon="list" size="2x"></fa-icon>
      <ion-label>{{'vendors.listTabLabel' | transloco}}</ion-label>
    </ion-tab-button>
    <ion-tab-button (click)="vendors.configureSearch()" data-cy="settings-button">
      <fa-icon icon="cog" size="2x"></fa-icon>
      <ion-label>{{'vendors.settingsTabLabel' | transloco}}</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
