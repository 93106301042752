<ion-header>
  <ion-toolbar color="light-spring-bud">
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'demand-appt-identity-modal.title' | transloco}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="submit()">
        <ion-icon name="card"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <form [formGroup]="form" novalidate *ngIf="!!authority">
    <ion-list>
      <ion-item lines="none" *ngIf="authority.useHHIDForAppts">
        <ion-label position="stacked">{{'demand-appt-identity-modal.householdSectionLabel' | transloco}}</ion-label>
        <ion-input
            formControlName="householdId"
            [minlength]="MIN_HHID_DIGITS" [maxlength]="MAX_HHID_DIGITS"
            type="tel" placeholder="{{'demand-appt-identity-modal.householdPlaceholder' | transloco}}">
        </ion-input>
        <ion-input
            formControlName="householdIdConfirm"
            type="tel" placeholder="{{'demand-appt-identity-modal.householdConfirmPlaceholder' | transloco}}">
        </ion-input>

        <ion-label
            color="danger"
            position="stacked"
            *ngIf="
            isFieldValid(form, 'householdId', 'required') ||
            isFieldValid(form, 'householdIdConfirm', 'required')
          ">
          {{'demand-appt-identity-modal.householdRequiredError' | transloco}}
        </ion-label>
        <ion-label
            color="danger"
            position="stacked"
            *ngIf="
            isFieldValid(form, 'householdId', 'minlength') ||
            isFieldValid(form, 'householdIdConfirm', 'minlength')
          ">
          {{'demand-appt-identity-modal.householdLengthError' | transloco:{min: MIN_HHID_DIGITS, max: MAX_HHID_DIGITS} }}
        </ion-label>
        <ion-label
            color="danger"
            position="stacked"
            *ngIf="isFieldValid(form, 'householdIdConfirm', 'validateEqual')">
          {{'demand-appt-identity-modal.householdMatchError' | transloco}}
        </ion-label>
      </ion-item>

      <ion-item lines="none" *ngIf="!authority.useHHIDForAppts">
        <ion-label position="stacked">{{'demand-appt-identity-modal.cardSectionLabel' | transloco}}</ion-label>
        <ion-input
            formControlName="cardNumber"
            type="tel" maxlength="16"
            placeholder="{{'demand-appt-identity-modal.cardNumberPlaceholder' | transloco:{len: LEN_CARD_DIGITS} }}">
        </ion-input>
        <ion-input
            formControlName="cardNumberConfirm"
            type="tel" maxlength="16"
            placeholder="{{'demand-appt-identity-modal.cardNumberConfirmPlaceholder' | transloco:{len: LEN_CARD_DIGITS} }}">
        </ion-input>

        <ion-label
            color="danger"
            position="stacked"
            *ngIf="
            isFieldValid(form, 'cardNumber', 'required') ||
            isFieldValid(form, 'cardNumberConfirm', 'required')
          ">
          {{'demand-appt-identity-modal.cardNumberRequiredError' | transloco}}
        </ion-label>
        <ion-label
            color="danger"
            position="stacked"
            *ngIf="
            isFieldValid(form, 'cardNumber', 'minlength') ||
            isFieldValid(form, 'cardNumberConfirm', 'minlength')
          ">
          {{'demand-appt-identity-modal.cardNumberLengthError' | transloco:{len: LEN_CARD_DIGITS} }}
        </ion-label>
        <ion-label
            color="danger"
            position="stacked"
            *ngIf="isFieldValid(form, 'cardNumberConfirm', 'validateEqual')">
          {{'demand-appt-identity-modal.cardNumberMatchError' | transloco}}
        </ion-label>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer translucent>
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button expand="block" class="btn-misty-moss" (click)="submit()" data-cy="identity-confirm-button">
            <fa-icon icon="search" [fixedWidth]="true"></fa-icon>
            <span class="ion-text-uppercase">{{'demand-appt-identity-modal.checkAppointmentButton' | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
