import { Pipe, PipeTransform } from '@angular/core';
import memoizee from 'memoizee';

type Memoized = <T>(fn: T, options) => T;
const memoize = memoizee as Memoized;

export const allowedQuantityRecompute = (benefit: number, size: number, packageSize: number) =>
  Math.floor(benefit / (size / packageSize));


export const allowedQuantity = memoize(allowedQuantityRecompute, { primitive: true});

@Pipe({name: 'computeAllowed'})
export class ComputeAllowedPipe implements PipeTransform {
  transform(benefit: number, size: number, packageSize: number): any {
    return allowedQuantity(benefit, size, packageSize);
  }
}
