import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { EnvironmentService } from '~features/environment.service';
import { MicroBlog } from '~features/micro-blogs/models/micro-blog';
import { MicroBlogViewerModalComponent } from '../../components/micro-blog-viewer-modal/micro-blog-viewer-modal.component';

const TAGS = ['Service', 'Micro Blogs'];

@Injectable()
export class MicroBlogsService {
  constructor(private http: HttpClient, private env: EnvironmentService, private modals: ModalController, private log: LogService) {
  }

  loadMicroBlogs(authorityId: number): Observable<MicroBlog[]> {
    return this.env.apiHost$.pipe(
      take(1),
      map(apiHost => `${apiHost}/v2/authorities/${authorityId}/microblogs`),
      switchMap(url => this.http.get<MicroBlog[]>(url))
    );
  }

  loadLatestMicroBlog(authorityId: number, ids?: string[]): Observable<MicroBlog> {
    return this.env.apiHost$.pipe(
      take(1),
      map(apiHost => `${apiHost}/v2/authorities/${authorityId}/microblogs/latest${!!ids ? `?ids=${ids.join()}` : ''}`),
      switchMap(url => this.http.get<any>(url).pipe(
        tap(blog => this.log.trace(TAGS, 'Found latest micro blog:', blog)),
        map((blog: MicroBlog[] | MicroBlog): MicroBlog => Array.isArray(blog)
          ? blog.length
            ? blog[0]
            : null
          : blog)
      ))
    );
  }

  async showBlog(microBlog: MicroBlog, lang: string): Promise<void> {
    const modal = await this.modals.create({
      component: MicroBlogViewerModalComponent,
      componentProps: {
        microBlog,
        lang,
        showExit: true
      }
    });
    await modal.present();
    await modal.onDidDismiss();
  }
}
