<ion-header>
  <ion-toolbar color="tufts-blue-shaded">
    <ion-title>
      <h4>{{'cant-buy-this.contentTitle' | transloco}}</h4>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row class="ion-padding-vertical">
      <ion-col>
          <ion-text><h2>{{ 'cant-buy-this.isThisYourStore' | transloco }}</h2></ion-text>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="(isLoading$ | async) === false"> 
      <ion-col>
        <div *ngIf="vendors.closest$ | async as closestVendor;">
          <ion-item>
            <ion-label>
              <h2>
                <strong>
                  {{closestVendor.name}}
                </strong>
              </h2>
              <h2>{{closestVendor.address1}}</h2>
              <h2>{{closestVendor.address2}}</h2>
              <h2>{{closestVendor.city}}, {{closestVendor.state}} {{closestVendor.zip}}</h2>
            </ion-label>
          </ion-item>
        </div>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="isLoading$ | async"> 
      <ion-col>
        <ion-item>
          <ion-label>
            <h2>
              <ion-skeleton-text [animated]="true" style="width: 90px"></ion-skeleton-text>
            </h2>
            <h2><ion-skeleton-text [animated]="true" style="width: 110px;"></ion-skeleton-text></h2>
            <h2><ion-skeleton-text [animated]="true" style="width: 110px;"></ion-skeleton-text></h2>
            <h2><ion-skeleton-text [animated]="true" style="width: 200px;"></ion-skeleton-text></h2>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <div *ngIf="vendors.closest$ | async as closestVendor;">
      <ion-row>
        <ion-col>
          <ion-button
            color="dark-red"
            strong="true"
            expand="block"
            (click)="no()"
          >
            {{ 'No' | transloco }}
            <fa-icon icon="times" class="pl-5"></fa-icon>
          </ion-button>
        </ion-col>
        <ion-col>
         
          <ion-button
            color="green"    
            strong="true"
            expand="block"
            (click)="yes(closestVendor)"
          >
            {{ 'Yes' | transloco }}
            <fa-icon icon="check" class="pl-5"></fa-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div>
      <ion-row>
        <ion-col>
          <ion-button color="" strong="true" expand="block" (click)="no()">
            {{ 'buttons.close' | transloco }}
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>  
</ion-content>

