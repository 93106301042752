import { Component, Input, OnInit } from '@angular/core';
import { ProductFacade } from '~features/products/product.facade';
import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { EnrichedBenefit } from '~features/benefits/models';
import { ModalController } from '@ionic/angular';
import { VendorInventoryItemsFacade } from '~features/vendor-inventory-items/vendor-inventory-items.facade';
import { ShoppingListsFacade } from '~features/shopping-lists/shopping-lists.facade';

@Component({
  selector: 'wic-add-item-modal',
  templateUrl: './add-item-modal.component.html',
  styleUrls: ['./add-item-modal.component.scss'],
})
export class AddItemModalComponent  {
  @Input() benefit: EnrichedBenefit;

  constructor(
    public product: ProductFacade,
    public modals: ModalController,
    public vendorInventoryItems: VendorInventoryItemsFacade,
  ) {}

}
