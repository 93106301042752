import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { SettingsFacade } from '~features/settings/settings.facade';
import { Vendor, VendorFavorite } from '~features/vendors/models';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartOpen } from '@fortawesome/free-regular-svg-icons';
import { VendorFacade } from '~features/vendors/vendor.facade';

@Component({
  selector: 'wic-vendor-detail-modal',
  templateUrl: './vendor-detail-modal.component.html',
  styleUrls: ['./vendor-detail-modal.component.scss'],
})
export class VendorDetailModalComponent implements OnInit {
  @Input() vendor: Vendor;
  @Input() favorites: VendorFavorite[];

  faHeart = faHeart;
  faHeartOpen = faHeartOpen;

  constructor(
    public modal: ModalController,
    public vendors: VendorFacade,
    private analytics: AnalyticsFacade,
    public settings: SettingsFacade) {
  }

  ngOnInit() {
    this.analytics.pageView('vendor-detail');
  }
}
