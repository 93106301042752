import { Component, OnInit } from '@angular/core';
import { LogService } from '~core/services/log.service';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { AppointmentsFacade } from '~features/appointments/appointments.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { Card } from '~features/registration/models';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { SettingsFacade } from '~features/settings/settings.facade';

const TAGS = ['Page', 'Appointments'];

@Component({
  selector: 'wic-appointments',
  templateUrl: './appointments.page.html',
  styleUrls: ['./appointments.page.scss'],
})
export class AppointmentsPage implements OnInit {
  constructor(
    public appointments: AppointmentsFacade,
    public enhancedMode: EnhancedModeFacade,
    public registration: RegistrationFacade,
    public settings: SettingsFacade,
    private log: LogService,
    private analytics: AnalyticsFacade) {
  }

  ngOnInit() {
    this.analytics.pageView('appointments');

    this.log.trace(TAGS, 'Appointments page initializing...');
    this.appointments.loadAppointment();
    this.log.trace(TAGS, 'Appointments page initialized.');
  }

  selectCard(cards: Card[], event) {
    this.registration.selectCard(cards.find(card => card.cardNumber === event.detail.value));
  }
}
