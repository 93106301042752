import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wic-startup',
  templateUrl: './startup.page.html',
  styleUrls: ['./startup.page.scss'],
})
export class StartupPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
