import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { CalculatorsFacade } from '~features/calculators/calculators.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { UpcFacade } from '~features/upc/upc.facade';
import { CalculatorEntryListComponent } from '../../components/calculator-entry-list/calculator-entry-list.component';
import { RegistrationFacade } from './../../features/registration/registration.facade';

@Component({
  selector: 'wic-calculator-cvv',
  templateUrl: './calculator-cvv.page.html',
  styleUrls: ['./calculator-cvv.page.scss', '../../features/calculators/calculators.scss'],
})
export class CalculatorCVVPage implements OnInit {
  @ViewChild('entryList', {static: false}) entryList: CalculatorEntryListComponent;

  cvvCategory: number;
  subCategoryId: number = null;

  constructor(
    public benefits: BenefitsFacade,
    public calculators: CalculatorsFacade,
    public enhancedMode: EnhancedModeFacade,
    public registration: RegistrationFacade,
    public upc: UpcFacade,
    private route: ActivatedRoute,
    private analytics: AnalyticsFacade
  ) {
  }

  ngOnInit() {
    this.cvvCategory = +this.route.snapshot.paramMap.get('categoryId') || 19;
    this.analytics.pageView('calculator', ['cvv', `${this.cvvCategory || 0}`]);
  }
}
