import { Column, PrimaryKey, Table } from '../../database/database.decorators';

export type ProductStatus = 'wic' | 'yes' | 'no' | 'cvv' | 'norx' | 'nowic' | 'chk' | 'unk' | 'indeterminate';

@Table('products')
export class Product {
  @PrimaryKey('TEXT') id?: string;
  @Column('TEXT') itemId?: string;
  @Column('INTEGER') authorityId: number;
  @Column('INTEGER') categoryId: number;
  @Column('INTEGER') subCategoryId: number;
  @Column('TEXT') itemNumber: string;
  @Column('TEXT') description: string;
  // Booleans are 0 or 1 Integers in sqlite
  @Column('INTEGER') purchaseIndicator: boolean;
  @Column('REAL') price: number;
  @Column('INTEGER') size: number;
  @Column('INTEGER') packageSize: number;
  @Column('TEXT') uom: string;
  @Column('TEXT') effectiveDate: string;
  @Column('TEXT') endDate: string;
  @Column('TEXT') addedAt: string;
  @Column('TEXT') updatedAt: string;
  @Column('TEXT') deletedAt: string;
}

export class EnrichedProduct extends Product {
  category?: string;
  subCategory?: string;
  version: string;
  status: ProductStatus;
  packSize?: number;
  allowed: number;
  quantity: number;
  normalizedItemNumber: string;
  imageUrl: string;
}

export const isProduct = (item: { categoryId?: number; subCategoryId?: number }): item is Product =>
  item.categoryId !== undefined && item.subCategoryId !== undefined;
