import { NgModule } from '@angular/core';
import { AuthoritiesFacade } from './authorities.facade';


@NgModule({
  providers: [
    AuthoritiesFacade
  ]
})
export class AuthoritiesModule {
}
