<ion-list *ngIf="alert" data-cy="notification-chooser">
  <ion-list-header>
    {{ title }}
  </ion-list-header>
  <ion-item>
    <ion-toggle slot="start" [checked]="alert.allowAlerts" (ionChange)="toggle(alert)"></ion-toggle>
    <ion-label *ngIf="!alert.allowAlerts">{{ 'settings.disableAlertsLabel' | transloco }}</ion-label>
    <ion-label *ngIf="alert.allowAlerts">
      <h2 *ngIf="alert.daysBefore === 0">{{ 'settings.daysBefore' | transloco: { days: 0 } }}</h2>
      <h2 *ngIf="alert.daysBefore === 1">{{ 'settings.daysBefore' | transloco: { days: 1 } }}</h2>
      <h2 *ngIf="alert.daysBefore === 5">{{ 'settings.daysBefore' | transloco: { days: 5 } }}</h2>
      <h2 *ngIf="alert.daysBefore === 7">{{ 'settings.daysBefore' | transloco: { days: 7 } }}</h2>
      <h2 *ngIf="alert.daysBefore === 14">{{ 'settings.daysBefore' | transloco: { days: 14 } }}</h2>
      <h2 *ngIf="![0, 1, 5, 7, 14].includes(alert.daysBefore)">
        {{ 'settings.customDaysBefore' | transloco: { days: alert.daysBefore } }}
      </h2>
    </ion-label>
    <ion-label slot="end" *ngIf="alert.allowAlerts">
      <div class="alert-cal" (click)="chooseSchedule(alert)" data-cy="choose-schedule">
        <div class="cal" [ngClass]="'cal-' + alert.daysBefore" inlineSVG="/assets/images/settings/calendar.svg"></div>
      </div>
    </ion-label>
  </ion-item>
  <ion-item *ngIf="alert.allowAlerts" (click)="popover.present()" button>
    <ion-icon slot="start" name="alarm"></ion-icon>
    <ion-label>{{ 'settings.whenLabel' | transloco }}</ion-label>
    <ion-text slot="end">{{ alert | alertTime | date: 'shortTime' }}</ion-text>

    <ion-popover #popover (didDismiss)="setTime($event, alert)">
      <ng-template>
        <ion-datetime
          presentation="time"
          #popoverDatetime
          [value]="alert | alertTime: true"
          data-cy="choose-time-of-day"
        >
          <ion-buttons slot="buttons">
            <ion-button (click)="popoverDatetime.cancel(true)" slot="buttons">{{
              'buttons.close' | transloco
            }}</ion-button>
            <ion-button (click)="confirm(popoverDatetime)" color="primary" slot="buttons">{{
              'Ok' | transloco
              }}</ion-button>
          </ion-buttons>
        </ion-datetime>
      </ng-template>
    </ion-popover>
  </ion-item>
</ion-list>
