import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Office, OfficeAndFavorites, OfficeFavorite } from '~features/offices/models';
import { Position } from '@capacitor/geolocation';

@Component({
  selector: 'wic-offices-map',
  templateUrl: './offices-map.component.html',
  styleUrls: ['./offices-map.component.scss'],
})
export class OfficesMapComponent {
  @Input() offices: Office[];
  @Input() favorites: OfficeFavorite[];
  @Input() location: Position;
  @Input() userLocation: Position;
  @Output() selected = new EventEmitter<OfficeAndFavorites>();

  userIcon = {
    fillColor: '#8B0000',
    fillOpacity: 1,
    strokeColor: '#8B0000',
    scale: 0.06,
    path: typeof faDotCircle.icon[4] === 'string' ? faDotCircle.icon[4] : faDotCircle.icon[4][0],
  };
  buildingIcon = {
    fillColor: '#60712f',
    fillOpacity: 1,
    strokeColor: 'black',
    scale: 0.06,
    path:  typeof faBuilding.icon[4] === 'string' ? faBuilding.icon[4] : faBuilding.icon[4][0],
  };
}
