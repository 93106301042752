import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LocalNotificationsService } from '~core/services/local-notifications.service';
import { LogService } from '~core/services/log.service';

import { hardReset, initializationCompleted, softReset } from '~features/app/app.actions';
import { allAppointments, appointmentsLoaded } from '~features/appointments/appointments.state';
import { currentBenefits } from '~features/benefits/benefits.selectors';
import { enrichBenefitsCompleted } from '~features/benefits/benefits.actions';
import { EnrichedBenefitInfo } from '~features/benefits/models';
import { AppointmentNotificationsService } from '~features/notifications/appointment-notifications.service';
import { BenefitNotificationsService } from '~features/notifications/benefit-notifications.service';
import { NotificationsService } from '~features/notifications/notifications.service';
import { deleteCardConfirmed, registrationChanged, registrationResetComplete } from '~features/registration/registration.actions';
import {currentCard, currentCards, currentProvider, pendingCard} from '~features/registration/registration.selectors';
import { UserSettings } from '~features/settings/models';
import { userSettings, userSettingsChanged } from '~features/settings/settings.state';
import { State } from '~features/state';
import { scheduleBenefitNotification } from './notifications.actions';
import { merge } from 'rxjs';

const TAGS = ['Effects', 'Notifications', 'Local'];

@Injectable()
export class LocalNotificationsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private localNotifications: LocalNotificationsService,
    private notifications: NotificationsService,
    private log: LogService,
    private appointmentNotifications: AppointmentNotificationsService,
    private benefitNotifications: BenefitNotificationsService) {
  }

  listenForLocalNotifications$ = createEffect(
    () => merge(
      this.localNotifications.localNotificationReceived$$,
      this.localNotifications.localNotificationActionPerformed$$.pipe(map(action => action.notification))
    ).pipe(
      switchMap(notification => this.notifications.handleNotification(notification))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  scheduleBenefitExpiryNotifications$ = createEffect(
    () => this.actions$.pipe(
      ofType(enrichBenefitsCompleted, userSettingsChanged),
      withLatestFrom(
        this.store.select(currentBenefits),
        this.store.select(userSettings)
      ),
      map(([action, benefits, settings]): [boolean, EnrichedBenefitInfo, UserSettings] => [
        action.type === userSettingsChanged.type,
        benefits,
        settings
      ]),
      filter(([, benefits, settings]) => !!benefits && !!settings),
      map(([force, {endDate}, settings]) => scheduleBenefitNotification({benefitsEndDate: endDate, force, settings})),
    )
  );

  scheduleBenefitNotification$ = createEffect(() =>
      this.actions$.pipe(
        ofType(scheduleBenefitNotification),
        tap(() => this.log.info(TAGS, 'Scheduling BENEFIT notifications...')),
        withLatestFrom(this.store.select(currentCard), this.store.select(pendingCard)),
        filter(([, current, pending]) =>  !!current || !!pending),
        filter(([{ benefitsEndDate }]) =>  !!benefitsEndDate),
        tap(([{force, benefitsEndDate, settings}, current, pending]) =>
          this.benefitNotifications.registerNotifications(
            current ? current.cardNumber : pending.cardNumber, benefitsEndDate, settings, force
          )
        )
      ),
    {dispatch: false, resubscribeOnError: true}
  );

  cancelAllBenefitExpiryNotifications$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset, registrationChanged, registrationResetComplete),
      tap(() => this.log.info(TAGS, 'Canceling all BENEFIT notifications...')),
      tap(() => this.benefitNotifications.cancelNotifications())
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  cancelCardBenefitExpiryNotifications$ = createEffect(
    () => this.actions$.pipe(
      ofType(deleteCardConfirmed),
      tap(({card: {cardNumber}}) => this.log.info(TAGS, `Canceling BENEFIT notifications for ${cardNumber}...`)),
      tap(({card: {cardNumber}}) => this.benefitNotifications.cancelNotifications(cardNumber))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  scheduleAppointmentNotifications$ = createEffect(
    () => this.actions$.pipe(
      ofType(appointmentsLoaded, userSettingsChanged),
      withLatestFrom(
        this.store.select(allAppointments),
        this.store.select(currentCards),
        this.store.select(userSettings),
        this.store.select(currentProvider)
      ),
      filter(([, , , settings, authority]) => !!settings && !!authority),
      tap(([, appointments, cards, settings]) =>
        this.log.info(TAGS, 'Scheduling APPOINTMENT notifications...', appointments, cards, settings)
      ),
      tap(([, appointments, cards, settings, authority]) =>
        this.appointmentNotifications.registerNotifications(cards, appointments, settings, authority)
      )
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  cancelAppointmentNotifications$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset, registrationChanged, registrationResetComplete),
      tap(() => this.log.info(TAGS, 'Canceling all APPOINTMENT notifications...')),
      tap(() => this.appointmentNotifications.cancelNotifications())
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  cancelCardAppointmentExpiryNotifications$ = createEffect(
    () => this.actions$.pipe(
      ofType(deleteCardConfirmed),
      tap(({card: {cardNumber}}) => this.log.info(TAGS, `Canceling APPOINTMENT notifications for ${cardNumber}...`)),
      tap(({card: {cardNumber}}) => this.appointmentNotifications.cancelNotifications(cardNumber))
    ),
    {dispatch: false, resubscribeOnError: true}
  );
}
