<ion-list lines="none" data-cy="card-list">
  <ion-item-sliding
    #slidingItem
    class="calculator-item"
    *ngFor="let card of cards"
  >
    <ion-item
      [color]="card.cardNumber === currentCard?.cardNumber ? 'medium' : null"
      data-cy="card"
    >
      <ion-avatar slot="start">
        <ion-button
          class="item-toggle-button flex align-center justify-center"
          fill="clear"
          (click)="isLoading ? noop() : selected.emit(card)"
        >
          <img
            *ngIf="card.cardNumber === currentCard?.cardNumber"
            class="card-active-img"
            src="assets/images/cards/active.png"
          />
          <img
            *ngIf="card.cardNumber !== currentCard?.cardNumber"
            class="card-inactive-img"
            src="assets/images/cards/inactive.png"
          />
      </ion-button>
      </ion-avatar>
      <ion-label (click)="isLoading ? noop() : selected.emit(card)">
        <h2
          [class.text-light-gray]="card.cardNumber !== currentCard?.cardNumber"
        >
          <strong>{{ card.cardNumber | mask }}</strong>
        </h2>
        <span
          [class.text-light-gray]="card.cardNumber !== currentCard?.cardNumber"
          [class.text-med-gray]="card.cardNumber === currentCard?.cardNumber"
        >
          {{ card.nickname }}
        </span>
      </ion-label>
      <ion-button
        slot="end"
        class="item-spinner-button"
        fill="clear"
        data-cy="loading-card"
        (click)="openSlidingItem(slidingItem)"
        *ngIf="!isLoading"
      >
        <ion-icon
          slot="icon-only"
          name="ellipsis-horizontal-circle-outline"
        ></ion-icon>
      </ion-button>
      <ion-button
        slot="end"
        class="item-spinner-button"
        fill="clear"
        *ngIf="isLoading"
        data-cy="loading-card"
      >
        <ion-spinner name="dots"></ion-spinner>
    </ion-button>
    </ion-item>

    <ion-item-options side="end">
      <ion-item-option
        (click)="edited.emit(card)"
        [disabled]="isLoading"
        data-cy="edit-card-button"
        color="light"
      >
        <ion-icon slot="icon-only" name="pencil-outline"></ion-icon>
      </ion-item-option>

      <ion-item-option
        (click)="deleted.emit(card)"
        [disabled]="isLoading"
        data-cy="delete-card-button"
        color="danger"
      >
        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</ion-list>
