import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wic-missing-data-warning',
  templateUrl: './missing-data-warning.component.html',
  styleUrls: ['./missing-data-warning.component.scss'],
})
export class MissingDataWarningComponent {
  @Input() title: string;
  @Input() info: string;
  @Output() retry = new EventEmitter();
}
