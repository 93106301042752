import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createAction, createReducer, createSelector, on, props } from '@ngrx/store';
import { EnrichedProduct } from '~features/products/models';
import { MissingProduct } from './models';
import { Vendor } from '~features/vendors/models';

export const {initialState, facade: MissingProductFacadeBase, entityState} = buildState(MissingProduct, {
  isScanning: 0
} as IMissingProductState);

export const reportMissingProduct = createAction(
  '[Missing Product] File report',
  props<{ product: EnrichedProduct }>()
);

export const captureMissingProductPhoto = createAction(
  '[Missing Product] Capture photo',
  props<{ isLabel: boolean, isUpc: boolean }>()
);

export const productPhotoCaptured = createAction(
  '[Missing Product] Product photo captured',
  props<{ capturedPhoto: any }>()
);

export const labelPhotoCaptured = createAction(
  '[Missing Product] Label photo captured',
  props<{ capturedLabel: any }>()
);

export const upcPhotoCaptured = createAction(
  '[Missing Product] Upc photo captured',
  props<{ capturedUpc: any }>()
);

export const icbtPhotoCaptureError = createAction(
  '[Missing Product] Error capturing photo',
  props<{ error: Error | any }>()
);

export const noPhotoCaptured = createAction(
  '[Missing Product] No photo captured'
);

export const clearCapturedProductPhoto = createAction(
  '[Missing Product] Clear product photo'
);

export const clearCapturedLabelPhoto = createAction(
  '[Missing Product] Clear label photo'
);

export const clearCapturedUpcPhoto = createAction(
  '[Missing Product] Clear upc photo'
);

export const scanMissingProductBarcode = createAction(
  '[Missing Product] Scan barcode'
);

export const missingProductBarcodeScanned = createAction(
  '[Missing Product] Barcode scanned',
  props<{ upc: string; upcType: string }>()
);

export const missingProductBarcodeScanError = createAction(
  '[Missing Product] Barcode scan error',
  props<{ error: Error | any }>()
);

export const missingProductBarcodeScanCancelled = createAction(
  '[Missing Product] Barcode scan cancelled'
);

export const clearUPC = createAction(
  '[Missing Product] Clear UPC'
);

export const submitMissingProductReport = createAction(
  '[Missing Product] Submit report',
  props<{ missing: MissingProduct }>()
);

export const missingProductReportSubmissionError = createAction(
  '[Missing Product] Submit report',
  props<{ missing: MissingProduct }>()
);

export const displayIsThisYourStore = createAction(
  '[Missing Product] Display Is This Your Store Modal'
);

export const confirmIsThisYourStore = createAction(
  '[Missing Product] Confirmed Is This Your Store Modal',
  props<{ vendor: Vendor }>()
);

export const dismissIsThisYourStore = createAction(
  '[Missing Product] Dismiss Is This Your Store Modal'
);

export const clearCurrentStore = createAction(
  '[Missing Product] Clear Current Store'
);

export interface IMissingProductState extends IEntityState<MissingProduct> {
  capturedPhoto?: any;
  capturedLabel?: any;
  capturedUpc?: any;
  isScanning: number;
  currentUPC: string;
  parsedUPC: string;
  vendor?: Vendor;
}

const reduce = createReducer(
  initialState,
  on(productPhotoCaptured, (state, {capturedPhoto}) => ({
    ...state,
    capturedPhoto
  })),
  on(labelPhotoCaptured, (state, {capturedLabel}) => ({
    ...state,
    capturedLabel
  })),
  on(upcPhotoCaptured, (state, { capturedUpc }) => ({
    ...state,
    capturedUpc
  })),
  on(clearCapturedProductPhoto, (state) => ({
    ...state,
    capturedPhoto: null
  })),
  on(clearCapturedLabelPhoto, (state) => ({
    ...state,
    capturedLabel: null
  })),
  on(clearCapturedUpcPhoto, (state) => ({
    ...state,
    capturedUpc: null
  })),
  on(missingProductBarcodeScanned, (state, {upc}) => ({
      ...state,
      currentUPC: upc,
      isScanning: 0
    })),
  on(missingProductBarcodeScanCancelled, missingProductBarcodeScanError, (state) => ({
    ...state,
    isScanning: 0
  })),
  on(clearUPC, (state) => ({
    ...state,
    currentUPC: null,
    parsedUPC: null,
    isScanning: 0
  })),
  on(clearCurrentStore, (state) => ({
    ...state,
    vendor: null
  })),
  on(confirmIsThisYourStore, (state, {vendor}) => ({
    ...state,
    vendor: vendor
  })),
  on(dismissIsThisYourStore, (state) => ({
    ...state,
    vendor: null
  }))
);

export function missingProductReducer(state: IMissingProductState, action: Action): IMissingProductState {
  return reduce(state, action);
}

export const mapToCapturedPhoto = (state: IMissingProductState): string =>
  state.capturedPhoto ? 'data:image/jpeg;base64,' + state.capturedPhoto : undefined;

export const mapToCapturedLabel = (state: IMissingProductState): string =>
  state.capturedLabel ? 'data:image/jpeg;base64,' + state.capturedLabel : undefined;

export const mapToCapturedUpc = (state: IMissingProductState): string  =>
  state.capturedUpc ? 'data:image/jpeg;base64,' + state.capturedUpc : undefined;

export const mapToHasCapturedPhoto = (state: IMissingProductState): boolean =>
  !!state.capturedPhoto;

export const mapToHasCapturedLabel = (state: IMissingProductState): boolean =>
  !!state.capturedLabel;

export const mapToHasCapturedUpc = (state:IMissingProductState): boolean =>
  !!state.capturedUpc;

export const mapToIsScanning = (state: IMissingProductState): number =>
  state.isScanning;

export const mapToCurrentUPC = (state: IMissingProductState): string =>
  state.currentUPC;

export const mapToCurrentStore = (state: IMissingProductState): Vendor =>
  state.vendor;

export const productImage = createSelector(
  entityState,
  mapToCapturedPhoto
);

export const hasProductPhoto = createSelector(
  entityState,
  mapToHasCapturedPhoto
);


export const labelImage = createSelector(
  entityState,
  mapToCapturedLabel
);

export const hasLabelPhoto = createSelector(
  entityState,
  mapToHasCapturedLabel
);

export const upcImage = createSelector(
  entityState,
  mapToCapturedUpc
);

export const hasUpcImage = createSelector(
  entityState,
  mapToHasCapturedUpc
)

export const isScanning = createSelector(
  entityState,
  mapToIsScanning
);

export const currentUPC = createSelector(
  entityState,
  mapToCurrentUPC
);

export const currentStore = createSelector(
  entityState,
  mapToCurrentStore
);
