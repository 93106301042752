import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { LocationSearchCriteria } from '../../../models/location-search-criteria';
import { GroupCodeFilterOption } from '~features/vendors/models';
import { VendorFacade } from '~features/vendors/vendor.facade';

@Component({
  selector: 'wic-vendor-search-modal',
  templateUrl: './vendor-search-modal.component.html',
  styleUrls: ['./vendor-search-modal.component.scss'],
})
export class VendorSearchModalComponent implements OnInit {
  @Input() criteria: LocationSearchCriteria;
  
  form: UntypedFormGroup;

  constructor(
    public modal: ModalController, 
    private fb: UntypedFormBuilder, 
    private analytics: AnalyticsFacade,
    public vendor: VendorFacade) {
  }

  ngOnInit() {
    this.analytics.pageView('vendors-search-settings');

    this.form = this.fb.group({
      radius: [5, [Validators.required, Validators.min(1), Validators.max(100)]],
      anywhere: [false],
      onlyFavorites: [false],
      groupCodeFilter: [0]
    });

    this.form.controls['anywhere'].valueChanges.subscribe(
      anywhere => anywhere ? this.form.controls['radius'].disable() : this.form.controls['radius'].enable()
    );

    this.form.patchValue(this.criteria || {});
  }
}
