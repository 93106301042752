import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject, DbTransaction, SQLiteDatabaseConfig } from '@awesome-cordova-plugins/sqlite/ngx';
import { format } from 'date-fns';
import { LogService } from '~core/services/log.service';
import { getColumns, getPrimaryKey, getTable } from './database.decorators';
import { DatabaseService, DBQuery } from './database.service';

const TAGS = ['Service', 'SQLite'];

export type SQLiteColumnType = 'TEXT' | 'INTEGER' | 'NUMERIC' | 'REAL' | 'BLOB';

export interface RawSQLiteResult<T> {
  insertId?: string;
  rows: {
    item: (i: number) => T;
    length: number;
  };
  rowsAffected: number;
}

export const toColumnDefinition = <T>(obj: Record<string, string>, pkColumn: string) =>
  obj
    ? Object.entries(obj).map(([name, type]) => `${name} ${type}${name === pkColumn ? ' PRIMARY KEY' : ''}`)
    : [];

export const createInsertString = (table, entity) =>
  `INSERT INTO ${table} (${Object.keys(entity).join(', ')}) VALUES (${Object.keys(entity).map(i => '?').join(', ')})`;

export const keyEqualsParamOf = (entity) =>
  entity
    ? Object.keys(entity).map(k => `${k} = ?`)
    : [];

export const keyInParamOf = (inQuery: { [p: string]: any[] }) =>
  inQuery
    ? Object.entries(inQuery).map(([key, values]) => `${key} IN (${values.map(v => '?').join()})`).join(', ')
    : [];

export const keyLikeParamOf = (entity) =>
  entity
    ? Object.keys(entity).map(k => `${k} LIKE ?`).join(' AND ')
    : [];

export const keyOrParamOf = (entity) =>
  ``;

export const createLikeParams = (entity) =>
  entity
    ? Object.values(entity).map(val => '%' + val + '%')
    : [];

@Injectable()
export class SQLiteService extends DatabaseService {
  db: SQLiteObject;
  config: SQLiteDatabaseConfig = {
    name: 'wicshopper.db',
    location: 'default'
  };

  constructor(private sqlite: SQLite, private log: LogService) {
    super();
  }

  setConfig(config: SQLiteDatabaseConfig) {
    this.config = config;
  }

  override async initialize() {
    try {
      const test = await this.sqlite.selfTest();
      this.log.trace(TAGS, 'SQLite Self Test', test);

      this.db = await this.sqlite.create(this.config);
      this.log.trace(TAGS, 'DB Created', this.db);
    } catch (err) {
      this.log.error(TAGS, 'Error Creating Connection to SQLite database', err);
    }
  }

  override createTable<T>(entity: new () => T) {
    const table = getTable(entity);
    const columns = getColumns(entity);
    const pkColumnName = getPrimaryKey(entity);
    return this.executeSqlRaw(`CREATE TABLE IF NOT EXISTS ${table} (${toColumnDefinition(columns, pkColumnName).join(', ')})`);
  }

  override removeTable<T>(entity: new () => T) {
    const table = getTable(entity);
    return this.executeSqlRaw(`DROP TABLE IF EXISTS ${table}`);
  }

  override getTableInfo<T>(entity: new () => T) {
    const table = getTable(entity);
    return this.executeSqlRaw(`PRAGMA table_info(${table})`);
  }

  override insert<T>(entity: new () => T, items: T[], tx?: DbTransaction): Promise<T[]> {
    return Promise.all(items.map(item => this.insertOne(entity, item, tx)));
  }

  override insertOne<T>(entity: new () => T, item: T, tx?: DbTransaction): Promise<T> {
    const table = getTable(entity);
    const sql = createInsertString(table, item);
    this.log.trace(TAGS, sql);
    return this.executeSql<T>(sql, Object.values(item), tx).then((items) => items[0]);
  }

  override upsert<T>(entity: new () => T, items: T[], tx?: DbTransaction): Promise<T[]> {
    return Promise.all(items.map(item => this.upsertOne(entity, item, tx)));
  }

  override upsertOne<T>(entity: new () => T, item: T, tx?: DbTransaction): Promise<T> {
    const table = getTable(entity);
    const pk = getPrimaryKey(entity);
    const resolveConfict = (i) => Object.keys(i).map(k => `${k}=excluded.${k}`);
    const sql = `${createInsertString(table, item)} ON CONFLICT(${pk}) DO UPDATE SET ${resolveConfict(item)}`;
    this.log.trace(TAGS, sql);
    return this.executeSql<T>(sql, Object.values(item), tx).then((items) => items[0]);
  }

  override update<T>(entity: new () => T, items: Partial<T>[], tx?: DbTransaction): Promise<T[]> {
    return Promise.all(items.map(item => this.updateOne(entity, item, tx)));
  }

  override updateOne<T>(entity: new () => T, item: Partial<T>, tx?: DbTransaction): Promise<T> {
    const table = getTable(entity);
    const pk = getPrimaryKey(entity);
    const sql = `UPDATE ${table} SET ${keyEqualsParamOf(item).join(', ')} WHERE ${pk} = ${item[pk]}`;
    this.log.trace(TAGS, sql);
    return this.executeSql<T>(sql, Object.values(item), tx).then((items) => items[0]);
  }

  override select<T>(entity: new () => T, query?: DBQuery<T>): Promise<T[]> {
    const table = getTable(entity);
    const select = `SELECT * FROM ${table}${query ? ' WHERE ' : ''}`;
    const where = query && query.where ? keyEqualsParamOf(query.where).join(' AND ') : '';
    const whereParams = query && query.where ? Object.values(query.where) : [];

    const selectIn = query && query.in ? keyInParamOf(query.in) : '';
    const selectInParams = query && query.in ? Object.values(query.in) : [];

    const like = query && query.like ? keyLikeParamOf(query.like) : '';
    const likeParams = query && query.like ? createLikeParams(query.like) : [];

    const or = query && query.or ? keyOrParamOf(query.or) : '';

    const sql = select + where + selectIn + like;
    this.log.trace(TAGS, sql);
    const params = [...whereParams, ...selectInParams, ...likeParams];
    this.log.trace(TAGS, params.join());
    return this.executeSql(sql, query ? params : undefined);
  }

  override selectProducts<T>(entity: new () => T, query: DBQuery<T>): Promise<T[]> {
    const now = format(Date.now(), 'yyyyMMdd');
    const table = getTable(entity);
    const where = query.where ? keyEqualsParamOf(query.where).join(' AND ') : '';
    const whereParams = Object.values(query.where);
    const like = query && query.like ? keyLikeParamOf(query.like) : '';
    const likeParams = query && query.like ? createLikeParams(query.like) : [];

    const sql =
      `SELECT *
      FROM ${table}
      WHERE
      (
        effectiveDate <= '${now}' OR
        effectiveDate = '00000000' OR
        effectiveDate = NULL
      ) AND (
        endDate >= '${now}' OR
        endDate = '00000000' OR
        endDate = NULL
      ) ${where || like ? `AND (
         ${where}${like}
      )` : ''}`;

    this.log.trace(TAGS, sql);
    const params = [...whereParams, ...likeParams];
    this.log.trace(TAGS, params.join());
    return this.executeSql(sql, query ? params : undefined);
  }

  override async removeMany<T>(entity: new () => T, wheres: Partial<T>[], tx?: DbTransaction): Promise<T[]> {
    const res = await Promise.all(wheres.map(item => this.remove(entity, item, tx)));
    this.log.trace(TAGS, 'Remove Res', res);
    return [].concat(...res);
  }

  override remove<T>(entity: new () => T, where: Partial<T>, tx?: DbTransaction): Promise<T[]> {
    const table = getTable(entity);
    const sql = `DELETE FROM ${table} WHERE ${keyEqualsParamOf(where).join(' AND ')}`;
    return this.executeSql(sql, Object.values(where), tx);
  }

  override removeAll<T>(entity: new () => T, tx?: DbTransaction): Promise<number> {
    const table = getTable(entity);
    const sql = `DELETE FROM ${table}`;
    return this.executeSql(sql, undefined, tx).then((items) => items.length);
  }

  override createTransaction<U>( callback: (tx: DbTransaction) => Promise<U>): Promise<U> {
    return new Promise((resolve, reject) => this.db.transaction((tx) => {
      callback(tx).then(resolve).catch(reject);
    }));
  }

  _executeSql<U>(sql: string, params, tx: DbTransaction): Promise<U[]> {
    return new Promise((resolve, reject) =>
      tx.executeSql(sql, params,
        (transaction, res) => resolve(this.resolveRawResult(res)),
        (transaction, err) => (reject(this.handleError('Error Executing SQL in Transaction', err)), false)
      )
    );
  }

  executeSql<U>(sql: string, params?, tx?: DbTransaction): Promise<U[]> {
    // eslint-disable-next-line no-underscore-dangle
    return tx ? this._executeSql(sql, params, tx) : this.executeSqlRaw(sql, params);
  }

  executeSqlRaw<U>(sql: string, params?): Promise<U[]> {
    return new Promise((resolve, reject) =>
      this.db.executeSql(sql, params,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (suc) => resolve(this.resolveRawResult(suc)),
        (err) => (reject(this.handleError('Error Executing SQL', err)), false)
      )
    );
  }

  resolveRawResult<U>(result: RawSQLiteResult<U>): U[] {
    return Array.from({length: result.rows.length}).map((_, i) => result.rows.item(i));
  }

  handleError(context: string, err: any) {
    this.log.error(TAGS, context, err);
    return err;
  }
}
