<ion-card>
  <ion-card-header>
    <ion-card-subtitle>{{ 'common.missingData' | transloco }}</ion-card-subtitle>
    <ion-card-title>
      {{ 'future-benefits.noFutureBenefitsTitle' | transloco }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <p>{{ 'future-benefits.noFutureBenefitsFoundInfo' | transloco }}</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
