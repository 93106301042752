import { NgModule } from '@angular/core';
import { APLMessagesFacade } from '~features/aplmessages/aplmessages.facade';


@NgModule({
  providers: [
    APLMessagesFacade
  ]
})
export class AplmessagesModule {
}
