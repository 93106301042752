<ion-header>
  <ion-toolbar color="white">
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false)">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="qr-warn-bg">
    <img src="/assets/images/upc/manual_barcode.png">
  </div>
  <div class="qr-warn-header">
    <h1 class="ion-text-center">{{ 'search-upc.scannedQRCode' | transloco }}</h1>
  </div>
  <div class="qr-warn-detail">
    <div class="qr-warn-detail-content ion-text-center">
      <img src="/assets/images/upc/qr_warn.png"/>
      <h4>{{ 'search-upc.warnQRHelp' | transloco }}</h4>
    </div>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar color="white">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button expand="block" color="burgundy" class="btn-burgundy" (click)="modal.dismiss(false)">
            <ion-icon name="close-circle-outline"></ion-icon>
            <span class="ion-text-uppercase">{{ 'cancel' | transloco }}</span>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button expand="block" color="tufts-blue" class="btn-tufts-blue" (click)="modal.dismiss(true)">
            <ion-icon name="checkmark-circle"></ion-icon>
            <span class="ion-text-uppercase">{{ 'search-upc.scanAgain' | transloco }}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
