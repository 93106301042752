import { Component, Input } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { EnrichedBenefitInfo } from '~features/benefits/models';

@Component({
  selector: 'wic-last-updated-at',
  templateUrl: './last-updated-at.component.html',
  styleUrls: ['./last-updated-at.component.scss'],
})
export class LastUpdatedAtComponent {
  @Input() benefitInfo: EnrichedBenefitInfo;
  @Input() hideUpdatedAt = false;

  getLastUpdateParams(benefits: EnrichedBenefitInfo) {
    if (!benefits.benefitsUpdatedAt) {
      return {
        date: '?',
        time: '?'
      };
    }
    const updatedAt = parseISO(benefits.benefitsUpdatedAt);
    return {
      date: format(updatedAt, 'M/dd'),
      time: format(updatedAt, 'h:mm a')
    };
  }
}
