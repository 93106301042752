import { Component, Input, OnInit } from '@angular/core';
import { ProductFacade } from '~features/products/product.facade';
import { ModalController } from '@ionic/angular';
import { VendorInventoryItemsFacade } from '~features/vendor-inventory-items/vendor-inventory-items.facade';
import { EnrichedBenefit } from '~features/benefits/models';
import { Product } from '~features/products/models';
import { allowedQuantity } from '../../../pipes/compute-allowed.pipe';
import { QuantityEditFormControl } from '../quantity-edit.form';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ShoppingListsFacade } from '~features/shopping-lists/shopping-lists.facade';
import { ShoppingListItemsFacade } from '~features/shopping-lists/shopping-list-items/shopping-list-items.facade';
import { Observable } from 'rxjs';
import { SubstitutionsFacade } from '~features/shopping-lists/substitutions/substitutions.facade';
import { VendorInventoryProduct } from '~features/vendor-inventory-items/vendor-inventory-product.model';

@Component({
  selector: 'wic-confirm-item-modal',
  templateUrl: './confirm-item-modal.component.html',
  styleUrls: ['./confirm-item-modal.component.scss'],
})
export class ConfirmItemModalComponent implements OnInit {
  @Input() benefit: EnrichedBenefit;
  @Input() product: VendorInventoryProduct;

  quantityEditForm$: Observable<QuantityEditFormControl> = this.shoppingListItems.quantityEditItem$.pipe(
    filter(id => !!id),
    withLatestFrom(this.shoppingLists.quantityEditBenefitShoppingListGroup$),
    map(([item, benefit]) => new QuantityEditFormControl(item, benefit))
  );

  constructor(
    public products: ProductFacade,
    public modal: ModalController,
    public readonly shoppingLists: ShoppingListsFacade,
    public readonly shoppingListItems: ShoppingListItemsFacade,
    public readonly substitutions: SubstitutionsFacade,
    public vendorInventoryItems: VendorInventoryItemsFacade,
  ) {}

  ngOnInit(): void {}

  getAllowedStatus(benefit: EnrichedBenefit, product: Product) {
    const allowed = allowedQuantity(benefit.quantity, product.size, product.packageSize);
    return allowed === 0 ? 'red' : allowed === 1
      ? 'yellow'
      : 'green';
  }
}
