import { Pipe, PipeTransform } from '@angular/core';
import { LoggingLevel } from '~core/services/log.service';

@Pipe({
  name: 'levelToName'
})
export class LevelToNamePipe implements PipeTransform {
  transform(level: LoggingLevel): any {
    switch (level) {
      case LoggingLevel.trace:
        return 'T';
      case LoggingLevel.debug:
        return 'D';
      case LoggingLevel.info:
        return 'I';
      case LoggingLevel.warn:
        return 'W';
      case LoggingLevel.error:
        return 'E';
    }

    return 'I';
  }
}
