import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '~env';
import { AdminSettingsFacade } from '~features/settings/admin-settings.facade';
import { tpipe } from '../../util/func-util';

import { RemoteLogService } from './remote-log.service';

export enum LoggingLevel {
  error = 50,
  warn = 40,
  info = 30,
  debug = 20,
  trace = 10
}

export interface TaggedMessages {
  type: string;
  messages: any;
  tags: string[];
  isDevice: boolean;
}

export interface TaggedMessage {
  type: string;
  message: any;
  tags: string[];
  isDevice: boolean;
}

export interface LogShipment {
  remoteLog: RemoteLogService;
  type: string;
  tags: string[];
  messages: any[];
}

const APP_TAG = '[WIC] [v5]';

export const CARD_NUMBERS_EXP = /.*?\b(\d{16})\b.*?/igm;

export const generateMask = (len: number, maskChar: string = '*'): string =>
  Array(len).fill(maskChar).join('');

export const mask = (str: string, start: number = 0, end?: number, maskChar?: string) =>
  str.replace(
    str,
    `${str.substring(0, start)}${generateMask((end || str.length) - start, maskChar)}${str.substring(end || str.length)}`
  );

export const secureString = (expr: RegExp, maskChar?: string) => (message: string): string =>
  message && message.replace(expr, (match, $1) =>
    !!$1
      ? match.replace($1, mask($1, 6, 13, maskChar))
      : match
  );

export const MAX_MESSAGE_LENGTH = 200;
export const truncateLongStrings = (maxLength: number) => (message: string): string =>
  message && message.length > maxLength
    ? `${message.substr(0, maxLength - 3)}...`
    : message;

export const sanitizeString = /* (value: string): string => */
  tpipe(
    truncateLongStrings(MAX_MESSAGE_LENGTH),
    // secureString(CARD_NUMBERS_EXP, '#'),
    // TODO: More sanitizations?
  );

export const sanitizeMessage = (message: string): string =>
  environment.production
    ? sanitizeString(message)
    : message;

export const sanitizeArray = (data: any[]): any[] =>
  data
    .slice(0, data.length > 20 ? 20 : data.length)
    .map(item =>
      typeof item === 'string'
        ? sanitizeMessage(item)
        // eslint-disable-next-line
        : sanitizeObject(item)
    )
    .concat(data.length > 20 ? ['truncated...'] : []);

export const sanitizeObject = (message: any): any =>
  (environment.production && message && !(message instanceof Date || typeof message === 'number' || typeof message === 'boolean'))
    ? Array.isArray(message)
    ? sanitizeArray(message)
    : Object.keys(message).reduce((obj, key) =>
        (typeof obj[key] === 'string'
          ? obj[key] = sanitizeString(obj[key])
          : obj[key] instanceof Date
            ? obj[key]
            : Array.isArray(obj[key])
              ? obj[key] = sanitizeArray(obj[key])
              : typeof obj[key] === 'object'
                ? obj[key] = sanitizeObject(obj[key])
                : obj[key]
          , obj)
      , {...message})
    : message;

export const formatTags = (tags: string[]): string =>
  tags ? tags.map(tag => `[${tag}]`).join(' ') : '';

export const formatMessage = ({type, message, tags, isDevice}: TaggedMessage): string | any =>
  typeof message === 'string'
    ? `(${(new Date()).toISOString()}) ${type}: ${APP_TAG} ${formatTags(tags)} ${sanitizeMessage(message)}`
    : isDevice ? JSON.stringify(sanitizeObject(message)) : sanitizeObject(message);

export const formatMessages = ({type, messages, tags, isDevice}: TaggedMessages): any[] =>
  messages.map(message => formatMessage({type, message, tags, isDevice}));

export const formatEntry = (message: any): string =>
  typeof message === 'string' ? sanitizeMessage(message) : JSON.stringify(sanitizeObject(message));

export const shipEntries = ({remoteLog, type, tags, messages}: LogShipment): void | false =>
  (messages && messages.length) ? messages
    .map(message => formatEntry(message))
    .forEach(message => remoteLog.ship({type: type.trim(), tags, message, timestamp: (new Date()).toISOString()})) : false;

/* eslint-disable no-console */
export const logSingleString = (isDevice: boolean, level: LoggingLevel, message: string): void =>
  level === LoggingLevel.error
    ? console.error(message)
    : level === LoggingLevel.warn
    ? console[isDevice ? 'log' : 'warn'](message)
    : level === LoggingLevel.info
      ? console.log(message)
      : console[isDevice ? 'log' : 'debug'](message);

export const logSingleObject = (isDevice: boolean, level: LoggingLevel, message: any): void =>
  level === LoggingLevel.error ? console.error(message) : console[isDevice ? 'log' : 'dir'](message);
/* eslint-enable no-console */

export const logSingle = (isDevice, level: LoggingLevel, message: any): void =>
  (typeof message === 'string')
    ? logSingleString(isDevice, level, message)
    : logSingleObject(isDevice, level, message);

export const writeLog = (isDevice: boolean, level: LoggingLevel, messages: any[]): void | false =>
  (messages && messages.length)
    ? messages.forEach(message => logSingle(isDevice, level, message))
    : false;

export const isAllowedLevel = (allowedLevel: LoggingLevel, requiredLevel: LoggingLevel): boolean => allowedLevel <= requiredLevel;
export const hasMessages = (messages: any[]): boolean => !!messages && !!messages.length;
export const levelToType = (level: LoggingLevel): string => {
  switch (level) {
    case LoggingLevel.trace:
      return 'TRACE';
    case LoggingLevel.debug:
      return 'DEBUG';
    case LoggingLevel.info:
      return ' INFO';
    case LoggingLevel.warn:
      return ' WARN';
    case LoggingLevel.error:
      return 'ERROR';
  }

  return 'INFO';
};

export const OVERRIDE_LOGGING_LEVEL = new InjectionToken('Override Logging Level');

export interface LogEntry {
  timestamp: Date;
  level: LoggingLevel;
  tags: string[];
  message: string;
}

export class LocalLogService {
  constructor(private root: LogService) {
  }

  trace(tags: string[], ...message: any[]) {
    this.root.log(LoggingLevel.trace, tags, false, ...message);
  }

  debug(tags: string[], ...message: any[]) {
    this.root.log(LoggingLevel.debug, tags, false, ...message);
  }

  info(tags: string[], ...message: any[]) {
    this.root.log(LoggingLevel.info, tags, false, ...message);
  }

  warn(tags: string[], ...message: any[]) {
    this.root.log(LoggingLevel.warn, tags, false, ...message);
  }

  error(tags: string[], ...message: any[]) {
    this.root.log(LoggingLevel.error, tags, false, ...message);
  }
}

@Injectable()
export class LogService {
  private allowedLevel = LoggingLevel.error;
  private readonly isDevice: boolean;

  private localEnabled$$ = new BehaviorSubject(false);
  private maxEntryCount = 500;
  private localLog$$ = new BehaviorSubject<LogEntry[]>([]);

  get localLog$(): Observable<LogEntry[]> {
    return this.localLog$$.asObservable();
  }

  get isEnabled$(): Observable<boolean> {
    return this.localEnabled$$;
  }

  get loggingLevel(): LoggingLevel {
    return this.allowedLevel;
  }

  set loggingLevel(level: LoggingLevel) {
    this.allowedLevel = level;
  }

  local: LocalLogService;

  constructor(
    private platform: Platform,
    private adminSettings: AdminSettingsFacade,
    private remoteLog: RemoteLogService,
    @Optional() @Inject(OVERRIDE_LOGGING_LEVEL) overrideLoggingLevel: LoggingLevel) {
    this.allowedLevel = overrideLoggingLevel || LoggingLevel[environment.logging.level || 'error'];
    adminSettings.logging$.pipe(
      filter(settings => !!settings),
      map(settings => settings.enableLocal)
    ).subscribe(this.localEnabled$$);

    this.local = new LocalLogService(this);

    this.isDevice = (platform && platform.is('cordova') && (platform.is('ios') || platform.is('android')));
    console.log(`[WIC] [v5] [Log] Log initialized. ${this.isDevice ? 'Currently running on a device' : 'Currently running in a browser'}`);
  }

  toggleLocal(maxEntryCount = 200) {
    if (this.localEnabled$$.getValue()) {
      this.adminSettings.updateSettings({logging: {enableLocal: false}});
      this.log(LoggingLevel.debug, ['Log', 'Local'], true, 'Local logging has been disabled.');
    } else {
      this.maxEntryCount = maxEntryCount;
      if (!this.localLog$$) {
        this.localLog$$ = new BehaviorSubject<LogEntry[]>([]);
      }
      this.adminSettings.updateSettings({logging: {enableLocal: true}});
      this.log(LoggingLevel.debug, ['Log', 'Local'], true, 'Local logging has been enabled.');
    }
  }

  purgeLocal() {
    if (this.localLog$$) {
      this.localLog$$.complete();
    }
    this.localLog$$ = new BehaviorSubject<LogEntry[]>([]);
  }

  private prependLocal(level: LoggingLevel, tags: string[], ...messages: any[]) {
    if (this.localEnabled$$.getValue()) {
      let entries = this.localLog$$.getValue();
      const newEntries = messages.map(message => ({timestamp: new Date(), level, tags, message})).reverse();
      entries.unshift(...newEntries);
      entries = entries.length > this.maxEntryCount ? entries.slice(0, this.maxEntryCount) : entries;
      this.localLog$$.next(entries);
    }
  }

  log(level: LoggingLevel, tags: string[], isShippable: boolean, ...messages: any[]) {
    try {
      if (isAllowedLevel(this.allowedLevel, level) && hasMessages(messages)) {
        const type = levelToType(level);
        const formattedMessages = formatMessages({type, messages, tags, isDevice: this.isDevice});
        if (isShippable) {
          shipEntries({remoteLog: this.remoteLog, type, tags, messages});
        }
        writeLog(this.isDevice, level, formattedMessages);

        this.prependLocal(level, tags, ...messages);
      }
    } catch (err) {
      console.error('[WIC] [v5] [Log] Error writing log entry: ', err);
    }
  }

  trace(tags: string[], ...message: any[]) {
    this.log(LoggingLevel.trace, tags, true, ...message);
  }

  debug(tags: string[], ...message: any[]) {
    this.log(LoggingLevel.debug, tags, true, ...message);
  }

  info(tags: string[], ...message: any[]) {
    this.log(LoggingLevel.info, tags, true, ...message);
  }

  warn(tags: string[], ...message: any[]) {
    this.log(LoggingLevel.warn, tags, true, ...message);
  }

  error(tags: string[], ...message: any[]) {
    this.log(LoggingLevel.error, tags, true, ...message);
  }
}

@Injectable()
export class StaticLog {
  static log: LogService;

  constructor(log: LogService) {
    StaticLog.log = log;
  }
}
