<ion-header>
  <ion-toolbar color="green">
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(null, 'cancel', 'calc-benefit-balance-modal')">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'calculators.benefitBalanceTitle' | transloco}}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-list-header>
      <ion-label>{{'calculators.balanceHeader' | transloco}}</ion-label>
    </ion-list-header>
  </ion-list>
  <ion-item *ngIf="balance.balance" lines="none">
    <ion-label *ngIf="balance.categoryId === 19 || balance.categoryId === 97">{{'calculators.previousBalanceLabel' | transloco}} {{balance.balance | currency}} </ion-label>
    <ion-label *ngIf="balance.categoryId !== 19 && balance.categoryId !== 97">{{'calculators.previousBalanceLabel' | transloco}} {{balance.balance}} </ion-label>
  </ion-item>
  <ion-item *ngIf="balance" color="green" class="ion-padding">
    <ion-label>{{'calculators.balanceLabel' | transloco}}</ion-label>
    <ion-input
            *ngIf="balance.categoryId === 19 || balance.categoryId === 97"
            type="currency"
            [formControl]="form"
            placeholder="{{'calculators.balanceEnterPrompt' | transloco}}"
    ></ion-input>
    <ion-input
            *ngIf="balance.categoryId !== 19 && balance.categoryId !== 97"
            type="number"
            [formControl]="form"
            placeholder="{{'calculators.balanceEnterPrompt' | transloco}}"
    ></ion-input>
  </ion-item>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button color="green" size="large" strong="true" expand="block" (click)="submit()">
      <fa-icon icon="times" class="pr-5"></fa-icon>
      {{'calculators.setBalanceSubmitButton' | transloco}}
    </ion-button>
  </ion-toolbar>
</ion-footer>
