<ion-card class="shopping-list-item-group">
  <ng-container *ngIf="!isProduce || isMobileOrder; else produceDisplay">
    <ion-item lines="none" class="item-group-header" color="light">
      <ion-label>
        <h1>{{ benefit.subCategory }}</h1>
        <p *ngIf="!isProduce">{{ benefit.availableQuantity }} {{ benefit.uom }}</p>
        <p *ngIf="isProduce">{{ benefit.availableQuantity | currency }}</p>
      </ion-label>

      <ion-avatar slot="end">
        <ion-img [src]="benefit.config?.image"></ion-img>
      </ion-avatar>
    </ion-item>
    <ng-container *ngFor="let item of benefit.shoppingListItems; trackBy: trackByShoppingListItem">
      <ion-item [lines]="editingId === item.id ? 'none' : 'inset'">
        <ion-checkbox
          *ngIf="!isMobileOrder"
          slot="start"
          [checked]="item.checkedOff"
          (ionChange)="checkBoxChanged($event, item)"
        ></ion-checkbox>
        <ion-label class="ion-text-wrap">
          <p>
            <span [ngClass]="{ 'checked-off': item.checkedOff }">{{ item.description }}</span>
          </p>

          <ng-container *ngIf="editingId !== item.id">
            <h3>
              <span [ngClass]="{ 'checked-off': item.checkedOff }" *ngIf="!isProduce; else produceText">
                {{ item.purchaseQuantity }} x {{ item.units }} {{ item.uom }}
              </span>

            </h3>
          </ng-container>
          <h3 *ngIf="editingId === item.id">
            <span [ngClass]="{ 'checked-off': item.checkedOff }" *ngIf="!isProduce; else produceText">
              {{ item.units }} {{ item.uom }}
            </span>
          </h3>

          <ng-template #produceText>
            <p class="text-muted fw-600">
              <span *ngIf="item.salePrice && item.salePrice > 0; else normalPrice">
                <ion-text>{{ item.purchaseQuantity }} x </ion-text>
                <ion-text class="original-price">{{ item.price | currency }}</ion-text>
                <ion-text color="green"> {{ item.salePrice | currency }}</ion-text>
              </span>
              <ng-template #normalPrice>
                <span>{{ item.purchaseQuantity }} x {{ item.price | currency }}</span>
              </ng-template>
            </p>
          </ng-template>
        </ion-label>

        <ion-button *ngIf="!editingId" slot="end" (click)="edit.emit(item.id)" fill="clear">
          <ion-icon name="pencil" slot="icon-only"></ion-icon>
        </ion-button>

        <div
          *ngIf="editingId === item.id"
          slot="end"
          class="ion-justify-content-between quantity-adjustment"
        >
          <ion-button
            color="secondary"
            size="small"
            (click)="form.decrement()"
            [disabled]="!form.canDecrement"
          >
            <ion-icon name="remove" slot="icon-only"></ion-icon>
          </ion-button>
          <ion-label class="ion-text-center">
            {{ form.value }}
          </ion-label>
          <ion-button
            color="secondary"
            size="small"
            (click)="form.increment()"
            [disabled]="!form.canIncrement"
          >
            <ion-icon name="add" slot="icon-only"></ion-icon>
          </ion-button>
        </div>
      </ion-item>

      <ng-container *ngIf="item.substitutions?.length">
        <ion-item *ngFor="let sub of item.substitutions" class="substitution-item" lines="inset">
          <ion-button slot="start" fill="clear" shape="round" color="medium">
            <ion-icon slot="icon-only" name="swap-horizontal" size="small"></ion-icon>
          </ion-button>
          <ion-label class="ion-text-wrap">
            <p>{{ sub.description }}</p>
            <h3 *ngIf="!isProduce; else produceSubText">{{ sub.purchaseQuantity }} x {{ sub.units }} {{ sub.uom }}</h3>
            <ng-template #produceSubText>
              <h3>{{ sub.purchaseQuantity }} x {{ sub.price | currency }}</h3>
            </ng-template>
          </ion-label>

          <ion-button slot="end" (click)="removeSubstitution.emit(sub.id)" fill="clear" color="danger">
            <ion-icon name="trash" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-item>
      </ng-container>

      <ng-container *ngIf="benefit.substitutionConfig.substitutionType === 1 && item.substitutions?.length < 2">
        <ion-item class="substitution-item" lines="inset" (click)="addSubstitution.emit(item)">
          <ion-label>
            {{ 'mobileOrdering.addSubstitution' | transloco }}
          </ion-label>
          <ion-button slot="end" fill="clear" shape="round" color="primary">
            <ion-icon slot="icon-only" name="swap-horizontal"></ion-icon>
          </ion-button>
        </ion-item>
      </ng-container>

      <ng-container *ngIf="editingId === item.id">
        <ion-item>
          <ion-button
            (click)="save.emit(form.value)"
            fill="clear"
            color="success"
            [disabled]="form.invalid"
          >
            {{ 'shoppingListsView.saveQuantityEdit' | transloco }}
          </ion-button>

          <ion-button slot="end" fill="clear" color="danger" (click)="remove.emit(item.id)">
            {{ 'shoppingListsView.removeItem' | transloco }}
          </ion-button>
        </ion-item>
      </ng-container>
    </ng-container>

    <ion-item *ngIf="benefit | isBenefitFilled : true" lines="none">
      <ion-label color="danger" class="ion-text-wrap">
        {{ 'shoppingListsView.tooManyItemsWarning' | transloco }}
      </ion-label>
    </ion-item>
    <ion-list-header class="benefit-list-subtotal">
      <span *ngIf="!isProduce">{{ benefit | benefitShoppingListTotal }} {{ benefit.uom }}</span>
      <span *ngIf="isProduce">{{ benefit | benefitShoppingListTotal | currency }}</span>
      <ion-button
        *ngIf="benefit.substitutionConfig?.substitutionType !== 1"
        shape="round"
        fill="clear"
        [color]="benefit.substitutionConfig?.substitutionType === 0 ? 'warning' : 'dark'"
        class="substitution-info-button"
        (click)="showNonSubInfo.emit(benefit.substitutionConfig?.substitutionType)"
      >
        <ion-icon
          [name]="
            benefit.substitutionConfig?.substitutionType === 0 ? 'warning' : 'swap-horizontal'
          "
        ></ion-icon>
      </ion-button>
      <span class="remaining-balance" *ngIf="!isProduce">
        ({{ benefit.availableQuantity - (benefit | benefitShoppingListTotal) }} {{ benefit.uom }})
      </span>
      <span class="remaining-balance" *ngIf="isProduce">
        ({{ (benefit.availableQuantity - (benefit | benefitShoppingListTotal)) | currency }})
      </span>
    </ion-list-header>
  </ng-container>

  <ng-template #produceDisplay>
    <ion-item lines="none" class="item-group-header" color="light">
      <ion-label>
        <h1>{{ benefit.subCategory }}</h1>
        <p>${{ benefit.availableQuantity | formatQuantity }}</p>
      </ion-label>

      <ion-avatar *ngIf="benefit.config?.image" slot="end">
        <ion-img [src]="benefit.config?.image"></ion-img>
      </ion-avatar>
    </ion-item>
    <ion-item *ngFor="let item of benefit.shoppingListItems; trackBy: trackByShoppingListItem">
      <ion-checkbox
        *ngIf="!isMobileOrder"
        slot="start"
        [checked]="item.checkedOff"
        (ionChange)="checkBoxChanged($event, item)"
      ></ion-checkbox>
      <ion-label class="ion-text-wrap">
        <h3>
          <span [ngClass]="{ 'checked-off': item.checkedOff }">{{ item.description }}</span>
        </h3>
        <!--        <h3>-->
        <!--          {{ item.purchaseQuantity | currency }}-->
        <!--        </h3>-->
      </ion-label>

      <ion-button slot="end" (click)="remove.emit(item.id)" fill="clear" color="danger">
        <ion-icon name="trash" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-item>
  </ng-template>
</ion-card>
