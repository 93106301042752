import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoMissingHandler, TranslocoMissingHandlerData } from '@ngneat/transloco';

@Injectable()
export class LocalMissingTranslationHandler implements TranslocoMissingHandler {
  private embeddedEN = null;

  constructor(http: HttpClient) {
    http.get('/assets/i18n/en.json').subscribe(translations =>
      this.embeddedEN = translations
    );
  }

  handle(key: string, config: TranslocoMissingHandlerData): any {
    try {
      if (this.embeddedEN) {
        const [group, childKey] = key.split('.');
        const value = group === key ? this.embeddedEN[key] : this.embeddedEN[group][childKey];
        if (value) {
          return value;
        }
      }
    } catch {
      if (config.missingHandler.logMissingKey && !config.prodMode) {
        console.warn('Failed to find embedded english replacement for missing key.');
      }
    }

    if (config.missingHandler.logMissingKey && !config.prodMode) {
      const msg = `Missing translation for '${key}'`;
      console.warn('%c ' + msg, 'font-size: 12px; color: red');
    }

    return key;
  }
}
