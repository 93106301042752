import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class VersionHeaderInterceptor implements HttpInterceptor {
  version = '5.x';

  constructor(private readonly appVersion: AppVersion) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return Capacitor.isNativePlatform() ? forkJoin([
      this.appVersion.getVersionNumber(),
      this.appVersion.getVersionCode()
    ]).pipe(
      switchMap(([ver, code]) => next.handle(req.clone({
          headers: req.headers.set('X-WICShopper-Version', ver).set('X-WICShopper-VersionCode', code + '')
        }))
      )
    ) : next.handle(req.clone({
      headers: req.headers.set('X-WICShopper-Version', this.version).set('X-WICShopper-VersionCode', '')
    }));
  }
}
