import { animate, animation, sequence, style, transition, trigger, useAnimation } from '@angular/animations';

export const fadeIn = (duration: string) =>
  animation([
    style({
      // transform: 'translateY(100%)',
      opacity: 0 }),
    sequence([animate(duration, style({
      // transform: 'translateY(0)',
      opacity: 1 }))]),
  ]);

export const fadeOut = (duration: string) =>
  animation([
    style({
      // transform: 'translateY(0)',
      opacity: 1 }),
    sequence([animate(duration, style({
      // transform: 'translateY(-100%)',
      opacity: 0 }))]),
  ]);

export const fade = (duration: string = '500ms') =>
  trigger('fade', [
    transition(':enter', [useAnimation(fadeIn(duration))]),
    transition(':leave', [useAnimation(fadeOut(duration))]),
  ]);
