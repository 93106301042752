import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import merge from 'merge-anything';
import { from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

import { LogService } from '~core/services/log.service';

const TAGS = ['Service', 'CloudSettings'];
const LS_SETTINGS_KEY = 'wic::cloud_settings';

export const mergeSettings = (settings, currentSettings, overwrite = false) =>
  overwrite
    ? Object.keys({...currentSettings, ...settings}).reduce((merged, key) =>
      ({...merged, [key]: settings[key] !== undefined ? settings[key] : currentSettings[key]})
    , currentSettings)
    : !!settings
    ? merge(currentSettings, settings)
    : currentSettings;

export const getSettings = () =>
  JSON.parse(localStorage.getItem(LS_SETTINGS_KEY) || '{}');

export const setSettings = (settings) =>
  localStorage.setItem(LS_SETTINGS_KEY, JSON.stringify(settings));

export const updateLocalSettings = (settings, overwrite = false) =>
  setSettings(mergeSettings(settings, getSettings() || {}, overwrite));

export const logError = (log: LogService, message: string) => error =>
  log.error(TAGS, message, error);

export const STORAGE_CLOUD_KEY = 'wic::cloud_settings';

@Injectable()
export class CloudSettingsService {
  private restored$$ = new Subject();
  private loaded$$ = new ReplaySubject(1);
  private saved$$ = new ReplaySubject(1);

  private savedSettingsQueue$$ = new Subject();

  get exists$(): Observable<boolean> {
    return from(this.storage.get(STORAGE_CLOUD_KEY)).pipe(
      map(result => !!result)
    );
  }

  get restored$(): Observable<any> {
    return this.restored$$.asObservable();
  }

  get loaded$(): Observable<any> {
    return this.loaded$$.asObservable();
  }

  get saved$(): Observable<any> {
    return this.saved$$.asObservable();
  }

  constructor(private storage: Storage, private log: LogService, private platform: Platform) {
    storage.create().then(() => {
      this.log.info(TAGS, 'Cloud settings have been restored from cordova storage.');
      this.restored$$.next(null);
    });

    // NOTE: This queueing approach is critical to ensuring settings save, fully, in the proper order, without
    //   subsequent events overriding later (and thus wiping out previously saved settings that are still
    //   asynchronously processing.) The use of concatMap here is ESSENTIAL to ensuring proper serialization
    //   of events.
    this.savedSettingsQueue$$.pipe(
      tap(({overwrite}) => this.log.trace(TAGS, `(sqlite) Saving cloud settings${overwrite ? ' (overwriting)' : ''}...`)),
      tap(({settings}) => this.log.trace(TAGS, `(sqlite) New settings:`, settings)),
      concatMap(({settings, overwrite}) =>
        from(storage.get(STORAGE_CLOUD_KEY)).pipe(
          tap(currentSettings => this.log.trace(TAGS, '(sqlite) Loaded current cloud settings:', currentSettings)),
          map(currentSettings => mergeSettings(settings, currentSettings, overwrite)),
          tap(mergedSettings => this.log.trace(TAGS, `(sqlite) Merged settings:`, mergedSettings)),
          concatMap(mergedSettings => from(this.storage.set(STORAGE_CLOUD_KEY, mergedSettings)).pipe(
            tap(() => this.log.trace(TAGS, `(sqlite) Saved settings:`, mergedSettings)),
            tap(() => this.saved$$.next(mergedSettings))
          ))
        )
      ),
      catchError(err => of({err}))
    ).subscribe(
      result => result.err
        ? this.log.error(TAGS, '(sqlite) Unable to save cloud settings. Error was:', result.err)
        : this.log.debug(TAGS, '(sqlite) Cloud settings saved!')
    );
  }

  private async loadCloud(): Promise<boolean> {
    try {
      this.log.trace(TAGS, '(sqlite) Loading cloud settings...');
      const settings = await this.storage.get(STORAGE_CLOUD_KEY) || {};
      this.loaded$$.next(settings);
      this.log.debug(TAGS, '(sqlite) Cloud settings loaded.', settings);
      return true;
    } catch (error) {
      this.log.error(TAGS, '(sqlite) Unable to load cloud settings. Error was:', error);
      return false;
    }
  }

  async load(): Promise<boolean> {
    return this.loadCloud();
  }

  private async saveCloud(settings: any, overwrite = false): Promise<boolean> {
    try {
      this.savedSettingsQueue$$.next({settings, overwrite});
      return true;
    } catch (error) {
      return false;
    }
  }

  async save(settings: any, overwrite = false): Promise<boolean> {
    return this.saveCloud(settings, overwrite);
  }
}
