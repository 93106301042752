<ion-header data-cy="redemptions-header">
  <ion-toolbar color="blue">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{'redemptions.contentTitle' | transloco}}</h3>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" data-cy="home-button" (click)="modal.dismiss(null, 'cancel')">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="redemptions.refreshRedemptions($any($event))">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <wic-redemption-list
    *ngIf="shoppingLists.current$ | async as shoppingList"
    [redemptions]="shoppingLists.redemptionForCurrentBenefitSubCatForVendor$ | async"
    [loading]="redemptions.isLoading$ | async"
    [offsetMonths]="redemptions.offsetMonths$ | async"
    (loadMore)="redemptions.loadOlderMonthsRedemptions()"
    (selected)="itemSelected($event, shoppingList)"
  ></wic-redemption-list>
</ion-content>

<ion-popover #warnNotCarried class="warn-item-not-carried">
  <ng-template>
    <ion-content [scrollY]="false">
      <ion-card-header>
        <ion-card-subtitle>{{ 'shoppingLists.warnItemNotCarriedHeader' | transloco }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        <ion-item lines="none">
          <ion-label class="ion-text-wrap">
            <p>{{ 'mobileOrdering.warnItemNotCarriedMessage' | transloco }}</p>
          </ion-label>
        </ion-item>

        <ion-button expand="block" (click)="popover.dismiss()">{{ 'buttons.close' | transloco }}</ion-button>
      </ion-card-content>
    </ion-content>
  </ng-template>
</ion-popover>
