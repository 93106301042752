import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createAction, createFeatureSelector, createReducer, on, props } from '@ngrx/store';

import { LocationSearchCriteria } from '../../models/location-search-criteria';
import { Office, OfficeFavorite } from './models';
import { Position } from '@capacitor/geolocation';

export interface IOfficeState extends IEntityState<Office> {
  searchCriteria: LocationSearchCriteria;
  filterCriteria?: string;
  favorites: OfficeFavorite[];
  error: any;
  location?: Position;
}

export const {
  initialState,
  facade: OfficeFacadeBase,
  actions: {
    loadAll: loadAllOffices,
    loadAllSuccess: loadAllOfficesSuccess,
    clear: clearOffices,
  },
  selectors: {
    selectAll: allOffices,
    selectLoadedAt: officesLoadedAt,
  },
} = buildState(Office, {
  searchCriteria: {
    radius: 20,
    anywhere: false,
    onlyFavorites: false,
    highlightTopPick: true,
  } as LocationSearchCriteria,
  filterCriteria: null as string,
  favorites: [] as OfficeFavorite[],
} as IOfficeState);

export const officesViewed = createAction('[Offices] Viewed');

export const configureOfficeSearch = createAction('[Offices] Configure search');

export const applyOfficeSearch = createAction(
  '[Office Search] Apply search criteria',
  props<{ criteria: LocationSearchCriteria }>()
);

export const officeDetailViewed = createAction(
  '[Offices] View detail',
  props<{ office: Office; favorites: OfficeFavorite[] }>()
);

export const officeFavoriteToggled = createAction(
  '[Offices] Favorite Toggled',
  props<{ office: Office; favorites: OfficeFavorite[] }>()
);

export const officeFavorited = createAction('[Offices] Favorited', props<{ office: Office }>());

export const officeUnfavorited = createAction('[Offices] Unfavorited', props<{ office: Office }>());

export const setOfficesFilter = createAction('[Offices] Set filter', props<{ criteria: string }>());

export const officeWebsiteViewed = createAction(
  '[Office Detail] Website viewed',
  props<{ office: Office }>()
);

export const officesLoaded = createAction('[Offices] Loaded');

export const officeLoadFailed = createAction('[Offices] Load failed', props<{ error?: any }>());

export const driveToOffice = createAction(
  '[Offices] Drive to location',
  props<{ office: Office; latitude: number; longitude: number }>()
);

export const loadIfNeeded = createAction(
  '[Offices] Load If Necessary Init',
  props<{ force: boolean }>()
);

export const requestLocationPermission = createAction(
  '[Offices] Request Location Permission'
);

export const loadOffices = createAction(
  '[Offices] Load Offices'
)

export const officeUserLocationChanged = createAction(
  '[Offices] User Location Changed',
  props<{ location: Position }>()
);

export const returnToHome = createAction(
  '[Offices] Return to Home'
);

export const resetOffices = createAction(
  '[Offices] Reset Offices'
);

export const createFavorite = (office: Office): OfficeFavorite => ({
  locationId: office.locationId,
  dateAdded: new Date().toISOString(),
});

export const isMatchingOffice = (a: OfficeFavorite) => (b: OfficeFavorite) =>
  a.locationId === b.locationId;

export const isNotMatchingOffice = (a: OfficeFavorite) => (b: OfficeFavorite) =>
  !isMatchingOffice(a)(b);

export const setSearchCriteriaInState = (state, { criteria }) => ({
  ...state,
  searchCriteria: criteria,
});

export const setFilterInState = (state, { criteria }) => ({
  ...state,
  filterCriteria: criteria,
});

export const addOfficeFavoriteToState = (state, { office }) => ({
  ...state,
  favorites: [createFavorite(office), ...state.favorites.filter(isNotMatchingOffice(office))],
});

export const removeOfficeFavoriteFromState = (state, { office }) => ({
  ...state,
  favorites: state.favorites.filter(isNotMatchingOffice(office)),
});

export const clearError = state => ({
  ...state,
  error: null,
});

export const trackOfficeError = (state, { error }: { error?: any }) => ({
  ...state,
  error: error || true,
});

export const clearOfficesState = (state) => ({
  ...state,
  searchCriteria: {
    radius: 20,
    anywhere: false,
    onlyFavorites: false,
    highlightTopPick: true,
  },
  filterCriteria: null,
  favorites: []
});

const reduce = createReducer(
  initialState,
  on(applyOfficeSearch, setSearchCriteriaInState),
  on(setOfficesFilter, setFilterInState),
  on(officeFavorited, addOfficeFavoriteToState),
  on(officeUnfavorited, removeOfficeFavoriteFromState),
  on(officesLoaded, clearError),
  on(officeLoadFailed, trackOfficeError),
  on(clearOffices, clearOfficesState),
  on(officeUserLocationChanged, (state, { location }) => ({ ...state, location }))
);

export function officeReducer(state = initialState, action: Action): IOfficeState {
  return reduce(state, action);
}

export const officesState = createFeatureSelector<IOfficeState>('office');
