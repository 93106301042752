import { Injectable } from '@angular/core';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { UniqueDeviceID } from '@awesome-cordova-plugins/unique-device-id/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import * as useragent from 'useragent-parser-js';
import { v4 as uuid } from 'uuid';
import { DeviceInfo } from '~models';
import { Device as CapDevice } from '@capacitor/device';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

// 268549e3dd13ef2a0000000000000000 -> 268549e3-dd13-ef2a-0000-000000000000
export const formatPaddedIdAsUUID = (id: string) => {
  const idArr = id.split('');
  [0,1,2,3].forEach((val) => idArr.splice(8 + (val * 5), 0, '-'));

  return idArr.join('');
};

// id: 268549e3dd13ef2a
export const androidDeviceIdToUUID = (id: string) =>
  id.length === 36 ? id : formatPaddedIdAsUUID(id.padEnd(32, '0'));

@Injectable()
export class DeviceService {
  readonly sessionId = uuid();
  private uniqueId$$ = new BehaviorSubject<string>(null);
  private ready$$ = new BehaviorSubject<boolean>(false);
  private deviceInfo$$ = new BehaviorSubject<DeviceInfo>(null);

  get uniqueId$(): Observable<string> {
    return this.uniqueId$$.asObservable();
  }

  get uniqueId(): string {
    return this.uniqueId$$.getValue();
  }

  get ready$(): Observable<boolean> {
    return this.ready$$.asObservable();
  }

  get deviceInfo$(): Observable<DeviceInfo> {
    return this.deviceInfo$$.asObservable();
  }

  get deviceInfo(): DeviceInfo {
    return this.deviceInfo$$.getValue();
  }

  constructor(
    platform: Platform,
    device: Device,
    uniqueDevice: UniqueDeviceID,
    appVersion: AppVersion
  ) {
    platform.ready().then(async () => {
      this.ready$$.next(true);

      const isCordova = platform.is('cordova');
      let udid = localStorage.getItem('browser_udid');
      udid = isCordova ? await uniqueDevice.get() : udid || uuid();
      const version = isCordova ? await appVersion.getVersionNumber() : '5.x';
      if (!isCordova) {
        localStorage.setItem('browser_udid', udid);
      }

      const ua = useragent.parse(window.navigator.userAgent);

      // udid (uniqueDevice.get()) is consistent on iOS
      // uuid (device.uuid) is consistent on Android
      const uniqueId = platform.is('android') && !platform.is('mobileweb')
        ? androidDeviceIdToUUID(device.uuid)
        : udid;
      this.uniqueId$$.next(uniqueId);

      this.deviceInfo$$.next(isCordova ? {
        cordova: device.cordova || 'n/a',
        model: device.model || ua.source,
        platform: device.platform || ua.platform,
        udid: uniqueId,
        uuid: device.uuid,
        version: device.version || ua.version,
        manufacturer: device.manufacturer || ua.browser,
        isVirtual: device.isVirtual || false,
        serial: device.serial,
        appVersion: version
      } : {
        cordova: 'n/a',
        model: ua.source,
        platform: ua.platform,
        udid,
        uuid: undefined,
        version: ua.version,
        manufacturer: ua.browser,
        isVirtual: false,
        serial: undefined,
        appVersion: version
      });
    });
  }

  async getDefaultLanguageInfo() {
    const {value: langCode} = await CapDevice.getLanguageCode();
    const {value: langTag} = await CapDevice.getLanguageTag();
    return { langCode, langTag }
  }
}
