import { Injectable } from '@angular/core';
import { LoadAll } from '@briebug/ngrx-auto-entity';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { add, addMinutes, format, isAfter, isBefore, isEqual, parseISO, set } from 'date-fns';
import { combineLatest, from, of, range } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  exhaustMap,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
  timeout,
  withLatestFrom
} from 'rxjs/operators';
import { DialogsService } from '~core/services/dialogs.service';
import { EmptyKey } from '~core/services/entity.service';
import { LogService } from '~core/services/log.service';
import { RatingService } from '~core/services/rating.service';

import { match } from '~jpma-wicshopper-imports-mono/utils/browser';
import { appInitialized, webLinkOpened } from '~features/app/app.actions';
import { BENEFITS_HARD_EXPIRE } from '~features/constants';
import { SoftError, Warning } from '~features/error/error.state';
import { abandonPendingRegistration, addCardRegistration } from '~features/registration/registration.actions';
import { currentProvider, isProvider } from '~features/registration/registration.selectors';
import { neverCompletes } from '../../util/rxjs-util';
import { categoriesLoaded } from '../categories/categories.state';
import { Category } from '../categories/models';
import { MightHaveError } from '../error/error.effects';
import { ErrorsService } from '../error/errors.service';
import { currentRoute, currentUrl } from '../router.selectors';

import { State } from '../state';
import { SubCategory } from '../subcategories/models';
import { subCategoriesLoaded } from '../subcategories/subcategories.state';
import { currentBenefitsCriteria, hasPendingBenefits, lastLoadedAt } from './benefits.selectors';
import { BenefitsService } from './benefits.service';
import {
  alertEmptyBenefit,
  benefitsResetComplete,
  benefitsViewed,
  checkCVVBalance,
  criteriaRestored,
  enrichBenefits,
  enrichBenefitsCompleted,
  loadBenefits,
  loadBenefitsFailure,
  loadBenefitsForCriteria,
  loadBenefitsProgress,
  loadBenefitsSuccess,
  nonActiveBenefitsLoaded,
  openCustomBenefitLink,
  resetBenefits,
  viewBenefit,
  viewBenefitItems,
  viewBenefits
} from './benefits.actions';

import { BenefitError } from './models';

const isSameOrBefore = (date, dateToCompare) =>
  isEqual(date, dateToCompare) || isBefore(date, dateToCompare);

const isSameOrAfter = (date, dateToCompare) =>
  isEqual(date, dateToCompare) || isAfter(date, dateToCompare);

const TAGS = ['Effects', 'Benefits'];

const CRITERIA_STORAGE_KEY = 'wic::benefits_criteria';

const BENEFITS_VIEWED_COUNT_STORAGE_KEY = 'wic::benefits_viewed_count';

const BENEFIT_RELOAD_TIMEOUT = 20;

@Injectable()
export class BenefitsEffects {
  constructor(
    private actions$: Actions,
    private benefits: BenefitsService,
    private dialogs: DialogsService,
    private errors: ErrorsService,
    private transloco: TranslocoService,
    private log: LogService,
    private nav: NavController,
    private storage: Storage,
    private store: Store<State>,
    private ratingService: RatingService) {
  }

  openCustomBenefitLink$ = createEffect(
    () => this.actions$.pipe(
      ofType(openCustomBenefitLink),
      map(({linkUrl}) => webLinkOpened({linkUrl}))
    )
  );

  restoreSavedCriteria$ = createEffect(
    () => this.actions$.pipe(
      ofType(appInitialized),
      switchMap(() => this.storage.get(CRITERIA_STORAGE_KEY)),
      filter(json => !!json),
      map(json => JSON.parse(json)),
      tap(criteria => this.log.debug(TAGS, `Restoring criteria for ${criteria.cardNumber}`)),
      map(criteria => criteriaRestored({criteria}))
    )
  );

  viewAll$ = createEffect(
    () => this.actions$.pipe(
      ofType(viewBenefits),
      switchMap(({pendingExpirationMode}) => from(this.nav.navigateRoot('/home')).pipe(map(() => pendingExpirationMode))),
      tap(pendingExpirationMode => this.nav.navigateForward('/benefits', {
        queryParams: {pendingExpirationMode}
      }))
    ),
    {dispatch: false}
  );

  benefitError$ = createEffect(
    () => this.actions$.pipe(
      map(action => action as MightHaveError),
      filter(({error}) => !!error),
      map(({error}) => error),
      filter(error => error instanceof BenefitError),
      tap((error) => this.errors.showBenefitErrorModal(error))
    ),
    {dispatch: false}
  );

  refreshBenefitsAfter20Minutes$ = createEffect(
    () => this.actions$.pipe(
      ofType(benefitsViewed),
      withLatestFrom(this.store.select(lastLoadedAt)),
      filter(([, loadedAt]) => isAfter(Date.now(), add(loadedAt, {minutes: BENEFIT_RELOAD_TIMEOUT}))),
      tap(([, loadedAt]) =>
        this.log.warn(TAGS, `Benefits were last loaded at ${loadedAt}, and are older than ${BENEFIT_RELOAD_TIMEOUT} minutes. Reloading...`)
      ),
      map(() => loadBenefits({criteria: null, force: true}))
    )
  );

  showPendingBenefitsAlert$ = createEffect(
    () => this.actions$.pipe(
      ofType(benefitsViewed),
      withLatestFrom(this.store.select(hasPendingBenefits)),
      filter(([, hasPending]) => hasPending),
      tap(() => this.log.warn(TAGS, `User Has Pending Benefits. Showing Warning Alert.`)),
      switchMap(() => this.dialogs.alert({
        title: this.transloco.translate('benefits.pendingBenefitsTitle'),
        message: this.transloco.translate('benefits.pendingBenefitsInfo'),
      }))
    ),
    {dispatch: false}
  );

  loadBenefits$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadBenefits),
      withLatestFrom(
        this.store.select(lastLoadedAt),
        this.store.select(isProvider)
      ),
      filter(([, , providesBenefits]) => !!providesBenefits),
      tap(([{force}, last]) => this.log.debug(TAGS, `${force ? 'Force loading' : 'Loading'} benefits, last loaded at ${last || 'never'}.`)),
      filter(([{force}, last]) => force || !last || isBefore(last, addMinutes(new Date(), -BENEFITS_HARD_EXPIRE))),
      tap(() =>
        range(0, 28).pipe(
          concatMap(count => of(count).pipe(delay(Math.random() * 200 + 250))),
          map(count => count > 30 ? 30 : count),
          takeUntil(this.actions$.pipe(
            ofType(loadBenefits, loadBenefitsSuccess, loadBenefitsFailure)
          )),
        ).subscribe(count =>
          this.store.dispatch(loadBenefitsProgress({percentage: count / 30}))
        )
      ),
      map(([{criteria}]) => criteria),
      withLatestFrom(this.store.select(currentBenefitsCriteria)),
      map(([criteria, currentCriteria]) => loadBenefitsForCriteria({criteria: !!criteria ? criteria : currentCriteria})),
    )
  );

  saveBenefitsCriteria$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadBenefitsForCriteria),
      tap(() => this.log.trace(TAGS, 'Saving benefit criteria to storage...')),
      map(({criteria}) => JSON.stringify(criteria)),
      switchMap(json => this.storage.set(CRITERIA_STORAGE_KEY, json)),
      tap({
        next: () => this.log.trace(TAGS, 'Benefit criteria saved to storage.'),
        error: err => this.log.warn(TAGS, 'An error was encountered saving benefit criteria to storage:', err)
      }),
      catchError(() => of(null))
    ),
    {dispatch: false}
  );

  loadBenefitsForCriteria$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadBenefitsForCriteria),
      filter(({criteria}) => !!criteria || !!criteria.authority || !!criteria.cardNumber),
      tap(({criteria}) => this.log.info(TAGS, 'Loading benefits for:', criteria)),
      exhaustMap(({criteria}) =>
        this.benefits.load(criteria).pipe(
          map(benefits => loadBenefitsSuccess({benefitInfo: benefits, timestamp: new Date()})),
          tap({error: err => this.log.error(TAGS, 'An error occurred while loading benefits', err)}),
          catchError(error =>
            match(
              () => ({
                isBenefitError: error instanceof BenefitError,
                status: error.status || -1,
                statusText: error.statusText || null,
                message: error.message || null
              }),
              err => [{isBenefitError: true}, of(loadBenefitsFailure({error}))],
              err => [{status: 400}, of(loadBenefitsFailure({
                error: new SoftError(
                  'Error loading benefits',
                  'Benefits Error',
                  `We encountered an error while trying to load your benefits. This error may be due to incorrect settings, an
                   invalid card number, or a bug. Verify your card number (and if necessary householdId) are correct, and try again.
                   If the error persists, report the error to JPMA.`,
                )
              }))],
              err => [{status: 504, statusText: 'Gateway Timeout'}, of(loadBenefitsFailure({
                error: new Warning('Unable to load benefits at this time.')
              }))],
              err => [{status: match.gte(500), statusText: 'Unknown Error'}, of(loadBenefitsFailure({error}))],
              err => [match.anything, of(loadBenefitsFailure({
                error: new SoftError(
                  'Unexpected error loading benefits',
                  'Benefits Error',
                  `We encountered an unknown error while trying to load your benefits. This error may be temporary,
                  so try again later. If the error persists, report the error to JPMA.`,
                  error
                )
              }))],
            )
          )
        )
      )
    )
  );

  navigateBackToRegistrationOnError$ = createEffect(() => this.actions$.pipe(
    ofType(loadBenefitsFailure),
    withLatestFrom(this.store.select(currentUrl)),
    tap(([, route]) => console.log(route)),
    filter(([, url]) => url.endsWith('/post-registration')),
    tap(() => this.nav.back())
  ), {dispatch: false});

  checkBenefitCardNonActive$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadBenefitsSuccess),
      tap(({benefitInfo}) => this.log.trace(TAGS, 'Benefits loaded, card non-active', benefitInfo)),
      filter(({benefitInfo}) => benefitInfo.isCardActivated === false),
      switchMap(({benefitInfo}) => this.benefits.warnNonActiveCard(benefitInfo, null)),
      neverCompletes(),
      switchMap(result => !!result
        ? [abandonPendingRegistration(), nonActiveBenefitsLoaded(), addCardRegistration()]
        : [abandonPendingRegistration(), nonActiveBenefitsLoaded()]
      )
    )
  );


  // NOTE: One would think forkJoin would be the perfect operator for the below effect,
  // however sadly, forkJoin only emits when all source observables COMPLETE...and all
  // of the observables involved here are action streams, which never complete!!!
  waitForBenefitsCatsAndSubCatsAndMerge$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadBenefitsSuccess),
      tap(({benefitInfo}) => this.log.trace(TAGS, 'Benefits loaded successfully', benefitInfo)),
      filter(({benefitInfo}) => benefitInfo.isCardActivated !== false), // Only process benefits if card is active

      switchMap(({benefitInfo, timestamp}) =>
        combineLatest([
          this.actions$.pipe(ofType(categoriesLoaded)),
          this.actions$.pipe(ofType(subCategoriesLoaded))
        ]).pipe(
          switchMap(([{categories, timestamp: catsDate}, {subCategories, timestamp: subCatsDate}]) =>
            of({benefitInfo, benesDate: timestamp, categories, catsDate, subCategories, subCatsDate})
          )
        )
      ),

      // Filter the stream so that only categories and subcategories loaded AFTER the benefits pass through:
      tap(({benesDate, catsDate, subCatsDate}) => {
        this.log.trace(TAGS, `Cats after benes? ${catsDate > benesDate}`);
        this.log.trace(TAGS, `SubCats after benes? ${subCatsDate > benesDate}`);
      }),
      filter(({benesDate, catsDate, subCatsDate}) =>
        catsDate > benesDate && subCatsDate > benesDate
      ),
      // ^-- With all of the above, we should now have properly timed and well-matched: benefits, categories & subCategories

      // Now reduce the data in the stream to just benefits, categories, and subcategories:
      map(({benefitInfo, categories, subCategories}) => ({
        benefitInfo, categories, subCategories
      })),

      // Finally, dispatch the enrichBenefits action with all of the combined data:
      map(data => enrichBenefits(data))
    )
  );

  enrichBenefits$ = createEffect(
    () => this.actions$.pipe(
      ofType(enrichBenefits),
      // Generate related metadata:
      map(data => ({
        ...data,
        metadata: {
          now: new Date(),
          startDate: parseISO(data.benefitInfo.startDate),
          endDate: parseISO(data.benefitInfo.endDate),
        }
      })),
      map(data => ({
        ...data,
        metadata: {
          ...data.metadata,
          benefitStart: set(data.metadata.startDate, {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}),
          benefitEnd: set(data.metadata.endDate, {hours: 23, minutes: 59, seconds: 59, milliseconds: 999})
        }
      })),
      tap(({benefitInfo, categories, subCategories, metadata}) =>
        this.log.debug(TAGS, `Enriching benefits. IsActive: ${benefitInfo.isCardActivated
        } Starts: ${metadata.benefitStart}; Ends: ${metadata.benefitEnd}`)
      ),
      // Next, enrich the benefits with category and subcategory descriptions:
      map(({benefitInfo, categories, subCategories, metadata}) => ({
        ...benefitInfo,

        loadedAt: format(new Date(), 'MMM dd @ h:mm:ss a'),
        formattedStart: format(metadata.benefitStart, 'MMM dd yyyy'),
        formattedEnd: format(metadata.benefitEnd, 'MMM dd yyyy'),
        isActive: benefitInfo.startDate !== '20000101',
        isExpired: isAfter(metadata.now, metadata.benefitEnd),
        hasCurrent:
          (!!benefitInfo.benefits && !!benefitInfo.benefits.length) ||
          (
            isBefore(metadata.startDate, metadata.endDate) &&
            isSameOrBefore(metadata.benefitStart, metadata.now) &&
            isSameOrAfter(metadata.benefitEnd, metadata.now)
          ),

        benefits: benefitInfo.benefits
          .map(benefit => ({
            ...benefit,

            category: categories.find(cat => +cat.categoryId === +benefit.categoryId),
            subCategory: subCategories.find(subCat =>
              +subCat.categoryId === +benefit.categoryId &&
              +subCat.subCategoryId === +benefit.subCategoryId
            )
          }))
          .map(benefit => ({
            ...benefit,

            hasCalc: [5, 16, 19].includes(benefit.categoryId),
            subCategory: benefit.subCategory ? benefit.subCategory.description : '<UNKNOWN>',
            uom: benefit.subCategory ? benefit.subCategory.uom : '',
            packageSize: benefit.subCategory ? benefit.subCategory.packageSize : 100,
            category: benefit.category ? benefit.category.description : '<UNKNOWN>'
          }))
      })),
      tap(enrichedBenefits =>
        this.log.debug(TAGS, `Benefits enriched. IsExpired: ${enrichedBenefits.isExpired
        } IsActive: ${enrichedBenefits.isActive}; HasCurrent: ${enrichedBenefits.hasCurrent}; LoadedAt: ${enrichedBenefits.loadedAt}`)
      ),
      // Finally, dispatch the enrichBenefitsComplete action with the enriched benefits object:
      map(enrichedBenefits => enrichBenefitsCompleted({enrichedBenefits}))
    )
  );

  loadCategoriesForBenefits$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadBenefitsSuccess),
      filter(({benefitInfo}) => benefitInfo.isCardActivated !== false), // Only continue if card is active
      withLatestFrom(this.store.select(currentProvider)),
      tap(([, authority]) => this.log.debug(TAGS, `Loading categories for authority ${authority.name}`)),
      map(([, {id}]) => new LoadAll(Category, {
        parents: {
          authorities: id,
          apl: EmptyKey
        }
      }))
    )
  );

  resetBenefits$ = createEffect(
    () => this.actions$.pipe(
      ofType(resetBenefits),
      map(() => benefitsResetComplete())
    )
  );

  clearSavedCriteria$ = createEffect(
    () => this.actions$.pipe(
      ofType(resetBenefits),
      tap(() => this.storage.remove(CRITERIA_STORAGE_KEY))
    ),
    {dispatch: false}
  );

  // Originally was only [{uom: '$$$'}, of(checkCVVBalance({benefit}))],
  // bene => [match.some([{categoryId: 19 }]), of(checkCVVBalance({benefit}))],
  // If checking {categoryId: 19} causes an issue, use ^ to check both
  viewBenefit$ = createEffect(
    () => this.actions$.pipe(
      ofType(viewBenefit),
      throttleTime(700),
      exhaustMap(({benefit}) =>
        match(() => benefit,
          bene => [{categoryId: 19 }, of(checkCVVBalance({benefit}))],
          bene => [{categoryId: 97 }, of(checkCVVBalance({benefit}))],
          bene => [{availableQuantity: match.gt(0)}, of(viewBenefitItems({benefit}))],
          bene => [match.anything, of(alertEmptyBenefit({benefit}))]
        )
      )
    )
  );

  checkCVVBalance$ = createEffect(
    () => this.actions$.pipe(
      ofType(checkCVVBalance),
      exhaustMap(({benefit}) => this.dialogs.alert({
        title: this.transloco.translate('benefits.cvvBenefitTitle'),
        message: this.transloco.translate('benefits.cvvBenefitMessage', {qty: benefit.availableQuantity})
      }).pipe(
        timeout(10000),
        catchError(() => of(null))
      ))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  viewBenefitItems$ = createEffect(
    () => this.actions$.pipe(
      ofType(viewBenefitItems),
      tap(() => this.nav.navigateForward('/items'))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  alertEmptyBenefit$ = createEffect(
    () => this.actions$.pipe(
      ofType(alertEmptyBenefit),
      exhaustMap(() => this.dialogs.alert({
        title: this.transloco.translate('benefits.noMoreTitle'),
        message: this.transloco.translate('benefits.noMoreMessage')
      }).pipe(
        timeout(10000),
        catchError(() => of(null))
      ))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  promptForAppRating$ = createEffect(
    () => this.actions$.pipe(
      ofType(benefitsViewed),
      tap(() => this.log.trace(TAGS, 'Checking current benefits viewed count...')),
      switchMap(() => this.storage.get(BENEFITS_VIEWED_COUNT_STORAGE_KEY)),
      tap(() => this.log.trace(TAGS, 'Updating benefits viewed count in storage...')),
      switchMap(count =>
        !!count || (count === 0)
          ? this.storage.set(BENEFITS_VIEWED_COUNT_STORAGE_KEY, count + 1)
          : this.storage.set(BENEFITS_VIEWED_COUNT_STORAGE_KEY, 0)),
      tap({
        next: count => this.log.trace(TAGS, `Benefits viewed count set to ${count}`),
        error: err => this.log.warn(TAGS, 'An error occurred when saving benefits viewed count to storage: ', err)
      }),
      filter(count => count === 3),
      tap(() => this.log.trace(TAGS, 'Prompting For App Rating...')),
      switchMap(count =>
        from(this.ratingService.requestRating()).pipe(
          tap(
            () => this.log.trace(TAGS, 'Rating was requested. Resetting benefits viewed count...'),
            err => this.log.error(TAGS, `Error requesting app rating: ${err.message}`, err)
          ),
          switchMap(() => this.storage.set(BENEFITS_VIEWED_COUNT_STORAGE_KEY, 0)),
        )
      ),
      catchError(err => of(null).pipe(
        tap(
          () => this.log.error(TAGS, `Unknown App Rate Error: ${err.message}`, err)
        ),
      ))
    ),
    {dispatch: false}
  );
}
