<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'vendors.selectVendor' | transloco }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" (click)="modal.dismiss(null, 'cancel')">
        <ion-icon name="close-circle" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      [value]="vendors.filterCriteria$ | async"
      [placeholder]="'vendors.filterLabel' | transloco"
      (ionChange)="vendors.setFilter($event.target.value)"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false">
  <ion-fab vertical="top" horizontal="end" edge="true" slot="fixed">
    <ion-fab-button color="primary" (click)="vendors.configureSearch()">
      <ion-icon name="cog"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <div style="height: 28px"></div>

  <ion-list *ngIf="vendors.isLoading$ | async; else list" data-cy="loading">
    <ion-item>
      <ion-label>
        <h2>{{ 'vendors.loadingLabel' | transloco }}</h2>
      </ion-label>
      <ion-spinner slot="end" name="dots"></ion-spinner>
    </ion-item>
  </ion-list>

  <ng-template #list>
    <wic-vendor-list
      *ngIf="(shoppingList.isMobileOrder ? vendors.hasFilteredWithInventory$ : vendors.hasFiltered$) | async; else missing"
      [vendors]="(shoppingList.isMobileOrder ? vendors.filteredWithInventory$ : vendors.filtered$) | async"
      [favorites]="vendors.favorites$ | async"
      (selected)="modal.dismiss($event.vendor, 'dismiss')"
      (favoriteToggled)="vendors.toggleFavorite($event)"
    ></wic-vendor-list>

    <ng-template #missing>
      <wic-missing-data-warning
        *ngIf="vendors.searchCriteria$ | async as searchCriteria"
        [title]="'vendors.missingTitle' | transloco"
        [info]="
          (searchCriteria.anywhere ? 'vendors.missingAnywhereInfo' : 'vendors.missingRadiusInfo')
            | transloco
              : {
                  radius: searchCriteria.radius,
                  anywhere: searchCriteria.anywhere ? 'true' : 'false',
                  favorites: searchCriteria.onlyFavorites ? 'true' : 'false',
                  groupcode: searchCriteria.groupCodeFilter
                }
        "
        (retry)="vendors.loadAllIfNecessary()"
      >
      </wic-missing-data-warning>
    </ng-template>
  </ng-template>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>
