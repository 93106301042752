import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { asyncScheduler, combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { delay, filter, first, map, observeOn, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { GeoLocationService } from '~core/services/geo-location.service';
import { LogService } from '~core/services/log.service';
import { NativePermissionsService } from '~core/services/native-permissions.service';
import { Office, OfficeFavorite } from '~features/offices/models';
import { OfficesFacade } from '~features/offices/offices.facade';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { LocationSearchCriteria } from '../../models/location-search-criteria';
import { OfficeDetailModalComponent } from './office-detail-modal/office-detail-modal.component';
import { OfficesSearchModalComponent } from './offices-search-modal/offices-search-modal.component';

const TAGS = ['Service', 'Offices'];

@Injectable()
export class OfficesService {
  get isLoading$(): Observable<boolean> {
    return combineLatest([
      this.offices.total$,
      this.offices.error$,
      this.offices.isLoading$
    ]).pipe(
      map(([count, error, isLoading]) => (!count && !error) || isLoading)
    );
  }

  constructor(
    private modals: ModalController,
    private offices: OfficesFacade,
    private permissions: NativePermissionsService,
    private log: LogService,
    private registration: RegistrationFacade,
    private geolocation: GeoLocationService
  ) {}

  async showDetailModal(office: Office, favorites: OfficeFavorite[]): Promise<void> {
    const modal = await this.modals.create({
      component: OfficeDetailModalComponent,
      componentProps: {
        office,
        favorites
      }
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  async showSearch(criteria: LocationSearchCriteria): Promise<LocationSearchCriteria> {
    const modal = await this.modals.create({
      component: OfficesSearchModalComponent,
      componentProps: {
        criteria
      }
    });

    await modal.present();
    const {data, role} = await modal.onDidDismiss();
    if (role === 'ok') {
      return data;
    } else {
      throw role;
    }
  }
}
