import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { QuantityEditFormControl } from '../quantity-edit.form';
import { ShoppingListItemsFacade } from '../../../features/shopping-lists/shopping-list-items/shopping-list-items.facade';
import { ShoppingListsFacade } from '~features/shopping-lists/shopping-lists.facade';
import { fade } from '../../../util/animations/fade.animation';
import { IonContent } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { SubstitutionsFacade } from '~features/shopping-lists/substitutions/substitutions.facade';

@Component({
  selector: 'wic-shopping-list-view',
  templateUrl: './shopping-list-view.page.html',
  styleUrls: ['./shopping-list-view.page.scss'],
  animations: [fade()]
})
export class ShoppingListViewPage implements AfterViewInit {
  @ViewChild('addItems') addItems: ElementRef;
  @ViewChild(IonContent) ionContent: IonContent;
  observer: IntersectionObserver;
  dateFormat = 'EEEE, MMMM d, h:mm a';
  private addItemsVisible$ = new BehaviorSubject(true);
  showAddItemFab$ = this.addItemsVisible$.pipe(map( visible => !visible));

  quantityEditForm$ = this.shoppingListItems.quantityEditItem$.pipe(
    filter(id => !!id),
    withLatestFrom(this.shoppingLists.quantityEditBenefitShoppingListGroup$),
    map(([item, benefit]) => new QuantityEditFormControl(item, benefit))
  );

  constructor(
    public readonly shoppingLists: ShoppingListsFacade,
    public readonly shoppingListItems: ShoppingListItemsFacade,
    public readonly substitutions: SubstitutionsFacade
  ) {}

  ngAfterViewInit() {
    if (this.addItems && this.ionContent) {
      this.observer = new IntersectionObserver(
        (entries, observer) => {
          if (entries.length === 1) {
            this.addItemsVisible$.next(entries[0].isIntersecting);
          }
        },
        {
          root: (this.ionContent as any).el,
          threshold: 0.05,
        }
      );

      this.observer.observe(this.addItems.nativeElement);
    }
  }

  trackByCategory(index: number, subCat: { categoryId: number; subCategoryId: number }) {
    return `${subCat.categoryId}_${subCat.subCategoryId}`;
  }
}
