import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {Platform} from '@ionic/angular';
import {TranslocoService} from '@ngneat/transloco';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {combineLatest, EMPTY, of} from 'rxjs';
import {catchError, delay, distinctUntilChanged, exhaustMap, filter, first, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {LogService} from '~core/services/log.service';
import {NativePermissionsService} from '~core/services/native-permissions.service';
import { PushNotificationsService } from '~core/services/push-notifications.service';
import {match} from '~jpma-wicshopper-imports-mono/utils/browser';
import {appInitialized, networkStatusChanged, openNetworkSettings} from '~features/app/app.actions';
import {
  checkNotificationPermissions,
  explainNotificationDemand,
  notificationPermissionDemanded,
} from '~features/app/permissions.actions';
import { neverCompletes, onNativePlatformOnly } from '../../util/rxjs-util';
import {NotificationsService} from '~features/notifications/notifications.service';

const TAGS = ['Effects', 'Permissions'];

@Injectable()
export class PermissionsEffects {
  constructor(
    private actions$: Actions,
    private log: LogService,
    private platform: Platform,
    private transloco: TranslocoService,
    private notifications: NotificationsService,
    private pushNotifications: PushNotificationsService,
    private permissions: NativePermissionsService) {
  }

  checkNotificationPermissions$ = createEffect(
    () =>
    this.actions$.pipe(
      ofType(checkNotificationPermissions),
      tap(() => this.log.trace(TAGS, 'Checking to see if should explain permissions')),
      switchMap(() => Promise.all([
        this.permissions.hasLocalNotificationPermission(),
        this.permissions.hasPushNotificationPermission()
      ])),
      neverCompletes(),
      switchMap(([hasLocal, hasPush]) =>
        match(
          () => !hasLocal || !hasPush,
          missing => [true, of(explainNotificationDemand())],
          missing => [false, of(notificationPermissionDemanded())]
        )
      ),
      catchError(err => of(notificationPermissionDemanded()))
    )
  );

  explainNotificationPermissions$ = createEffect(
    () => this.actions$.pipe(
      ofType(explainNotificationDemand),
      tap(() => this.log.trace(TAGS, 'Explaining notification permission requirement to user...')),
      switchMap(() => this.permissions.explainNotificationPermissions()),
      neverCompletes(),
      map(() => notificationPermissionDemanded())
    )
  );

  requestLocalNotificationPermissions$ = createEffect(
    () => this.actions$.pipe(
      ofType(notificationPermissionDemanded),
      switchMap(() => this.notifications.initialize())
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  requestPushNotificationPermission$ = createEffect(
    () => this.actions$.pipe(
      ofType(notificationPermissionDemanded),
      onNativePlatformOnly(),
      tap(() => this.log.debug(TAGS, 'Requesting permission to push notifications...')),
      // To ensure permission requests overlap correctly, otherwise one will deny the other regardless of user input
      delay(100),
      tap(() => this.log.debug(TAGS, 'Proceeding with push permission request')),
      switchMap(() => this.pushNotifications.requestPermission()),
      filter(granted => granted),
      tap(() => this.log.debug(TAGS, 'Push notifications permission granted!')),
      switchMap(() => this.pushNotifications.initialize()),
      tap(() => this.log.debug(TAGS, 'Push notifications initialized!')),
      neverCompletes(),
      catchError(err => {
        this.log.error(TAGS, 'Error getting permission to push notifications!', err);
        return EMPTY;
      })
    ),
    {dispatch: false}
  );

  checkNetworkStatus$ = createEffect(() => 
    this.actions$.pipe(
      ofType(networkStatusChanged),
      tap((status) => this.log.info(TAGS, `Network status changed to ${status.status}`)),
      distinctUntilChanged((prev, cur) => prev.status === cur.status),
      filter(({status}) => !status),
      exhaustMap(() => this.permissions.explainNetworkStatus('misty-moss', 'secondary')),
      filter(({role}) => role !== 'cancel'),
      map(() => openNetworkSettings())

    )
  );

  openNetworkSettings$ = createEffect(() => 
    this.actions$.pipe(
      ofType(openNetworkSettings),
      switchMap(() => this.permissions.openNetworkSettings())
    ),
    { dispatch: false }
  )
  
}
