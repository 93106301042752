import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { currentProviderId } from '~features/registration/registration.selectors';
import { MobileOrder } from '~features/shopping-lists/mobile-orders/mobile-order.entity';
import { createMobileOrder } from '~features/shopping-lists/mobile-orders/mobile-orders.state';
import {
  filteredBenefitsWithShoppingListItems,
} from '~features/shopping-lists/shopping-lists.selectors';
import { currentShoppingList } from '~features/shopping-lists/shopping-lists.state';
import { uuid } from '../../../util/func-util';
import {
  allItemsFoundForNewVendor,
  unavailableItemsConfirmed,
} from '../shopping-list-items/shopping-list-items.actions';
import { ShoppingListsUIService } from '../shopping-lists-ui.service';
import {
  changeOrderDate,
  setVendorTimeSlotForOrder,
  submitMobileOrder,
} from '../shopping-lists.actions';

@Injectable()
export class MobileOrderEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly shoppingListsUI: ShoppingListsUIService
  ) {}

  selectVendorTimeSlot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(allItemsFoundForNewVendor, unavailableItemsConfirmed, changeOrderDate),
      switchMap(() => this.shoppingListsUI.openSelectVendorTimeSlotModal()),
      filter(({ role }) => role !== 'cancel'),
      map(({ data }) =>
        setVendorTimeSlotForOrder({ vendorTimeSlot: data.timeSlot, orderDate: data.dayOption.date })
      )
    )
  );

  submitMobileOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitMobileOrder),
      withLatestFrom(
        this.store.select(filteredBenefitsWithShoppingListItems),
        this.store.select(currentShoppingList),
        this.store.select(currentProviderId)
      ),
      switchMap(([action, benefits, shoppingList, authorityId]) =>
        of({
          id: uuid(),
          authorityId: +authorityId,
          exceptionId: +shoppingList.vendorId,
          cardNumber: shoppingList.cardNumber,
          orderDate: shoppingList.orderDate,
        }).pipe(
          map(
            (mobileOrder: Omit<MobileOrder, 'benefitGroups'>): MobileOrder => ({
              ...mobileOrder,
              benefitGroups: benefits.map(benefit => ({
                // id: uuid(),
                categoryId: benefit.categoryId,
                subCategoryId: benefit.subCategoryId,
                mobileOrderId: mobileOrder.id,
                availableQuantity: benefit.availableQuantity,
                beginDate: benefit.beginDate,
                endDate: benefit.endDate,
                items: benefit.shoppingListItems,
              })),
            })
          )
        )
      ),
      map(entity =>
        createMobileOrder({
          entity,
          criteria: {
            parents: {
              authorities: entity.authorityId,
              vendors: entity.exceptionId,
            },
          },
        })
      )
    )
  );
}
