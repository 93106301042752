<ion-card *ngIf="showNoProductsWarning && !!chunkedProducts && !chunkedProducts.length && !hasFilterCriteria">
  <ion-card-header class="golden">
    <ion-card-subtitle>{{ 'common.missingData' | transloco }}</ion-card-subtitle>
    <ion-card-title>
      {{ 'product-list.noProductsTitle' | transloco }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <p>
            {{
              (totalCount ? 'product-list.noProductsAvailable' : 'product-list.noProductsInfo')
                | transloco
            }}
          </p>
        </ion-col>
      </ion-row>
      <ion-row class="pt-20" *ngIf="!totalCount">
        <ion-col>
          <ion-button expand="block" (click)="retry.emit(benefit)">
            <ion-icon name="refresh" class="pr-5"></ion-icon>
            {{ 'buttons.retry' | transloco }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>

<ion-card *ngIf="!!chunkedProducts && !chunkedProducts.length && hasFilterCriteria">
  <ion-card-header class="golden">
    <ion-card-title>
      {{ 'product-list.noProductsFoundTitle' | transloco }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <p>{{ 'product-list.noProductsFoundInfo' | transloco }}</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>

<ion-list *ngIf="!hideTitle && !!chunkedProducts && (!!chunkedProducts.length || hasFilterCriteria)">
  <ion-list-header>
    <span class="text-all-caps">{{ 'product-list.listHeader' | transloco }}</span>
  </ion-list-header>
</ion-list>
<!--[approxItemHeight]="80"-->

<cdk-virtual-scroll-viewport
  itemSize="270"
  class="ion-content-scroll-host"
>
  <div *cdkVirtualFor="let productGroup of chunkedProducts" class="product-list">
    <ion-card *ngFor="let product of productGroup" data-cy="product-list-item" class="product-item">
      <ion-card-content>
        <wic-product-image size="large" [product]="product"></wic-product-image>
        <ion-fab vertical="bottom" horizontal="end">
          <ng-container *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97 && (getAllowedAmount(benefit, product) > 0); else allowedButton">
            <ion-fab-button color="independence" (click)="selected.emit(product)">
              <ion-icon name="add"></ion-icon>
            </ion-fab-button>
          </ng-container>

          <ng-template #allowedButton>
            <ion-fab-button color="independence" (click)="presentAlert()">
              <ion-icon name="add"></ion-icon>
            </ion-fab-button>
          </ng-template>

        </ion-fab>
      </ion-card-content>
      <ion-card-header class="pd-10">
        <ion-card-title>{{ product.description }}</ion-card-title>
        <ion-card-subtitle class="addtl-info">
          <p
            class="text-muted"
            *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97; else produceDisplay"
          >
            {{ product.size / product.packageSize }} {{ product.uom }}
          </p>
          <ng-template #produceDisplay>
            <p class="text-muted">
              <span *ngIf="product.salePrice && product.salePrice > 0; else normalPrice">
                <ion-text class="original-price"
                  >{{ product.price | currency }} {{ product.costUnit }}</ion-text
                >
                <ion-text color="green">
                  {{ product.salePrice | currency }} {{ product.costUnit }}
                </ion-text>
              </span>
              <ng-template #normalPrice>
                <span>{{ product.price | currency }} {{ product.costUnit }}</span>
              </ng-template>
            </p>
            <p class="text-muted" *ngIf="product.costUnit === 'LB'">
              <span *ngIf="product.salePrice && product.salePrice > 0; else normalParPrice">
                <ion-text color="blue">
                  {{ 'buttons.about' | transloco }}
                  {{ product.parWeight * product.salePrice | currency }}
                  {{ 'product-list.eachLabel' | transloco }}
                </ion-text>
              </span>
              <ng-template #normalParPrice>
                <span>
                  <ion-text color="blue">
                    {{ 'buttons.about' | transloco }}
                    {{ product.parWeight * product.price | currency }} {{ product.costUnit }}
                  </ion-text>
                </span>
              </ng-template>
            </p>
          </ng-template>
          <p
            class="allowed"
            *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97"
            [ngClass]="getAllowedStatus(benefit, product)"
          >
            {{ benefit.availableQuantity - (benefit | benefitShoppingListTotal) }}
            {{ benefit.uom }} {{ 'product-list.allowedLabel' | transloco }}
          </p>
        </ion-card-subtitle>
      </ion-card-header>
    </ion-card>
  </div>
</cdk-virtual-scroll-viewport>

<!--<ion-virtual-scroll [items]="products">-->
<!--  <ion-card *virtualItem="let product" (click)="selected.emit(product)" data-cy="product-list-item" class="product-item">-->
<!--    <ion-card-content><wic-product-image size="large" [product]="product"></wic-product-image></ion-card-content>-->
<!--    <ion-card-header>-->
<!--      <ion-card-title>{{product.description}}</ion-card-title>-->
<!--      <ion-card-subtitle class="addtl-info">-->
<!--        <span class="text-muted">{{ product.size / product.packageSize }} {{ product.uom }}</span>-->
<!--        <span class="allowed" [ngClass]="getAllowedStatus(benefit, product)">{{benefit.quantity | computeAllowed:product.size:product.packageSize}}-{{'product-list.allowedLabel' | transloco}}</span>-->
<!--      </ion-card-subtitle>-->
<!--    </ion-card-header>-->
<!--  </ion-card>-->
<!--</ion-virtual-scroll>-->
