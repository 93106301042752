import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { selectDay } from '~features/vendor-time-slots/vendor-time-slots.actions';
import { TimeSlotDaysToDayMap, VendorTimeSlot } from './vendor-time-slot.entity';
import { TimeSlotDayOption } from '~features/vendor-time-slots/time-slot-day-option.model';
import { changeOrderDate } from '~features/shopping-lists/shopping-lists.actions';

export interface VendorTimeSlotsState extends IEntityState<VendorTimeSlot> {
  selectedDay?: TimeSlotDayOption;
}

export const {
  initialState: initialVendorTimeSlotState,
  facade: VendorTimeSlotFacadeBase,
  actions: { loadAll: loadAllVendorTimeSlots, create: createVendorTimeSlot, deselect: deselectVendorTimeSlot },
  selectors: { selectAll: allVendorTimeSlots, selectCurrentEntity: currentVendorTimeSlot },
} = buildState(VendorTimeSlot, {} as VendorTimeSlotsState);

export const vendorTimeSlotsReducer = createReducer(
  initialVendorTimeSlotState,
  on(selectDay, (state, { day }) => ({ ...state, selectedDay: day })),
  on(deselectVendorTimeSlot, (state) => ({ ...state, selectedDay: undefined })),
);

export const VENDOR_TIME_SLOTS_FEATURE_KEY = 'vendorTimeSlot';

export const vendorTimeSlotState = createFeatureSelector<VendorTimeSlotsState>(
  VENDOR_TIME_SLOTS_FEATURE_KEY
);
