import {
  defer,
  from,
  iif,
  MonoTypeOperatorFunction,
  ObservableInput,
  of, OperatorFunction,
  pipe,
  retryWhen,
  throwError,
  timer,
} from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

export const delayedRetry = <T>(delay = 1000, maxRetries = 3): MonoTypeOperatorFunction<T> =>
  retryWhen(errors =>
    errors.pipe(
      mergeMap(error =>
        maxRetries--
          ? timer(delay)
          : throwError(() => ({ message: `Failed after max retries ${3}`, lastError: error }))
      )
    )
  );

export const switchTap = <T>(project: (val: T) => ObservableInput<any>): MonoTypeOperatorFunction<T> =>
  switchMap((val: T) => from(project(val)).pipe(
    map(() => val)
  ))

export const switchMapIf = <T, U>(condition: (val: T) => boolean, project: (val: T) => ObservableInput<U>): OperatorFunction<T, T | U> =>
  switchMap((val: T) =>
    iif(() => condition(val),
      defer(() => project(val)),
      of(val)
    )
  )

export const throwErrorIf = <T>(
  condition: (value?: T) => boolean,
  errorFactory: (value?: T) => any
): MonoTypeOperatorFunction<T> =>
  tap((value: T) => {
    if (condition(value)) {
      throw errorFactory(value);
    }
  });

export const tapErrorAsync = <T, U extends Error>(
  fn: (err: U) => ObservableInput<any>
): MonoTypeOperatorFunction<T> =>
  catchError((error: U) => from(fn(error)).pipe(switchMap(() => throwError(() => error))));

export const tapWhenChanged = <T, U>(
  selector: (curr: T) => U,
  next: (value: U) => any,
  comparer?: (prev: U, curr: U) => boolean
): MonoTypeOperatorFunction<T> => {
  let prev: U = null;
  return tap((curr: T) => {
    const value = selector(curr);
    const matches = comparer ? comparer(prev, value) : prev === value;
    prev = matches ? value : (next(value), value);
  });
};

// Catches error and tracks it, filtering error notifications from source.
export const trackError = <T, U extends any>(
  trackingMethod: (err: U) => Promise<any>,
  logger?: (err: U) => any
): MonoTypeOperatorFunction<T> =>
  pipe(
    catchError((error: U) =>
      from(trackingMethod(error)).pipe(
        tap(() => logger && logger(error)),
        map(() => null)
      )
    ),
    filter((val: T) => !!val)
  );
