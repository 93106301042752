import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { defer, from, iif, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap, timeoutWith } from 'rxjs/operators';
import { WEB_CACHE_CONFIG } from '~core/interceptors/config-token';
import { WebCacheService } from '~core/interceptors/web-cache.service';
import { LogService } from '~core/services/log.service';
import { SilentError } from '~features/error/error.state';

const TAGS = ['Interceptor', 'Caching'];

export class WebCacheError extends SilentError {
  constructor(message: string, public url: string) {
    super(message);
  }
}

export interface WebCachingStrategy {
  apply(req: HttpRequest<any>, next: HttpHandler, cache: WebCacheService, config: WebCacheConfigGroup): Observable<HttpEvent<any>>;
}

@Injectable()
export class Fresh implements WebCachingStrategy {
  constructor(protected log: LogService) {
  }

  apply(req: HttpRequest<any>, next: HttpHandler, cache: WebCacheService, config: WebCacheConfigGroup): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => event instanceof HttpResponse
        ? this.log.trace(TAGS, `Retrieved entity at ${req.method} ${req.urlWithParams}...`)
        : this.log.trace(TAGS, `Non-http response received:`, event, `${HttpEventType[event.type]}`)
      ),
      tap(event => event instanceof HttpResponse
        ? this.log.trace(TAGS, `Http response received, caching! (${req.method} ${req.urlWithParams})`)
        : this.log.trace(TAGS, `Non-http response received, not caching. (${req.method} ${req.urlWithParams})`)
      ),
      tap(event => event instanceof HttpResponse
        ? cache.set(req, config, event.body)
        : false
      ),
      tap({error: err => this.log.error(TAGS, `Error encountered during request (${req.method} ${req.urlWithParams}):`, err)}),
      catchError(err =>
        err instanceof HttpErrorResponse && err.status !== 0 && err.status < 500
          ? throwError(err).pipe(tap(subErr => this.log.error(TAGS, `Forwarding http error (${req.method} ${req.urlWithParams}):`, subErr)))
          : from(cache.get(req, config)).pipe(
          tap(entry => this.log.trace(TAGS, `Retrieved cached entry: `, entry)),
          switchMap(entry =>
            iif(
              () => !config.networkErrorReuseCacheIfExpired && (!entry || (!!entry.expiresAt && entry.expiresAt < Date.now())),
              defer(() => throwError(err).pipe(tap(subErr =>
                this.log.error(TAGS, `${
                  !entry ? 'No cached entry found!' : (!!entry.expiresAt && entry.expiresAt < Date.now())
                    ? 'Cache entry has expired!' : 'Unknown caching error!'
                } Forwarding http error: (${req.method} ${req.urlWithParams})`, subErr))
              )),
              defer(() =>
                of(new HttpResponse({body: entry.body, status: 200, statusText: 'Ok'})).pipe(
                  tap(() => this.log.trace(TAGS, `Returned cached response for ${req.method} ${req.urlWithParams}`))
                )
              )
            )
          ),
          tap({
            error: iifErr => this.log.error(TAGS, 'Error encountered handling cached entry:', iifErr)
          }))
      ),
      timeoutWith(
        config.timeout * 1000,
        from(cache.get(req, config)).pipe(
          tap(() => this.log.trace(TAGS, `Timed out making request to ${req.method} ${req.urlWithParams}`)),
          switchMap(entry =>
            iif(
              () => !config.networkErrorReuseCacheIfExpired && (!!entry || !entry.expiresAt || entry.expiresAt < Date.now()),
              defer(() => throwError(new HttpErrorResponse({
                error: new WebCacheError(
                  'Could not retrieve requested resource. Connection timed out and cached copy not available.',
                  req.urlWithParams
                ),
                status: 502,
                statusText: 'Bad Gateway',
                url: req.urlWithParams
              })).pipe(tap(err => this.log.error(TAGS, `Error for ${req.method} ${req.urlWithParams}:`, err)))),
              defer(() =>
                of(new HttpResponse({body: entry.body, status: 200, statusText: 'Ok'})).pipe(
                  tap(() => this.log.trace(TAGS, `Returned cached response for ${req.method} ${req.urlWithParams}`))
                )
              )
            )
          )
        )
      ),
      tap({
        error: finalErr => this.log.error(TAGS, 'Error encountered handling request:', finalErr)
      })
    );
  }
}

@Injectable()
export class PersistentWithRefresh extends Fresh {
  constructor(log: LogService) {
    super(log);
  }

  override apply(req: HttpRequest<any>, next: HttpHandler, cache: WebCacheService, config: WebCacheConfigGroup): Observable<HttpEvent<any>> {
    return from(cache.get(req, config)).pipe(
      tap(entry =>
        this.log.trace(TAGS,
          `Cached entry for ${req.method} ${config.ignoreQueryString ? req.url : req.urlWithParams}: ${entry ? 'found' : 'not found'}`
        )
      ),
      switchMap(entry =>
        iif(
          () => !entry || (!!entry.expiresAt && entry.expiresAt < Date.now()),
          defer(() => super.apply(req, next, cache, {...config, networkErrorReuseCacheIfExpired: true})),
          defer(() =>
            of(new HttpResponse({body: entry.body, status: 200, statusText: 'Ok'})).pipe(
              tap(() => this.log.trace(TAGS, `Returned cached response for ${req.method} ${req.urlWithParams}`))
            )
          )
        )
      )
    );
  }
}

export interface WebCacheConfigGroup {
  name: string;
  strategy: any;
  urls: Array<string | [string, string]>;
  excludedUrls?: Array<string | [string, string]>;
  ignoreQueryString: boolean;
  timeout?: number;
  maxAge?: number;
  networkErrorReuseCacheIfExpired?: boolean;
}

export interface WebCacheConfig {
  [group: string]: WebCacheConfigGroup;
}

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(
    private cache: WebCacheService,
    private log: LogService,
    private injector: Injector,
    @Inject(WEB_CACHE_CONFIG) private config: WebCacheConfig) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const config = this.findConfig(req);
    if (!config) {
      return next.handle(req);
    }

    const strategy = this.injector.get(config.strategy);
    return strategy.apply(req, next, this.cache, config);
  }

  private findConfig(req: HttpRequest<any>): WebCacheConfigGroup {
    if (!this.config) {
      return null;
    }

    const configs = Object
      .keys(this.config)
      .map(key => this.config[key]);

    const isExcluded = configs
      .map((config): [string, WebCacheConfigGroup] =>
        config.ignoreQueryString
          ? [req.url, config]
          : [req.urlWithParams, config]
      )
      .map(([reqUrl, config]) =>
        (config.excludedUrls || [])
          .map(url => typeof url === 'string'
            ? url === reqUrl
            : new RegExp(url[0], url[1]).test(reqUrl)
          )
          .reduce((excluded, match) => excluded || match, false)
      )
      .reduce((excluded, exclusion) => excluded || exclusion, false);

    if (isExcluded) {
      return null;
    }

    const selectedConfig = configs // TODO: Try hashmap first?
      .map((config): [string, WebCacheConfigGroup] =>
        config.ignoreQueryString
          ? [req.url, config]
          : [req.urlWithParams, config]
      )
      .map(([reqUrl, config]) =>
        config.urls
          .map(url => typeof url === 'string'
            ? url === reqUrl
            : new RegExp(url[0], url[1]).test(reqUrl)
          )
          .reduce((selected, match) => selected || (match ? config : null), null as WebCacheConfigGroup)
      )
      .reduce((selected, selection) => selected || selection, null);

    // TODO: Hashmap config selections for urls? Deal with query vs. no query string option!

    return selectedConfig;
  }
}
