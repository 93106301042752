<header>
  <ion-grid>
    <ion-row>
      <ion-col>
        <h3 class="date-smaller" *ngIf="!loading">
          {{ dateRange ? dateRange.formattedStart + ' - ' + dateRange.formattedEnd : latestDate }}
        </h3>
        <h3 class="date-smaller" *ngIf="loading">
          {{'future-benefits.loadingHeaderLabel' | transloco}}
        </h3>
      </ion-col>
    </ion-row>
  </ion-grid>
</header>
