import { Pipe, PipeTransform } from '@angular/core';
import { LogService } from '~core/services/log.service';

import { ServerSettingsService } from '~core/services/server-settings.service';
import { environment } from '~env';

export const isIFPS = (itemNumber: string): boolean => itemNumber.length >= 16 && itemNumber[0] === '1';

export const formatItemNumber = (itemNumber: string): string => {
  const padded = itemNumber.padStart(14, '0');
  return isIFPS(padded)
    ? padded.substr(11, 5).replace(/^0*(\d+)$/, '$1')
    : padded;
};

const TAGS = ['Pipe', 'formatImageUrl'];

@Pipe({name: 'formatImageUrl'})
export class FormatImageUrlPipe implements PipeTransform {
  constructor(private log: LogService) {
  }

  transform(itemNumber: string, errorUrl?: string): string {
    const purl = !itemNumber ? errorUrl : (
      ServerSettingsService.settings.productImageUrl || (environment.hosts.cdn + '/products/:itemNumber.jpg')
    ).replace(':itemNumber', formatItemNumber(itemNumber));
    this.log.debug(TAGS, `Formatted Product Image URL: ${purl}`);
    return purl;
  }
}
