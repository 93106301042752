import { Component, Input } from "@angular/core";

@Component({
  selector: 'wic-custom-error-toolbar',
  templateUrl: './custom-error-toolbar.component.html',
  styleUrls: ['./custom-error-toolbar.component.scss'],
})
export class CustomErrorToolbarComponent {
  @Input() title: string;
  @Input() message: string;
  
}