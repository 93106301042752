import { Component, OnInit } from '@angular/core';
import { LogService, LogEntry } from '~core/services/log.service';
import { ModalController } from '@ionic/angular';
import { LogDetailModalComponent } from '../log-detail-modal/log-detail-modal.component';

@Component({
  selector: 'wic-log-viewer',
  templateUrl: './log-viewer.component.html',
  styleUrls: ['./log-viewer.component.scss'],
})
export class LogViewerComponent {
  levels = ['trace', 'debug', 'info', 'warn', 'error'];
  filteredLevels = ['info', 'warn', 'error'];
  filteredTags = [];

  constructor(public log: LogService, private modals: ModalController) {
  }

  toggleLevel(level: string) {
    this.filteredLevels = this.filteredLevels.includes(level)
      ? this.filteredLevels.filter(existingLevel => existingLevel !== level)
      : [...this.filteredLevels, level];
  }

  addTag(tag: string) {
    this.filteredTags = this.filteredTags.includes(tag) ? this.filteredTags : [...this.filteredTags, tag];
  }

  removeTag(tag: string) {
    this.filteredTags = this.filteredTags.filter(t => t !== tag);
  }

  async viewLogEntry(entry: LogEntry) {
    const modal = await this.modals.create({
      component: LogDetailModalComponent,
      componentProps: {
        entry
      }
    });
    await modal.present();
    await modal.onDidDismiss();
  }
}
