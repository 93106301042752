import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  VENDOR_INVENTORY_ITEMS_FEATURE_KEY,
  vendorInventoryItemsReducer,
} from './vendor-inventory-items.state';
import { EffectsModule } from '@ngrx/effects';
import { VendorInventoryItemsEffects } from './vendor-inventory-items.effects';
import { VendorInventoryItem } from './vendor-inventory-item.entity';
import { EntityService } from '~core/services/entity.service';
import { VendorInventoryItemsFacade } from '~features/vendor-inventory-items/vendor-inventory-items.facade';


@NgModule({
  imports: [
    StoreModule.forFeature(VENDOR_INVENTORY_ITEMS_FEATURE_KEY, vendorInventoryItemsReducer),
    EffectsModule.forFeature([VendorInventoryItemsEffects])
  ],
  providers: [
    { provide: VendorInventoryItem, useClass: EntityService },
    VendorInventoryItemsFacade
  ]
})
export class VendorInventoryItemsStateModule {}
