import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppConfig, CONFIG_TOKEN, ConfigService } from '~core/config/config.service';

@NgModule({
  providers: [ConfigService],
})
export class ConfigModule {
  static forRoot(config: AppConfig): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [
        { provide: CONFIG_TOKEN, useValue: config },
        ConfigService
      ]
    }
  }
}
