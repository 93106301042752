import { createAction, props } from '@ngrx/store';


export const sendErrorReport = createAction(
  '[Errors] Send error report',
  props<{reportedError: SoftError | HardError; screenshot?: string}>()
);

export const requestSupport = createAction(
  '[Errors] Request support',
  props<{supportError: SoftError | HardError; screenshot?: string}>()
);

export const getSupportEmail = createAction(
  '[Errors] Get support email',
  props<{supportError: SoftError | HardError; screenshot?: string}>()
);

export const supportEmailAcquired = createAction(
  '[Errors] Support email acquired',
  props<{supportError: SoftError | HardError; email: any; screenshot?: string}>()
);

export const emailNotAvailable = createAction(
  '[Errors] No share via email'
);

export class Warning extends Error {
  constructor(public override message: string, originalError?: Error) {
    super(message);
  }
}

export class SilentError extends Error {
  constructor(public override message: string, originalError?: Error) {
    super(message);
  }
}

export class SoftError extends Error {
  constructor(public override message: string, public title?: string, public detailedMessage?: string, public originalError?: Error) {
    super(message);
  }
}

export class HardError extends Error {
  constructor(public override message: string, public title?: string, public detailedMessage?: string, public originalError?: Error) {
    super(message);
  }
}
