<ng-template #defaultUom> ea</ng-template>

<ion-list *ngIf="showList">
  <ion-item-sliding class="calculator-item" *ngFor="let entry of entries" data-cy="calculator-item">
    <ion-item lines="none">
      <ion-avatar slot="start">
        <fa-icon data-cy="toggle-button"
            icon="check" size="2x"
            (click)="toggled.emit(entry)"
            [styles]="{
              color: entry.isSelected ? 'var(--green)' : 'var(--light-grey)',
              fill: entry.isSelected ? 'var(--green)' : 'var(--light-grey)'
            }">
        </fa-icon>
      </ion-avatar>
      <ion-label>
        <h2 [class.text-light-grey]="!entry.isSelected"><strong>{{entry.name}}</strong></h2>
        <span [class.text-light-grey]="!entry.isSelected" [class.text-med-gray]="entry.isSelected">
          <span *ngIf="entry.quantity">{{entry.quantity}}
            <span *ngIf="entry.byCount || mode === 'quantity'">x </span>
            <span *ngIf="!entry.byCount && mode === 'edit'"><ng-container *ngTemplateOutlet="uom ? uom : defaultUom"></ng-container> @ </span>
          </span>
          <span *ngIf="entry.size && (entry.size !== 1)">{{entry.size}}<ng-container *ngTemplateOutlet="uom ? uom : defaultUom"></ng-container><span *ngIf="entry.isSelected">,
            {{(entry.size * entry.quantity) | number:'1.0-1'}}<ng-container *ngTemplateOutlet="uom ? uom : defaultUom"></ng-container> total</span>
          </span>
          <span *ngIf="mode === 'edit' && entry.unitPrice">{{entry.unitPrice | currency}}<span *ngIf="entry.byCount">
            ea</span><span *ngIf="!entry.byCount">/<ng-container *ngTemplateOutlet="uom ? uom : defaultUom"></ng-container></span><span *ngIf="entry.isSelected">,
            <span [class.totalPrice]="entry.isSelected">{{(entry.unitPrice * entry.quantity) | currency}}</span> total</span>
          </span>
        </span>
      </ion-label>
      <button
          *ngIf="mode === 'quantity' && !deleteMode"
          slot="end" class="item-action-button add"
          [disabled]="!entry.isSelected || (remaining - entry.size) < 0"
          (click)="incremented.emit(entry)" data-cy="quantity-add-button">
        <fa-stack>
          <fa-icon icon="circle" stackItemSize="2x" transform="grow-10" [styles]="{color: entry.isSelected && (remaining - entry.size) >= 0 ? 'var(--green)' : 'var(--light-grey)'}"></fa-icon>
          <fa-icon icon="plus" stackItemSize="2x" [styles]="{color: 'var(--white)'}"></fa-icon>
        </fa-stack>
      </button>
      <button
          *ngIf="mode === 'quantity' && !deleteMode"
          slot="end" class="item-action-button sub"
          [disabled]="!entry.isSelected || entry.quantity === 0"
          (click)="decremented.emit(entry)" data-cy="quantity-sub-button">
        <fa-stack>
          <fa-icon icon="circle" stackItemSize="2x" transform="grow-10" [styles]="{color: entry.isSelected && entry.quantity > 0 ? 'var(--red)' : 'var(--light-grey)'}"></fa-icon>
          <fa-icon icon="minus" stackItemSize="2x" [styles]="{color: 'var(--white)'}"></fa-icon>
        </fa-stack>
      </button>
      <button
          *ngIf="mode === 'edit' && !deleteMode"
          slot="end" class="item-action-button edit"
          [disabled]="!entry.isSelected || !entry.quantity"
          (click)="edited.emit(entry)" data-cy="entry-edit-button">
        <fa-stack>
          <fa-icon icon="circle" stackItemSize="2x" transform="grow-10" [styles]="{color: entry.isSelected && entry.quantity ? 'dimgrey' : 'var(--light-grey)'}"></fa-icon>
          <fa-icon icon="pencil-alt" stackItemSize="2x" transform="shrink-4" [styles]="{color: 'var(--white)'}"></fa-icon>
        </fa-stack>
      </button>
      <button
          *ngIf="deleteMode"
          class="item-action-button del"
          (click)="removed.emit(entry)" data-cy="secondary-delete-button">
        <fa-stack>
          <fa-icon icon="circle" stackItemSize="2x" transform="grow-10" [styles]="{color: 'var(--lava)'}"></fa-icon>
          <fa-icon icon="trash" stackItemSize="2x" transform="shrink-4" [styles]="{color: 'var(--white)'}"></fa-icon>
        </fa-stack>
      </button>
    </ion-item>
    <ion-item-options side="end" *ngIf="!deleteMode">
      <ion-item-option
          class="item-action-button item-option-button del"
          (click)="removed.emit(entry)" data-cy="slide-delete-button">
        <fa-stack>
          <fa-icon icon="circle" stackItemSize="2x" transform="grow-2" [styles]="{color: 'var(--lava)'}"></fa-icon>
          <fa-icon icon="trash" stackItemSize="2x" transform="shrink-8"[styles]="{color: 'var(--white)'}"></fa-icon>
        </fa-stack>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
</ion-list>
