import { Component, EventEmitter, Input, Output } from '@angular/core';
import { APLMessage } from '~features/aplmessages/models';
import { Authority } from '~features/authorities/models';
import { EnrichedBenefit, EnrichedBenefitInfo } from '~features/benefits/models';

@Component({
  selector: 'wic-benefit-list',
  templateUrl: './benefit-list.component.html',
  styleUrls: ['./benefit-list.component.scss'],
})
export class BenefitListComponent {
  @Input() authority: Authority;
  @Input() aplMessages: APLMessage[];
  @Input() benefitInfo: EnrichedBenefitInfo;
  @Input() benefits: EnrichedBenefit[];
  @Input() pendingExpirationMode = false;
  @Input() nonInteractive = false;
  @Input() isFuture = false;
  @Input() currentLanguage: string;
  @Output() viewed = new EventEmitter<EnrichedBenefit>();
  @Output() messageViewed = new EventEmitter<APLMessage>();
  @Output() linkOpened = new EventEmitter<string>();
  @Output() calcOpened = new EventEmitter<EnrichedBenefit>();
}
