<ion-header>
  <ion-toolbar color="vendors">
    <ion-buttons slot="end"></ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <wic-custom-error-toolbar *ngIf="(vendors.vendorUserLocation$ | async) === undefined"
                            [title]="('vendors.noLocationTitle' | transloco)"
                            [message]="('vendors.noLocationMessage' | transloco)"
  >
  </wic-custom-error-toolbar>

  <ion-list *ngIf="(vendors.isLoadingOrEmpty$ | async) || ((app.mapsApiLoaded$ | async) === false); else map">
    <ion-item>
      <ion-label><h2>{{'vendors.loadingLabel' | transloco}}</h2></ion-label>
      <ion-spinner slot="end" name="dots"></ion-spinner>
    </ion-item>
  </ion-list>

  <ng-template #map>
    <wic-vendors-map
        [location]="originalLocation$ | async"
        [vendors]="vendors.filtered$ | async"
        [favorites]="vendors.favorites$ | async"
        [userLocation]="geolocation.location$ | async"
        (geocoded)="geolocation.getLocation()"
        (selected)="vendors.viewDetail($event)">
    </wic-vendors-map>
  </ng-template>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>

<ion-footer>
  <ion-toolbar>
    <ion-searchbar data-cy="search"
        [value]="vendors.filterCriteria$ | async"
        [placeholder]="'vendors.filterLabel' | transloco"
        (ionChange)="vendors.setFilter($event.target.value)">
    </ion-searchbar>
  </ion-toolbar>
</ion-footer>
