import { IEntityOptions } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import * as fuzzy from 'fuzzy';

import { LocationSearchCriteria } from '~models';
import { Office, OfficeFavorite } from './models';
import { allOffices, IOfficeState, officesState } from './offices.state';

export const mapToFilterCriteria = (state: IOfficeState): string =>
  state.filterCriteria;

export const mapToSearchCriteria = (state: IOfficeState): LocationSearchCriteria =>
  state.searchCriteria;

export const mapToSearchedOffices = (criteria: LocationSearchCriteria, offices: Office[], favorites: OfficeFavorite[]): Office[] =>
  offices.filter(office =>
    (criteria.anywhere || office.distance <= criteria.radius) &&
    (criteria.onlyFavorites ? favorites.some(favorite => favorite.locationId === office.locationId) : true)
  );

export const mapToFilteredOffices = (offices: Office[], criteria: string): Office[] =>
  !!offices && !!criteria
    ? fuzzy.filter(criteria, offices, {
      extract: office => `${office.name} ${office.address1} ${office.address2} ${office.city}`
    }).map(m => m.original)
    : offices || [];

export const mapToSortedOffices = (offices: Office[]): Office[] =>
  offices.sort((Office['__nae_entity_opts'] as IEntityOptions).comparer); // eslint-disable-line

export const mapToFavoriteOffices = (state: IOfficeState): OfficeFavorite[] =>
  state.favorites;

export const getCountOfOffices = (offices: Office[]): number =>
  offices ? offices.length : 0;

export const getHasOffices = (count: number): boolean =>
  !!count;

export const mapToError = (state: IOfficeState): any =>
  state.error;

export const favoriteOffices = createSelector(
  officesState,
  mapToFavoriteOffices
);

export const filterCriteria = createSelector(
  officesState,
  mapToFilterCriteria
);

export const searchCriteria = createSelector(
  officesState,
  mapToSearchCriteria
);

export const searchedOffices = createSelector(
  searchCriteria,
  allOffices,
  favoriteOffices,
  mapToSearchedOffices
);

export const filteredOffices = createSelector(
  allOffices,
  filterCriteria,
  mapToFilteredOffices
);

export const sortedFilteredOffices = createSelector(
  filteredOffices,
  mapToSortedOffices
);

export const filteredSearchedOffices = createSelector(
  searchedOffices,
  filterCriteria,
  mapToFilteredOffices
);

export const sortedFilteredSearchedOffices = createSelector(
  filteredSearchedOffices,
  mapToSortedOffices
);

export const totalSortedFilteredSearchedOffices = createSelector(
  sortedFilteredSearchedOffices,
  getCountOfOffices
);

export const hasSortedFilteredSearchedOffices = createSelector(
  totalSortedFilteredSearchedOffices,
  getHasOffices
);


export const officeError = createSelector(
  officesState,
  mapToError
);

export const officeUserLocation = createSelector(officesState, state => state.location);
