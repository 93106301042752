import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { EnvironmentService } from '~features/environment.service';
import { SilentError } from '~features/error/error.state';
import { ProductsDbService } from '~features/products/products-db.service';
import { Product } from './models';
import { Tracking } from './models/tracking';
import { totalCount } from '../calculators/calculators.selectors';

const TAGS = ['Service', 'Products'];

export interface ProductLoadCriteria {
  authorityId: number;
  categoryId: number;
  subCategoryId: number;
  lastLoadDate: string;
  changesOnly: boolean;
}

export class SelectError extends Error {
  constructor(public categoryId: number, public subCategoryId: number, message?: string) {
    super(message);
  }
}

export class SelectAllError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export class SQLiteError extends Error {
  constructor(public categoryId: number, public subCategoryId: number, message?: string) {
    super(message);
  }
}

export class InsertError extends Error {
  constructor(public result: any, message?: string) {
    super(message);
  }
}

export const formatDexieError = error => ({
  ...error,
  stack: error.stack,
  message: error.message,
  _promise: undefined,
});

@Injectable()
export class ProductsService {
  constructor(
    private http: HttpClient,
    private log: LogService,
    private env: EnvironmentService,
    private productsDb: ProductsDbService
  ) {}

  async initializeCollection() {
    try {
      await this.productsDb.initialize();
      this.log.info(TAGS, 'Products Initializing');

      // await this.productsDb.removeTable(Product); // Used to synchronize while in dev
      this.log.debug(TAGS, 'Creating Products table...');
      await this.productsDb.createTable(Product);
      const productInfo = await this.productsDb.getTableInfo(Product);
      this.log.trace(TAGS, 'Product Table Info', productInfo);
      this.log.debug(TAGS, 'Products table created.');

      // await this.productsDb.removeTable(Tracking); // Used to synchronize while in dev
      this.log.debug(TAGS, 'Creating Tracking table...');
      await this.productsDb.createTable(Tracking);
      const trackingInfo = await this.productsDb.getTableInfo(Tracking);
      this.log.trace(TAGS, 'Tracking Table Info', trackingInfo);
      this.log.debug(TAGS, 'Tracking table created.');
      return { productInfo, trackingInfo };
    } catch (err) {
      console.error(JSON.stringify({ ...err, message: err.message, stack: err.stack }));
      this.log.error(TAGS, `Error creating products db`, formatDexieError(err));
      throw new SilentError(`Error creating products db`, err);
    }
  }

  loadBySubCategory({
    authorityId,
    categoryId,
    subCategoryId,
    lastLoadDate,
    changesOnly,
  }: ProductLoadCriteria): Observable<Product[]> {
    return this.env.apiHost$.pipe(
      first(),
      map(
        apiHost =>
          `${apiHost}/v1/authorities/${authorityId}/items/categories/${categoryId}/subcategories/${subCategoryId}`
      ),
      tap(url =>
        this.log.trace(
          TAGS,
          `Url trace: ${url}?lastLoadDate=${lastLoadDate}&changesOnly=${changesOnly ? 'true' : 'false'}`
        )
      ),
      switchMap(url =>
        this.http.get<Product[]>(url, {
          params: {
            lastLoadDate,
            changesOnly: changesOnly ? 'true' : 'false',
          },
        })
      )
    );
  }

  lookupOnline(authorityId: number, upc: string): Observable<Product[]> {
    return this.env.apiHost$.pipe(
      first(),
      map(apiHost => `${apiHost}/v1/authorities/${authorityId}/items/${encodeURIComponent(upc)}`),
      tap(url => this.log.trace(TAGS, `Url trace: ${url}`)),
      switchMap(url => this.http.get<Product[]>(url))
    );
  }

  async lookup(upc: string): Promise<Product[]> {
    return this.productsDb.lookup(upc);
  }

  async select(categoryId: number, subCategoryId: number, allowedCount?: number): Promise<{ products: Product[]; totalCount: number }> {
    const products = await this.productsDb.select(categoryId, subCategoryId, allowedCount);
    const totalCount = await this.productsDb.selectTotalCount(categoryId, subCategoryId);

    if (!totalCount) {
      this.log.warn(TAGS, `No products found to select for ${categoryId}:${subCategoryId}!`);
      throw new SelectError(categoryId, subCategoryId);
    }
    return { products, totalCount };
  }

  async selectAll(): Promise<{ products: Product[]; totalCount: number}> {
    const products = await this.productsDb.selectAll();
    const totalCount = await this.productsDb.selectAllTotalCount();

    if (!totalCount) {
      this.log.warn(TAGS, `No products found to select!`);
      throw new SelectAllError();
    }

    return { products, totalCount };
  }

  async updateProducts(categoryId: number, subCategoryId: number, products: Product[]): Promise<void> {
    try {
      await this.productsDb.updateProducts(categoryId, subCategoryId, products);
    } catch (err) {
      console.error(JSON.stringify({ ...err, message: err.message, stack: err.stack }));
      this.log.error(TAGS, `Error synchronizing products for ${categoryId}:${subCategoryId}`, formatDexieError(err));
      throw new SilentError(`Error synchronizing products for ${categoryId}:${subCategoryId}`, err);
    }
  }

  async populateProducts(categoryId: number, subCategoryId: number, products: Product[]): Promise<void> {
    try {
      await this.productsDb.populateProducts(categoryId, subCategoryId, products);
    } catch (err) {
      console.error(JSON.stringify({ ...err, message: err.message, stack: err.stack }));
      this.log.error(TAGS, `Error inserting products for ${categoryId}:${subCategoryId}`, formatDexieError(err));
      throw new SilentError(`Error inserting products for ${categoryId}:${subCategoryId}`, err);
    }
  }

  clearProducts() {
    return this.productsDb.clearProducts();
  }

  clearProductTracking() {
    return this.productsDb.clearProductTracking();
  }

  async trackProductLoadTime(categoryId: number, subCategoryId: number, timestamp = new Date()) {
    try {
      const trackingInfo = await this.productsDb.setProductUpdateTime(categoryId, subCategoryId, timestamp);
    } catch (err) {
      console.error(err);
      this.log.error(TAGS, `Error tracking product update time for ${categoryId}:${subCategoryId}`, formatDexieError(err));
    }
  }

  async getProductUpdate(categoryId: number, subCategoryId: number): Promise<Tracking> {
    try {
      const trackingInfo = await this.productsDb.getProductUpdate(categoryId, subCategoryId);
      return trackingInfo;
    } catch (err) {
      console.error(err);
      this.log.error(TAGS, `Error inserting products for ${categoryId}:${subCategoryId}`, formatDexieError(err));
      throw new SilentError(`Error inserting products for ${categoryId}:${subCategoryId}`, err);
    }
  }

  async getAllProductUpdates(): Promise<Tracking[]> {
    try {
      const trackingInfo = await this.productsDb.getAllProductUpdates();
      return trackingInfo;
    } catch (err) {
      console.error(err);
      this.log.error(TAGS, 'Error finding product tracking entities.', formatDexieError(err));
      throw new SilentError('Error finding product tracking entities.', err);
    }
  }
}
