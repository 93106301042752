import { Component, Input } from '@angular/core';

@Component({
  selector: 'wic-future-benefits-header',
  templateUrl: './future-benefits-header.component.html',
  styleUrls: ['./future-benefits-header.component.scss'],
})
export class FutureBenefitsHeaderComponent {
  @Input() loading = false;
  @Input() dateRange: { formattedStart: string; formattedEnd: string };
  @Input() latestDate: string;
}
