import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'wic-schedule-chooser-modal',
  templateUrl: './schedule-chooser-modal.component.html',
  styleUrls: ['./schedule-chooser-modal.component.scss'],
})
export class ScheduleChooserModalComponent implements OnInit {
  @Input() currentDays: number;
  useCustom = false;

  currentDaysControl = new FormControl(5);
  customCurrentDaysControl = new FormControl(5);
  useCustomControl = new FormControl(false);

  constructor(public modal: ModalController) {
  }

  ngOnInit() {
    this.currentDaysControl.setValue(this.currentDays);
  }

  dismiss() {
    const days = this.useCustomControl.value ? this.customCurrentDaysControl.value : this.currentDaysControl.value;
    this.modal.dismiss(days || 1, 'ok')
  }
}
