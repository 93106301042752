import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

const TAGS = ['Service', 'Analytics'];

@Injectable()
export class AnalyticsService {
  constructor() {}

  async enable() {
    return FirebaseAnalytics.enable();
  }

  async registerUserProperties(...props: { name: string; value: string }[]): Promise<void[]> {
    return Promise.all(props.map(FirebaseAnalytics.setUserProperty));
  }

  setScreenName(screenName: string) {
    return FirebaseAnalytics.setScreenName({ screenName });
  }

  logEvent(name: string, params = {}) {
    return FirebaseAnalytics.logEvent({ name, params });
  }
}
