import { IEntityState, buildState } from '@briebug/ngrx-auto-entity';
import { EnhancedIssue } from './models';
import { createAction } from '@ngrx/store';

export const loadAllEnhancedIssuesByAuthority = createAction('[Enhanced Issues] Load All By Authority');

export const {
  initialState,
  selectors: {
    selectAll: allEnhancedIssues,
  },
  actions: {
    loadAll: loadAllEnhancedIssues,
    loadAllSuccess: loadAllEnhancedIssuesSuccess,
    loadAllFailure: loadAllEnhancedIssuesFailure,
    loadAllIfNecessary: loadAllEnhancedIssuesIfNecessary,
  },
  facade: EnhancedIssuesFacadeBase
} = buildState(EnhancedIssue);

export function enhancedIssueReducer(state = initialState): IEntityState<EnhancedIssue> {
  return state;
}