import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCalendar,
  faCog,
  faCreditCard,
  faFingerprint,
  faHandshake,
  faHome,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';

import { I18nModule } from '~core/i18n/i18n.module';
import { AuthorityHeaderInterceptor } from '~core/interceptors/authority-header.interceptor';
import { CachingInterceptor, Fresh, PersistentWithRefresh } from '~core/interceptors/CachingInterceptor';
import { WEB_CACHE_CONFIG } from '~core/interceptors/config-token';
import { VersionHeaderInterceptor } from '~core/interceptors/version-header.interceptor';
import { WebCacheService } from '~core/interceptors/web-cache.service';
import { LogService } from '~core/services/log.service';
import { ServicesModule } from '~core/services/services.module';
import { FeaturesModule } from '~features/features.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ComponentsModule } from '~jpma-wicshopper-imports-mono/mobile/core';
import { HomePageModule } from '~jpma-wicshopper-imports-mono/mobile/core';
import { PostRegistrationPageModule } from '~jpma-wicshopper-imports-mono/mobile/core';
import { RegistrationPageModule } from '~jpma-wicshopper-imports-mono/mobile/core';

import { SelectWicProviderModule } from '~jpma-wicshopper-imports-mono/mobile/core';
import { StartupPageModule } from '~jpma-wicshopper-imports-mono/mobile/core';
import { ConfigModule } from '~core/config/config.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // Common/Platform
    // BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      driverOrder: ['sqlite', 'indexeddb', 'localstorage']
    }),
    TranslocoModule,
    FontAwesomeModule,

    // Core
    ConfigModule.forRoot({
      appId: 1,
      vendors: { searchAnywhere: true },
      offices: { searchAnywhere: true },
    }),
    I18nModule,
    ServicesModule,

    // Stateful Features
    FeaturesModule,

    // Pages
    StartupPageModule,
    SelectWicProviderModule,
    HomePageModule,
    RegistrationPageModule,
    PostRegistrationPageModule,

    // Routing
    AppRoutingModule,
    ComponentsModule
  ],
  providers: [
    Storage,
    // Ionic Native
    OpenNativeSettings,
    WebCacheService,

    // Strategies
    Fresh,
    PersistentWithRefresh,
    // TODO: Refactor into a module within shared lib
    {
      provide: WEB_CACHE_CONFIG, useValue: {
        benefits: {
          name: 'benefits',
          strategy: Fresh,
          ignoreQueryString: true,
          timeout: 30,
          maxAge: 30 * 24 * 60 * 60,
          urls: [
            ['.*\/api\/v2\/cards\/.*\/benefits$', 'gi'],
            ['.*\/api\/v3\/cards\/.*\/benefits$', 'gi']
          ]
        },
        banners: {
          name: 'banners',
          strategy: Fresh,
          timeout: 30,
          maxAge: 30 * 24 * 60 * 60,
          ignoreQueryString: true,
          urls: [
            ['.*\/api\/v1\/authorities\/.*\/banners', 'gi']
          ]
        },
        redemptionHistory: {
          name: 'redemptionHistory',
          strategy: Fresh,
          timeout: 30,
          maxAge: 30 * 24 * 60 * 60,
          urls: [
            ['.*\/api\/v1\/cards\/.*\/redemptions', 'gi'],
          ]
        },
        appointments: {
          name: 'appointments',
          strategy: Fresh,
          timeout: 30,
          maxAge: 30 * 24 * 60 * 60,
          urls: [
            ['.*\/api\/v2\/authorities\/.*\/cards\/*\/appointment', 'gi']
          ]
        },
        menu: {
          name: 'menu',
          strategy: PersistentWithRefresh,
          timeout: 30,
          maxAge: 6 * 60 * 60,
          urls: [
            ['.*\/api\/v\\d\/authorities\/.*\/menu', 'gi']
          ]
        },
        // appointments: {
        //   name: 'appointments',
        //   strategy: PersistentWithRefresh,
        //   timeout: 30,
        //   maxAge: 6 * 60 * 60,
        //   urls: [
        //     ['.*\/api\/v2\/authorities\/.*\/cards\/.*\/appointment', 'gi']
        //   ]
        // },
        vendorsAndOffices: {
          name: 'vendorsAndOffices',
          strategy: PersistentWithRefresh,
          timeout: 30,
          maxAge: 24 * 60 * 60,
          urls: [
            ['.*\/api\/v1\/authorities\/.*\/vendors$', 'gi'],
            ['.*\/api\/v2\/authorities\/.*\/offices', 'gi'],
          ]
        },
        fullAPL: {
          name: 'fullAPL',
          strategy: PersistentWithRefresh,
          timeout: 30,
          maxAge: 24 * 60 * 60,
          urls: [
            ['.*\/api\/v1\/authorities\/.*\/apl\/categories', 'gi'],
            ['.*\/api\/v1\/authorities\/.*\/apl\/categories\/subcategories', 'gi'],
            ['.*\/api\/v1\/subcategoryconfigs', 'gi'],
            ['.*\/api\/v1\/authorities\/.*\/apl\/messages\/(en|es|ar|so|np|me)', 'gi']
          ]
        },
        authorities: {
          name: 'authorities',
          strategy: PersistentWithRefresh,
          timeout: 30,
          maxAge: 24 * 60 * 60,
          excludedUrls: [
            ['.*\/api\/(v1|v2)\/authorities\/.*\/items\/.*', 'gi'],
          ],
          urls: [
            ['.*\/api\/v1\/app\/.*\/authorities$', 'gi'],
            ['.*\/api\/v2\/authorities$', 'gi']
          ]
        },
        i18n: {
          name: 'i18n',
          strategy: PersistentWithRefresh,
          timeout: 30,
          maxAge: 24 * 60 * 60,
          urls: [
            ['.*\/api\/v1\/i18n\/.*\/.*', 'gi'],
          ]
        },
        languages: {
          name: 'languages',
          strategy: PersistentWithRefresh,
          timeout: 30,
          maxAge: 24 * 60 * 60,
          urls: [
            ['.*\/api\/v1\/languages', 'gi'],
          ]
        }
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: VersionHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorityHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private log: LogService, private storage: Storage, private faIconLibrary: FaIconLibrary) {
    log.info(['Module', 'App'], 'App module initialized.');
    log.info(['Module', 'App'], `Current Ionic Storage driver is: ${storage['driver']}`);
    faIconLibrary.addIcons(faHome, faCreditCard, faCalendar, faCog, faHandshake, faInfoCircle, faFingerprint);
  }
}

