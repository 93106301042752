import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';

import { State } from '../state';
import { EnhancedIssuesFacadeBase } from "./enhanced-issues.state";
import { EnhancedIssue } from "./models";
import { dynamicButtonActivated, functionProtocolButtonActivated, predefinedButtonActivated, protocolButtonActivated } from "~features/home/home.state";
import { HomeButton } from "~features/home/models";
import { TranslocoService } from "@ngneat/transloco";

@Injectable()
export class EnhancedIssuesFacade extends EnhancedIssuesFacadeBase {
  
  constructor(private store: Store<State>, private transloco: TranslocoService) {
    super(EnhancedIssue, store);
  }

  openLink(enhancedIssue: EnhancedIssue): void {
    const button = {
      name: 'dynamic',
      order: -1,
      show: true,
      imgSrc: '',
      label: this.transloco.translate('enhancedIssues.' + enhancedIssue.issueName + '.buttonText'),
      linkUrl: enhancedIssue.link
    } as HomeButton;
    
    button.linkUrl.startsWith('wic:/')
    ? this.store.dispatch(protocolButtonActivated({button}))
    : button.linkUrl.startsWith('wic$:/')
    ? this.store.dispatch(functionProtocolButtonActivated({button}))
    : button.name === 'dynamic'
    ? this.store.dispatch(dynamicButtonActivated({button}))
    : this.store.dispatch(predefinedButtonActivated({button}));
  }
}
