  <ion-header>
    <ion-toolbar color="danger">
      <ion-title>
        <h3>{{'error-modal.noNetworkTitle' | transloco}}</h3>
      </ion-title>
    </ion-toolbar>
  </ion-header>
  

  <ion-content>
    <ion-grid>
      <ion-row class="ion-padding-vertical">
        <ion-col>
          <ion-label>
            <ion-text>{{ message }}</ion-text>
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>  
  </ion-content>

  <ion-footer>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button
                  [color]="buttonColor"
                  
                  strong="true"
                  expand="block"
                  (click)="modal.dismiss(undefined, 'dismiss')"
          >
            {{ 'error-modal.openSettingsButton' | transloco }}
            <fa-icon icon="arrow-right" class="pl-5"></fa-icon>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button
                  [color]="buttonColor"
                
                  strong="true"
                  expand="block"
                  (click)="modal.dismiss(undefined, 'cancel')"
          >
            {{ 'Cancel' | transloco }}
            <fa-icon icon="arrow-right" class="pl-5"></fa-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>
