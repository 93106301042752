import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from '../state';
import { HardError, SoftError, sendErrorReport, requestSupport } from './error.state';

@Injectable()
export class ErrorsFacade {
  constructor(private store: Store<State>) { }

  reportError(reportedError: SoftError | HardError, screenshot?: string): void {
    this.store.dispatch(sendErrorReport({reportedError, screenshot}));
  }

  requestSupport(supportError: SoftError | HardError, screenshot?: string): void {
    this.store.dispatch(requestSupport({supportError, screenshot}));
  }
}
