import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'Redemption', uriName: 'redemptions' })
export class Redemption {
  @Key id: number;
  redemptionDate: string;
  vendorName: string;
  isVoided: boolean;
  redemptionItems: RedemptionItem[];
}

export interface RedemptionItem {
  categoryId: string;
  subCategoryId: string;
  uom: string;
  packageSize: string;
  units: number;
  purchaseQuantity: number;
  description: string;
  upcPluData: UPCPLUData;
}

export interface UPCPLUData {
  upcPluIndicator: number;
  upcPluCode: number;
  upcPluCheckDigit: number;
  upcPluDataLength: number;
}
