<ion-item
  *ngIf="language"
  class="ripple-parent ion-activatable"
  [class.selected]="isSelected"
>
  <ion-avatar slot="start">
    <img
      src="{{ env.azureGateway.baseUrl }}/get-language-icon/{{ language.code }}?subscription-key={{env.azureGateway.subscriptionKey}}"
      [alt]="language.label"
    />
  </ion-avatar>
  <ion-label [class.selected]="isSelected">
    <h3>{{ "settings.languageLabel" | transloco: getLangCode() }}</h3>
    <p>{{ language.label }}</p>
  </ion-label>
  <ion-icon *ngIf="isSelected" slot="end" name="radio-button-on"></ion-icon>
  <ion-icon *ngIf="edit" slot="end" name="create" color="grey"></ion-icon>
</ion-item>
