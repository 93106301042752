import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import QRCode from 'qrcode';
import { combineLatest, ReplaySubject, switchMap, tap } from 'rxjs';
import { Authority } from '~features/authorities/models';

const generateQRDataUrl = (data: string) =>
  new Promise<string>((resolve, reject) =>
    QRCode.toDataURL(data, { scale: 8 }, (error, url) => (error ? reject(error) : resolve(url)))
  );

@Component({
  selector: 'wic-card-qr-code',
  templateUrl: './card-qr-code.component.html',
  styleUrls: ['./card-qr-code.component.scss'],
})
export class CardQrCodeComponent {
  @ViewChild('qrcodeCanvas') qrcodeCanvas?: ElementRef<HTMLCanvasElement>;
  cardNumber$ = new ReplaySubject<string>(1);
  @Input() set cardNumber(value: string) {
    this.cardNumber$.next(value);
  }
  authority$ = new ReplaySubject<Authority>(1);
  @Input() set authority(value: Authority) {
    this.authority$.next(value);
  }
  @Input() size = '60vw';

  imageUrl$ = combineLatest([
    this.cardNumber$.pipe(tap(value => console.warn(value + ' card Number'))),
    this.authority$.pipe(tap(value => console.warn(value, ' authoriy'))),
  ]).pipe(switchMap(([cardNumber, authority]) => this.generateImage(cardNumber, authority)));

  constructor(private domSanitizer: DomSanitizer) {}

  async generateImage(cardNumber: string, authority: Authority) {
    const authorityId = (authority.proxyId || authority.id) + '';
    const data = `ebt://v1/${authorityId.padStart(3, '0')}::${cardNumber}`;
    const url = await generateQRDataUrl(data);
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
}
