<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <img src="assets/images/img_main.png" alt="{{'common.wicshopper' | transloco}}" height="50">
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <ion-icon icon="finger-print" size="large" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ng-template #hasAuthorities>
    <div *ngIf="(authorities.all$ | async) as allAuthorities">
      <wic-authorities-list *ngIf="!!allAuthorities && !!allAuthorities.length; else noAuthorities"
          [authorities]="allAuthorities"
          (selected)="registration.selectProvider($event)">
      </wic-authorities-list>

      <ng-template #noAuthorities>
        <wic-whoops (refreshed)="refresh()"
                    [message]="'select-provider.whoopsMessage' | transloco"></wic-whoops>
      </ng-template>
    </div>
  </ng-template>

  <wic-authorities-list-loading *ngIf="loadPending$ | async; else hasAuthorities"></wic-authorities-list-loading>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>

<ion-footer translucent mode="ios">
  <ion-toolbar class="missing-provider-button">
    <ion-button (click)="registration.showCantFindAuthAlert()"
                expand="block" class="btn-rust" data-cy="missing-provider-button">
      <ion-icon name="help-circle" slot="start" size="large"></ion-icon>
      <span class="ion-text-uppercase">{{'select-provider.missingProviderButton' | transloco}}</span>
    </ion-button>
  </ion-toolbar>
</ion-footer>
