import { Component, Input } from '@angular/core';
import { EnvironmentService } from '~features/environment.service';
import { ModalController } from '@ionic/angular';
import { Authority } from '~features/authorities/models';

@Component({
  selector: 'wic-provider-selected-modal',
  templateUrl: './provider-selected-modal.component.html',
  styleUrls: ['./provider-selected-modal.component.scss'],
})
export class ProviderSelectedModalComponent {
  @Input() authority: Authority;

  constructor(public modal: ModalController, public env: EnvironmentService) {
  }
}
