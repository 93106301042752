import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomePage } from '~jpma-wicshopper-imports-mono/mobile/core';
import { PostRegistrationPage } from '~jpma-wicshopper-imports-mono/mobile/core';
import { RegistrationPage } from '~jpma-wicshopper-imports-mono/mobile/core';
import { SelectWicProviderPage } from '~jpma-wicshopper-imports-mono/mobile/core';
import { StartupPage } from '~jpma-wicshopper-imports-mono/mobile/core';

// TODO: Fix lazy load imports since they currently would cause the entire library to load.

const routes: Routes = [
  { path: '', redirectTo: 'startup', pathMatch: 'full' },
  { path: 'startup', component: StartupPage },
  { path: 'home', component: HomePage },
  { path: 'select-provider', component: SelectWicProviderPage },
  { path: 'registration', component: RegistrationPage },
  { path: 'post-registration', component: PostRegistrationPage },
  { path: 'settings', loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.SettingsPageModule) },
  { path: 'benefits', loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.BenefitsPageModule) },
  {
    path: 'manage-cards',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.ManageCardsPageModule)
  },
  {
    path: 'appointments',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.AppointmentsPageModule)
  },
  {
    path: 'item-detail',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.ItemDetailPageModule)
  },
  {
    path: 'search-upc',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.SearchUpcPageModule)
  },
  { path: 'items', loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.ItemListPageModule) },
  { path: 'vendors', loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.VendorsPageModule) },
  { path: 'offices', loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.OfficesPageModule) },
  {
    path: 'future-benefits',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.FutureBenefitsPageModule)
  },
  { path: 'qr-code', loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.QRCodePageModule) },
  { path: 'receipts', loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.ReceiptsPageModule) },
  {
    path: 'cant-buy-this',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.CantBuyThisPageModule)
  },
  {
    path: 'cant-buy-this/barcode-scanner',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then( m => m.BarcodeScannerPageModule)
  },
  {
    path: 'calculator/cvv/:categoryId',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.CalculatorCVVPageModule)
  },
  { path: 'calculator/cvv', redirectTo: 'calculator/cvv/19', pathMatch: 'full' },
  { path: 'calculator/cvv/:categoryId/:subCategoryId', redirectTo: 'calculator/cvv/:categoryId', pathMatch: 'full' },
  {
    path: 'calculator/uom/:categoryId',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.CalculatorUomPageModule)
  },
  { path: 'calculator/uom/:categoryId/:subCategoryId', redirectTo: 'calculator/uom/:categoryId', pathMatch: 'full' },
  { path: 'black', loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then(m => m.BlackPageModule) },
  {
    path: 'barcode-scanner',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then( m => m.BarcodeScannerPageModule)
  },
  {
    path: 'redemption-history',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then( m => m.RedemptionHistoryPageModule)
  },
  {
    path: 'enhanced-issues',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then( m => m.EnhancedIssuesPageModule)
  },
  {
    path: 'shopping-lists',
    loadChildren: () => import('~jpma-wicshopper-imports-mono/mobile/core').then( m => m.ShoppingListsModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
