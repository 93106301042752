import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { SubCatConfig } from '~features/subcat-configs/models';
import { SubCatConfigFacade } from '~features/subcat-configs/subcat-config.facade';
import { Benefit } from '~features/benefits/models';

@Pipe({name: 'findConfig'})
export class FindConfigPipe implements PipeTransform {
  constructor(private subCatConfig: SubCatConfigFacade) {}

  transform(benefit: Benefit, ...args: any[]): Observable<SubCatConfig> {
    return this.subCatConfig.findForBenefit(benefit);
  }
}
