import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Storage } from '@ionic/storage';
import { MicroBlogsService } from '~features/micro-blogs/micro-blogs.service';

@NgModule({
  providers: [
    MicroBlogsService
  ]
})
export class MicroBlogsModule { }
