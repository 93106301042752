import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EmailComposer } from 'capacitor-email-composer';
import { format } from 'date-fns';
import { EMPTY, from, iif, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DeviceService } from '~core/services/device.service';

import { LogService } from '~core/services/log.service';
import { RemoteLogService } from '~core/services/remote-log.service';
import { environment } from '~env';
import {
  emailNotAvailable,
  getSupportEmail,
  HardError,
  requestSupport,
  sendErrorReport,
  SoftError,
  supportEmailAcquired,
  Warning
} from './error.state';

import { ErrorsService } from './errors.service';
import { State } from '~features/state';
import { currentRegistration } from '~features/registration/registration.selectors';

const TAGS = ['Effects', 'Application'];


export interface MightHaveError extends Action {
  error?: any;
}

@Injectable()
export class ErrorEffects {
  constructor(
    private actions$: Actions,
    private platform: Platform,
    private log: LogService,
    private remoteLog: RemoteLogService,
    private errors: ErrorsService,
    private device: DeviceService,
    private readonly store: Store<State>
  ) {
  }

  warning$ = createEffect(
    () => this.actions$.pipe(
      map(action => action as MightHaveError),
      filter(({ error }) => !!error),
      map(({ error }) => error),
      filter(error => error instanceof Warning),
      tap(error => this.log.warn(TAGS, 'An application warning occurred!', error)),
      tap(error => this.errors.showWarningToast(error))
    ),
    { dispatch: false, resubscribeOnError: true }
  );

  error$ = createEffect(
    () => this.actions$.pipe(
      map(action => action as MightHaveError),
      filter(({ error }) => !!error),
      map(({ error }) => error),
      filter(error => error instanceof HardError),
      tap(error => this.log.error(TAGS, 'An application error occurred!', error)),
    ),
    { dispatch: false, resubscribeOnError: true }
  );

  softError$ = createEffect(
    () => this.actions$.pipe(
      map(action => action as MightHaveError),
      filter(({ error }) => !!error),
      map(({ error }) => error),
      filter(error => error instanceof SoftError),
      tap(() => this.log.trace(TAGS, 'Capturing screen shot for error reporting...')),
      switchMap(error =>
        from(this.errors.captureErrorScreenshot(error)).pipe(
          tap({
            next: () => this.log.trace(TAGS, 'Screen shot captured for error reporting.'),
            error: err => this.log.warn(TAGS, 'Error acquiring screen shot for error reporting: ', err)
          }),
          catchError(() => of([error, null]))
        )
      ),
      tap(([error]) => this.log.trace(TAGS, 'Showing soft error toast...', error)),
      tap(([error, dataURI]) => this.errors.showErrorToast(error, dataURI))
    ),
    { dispatch: false, resubscribeOnError: true }
  );

  hardError$ = createEffect(
    () => this.actions$.pipe(
      map(action => action as MightHaveError),
      filter(({ error }) => !!error),
      map(({ error }) => error),
      filter(error => error instanceof HardError),
      tap(() => this.log.trace(TAGS, 'Capturing screen shot for error reporting...')),
      switchMap(error =>
        from(this.errors.captureErrorScreenshot(error)).pipe(
          tap({
            next: () => this.log.trace(TAGS, 'Screen shot captured for error reporting.'),
            error: err => this.log.warn(TAGS, 'Error acquiring screen shot for error reporting: ', err)
          }),
          catchError(() => of([error, null]))
        )
      ),
      tap(([error]) => this.log.trace(TAGS, 'Showing hard error modal...', error)),
      tap(([error, dataURI]) => this.errors.showErrorModal(error, dataURI))
    ),
    { dispatch: false, resubscribeOnError: true }
  );

  reportError$ = createEffect(
    () => this.actions$.pipe(
      ofType(sendErrorReport),
      withLatestFrom(this.store.select(currentRegistration)),
      switchMap(([{ reportedError, screenshot, }, registration]) =>
        this.errors.sendErrorReport(reportedError, screenshot, registration).pipe(
          tap(() => this.errors.confirmErrorReported()),
          tap({
            next: () => this.log.trace(TAGS, 'Successfully reported error!'),
            error: err => this.log.trace(TAGS, 'Failed to report error: ', err)
          }),
          catchError((err) => of(this.errors.warnErrorReportingFailed()))
        )
      )
    ),
    { dispatch: false, resubscribeOnError: true }
  );

  requestSupport$ = createEffect(
    () => this.actions$.pipe(
      ofType(requestSupport),
      switchMap(({ supportError, screenshot }) =>
        this.platform.is('capacitor')
          ? of({ supportError, screenshot, canShare: true })
          // from(this.socialSharing.canShareViaEmail())
          //   .pipe(
          //     tap({error: err => this.log.debug(TAGS, `Error checking email sharing:`, err)}),
          //     catchError(() => of(false).pipe(
          //       tap(() => this.log.warn(TAGS, 'Cannot share via email. Not Cordova platform!'))
          //     )),
          //     map(canShare => ({supportError, canShare}))
          //   )
          : of({ supportError, screenshot, canShare: false })
      ),
      tap(({ canShare }) => this.log.debug(TAGS, `Can share via email? ${canShare ? 'yes' : 'no'}`)),
      switchMap(({ supportError, screenshot, canShare }) =>
        iif(
          () => canShare,
          of(getSupportEmail({ supportError, screenshot })),
          of(emailNotAvailable())
        )
      )
    )
  );

  getSupportEmail$ = createEffect(
    () => this.actions$.pipe(
      ofType(getSupportEmail),
      tap(({ screenshot }) => this.log.trace(TAGS, `Acquiring support email...`, screenshot)),
      switchMap(({ supportError, screenshot }) =>
        from(this.errors.requestSupport()).pipe(
          filter(res => res.role !== 'cancel'),
          map(res => res.data),
          tap(email => this.log.trace(TAGS, `Acquired support email details:`, email)),
          map(email => supportEmailAcquired({
            supportError,
            email,
            screenshot
          }))
        )
      )
    )
  );

  sendErrorReportForSupport$ = createEffect(
    () => this.actions$.pipe(
      ofType(supportEmailAcquired),
      map(({ supportError: reportedError, screenshot }) => sendErrorReport({ reportedError, screenshot }))
    )
  );

  shipCurrentLog$ = createEffect(
    () => this.actions$.pipe(
      ofType(supportEmailAcquired),
      tap(() => this.remoteLog.shipLog()),
      tap(() => this.log.trace(TAGS, 'Shipped current session\'s log for current support request.'))
    ),
    { dispatch: false, resubscribeOnError: true }
  );

  sendSupportEmail$ = createEffect(
    () => this.actions$.pipe(
      ofType(supportEmailAcquired),
      withLatestFrom(this.device.deviceInfo$, this.log.localLog$, this.store.select(currentRegistration)),
      tap(([{
        email,
        screenshot
      }, deviceInfo]) => this.log.trace(TAGS, 'Sending support email:', email, screenshot, deviceInfo)),
      map(([{ supportError, email, screenshot }, deviceInfo, log, registration]) => ({
        message: `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8"/>
  <title>Error Report</title>
</head>

<body>
  <p>${email.message}</p>

  <h2>== ==[ Error Report ]== ==</h2>
  <h4>Timestamp:</h4><span>${format(Date.now(), 'yyyy-MM-dd HH:mm:ss')}</span><br/>
  <h4>Reply to:</h4><span> ${email.email}</span> <br/>

  <h4>Device OS:</h4><span> ${deviceInfo.platform}</span> <br/>
  <h4>Device Model:</h4><span> ${deviceInfo.model}</span> <br/>
  <h4>Device Version:</h4><span> ${deviceInfo.version}</span> <br/>

  <h4>Device Reference Id:</h4><span> ${deviceInfo.udid}</span> <br/>
  <h4>Session Id:</h4><span> ${this.device.sessionId}</span> <br/>

  <h4>Authority Id:</h4><span> ${registration.authority.id} </span><br/>
  <h4>Card Number:</h4><span> ${registration.currentCard?.cardNumber} </span><br/>

  <h4>Error Name:</h4><span> ${supportError.name}</span> <br/>
  <h4>Error Message:</h4><span> ${supportError.message}</span> <br/>
  <h4>Error Detailed Message:</h4>
  <span>${supportError.detailedMessage}</span> <br/>

  <h4>Error Stack Trace:</h4> <br/>
  <span>${supportError.stack}</span> <br/>

  <h4>Recent Log Entries:</h4> <br/>
  ${log.slice(0, 20).map(entry => `
  <span>
    (${entry.timestamp}) ${entry.level} [${entry.tags}]
    ${typeof entry.message === 'string' ? entry.message.slice(0, 250) + '...' : entry.message}
  </span><br/>
  `).join('\r\n') /* Starting to feel like I'm writing a React app */}

  <br/>
  <br/>

  <img src="${screenshot}" alt="Error Image Screenshot" />
</body>
</html>

`,
        subject: `WIC Shopper Support Request from ${email.name} (Session ID: ${this.device.sessionId})`,
        screenshot: `file://${screenshot}`
      })),
      tap(request => this.log.trace(TAGS, 'Support request:', request)),
      switchMap(request =>
        EmailComposer.open({
          subject: request.subject,
          body: request.message,
          to: environment.support.to,
          isHtml: true
        })
      ),
      tap({ error: err => this.log.error(TAGS, 'Error trying to share via email:', { message: err.message, ...err }) }),
      tap({ error: () => this.errors.warnEmailSharingNotAvailable() }),
      catchError(() => EMPTY)
    ),
    { dispatch: false, resubscribeOnError: true }
  );

  emailNotAvailable$ = createEffect(
    () => this.actions$.pipe(
      ofType(emailNotAvailable),
      tap(() => this.errors.warnEmailSharingNotAvailable())
    ),
    { dispatch: false, resubscribeOnError: true }
  );
}
