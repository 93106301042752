import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity('MobileOrder', { uriName: 'mobile-orders' })
export class MobileOrder {
  @Key id: string;

  authorityId: number;
  exceptionId: number;
  cardNumber: string;
  orderDate: Date;

  // timeSlot?: VendorTimeSlot;

  benefitGroups: OrderBenefitGroup[];
}

export class OrderBenefitGroup {
  id?: number;
  categoryId: number;
  subCategoryId: number;
  mobileOrderId: string;

  // quantity: number;
  availableQuantity: number;

  beginDate: string | Date;
  endDate: string | Date;

  items?: MobileOrderItem[];
}

export class MobileOrderItem {
  id: string;
  benefitGroupId?: string;

  productId: string;
  itemId?: string; // Might be number
  itemNumber: string;

  purchaseQuantity: number;
  uom: string;
  units: number;
  description: string;

  substitutions?: MobileOrderItemSubstitution[];
}

export class MobileOrderItemSubstitution {
  id: string;
  mobileOrderItemId?: string;

  // TODO: Check Length
  //       Not all of these IDs might be needed
  productId: string;
  itemId: string; // Might be number
  itemNumber: string;

  purchaseQuantity: number;
  uom: string;
  units: number;
  description: string;
}
