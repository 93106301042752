import { Injectable } from '@angular/core';
import { buildState, IEntityState, LoadAll, SelectByKey } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, createAction, createReducer, props, select, Store } from '@ngrx/store';
import { add, isBefore } from 'date-fns';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DialogsService } from '~core/services/dialogs.service';
import { EmptyKey } from '~core/services/entity.service';
import { LogService } from '~core/services/log.service';
import { APLMessage } from '~features/aplmessages/models';
import { benefitsViewed } from '~features/benefits/benefits.actions';
import { BENEFITS_HARD_EXPIRE } from '~features/constants';
import { statusProduct } from '~features/products/product-upc.selectors';
import { itemDetailViewed } from '~features/products/product.state';
import { currentProviderId } from '~features/registration/registration.selectors';
import { currentLanguage } from '~features/settings/settings.state';
import { State } from '~features/state';

const TAGS = ['Effects', 'APL Messages'];

export const {
  initialState,
  selectors: {
    selectLoadedAt: aplMessagesLoadedAt,
  },
  facade: APLMessagesFacadeBase
} = buildState(APLMessage);

export const aplMessageViewed = createAction(
  '[APL Message] Viewed',
  props<{ message: APLMessage }>()
);

export type IAPLMessageState = IEntityState<APLMessage>;

const reduce = createReducer(
  initialState
);

export function aplMessageReducer(state: IAPLMessageState, action: Action): IAPLMessageState {
  return reduce(state, action);
}

@Injectable()
export class APLMessageEffects {
  constructor(private actions$: Actions, private store: Store<State>, private log: LogService, private dialogs: DialogsService) {
  }

  showAPLMessage$ = createEffect(
    () => this.actions$.pipe(
      ofType(aplMessageViewed),
      switchMap(({message}) =>
        this.dialogs.alert(message)
      )
    ),
    {dispatch: false}
  );

  loadAPLMessages$ = createEffect(
    () => this.actions$.pipe(
      ofType(benefitsViewed, itemDetailViewed),
      tap(() => this.log.trace(TAGS, 'Checking for APL Messages...')),
      withLatestFrom(
        this.store.select(aplMessagesLoadedAt),
        this.store.select(currentProviderId),
        this.store.select(currentLanguage)
      ),
      tap(([, loadedAt, authorityId, lang]) => this.log.trace(TAGS, `Loaded at: ${loadedAt}; Authority: ${authorityId}; Lang: ${lang}`)),
      filter(([, loadedAt, authorityId, lang]) =>
        !!authorityId && !!lang && (!loadedAt || isBefore(loadedAt, add(Date.now(), {minutes: BENEFITS_HARD_EXPIRE})))
      ),
      tap(() => this.log.trace(TAGS, 'Loading APL Messages...')),
      map(([, , authorityId, lang]) => new LoadAll(APLMessage, {
        parents: {
          authorities: authorityId,
          apl: EmptyKey
        },
        param: lang
      }))
    )
  );


  selectAPLMessageForItem$ = createEffect(
    () => this.store.pipe(
      select(statusProduct),
      filter(product => !!product),
      tap(() => this.log.trace(TAGS, 'Selecting APL Message...')),
      withLatestFrom(this.store.select(currentProviderId)),
      map(([product, authorityId]) => `${authorityId}_${product.categoryId}_${product.subCategoryId}`),
      map(key => new SelectByKey(APLMessage, key))
    )
  );
}
