import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { APLMessage } from '~features/aplmessages/models';
import { EnrichedBenefit } from '~features/benefits/models';
import { SubCatConfig } from '~features/subcat-configs/models';

@Component({
  selector: 'wic-benefit-card-image',
  templateUrl: './benefit-card-image.component.html',
  styleUrls: ['./benefit-card-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BenefitCardImageComponent {
  @Input() benefit: EnrichedBenefit;
  @Input() aplMessage: APLMessage;
  @Input() config: SubCatConfig;
  @Output() viewed = new EventEmitter<EnrichedBenefit>();
  @Output() messageViewed = new EventEmitter<APLMessage>();

  constructor(private sanitizer: DomSanitizer) {
  }

  createIconUrl(dataUrl): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(dataUrl);
  }
}
