import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBan,
  faCalculator,
  faCheck,
  faCheckSquare,
  faCircle,
  faDollarSign,
  faExclamationTriangle,
  faHandHoldingMedical,
  faListUl,
  faMinusCircle,
  faPlusCircle,
  faSearch,
  faSquare,
  faWifi
} from '@fortawesome/free-solid-svg-icons';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { AltCVVStylePipe } from './alt-cvvstyle.pipe';

import { ItemDetailPage } from './item-detail.page';
import { ItemStatusComponent } from './item-status/item-status.component';
import { StatusToColorPipe } from './status-to-color.pipe';
import { I18nModule } from '~core/i18n/i18n.module';

const routes: Routes = [
  {
    path: '',
    component: ItemDetailPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    RouterModule.forChild(routes),
    InlineSVGModule.forRoot(),
    FontAwesomeModule,
    ComponentsModule,
    TranslocoModule,
    I18nModule
  ],
  declarations: [ItemDetailPage, ItemStatusComponent, AltCVVStylePipe, StatusToColorPipe]
})
export class ItemDetailPageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(
      faBan,
      faCalculator,
      faCheck,
      faCheckSquare,
      faCircle,
      faDollarSign,
      faExclamationTriangle,
      faHandHoldingMedical,
      faListUl,
      faMinusCircle,
      faPlusCircle,
      faSearch,
      faSquare,
      faWifi,
    );
  }
}
