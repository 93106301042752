<header class="page-header">
  <ion-grid *ngIf="!!registration?.authority && !!benefitInfo">
    <ion-row>
      <ion-col>
        <h2 *ngIf="benefitInfo.hasCurrent">
          {{(pendingExpirationMode ? 'benefits.pendingExpirationTitle' : 'benefits.availableBenefitsTitle') | transloco}}
        </h2>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="benefitInfo.isActive && benefitInfo.hasCurrent">
      <ion-col>
        <h3 *ngIf="pendingExpirationMode">{{benefitInfo.formattedEnd }}</h3>
        <h3 *ngIf="!pendingExpirationMode" class="date-smaller">
          {{benefitInfo.formattedStart + ' - ' + benefitInfo.formattedEnd}}
        </h3>
      </ion-col>
    </ion-row>
  </ion-grid>
</header>

<header class="page-subheader"
        *ngIf="!!registration?.authority && !!benefitInfo && !!benefitInfo.loadedAt">
  <ion-grid >
    <ion-row>
      <ion-col>
        <div>
          <h4>
            {{'benefits.loadedAtSubtitle' | transloco:{timestamp: benefitInfo.loadedAt} }}
            <ion-icon (click)="refreshed.emit()" name="refresh" data-cy="benefits-refresh"></ion-icon>
          </h4>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</header>

<header class="page-subheader"
        *ngIf="!!registration?.authority && !!benefitInfo && isStale">
  <ion-grid >
    <ion-row>
      <ion-col>
        <div>
          <h4 class="alert">
            {{'benefits.benefitsStaleMessage' | transloco:{timestamp: benefitInfo.retrievedAtFormatted} }}
          </h4>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</header>
