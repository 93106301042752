<ion-list *ngIf="!!recentUPCs?.length">
  <ion-list-header>
    {{'search-upc.recentTitle' | transloco}}
  </ion-list-header>
  <ion-item *ngFor="let info of recentUPCs" (click)="selected.emit(info.upc)" data-cy="recent-upcs">
    <ion-avatar slot="start" *ngIf="!info.description">
      <fa-icon icon="question" [styles]="{color: 'var(--light-grey)'}"></fa-icon>
    </ion-avatar>
    <wic-product-image slot="start" *ngIf="info.description && info.itemNumber" [product]="info"></wic-product-image>
    <ion-icon slot="end" icon="barcode"></ion-icon>
    <ion-label>
      <h2>{{info.description || info.upc}}</h2>
      <h3 *ngIf="info.description">{{info.upc}}</h3>
    </ion-label>
  </ion-item>
</ion-list>
