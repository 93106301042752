import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs';

@Pipe({
  name: 'translocoFallback',
  pure: false,
})
export class TranslocoFallbackPipe implements PipeTransform {
  constructor(private transloco: TranslocoService) {}

  transform(value: string, fallback: string) {
    return this.transloco
      .selectTranslate(value)
      .pipe(map(translation => (translation === value ? fallback : translation)));
  }
}
