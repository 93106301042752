import { createAction, props } from '@ngrx/store';
import { EnrichedBenefit } from '~features/benefits/models';
import { Product } from '~features/products/models';
import { ShoppingList } from './shopping-list.entity';
import { Vendor } from '~features/vendors/models';
import { VendorTimeSlot } from '~features/vendor-time-slots/vendor-time-slot.entity';
import { TimeSlotDayOption } from '~features/vendor-time-slots/time-slot-day-option.model';

export const addShoppingList = createAction(
  '[ShoppingList] Add Shopping List',
  props<{ name: string, isMobileOrder: boolean }>()
);

export const viewShoppingList = createAction(
  '[ShoppingList] View Shopping List',
  props<{ shoppingList: ShoppingList }>()
);

export const showSelectVendorForShoppingList = createAction(
  '[ShoppingList] Show Select Vendor',
  props<{ shoppingList: ShoppingList }>()
);

export const vendorSelectedForShoppingList = createAction(
  '[ShoppingList] Vendor Selected',
  props<{ shoppingList: ShoppingList, vendor: Vendor }>()
);

export const vendorChangedForShoppingList = createAction(
  '[ShoppingList] Vendor Changed',
  props<{ shoppingList: ShoppingList, vendor: Vendor }>()
);

export const openSelectBenefitSubCategory = createAction(
  '[ShoppingList] Open Select Benefit SubCategory'
);

export const addItem = createAction(
  '[ShoppingList] Add Item',
  props<{ benefit: EnrichedBenefit }>()
);

export const openSelectItemFrom = createAction(
  '[ShoppingList] Open Select Item From',
  props<{ benefit: EnrichedBenefit }>()
);

export const openAddItem = createAction(
  '[ShoppingList] Open Add Item',
  props<{ benefit: EnrichedBenefit }>()
);
export const openAddItemFromPastPurchases = createAction(
  '[ShoppingList] Open Add Item From Past Purchases',
  props<{ benefit: EnrichedBenefit }>()
);
export const openAddCVVItem = createAction(
  '[ShoppingList] Open Add CVV Item',
  props<{ benefit: EnrichedBenefit }>()
);

export const openAddCustomItem = createAction(
  '[ShoppingList] Open Add Custom Item'
)

export const addCustomItemToShoppingList = createAction(
  '[ShoppingList] Add Custom Item To Shopping List',
  props<{ name : string }>()
)

export const addItemToShoppingList = createAction(
  '[ShoppingList] Add Item To Shopping List',
  props<{ product: Product; benefit: EnrichedBenefit; purchaseQuantity?: number, units?: number, id?: string }>()
);
export const addCVVItemToShoppingList = createAction(
  '[ShoppingList] Add CVV Item To Shopping List',
  props<{ name: string; benefit: EnrichedBenefit }>()
);

export const submitMobileOrder = createAction(
  '[MobileOrdering] Submit Mobile Order',
);

export const changeOrderDate = createAction(
  '[MobileOrdering] Change Order Date',
);

export const setVendorTimeSlotForOrder = createAction(
  '[MobileOrdering] Set Vendor Time Slot',
  props<{ vendorTimeSlot: VendorTimeSlot, orderDate: Date }>()
);
