import {SQLiteColumnType} from './sqlite.service';

export const PKSymbol = Symbol('PrimaryKey');
export const ColumnSymbol = Symbol('Column');
export const TableSymbol = Symbol('Table');

export const getPrimaryKey = <T>(entity: new () => T): string => entity.prototype[PKSymbol];
export const getTable = <T>(entity: new () => T): string => entity.prototype[TableSymbol];
export const getColumns = <T>(entity: new () => T): Record<string, string> => entity.prototype[ColumnSymbol];

export const PrimaryKey = (type: SQLiteColumnType): PropertyDecorator => (target, propertyKey) => {
  target.constructor.prototype[ColumnSymbol] = {...target.constructor.prototype[ColumnSymbol], [propertyKey]: type};
  target.constructor.prototype[PKSymbol] = propertyKey;
};

export const Column = (type: SQLiteColumnType): PropertyDecorator => (target, propertyKey) => {
  target.constructor.prototype[ColumnSymbol] = {...target.constructor.prototype[ColumnSymbol], [propertyKey]: type};
};

// TODO: Temporarily disabled
// eslint- disable-next-line prefer-arrow/prefer-arrow-functions
export function Table(table: string): ClassDecorator {
  return (constructor) => {
    constructor.prototype[TableSymbol] = table;
  };
}
