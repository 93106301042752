import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Receipt } from '~features/receipts/models';

@Component({
  selector: 'wic-receipt-history',
  templateUrl: './receipt-history.component.html',
  styleUrls: ['./receipt-history.component.scss'],
})
export class ReceiptHistoryComponent {
  @Input() hasHistory: boolean;
  @Input() receipts: Receipt[];
  @Input() selectedIndex: number;

  @Output() selected = new EventEmitter<Receipt>();
  @Output() deleted = new EventEmitter<number>();
}
