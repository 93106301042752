import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRight, faAsterisk, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { BenefitsComponentsModule } from './benefits-components.module';
import { BenefitsPage } from './benefits.page';

const routes: Routes = [
  {
    path: '',
    component: BenefitsPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    PipesModule,
    RouterModule.forChild(routes),
    FontAwesomeModule,
    TranslocoModule,
    BenefitsComponentsModule,
  ],
  declarations: [BenefitsPage],
})
export class BenefitsPageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(faCreditCard, faAsterisk, faArrowRight);
  }
}
