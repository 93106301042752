import { createAction, props } from '@ngrx/store';
import { Authority } from '~features/authorities/models';
import { Card, Registration } from '~features/registration/models';

export const manageCards = createAction(
  '[Registration] Manage cards',
  props<{ rooted?: boolean }>()
);

export const editCard = createAction(
  '[Registration] Edit card',
  props<{ card: Card }>()
);

export const cardEdited = createAction(
  '[Registration] Card edited',
  props<{ card?: Card }>()
);

export const selectCard = createAction(
  '[Registration] Select card',
  props<{ card: Card }>()
);

export const deleteCard = createAction(
  '[Registration] Delete card',
  props<{ card: Card }>()
);

export const deleteCardConfirmed = createAction(
  '[Registration] Delete card: Confirmed',
  props<{ card: Card }>()
);

export const selectProvider = createAction(
  '[Registration] Select provider',
  props<{ authority: Authority }>()
);

export const verityRegistrationChange = createAction(
  '[Registration] Verify change of registration',
  props<{ selected: Authority; current: Authority }>()
);

export const registrationChanged = createAction(
  '[Registration] Registration has been changed',
  props<{ selected: Authority }>()
);

export const providerSelected = createAction(
  '[Registration] Provider selected',
  props<{ selected: Authority }>()
);

export const providerConfirmed = createAction(
  '[Registration] Provider selection: confirmed',
  props<{ authority: Authority }>()
);

export const nonProviderRegistrationCompleted = createAction(
  '[Registration] Non-Provider Completed'
);

export const restoreRegistration = createAction(
  '[Registration] Restore registration',
  props<{ registration: Registration }>()
);

export const registrationRestored = createAction(
  '[Registration] Registration restored',
  props<{ registration: Registration }>()
);

export const addCardRegistration = createAction(
  '[Registration] Register additional card'
);

export const registerCard = createAction(
  '[Registration] Register card',
  props<{ card: Card }>()
);

export const cardAlreadyRegistered = createAction(
  '[Registration] Card already registered',
  props<{ card: Card }>()
);

export const registerPendingCard = createAction(
  '[Registration] Register new card: Pending',
  props<{ card: Card }>()
);

export const abandonPendingRegistration = createAction(
  '[Registration] Abandon pending registration'
);

export const cardRegistrationCompleted = createAction(
  '[Registration] Register card: Complete'
);

export const skipCardRegistration = createAction(
  '[Registration] Skip card registration'
);

export const setCurrentCard = createAction(
  '[Registration] Set current card',
  props<{ card: Card }>()
);

export const addCard = createAction(
  '[Registration] Add card',
  props<{ card: Card }>()
);

export const resetRegistration = createAction(
  '[Registration] Reset'
);

export const registrationResetComplete = createAction(
  '[Registration] Reset: Complete'
);

export const registrationMigrated = createAction(
  '[Registration] Migrated legacy registration',
  props<{ registration: Registration }>()
);
