import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { BenefitHeaderComponent } from './benefit-header/benefit-header.component';
import { ExpiredBenefitsWarningComponent } from './expired-benefits-warning/expired-benefits-warning.component';
import { FutureBenefitsButtonComponent } from './future-benefits-button/future-benefits-button.component';
import { LastShoppingTripComponent } from './last-shopping-trip/last-shopping-trip.component';
import { LastUpdatedAtComponent } from './last-updated-at/last-updated-at.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    PipesModule,
    FontAwesomeModule,
    TranslocoModule,
  ],
  declarations: [
    BenefitHeaderComponent,
    FutureBenefitsButtonComponent,
    LastShoppingTripComponent,
    LastUpdatedAtComponent,
    ExpiredBenefitsWarningComponent,
  ],
  exports: [
    BenefitHeaderComponent,
    FutureBenefitsButtonComponent,
    LastShoppingTripComponent,
    LastUpdatedAtComponent,
    ExpiredBenefitsWarningComponent,
  ],
})
export class BenefitsComponentsModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(faHistory);
  }
}
