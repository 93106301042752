import { Component, OnInit, ViewChild } from '@angular/core';
import { IonTabs } from '@ionic/angular';
import { GeoLocationService } from '~core/services/geo-location.service';
import { LogService } from '~core/services/log.service';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { OfficesFacade } from '~features/offices/offices.facade';

const TAGS = ['Page', 'Vendors'];

@Component({
  selector: 'wic-offices',
  templateUrl: './offices.page.html',
  styleUrls: ['./offices.page.scss'],
})
export class OfficesPage implements OnInit {
  @ViewChild('tabs', { static: true }) tabs: IonTabs;

  constructor(
    public offices: OfficesFacade,
    public enhancedMode: EnhancedModeFacade,
    public geolocation: GeoLocationService,
    private analytics: AnalyticsFacade,
    private log: LogService
  ) {
    this.log.trace(TAGS, 'Constructing Offices Page');
  }

  ngOnInit() {
    this.analytics.pageView('offices');
    this.offices.viewed();
  }
}
