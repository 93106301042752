<ion-item lines="none" class="ion-margin-vertical">
  <ion-icon name="warning-outline" color="gold" size="large" slot="start"></ion-icon>
  <ion-label>
    <h2 class="ion-text-center">{{ 'benefit-error.warningTitle' | transloco }}</h2>
  </ion-label>
  <ion-icon name="warning-outline" color="gold" size="large" slot="end"></ion-icon>
</ion-item>

<ng-container *ngIf="error">
  <ion-item lines="none" class="ion-margin-vertical">
    <ion-label class="ion-text-wrap">
      <h3>{{ error.title | transloco }}</h3>
    </ion-label>
  </ion-item>

  <ion-item lines="none" class="ion-margin-vertical">
    <ion-label class="ion-text-wrap">
      <h4>{{ error.detailedMessage || error.message | transloco }}</h4>
    </ion-label>
  </ion-item>
</ng-container>

<ion-button size="large" color="gold" expand="block" class="ion-margin" (click)="popover.dismiss()">
  {{ 'buttons.close' | transloco }}
</ion-button>
