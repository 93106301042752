import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class ResetUIService {
  private spinner;

  constructor(private loading: LoadingController) {
  }

  async showResetSpinner(isSoft = false) {
    if (this.spinner) {
      return;
    }

    this.spinner = await this.loading.create({
      duration: 30000,
      backdropDismiss: false,
      message: `${isSoft ? 'Soft' : 'Hard'} Resetting app...`
    });
    await this.spinner.present();
    this.spinner.onDidDismiss(() => this.spinner = null);
  }

  async hideResetSpinner() {
    if (this.spinner) {
      await this.spinner.dismiss();
    }
  }
}
