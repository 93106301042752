import { Injectable } from '@angular/core';
import { LocalNotificationSchema } from '@capacitor/local-notifications';
import { TranslocoService } from '@ngneat/transloco';
import { add, format, isAfter, isBefore, isEqual, parseISO, set } from 'date-fns';
import { LocalNotificationsService } from '~core/services/local-notifications.service';
import { LogService } from '~core/services/log.service';
import { Appointment } from '~features/appointments/models';
import { Authority } from '~features/authorities/models';
import { APPT_RMD_KIND } from '~features/notifications/notifications.state';
import { Card } from '~features/registration/models';
import { UserSettings } from '~features/settings/models';
import {Platform} from '@ionic/angular';

const TAGS = ['Service', 'Notifications', 'Appointment'];

@Injectable()
export class AppointmentNotificationsService {
  constructor(
    private localNotifications: LocalNotificationsService,
    private transloco: TranslocoService,
    private log: LogService,
    private platform: Platform) {
  }

  async getCurrentNotifications(cardNumber?: string) {
    try {
      const pendingNotifications = await this.localNotifications.getPending();
      this.log.trace(TAGS, `Current scheduled ids: ${JSON.stringify(pendingNotifications.map(n => n.id))}`);

      const notifications = pendingNotifications.filter(({extra}) =>
        extra && extra.kind === APPT_RMD_KIND && (!cardNumber || extra.cardNumber === cardNumber)
      );
      return notifications;
    } catch (err) {
      this.log.error(TAGS, `! Error getting appointment notifications: ` +
        (err && err.message ? err.message : err ? err : ''));
      return [];
    }
  }

  async cancelNotifications(cardNumber?: string) {
    // Refactored: Cancel and clear only appt reminder notifications!!
    // Do not cancelAll or clearAll, as there are other notifications scheduled by WICShopper!
    const notifications = await this.getCurrentNotifications(cardNumber);
    for (const notification of notifications) {
      this.log.trace(TAGS, `Clearing notification with id ${notification.id}...`);
      try {
        await this.localNotifications.cancel(notification.id);
        this.log.trace(TAGS, `Notification with id ${notification.id} cleared.`);
      } catch (err) {
        this.log.trace(TAGS, `! Notification with id ${notification.id} errored while clearing.`);
      }
    }
  }

  async registerNotifications(cards: Card[], appointments: Appointment[], settings: UserSettings, authority: Authority) {
    try {
      this.log.trace(TAGS, `Clearing out old notifications...`);
      await this.cancelNotifications();
      this.log.trace(TAGS, `Old notifications cleared...`);

      if (!settings.appointments.allowAlerts) {
        this.log.info(TAGS, `Notifications have been disabled! Not scheduling new appointment notifications!`);
        return;
      }

      this.log.trace(TAGS, 'Checking cards for appointments:', appointments, cards);

      const cardAppointments = authority.isProvider
        ? cards.map(card => ({ // Find cards with appointments for provider states
            card,
            appointment: appointments.find(appt =>
              (card.householdId ? (appt.householdId || '').endsWith(card.householdId) : false) ||
              appt.cardNumber === card.cardNumber
            )
          }))
        : appointments.map(appt => ({ // Create virtual cards with appointments for non-provider states
          card: {
            householdId: appt.householdId,
            cardNumber: appt.cardNumber
          },
          appointment: appt
        }));

      this.log.trace(TAGS, 'Found cards with appointments: ', cardAppointments);

      let startId = 100;
      for (const ca of cardAppointments) {
        if (!ca.appointment) {
          this.log.debug(TAGS, `Card ${ca.card.cardNumber} does not have any scheduled appointments.`);
          continue;
        }
        await this.registerNotificationsForCard(ca.card, ca.appointment, settings, startId);
        startId += 100;
      }
    } catch (err) {
      this.log.error(TAGS, `! Error configuring appointment reminder notifications: ` +
        (err && err.message ? err.message : err ? err : ''));
    }
  }

  protected async registerNotificationsForCard(card: Card, appointment: Appointment, settings: UserSettings, startId: number) {
    try {
      const now = Date.now();
      const apptDate = parseISO(appointment.appointmentTime);
      this.log.trace(TAGS,
        `Card ${card.householdId || card.cardNumber} has an appointment scheduled on: ${format(apptDate, 'M-dd HH:mm')}`
      );

      const startOfNotifications = set(add(apptDate, {days: -settings.appointments.daysBefore}), {hours: 0, minutes: 0, seconds: 0});

      const notificationDate = set(startOfNotifications, {hours: settings.appointments.hour, minutes: settings.appointments.minute});

      this.log.trace(TAGS,
        `Card ${card.cardNumber} appointment reminder notification date is: ${format(notificationDate, 'yyyy-MM-dd HH:mm:ss')}`
      );

      if (isEqual(now, notificationDate) || isBefore(now, notificationDate)) {
        await this.scheduleNotifications(card, appointment, apptDate, notificationDate, settings, startId);
      } else {
        this.log.trace(TAGS, `Notification date ${
          format(notificationDate, 'yyyy-MM-dd HH:mm:ss')} for appt on ${
          format(apptDate, 'yyyy-MM-dd HH:mm:ss')} has passed! Skipping notifications!`
        );
      }
    } catch (err) {
      this.log.error(TAGS, `! Error configuring appointment notifications for card ${card.cardNumber}: ` +
        (err && err.message ? err.message : err ? err : ''), err);
    }
  }

  protected async scheduleNotifications(
    card: Card, appointment: Appointment, appointmentDate: Date,
    notificationDate: Date, settings: UserSettings, startId: number, count: number = 0
  ) {
    const dateTime = format(appointmentDate, 'MM/dd/yyyy');
    const title = this.transloco.translate('appointment-notifications.reminderTitle');
    const text = this.transloco.translate('appointment-notifications.reminderMessage', {
      wicClinic: appointment.locationName,
      date: format(appointmentDate, 'MM/dd')
    });
    const subTitle = text;

    const now = Date.now();
    this.log.trace(TAGS, `Now = ${format(now, 'yyyy-MM-dd HH:mm:ss')}`);
    for (let i = 0; i <= count; i++) {
      this.log.trace(TAGS, `NotificationDate = ${format(notificationDate, 'yyyy-MM-dd HH:mm:ss')}`);

      if (isEqual(notificationDate, now) || isAfter(notificationDate, now)) {
        this.log.debug(TAGS, `Scheduling appointment reminder notification on: ${format(notificationDate, 'yyyy-MM-dd HH:mm:ss')}`);
        await this.scheduleNotification(i + startId + 1, notificationDate, title, subTitle, text, card.cardNumber, card.householdId);
      }
      set(add(notificationDate, {days: 1}), {hours: settings.appointments.hour, minutes: settings.appointments.minute, seconds: 0});
    }
  }

  protected async scheduleNotification(
    id: number, notificationDate: Date, title: string, subTitle: string, text: string, cardNumber: string, hhid: string
  ) {
    try {
      const notification: LocalNotificationSchema = {
        id,
        title,
        body: text,
        schedule: {
          at: notificationDate
        },
        extra: {displayData: text, title, subTitle, kind: APPT_RMD_KIND, cardNumber, hhid}
      };
      this.log.trace(TAGS, 'Scheduling appointment notification:', notification);
      await this.localNotifications.schedule(notification);
    } catch (err) {
      this.log.warn(TAGS,
        `! Error scheduling an appointment reminder notification on: ${format(notificationDate, 'yyyy-MM-dd HH:mm:ss')}`
      );
    }
  }
}
