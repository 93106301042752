import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { Office, OfficeFavorite } from '~features/offices/models';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartOpen } from '@fortawesome/free-regular-svg-icons';
import { OfficesFacade } from '~features/offices/offices.facade';
import { SettingsFacade } from '~features/settings/settings.facade';

@Component({
  selector: 'wic-office-detail-modal',
  templateUrl: './office-detail-modal.component.html',
  styleUrls: ['./office-detail-modal.component.scss'],
})
export class OfficeDetailModalComponent implements OnInit {
  @Input() office: Office;
  @Input() favorites: OfficeFavorite[];

  faHeart = faHeart;
  faHeartOpen = faHeartOpen;


  constructor(
    public modal: ModalController,
    public offices: OfficesFacade,
    private analytics: AnalyticsFacade,
    public settings: SettingsFacade) {
  }

  ngOnInit() {
    this.analytics.pageView('office-detail');
  }
}
