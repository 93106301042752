import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { RefresherEventDetail } from '@ionic/angular';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { Redemption } from './redemption';

export const loadRedemptionsByBenefitCriteria = createAction('[Redemption] Load By Benefit Criteria');

export const refreshRedemptions = createAction(
  '[Redemption] Refresh',
  props<{ refreshEvent: RefresherEventDetail }>()
);

export const loadOlderMonthsRedemptions = createAction('[Redemptions] Load Older Months');

export const resetRedemptions = createAction('[Redemptions] Reset');

export interface RedemptionsState extends IEntityState<Redemption> {
  offsetMonths: number;
}

export const {
  initialState: initialRedemptionsState,
  facade: RedemptionsFacadeBase,
  actions: {
    loadAll: loadAllRedemptions,
    loadAllSuccess: loadAllRedemptionsSuccess,
    loadAllFailure: loadAllRedemptionsFailure,
    loadMany: loadManyRedemptions,
    clear: clearRedemptions
  },
  selectors: { selectAll: allRedemptions },
} = buildState(Redemption, { offsetMonths: 0 } as RedemptionsState);

const reduce = createReducer(
  initialRedemptionsState,
  on(resetRedemptions, state => ({ ...state, offsetMonths: 0 })),
  on(refreshRedemptions, state => ({ ...state, offsetMonths: 0 })),
  on(loadOlderMonthsRedemptions, state => ({ ...state, offsetMonths: state.offsetMonths + 1 }))
);

export function redemptionReducer(state = initialRedemptionsState, action: Action) {
  return reduce(state, action);
}

export const redemptionsState = createFeatureSelector<RedemptionsState>('redemption');

export const offsetMonths = createSelector(redemptionsState, state => state.offsetMonths);
