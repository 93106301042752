<ion-card data-cy="no-cards-warning">
  <ion-card-header>
    <ion-card-subtitle>{{ 'common.missingData' | transloco }}</ion-card-subtitle>
    <ion-card-title>
      {{ (hasCard ? 'benefits.noBenefitsTitle' : hasCards ? 'benefits.noCurrentCardTitle' : 'benefits.noCardsRegisteredTitle') | transloco }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ng-content select='[slot=info]'></ng-content>
        </ion-col>
      </ion-row>

      <ng-content select='[slot=buttons]'></ng-content>

      <ion-row class="pt-20" *ngIf="hasCard">
        <ion-col>
          <ion-button expand="block" (click)="benefits.refresh()" data-cy="no-benefits-retry">
            <ion-icon name="refresh" class="pr-5"></ion-icon>
            {{'buttons.retry' | transloco}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
