import { Component, Input } from '@angular/core';
import { BenefitsFacade } from '../../features/benefits/benefits.facade';

@Component({
  selector: 'wic-no-cards-warning',
  templateUrl: './no-cards-warning.component.html',
  styleUrls: ['./no-cards-warning.component.scss'],
})
export class NoCardsWarningComponent {
  @Input() hasCard: boolean;
  @Input() hasCards: boolean;

  constructor(public benefits: BenefitsFacade) {}
}
