import { Capacitor } from '@capacitor/core';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { dematerialize, filter, map, materialize, tap } from 'rxjs/operators';

export const NOTIFICATION = 'N';
export const COMPLETION = 'C';
export const ERROR = 'E';
export type Kind = 'N' | 'E' | 'C';

export const onlyKinds =<T> (...kinds: Kind[]): MonoTypeOperatorFunction<T> => pipe(
  materialize(),
  filter(notification => kinds.some(kind => notification.kind === kind)),
  dematerialize()
);

export const onlyNotifications = () => pipe(
  onlyKinds(NOTIFICATION)
);

export const onlyErrors = () => pipe(
  onlyKinds(ERROR)
);

export const onlyCompletions = () => pipe(
  onlyKinds(COMPLETION)
);

export const neverCompletes = <T>(): MonoTypeOperatorFunction<T> => onlyKinds(NOTIFICATION, ERROR)

export const ifTruthy = () => pipe(
  filter(value => !!value) // Not falsy
);

export const ifExists = <T>() => pipe(
  filter(value => value != null) // Not null or undefined
);

// export const ensure = <T, U extends NonNullable<T>>(defaultValue: U) => pipe(
//   map((value: T): U =>
//     value == null ? defaultValue : value as U
//   )
// );

export const negate = () => pipe(
  map((value: boolean): boolean => !value)
);

export const onNativePlatformOnly = <T>(): MonoTypeOperatorFunction<T> =>
  filter((data: T) => Capacitor.isNativePlatform());
