import { Injectable } from '@angular/core';
import { buildState, Clear, EntityActionTypes, IEntityState, LoadAll, ofEntityType } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { SubCatConfig } from '../subcat-configs/models';
import { SubCategory } from '../subcategories/models';

const TAGS = ['State', 'SubCatConfig'];

export const {
  initialState: initialSubCatConfigState,
  facade: SubCatConfigFacadeBase,
  selectors: { selectAll: allSubCatConfigs, selectEntities: subCatConfigEntities, },
} = buildState(SubCatConfig);

export function subCatConfigReducer(state = initialSubCatConfigState): IEntityState<SubCatConfig> {
  return state;
}

export const resetSubCatConfigs = createAction('[SubCatConfigs] Reset');

@Injectable()
export class SubCatConfigEffects {
  constructor(private actions$: Actions, private log: LogService) {}

  loadSubCatConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(SubCategory, EntityActionTypes.LoadAllSuccess),
      tap(() => this.log.debug(TAGS, 'Loading sub category configs...')),
      map(
        () =>
          new LoadAll(SubCatConfig, {
            version: 2,
          })
      )
    )
  );

  resetSubCatConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetSubCatConfigs),
      tap(() => this.log.debug(TAGS, 'Resetting subcategory configs...')),
      map(() => new Clear(SubCatConfig))
    )
  );
}
