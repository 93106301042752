import { Component, ViewChild } from '@angular/core';
import { RedemptionsFacade } from '~features/redemptions/redemptions.facade';
import { ShoppingListsFacade } from '~features/shopping-lists/shopping-lists.facade';
import { IonPopover, ModalController } from '@ionic/angular';
import { RedemptionItem } from '~features/redemptions/redemption';
import { ShoppingList } from '~features/shopping-lists/shopping-list.entity';
import { VendorInventoryProduct } from '~features/vendor-inventory-items/vendor-inventory-product.model';

@Component({
  selector: 'wic-add-item-from-past-purchases-modal',
  templateUrl: './add-item-from-past-purchases-modal.component.html',
  styleUrls: ['./add-item-from-past-purchases-modal.component.scss'],
})
export class AddItemFromPastPurchasesModalComponent {
  @ViewChild('warnNotCarried') popover: IonPopover

  constructor(public redemptions: RedemptionsFacade, public shoppingLists: ShoppingListsFacade, public modal: ModalController) {}

  itemSelected(item: RedemptionItem & { product?: VendorInventoryProduct }, shoppingList: ShoppingList) {
    if(!shoppingList.isMobileOrder || item.product) {
      return this.modal.dismiss(item, 'dismiss')
    }
    return this.popover.present();
  }
}
