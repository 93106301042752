import { Action, createAction, createReducer, createSelector, on, props } from '@ngrx/store';

import { EnhancedDevice } from './models';
import { HostsSettingsMode } from '~models';

import { State } from '../state';


export const registerEnhancedDevice = createAction(
  '[Enhanced Mode] Register device'
);

export const enhancedDeviceRegistered = createAction(
  '[Enhanced Mode] Device registered',
  props<{ registration: EnhancedDevice }>()
);

export const enhancedDeviceRegistrationError = createAction(
  '[Enhanced Mode] Device registration error',
  props<{ error: Error | any }>()
);

export const loadEnhancedDevice = createAction(
  '[Enhanced Mode] Load registration'
);

export const enhancedDeviceLoaded = createAction(
  '[Enhanced Mode] Registration loaded',
  props<{ registration: EnhancedDevice }>()
);

export const enhancedDeviceLoadError = createAction(
  '[Enhanced Mode] Registration load error',
  props<{ error: Error | any }>()
);

export const showEnhancedTools = createAction(
  '[Enhanced Mode] Show tools fab'
);

export const hideEnhancedTools = createAction(
  '[Enhanced Mode] Hide tools fab'
);

export const showDebuggingTools = createAction(
  '[Enhanced Mode] Show debugging tools'
);

export const showDebugSettings = createAction(
  '[Enhanced Mode] Show debug settings'
);

export const showHostSettings = createAction(
  '[Enhanced Mode] Show host settings',
  props<{ mode: HostsSettingsMode }>()
);

export const showAdminTools = createAction(
  '[Enhanced Mode] Show admin tools'
);

export const showNotificationInvestigator = createAction(
  '[Enhanced Mode] Show notification investigator'
);

export interface IEnhancedDeviceState {
  registration?: EnhancedDevice;
  isHidden: boolean;
}

export const enhancedDeviceInitialState = {
  isHidden: false
};

const reducer = createReducer(
  enhancedDeviceInitialState,
  on(enhancedDeviceRegistered, enhancedDeviceLoaded, (state, {registration}) => ({
    ...state,
    registration,
    isHidden: registration ? !registration.allowed : true
  })),
  on(showEnhancedTools, (state) => ({
    ...state,
    isHidden: false
  })),
  on(hideEnhancedTools, (state) => ({
    ...state,
    isHidden: true
  }))
);

export function enhancedDeviceReducer(state = enhancedDeviceInitialState, action: Action): IEnhancedDeviceState {
  return reducer(state, action);
}

export const getEnhancedDeviceState = (state: State): IEnhancedDeviceState =>
  state.enhancedDevice;

export const mapToRegistration = (state: IEnhancedDeviceState): EnhancedDevice =>
  state.registration;
export const mapToAllowed = (registration: EnhancedDevice): boolean =>
  !!registration && registration.allowed;

export const mapToIsHidden = (state: IEnhancedDeviceState): boolean =>
  state.isHidden;

export const enhancedDeviceRegistration = createSelector(
  getEnhancedDeviceState,
  mapToRegistration
);

export const isEnhanced = createSelector(
  enhancedDeviceRegistration,
  mapToAllowed
);

export const enhancedToolsHidden = createSelector(
  getEnhancedDeviceState,
  mapToIsHidden
);
