<form [formGroup]="form">
  <ion-list>
    <ion-list-header>
      {{'search-upc.searchTitle' | transloco}}
    </ion-list-header>
    <ion-item>
      <ion-input type="tel" formControlName="upc" placeholder="{{'search-upc.searchPlaceholder' | transloco}}" (ionFocus)="focused.emit($event)" data-cy="search-upc-input"></ion-input>
      <ion-icon slot="end" icon="close-circle" (click)="form.reset()" [color]="!form.controls['upc'].value ? 'light' : 'dark'"></ion-icon>
    </ion-item>
  </ion-list>
</form>
