import { createSelector } from '@ngrx/store';
import { Product } from '~features/products/models';
import { currentProductsFiltered } from '~features/products/product.state';
import { currentShoppingListVendor } from '~features/shopping-lists/shopping-lists.selectors';
import { vendorInventoryItemEntities } from './vendor-inventory-items.state';
import { VendorInventoryProduct } from '~features/vendor-inventory-items/vendor-inventory-product.model';

export const productsWithMatchingInventoryItems = createSelector(
  currentProductsFiltered,
  vendorInventoryItemEntities,
  (products, inventoryItems) =>
    products?.map(item => ({
      ...item,
      vendorInventoryItem: inventoryItems[item.itemNumber],
    })) || []
);

export const productsInVendorsInventory = createSelector(
  productsWithMatchingInventoryItems,
  products =>
    products
      .filter(product => !!product.vendorInventoryItem)
      .map(
        (product): VendorInventoryProduct => ({
          ...product,
          price: product.vendorInventoryItem.price,
          salePrice: product.vendorInventoryItem.salePrice,
          parWeight: product.vendorInventoryItem.parWeight,
          costUnit: product.vendorInventoryItem.costUnit,
        })
      )
);

export const productsAvailableBasedOnVendor = createSelector(
  currentShoppingListVendor,
  currentProductsFiltered,
  productsInVendorsInventory,
  (vendor, products, inventoryProducts): VendorInventoryProduct[] | Product[] => (vendor.hasActiveInventory ? inventoryProducts : products)
);

export const productsAvailableBasedOnVendorCount = createSelector(productsAvailableBasedOnVendor, products => products.length)
