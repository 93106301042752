import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '~core/config/config.service';

const GROUP_CODE_TO_COLOR_MAP = {
  // 0: 'var(--white)',
  0: '#fff',
  // 1: 'var(--green)',
  1: '#008000',
  // 2: 'var(--green)',
  2: '#008000',
  // 3: 'var(--yellow)',
  3: '#ccc200',
  // 4: 'var(--tufts-blue)'
  4: '#418AC1',
  5: '#008000',
  6: '#008000',
  7: '#008000',
  101: '#008000',
  102: '#9f9904',
};

export const getGroupCodeColor = (groupCode: number, overrides: Record<number, string> = {}) => ({ ...GROUP_CODE_TO_COLOR_MAP, ...overrides })[groupCode];

@Pipe({name: 'groupCodedColor'})
export class GroupCodedColorPipe implements PipeTransform {
  constructor(private config: ConfigService) {}

  transform(groupCode: number): any {
    return getGroupCodeColor(groupCode, this.config.vendorColors);
  }
}
