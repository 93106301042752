import { NgModule } from '@angular/core';
import { SubCatConfigFacade } from './subcat-config.facade';


@NgModule({
  providers: [
    SubCatConfigFacade
  ]
})
export class SubcatConfigsModule {
}
