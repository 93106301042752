import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Banner } from '~features/banners/models';

@Component({
  selector: 'wic-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
})
export class BannersComponent {
  
  @ViewChild('slides') slides?: ElementRef | undefined;
  @Input() detailedBanners: (Banner & { imageUrl: string })[];
  @Input() showButtons = true;
  @Output() viewBanner = new EventEmitter<Banner>();

  
  swiperSlideNext() {
    this.slides?.nativeElement.swiper.slideNext();
  }

  swiperSlidePrev() {
    this.slides?.nativeElement.swiper.slidePrev();
  }
}
