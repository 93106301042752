import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, forkJoin } from 'rxjs';
import { catchError, delay, map, switchMap, take, tap } from 'rxjs/operators';

import { WebCacheService } from '~core/interceptors/web-cache.service';
import { LogService } from '~core/services/log.service';
import { hardReset, resetComplete, softReset } from '~features/app/app.actions';
import { ResetUIService } from '~features/app/reset-ui.service';
import { resetBanners } from '~features/banners/banners.state';
import { benefitsResetComplete, resetBenefits } from '~features/benefits/benefits.actions';
import { clearEntries } from '~features/calculators/calculators.actions';
import { categoriesResetComplete, resetCategories } from '~features/categories/categories.state';
import { menuReset, menuResetComplete } from '~features/home/home.state';
import {
  resetProductCollection,
  resetProductCollectionComplete,
  resetTrackingCollection,
  resetTrackingCollectionComplete
} from '~features/products/product.state';
import { registrationResetComplete, resetRegistration } from '~features/registration/registration.actions';
import { languageReset, languageResetComplete, settingsReset, settingsResetComplete } from '~features/settings/settings.state';
import { resetSubCatConfigs } from '~features/subcat-configs/subcat-configs.state';
import { resetSubCategories, subCategoriesResetComplete } from '~features/subcategories/subcategories.state';
import { resetRecentUPCs, resetRecentUPCsComplete } from '~features/upc/upc.state';
import { resetAppointments } from '../appointments/appointments.state';
import { resetPushNotifications } from '../notifications/notifications.actions';
import { resetRedemptions } from '~features/redemptions/redemptions.state';

const TAGS = ['Effects', 'Reset'];

@Injectable()
export class ResetEffects {
  constructor(
    private actions$: Actions,
    private log: LogService,
    private nav: NavController,
    private webCache: WebCacheService,
    private resetUI: ResetUIService,
  ) {
  }

  showResetSpinner$ = createEffect(
    () => this.actions$.pipe(
      ofType(hardReset, softReset),
      switchMap(({type}) => this.resetUI.showResetSpinner(type === softReset.type))
    ),
    {dispatch: false}
  );

  purgeAllCachedHttpData$ = createEffect(
    () => this.actions$.pipe(
      ofType(hardReset),
      tap(() => this.log.warn(TAGS, 'App reset (hard): purging all cached data...')),
      switchMap(() => this.webCache.purgeAll()),
      tap(() => this.log.warn(TAGS, 'App reset (hard): all cached data purged.')),
      catchError(() => EMPTY)
    ),
    {dispatch: false}
  );

  purgeSelectCachedHttpData$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset),
      tap(() => this.log.warn(TAGS, 'App reset (soft): purging select cached data...')),
      switchMap(() => this.webCache.purge('vendorsAndOffices')),
      switchMap(() => this.webCache.purge('fullAPL')),
      switchMap(() => this.webCache.purge('recentlyFresh')),
      switchMap(() => this.webCache.purge('banners')),
      switchMap(() => this.webCache.purge('appointments')),
      switchMap(() => this.webCache.purge('menu')),
      switchMap(() => this.webCache.purge('banners')),
      switchMap(() => this.webCache.purge('benefits')),
      switchMap(() => this.webCache.purge('i18n')),
      tap(() => this.log.warn(TAGS, 'App reset (soft): select cached data purged.')),
      catchError(() => EMPTY)
    ),
    {dispatch: false}
  );

  resetLanguage$ = createEffect(
    () => this.actions$.pipe(
      ofType(hardReset, softReset),
      tap(() => this.log.warn(TAGS, 'Language has been reset...')),
      map(() => languageReset())
    )
  );

  hardResetAllSettings$ = createEffect(
    () => this.actions$.pipe(
      ofType(hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: resetting settings to default...')),
      map(() => settingsReset())
    )
  );

  resetClearRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing registration...')),
      map(() => resetRegistration())
    )
  );

  resetClearMenu$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing menu buttons...')),
      map(() => menuReset())
    )
  );

  resetClearBenefits$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing benefits...')),
      map(() => resetBenefits())
    )
  );

  resetClearAppointments$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing appointments...')),
      map(() => resetAppointments())
    )
  );

  resetClearCategories$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing categories...')),
      map(() => resetCategories())
    )
  );

  resetClearSubCategories$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing sub-categories...')),
      map(() => resetSubCategories())
    )
  );

  resetClearSubCatConfigs$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing sub-cat configs...')),
      map(() => resetSubCatConfigs())
    )
  );

  resetProductCollection$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: resetting local products table...')),
      map(() => resetProductCollection())
    )
  );

  resetTrackingCollection$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: resetting local tracking table...')),
      map(() => resetTrackingCollection())
    )
  );

  resetBanners$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing banners...')),
      map(() => resetBanners())
    )
  );

  resetRedemptions$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing redemptions...')),
      map(() => resetRedemptions())
    )
  );

  resetPushNotifications$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing push notifications...')),
      map(() => resetPushNotifications())
    )
  );

  resetCalculators$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing calculator entries...')),
      map(() => clearEntries())
    )
  );

  resetRecentUPCs$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset, hardReset),
      tap(() => this.log.warn(TAGS, 'App reset: clearing recent UPC entries...')),
      map(() => resetRecentUPCs())
    )
  );

  hardResetCompletion$ = createEffect(
    () => this.actions$.pipe(
      ofType(hardReset),
      tap(() => this.log.info(TAGS, 'Application has been HARD reset. Waiting for reset actions to complete...')),
      switchMap(() =>
        forkJoin([
          this.actions$.pipe(ofType(menuResetComplete), tap(() => this.log.trace(TAGS, 'Menu reset complete.')), take(1)),
          this.actions$.pipe(ofType(settingsResetComplete), tap(() => this.log.trace(TAGS, 'Settings reset complete.')), take(1)),
          this.actions$.pipe(ofType(registrationResetComplete), tap(() => this.log.trace(TAGS, 'Registration reset complete.')), take(1)),
          this.actions$.pipe(ofType(benefitsResetComplete), tap(() => this.log.trace(TAGS, 'Benefits reset complete.')), take(1)),
          this.actions$.pipe(ofType(categoriesResetComplete), tap(() => this.log.trace(TAGS, 'Categories reset complete.')), take(1)),
          this.actions$.pipe(ofType(subCategoriesResetComplete), tap(() => this.log.trace(TAGS, 'SubCategories reset complete.')), take(1)),
          this.actions$.pipe(ofType(resetProductCollectionComplete), tap(() => this.log.trace(TAGS, 'Products reset complete.')), take(1)),
          this.actions$.pipe(ofType(languageResetComplete), tap(() => this.log.trace(TAGS, 'Language reset complete.')), take(1)),
          this.actions$.pipe(
            ofType(resetTrackingCollectionComplete), tap(() => this.log.trace(TAGS, 'Product load tracking reset complete.')), take(1)
          ),
          this.actions$.pipe(ofType(resetRecentUPCsComplete), tap(() => this.log.trace(TAGS, 'Reset recent UPCs complete.')), take(1))
        ]).pipe(
          map(() => resetComplete())
        )
      )
    )
  );

  softResetCompletion$ = createEffect(
    () => this.actions$.pipe(
      ofType(softReset),
      tap(() => this.log.info(TAGS, 'Application has been SOFT reset. Waiting for reset actions to complete...')),
      switchMap(() =>
        forkJoin([
          this.actions$.pipe(ofType(menuResetComplete), tap(() => this.log.trace(TAGS, 'Menu reset complete.')), take(1)),
          this.actions$.pipe(ofType(registrationResetComplete), tap(() => this.log.trace(TAGS, 'Registration reset complete.')), take(1)),
          this.actions$.pipe(ofType(benefitsResetComplete), tap(() => this.log.trace(TAGS, 'Benefits reset complete.')), take(1)),
          this.actions$.pipe(ofType(categoriesResetComplete), tap(() => this.log.trace(TAGS, 'Categories reset complete.')), take(1)),
          this.actions$.pipe(ofType(subCategoriesResetComplete), tap(() => this.log.trace(TAGS, 'SubCategories reset complete.')), take(1)),
          this.actions$.pipe(ofType(resetProductCollectionComplete), tap(() => this.log.trace(TAGS, 'Products reset complete.')), take(1)),
          this.actions$.pipe(ofType(languageResetComplete), tap(() => this.log.trace(TAGS, 'Language reset complete.')), take(1)),
          this.actions$.pipe(
            ofType(resetTrackingCollectionComplete), tap(() => this.log.trace(TAGS, 'Product load tracking reset complete.')), take(1)
          ),
          this.actions$.pipe(ofType(resetRecentUPCsComplete), tap(() => this.log.trace(TAGS, 'Reset recent UPCs complete.')), take(1))
        ]).pipe(
          map(() => resetComplete())
        )
      )
    )
  );

  resetComplete$ = createEffect(
    () => this.actions$.pipe(
      ofType(resetComplete),
      tap(() => this.log.warn(TAGS, 'Application reset has completed!')),
      tap(() => this.nav.navigateRoot('/select-provider')),
      delay(1250),
      switchMap(() => this.resetUI.hideResetSpinner())
    ),
    {dispatch: false}
  );

}
