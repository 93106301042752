import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { format } from 'date-fns';
import { defer, iif, Observable, throwError, timer } from 'rxjs';
import { map, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { DeviceService } from '~core/services/device.service';
import { LogService } from '~core/services/log.service';
import { EnvironmentService } from '~features/environment.service';
import { Card } from '~features/registration/models';
import { CardNonActiveErrorComponent } from '../../components/card-non-active-error/card-non-active-error.component';
import { nonNull, pipe } from '../../util/func-util';
import { HardError } from '../error/error.state';
import { BenefitCriteria, BenefitError, BenefitInfo } from './models';
import { ConfigService } from '~core/config/config.service';

const TAGS = ['Service', 'Benefits'];

export interface BenefitParams {
  authorityId: string;
  householdId?: string;
  startDate?: string;
  endDate?: string;
}

export const addParam = (
  params: NonNullable<BenefitParams>,
  param: keyof BenefitParams,
  value?: string | null,
  defaultValue?: string
): BenefitParams =>
  nonNull(defaultValue)
    ? { ...params, [param]: nonNull(value) ? value : defaultValue }
    : nonNull(value)
    ? { ...params, [param]: value }
    : params;

export const addStartDate = (startDate?: string) => (params: NonNullable<BenefitParams>): BenefitParams =>
  addParam(params, 'startDate', startDate, format(new Date(), 'yyyy-MM-dd'));

export const addEndDate = (endDate?: string) => (params: NonNullable<BenefitParams>): BenefitParams =>
  addParam(params, 'endDate', endDate, format(new Date(), 'yyyy-MM-dd'));

export const addHouseholdId = (householdId?: string) => (params: NonNullable<BenefitParams>): BenefitParams =>
  addParam(params, 'householdId', householdId);

export const buildParams = (criteria: BenefitCriteria) =>
  pipe(addStartDate(criteria.startDate), addEndDate(criteria.endDate));

export type BenefitInfoWithServerInfo = BenefitInfo & { serverRetrievedAt?: string };

interface DatePattern {
  pattern: string;
  timezone: string;
  dst_offset: number;
  utc_offset: number;
}

export const ERROR_CODE_HANDLERS = {
  EAUTHINVALID: 'handleInvalidOrMismatched',
  EAUTHINACTIVE: 'handleInactiveAuthority',
  EAUTHMAPDISABLED: 'handleAuthorityConfigError',
  EAUTHCARDINVALID: 'handleAuthorityMismatch',
  ECARDINVALID: 'handleInvalidCard',
  ENOINTEGRATIONCFG: 'handleAuthorityConfigError',
  ENOINTEGRATION: 'handleAuthorityConfigError',
  ENOBENEFITS: 'handleNoBenefits',
  EEXPIREDBENEFITS: 'handleExpiredCard',
  EBADREQUEST: 'handleGenericError',
  EBADCARDNO: 'handleInvalidCard',
  ETIMEOUT: 'handleTimeoutError',
  EUNK: 'handleGenericError',
};

@Injectable()
export class BenefitsService {
  constructor(
    private http: HttpClient,
    private log: LogService,
    private device: DeviceService,
    private modals: ModalController,
    private env: EnvironmentService,
    private config: ConfigService,
  ) {}

  async warnNonActiveCard(benefitInfo: BenefitInfo, card: Card): Promise<boolean> {
    const modal = await this.modals.create({
      component: CardNonActiveErrorComponent,
      componentProps: {
        benefitInfo,
        card,
      },
    });

    await modal.present();
    const result = await modal.onDidDismiss();
    return result.role === 'ok';
  }

  load(criteria: BenefitCriteria): Observable<BenefitInfo> {
    const params: any = buildParams(criteria)({
      authorityId: criteria.authority.id.toString(),
      deviceId: this.device.uniqueId,
      appId: this.config.appId,
    });
    let maxRetries = 3;
    const delay = 1000;

    const url = `${this.env.apiHost}/v3/cards/${criteria.cardNumber}${
      criteria.householdId ? `/${criteria.householdId}` : ''
    }/benefits${criteria.isFuture ? '/future' : ''}`;

    this.log.trace(TAGS, `Requesting benefits for ${criteria.cardNumber} from API...`);
    this.log.trace(TAGS, `Url trace: ${url}`);

    return this.http
      .get<BenefitInfo>(url, { params })
      .pipe(
        tap(result => this.log.trace(TAGS, `Benefits for ${criteria.cardNumber} retrieved from API.`, result)),
        map(
          (result: BenefitInfoWithServerInfo) =>
            ({
              ...result,
              retrievedAt: new Date(result.serverRetrievedAt).valueOf(),
              retrievedAtFormatted: format(new Date(result.serverRetrievedAt).valueOf(), 'yyyy-MM-dd HH:mm:ss'),
            } as BenefitInfo)
        ),
        tap(benefit => this.log.trace(TAGS, `Benefits retrieved at ${benefit.retrievedAtFormatted} local time.`)),
        retryWhen(errors$ =>
          errors$.pipe(
            map(error =>
              error.status === 500
                ? this.handle500(criteria, error)
                : error.status === 400
                ? this.handle400(criteria, error)
                : error
            ),
            mergeMap(error =>
              iif(
                () => (error.retryable || !(error instanceof BenefitError)) && maxRetries > 0,
                timer(delay).pipe(
                  tap(() => maxRetries--),
                  tap(() =>
                    this.log.warn(
                      TAGS,
                      `Error Loading Benefits! Error is Retryable. Attempting Retry: ${error?.message}`,
                      error
                    )
                  )
                ),
                defer(() => throwError(error))
              )
            )
          )
        )
      );
  }

  private handle400(criteria: BenefitCriteria, error: any): Error {
    const handler = ERROR_CODE_HANDLERS[(error.error || error).ecode];
    if (handler) {
      return this[handler](criteria, error);
    }

    switch ((error.error || {}).code) {
      case '1401':
      case 1401:
      case '1301':
      case '0003':
      case 1301:
      case '0029': 
        return this.handleCardNotActive(criteria, error);
      case 12:
        return this.handleInvalidCard(criteria, error);
      case 13:
        return this.handleAuthorityMismatch(criteria, error);
      case '0101':
      case 101:
        return this.handleInvalidOrMismatched(criteria, error);
      case '0005':
        return this.handleExpiredCard(criteria, error);
      case 'ETIMEOUT':
        return this.handleTimeoutError(criteria, error);
      default:
        return this.handleGenericError(criteria, error);
    }
  }

  private handleGenericError(criteria, error: HttpErrorResponse): Error {
    this.log.error(TAGS, `An unexpected error occurred retrieving benefits! Card: ${criteria.cardNumber}`);
    return new HardError(
      'An unexpected error occurred retrieving benefits!',
      'Benefit Error',
      'An unexpected error occurred retrieving your benefits, and we have been unable to load them. This may be a temporary server issue,' +
        ' so try again later. If the issue persists, contact support.',
      error
    );
  }

  private handleInvalidCard(criteria: BenefitCriteria, error: HttpErrorResponse): Error {
    this.log.error(TAGS, `An invalid card number was specified! Card: ${criteria.cardNumber}`);
    return new BenefitError(
      'Invalid card number specified!',
      'Invalid Card Number',
      'An invalid card number was specified! Your provider does not recognize this card number. ' +
        'Try a different card, or contact your provider for assistance.',
      error
    );
  }

  private handleCardNotActive(criteria: BenefitCriteria, error: HttpErrorResponse): Error {
    this.log.error(TAGS, `${criteria.cardNumber} is not an active card`);
    return new BenefitError(
      'Card is not active!',
      'Card Is Not Active!',
      'Your currently registered card is not active. It may have been disabled, ' + 
      'or may have been flagged as lost or stolen. Please contact your agency ' +
      'for new card information. If you have your new card info, you may register ' +
      'it by clicking the button below.',
      error,
      false
    );
  }

  private handleNoBenefits(criteria: BenefitCriteria, error: HttpErrorResponse): Error {
    this.log.error(TAGS, `No benefits could be found! Card: ${criteria.cardNumber}`);
    return new BenefitError(
      'No Benefits Found!',
      'No Benefits',
      'We could not find any benefits for this card. Verify your card number, or contact your provider for assistance.',
      error
    );
  }

  private handleExpiredCard(criteria: BenefitCriteria, error: HttpErrorResponse): Error {
    this.log.error(TAGS, `An expired card number was specified! Card: ${criteria.cardNumber}`);
    return new BenefitError(
      'WIC card is expired!',
      'Expired Card',
      'The card number you provided is for an expired WIC benefits card! Try a different card, or contact your provider for assistance.',
      error
    );
  }

  private handleInvalidOrMismatched(criteria: BenefitCriteria, error: HttpErrorResponse): Error {
    this.log.error(TAGS, `There was an error determining the validity of your card! Card: ${criteria.cardNumber}`);
    return new BenefitError(
      'Error validating card!',
      'Invalid Card Number',
      'There was an error determining the validity of your card!',
      error
    );
  }

  private handleTimeoutError(criteria: BenefitCriteria, error: HttpErrorResponse): Error {
    this.log.error(TAGS, `Timeout occurred retrieving benefits! Card: ${criteria.cardNumber}`);
    return new BenefitError(
      'Timeout occurred retrieving benefits!',
      'Timed Out',
      'A timeout occurred while retrieving your benefits! This is a temporary error, try again later.',
      error,
      true
    );
  }

  private handleAuthorityMismatch(criteria: BenefitCriteria, error: HttpErrorResponse): Error {
    this.log.error(
      TAGS,
      `The card does not match the selected authority! Card: ${criteria.cardNumber} Authority: ${criteria.authority.id}`
    );
    return new BenefitError(
      'Card unknown for authority!',
      'Card Mismatch',
      'The card does not match the selected authority!',
      error
    );
  }

  private handle500(criteria: BenefitCriteria, error: any): Error {
    const handler = ERROR_CODE_HANDLERS[(error.error || error).ecode];
    if (handler) {
      return this[handler](criteria, error);
    }

    switch ((error.error || {}).code) {
      case 11:
        return this.handleInactiveAuthority(criteria, error);
      case -1:
      case -2:
        return this.handleAuthorityConfigError(criteria, error);
      default:
        return this.handleGenericError(criteria, error);
    }
  }

  private handleInactiveAuthority(criteria: BenefitCriteria, error: HttpErrorResponse): Error {
    this.log.error(TAGS, `The specified authority is currently inactive! Authority: ${criteria.authority.id}`);
    return new BenefitError(
      'Authority is inactive!',
      'Authority Error',
      'The specified authority is currently inactive!',
      error
    );
  }

  private handleAuthorityConfigError(criteria: BenefitCriteria, error: HttpErrorResponse) {
    this.log.error(
      TAGS,
      `The specified authority is currently misconfigured on the server! Authority: ${criteria.authority.id}`
    );
    return new BenefitError(
      'Authority is misconfigured!',
      'Authority Error',
      'The specified authority is currently misconfigured on the server',
      error
    );
  }
}
