import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, from } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { protocolButtonActivated } from '~features/home/home.state';
import { currentCard } from '~features/registration/registration.selectors';
import {
  editShoppingListItemQuantity,
  submitShoppingListItemQuantityEdit,
} from '~features/shopping-lists/shopping-list-items/shopping-list-items.actions';
import { addSubstitutionItem, promptSubstitution } from '~features/shopping-lists/substitutions/substitution.actions';
import { loadIfNeeded } from '~features/vendors/vendors.state';
import { uuid } from '../../util/func-util';
import { selectProducts } from '../products/product.state';
import { ShoppingListItem } from './shopping-list-items/shopping-list-item.entity';
import {
  createShoppingListItemSuccess, currentShoppingListItem,
  currentShoppingListItemKey,
  deleteShoppingListItemByKeySuccess,
} from './shopping-list-items/shopping-list-items.state';
import { ShoppingList } from './shopping-list.entity';
import { ShoppingListsUIService } from './shopping-lists-ui.service';
import {
  addCustomItemToShoppingList,
  addCVVItemToShoppingList,
  addItem,
  addItemToShoppingList,
  addShoppingList,
  openAddCustomItem,
  openAddCVVItem,
  openAddItem,
  openAddItemFromPastPurchases,
  openSelectBenefitSubCategory,
  openSelectItemFrom,
  setVendorTimeSlotForOrder,
  showSelectVendorForShoppingList,
  vendorChangedForShoppingList,
  vendorSelectedForShoppingList,
  viewShoppingList,
} from './shopping-lists.actions';
import {
  currentShoppingList,
  currentShoppingListKey,
  loadShoppingListSuccess,
  selectShoppingList,
  updateShoppingListSuccess,
} from './shopping-lists.state';
import { currentShoppingListBenefit } from '~features/shopping-lists/shopping-lists.selectors';
import {
  substitutionsForCurrentShoppingListItem
} from '~features/shopping-lists/substitutions/substitutions.selectors';
import { Product } from '~features/products/models';
import {
  currentShoppingListItemProduct
} from '~features/shopping-lists/shopping-list-items/shopping-list-items.selectors';
import { RedemptionItem } from '~features/redemptions/redemption';
import { VendorInventoryProduct } from '~features/vendor-inventory-items/vendor-inventory-product.model';

@Injectable()
export class ShoppingListsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly nav: NavController,
    private readonly shoppingListsUI: ShoppingListsUIService
  ) {}

  loadVendorsForShoppingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(protocolButtonActivated),
      filter(({ button }) => button.name === 'shoppingLists'),
      map(() => loadIfNeeded({ force: false }))
    )
  );

  addShoppingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addShoppingList),
      withLatestFrom(this.store.select(currentCard)),
      map(
        ([{ name, isMobileOrder }, { cardNumber }]): ShoppingList => ({
          name,
          isMobileOrder,
          cardNumber,
          id: uuid(),
        })
      ),
      map(entity => loadShoppingListSuccess({ entity }))
    )
  );

  navigateToShoppingListOnCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadShoppingListSuccess),
      tap(({ entity }) => this.nav.navigateForward(`/shopping-lists/${entity.id}`)),
    ),
    { dispatch: false }
  )

  selectCreatedShoppingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadShoppingListSuccess),
      map(({ entity }) => selectShoppingList({ entity }))
    )
  );

  showInitialSelectVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadShoppingListSuccess),
      map(({ entity }) => showSelectVendorForShoppingList({ shoppingList: entity }))
    )
  );

  selectVendorForList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showSelectVendorForShoppingList),
      exhaustMap(({ shoppingList }) =>
        from(this.shoppingListsUI.openSelectVendorModal(shoppingList)).pipe(
          filter(({ role }) => role === 'dismiss'),
          map(({ data }) => vendorSelectedForShoppingList({ vendor: data, shoppingList }))
        )
      )
    )
  );

  checkForVendorChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorSelectedForShoppingList),
      filter(({ vendor, shoppingList }) => vendor.entryId !== shoppingList.vendorId),
      map(({ vendor, shoppingList }) => vendorChangedForShoppingList({ vendor, shoppingList }))
    )
  );

  setSelectedVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorChangedForShoppingList),
      map(
        ({ vendor, shoppingList }): ShoppingList => ({
          ...shoppingList,
          vendorId: vendor.entryId,
        })
      ),
      map(entity => updateShoppingListSuccess({ entity }))
    )
  );

  viewShoppingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewShoppingList),
      tap(({ shoppingList }) => this.nav.navigateForward(`/shopping-lists/${shoppingList.id}`)),
      map(({ shoppingList }) => selectShoppingList({ entity: shoppingList }))
    )
  );

  selectProductsForItemAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openAddItem, openAddItemFromPastPurchases),
      map(({ benefit }) =>
        selectProducts({ categoryId: benefit.categoryId, subCategoryId: benefit.subCategoryId })
      )
    )
  );

  addItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addItem),
      withLatestFrom(this.store.select(currentShoppingList)),
      map(([{ benefit }, shoppingList]) =>
        shoppingList.isMobileOrder || benefit.categoryId !== 19 && benefit.categoryId !== 97
          ? openSelectItemFrom({ benefit })
          : openAddCVVItem({ benefit })
      )
    )
  );

  openSelectItemFrom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openSelectItemFrom),
      switchMap(({ benefit }) =>
        from(this.shoppingListsUI.openSelectItemFrom(benefit)).pipe(
          filter(({ role }) => role === 'dismiss'),
          map(({ data }) =>
            data === 'redemptions'
              ? openAddItemFromPastPurchases({ benefit })
              : openAddItem({ benefit })
          )
        )
      )
    )
  );

  openAddItemModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openAddItem),
      switchMap(({ benefit }) =>
        from(this.shoppingListsUI.openAddItemModal(benefit)).pipe(
          filter(({ role }) => role === 'dismiss'),
          map(({ data }) => ({ data, id: uuid() })),
          switchMap(({ data, id }) => [
            addItemToShoppingList({ product: data, benefit, id }),
            editShoppingListItemQuantity({ id }),
          ])
        )
      )
    )
  );

  openConfirmItemModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addItemToShoppingList),
      switchMap(({ benefit, product, purchaseQuantity, units, id }) =>
        from(this.shoppingListsUI.openConfirmItemModal(benefit, product)).pipe(
          filter(({ role }) => role === 'dismiss' || role === 'skip' || role === 'cancel'),
          withLatestFrom(this.store.select(currentShoppingListKey)),
          switchMap(([{ data, role }, shoppingListId]) => [
            ...(role === 'dismiss' ? [
              promptSubstitution({
                benefit,
                shoppingListItem: {
                  ...product,
                  categoryId: +product.categoryId,
                  subCategoryId: +product.subCategoryId,
                  id: id,
                  shoppingListId: shoppingListId + '',
                  itemId: product.itemId + '',
                  productId:
                    product.id ||
                    `${product.categoryId}_${product.subCategoryId}_${product.itemNumber}`,
                  purchaseQuantity: purchaseQuantity || 1,
                  units: units || product.size / product.packageSize,
                  checkedOff: false,
                } as ShoppingListItem,
              }),
            ] 
            : role === 'cancel' ? 
              [deleteShoppingListItemByKeySuccess({ key: data })] : []),
            ...(role === 'cancel' ? [] : [submitShoppingListItemQuantityEdit({ purchaseQuantity: data })]),
          ])
        )
      )
    )
  );

  setEditingOnReConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSubstitutionItem),
      withLatestFrom(this.store.select(currentShoppingListItemKey), this.store.select(substitutionsForCurrentShoppingListItem)),
      filter(([, id, substitutions]) => substitutions.length === 0),
      map(([, id]) => editShoppingListItemQuantity({ id: id + '' }))
    )
  );

  reOpenConfirmItemModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSubstitutionItem),
      withLatestFrom(this.store.select(currentShoppingListItemProduct), this.store.select(currentShoppingListItem), this.store.select(currentShoppingListBenefit), this.store.select(substitutionsForCurrentShoppingListItem)),
      filter(([, product, item, benefit, substitutions]) => substitutions.length === 0),
      switchMap(([, product, item, benefit ], ) =>
        from(this.shoppingListsUI.openConfirmItemModal(benefit, product)).pipe(
          filter(({ role }) => role === 'dismiss' || role === 'skip'),
          switchMap(({ data, role }) => [
            ...(role === 'dismiss' ? [
              promptSubstitution({
                benefit,
                shoppingListItem: item,
              }),
            ] : []),
            submitShoppingListItemQuantityEdit({ purchaseQuantity: data }),
          ])
        )
      )
    )
  );

  openAddItemFromPastPurchasesModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openAddItemFromPastPurchases),
      switchMap(({ benefit }) =>
        from(this.shoppingListsUI.openAddItemFromPastPurchases(benefit)).pipe(
          filter(({ role }) => role === 'dismiss'),
          map(({ data }) => data as RedemptionItem & { product: VendorInventoryProduct }),
          map((data) =>
            addItemToShoppingList({
              product: { 
                ...data,
                ...data.product
              },
              benefit,
              purchaseQuantity: data.purchaseQuantity,
              units: data.units,
            })
          )
        )
      )
    )
  );

  openAddItemModalCVV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openAddCVVItem),
      switchMap(({ benefit }) =>
        from(this.shoppingListsUI.openAddCVVItemModal()).pipe(
          filter(({ role }) => role === 'dismiss'),
          map(({ data }) => addCVVItemToShoppingList({ name: data, benefit }))
        )
      )
    )
  );

  openAddCustomItemPopover$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openAddCustomItem),
      switchMap(() => this.shoppingListsUI.openAddCustomItemModal()),
      filter(({ role }) => role === 'dismiss'),
      map(({ data }) => addCustomItemToShoppingList({ name: data }))
    )
  );

  openSelectBenefitSubCategoryModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openSelectBenefitSubCategory),
        switchMap(() => this.shoppingListsUI.openSelectBenefitSubCategoryModal())
      ),
    { dispatch: false }
  );

  dismissSelectBenefitSubCategoryModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addItemToShoppingList, addCVVItemToShoppingList),
        switchMap(() => this.shoppingListsUI.dismissSelectBenefitSubCategoryModal())
      ),
    { dispatch: false }
  );

  addItemToShoppingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addItemToShoppingList),
      withLatestFrom(this.store.select(currentShoppingListKey)),
      map(
        ([{ product, purchaseQuantity, units, id }, shoppingListId]): ShoppingListItem => ({
          ...product,
          categoryId: +product.categoryId,
          subCategoryId: +product.subCategoryId,
          id: id || uuid(),
          shoppingListId: shoppingListId + '',
          itemId: product.itemId + '',
          productId:
            product.id || `${product.categoryId}_${product.subCategoryId}_${product.itemNumber}`,
          purchaseQuantity: purchaseQuantity || 1,
          units: units || product.size / product.packageSize,
          checkedOff: false,
        })
      ),
      map(entity => createShoppingListItemSuccess({ entity }))
    )
  );

  addCVVItemToShoppingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCVVItemToShoppingList),
      withLatestFrom(this.store.select(currentShoppingListKey)),
      map(
        ([{ name, benefit }, shoppingListId]): ShoppingListItem => ({
          id: uuid(),
          shoppingListId: shoppingListId + '',
          productId: '',
          itemNumber: '',

          categoryId: benefit.categoryId,
          subCategoryId: benefit.subCategoryId,
          price: 0, // Only needed for Mobile Orders since shopping list uses this cvv add process.

          purchaseQuantity: 1,
          uom: benefit.uom,
          units: 0,
          description: name,
          checkedOff: false,
        })
      ),
      map(entity => createShoppingListItemSuccess({ entity }))
    )
  );

  addCustomItemToShoppingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCustomItemToShoppingList),
      withLatestFrom(this.store.select(currentShoppingListKey)),
      map(
        ([{ name }, shoppingListId]): ShoppingListItem => ({
          id: uuid(),
          shoppingListId: shoppingListId + '',
          productId: '',
          itemNumber: '',

          categoryId: -1,
          subCategoryId: -1,
          price: 0,

          purchaseQuantity: 1,
          uom: '$$$',
          units: 0,
          description: name,
          checkedOff: false,
          customItem: true,
        })
      ),
      map(entity => createShoppingListItemSuccess({ entity }))
    )
  );

  setVendorTimeSlot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setVendorTimeSlotForOrder),
      withLatestFrom(this.store.select(currentShoppingList)),
      map(([{ vendorTimeSlot, orderDate }, shoppingList]) =>
        updateShoppingListSuccess({
          entity: {
            ...shoppingList,
            vendorTimeSlot,
            orderDate,
          },
        })
      )
    )
  );
}
