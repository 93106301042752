import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RegistrationFacade } from '~features/registration/registration.facade';

@Component({
  selector: 'wic-card-non-active-error',
  templateUrl: './card-non-active-error.component.html',
  styleUrls: ['./card-non-active-error.component.scss'],
})
export class CardNonActiveErrorComponent {
  constructor(public modal: ModalController, public registration: RegistrationFacade) {
  }
}
