import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { loadAllEnhancedIssues, loadAllEnhancedIssuesByAuthority } from "./enhanced-issues.state";
import { map, withLatestFrom } from "rxjs";
import { Store } from "@ngrx/store";
import { State } from "~features/state";
import { currentProviderId } from "~features/registration/registration.selectors";
import { registrationRestored } from "~features/registration/registration.actions";

@Injectable()
export class EnhancedIssuesEffects {

  constructor(
    private readonly actions$: Actions,
    private store: Store<State>
  ) {}

  loadAllEnhancedIssuesByAuthority$ = createEffect(() => 
    this.actions$.pipe(
      ofType(loadAllEnhancedIssuesByAuthority),
      withLatestFrom(this.store.select(currentProviderId)),
      map(([, authorityId]) => loadAllEnhancedIssues({ criteria: { parents: { authorities: authorityId }}}))
    )
  );

  preloadEnhancedIssuesByAuthority$ = createEffect(() => 
    this.actions$.pipe(
      ofType(registrationRestored),
      map(() => loadAllEnhancedIssuesByAuthority())
    )
  );
}