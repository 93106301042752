import { createAction, props } from '@ngrx/store';
import { Product } from '~features/products/models';
import { EnrichedBenefit } from '~features/benefits/models';
import { ShoppingListItem } from '~features/shopping-lists/shopping-list-items/shopping-list-item.entity';
import { SubstitutionType } from '~features/substitution-configs/substitution-config.entity';

export const promptSubstitution = createAction(
  '[Substitution] Prompt Substitution',
  props<{ shoppingListItem: ShoppingListItem, benefit: EnrichedBenefit; }>()
);

export const addSubstitutionItem = createAction(
  '[Substitution] Add Item',
  props<{ product: Product; }>()
);

export const showSubstitutionInfo = createAction(
  '[Substitution] Show Info',
  props<{ substitutionType: SubstitutionType}>()
)
