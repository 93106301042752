<ion-card>
  <ion-card-content>
    <ion-card-subtitle *ngIf="!hideUpdatedAt">
      <h4 class="heavy">{{'benefits.lastUpdateLabel' | transloco:getLastUpdateParams(benefitInfo)}}</h4>
    </ion-card-subtitle>
    <ion-card-subtitle *ngIf="hideUpdatedAt">
      <h4 class="heavy">{{'benefits.remainingBenefitsLabel' | transloco}}</h4>
    </ion-card-subtitle>
    <p class="heavy">
      <ion-text color="danger">{{'benefits.recentPurchaseWarningLabel' | transloco}}</ion-text>
    </p>
  </ion-card-content>
</ion-card>
