import { Injectable } from '@angular/core';
import { Position, Geolocation, CallbackID } from '@capacitor/geolocation';
import { BehaviorSubject, from, Observable, of, ReplaySubject } from 'rxjs';
import { takeWhile, tap, throttleTime } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { ifExists } from '../../util/rxjs-util';
import { Capacitor } from '@capacitor/core';

const TAGS = ['Service', 'GeoLocation'];

@Injectable()
export class GeoLocationService {
  static location: Position = null;
  private watcher$$ = new BehaviorSubject<Position>(null);

  private watchId: CallbackID = null;
  private location$$ = new ReplaySubject<Position>(1);
  private watcher$ = this.watcher$$.pipe(
    throttleTime(30000),
    tap(() => this.log.debug(TAGS, 'Watching current geoposition...')),
    ifExists(),
    tap((location: Position) => GeoLocationService.location = location),
    tap((location: Position) => this.log.info(TAGS, `Current geoposition is ${location.coords}`)),
    takeWhile(() => !!this.watchId)
  ).subscribe(
    this.location$$
  );

  get location$(): Observable<Position> {
    return this.location$$.asObservable();
  }

  get location(): Position {
    return GeoLocationService.location;
  }

  constructor(private log: LogService) {
  }

  async watch() {
    if (this.watchId) {
      return;
    }

    this.watchId = await Geolocation.watchPosition({
      maximumAge: 30000,
      timeout: 15000
    }, (location) => {
      this.watcher$$.next(location);
    });
  }

  async unwatch() {
    await Geolocation.clearWatch({ id: this.watchId })
    this.watchId = null;
  }

  getLocation(): Observable<Position> {
    this.log.debug(TAGS, 'Getting current geoposition...');
    return from(Geolocation.getCurrentPosition({
      timeout: 30000
    })).pipe(
      tap((location: Position) => this.log.info(TAGS, `Current geoposition is ${location.coords}`)),
      tap((location: Position) => GeoLocationService.location = location),
      tap((location: Position) => this.location$$.next(location))
    );
  }

  async hasLocationPermission(): Promise<boolean> {
    this.log.trace(TAGS, 'Checking if app has location permission...');
    if (!Capacitor.isNativePlatform()) {
      return true;
    }

    const permitted = await Geolocation.checkPermissions();
    if (permitted.coarseLocation === 'granted' || permitted.location === 'granted') {
      return true;
    }

    return false;
  }

  async requestLocationPermission() {
    const req = await Geolocation.requestPermissions({permissions : ['coarseLocation', 'location']})

    return req.location;
  }
}
