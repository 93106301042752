import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubstitutionType } from '~features/substitution-configs/substitution-config.entity';

@Component({
  selector: 'wic-substitution-info-modal-component',
  templateUrl: './substitution-info-modal.component.html',
  styleUrls: ['./substitution-info-modal.component.scss'],
})
export class SubstitutionInfoModalComponent {
  @Input() substitutionType: SubstitutionType;

  constructor(public modal: ModalController) {}
}
