import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DeviceService } from '~core/services/device.service';
import { LogService } from '~core/services/log.service';
import { currentCards, currentProviderId } from '~features/registration/registration.selectors';
import { userSettings, userSettingsChanged } from '~features/settings/settings.state';
import { State } from '~features/state';
import { initializationCompleted } from '../app/app.actions';
import { NotificationDetailsService } from './notification-details.service';
import { getNotifications, scheduleBenefitNotification, scheduleNotifications } from './notifications.actions';
import { ConfigService } from '~core/config/config.service';

const TAGS = ['Effects', 'Notifications'];

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private config: ConfigService,
    private store: Store<State>,
    private log: LogService,
    private device: DeviceService,
    private notificationDetails: NotificationDetailsService
  ) {
  }

  checkUserCardStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializationCompleted, userSettingsChanged),
      withLatestFrom(this.store.select(currentCards)),
      filter(([, cards]) => cards.length >= 1),
      tap(([, {length}]) => this.log.info(TAGS, `Loading Notifications for ${length} cards...`)),
      map(([, cards]) => getNotifications({cards}))
    )
  );

  getNotificationDetails$ = createEffect(() =>
      this.actions$.pipe(
        ofType(getNotifications),
        withLatestFrom(
          this.store.select(currentProviderId),
        ),
        map(([{cards}, authorityId]) => ({
          authorityId,
          appId: this.config.appId,
          deviceId: this.device.uniqueId,
          startDate: format(new Date(), 'yyyy-MM-dd'),
          endDate: format(new Date(), 'yyyy-MM-dd'),
          cards
        })),
        switchMap(query =>
          this.notificationDetails.load(query)
        ),
        map((details) => scheduleNotifications({details}))
      )
  );

  checkBenefitNotificationsAndSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(scheduleNotifications),
      switchMap(({details}) => details),
      tap(({cardNumber}) => this.log.info(TAGS, 'Scheduling BENEFIT notifications for:', cardNumber)),
      withLatestFrom(this.store.select(userSettings)),
      map(([{endDate}, settings]) => scheduleBenefitNotification({benefitsEndDate: endDate, settings, force: false}))
    )
  );
}
