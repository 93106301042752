import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'SubCategory',
  uriName: 'subcategories'
})
export class SubCategory {
  authorityId: number;
  @Key categoryId: number;
  @Key subCategoryId: number;
  description: string;
  uom: string;
  packageSize: number;
}
