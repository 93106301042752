<ion-card>
  <ion-card-header>
    <ion-card-subtitle>{{ 'common.sorry' | transloco }}</ion-card-subtitle>
    <ion-card-title>
      {{ 'benefits.expiredBenefitsTitle' | transloco }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <p>{{ 'benefits.expiredBenefitsInfo' | transloco:{expDate: benefits?.formattedEnd} }}</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
