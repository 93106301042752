import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShoppingListBenefit } from '../../../../features/shopping-lists/models/shopping-list-benefit.model';
import { ShoppingListItem } from '../../../../features/shopping-lists/shopping-list-items/shopping-list-item.entity';
import { QuantityEditFormControl } from '../../quantity-edit.form';
import { SubstitutionType } from '~features/substitution-configs/substitution-config.entity';

@Component({
  selector: 'wic-mobile-order-item-group',
  templateUrl: './mobile-order-item-group.component.html',
  styleUrls: ['./mobile-order-item-group.component.scss'],
})
export class MobileOrderItemGroupComponent {
  benefit: ShoppingListBenefit;
  isProduce = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('benefit') set _benefit(value: ShoppingListBenefit) {
    this.benefit = value;
    this.isProduce = value.categoryId === 19 || value.categoryId === 97
  }

  @Input() editingId: string;
  @Input() form?: QuantityEditFormControl;
  @Output() edit = new EventEmitter<string>();
  @Output() save = new EventEmitter<number>();
  @Output() remove = new EventEmitter<string>();
  @Output() addSubstitution = new EventEmitter<ShoppingListItem>();
  @Output() removeSubstitution = new EventEmitter<string>();
  @Output() showNonSubInfo = new EventEmitter<SubstitutionType>();

  trackByShoppingListItem(index: number, item: ShoppingListItem) {
    return item.id;
  }
}
