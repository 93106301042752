import { Injectable } from '@angular/core';
import { Clear } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import { LogService } from '~core/services/log.service';
import { resetBanners } from '~features/banners/banners.state';
import { resetBenefits } from '~features/benefits/benefits.actions';
import { clearEntries } from '~features/calculators/calculators.actions';
import { resetCategories } from '~features/categories/categories.state';
import { menuReset } from '~features/home/home.state';
import { MenuButton } from '~features/home/models';
import { registrationChanged } from '~features/registration/registration.actions';
import { resetSubCatConfigs } from '~features/subcat-configs/subcat-configs.state';
import { resetSubCategories } from '~features/subcategories/subcategories.state';
import { resetAppointments } from '../appointments/appointments.state';
import { resetProductCollection, resetTrackingCollection } from '../products/product.state';
import { resetRedemptions } from '~features/redemptions/redemptions.state';
import { resetVendors } from '~features/vendors/vendors.state';
import { resetOffices } from '~features/offices/offices.state';

const TAGS = ['Effects', 'Registration Change'];

@Injectable()
export class RegistrationChangeEffects {
  constructor(private actions$: Actions, private log: LogService) {
  }

  registrationChangedClearMenu$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: clearing menu buttons...')),
      map(() => menuReset())
    )
  );

  registrationChangedResetBenefits$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting benefits...')),
      map(() => resetBenefits())
    )
  );

  registrationChangedResetAppointments$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting appointments...')),
      map(() => resetAppointments())
    )
  );

  registrationChangedResetCategories$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting categories...')),
      map(() => resetCategories())
    )
  );

  registrationChangedResetSubCategories$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting sub-categories...')),
      map(() => resetSubCategories())
    )
  );

  registrationChangedResetSubCatConfigs$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting sub-cat configs...')),
      map(() => resetSubCatConfigs())
    )
  );

  registrationChangedResetBanners$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting banners...')),
      map(() => resetBanners())
    )
  );

  registrationChangedResetRedemptions$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting redemptions...')),
      map(() => resetRedemptions())
    )
  );

  registrationChangedResetProducts$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting products...')),
      map(() => resetProductCollection())
    )
  );

  registrationChangedResetProductTracking$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting product tracking...')),
      map(() => resetTrackingCollection())
    )
  );

  registrationChangedResetCalculator$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting calculator...')),
      map(() => clearEntries())
    )
  );

  registrationChangedResetVendors$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting vendors...')),
      map(() => resetVendors())
    )
  );

  registrationChangedResetOffices$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration changed: resetting offices...')),
      map(() => resetOffices())
    )
  );
}
