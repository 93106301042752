import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from '../../components/components.module';
import { SelectWicProviderPage } from './select-wic-provider.page';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ComponentsModule,
    RouterModule.forChild([
      {path: '', component: SelectWicProviderPage}
    ]),
    TranslocoModule
  ],
  declarations: [SelectWicProviderPage]
})
export class SelectWicProviderModule {}
