import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { IonInput } from '@ionic/angular';
import IMask from 'imask';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * ion-mask directive, based on text-mask module
 */
@Directive({
  selector: '[wicMask]',
  providers: [IonInput],
})
export class MaskDirective implements OnInit, OnDestroy {
  @Input('wicMask') mask = '';
  private onDestroy: Subject<void> = new Subject<void>();

  constructor(public ionInput: IonInput) {}

  ngOnInit() {
    this.configureInput();
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  async configureInput() {
    const input = await this.ionInput.getInputElement();
    const mask = IMask(input, {
      mask: this.mask,
    });
    // masking when event is not generated
    mask.unmaskedValue = input.value;
    this.ionInput.value = input.value;
    // masking when event is generated
    this.ionInput.ionChange.pipe(takeUntil(this.onDestroy)).subscribe((event: CustomEvent) => {
      const { value } = event.detail;
      mask.unmaskedValue = value;
      // mask.unmaskedValue = input.value;
      this.ionInput.value = input.value;
    });
  }
}
