import { compose, createSelector } from '@ngrx/store';
import { addMonths, compareAsc, isAfter } from 'date-fns';
import { addDays } from 'date-fns/fp';
import { tpipe } from '../../util/func-util';
import { parseBenefitDate, parseBenefitHeaderDate, prettyFormatBenefitDate } from './benefit-utils';
import { currentBenefits } from './benefits.selectors';
import { benefitsState } from './benefits.state';
import { EnrichedBenefit,EnrichedBenefitInfo } from './models';

export const futureBenefitsCache = createSelector(benefitsState, state => state.futureBenefitsCache);
export const currentFutureGroupIndex = createSelector(benefitsState, state => state.currentFutureGroupIndex ?? 0);

export const allFutureBenefits = createSelector(futureBenefitsCache, (future): EnrichedBenefit[] =>
  [].concat(...Object.values(future).map(info => info.benefits))
);

export const futureBenefitsGroups = createSelector(
  allFutureBenefits,
  (benefits): Record<string, EnrichedBenefit[]> =>
    benefits?.reduce((groups, benefit) => {
      const key = `${benefit.beginDate}_${benefit.endDate}`;
      return {
        ...groups,
        [key]: groups[key] ? [...groups[key], benefit] : [benefit],
      };
    }, {})
);

export const parseKeyStartDate = (key: string) => parseBenefitDate(key.split('_')[0]);

export const futureBenefitGroupsKeys = createSelector(futureBenefitsGroups, groups =>
  Object.keys(groups).sort((a, b) => compareAsc(parseKeyStartDate(a), parseKeyStartDate(b)))
);

export const latestFutureBenefitEndDate = createSelector(
  futureBenefitGroupsKeys,
  keys => keys?.length && keys[keys.length - 1]?.split('_')[1]
);

export const mapToLatestBenefitsEndDate = (benefits: EnrichedBenefitInfo, futureEnd?: string): string =>
  futureEnd || benefits?.endDate;

export const latestBenefitsEndDate = createSelector(
  currentBenefits,
  latestFutureBenefitEndDate,
  mapToLatestBenefitsEndDate
);

export const resolveBenefitDate = (date: string): Date =>
  date.includes('-') ? parseBenefitDate(date) : parseBenefitHeaderDate(date);

// Compose is reversed, so functions run in reverse order of use.
// Add 1 to show start of next benefits period
export const formatLatestBenefitsEndDate = compose(
  prettyFormatBenefitDate,
  addDays(1),
  resolveBenefitDate,
);

export const latestBenefitsEndDateFormatted = createSelector(
  latestBenefitsEndDate,
  (date) => date && formatLatestBenefitsEndDate(date)
);

export const currentFutureGroupKey = createSelector(
  futureBenefitGroupsKeys,
  currentFutureGroupIndex,
  (keys, index) => keys[index]
);

export const atFirstGroup = createSelector(currentFutureGroupIndex, index => index === 0);
export const afterFirstGroup = createSelector(currentFutureGroupIndex, index => index > 0);
export const afterThirdMonth = createSelector(
  currentFutureGroupKey,
  key => !!key && isAfter(parseBenefitDate(key.split('_')[0]), addMonths(new Date(), 3))
);

export const currentFutureGroup = createSelector(
  futureBenefitsGroups,
  currentFutureGroupKey,
  (groups, key) =>
    groups?.[key] && {
      benefits: groups[key],
      formattedStart: prettyFormatBenefitDate(parseBenefitDate(key.split('_')[0])),
      formattedEnd: prettyFormatBenefitDate(parseBenefitDate(key.split('_')[1])),
    }
);

export const futureGroupExists = createSelector(currentFutureGroup, currentGroup => !!currentGroup);
