<ion-button expand="block" *ngIf="!balanceEmpty" (click)="added.emit()" color="green" data-cy="entry-add-button">
  <fa-icon icon="plus" class="pr-5"></fa-icon>
  <strong>{{'calculator-cvv.addNewItemLabel' | transloco}}</strong>
</ion-button>

<ion-button expand="block" *ngIf="authority?.isOfflineOrVerifyOnly" (click)="balanceSet.emit()" color="green">
  <fa-icon icon="hand-holding-usd" class="pr-5"></fa-icon>
  <strong>{{'calculator-cvv.setBalanceButton' | transloco}}</strong>
</ion-button>

