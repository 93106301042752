import { Action, createReducer, on } from '@ngrx/store';
import { anyExist, exists } from '../../util/func-util';
import {
  calculatorBalancesRestored,
  calculatorEntriesRestored,
  clearCategoryEntriesConfirmed,
  clearEntries,
  decrementEntry,
  incrementEntry,
  mergeEntry,
  removeEntry,
  setBenefitBalance,
  toggleEntry,
} from './calculators.actions';
import { CalculatorBalance } from './models/calculator-balance';
import { CalculatorEntry } from './models/calculator-entry';

export const CVV_CATEGORY = 19;
export const CVV_CATEGORIES = [19, 97];

export interface HasCategories {
  categoryId: number;
  subCategoryId?: number;
}

export interface ICalculatorState {
  entries: CalculatorEntry[];
  balances: CalculatorBalance [];
  entriesRestoredAt?: number;
  balancesRestoredAt?: number;
}

export const initialCalculatorState: ICalculatorState = {
  entries: [],
  balances: []
};

const isMatchingCategories = (a: HasCategories) => (b: HasCategories): boolean =>
  a.categoryId === b.categoryId;

const isMatchingEntry = (a: CalculatorEntry) => (b: CalculatorEntry): boolean =>
  a.name === b.name && isMatchingCategories(a)(b);

const isNotMatchingEntry = (a: CalculatorEntry) => (b: CalculatorEntry): boolean =>
  !isMatchingEntry(a)(b);

const isNotMatchingBalance = (a: CalculatorBalance) => (b: CalculatorBalance): boolean =>
  !isMatchingCategories(a)(b);

const normalizeSubCategory = (entry: CalculatorEntry) =>
  0;

const mergeQuantities = (entry: CalculatorEntry, existing: CalculatorEntry): number =>
  anyExist(entry.quantity, existing.quantity)
    ? (entry.quantity || 0) + (existing.quantity || 0)
    : 0;

const mergePrices = (entry: CalculatorEntry, existing: CalculatorEntry): number =>
  anyExist(entry.unitPrice, existing.unitPrice)
    ? (entry.unitPrice || 0) + (existing.unitPrice || 0)
    : 0;

const mergeSelections = (entry: CalculatorEntry, existing: CalculatorEntry): boolean =>
  exists(existing.isSelected) && !exists(entry.isSelected)
    ? existing.isSelected
    : exists(entry.isSelected)
    ? entry.isSelected
    : true;

const mergeExisting = (existing: CalculatorEntry, entry: CalculatorEntry) => ({
  ...existing,
  subCategoryId: normalizeSubCategory(entry),
  isSelected: mergeSelections(entry, existing),
  quantity: mergeQuantities(entry, existing),
  unitPrice: mergePrices(entry, existing)
});

const mergeIntoExisting = (entry: CalculatorEntry) => (existing: CalculatorEntry): CalculatorEntry =>
  isMatchingEntry(entry)(existing) ? mergeExisting(existing, entry) : existing;

const replaceExisting = (entry: CalculatorEntry) => (existing: CalculatorEntry): CalculatorEntry =>
  isMatchingEntry(entry)(existing) ? entry : existing;

const addToExisting = (existingEntries: CalculatorEntry[], entry: CalculatorEntry) =>
  [...(existingEntries || []), {...entry, subCategoryId: normalizeSubCategory(entry)}];

const existingHasEntry = (existingEntries: CalculatorEntry[], entry: CalculatorEntry) =>
  (existingEntries || []).some(isMatchingEntry(entry));

export const mergeEntryIntoState = (state, {entry, replace}) => ({
  ...state,
  entries: existingHasEntry(state.entries, entry)
    ? state.entries.map(replace ? replaceExisting(entry) : mergeIntoExisting(entry))
    : addToExisting(state.entries, entry)
});

export const clearAllEntriesFromState = () => initialCalculatorState;

export const clearCategoryEntriesAndBalanceFromState = (state, {categoryId}) => ({
  ...state,
  entries: (state.entries || []).filter(entry => entry.categoryId !== categoryId),
  balances: (state.balances || []).filter(balance => balance.categoryId !== categoryId)
});

const flipIsSelected = (entry: CalculatorEntry) => (existing: CalculatorEntry): CalculatorEntry =>
  isMatchingEntry(entry)(existing) ? {...existing, isSelected: !existing.isSelected} : existing;

export const toggleEntryInState = (state, {entry}) => ({
  ...state,
  entries: (state.entries || []).map(flipIsSelected(entry))
});

const increment = (entry: CalculatorEntry) => (existing: CalculatorEntry): CalculatorEntry =>
  isMatchingEntry(entry)(existing) ? {...existing, quantity: existing.quantity + 1} : existing;

export const incrementEntryInState = (state, {entry}) => ({
  ...state,
  entries: (state.entries || []).map(increment(entry))
});

const decrement = (entry: CalculatorEntry) => (existing: CalculatorEntry): CalculatorEntry =>
  isMatchingEntry(entry)(existing) ? {...existing, quantity: existing.quantity - 1} : existing;

export const decrementEntryInState = (state, {entry}) => ({
  ...state,
  entries: (state.entries || []).map(decrement(entry))
});

export const removeEntryFromState = (state, {entry}) => ({
  ...state,
  entries: (state.entries || []).filter(isNotMatchingEntry(entry))
});

export const addEntriesAndTrackRestorationTimestampInState = (state, {entries}) => ({
  ...state,
  entries,
  entriesRestoredAt: Date.now()
});

export const addBalancesAndTrackRestorationTimestampInState = (state, {balances}) => ({
  ...state,
  balances,
  balancesRestoredAt: Date.now()
});

export const setBenefitBalanceForCatAndSubcat = (state, {balance}) => ({
  ...state,
  balances: [...(state.balances || []).filter(isNotMatchingBalance(balance)), {
    ...balance,
    subCategoryId: 0
  }]
});

const reduce = createReducer(
  initialCalculatorState,
  on(calculatorEntriesRestored, addEntriesAndTrackRestorationTimestampInState),
  on(calculatorBalancesRestored, addBalancesAndTrackRestorationTimestampInState),
  on(mergeEntry, mergeEntryIntoState),
  on(clearEntries, clearAllEntriesFromState),
  on(clearCategoryEntriesConfirmed, clearCategoryEntriesAndBalanceFromState),
  on(toggleEntry, toggleEntryInState),
  on(incrementEntry, incrementEntryInState),
  on(decrementEntry, decrementEntryInState),
  on(removeEntry, removeEntryFromState),
  on(setBenefitBalance, setBenefitBalanceForCatAndSubcat)
);

export function calculatorReducer(state: ICalculatorState, action: Action): ICalculatorState {
  return reduce(state, action);
}
