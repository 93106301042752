<ion-header mode="ios">
  <ion-toolbar color="tufts-blue-shaded" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h4>{{'cant-buy-this.contentTitle' | transloco}}</h4>
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon icon="fingerprint" size="2x" [styles]="{opacity: '0.075', color: 'var(--white)'}"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content >
  <ion-card color="tufts-blue-shaded">
    <ion-card-header>
      <ion-card-subtitle>{{'cant-buy-this.infoSubtitle' | transloco}}</ion-card-subtitle>
      <ion-card-title>{{'cant-buy-this.infoTitle' | transloco}}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <p>{{'cant-buy-this.instructionMessage' | transloco}}</p>
    </ion-card-content>
    <ion-card-content>
      <p>{{'cant-buy-this.photoMessage' | transloco}}</p>
    </ion-card-content>
  </ion-card>

  <form [formGroup]="form">
    <ion-list>
      <ion-list-header>
        <span class="text-larger">{{'cant-buy-this.userInfoHeader' | transloco}}</span>
      </ion-list-header>
      <ion-item>
        <fa-icon slot="start" icon="user" [fixedWidth]="true"
                 [styles]="{color: submitted && form.get('name').invalid ? 'var(--lava)' : 'var(--med-grey)'}">
        </fa-icon>
        <ion-input formControlName="name" [placeholder]="('cant-buy-this.namePlaceholder' | transloco)"
                   [autofocus]="true"
                   [color]="submitted && form.get('name').invalid ? 'lava' : undefined"
                   clearInput>
        </ion-input>
      </ion-item>
      <ion-item *ngIf="form.get('name').invalid && form.get('name').touched">
        <ion-text color="lava">
          {{'cant-buy-this.nameRequired' | transloco}}
        </ion-text>
      </ion-item>

      <ion-item>
        <fa-icon slot="start" icon="at" [fixedWidth]="true"
                 [styles]="{color: submitted && form.get('email').invalid ? 'var(--lava)' : 'var(--med-grey)'}">
        </fa-icon>
        <ion-input formControlName="email" type="email" [placeholder]="('cant-buy-this.emailPlaceholder' | transloco) + ' *'"
                   [color]="submitted && form.get('email').invalid ? 'lava' : undefined" clearInput>
        </ion-input>
      </ion-item>
      <ion-item *ngIf="form.get('email').invalid && form.get('email').touched">
        <ion-text color="lava">
          {{'cant-buy-this.emailRequired' | transloco}}
        </ion-text>
      </ion-item>

      <ion-item>
        <fa-icon slot="start" icon="phone-alt" [fixedWidth]="true"
                 [styles]="{color: submitted && form.get('phone').invalid ? 'var(--lava)' : 'var(--med-grey)'}">
        </fa-icon>
        <ion-input formControlName="phone" type="tel" [placeholder]="('cant-buy-this.phonePlaceholder' | transloco)"
                   [color]="submitted && form.get('phone').invalid ? 'lava' : undefined" clearInput>
        </ion-input>
      </ion-item>

      <ion-item>
        <fa-icon slot="start" icon="store" [fixedWidth]="true"
                 [styles]="{color: submitted && form.get('storeName').invalid ? 'var(--lava)' : 'var(--med-grey)'}">
        </fa-icon>
        <ion-input formControlName="storeName" [placeholder]="('cant-buy-this.storeNamePlaceholder' | transloco)"
                   [color]="submitted && form.get('storeName').invalid ? 'lava' : undefined" clearInput>
        </ion-input>
      </ion-item>

      <ion-item>
        <fa-icon slot="start" icon="hashtag" [fixedWidth]="true"
                 [styles]="{color: submitted && form.get('storeLocNum').invalid ? 'var(--lava)' : 'var(--med-grey)'}">
        </fa-icon>
        <ion-input formControlName="storeLocNum" [placeholder]="('cant-buy-this.storeLocNumPlaceholder' | transloco)"
                   [color]="submitted && form.get('storeLocNum').invalid ? 'lava' : undefined" clearInput>
        </ion-input>
      </ion-item>

      <ion-item>
        <fa-icon slot="start" icon="user-tag" [fixedWidth]="true"
                 [styles]="{color: submitted && form.get('who').invalid ? 'var(--lava)' : 'var(--med-grey)'}">
        </fa-icon>
        <ion-label>{{ 'cant-buy-this.whoPlaceholder' | transloco }}</ion-label>
        <ion-select formControlName="who">
          <ion-select-option value="participant">{{'cant-buy-this.whoParticipantLabel' | transloco}}</ion-select-option>
          <ion-select-option value="staff">{{'cant-buy-this.whoWICStaffLabel' | transloco}}</ion-select-option>
          <ion-select-option value="store">{{'cant-buy-this.whoStoreStaffLabel' | transloco}}</ion-select-option>
          <ion-select-option value="other">{{'cant-buy-this.whoOtherLabel' | transloco}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item *ngIf="form.get('who').invalid && form.get('who').touched">
        <ion-text color="lava">
          {{'cant-buy-this.whoRequired' | transloco}}
        </ion-text>
      </ion-item>
    </ion-list>

    <ion-list>
      <ion-list-header>
        <span class="text-larger">{{'cant-buy-this.productInfoHeader' | transloco}}</span>
      </ion-list-header>
      <ion-item>
        <fa-icon slot="start" icon="copyright" [fixedWidth]="true"
                 [styles]="{color: submitted && form.get('brand').invalid ? 'var(--lava)' : 'var(--med-grey)'}">
        </fa-icon>
        <ion-input formControlName="brand" [placeholder]="('cant-buy-this.brandPlaceholder' | transloco) + ' *'"
                   [color]="submitted && form.get('brand').invalid ? 'lava' : undefined" clearInput>
        </ion-input>
      </ion-item>
      <ion-item *ngIf="form.get('brand').invalid && form.get('brand').touched">
        <ion-text color="lava">
          {{'cant-buy-this.brandRequired' | transloco}}
        </ion-text>
      </ion-item>

      <ion-item>
        <fa-icon slot="start" icon="carrot" [fixedWidth]="true"
                 [styles]="{color: submitted && form.get('productName').invalid ? 'var(--lava)' : 'var(--med-grey)'}">
        </fa-icon>
        <ion-input formControlName="productName" type="email"
                   [placeholder]="('cant-buy-this.productNamePlaceholder' | transloco) + ' *'"
                   [color]="submitted && form.get('productName').invalid ? 'lava' : undefined" clearInput>
        </ion-input>
      </ion-item>
      <ion-item *ngIf="form.get('productName').invalid && form.get('productName').touched">
        <ion-text color="lava">
          {{'cant-buy-this.productNameRequired' | transloco}}
        </ion-text>
      </ion-item>

      <ion-item>
        <fa-icon slot="start" icon="weight-hanging" [fixedWidth]="true"
                 [styles]="{color: submitted && form.get('packageSize').invalid ? 'var(--lava)' : 'var(--med-grey)'}">
        </fa-icon>
        <ion-input formControlName="packageSize"
                   [placeholder]="('cant-buy-this.packageSizePlaceholder' | transloco) + ' *'"
                   [color]="submitted && form.get('packageSize').invalid ? 'lava' : undefined" clearInput>
        </ion-input>
      </ion-item>
      <ion-item *ngIf="form.get('packageSize').invalid && form.get('packageSize').touched">
        <ion-text color="lava">
          {{'cant-buy-this.packageSizeRequired' | transloco}}
        </ion-text>
      </ion-item>

      <ion-item>
        <fa-icon slot="start" icon="barcode" [fixedWidth]="true"
                 [styles]="{color: submitted && form.get('upc').invalid ? 'var(--lava)' : 'var(--med-grey)'}">
        </fa-icon>
        <ion-input formControlName="upc" type="number"
                   [placeholder]="('cant-buy-this.upcPlaceholder' | transloco) + ' *'"
                   [color]="submitted && form.get('upc').invalid ? 'lava' : undefined" clearInput>
        </ion-input>
        <fa-stack slot="end" class="btn-scan-barcode"
            *ngIf="!form.controls['upc'].value"
            (click)="missingProduct.scanBarcode()">
          <fa-icon icon="circle" stackItemSize="1x" transform="grow-6" [styles]="{color: 'var(--med-grey)'}"></fa-icon>
          <fa-icon icon="camera" stackItemSize="1x" transform="shrink-4" [styles]="{color: 'var(--white)'}"></fa-icon>
        </fa-stack>
      </ion-item>
      <ion-item *ngIf="form.get('upc').invalid && form.get('upc').touched">
        <ion-text color="lava">
          {{'cant-buy-this.upcRequired' | transloco}}
        </ion-text>
      </ion-item>

      <ion-item>
        <fa-icon slot="start" icon="info-circle" [fixedWidth]="true" [styles]="{opacity: '0', color: 'transparent'}">
        </fa-icon>
        <ion-textarea formControlName="addtlInfo" [rows]="4"
                      [placeholder]="('cant-buy-this.addtlInfoPlaceholder' | transloco)">
        </ion-textarea>
        <ion-icon slot="end" icon="close-circle" (click)="form.controls['addtlInfo'].reset()"
                  [color]="!form.controls['addtlInfo'].value ? 'light' : 'dark'">
        </ion-icon>
      </ion-item>
    </ion-list>

    <ion-list>
      <ion-list-header>
        <span class="text-larger" [class.text-red]="submitted && (form.controls['productImage'].invalid || form.controls['labelImage'].invalid)">
          {{'cant-buy-this.photoHeader' | transloco}} *
        </span>
      </ion-list-header>
    </ion-list>

    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-list>
            <wic-icbt-image-component
              [image]="form.controls['productImage'].value"
              [imageAlt]="'cant-buy-this.productPhotoAlt' | transloco"
              [captureButton]="'cant-buy-this.captureProductPhotoButton' | transloco"
              [capturePlaceholder]="'cant-buy-this.captureProductPhotoPlaceholder' | transloco"
              [clearButton]="'cant-buy-this.clearProductPhotoButton' | transloco"
              (clearImage)="missingProduct.clearCapturedPhoto()"
              (captureImage)="missingProduct.captureProductPhoto()"
            ></wic-icbt-image-component>

            <wic-icbt-image-component
              [image]="form.controls['labelImage'].value"
              [imageAlt]="'cant-buy-this.labelImageAlt' | transloco"
              [captureButton]="'cant-buy-this.captureLabelPhotoButton' | transloco"
              [capturePlaceholder]="'cant-buy-this.captureLabelPhotoPlaceholder' | transloco"
              [clearButton]="'cant-buy-this.clearLabelPhotoButton' | transloco"
              (clearImage)="missingProduct.clearCapturedLabel()"
              (captureImage)="missingProduct.captureLabelPhoto()"
            ></wic-icbt-image-component>

            <wic-icbt-image-component
              [image]="form.controls['upcImage'].value"
              [imageAlt]="'cant-buy-this.upcImageAlt' | transloco"
              [captureButton]="'cant-buy-this.captureUpcPhotoButton' | transloco"
              [capturePlaceholder]="'cant-buy-this.captureUpcPhotoPlaceholder' | transloco"
              [clearButton]="'cant-buy-this.clearUpcPhotoButton' | transloco"
              (clearImage)="missingProduct.clearCapturedUpcPhoto()"
              (captureImage)="missingProduct.captureUpcPhoto()"
            ></wic-icbt-image-component>


          </ion-list>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="form.get('productImage').invalid || form.get('labelImage').invalid || form.get('upcImage').invalid">
        <ion-col size="12">
          <ion-text color="lava">
            {{'cant-buy-this.photosRequired' | transloco}}
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer mode="ios">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button expand="block" color="dark-red" (click)="nav.pop()">
            <fa-icon icon="times-circle"></fa-icon>
            <span class="pl-5">{{ 'cant-buy-this.cancelButton' | transloco}}</span>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button expand="block" color="green" (click)="submit()" data-cy="cant-buy-this-submit"> <!-- [disabled]="form.invalid" -->
            <fa-icon icon="cloud-upload-alt"></fa-icon>
            <span class="pl-5">{{ 'cant-buy-this.sendButton' | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
