import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule, Routes } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faBuilding,
  faCog,
  faDirections,
  faEnvelope,
  faInfoCircle,
  faLink,
  faList,
  faMap,
  faPhone,
  faSearch,
  faStar,
  faTimes,
  faLocationArrow
} from '@fortawesome/free-solid-svg-icons';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { IsFavoritePipe } from './is-favorite.pipe';
import { OfficeAsGeopositionPipe } from './office-as-geoposition.pipe';
import { OfficeDetailModalComponent } from './office-detail-modal/office-detail-modal.component';
import { OfficesListTabComponent } from './offices-list-tab/offices-list-tab.component';
import { OfficesListComponent } from './offices-list/offices-list.component';
import { OfficesMapTabComponent } from './offices-map-tab/offices-map-tab.component';
import { OfficesMapComponent } from './offices-map/offices-map.component';
import { OfficesSearchModalComponent } from './offices-search-modal/offices-search-modal.component';
import { OfficesEffects } from './offices.effects';

import { OfficesPage } from './offices.page';
import { OfficesService } from './offices.service';
import { ScrollingModule } from '@angular/cdk/scrolling';

const routes: Routes = [
  {
    path: '',
    component: OfficesPage,
    children: [
      {
        path: 'map',
        component: OfficesMapTabComponent,
      },
      {
        path: 'list',
        component: OfficesListTabComponent,
      },
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
    ],
  },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        EffectsModule.forFeature([
            OfficesEffects
        ]),
        ComponentsModule,
        TranslocoModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        PipesModule,
        GoogleMapsModule,
        ScrollingModule
    ],
    declarations: [
        OfficesPage,
        OfficesMapTabComponent,
        OfficesListTabComponent,
        OfficesMapComponent,
        OfficesListComponent,
        OfficeDetailModalComponent,
        OfficesSearchModalComponent,
        IsFavoritePipe,
        OfficesMapComponent,
        OfficeAsGeopositionPipe
    ],
    providers: [
        OfficesService,
        OfficesEffects
    ]
})
export class OfficesPageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(
      faSearch,
      faDirections,
      faBuilding,
      faStar,
      faTimes,
      faPhone,
      faEnvelope,
      faLink,
      faInfoCircle,
      faMap,
      faList,
      faCog,
      faDotCircle,
      faLocationArrow,
    );
  }
}
