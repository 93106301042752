import { Component, Input } from '@angular/core';
import { EnrichedBenefitInfo } from '~features/benefits/models';

@Component({
  selector: 'wic-expired-benefits-warning',
  templateUrl: './expired-benefits-warning.component.html',
  styleUrls: ['./expired-benefits-warning.component.scss'],
})
export class ExpiredBenefitsWarningComponent {
  @Input() benefits: EnrichedBenefitInfo;
}
