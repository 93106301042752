import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wic-vendor-icon',
  templateUrl: './vendor-icon.component.html',
  styleUrls: ['./vendor-icon.component.scss']
})

export class VendorIconComponent {
  localIcons = [6,7,101,102];
  @Input() groupCode: number;

  get isLocal() {
    return this.localIcons.includes(this.groupCode);
  }
}
