import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ErrorsFacade } from '~features/error/errors.facade';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'wic-get-support-modal',
  templateUrl: './get-support-modal.component.html',
  styleUrls: ['./get-support-modal.component.scss'],
})
export class GetSupportModalComponent implements OnInit {
  form: UntypedFormGroup;
  submitted = false;

  constructor(public modal: ModalController, public errors: ErrorsFacade, public builder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.builder.group({
      name: [null, Validators.required],
      email: [null, Validators.required],
      message: [null, Validators.required]
    });
  }

  submit() {
    if (this.form.invalid) {
      this.submitted = true;
    }

    this.modal.dismiss(this.form.value, 'ok');
  }
}
