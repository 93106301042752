import { Injectable } from '@angular/core';
import { LocalNotificationSchema } from '@capacitor/local-notifications';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { LocalNotificationsService } from '~core/services/local-notifications.service';
import { LogService } from '~core/services/log.service';
import { PushNotificationsService } from '~core/services/push-notifications.service';
import { viewAppointment } from '~features/appointments/appointments.state';
import { viewBenefits } from '~features/benefits/benefits.actions';
import { APPT_RMD_KIND, BENE_EXP_KIND } from '~features/notifications/notifications.state';
import { State } from '~features/state';
import { Badge } from '@capawesome/capacitor-badge';

const TAGS = ['Service', 'Notifications'];

@Injectable()
export class NotificationsService {
  private isShowing = false;

  constructor(
    private localNotifications: LocalNotificationsService,
    private pushNotifications: PushNotificationsService,
    private log: LogService,
    private nav: NavController,
    private alerts: AlertController,
    private store: Store<State>,
    private platform: Platform,
  ) {
  }

  async initialize() {
    try {
      // if (!this.platform.is('capacitor')) {
      //   return;
      // }
      this.log.trace(TAGS, `Checking if has permission to use local notifications...`);
      const hasPermission = await this.localNotifications.hasPermission();
      if (!hasPermission) {
        this.log.trace(TAGS, `Requesting permission to use local notifications:`);
        const permission = await this.localNotifications.requestPermission();
        if (permission) {
          this.log.trace(TAGS, `Permission to use local notifications GRANTED!`);
        } else {
          this.log.trace(TAGS, `Permission to use local notifications DENIED!`);
        }
      } else {
        this.log.trace(TAGS, `Permission to use local notifications already GRANTED!`);
      }
    } catch (err) {
      this.log.error(TAGS, `Notifications initialization failed:` + (err && err.message ? err.message : err ? err : ''));
    }
  }

  async handleNotification(notification: LocalNotificationSchema) {
    if (this.isShowing) {
      this.log.trace(TAGS, `Notification already showing! Title: ${notification.title}`);
      return;
    }
    this.log.trace(TAGS, `Notification clicked! Title: ${notification.title}`);
    this.isShowing = true;

    setTimeout(async () => {
      const alert = await this.alerts.create({
        header: notification.title,
        message: notification.body,
        buttons: [{
          text: 'Ok',
          handler: () => {
            try {
              this.isShowing = false;

              this.log.trace(TAGS, `Notification triggered!`, notification);


              if (notification.extra.kind === BENE_EXP_KIND) {
                this.log.trace(TAGS, `Benefits notification triggered!`, notification);
                this.store.dispatch(viewBenefits({ pendingExpirationMode: true }));
              } else if (notification.extra.kind === APPT_RMD_KIND) {
                this.log.trace(TAGS, `Appointment notification triggered!`, notification);
                // TODO: Figure out what to do about switching cards if the card the notification was for
                //  differs from the currently selected card
                this.store.dispatch(viewAppointment({
                  cardNumber: notification.extra.cardNumber, hhid: notification.extra.hhid
                }));
              }
            } catch (err) {
              this.log.error(TAGS, 'Error handling notification:', err);
            } finally {
              Badge.clear();
            }
          }
        }]
      });
      await alert.present();
      await alert.onDidDismiss();
    }, 500);
  }
}
