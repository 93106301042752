<ion-header mode="ios">
  <ion-toolbar color="tufts-blue-shaded" mode="ios">
    <ion-title>
      <h3 class="h3-tw">{{ benefit.subCategory }}</h3>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" data-cy="home-button" (click)="product.setProductFilter(null); modals.dismiss(null, 'cancel')">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar color="tufts-blue-shaded">
    <ion-title>
      <h3 *ngIf="benefit.categoryId === 19 || benefit.categoryId === 97" class="h3-tw">
        {{ (benefit.availableQuantity - (benefit | benefitShoppingListTotal)) | currency }} {{ 'item-list.remainingLabel' | transloco }}
      </h3>
      <h3 *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97" class="h3-tw">
        {{ benefit.availableQuantity - (benefit | benefitShoppingListTotal) }} {{ benefit.uom }} {{ 'item-list.remainingLabel' | transloco }}
      </h3>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar color="tufts-blue-shaded">
    <ion-searchbar
      class="searchbar"
      data-cy="item-list-search-bar"
      placeholder="{{'item-list.searchPlaceholder' | transloco}}"
      [debounce]="700"
      (ionChange)="product.setProductFilter($event.target.value)"
      (ionClear)="product.setProductFilter(null)"
      (ionCancel)="product.setProductFilter(null)"
    >
    </ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false">
<!--  <wic-item-list-header data-cy="current-benefit" [benefit]="benefits.currentBenefit$ | async"> </wic-item-list-header>-->

  <section *ngIf="product.isSelecting$ | async; else currentItems" data-cy="products-loading">
    <ion-list>
      <ion-item>
        <ion-label><h2>{{'item-list.selectingLabel' | transloco}}</h2></ion-label>
        <ion-spinner slot="end" name="dots"></ion-spinner>
      </ion-item>
    </ion-list>
  </section>

  <ng-template #currentItems>
<!--    <wic-item-loading-error *ngIf="product.isSQLiteError$ | async"></wic-item-loading-error>-->

    <wic-orderable-product-list
      *ngIf="(product.isSQLiteError$ | async) === false"
      [hideTitle]="true"
      [showNoProductsWarning]="true"
      [hasFilterCriteria]="product.hasFilterCriteria$ | async"
      [totalCount]="vendorInventoryItems.productsAvailableBasedOnVendorCount$ | async"
      [products]="vendorInventoryItems.productsAvailableBasedOnVendor$ | async"
      [benefit]="benefit"
      (retry)="product.selectProducts($event.categoryId, $event.subCategoryId)"
      (selected)="modals.dismiss($event, 'dismiss'); product.setProductFilter(null);"
    ></wic-orderable-product-list>
  </ng-template>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>
