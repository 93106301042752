<ion-fab aria-hidden="true" *ngIf="(enhancedMode.isEnhanced$ | async) && (enhancedMode.toolsHidden$ | async) === false"
         vertical="bottom" horizontal="end" slot="fixed">
  <ion-fab-button color="danger">
    <ion-icon name="finger-print" data-cy="finger-print"></ion-icon>
  </ion-fab-button>
  <ion-fab-list side="top">
    <ion-fab-button color="dark">
      <ion-icon name="settings" (click)="enhancedMode.showAdminTools()"></ion-icon>
    </ion-fab-button>
    <ion-fab-button color="dark">
      <ion-icon name="notifications" (click)="enhancedMode.showNotificationInvestigator()"></ion-icon>
    </ion-fab-button>
    <ion-fab-button color="dark">
      <ion-icon name="logo-buffer" (click)="enhancedMode.showHostSettings(hostsSettingsMode.Full)"></ion-icon>
    </ion-fab-button>
    <ion-fab-button color="dark" (click)="enhancedMode.showDebugging()">
      <ion-icon name="bug"></ion-icon>
    </ion-fab-button>
    <ion-fab-button color="dark" (click)="enhancedMode.hideTools()">
      <ion-icon name="eye-off"></ion-icon>
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>
