import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnvironmentService } from '~features/environment.service';
import { Authority } from '~features/authorities/models';

@Component({
  selector: 'wic-authorities-list',
  templateUrl: './authorities-list.component.html',
  styleUrls: ['./authorities-list.component.scss'],
})
export class AuthoritiesListComponent {
  @Input() authorities: Authority[];
  @Output() selected = new EventEmitter<Authority>();

  constructor(public env: EnvironmentService) {
  }
}
