import { Registration } from '~features/registration/models';
import { UserSettings } from '~features/settings/models';

export const NA = 'n/a';
export const boolToString = (flag: boolean): string => flag === true ? 'true' : 'false';
export const stringOrNull = (condition: boolean, value: () => string): string | null => condition ? value() : null;
export const numberOrNull = (condition: boolean, value: () => number): number | null => condition ? value() : null;

const encodeText = (text: string): ArrayBuffer =>
  new TextEncoder().encode(text);

const decodeText = (buffer: ArrayBuffer): string =>
  new TextDecoder().decode(buffer);

const DIGEST_ALG = 'SHA-256';
export const generateParticipantToken = (cardNumber: string): PromiseLike<string> =>
  cardNumber
    ? crypto.subtle.digest(DIGEST_ALG, encodeText(cardNumber)).then(buffer => decodeText(buffer))
    : Promise.resolve(cardNumber);

export const ensureRegistration = (registration: Registration | null) =>
  !!registration
    ? {
      ...registration,
      authority: registration.authority || {
        id: null,
        name: null
      }
    }
    : {
      authority: {
        id: null,
        name: null
      },
      cards: undefined,
      currentCard: undefined
    };

export const ensureSettings = (settings: UserSettings | null) =>
  settings || {
    lang: 'en'
  };
