<swiper-container *ngIf="microBlog?.type === 'post'">
  <swiper-slide>
    <div class="blog-container post">
      <ion-row *ngIf="microBlog.image">
        <ion-col size="12">
          <img class="blog-image" [src]="microBlog.image"
               [ngStyle]="{'height': microBlog.showTitleInSlide && (microBlog | hasContent:lang) ? '375px' : ''}">
        </ion-col>
      </ion-row>
      <ion-row *ngIf="microBlog.showTitleInSlide && (microBlog | hasContent:lang)">
        <ion-col size="12">
          <div class="blog-text">
            <h2>{{microBlog.title}}</h2>
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="microBlog.showTitleInSlide && (microBlog | hasContent:lang)">
        <ion-col size="12">
          <div class="blog-text">
            <h2>{{microBlog.title}}</h2>
            <h5>{{microBlog | content:lang}}</h5>
          </div>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!microBlog.showTitleInSlide && (microBlog | hasContent:lang)">
        <ion-col size="12">
          <div class="blog-text">
            <h5>{{microBlog | content:lang}}</h5>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </swiper-slide>
</swiper-container>
