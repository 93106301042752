import { ShoppingListBenefit } from '~features/shopping-lists/models/shopping-list-benefit.model';
import { isProduceCategory } from '~features/benefits/benefit-utils';
import { ShoppingListItem } from '~features/shopping-lists/shopping-list-items/shopping-list-item.entity';

export const calculateAmountForNonProduce = (item: ShoppingListItem): number =>
  item.units * item.purchaseQuantity || 0;

const getPriceForCVBItem = (item: ShoppingListItem): number => {
  if (item.costUnit === 'LB') {
    return (item.salePrice && item.salePrice > 0) ?
      item.parWeight * item.salePrice :
      item.parWeight * item.price;
  } else {
    return (item.salePrice && item.salePrice > 0) ? 
      item.salePrice : 
      item.price;
  }
}

export const calculateAmountForProduce = (item: ShoppingListItem): number =>
  item.purchaseQuantity * getPriceForCVBItem(item) || 0;

export const getItemValue = (benefit: ShoppingListBenefit, item: ShoppingListItem): number =>
  isProduceCategory(benefit.categoryId) ? calculateAmountForProduce(item) :  calculateAmountForNonProduce(item);

export const calculateShoppingListBenefitTotal = (benefit: ShoppingListBenefit): number =>
  benefit.shoppingListItems.reduce(
    (sum, item) => sum + (getItemValue(benefit, item)),
    0
  );
