import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { LogService } from '~core/services/log.service';
import { ExplainPermissionModalComponent } from '../../components/explain-permission-modal/explain-permission-modal.component';
import { CameraService } from '~core/services/camera.service';
import { LocalNotificationsService } from './local-notifications.service';
import { PushNotificationsService } from './push-notifications.service';
import { Geolocation } from '@capacitor/geolocation';
import { NetworkStatusModalComponent } from '../../components/network-status-modal/network-status-modal.component';


const TAGS = ['Service', 'Native Permissions'];

@Injectable()
export class NativePermissionsService {
  constructor(
    private modals: ModalController,
    private popovers: PopoverController,
    private androidPermissions: AndroidPermissions,
    private localNotifications: LocalNotificationsService,
    private pushNotifications: PushNotificationsService,
    private log: LogService,
    private transloco: TranslocoService,
    private platform: Platform,
    private openNativeSettings: OpenNativeSettings,
    private cameraService: CameraService) {
  }

  async hasCameraPermission(): Promise<boolean> {
    return await this.cameraService.hasCameraPermission();
  }

  async hasLocationPermission(): Promise<boolean> {
    this.log.trace(TAGS, 'Checking if app has location permission...');
    if (!Capacitor.isNativePlatform()) {
      return true;
    }

    const permitted = await Geolocation.checkPermissions();
    if (permitted.coarseLocation === 'granted' || permitted.location === 'granted') {
      return true;
    } 
    
    return false;
  }

  async hasInternetPermission(): Promise<boolean> {

    if (this.platform.is('android')) {
      const networkConnect = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.INTERNET);
      return networkConnect.hasPermission;
    }

    return true;
  }
  // TODO: Storage Permissions may be build in, can possible remove
  // async hasStoragePermissions(): Promise<boolean> {
  //   if (this.platform.is('android')) {
  //     const read = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE);
  //     const write = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
  //     return read.hasPermission && write.hasPermission;
  //   }
  //
  //   return true; // ios
  // }

  async hasLocalNotificationPermission(): Promise<boolean> {
    return this.localNotifications.hasPermission();
  }

  async hasPushNotificationPermission(): Promise<boolean> {
    return this.pushNotifications.hasPermission();
  }

  async explainNotificationPermissions(): Promise<any> {
    const modal = await this.modals.create({
      component: ExplainPermissionModalComponent,
      componentProps: {
        headerImage: 'AppNotifications-4.png',
        message: this.transloco.translate('explain-permissions.notificationExplanation')
      }
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  async explainGeolocationPermission(backgroundColor: string, buttonColor: string): Promise<any> {
    const modal = await this.modals.create({
      component: ExplainPermissionModalComponent,
      componentProps: {
        headerImage: 'NavPermissions-4.png',
        backgroundColor,
        buttonColor,
        message: this.transloco.translate('explain-permissions.geolocationExplanation')
      }
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  async explainCameraPermission(backgroundColor: string, buttonColor: string, promptSettings = false): Promise<any> {
    const modal = await this.popovers.create({
      component: ExplainPermissionModalComponent,
      cssClass: 'permissions-popover',
      alignment: 'center',
      componentProps: {
        backgroundColor,
        headerImage: null,
        buttonColor,
        popover: true,
        icon: 'camera-outline',
        message: this.transloco.translate('explain-permissions.cameraExplanation')
      }
    });

    await modal.present();
    await modal.onDidDismiss().then(() => promptSettings && this.openNativeSettings.open('application_details'));
  }

  async explainStoragePermission(backgroundColor: string, buttonColor: string): Promise<any> {
    const modal = await this.modals.create({
      component: ExplainPermissionModalComponent,
      componentProps: {
        backgroundColor,
        headerImage: null,
        buttonColor,
        message: this.transloco.translate('explain-permissions.storageExplanation')
      }
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  async explainNetworkStatus(backgroundColor: string, buttonColor: string) {
    const modal = await this.modals.create({
      component: NetworkStatusModalComponent,
      componentProps: {
        backgroundColor,
        buttonColor,
        message: this.transloco.translate('error-modal.noNetInfo1')
      }
    });

    await modal.present();
    const result = await modal.onDidDismiss();

    return result;
  }

  async openLocationSettings() {
    await this.openNativeSettings.open("application_details");
  }

  async openNetworkSettings() {
    this.platform.is("android") ? 
      await this.openNativeSettings.open("network") :
      await this.openNativeSettings.open("application_details");
  }
}
