import { CalculatorBalance } from './models/calculator-balance';
import { createAction, props } from '@ngrx/store';
import { CalculatorEntry, IWithCategory } from '~features/calculators/models';
import { EnrichedProduct } from '~features/products/models';

export const restoreCalculatorEntries = createAction(
  '[Calculator] Restore calculator entries'
);

export const restoreCalculatorBalances = createAction(
  '[Calculator] Restore calculator balances'
);

export const calculatorEntriesRestored = createAction(
  '[Calculator] Calculator entries restored',
  props<{ entries: CalculatorEntry[] }>()
);

export const calculatorBalancesRestored = createAction(
  '[Calculator] Calculator balances restored',
  props<{ balances: CalculatorBalance[] }>()
);

export const createAdHocEntry = createAction(
  '[Calculator] Create Ad-Hoc Entry',
  props<{ entry: Partial<CalculatorEntry> }>()
);

export const setBenefitBalanceForCategories = createAction(
  '[Calculator] Set Benefit Balance For Categories',
  props<{ categoryId: number }>()
);

export const setBenefitBalanceForProduct = createAction(
  '[Calculator] Set Benefit Balance For Product',
  props<{ product: EnrichedProduct }>()
);

export const tryAddProduct = createAction(
  '[Calculator] Try Add product',
  props<{ product: EnrichedProduct }>()
);

export const addProduct = createAction(
  '[Calculator] Add product',
  props<{ product: EnrichedProduct }>()
);

export const editEntry = createAction(
  '[Calculator] Edit entry',
  props<{ entry: CalculatorEntry }>()
);
export const editNewEntry = createAction(
  '[Calculator] Edit new entry',
  props<{ entry: CalculatorEntry }>()
);
export const addEntry = createAction(
  '[Calculator] Add entry',
  props<{ entry: CalculatorEntry }>()
);
export const mergeEntry = createAction(
  '[Calculator] Merge entry',
  props<{ entry: CalculatorEntry; replace: boolean }>()
);
export const noBenefitsAvailable = createAction(
  '[Calculator] Insufficient benefits',
  props<{ entry: CalculatorEntry }>()
);
export const allowanceInsufficient = createAction(
  '[Calculator] Insufficient benefit balance',
  props<{ entry: CalculatorEntry }>()
);
export const checkAllowance = createAction(
  '[Calculator] Check allowance',
  props<{ entry: CalculatorEntry }>()
);
export const checkDollarAllowance = createAction(
  '[Calculator] Check dollar allowance',
  props<{ entry: CalculatorEntry; balance: number }>()
);
export const checkQuantityAllowance = createAction(
  '[Calculator] Check quantity allowance',
  props<{ entry: CalculatorEntry; balance: number }>()
);
export const viewCalculator = createAction(
  '[Calculator] View calculator',
  props<{ category: IWithCategory }>()
);
export const clearEntries = createAction(
  '[Calculator] Clear all entries'
);
export const clearCategoryEntries = createAction(
  '[Calculator] Clear Entries in Category',
  props<{ categoryId: number }>()
);
export const clearCategoryEntriesConfirmed = createAction(
  '[Calculator] Clear Entries in Category: Confirmed',
  props<{ categoryId: number }>()
);
export const toggleEntryFailed = createAction(
  '[Calculator] Toggle entry failed',
  props<{ entry: CalculatorEntry; reason: 'balance' | 'quantity' }>()
);
export const balanceInsufficientToToggle = createAction(
  '[Calculator] Remaining balance insufficient',
  props<{ entry: CalculatorEntry }>()
);
export const tryToggleEntry = createAction(
  '[Calculator] Try toggle entry',
  props<{ entry: CalculatorEntry }>()
);
export const toggleEntry = createAction(
  '[Calculator] Toggle entry',
  props<{ entry: CalculatorEntry }>()
);
export const incrementEntry = createAction(
  '[Calculator] Increment entry quantity',
  props<{ entry: CalculatorEntry }>()
);
export const decrementEntry = createAction(
  '[Calculator] Decrement entry quantity',
  props<{ entry: CalculatorEntry }>()
);
export const removeEntry = createAction(
  '[Calculator] Remove entry',
  props<{ entry: CalculatorEntry }>()
);

export const setBenefitBalance = createAction(
    '[Calculator] Set Balance',
    props<{balance: CalculatorBalance }>()
);

export const editBenefitBalance = createAction(
    '[Calculator] Edit Balance',
    props<{balance: CalculatorBalance}>()
);
