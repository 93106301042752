import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faHeart as faHeartOpen } from '@fortawesome/free-regular-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { Vendor, VendorFavorite } from '~features/vendors/models';
import { VendorAndFavorites } from '~features/vendors/models/types';

@Component({
  selector: 'wic-vendors-list',
  templateUrl: './vendors-list.component.html',
  styleUrls: ['./vendors-list.component.scss'],
})
export class VendorsListComponent {
  @Input() vendors: Vendor[];
  @Input() favorites: VendorFavorite[];

  @Output() selected = new EventEmitter<VendorAndFavorites>();
  @Output() directions = new EventEmitter<Vendor>();
  @Output() favoriteToggled = new EventEmitter<VendorAndFavorites>();

  faHeart = faHeart;
  faHeartOpen = faHeartOpen;
}
