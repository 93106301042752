<ion-header mode="ios" data-cy="settings-header">
  <ion-toolbar color="med-gray" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{'settings.contentTitle' | transloco}}</h3>
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon icon="fingerprint" size="2x" [styles]="{opacity: '0.03', color: 'var(--white)'}"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-menu contentId="settings" type="overlay" side="end">
  <ion-header>
    <ion-toolbar color="med-gray">
      <ion-buttons slot="start">
        <ion-button (click)="menu.close('end')">
          <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{'settings.contentTitle' | transloco}}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content fullscreen>
    <ion-list (click)="menu.close('end')">
      <ion-list-header>{{'settings.languageChooserTitle' | transloco}}</ion-list-header>
      <wic-language-item *ngFor="let language of languages.all$ | async" (click)="settings.selectLanguage(language.code)"
                         [language]="language" [selected]="languages.selectedLanguage$ | async">
      </wic-language-item>
    </ion-list>
  </ion-content>
</ion-menu>

<ion-content id="settings" fullscreen>
  <header class="text-center page-header">
    <strong>App Version: {{ version$ | async }}</strong>
  </header>

  <ion-list>
    <ion-list-header>{{'settings.languageChooserTitle' | transloco}}</ion-list-header>
    <wic-language-item (click)="menu.open('end')" [edit]="true"
            [language]="languages.selectedLanguage$ | async">
    </wic-language-item>
  </ion-list>

  <wic-notification-chooser
      [title]="'settings.benefitNotificationsTitle' | transloco"
      [alert]="settings.editedBenefitOptions$ | async"
      (changed)="settings.editBenefitOptions($event)">
  </wic-notification-chooser>
  <wic-notification-chooser
      *ngIf="!!(registration.hasAppointmentReminders$ | async)"
      [title]="'settings.appointmentNotificationsTitle' | transloco"
      [alert]="settings.editedAppointmentOptions$ | async"
      (changed)="settings.editAppointmentOptions($event)">
  </wic-notification-chooser>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>

<ion-footer>
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button data-cy="notification-settings-ok-button" class="btn-green" (click)="settings.saveEditedUserSettings()" expand="block">
            <ion-icon name="cloud-upload" slot="start"></ion-icon>
            <span class="ion-text-uppercase">{{'settings.saveButton' | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
