import { createSelector } from '@ngrx/store';
import moize from 'moize';
import { currentProvider } from '~features/registration/registration.selectors';

import { Authority } from '../authorities/models';
import { currentBenefits } from '../benefits/benefits.selectors';
import { EnrichedBenefit, EnrichedBenefitInfo } from '../benefits/models';
import { State } from '../state';
import { ICalculatorState } from './calculators.state';
import { CalculatorEntry } from './models';
import { CalculatorBalance } from './models/calculator-balance';

const INITIAL_TOTAL = 0;

const currency = (value: number): number =>
  Math.ceil(value * 100) / 100;

export const getCalculatorState = (state: State): ICalculatorState =>
  state.calculators;

export const getEntriesHaveBeenRestored = (state: ICalculatorState): boolean =>
  !!state.entriesRestoredAt;

export const getBalancesHaveBeenRestored = (state: ICalculatorState): boolean =>
  !!state.balancesRestoredAt;

export const mapToEntries = (state: ICalculatorState): CalculatorEntry[] =>
  state.entries || [];

export const mapToBalances = (state: ICalculatorState): CalculatorBalance[] =>
  state.balances || [];

export const mapToCategoryEntries = (categoryId: number) => (state: ICalculatorState): CalculatorEntry[] =>
  !!state.entries ? state.entries.filter(entry =>
    entry.categoryId === categoryId
  ) : [];

export const filterToSelectedEntries = (entries: CalculatorEntry[]): CalculatorEntry[] =>
  entries.filter(entry => !!entry.isSelected);

export const mapToHasEntries = (entries: CalculatorEntry[]): boolean =>
  !!entries && !!entries.length;

export const sumEntryPrices = (entries: CalculatorEntry[]): number =>
  (entries && entries.length) ? currency(entries.reduce((total, entry) =>
    total + entry.unitPrice * (entry.quantity || 0),
    INITIAL_TOTAL
  )) : 0;

export const sumEntryQuantities = (entries: CalculatorEntry[]): number =>
  entries.reduce((total, entry) =>
    total + entry.size * (entry.quantity || 0),
    INITIAL_TOTAL
  );

export const sumEntryCounts = (entries: CalculatorEntry[]): number =>
  entries.reduce((total, entry) =>
    total + (entry.quantity || 0),
    INITIAL_TOTAL
  );

export const mapToBalanceIsEmpty = (balance: number): boolean =>
  balance <= 0;

export const mapToQuantityIsEmpty = (count: number): boolean =>
  count <= 0;

export const getBalanceFromCalculatorBalance = (calculatorBalance: CalculatorBalance) =>
  calculatorBalance ? calculatorBalance.balance : 0;

export const getUserBenefitBalance = (categoryId: number) => (state: ICalculatorState): number =>
  !!state.balances
    ? getBalanceFromCalculatorBalance(
    state.balances.find(balance =>
      balance.categoryId === categoryId
    ))
    : 0;

export const getAvailableQuantity = (categoryId: number, benefits: EnrichedBenefit[]): number | null =>
  benefits.reduce((balance, benefit) =>
      (balance == null && benefit.categoryId === categoryId
      )
        ? benefit.availableQuantity
        : balance,
    null as number
  );

export const getBenefitBalance = (categoryId: number, benefits: EnrichedBenefitInfo): number =>
  ((!!benefits && benefits.hasCurrent)
    ? getAvailableQuantity(categoryId, benefits.benefits)
    : 0) || 0;


export const calculatorEntriesHaveBeenRestored = createSelector(
  getCalculatorState,
  getEntriesHaveBeenRestored
);

export const calculatorBalancesHaveBeenRestored = createSelector(
  getCalculatorState,
  getBalancesHaveBeenRestored
);

export const allEntries = createSelector(
  getCalculatorState,
  mapToEntries
);

export const allBalances = createSelector(
  getCalculatorState,
  mapToBalances
);

export const calculatorEntries = moize((categoryId: number) =>
  createSelector(
    getCalculatorState,
    mapToCategoryEntries(categoryId)
  )
);

export const selectedCalculatorEntries = moize((categoryId: number) =>
  createSelector(
    calculatorEntries(categoryId),
    filterToSelectedEntries
  )
);

export const hasEntries = moize((categoryId: number) =>
  createSelector(
    calculatorEntries(categoryId),
    mapToHasEntries
  )
);

export const userEnteredBalance = moize((categoryId: number) =>
  createSelector(
    getCalculatorState,
    getUserBenefitBalance(categoryId)
  )
);

export const totalCost = moize((categoryId: number) =>
  createSelector(
    selectedCalculatorEntries(categoryId),
    sumEntryPrices
  )
);

export const totalQuantity = moize((categoryId: number) =>
  createSelector(
    selectedCalculatorEntries(categoryId),
    sumEntryQuantities
  )
);

export const totalCount = moize((categoryId: number) =>
  createSelector(
    selectedCalculatorEntries(categoryId),
    sumEntryCounts
  )
);

export const currentBalance = moize((categoryId: number) =>
  createSelector(
    currentProvider,
    currentBenefits,
    userEnteredBalance(categoryId),
    (authority: Authority, benefits: EnrichedBenefitInfo, userBalance: number) =>
      authority ? currency(
        authority.isOfflineOrVerifyOnly
          ? userBalance
          : getBenefitBalance(categoryId, benefits)
      ) : 0
  )
);

export const remainingBalance = moize((categoryId: number) =>
  createSelector(
    currentBalance(categoryId),
    totalCost(categoryId),
    (balance: number, cost: number) =>
      balance - currency(cost)
  )
);

export const remainingBalanceEmpty = moize((categoryId: number) =>
  createSelector(
    remainingBalance(categoryId),
    mapToBalanceIsEmpty
  )
);

export const remainingQuantity = moize((categoryId: number) =>
  createSelector(
    currentProvider,
    currentBenefits,
    userEnteredBalance(categoryId),
    totalQuantity(categoryId),
    (authority: Authority, benefits: EnrichedBenefitInfo, userQuantity: number, quantity: number) =>
      authority ? ((authority.isOfflineOrVerifyOnly
        ? userQuantity
        : getBenefitBalance(categoryId, benefits)) - quantity) : 0
  )
);

export const remainingQuantityEmpty = moize((categoryId: number) =>
  createSelector(
    remainingQuantity(categoryId),
    mapToQuantityIsEmpty
  )
);
