<ion-card *ngIf="showNoProductsWarning && !!products && !products.length && !hasFilterCriteria">
  <ion-card-header>
    <ion-card-subtitle>{{ 'common.missingData' | transloco }}</ion-card-subtitle>
    <ion-card-title>
      {{ 'product-list.noProductsTitle' | transloco }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <p>{{ (totalCount ? 'product-list.noProductsAvailable' : 'product-list.noProductsInfo') | transloco }}</p>
        </ion-col>
      </ion-row>
      <ion-row class="pt-20" *ngIf="!totalCount">
        <ion-col>
          <ion-button expand="block" (click)="retry.emit(benefit)">
            <ion-icon name="refresh" class="pr-5"></ion-icon>
            {{'buttons.retry' | transloco}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>

<ion-card *ngIf="!!products && !products.length && hasFilterCriteria">
  <ion-card-header>
    <ion-card-title>
      {{ 'product-list.noProductsFoundTitle' | transloco }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <p>{{ 'product-list.noProductsFoundInfo' | transloco }}</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>

<ion-list *ngIf="!hideTitle && !!products && (!!products.length || hasFilterCriteria)">
  <ion-list-header>
    <span class="text-all-caps">{{'product-list.listHeader' | transloco}}</span>
  </ion-list-header>
</ion-list>
<cdk-virtual-scroll-viewport itemSize="60" minBufferPx="360" maxBufferPx="720" class="ion-content-scroll-host">
  <ion-item *cdkVirtualFor="let product of products" (click)="selected.emit(product)" data-cy="product-list-item">
    <wic-product-image slot="start" [product]="product"></wic-product-image>
    <ion-label>
      <h2>{{product.description}}</h2>
      <h3 class="addtl-info">
        <span class="text-muted">{{product.itemNumber}}</span>
        <span *ngIf="benefit" class="allowed" [ngClass]="getAllowedStatus(benefit, product)">{{benefit.quantity | computeAllowed:product.size:product.packageSize}}-{{'product-list.allowedLabel' | transloco}}</span>
      </h3>
    </ion-label>
  </ion-item>
</cdk-virtual-scroll-viewport>
