import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { VendorDetailModalComponent } from './vendor-detail-modal/vendor-detail-modal.component';
import { VendorSearchModalComponent } from './vendor-search-modal/vendor-search-modal.component';
import { VendorsListTabComponent } from './vendors-list-tab/vendors-list-tab.component';
import { VendorsMapTabComponent } from './vendors-map-tab/vendors-map-tab.component';
import { VendorsMapComponent } from './vendors-map/vendors-map.component';
import { VendorsEffects } from './vendors.effects';

import { InlineSVGModule } from 'ng-inline-svg-2';
import { VendorsComponentsModule } from './vendors-components.module';
import { VendorsPage } from './vendors.page';

const routes: Routes = [
  {
    path: '',
    component: VendorsPage,
    children: [
      {
        path: 'map',
        component: VendorsMapTabComponent,
      },
      {
        path: 'list',
        component: VendorsListTabComponent,
      },
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    InlineSVGModule.forRoot(),
    VendorsComponentsModule,
    ComponentsModule,
    TranslocoModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    PipesModule,
    GoogleMapsModule,
  ],
  declarations: [
    VendorsPage,
    VendorsMapTabComponent,
    VendorsListTabComponent,
    VendorsMapComponent,
  ],
})
export class VendorsPageModule {}
