export enum HostsSettingsMode {
  Restricted = 'r',
  Full = 'f'
}

export interface APIHostOption {
  url: string;
  name: string;
  color: string;
  modes: HostsSettingsMode[];
}
