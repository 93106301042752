<cdk-virtual-scroll-viewport itemSize="133" minBufferPx="798" maxBufferPx="1596">
  <ion-item *cdkVirtualFor="let office of offices" data-cy="list-item">
    <ion-item-sliding #item>
      <ion-item>
        <ion-label (click)="selected.emit({office: office, favorites: favorites})">
          <h2><strong>
            <fa-icon icon="building"></fa-icon>
            {{office.name}}</strong></h2>
          <h2>{{office.address1}}</h2>
          <h2>{{office.address2}}</h2>
          <h2>{{office.city}}, {{office.state}} {{office.zip}}</h2>
          <h4 class="mt-10" [ngClass]="office.distance | distanceGrade:10:30" data-cy="distance">
            Distance: <strong>{{office.distance | prettyDistance}} {{'mi' | transloco}}</strong>
          </h4>
        </ion-label>
        <ion-note>
          <fa-icon data-cy="favorite-button"
              [icon]="(office | isFavorite:favorites) ? faHeart : faHeartOpen" size="2x"
              [styles]="(office | isFavorite:favorites) ? {'color': 'var(--soft-red)'} : {'color': 'var(--med-grey)'}"
              (click)="favoriteToggled.emit({office: office, favorites: favorites})">
          </fa-icon>
        </ion-note>
      </ion-item>
      <ion-item-options side="end">
        <ion-item-option color="white" (click)="directions.emit(office); item.close()" data-cy="directions-button">
          <fa-icon icon="directions" size="2x" [styles]="{'color': 'var(--ion-color-misty-moss)'}"></fa-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-item>
</cdk-virtual-scroll-viewport>
