import { NgModule } from '@angular/core';
import { CalculatorsFacade } from '~features/calculators/calculators.facade';
import { CalculatorsService } from '~features/calculators/calculators.service';


@NgModule({
  providers: [
    CalculatorsFacade,
    CalculatorsService
  ]
})
export class CalculatorsModule {
}
