<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-menu-button data-cy="menu-button"></ion-menu-button>
    </ion-buttons>
    <ion-title>
      <img src="assets/images/img_main.png" alt="{{'common.wicshopper' | transloco}}" height="50" />
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon icon="fingerprint" size="2x" [styles]="{opacity: '0.03', color: 'var(--med-gray)'}"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-refresher slot="fixed" pullFactor="0.5" pullMin="90" pullMax="180" (ionRefresh)="reload($event)">
    <ion-refresher-content pullingText="Pull to reload menu"></ion-refresher-content>
  </ion-refresher>

  <div #bannersComponent>
    <ng-container *ngIf="registration.enableNewHomeScreen$ | async">
      <wic-banners
        *ngIf="banners.hasBanner$ | async"
        class="content-banners"
        [detailedBanners]="banners.detailedBanners$ | async"
        [showButtons]="banners.hasManyBanners$ | async"
        (viewBanner)="banners.openBannerLink($event)"
      ></wic-banners>
    </ng-container>
  </div>

  <section class="menu">
    <ion-card
      role="button"
      *ngFor="let button of menu$ | async"
      class="menu-item {{button.name}} {{button.imgIdx}} ion-activatable"
      [ngClass]="{
        'menu-item-card': registration.enableNewHomeScreen$ | async
      }"
      (click)="home.activateButton(button)"
      data-cy="menu-item"
    >
      <div class="img-background" aria-hidden="true">
        <img [src]="button.imgSrc" [alt]="button.label | transloco" />
      </div>
      <h6>{{button.label | transloco}}</h6>

      <ion-ripple-effect *ngIf="registration.enableNewHomeScreen$ | async"></ion-ripple-effect>
    </ion-card>
  </section>

  <wic-banners
    *ngIf="banners.hasBanner$ | async"
    slot="fixed"
    class="bottom-banners"
    [ngClass]="{
      'bottom-banners-hide': (hideBottom$ | async)
    }"
    [detailedBanners]="banners.detailedPriorityBanners$ | async"
    [showButtons]="banners.hasManyPriorityBanners$ | async"
    (viewBanner)="banners.openBannerLink($event)"
  ></wic-banners>

  <div style="height: 40vh;"></div>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>
