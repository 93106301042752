import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArrowRight,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCreditCard,
  faDollarSign,
  faKeyboard,
  faMinus,
  faPencilAlt,
  faPlus,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

import { HostSettingsModeFilterPipe } from '../pipes/host-settings-mode-filter.pipe';
import { PipesModule } from '../pipes/pipes.module';
import { AdminToolsModalComponent } from './admin-tools-modal/admin-tools-modal.component';
import { AuthoritiesListLoadingComponent } from './authorities-list-loading/authorities-list-loading.component';
import { AuthoritiesListComponent } from './authorities-list/authorities-list.component';
import { AuthorityLogoComponent } from './authority-logo/authority-logo.component';
import { BannersComponent } from './banners/banners.component';
import { BenefitErrorComponent } from './benefit-error/benefit-error.component';
import { BenefitCardImageComponent } from './benefit-item/benefit-card-image/benefit-card-image.component';
import { BenefitItemComponent } from './benefit-item/benefit-item.component';
import { AplMessagePipe } from './benefit-list/apl-message.pipe';
import { BenefitListComponent } from './benefit-list/benefit-list.component';
import { NonZeroBalancePipe } from './benefit-list/non-zero-balance.pipe';
import { BenefitsLoadingProgressComponent } from './benefits-loading-progress/benefits-loading-progress.component';
import { CalculatorBenefitBalanceModalComponent } from './calculator-benefit-balance-modal/calculator-benefit-balance-modal.component';
import { CalculatorEntryListComponent } from './calculator-entry-list/calculator-entry-list.component';
import { CardNonActiveErrorComponent } from './card-non-active-error/card-non-active-error.component';
import { CardQrCodeComponent } from './card-qr-code/card-qr-code.component';
import { CardSelectToolbarComponent } from './card-select-toolbar/card-select-toolbar.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { DebugSettingsModalComponent } from './debug-settings-modal/debug-settings-modal.component';
import { DebuggingModalComponent } from './debugging-modal/debugging-modal.component';
import { DemandApptIdentityModalComponent } from './demand-appt-identity-modal/demand-appt-identity-modal.component';
import { EditCalculatorEntryModalComponent } from './edit-calculator-entry-modal/edit-calculator-entry-modal.component';
import { EditCardModalComponent } from './edit-card-modal/edit-card-modal.component';
import { EnhancedModeFabComponent } from './enhanced-mode-fab/enhanced-mode-fab.component';
import { EnhancedModeRegistrationModalComponent } from './enhanced-mode-registration-modal/enhanced-mode-registration-modal.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { ExplainPermissionModalComponent } from './explain-permission-modal/explain-permission-modal.component';
import { FindConfigPipe } from './find-config.pipe';
import { GetSupportModalComponent } from './get-support-modal/get-support-modal.component';
import { HostSettingsModalComponent } from './host-settings-modal/host-settings-modal.component';
import { LanguageSelectModalComponent } from './language-select-modal/language-select-modal.component';
import { LogDetailModalComponent } from './log-detail-modal/log-detail-modal.component';
import { LogViewerComponent } from './log-viewer/log-viewer.component';
import { ContentPipe } from './micro-blog-viewer-modal/content.pipe';
import { HasContentPipe } from './micro-blog-viewer-modal/has-content.pipe';
import { MicroBlogDeckComponent } from './micro-blog-viewer-modal/micro-blog-deck/micro-blog-deck.component';
import { MicroBlogPostComponent } from './micro-blog-viewer-modal/micro-blog-post/micro-blog-post.component';
import { MicroBlogViewerModalComponent } from './micro-blog-viewer-modal/micro-blog-viewer-modal.component';
import { MissingDataWarningComponent } from './missing-data-warning/missing-data-warning.component';
import { NoCardsWarningComponent } from './no-cards-warning/no-cards-warning.component';
import { NotificationInvestigatorModalComponent } from './notification-investigator-modal/notification-investigator-modal.component';
import { NotificationsDetailComponent } from './notification-investigator-modal/notifications-detail/notifications-detail.component';
import { ProductImageComponent } from './product-image/product-image.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProviderCardComponent } from './provider-card/provider-card.component';
import { ProviderSelectedModalComponent } from './provider-selected-modal/provider-selected-modal.component';
import { QRWarnModalComponent } from './qr-warn-modal/qr-warn-modal.component';
import { WhoopsComponent } from './whoops/whoops.component';
import { RedemptionListComponent } from './redemption-list/redemption-list.component';
import { LanguageItemComponent } from './language-item/language-item.component';
import { NetworkStatusModalComponent } from './network-status-modal/network-status-modal.component';
import { CheckVendorModalComponent } from './check-vendor-modal/check-vendor-modal.component';
import { NoWicWarningComponent } from './no-wic-warning/no-wic-warning.component';
import { I18nModule } from '~core/i18n/i18n.module';
import { CustomErrorToolbarComponent } from './custom-error-toolbar/custom-error-toolbar.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
    PipesModule,
    TranslocoModule,
    TranslocoLocaleModule,
    FontAwesomeModule,
    I18nModule,
    ScrollingModule,
  ],
  declarations: [
    AdminToolsModalComponent,
    AuthoritiesListComponent,
    AuthoritiesListLoadingComponent,
    AuthorityLogoComponent,
    BenefitErrorComponent,
    BenefitsLoadingProgressComponent,
    BenefitItemComponent,
    BenefitCardImageComponent,
    BenefitListComponent,
    CalculatorBenefitBalanceModalComponent,
    CalculatorEntryListComponent,
    CardNonActiveErrorComponent,
    CardQrCodeComponent,
    ContentHeaderComponent,
    DebugSettingsModalComponent,
    DebuggingModalComponent,
    QRWarnModalComponent,
    DemandApptIdentityModalComponent,
    EditCardModalComponent,
    EditCalculatorEntryModalComponent,
    EnhancedModeFabComponent,
    EnhancedModeRegistrationModalComponent,
    ErrorModalComponent,
    ExplainPermissionModalComponent,
    GetSupportModalComponent,
    HostSettingsModalComponent,
    MicroBlogViewerModalComponent,
    MicroBlogDeckComponent,
    MicroBlogPostComponent,
    LanguageSelectModalComponent,
    LanguageItemComponent,
    LogDetailModalComponent,
    LogViewerComponent,
    ProviderCardComponent,
    ProviderSelectedModalComponent,
    WhoopsComponent,
    ProductListComponent,
    NotificationInvestigatorModalComponent,
    NotificationsDetailComponent,
    NoCardsWarningComponent,
    ProductImageComponent,
    FindConfigPipe,
    BannersComponent,
    AplMessagePipe,
    ContentPipe,
    HasContentPipe,
    MicroBlogDeckComponent,
    MicroBlogPostComponent,
    NonZeroBalancePipe,
    MissingDataWarningComponent,
    CardSelectToolbarComponent,
    NetworkStatusModalComponent,
    CheckVendorModalComponent,
    NoWicWarningComponent,
    RedemptionListComponent,
    CustomErrorToolbarComponent,
  ],
  exports: [
    AuthoritiesListLoadingComponent,
    AuthoritiesListComponent,
    AuthorityLogoComponent,
    BenefitErrorComponent,
    BenefitListComponent,
    CalculatorEntryListComponent,
    CardNonActiveErrorComponent,
    CardQrCodeComponent,
    ContentHeaderComponent,
    ErrorModalComponent,
    ProviderCardComponent,
    ProviderSelectedModalComponent,
    WhoopsComponent,
    EnhancedModeFabComponent,
    BenefitsLoadingProgressComponent,
    LanguageSelectModalComponent,
    LanguageItemComponent,
    MicroBlogViewerModalComponent,
    ProductListComponent,
    BannersComponent,
    MissingDataWarningComponent,
    QRWarnModalComponent,
    NotificationInvestigatorModalComponent,
    NoCardsWarningComponent,
    CardSelectToolbarComponent,
    ProductImageComponent,
    CheckVendorModalComponent,
    NoWicWarningComponent,
    RedemptionListComponent,
    CustomErrorToolbarComponent,
  ],
  providers: [HostSettingsModeFilterPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faArrowRight,
      faCheck,
      faChevronLeft,
      faChevronRight,
      faCircle,
      faDollarSign,
      faKeyboard,
      faMinus,
      faPencilAlt,
      faPlus,
      faTimes,
      faTrash,
      faCreditCard
    );
  }
}
