import { Component, OnInit, ViewChild } from '@angular/core';
import { IonTabs } from '@ionic/angular';
import { GeoLocationService } from '~core/services/geo-location.service';
import { LogService } from '~core/services/log.service';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { VendorFacade } from '~features/vendors/vendor.facade';

const TAGS = ['Page', 'Vendors'];

@Component({
  selector: 'wic-vendors',
  templateUrl: './vendors.page.html',
  styleUrls: ['./vendors.page.scss'],
})
export class VendorsPage implements OnInit {
  @ViewChild('tabs', { static: true }) tabs: IonTabs;

  constructor(
    public vendors: VendorFacade,
    public enhancedMode: EnhancedModeFacade,
    public geolocation: GeoLocationService,
    private log: LogService,
    private analytics: AnalyticsFacade
  ) {
    this.log.trace(TAGS, 'Constructing Vendors Page');
  }

  ngOnInit() {
    this.analytics.pageView('vendors');
    this.vendors.viewed();
  }
}
