import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faAt,
  faBarcode, faCamera,
  faCarrot, faCircle,
  faCloudUploadAlt,
  faCopyright,
  faFingerprint, faHashtag,
  faInfoCircle, faPhoneAlt, faSearch,
  faStore,
  faTimesCircle,
  faUser, faUserTag,
  faWeightHanging
} from '@fortawesome/free-solid-svg-icons';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';

import { CantBuyThisPage } from './cant-buy-this.page';
import { CantBuyThisEffects } from './cant-buy-this.effects';
import { EffectsModule } from '@ngrx/effects';
import { CantBuyThisService } from './cant-buy-this.service';
import { ComponentsModule } from '../../components/components.module';
import { ICBTImageComponent } from './icbt-image/icbt-image.component';

const routes: Routes = [
  {
    path: '',
    component: CantBuyThisPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    EffectsModule.forFeature([
      CantBuyThisEffects
    ]),
    TranslocoModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    ComponentsModule
  ],
  declarations: [CantBuyThisPage, ICBTImageComponent],
  providers: [CantBuyThisService, CantBuyThisEffects]
})
export class CantBuyThisPageModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faCircle,
      faFingerprint,
      faTimesCircle,
      faCloudUploadAlt,
      faUser,
      faAt,
      faStore,
      faCopyright,
      faCarrot,
      faWeightHanging,
      faBarcode,
      faInfoCircle,
      faSearch,
      faUserTag,
      faCamera,
      faHashtag,
      faPhoneAlt
    );
  }
}
