<div *ngIf="shoppingListItems.quantityEditId$ | async as quantityEditId">
  <ion-header mode="ios">
    <ion-toolbar color="tufts-blue-shaded" mode="ios">
      <ion-title>
        <h3>{{'item-list.contentTitle' | transloco}}</h3>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button fill="clear" data-cy="home-button" (click)="modal.dismiss(quantityEditId, 'cancel')">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content fullscreen *ngIf="quantityEditForm$ | async as form">
    <ion-toolbar color="tufts-blue-shaded">
      <ion-title>
        <h3 *ngIf="benefit.categoryId === 19 || benefit.categoryId === 97" class="h3-tw">
          {{ form.remainingQuantity | currency }} {{ 'item-list.remainingLabel' | transloco }}
        </h3>
        <h3 *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97" class="h3-tw">
          {{ form.remainingQuantity }} {{ benefit.uom }} {{ 'item-list.remainingLabel' | transloco }}
        </h3>
      </ion-title>
    </ion-toolbar>
    <ion-card data-cy="product-list-item" class="product-item" >
      <ion-card-content>
        <wic-product-image size="larger" [product]="product"></wic-product-image>
      </ion-card-content>
      <ion-card-header>
        <ion-card-title>{{product.description}}</ion-card-title>
        <ion-card-subtitle class="addtl-info">
          <p class="text-muted" *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97; else produceDisplay">
            {{ product.size / product.packageSize }} {{ product.uom }}
          </p>
          <ng-template #produceDisplay>
            <p class="text-muted">
            <span *ngIf="product.salePrice && product.salePrice > 0; else normalPrice">
              <ion-text class="original-price">{{ product.price | currency }}</ion-text>
              <ion-text color="green">
                {{ product.salePrice | currency }} {{ product.costUnit }}
              </ion-text>
            </span>
              <ng-template #normalPrice>
                <span>{{ product.price | currency }} {{ product.costUnit }}</span>
              </ng-template>
            </p>
            <p class="text-muted" *ngIf="product.costUnit === 'LB'">
              <span *ngIf="product.salePrice && product.salePrice > 0; else normalParPrice">
                <ion-text color="blue" class="smaller-text">
                  {{ 'buttons.about' | transloco }} {{ product.parWeight * product.salePrice | currency }} {{ 'product-list.eachLabel' | transloco }}
                </ion-text>
              </span>
              <ng-template #normalParPrice>
                <span>
                  <ion-text color="blue" class="smaller-text">
                    {{ 'buttons.about' | transloco }} {{ product.parWeight * product.price | currency }} {{ product.costUnit }}
                  </ion-text>
                </span>
              </ng-template>
            </p>
          </ng-template>
          <p class="allowed" *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97" [ngClass]="getAllowedStatus(benefit, product)">
            {{benefit.quantity | computeAllowed:product.size:product.packageSize}}-{{'product-list.allowedLabel' | transloco}}
          </p>
        </ion-card-subtitle>
      </ion-card-header>

      <ion-item lines="none" class="spacing-bottom">
        <ion-button
          color="secondary"
          size="default"
          (click)="form.decrement()"
          [disabled]="!form.canDecrement"
        >
          <ion-icon name="remove" slot="icon-only"></ion-icon>
        </ion-button>

     <ion-label class="ion-text-center">
       <h3>{{ form.value }}</h3>
       <p *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97; else produceText">{{ (product.size / product.packageSize) * form.value }} {{ product.uom }}</p>
       <ng-template #produceText>
        <span *ngIf="product.salePrice && product.salePrice > 0; else normalPrice">
          <p *ngIf="product.costUnit === 'LB'">{{ form.value * (product.parWeight * product.salePrice) | currency }}</p>
          <p *ngIf="product.costUnit !== 'LB'"> {{ (form.value * product.salePrice) | currency }}*</p>
        </span>
        <ng-template #normalPrice>
          <p *ngIf="product.costUnit === 'LB'">{{ form.value * (product.parWeight * product.price) | currency }}</p>
          <p *ngIf="product.costUnit !== 'LB'">{{ form.value * product.price | currency }}*</p>
        </ng-template>
       </ng-template>
     </ion-label>
     <ion-button
       color="secondary"
       size="default"
       (click)="form.increment()"
       [disabled]="!form.canIncrement"
     >
       <ion-icon name="add" slot="icon-only"></ion-icon>
     </ion-button>
   </ion-item>

      <ion-item lines="none" *ngIf="benefit.categoryId === 19 || benefit.categoryId === 97" class="spacing-bottom">
        <p class="approximate-cost-text">
          *{{ 'product-list.approximateCostLabel' | transloco }}
        </p>
      </ion-item>

      <ion-button color="green" expand="block" size="large" (click)="modal.dismiss(+form.value, 'skip')">
        {{ 'mobileOrdering.addItemToOrder'  | transloco }}
      </ion-button>

      <ion-button
        color="primary"
        expand="block"
        size="large"
        (click)="modal.dismiss(quantityEditId, 'cancel')"
      >
        {{ 'mobileOrdering.returnToItemSelection' | transloco }}
      </ion-button>
    </ion-card>

    <ion-item *ngFor="let sub of substitutions.substitutionsForCurrentShoppingListItem$ | async ">
      <ion-label class="ion-text-wrap">
        <p>{{ sub.description }}</p>
  <!--      TODO: Turn into a component -->
        <h3 *ngIf="!benefit.categoryId !== 19 && benefit.categoryId !== 97; else produceSubText">{{ sub.purchaseQuantity }} x {{ sub.units }} {{ sub.uom }}</h3>
        <ng-template #produceSubText>
          <h3>{{ sub.purchaseQuantity }} x {{ sub.price | currency }}</h3>
        </ng-template>
      </ion-label>
    </ion-item>

    <div [ngSwitch]="substitutions.currentSubstitutionType$ | async">
      <div *ngSwitchCase="0">
        <ion-card class="confirm-product" color="blue">
          <ion-card-header class="ion-margin-bottom">
            <ion-card-subtitle style="text-align: center; font-size: 1em;">
              {{ 'mobileOrdering.disabledSubstitutionInfoMessage' | transloco }}
            </ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </div>
      <div *ngSwitchCase="1">
        <ion-card class="confirm-product" color="blue">
          <ion-card-header class="ion-margin-bottom">
            <ion-card-subtitle style="text-align: center; font-size: 1em;">
              {{ 'mobileOrdering.allowedSubstitutionInfoMessage' | transloco }}
            </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <ion-button *ngIf="(substitutions.substitutionsForCurrentShoppingListItem$ | async).length <= 1" size="large" expand="block" shape="round" color="light" (click)="modal.dismiss(+form.value, 'dismiss')">
              Select 2nd Choice
            </ion-button>
            <ion-button size="large" expand="block" shape="round" color="orange" (click)="modal.dismiss(+form.value, 'skip')">
              Skip Substitution
            </ion-button>
          </ion-card-content>
        </ion-card>
      </div>
      <div *ngSwitchCase="2">
        <ion-card class="confirm-product" color="blue">
          <ion-card-header class="ion-margin-bottom">
            <ion-card-subtitle style="text-align: center; font-size: 1em;">
              {{ 'mobileOrdering.autoSubstitutionInfoMessage' | transloco }}
            </ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </div>

    </div>
  </ion-content>
</div>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>
