import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, exhaustMap, from, map, withLatestFrom } from 'rxjs';
import {
  confirmIsThisYourStore,
  dismissIsThisYourStore,
  displayIsThisYourStore,
} from '~features/missing-product/missing-product.state';
import { CantBuyThisService } from './cant-buy-this.service';
import { currentProvider } from '~features/registration/registration.selectors';
import { Store } from "@ngrx/store";
import { State } from "~features/state";

@Injectable()
export class CantBuyThisEffects {
  constructor(
    private actions$: Actions, 
    private store: Store<State>,
    private cantBuyThis: CantBuyThisService
  ) {}

  showIsThisYourVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(displayIsThisYourStore),
      withLatestFrom(this.store.select(currentProvider)),
      exhaustMap(([, authority]) =>
        authority.hasLocations ? 
          from(this.cantBuyThis.showCheckVendorModal()).pipe(
            map(vendor =>
              vendor !== null ? confirmIsThisYourStore({ vendor }) : dismissIsThisYourStore()
            )
          ) 
        : EMPTY
      )
    )
  );
}
