import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';

@Component({
  selector: 'wic-debugging-modal',
  templateUrl: './debugging-modal.component.html',
  styleUrls: ['./debugging-modal.component.scss'],
})
export class DebuggingModalComponent {
  constructor(public enhancedMode: EnhancedModeFacade, public modal: ModalController) {
  }
}
