<ion-card class="shopping-list-item-group">
  <ion-item lines="none" class="item-group-header" color="light">
    <ion-label>
      <h1>{{ benefit.subCategory }}</h1>
      <p *ngIf="!isProduce">{{ benefit.availableQuantity - (benefit | benefitShoppingListTotal) }} {{ benefit.uom }}</p>
      <p *ngIf="isProduce">{{ (benefit.availableQuantity - (benefit | benefitShoppingListTotal)) | currency }}</p>
    </ion-label>
    <ion-avatar slot="end">
      <ion-img [src]="benefit.config?.image"></ion-img>
    </ion-avatar>
  </ion-item>
  <ion-card-content>
    <ion-card *ngFor="let item of benefit.shoppingListItems; trackBy: trackByShoppingListItem">
      <ion-card-content>
        <ion-item lines="none">
          <ion-label>
            <h2><span [ngClass]="{ 'checked-off': item.checkedOff }">{{ item.description }}</span></h2>
          </ion-label>
          <ion-button fill="clear" (click)="remove.emit(item.id)" slot="end">
            <ion-icon name="close-circle-outline" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-grid>
          <ion-row>
            <ion-col size="4">
              <wic-product-image size="large" [product]="item"></wic-product-image>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col>
              <div class="item-info">
                <div>
                  <ion-label>
                    <ng-container *ngIf="editingId !== item.id">
                      <h3>
                        <span [ngClass]="{ 'checked-off': item.checkedOff }" *ngIf="!isProduce; else produceText">
                          {{ item.purchaseQuantity }} x {{ item.units }} {{ item.uom }}
                        </span>
                      </h3>
                    </ng-container>
                    <h3 *ngIf="editingId === item.id">
                      <span [ngClass]="{ 'checked-off': item.checkedOff }" *ngIf="!isProduce; else produceText">
                        {{ item.units }} {{ item.uom }}
                      </span>
                    </h3>
        
                    <ng-template #produceText>
                      <p class="text-muted fw-600">
                        <span *ngIf="item.salePrice && item.salePrice > 0; else normalPrice">
                          <ion-text>{{ item.purchaseQuantity }} x </ion-text>
                          <ion-text class="original-price">{{ item.price | currency }}</ion-text>
                          <ion-text color="green"> {{ item.salePrice | currency }}</ion-text>
                          <p>
                            <ion-text color="blue" *ngIf="item.costUnit === 'LB'">
                              {{ 'buttons.about' | transloco }} {{ item.parWeight * item.salePrice | currency }} {{ 'product-list.eachLabel' | transloco }}
                            </ion-text>
                          </p>
                        </span>
                        <ng-template #normalPrice>
                          <span>{{ item.purchaseQuantity }} x {{ item.price | currency }}</span>
                          <ion-text color="blue" *ngIf="item.costUnit === 'LB'">
                            {{ 'buttons.about' | transloco }} {{ item.parWeight * item.price | currency }} {{ item.costUnit }}
                          </ion-text>
                        </ng-template>
                      </p>
                    </ng-template>
                  </ion-label> 
                </div>
                <div> 
                  <ion-button *ngIf="!editingId" (click)="edit.emit(item.id)" expand="block" size="small">
                    <ion-icon slot="start" name="pencil"></ion-icon>
                    {{ 'mobileOrdering.editQuantity' | transloco }}
                  </ion-button>
                  <div
                    *ngIf="editingId === item.id"
                    class="ion-justify-content-between quantity-adjustment"
                  >
                    <ion-button
                      color="secondary"
                      size="small"
                      (click)="form.decrement()"
                      [disabled]="!form.canDecrement"
                    >
                      <ion-icon name="remove" slot="icon-only"></ion-icon>
                    </ion-button>
                    <ion-label class="ion-text-center">
                      {{ form.value }}
                    </ion-label>
                    <ion-button
                      color="secondary"
                      size="small"
                      (click)="form.increment()"
                      [disabled]="!form.canIncrement"
                    >
                      <ion-icon name="add" slot="icon-only"></ion-icon>
                    </ion-button>
                  </div>
                  <ng-container *ngIf="editingId === item.id">
                    <ion-item lines="none">
                      <ion-button
                        (click)="save.emit(form.value)"
                        fill="clear"
                        color="success"
                        [disabled]="form.invalid"
                      >
                        {{ 'shoppingListsView.saveQuantityEdit' | transloco }}
                      </ion-button>
                    </ion-item>
                  </ng-container>
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="isProduce">
            <ion-col class="wic-cost-text">
              <span *ngIf="item.salePrice && item.salePrice > 0; else normalPrice">
                <ion-text>WIC Benefit Cost: </ion-text>
                <ion-text color="green">{{ (item.purchaseQuantity * item.salePrice) | currency }}*</ion-text>
              </span>
              <ng-template #normalPrice>
                <ion-text>WIC Benefit Cost: </ion-text>
                <ion-text color="green">{{ item.purchaseQuantity * item.price | currency }}*</ion-text>
              </ng-template>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="isProduce">
            <ion-col>
              <p class="approximate-cost-text">
                *{{ 'product-list.approximateCostLabel' | transloco }}
              </p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ng-container *ngIf="item.substitutions?.length">
                <ion-item *ngFor="let sub of item.substitutions" class="substitution-item" lines="inset">
                  <ion-button slot="start" fill="clear" shape="round" color="medium">
                    <ion-icon slot="icon-only" name="swap-horizontal" size="small"></ion-icon>
                  </ion-button>
                  <ion-label class="ion-text-wrap">
                    <p>{{ sub.description }}</p>
                    <h3 *ngIf="!isProduce; else produceSubText">{{ sub.purchaseQuantity }} x {{ sub.units }} {{ sub.uom }}</h3>
                    <ng-template #produceSubText>
                      <h3>{{ sub.purchaseQuantity }} x {{ sub.price | currency }}</h3>
                    </ng-template>
                  </ion-label>
          
                  <ion-button slot="end" (click)="removeSubstitution.emit(sub.id)" fill="clear" color="danger">
                    <ion-icon name="trash" slot="icon-only"></ion-icon>
                  </ion-button>
                </ion-item>
              </ng-container>
          
              <ng-container *ngIf="benefit.substitutionConfig.substitutionType === 1 && item.substitutions?.length < 2">
                <ion-item class="substitution-item" lines="inset" (click)="addSubstitution.emit(item)">
                  <ion-label>
                    {{ 'mobileOrdering.addSubstitution' | transloco }}
                  </ion-label>
                  <ion-button slot="end" fill="clear" shape="round" color="primary">
                    <ion-icon slot="icon-only" name="swap-horizontal"></ion-icon>
                  </ion-button>
                </ion-item>
              </ng-container>
          
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-card-content>
</ion-card>