import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { ShoppingListBenefit } from '../../features/shopping-lists/models/shopping-list-benefit.model';
import { ShoppingListItem } from '../../features/shopping-lists/shopping-list-items/shopping-list-item.entity';
import { getItemValue } from './utils';



export const newTotalQuantity = (originalItem: ShoppingListItem, benefit: ShoppingListBenefit) =>
  getItemValue(benefit, originalItem) +
  benefit.shoppingListItems.reduce(
    (sum, item) => sum + (item.id === originalItem.id ? 0 : getItemValue(benefit, item)),
    0
  );

export const validateItemPurchaseQuantity =
  (originalItem: ShoppingListItem, benefit: ShoppingListBenefit): ValidatorFn =>
  (control: AbstractControl) => {
    const total = newTotalQuantity({ ...originalItem, purchaseQuantity: control.value }, benefit);
    return total > benefit.availableQuantity
      ? { quantityOverage: benefit.availableQuantity - total }
      : null;
  };

export const validateItemMinimum =
  (minimum: number): ValidatorFn =>
  (control: AbstractControl) =>
    +control.value > minimum ? null : { quantityUnderage: minimum };

export class QuantityEditFormControl extends FormControl {
  constructor(private item: ShoppingListItem, private benefit: ShoppingListBenefit) {
    super(item.purchaseQuantity, {
      validators: [validateItemPurchaseQuantity(item, benefit), validateItemMinimum(0)],
    });
  }

  get remainingQuantity() {
    return this.benefit.availableQuantity - newTotalQuantity({...this.item , purchaseQuantity: this.value}, this.benefit);
  }

  increment() {
    this.setValue(+this.value + 1);
  }

  get canIncrement() {
    return (
      newTotalQuantity({ ...this.item, purchaseQuantity: this.value + 1 }, this.benefit) <=
      this.benefit.availableQuantity
    );
  }

  decrement() {
    this.setValue(+this.value - 1);
  }

  get canDecrement() {
    return this.value > 1;
  }
}
