import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { ComponentsModule } from '../../components/components.module';
import { DirectivesModule } from '../../directives/directives.module';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { RegistrationPage } from './registration.page';
import { RegistrationInfoComponent } from './registration-info/registration-info.component';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    ComponentsModule,
    RouterModule.forChild([
      { path: '', component: RegistrationPage }
    ]),
    TranslocoModule,
    TranslocoMessageFormatModule,
    FontAwesomeModule,
    DirectivesModule,
    MaskitoDirective
  ],
  declarations: [
    RegistrationPage,
    RegistrationFormComponent,
    RegistrationInfoComponent
  ]
})
export class RegistrationPageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(faCheck);
  }
}
