import { Component, OnInit } from '@angular/core';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { ReceiptsFacade } from '~features/receipts/receipts.facade';

@Component({
  selector: 'wic-receipts',
  templateUrl: './receipts.page.html',
  styleUrls: ['./receipts.page.scss'],
})
export class ReceiptsPage implements OnInit {
  constructor(
    public receipts: ReceiptsFacade,
    public enhancedMode: EnhancedModeFacade,
    private analytics: AnalyticsFacade
  ) {
  }

  ngOnInit() {
    this.analytics.pageView('receipts');
    this.receipts.restorePriorReceipts();
  }
}
