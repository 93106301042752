import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { CheckVendorModalComponent } from "../../components/check-vendor-modal/check-vendor-modal.component";
import { Vendor } from "~features/vendors/models";

@Injectable()
export class CantBuyThisService {

  constructor(
    private modal: ModalController
  ) {}

  async showCheckVendorModal(): Promise<Vendor> {
    const modal = await this.modal.create({
      component: CheckVendorModalComponent
    });

    await modal.present();
    
    const result = await modal.onDidDismiss();
    return result.role === 'yes' ? result.data : null;
  }
}