import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MicroBlog } from '~features/micro-blogs/models/micro-blog';

@Component({
  selector: 'wic-micro-blog-viewer-modal',
  templateUrl: './micro-blog-viewer-modal.component.html',
  styleUrls: ['./micro-blog-viewer-modal.component.scss'],
})
export class MicroBlogViewerModalComponent {
  @Input() microBlog: MicroBlog;
  @Input() lang = 'en';
  @Input() showExit = true;
  slideIndex = 0;

  constructor(public modal: ModalController) {
  }
}
