import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EnrichedBenefit } from '~features/benefits/models';

import { State } from '../state';

import { Product } from './models';
import { statusProduct, statusProductIsKnown, statusProductStatus } from './product-upc.selectors';
import { selectAllProducts } from './product.state';
import {
  checkProduct,
  currentProducts,
  currentProductsFiltered,
  deselectProducts,
  hasFilterCriteria,
  isSelectingProducts,
  isSQLiteError,
  itemDetailViewed,
  selectProducts,
  setProductFilter,
  totalAvailableCount,
} from './product.state';

@Injectable()
export class ProductFacade {
  statusProduct$ = this.store.select(statusProduct);
  statusProductStatus$ = this.store.select(statusProductStatus);
  statusProductIsKnown$ = this.store.select(statusProductIsKnown);
  currentProducts$ = this.store.select(currentProducts);
  currentProductsFiltered$ = this.store.select(currentProductsFiltered);
  isSelecting$ = this.store.select(isSelectingProducts);
  isSQLiteError$ = this.store.select(isSQLiteError);
  hasFilterCriteria$ = this.store.select(hasFilterCriteria);
  totalAvailableCount$ = this.store.select(totalAvailableCount);

  constructor(private store: Store<State>) {
  }

  checkProduct(product: Product, shouldNavigate = false): void {
    this.store.dispatch(checkProduct({product, shouldNavigate}));
  }

  selectProducts(categoryId: number, subCategoryId: number): void {
    this.store.dispatch(selectProducts({categoryId, subCategoryId}));
  }

  selectAllProducts(): void {
    this.store.dispatch(selectAllProducts());
  }

  setProductFilter(criteria: string): void {
    this.store.dispatch(setProductFilter({criteria}));
  }

  deselectProducts(): void {
    this.store.dispatch(deselectProducts());
  }

  viewItemDetail(benefit: EnrichedBenefit): void {
    this.store.dispatch(itemDetailViewed({benefit}));
  }
}
