import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Card } from '~features/registration/models';

@Component({
  selector: 'wic-registered-cards-list',
  templateUrl: './registered-cards-list.component.html',
  styleUrls: ['./registered-cards-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisteredCardsListComponent {
  @Input() cards: Card[];
  @Input() currentCard: Card;
  @Input() isLoading: boolean;
  @Output() selected = new EventEmitter<Card>();
  @Output() edited = new EventEmitter<Card>();
  @Output() deleted = new EventEmitter<Card>();

  noop() {
  }

  openSlidingItem(item: any) {
    item.open();
  }
}
