import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Translation,TranslocoLoader,TRANSLOCO_LOADER } from '@ngneat/transloco';
import { EMPTY,from,Observable,of } from 'rxjs';
import { catchError,map,switchMap,tap,withLatestFrom } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';

import { TranslocoLoaderData } from '@ngneat/transloco/lib/transloco.loader';
import { ConfigService } from '~core/config/config.service';
import { environment } from '~env';
import { STORAGE_CLOUD_KEY } from '~features/settings/cloud-settings-storage.service';

const TAGS = ['Service', 'Language Loader'];

@Injectable()
export class RemoteFallbackHttpLoaderService implements TranslocoLoader {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private log: LogService,
    private storage: Storage
  ) {}

  getTranslation(lang: string, data?: TranslocoLoaderData): Observable<Translation> | Promise<Translation> {
    console.warn("TranslocoLoaderData: ", data);
    return from(this.storage.get(STORAGE_CLOUD_KEY)).pipe(
      tap(settings => this.log.trace(TAGS, 'Raw cloud settings:', JSON.stringify(settings))),
      catchError(() =>
        of({
          admin: {
            hosts: {
              api: environment.hosts.api,
            },
          },
        })
      ),
      tap(settings => this.log.trace(TAGS, 'Cloud settings:', JSON.stringify(settings))),
      map(settings => ({
        apiHost: settings.admin.hosts.api || environment.hosts.api,
        authorityId: settings.registration.authority.id,
      })),
      catchError(() => of({ apiHost: environment.hosts.api, authorityId: null })),
      map(({ apiHost, authorityId }) =>
        this.config.appId === 1
          ? data?.scope === 'enhanced-issues'
            ? `${apiHost}/v1/i18n/${authorityId}/${lang}`
            : data?.scope === 'subcategory-translations'
            ? `${apiHost}/v1/i18n/${lang}`
            : `${apiHost}/v1/i18n/${lang}`
          : data?.scope === 'enhanced-issues'
            ? `${apiHost}/v1/i18n/${this.config.appId}/${authorityId}/${lang}`
            : data?.scope === 'subcategory-translations'
            ? `${apiHost}/v1/i18n/${this.config.appId}/${lang}`
            : `${apiHost}/v1/i18n/${this.config.appId}/${lang}`
      ),
      tap(apiUrl => this.log.warn(TAGS, `Loading translations from ${apiUrl}`)),
      withLatestFrom(
        //TODO :: fix this for scope changes
        of(`/assets/i18n/${lang}.json`),
        of(`/assets/i18n/en.json`)
      ),
      switchMap(([apiUrl, localPath, localEnPath]) =>
        this.http.get<Translation>(apiUrl).pipe(
          tap(value => console.log(value)),
          tap({
            error: err =>
              this.log.error(
                TAGS,
                `Error loading server language translation file. Falling back to local file...`,
                err
              ),
          }),
          catchError(() => this.http.get<Translation>(localPath)),
          tap({
            error: err =>
              this.log.error(
                TAGS,
                `Error loading language translation file. Falling back to local english...`,
                err
              ),
          }),
          catchError(() => this.http.get<Translation>(localEnPath)),
          tap({
            error: err => this.log.error(TAGS, `Error loading english translation file.`, err),
          }),
          catchError(() => EMPTY)
        )
      )
    );
  }
}

export const remoteFallbackLoader = {
  provide: TRANSLOCO_LOADER,
  useExisting: RemoteFallbackHttpLoaderService,
};
