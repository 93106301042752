import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthoritiesFacade } from '~features/authorities/authorities.facade';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { ConfigService } from '~core/config/config.service';

@Component({
  selector: 'wic-select-wic-provider',
  templateUrl: './select-wic-provider.page.html',
  styleUrls: ['./select-wic-provider.page.scss'],
})
export class SelectWicProviderPage implements OnInit {
  loadPending$: Observable<boolean>;


  private hasBeenLoaded$$ = new BehaviorSubject<boolean>(true);

  constructor(
    public authorities: AuthoritiesFacade,
    public config: ConfigService,
    public enhancedMode: EnhancedModeFacade,
    public registration: RegistrationFacade,
    public analytics: AnalyticsFacade) {
  }

  get criteria() {
    return this.config.appId === 1 ? { version: 2 } : { deescalateError: true, parents: { apps: this.config.appId } }
  }

  ngOnInit() {
    this.analytics.pageView('select-provider');

    this.loadPending$ = combineLatest([
      this.hasBeenLoaded$$,
      this.authorities.isLoading$
    ]).pipe(map(([a, b]) => a || b));

    setTimeout(() => {
      this.authorities.loadAll(this.criteria);
      this.hasBeenLoaded$$.next(false);
    }, 300);
  }

  refresh() {
    this.authorities.loadAll(this.criteria);
  }
}
