import { Pipe, PipeTransform } from '@angular/core';

const normalizeName = (name: string) =>
  `${name
    .replace(/.*(wal[\s-_]*mart|safeway|king[\s-]*soopers|target|save[\s-]*a[\s-]*lot|lowes[\s-]*mercado)(?:.*)/ig, '$1')
    .replace(/[\s-]/ig, '')
    .toLowerCase()}`;

const mapToExtension = (name: string) =>
  `${!!name.replace(/(walmart|safeway|kingsoopers|targe|savealot|lowesmercado)/ig, '$1') ? 'svg' : 'png'}`;

@Pipe({name: 'storeLogo'})
export class StoreLogoPipe implements PipeTransform {
  transform(name: string = ''): any {
    const normal = normalizeName(name);
    return `assets/images/stores/${normal}_logo.${mapToExtension(normal)}`;
  }
}
