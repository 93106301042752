<ion-grid data-cy="benefit-item"
    *ngIf="!!benefit"
    class="benefit-card p-0"
    [class.future]="isFuture"
    [class.one-left]="benefit.availableQuantity === 1"
    [class.none-left]="benefit.availableQuantity === 0">
  <ion-row>
    <ion-col size="4" size-sm="3" class="p-0">
      <wic-benefit-card-image
          [config]="config" [benefit]="benefit" [aplMessage]="aplMessage"
          (viewed)="viewed.emit($event)" (messageViewed)="messageViewed.emit($event)">
      </wic-benefit-card-image>
    </ion-col>
    <ion-col size="8" size-sm="9" class="benefit-card-body p-0">
      <ion-row class="benefit-card-label"
               [class.no-links]="!hasCalcs(benefit) && config?.linkDetails.length === 0"
               (click)="viewed.emit(benefit)">
        <ion-col size="10" class="p-0 benefit-card-description">
          <ion-row>
            <ion-col size="12" class="p-0" data-cy="benefit-card-amount">
              <h4 class="amount">
                <div *ngIf="currentLanguage === 'en'">
                  <span *ngIf="benefit.categoryId === 19 || benefit.categoryId === 97">${{benefit.availableQuantity | formatQuantity}}</span>
                  <span *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97">{{benefit.availableQuantity}} {{benefit.uom}}</span>
                </div>
                <div *ngIf="currentLanguage !== 'en'">
                  <span *ngIf="benefit.categoryId === 19 || benefit.categoryId === 97">${{benefit.availableQuantity | formatQuantity}}</span>
                  <span *ngIf="benefit.categoryId !== 19 && benefit.categoryId !== 97">
                    {{benefit.availableQuantity}} {{'subcategoryTranslations.' + benefit.categoryId + '.' + benefit.subCategoryId + '.uom' | translocoFallback: benefit.uom | async }}
                  </span>
                </div>
              </h4>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" class="p-0" data-cy="benefit-card-description">
              <span class="description">
                <div *ngIf="currentLanguage === 'en'">
                  {{benefit?.subCategory | transloco }}
                </div>
                <div *ngIf="currentLanguage !== 'en'">
                  {{ 'subcategoryTranslations.' + benefit?.categoryId + '.' + benefit?.subCategoryId + '.description' | translocoFallback: benefit?.subCategory | async }}
                </div>
<!--                  {{benefit?.subCategory?.length > 20 ? '...' : ''}}-->
              </span>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col size="2" class="p-0" *ngIf="!nonInteractive">
          <div class="benefit-card-link">
            <fa-icon [icon]="benefit.availableQuantity <= 0 ? 'times' : benefit.uom === '$$$' ? 'check' : 'arrow-right' "></fa-icon>
          </div>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="!nonInteractive && (hasCalcs(benefit) || config?.linkDetails.length > 0)">
        <ion-col size="12" class="p-0" data-cy="links">
          <ion-row class="benefit-card-recipe-links" *ngIf="isCalc(benefit)">

            <ion-col size="6" class="benefit-card-recipe-link p-0" *ngIf="config?.linkDetails.length > 0">
              <ion-item lines="none" (click)="linkOpened.emit(config.linkDetails[0].url)" [detail]="false">
                <ion-img slot="start" [src]="config?.linkDetails[0].icon" alt="{{ 'benefits.linkAlt' | transloco}}"></ion-img>
                <ion-label>{{config?.linkDetails[0].label | transloco}}</ion-label>
              </ion-item>
            </ion-col>

            <ion-col size="6" class="benefit-card-recipe-link p-0"
                     *ngIf="hasCalcs(benefit); else noCalc">
              <ion-item lines="none" (click)="calcOpened.emit(benefit)" [detail]="false">
                <ion-icon slot="start" name="calculator"></ion-icon>
                <ion-label>{{'benefits.calcLinkLabel' | transloco}}</ion-label>
              </ion-item>
            </ion-col>
            <ng-template #noCalc>
              <ion-col size="6" class="benefit-card-recipe-link p-0"
                       (click)="linkOpened.emit(config.linkDetails[1].url)"
                       *ngIf="config?.linkDetails.length > 1">
                <img [src]='config?.linkDetails[1].icon' role="button" alt="{{'benefits.linkAlt' | transloco}}">
                <span>{{config?.linkDetails[1].label | transloco}}</span>
              </ion-col>
            </ng-template>
          </ion-row>

          <ion-row class="benefit-card-recipe-links"
                   *ngIf="config?.linkDetails.length > 0 && benefit.categoryId !== 5 && benefit.categoryId !== 16 && benefit.categoryId !== 19 && benefit.categoryId !== 97">
            <ion-col size="6" class="benefit-card-recipe-link p-0" (click)="linkOpened.emit(link.url)"
                     *ngFor="let link of config?.linkDetails">
              <ion-item lines="none">
                <ion-img slot="start" [src]="link.icon"  alt="{{'benefits.linkAlt' | transloco}}"></ion-img>
                <ion-label>{{link.label | transloco}}</ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>

