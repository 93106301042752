import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {ItemListPage} from './item-list.page';
import {ComponentsModule} from '../../components/components.module';
import {ItemListHeaderComponent} from './item-list-header/item-list-header.component';
import {FormatBenefitPipe} from './format-benefit.pipe';
import {TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import {ItemLoadingErrorComponent} from './item-loading-error/item-loading-error.component';

const routes: Routes = [
  {
    path: '',
    component: ItemListPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    TranslocoModule
  ],
  declarations: [ItemListPage, ItemListHeaderComponent, ItemLoadingErrorComponent, FormatBenefitPipe]
})
export class ItemListPageModule {
}
