import { createSelector } from '@ngrx/store';
import { addDays, getDay } from 'date-fns';
import {
  DayToTimeSlotMap,
  TimeSlotDaysToDayMap,
  VendorTimeSlotDays,
  VendorTimeSlotDayString,
} from './vendor-time-slot.entity';
import { allVendorTimeSlots, vendorTimeSlotState } from './vendor-time-slots.state';
import { TimeSlotDayOption } from './time-slot-day-option.model';
import { currentBenefits } from '~features/benefits/benefits.selectors';

export const selectedDay = createSelector(vendorTimeSlotState, state => state.selectedDay);

const getCurrentDaysTimeSlot = (date = new Date()) => DayToTimeSlotMap[getDay(date)];

export const dayOptionsForTimeSlots = createSelector(allVendorTimeSlots, (timeSlots): TimeSlotDayOption[] => {
  const date = new Date();
  const day = getDay(date);
  const reorderedDays = [...DayToTimeSlotMap.slice(day), ...DayToTimeSlotMap.slice(0, day)];
  return reorderedDays.map((timeSlotDay, index) => ({
    day: TimeSlotDaysToDayMap[timeSlotDay],
    date: addDays(date, index),
    availableOrders: timeSlots.reduce(
      (total, timeSlot) =>
        timeSlot.day === timeSlotDay ? total + (timeSlot.orderCount ?? 0) : total,
      0
    ),

  }));
});

export const dayOptionsWithinBenefitPeriod = createSelector(dayOptionsForTimeSlots, currentBenefits, (dayOptions, benefits) =>
    dayOptions.filter(option => option.date < new Date(benefits.endDate))
)

export const vendorTimeSlotsForSelectedDay = createSelector(
  selectedDay,
  allVendorTimeSlots,
  (day: TimeSlotDayOption, timeSlots) => timeSlots.filter((slot) => slot.day === VendorTimeSlotDays[day.day])
);

export const trimmedTimeSlotsForSelectedDay = createSelector(
  selectedDay,
  vendorTimeSlotsForSelectedDay,
  (day, timeSlots) => {
    const date = new Date();
    const dayOfWeek = getDay(date);
    const hours = date.getHours()
    return dayOfWeek === VendorTimeSlotDays[day.day] ? timeSlots.filter((slot) => (slot.hour > hours + 1) && slot.orderCount > 0) : timeSlots
  }
)
