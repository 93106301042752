import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { currentProviderId } from '~features/registration/registration.selectors';
import { State } from '~features/state';

@Injectable()
export class AuthorityHeaderInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<State>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store.pipe(
      select(currentProviderId),
      take(1),
      switchMap(authorityId =>
        authorityId ? next.handle(req.clone({
            headers: req.headers.set('X-WICShopper-Authority', authorityId.toString())
          })
        ) : next.handle(req)
      ),
    );
  }
}
