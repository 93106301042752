import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  VENDOR_TIME_SLOTS_FEATURE_KEY,
  vendorTimeSlotsReducer,
} from '~features/vendor-time-slots/vendor-time-slots.state';
import { VendorTimeSlot } from '~features/vendor-time-slots/vendor-time-slot.entity';
import { EntityService } from '~core/services/entity.service';
import { VendorTimeSlotsFacade } from '~features/vendor-time-slots/vendor-time-slots.facade';
import { EffectsModule } from '@ngrx/effects';
import { VendorTimeSlotsEffects } from '~features/vendor-time-slots/vendor-time-slots.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(VENDOR_TIME_SLOTS_FEATURE_KEY, vendorTimeSlotsReducer),
    EffectsModule.forFeature([VendorTimeSlotsEffects])
  ],
  providers: [
    { provide: VendorTimeSlot, useClass: EntityService },
    VendorTimeSlotsFacade
  ],
})
export class VendorTimeSlotsStateModule {
}
