import { NgModule } from '@angular/core';
import { HomeFacade } from './home.facade';


@NgModule({
  providers: [
    HomeFacade
  ]
})
export class HomeModule {
}
