import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '~env';

import { EnvironmentService } from '~features/environment.service';
import { Authority } from '~features/authorities/models';

@Injectable()
export class ServerSettingsService {
  static settings = {
    authorityId: 0,
    productImageUrl: environment.hosts.cdn + '/products/:itemNumber.jpg'
  };

  constructor(private env: EnvironmentService, private http: HttpClient) {
  }

  load(authority: Authority): Observable<any> {
    return this.env.apiHost$.pipe(
      first(),
      map(apiHost => `${apiHost}/v1/settings?authorityId=${authority ? authority.id : 0}`),
      switchMap(url =>
        this.http.get<any>(url).pipe(
          tap(settings => ServerSettingsService.settings = settings)
        )
      )
    );
  }
}
