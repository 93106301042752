import { Injectable } from '@angular/core';
import { EntityActionTypes, ofEntityType } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { add, isAfter } from 'date-fns';
import { combineLatest, from, iif, of } from 'rxjs';
import { catchError, debounceTime, delay, exhaustMap, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { LogService } from '~core/services/log.service';
import { currentProviderId } from '~features/registration/registration.selectors';

import { Vendor, VendorFavorite } from './models';
import {
  allVendors,
  applyVendorSearch,
  clearVendors,
  loadAllVendors,
  loadIfNeeded,
  loadVendors,
  requestLocationPermission,
  resetVendors,
  returnToHome,
  vendorFavorited,
  vendorFavoriteToggled,
  vendorLoadFailed,
  vendorUserLocationChanged,
  vendorsLoaded,
  vendorsLoadedAt,
  vendorUnfavorited,
} from './vendors.state';
import { State } from '~features/state';
import { searchCriteria } from '~features/vendors/vendors.selectors';
import { ConfigService } from '~core/config/config.service';
import { NativePermissionsService } from '~core/services/native-permissions.service';
import { GeoLocationService } from '~core/services/geo-location.service';
import { RegistrationFacade } from '~features/registration/registration.facade';

const TAGS = ['Effects', 'Vendors'];

export const isFavorite = (vendor: Vendor, favorites: VendorFavorite[]) => (): boolean =>
  !!favorites.find(favorite => favorite.entryId === vendor.entryId);

@Injectable()
export class VendorsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private log: LogService,
    private config: ConfigService,
    private permissions: NativePermissionsService,
    private geolocation: GeoLocationService,
    private registration: RegistrationFacade
  ) {}

  toggleFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorFavoriteToggled),
      exhaustMap(({ vendor, favorites }) =>
        iif(
          isFavorite(vendor, favorites),
          of(vendorUnfavorited({ vendor })),
          of(vendorFavorited({ vendor }))
        )
      )
    )
  );

  favorite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorFavorited),
        tap(({ vendor }) => this.log.trace(TAGS, `User has favorited vendor ${vendor.name}.`))
      ),
    { dispatch: false }
  );

  unfavorite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorUnfavorited),
        tap(({ vendor }) => this.log.trace(TAGS, `User has un-favorited vendor ${vendor.name}.`))
      ),
    { dispatch: false }
  );

  trackLoadError$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(Vendor, EntityActionTypes.LoadAllFailure),
      map(() => vendorLoadFailed({}))
    )
  );

  vendorsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(Vendor, EntityActionTypes.LoadAllSuccess),
      map(() => vendorsLoaded())
    )
  );

  setDefaultSearchCriteria$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorsLoaded),
      withLatestFrom(this.store.select(searchCriteria)),
      map(([, criteria]) => ({
        ...criteria,
        anywhere: this.config.vendors.searchAnywhere || criteria.anywhere,
      })),
      map(criteria => applyVendorSearch({ criteria }))
    )
  );

  loadIfNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadIfNeeded),
      withLatestFrom(this.store.select(vendorsLoadedAt), this.store.select(allVendors)),
      filter(
        ([{ force }, loadedAt, vendors]) =>
          force ||
          !loadedAt ||
          !vendors.length ||
          isAfter(
            new Date(),
            add(loadedAt, {
              minutes: 1,
            })
          )
      ),
      delay(500),
      switchMap(() => from(this.geolocation.hasLocationPermission()).pipe(
        catchError(() => of(true))
      )),
      map(hasPermission => (hasPermission ? loadVendors() : requestLocationPermission()))
    )
  );

  requestLocationPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestLocationPermission),
      switchMap(() => this.permissions.explainGeolocationPermission('misty-moss', 'secondary')),
      switchMap(() => this.geolocation.requestLocationPermission()),
      map(hasPermission => (hasPermission === 'granted' ? loadVendors() : returnToHome()))
    )
  );

  loadVendors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadVendors),
      switchMap(() =>
        combineLatest([
          this.store.select(currentProviderId).pipe(first(id => !!id)),
          this.geolocation.getLocation().pipe(
            first(location => !!location),
            catchError(() => of(null))
          ),
        ])
      ),
      map(([id, location]) => loadAllVendors({
        criteria: {
          deescalateError: true,
          options: {
            location
          },
          parents: {
            authorities: id
          }
        }
      }))
    )
  );

  trackLocationChange$ = createEffect(() =>
    this.geolocation.location$.pipe(
      debounceTime(200),
      map(location => vendorUserLocationChanged({ location }))
    )
  )

  resetVendors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetVendors),
      map(() => clearVendors())
    )
  );
}
