import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, from, iif, of } from 'rxjs';
import { delay, exhaustMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { DialogsService } from '~core/services/dialogs.service';
import { LogService } from '~core/services/log.service';
import { allAuthorities, authorityRefreshed } from '~features/authorities/authorities.state';
import {
  abandonPendingRegistration,
  addCard,
  addCardRegistration,
  cardAlreadyRegistered,
  cardEdited,
  cardRegistrationCompleted,
  editCard,
  manageCards,
  nonProviderRegistrationCompleted,
  providerConfirmed,
  providerSelected,
  registerCard,
  registerPendingCard,
  registrationChanged,
  registrationResetComplete,
  registrationRestored,
  resetRegistration,
  restoreRegistration,
  selectProvider,
  setCurrentCard,
  skipCardRegistration,
  verityRegistrationChange
} from '~features/registration/registration.actions';
import { currentCards, currentProvider, currentRegistration } from '~features/registration/registration.selectors';
import { hasBenefitInfo } from '../benefits/benefits.selectors';
import { enrichBenefitsCompleted } from '../benefits/benefits.actions';
import { categoriesLoaded, hasCategories } from '../categories/categories.state';
import { CloudSettingsService } from '../settings/cloud-settings-storage.service';

import { State } from '../state';
import { hasSubCategories, subCategoriesLoaded } from '../subcategories/subcategories.state';
import { RegistrationService } from './registration.service';

const TAGS = ['State', 'Registration'];

@Injectable()
export class RegistrationEffects {
  constructor(
    private actions$: Actions,
    private cloudSettings: CloudSettingsService,
    private dialogs: DialogsService,
    private nav: NavController,
    private registration: RegistrationService,
    private router: Router,
    private transloco: TranslocoService,
    private store: Store<State>,
    private log: LogService) {
  }

  manageCards$ = createEffect(
    () => this.actions$.pipe(
      ofType(manageCards),
      tap(() => this.log.info(TAGS, 'Navigating to manage cards')),
      switchMap(({rooted}) => rooted
        ? this.nav.navigateRoot('/home').then(() => this.nav.navigateForward('/manage-cards'))
        : this.nav.navigateForward('/manage-cards')
      )
    ),
    {dispatch: false}
  );

  selectProvider$ = createEffect(
    () => this.actions$.pipe(
      ofType(selectProvider),
      withLatestFrom(this.store.select(currentProvider)),
      map(([{authority}, current]) => [authority, current]),
      tap(([selected, current]) =>
        this.log.info(TAGS, `User has selected provider '${selected.name}'. ${current ? `Current provider is '${current.name}'.` : ''}`)
      ),
      switchMap(([selected, current]) =>
        iif(
          () => !!current && current.id !== selected.id,
          of(verityRegistrationChange({selected, current})),
          of(providerSelected({selected}))
        )
      )
    )
  );

  verifyRegistrationChange$ = createEffect(
    () => this.actions$.pipe(
      ofType(verityRegistrationChange),
      switchMap(({selected, current}) =>
        from(this.registration.confirmSwitchProvider(selected, current)).pipe(
          filter(result => !!result),
          tap(() => this.log.warn(TAGS, `User has chosen to switch providers from '${current.name}' to '${selected.name}'.`)),
          map(() => registrationChanged({selected}))
        )
      )
    )
  );

  registrationChanged$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationChanged),
      tap(() => this.log.warn(TAGS, 'Registration has been changed. Purging previous authority and cards!.')),
      tap(() => this.cloudSettings.save({registration: {}}, true)),
      map(({selected}) => providerSelected({selected}))
    )
  );

  providerSelected$ = createEffect(
    () => this.actions$.pipe(
      ofType(providerSelected),
      switchMap(({selected}) =>
        from(this.registration.confirmProviderSelection(selected)).pipe(
          filter(result => !!result),
          tap(() => this.log.info(TAGS, `User has confirmed '${selected.name}' as their provider.`)),
          map(() => providerConfirmed({authority: selected}))
        )
      )
    )
  );

  saveRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(providerConfirmed, registrationRestored, authorityRefreshed),
      withLatestFrom(
        this.store.select(currentRegistration),
        this.store.select(allAuthorities)
      ),
      map(([, registration, authorities]) => !!registration ? ({
        ...registration,
        authority: authorities.find(authority => registration.authority?.id === authority.id) || registration.authority
      }) : registration),
      tap(registration => this.log.trace(TAGS, 'Saving registration:', registration)),
      tap(registration => this.cloudSettings.save({registration}))
    ),
    {dispatch: false}
  );

  addCardRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(addCardRegistration),
      tap(() => this.nav.navigateForward(['/registration']))
    ),
    {dispatch: false}
  );

  proceedToCardRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(providerConfirmed),
      filter(({authority}) => authority.isProvider),
      tap(({authority}) => this.log.debug(TAGS, `Completing provider selection for ${authority.name}.`)),
      tap(() => this.nav.navigateForward(['/registration']))
    ),
    {dispatch: false}
  );

  completeNonProviderRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(providerConfirmed),
      filter(({authority}) => !authority.isProvider),
      tap(({authority}) => this.log.debug(TAGS, `Completing non-provider registration for ${authority.name}.`)),
      map(() => nonProviderRegistrationCompleted())
    )
  );

  proceedToHome$ = createEffect(
    () => this.actions$.pipe(
      ofType(providerConfirmed),
      filter(({authority}) => !authority.isProvider),
      tap(() => this.nav.navigateRoot('/home'))
    ),
    {dispatch: false}
  );

  restoreRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(restoreRegistration),
      filter(({registration}) => !!registration && !!Object.keys(registration).length),
      tap(({registration}) =>
        this.log.debug(TAGS, `Restoring registration for authority ${registration.authority && registration.authority.name}.
          Current card: ${registration.currentCard}`)
      ),
      map(({registration}) => registrationRestored({registration}))
    )
  );

  registerCard$ = createEffect(
    () => this.actions$.pipe(
      ofType(registerCard),
      tap(({card}) => this.log.debug(TAGS, `Registering card: ${card && card.cardNumber}`)),
      withLatestFrom(this.store.select(currentCards)),
      switchMap(([{card}, cards]) =>
        iif(
          () => !!cards.find(c => c.cardNumber === card.cardNumber),
          of(cardAlreadyRegistered({card})),
          of(registerPendingCard({card}))
        )
      )
    )
  );

  registerPendingCard$ = createEffect(
    () => this.actions$.pipe(
      ofType(registerPendingCard),
      tap(({card}) => this.log.debug(TAGS, `Pending registration: ${card && card.cardNumber}`)),
      tap(() => this.nav.navigateForward(['/post-registration']))
    ),
    {dispatch: false}
  );

  cardAlreadyRegistered$ = createEffect(
    () => this.actions$.pipe(
      ofType(cardAlreadyRegistered),
      tap(({card}) => this.log.debug(TAGS, `Card already registered: ${card && card.cardNumber}`)),
      switchMap(({card}) => this.dialogs.alert({
        title: this.transloco.translate('registration.conflictWarningTitle'),
        message: this.transloco.translate('registration.conflictWarningTitle', {
          cardNumber: card.cardNumber.slice(card.cardNumber.length - 4, card.cardNumber.length)
        })
      })),
    ),
    {dispatch: false}
  );

  persistRegistrationToCloud$ = createEffect(
    () => this.actions$.pipe(
      ofType(registerPendingCard),
      tap(({card}) => this.log.debug(TAGS, 'Persisting registration to cloud: ' + (card && card.cardNumber))),
      withLatestFrom(this.store.select(currentRegistration)),
      tap(([, registration]) => this.cloudSettings.save({registration}))
    ),
    {dispatch: false}
  );

  skipCardRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(skipCardRegistration),
      switchMap(() =>
        this.dialogs.confirm({
          title: this.transloco.translate('registration.skipWarningTitle'),
          message: this.transloco.translate('registration.skipWarningMessage')
        })
      ),
      filter(result => !!result),
      tap(() => this.log.warn(TAGS, 'User has elected to skip card registration.')),
      tap(() => this.nav.navigateRoot('/home'))
    ),
    {dispatch: false}
  );

  registrationComplete$ = createEffect(
    () => this.actions$.pipe(
      ofType(registerPendingCard),
      tap(() => this.log.trace(TAGS, 'A card has been registered. Waiting for benefits and product details to load...')),
      switchMap(() =>
        forkJoin([
          this.actions$.pipe(
            ofType(enrichBenefitsCompleted),
            tap(() => this.log.trace(TAGS, 'Pending benefits enrichment complete!')),
            take(1)
          ),
          this.actions$.pipe(
            ofType(categoriesLoaded),
            tap(() => this.log.trace(TAGS, 'Pending categories load complete!')),
            take(1)
          ),
          this.actions$.pipe(
            ofType(subCategoriesLoaded),
            tap(() => this.log.trace(TAGS, 'Pending subcategories load complete!')),
            take(1)
          )
        ]).pipe(
          withLatestFrom(
            this.store.select(hasBenefitInfo),
            this.store.select(hasCategories),
            this.store.select(hasSubCategories)
          ),
          tap(([, hasBenes, hasCats, hasSubCats]) =>
            this.log.debug(TAGS, `Reg. Complete Check: Has Benes: ${!!hasBenes}; Has Cats: ${!!hasCats}; Has SubCats: ${!!hasSubCats}`)
          ),
          filter(([, hasBenes, hasCats, hasSubCats]) => !!hasBenes && !!hasCats && !!hasSubCats),
          tap(([benesAndCats]) => this.log.info(TAGS, 'Benefits loaded and enriched: ', benesAndCats)),
          delay(2000),
          map(() => cardRegistrationCompleted()),
          tap(() => this.nav.navigateRoot('/home')),
        )
      )
    )
  );

  saveRegistrationToCloud$ = createEffect(
    () => this.actions$.pipe(
      ofType(cardRegistrationCompleted, cardEdited, abandonPendingRegistration, setCurrentCard, addCard),
      withLatestFrom(this.store.select(currentRegistration)),
      tap(([, {currentCard: card}]) => this.log.debug(TAGS, 'Persisting registration to cloud: ' + (card && card.cardNumber))),
      tap(([, registration]) => this.cloudSettings.save({registration}))
    ),
    {dispatch: false}
  );

  resetRegistration$ = createEffect(
    () => this.actions$.pipe(
      ofType(resetRegistration),
      exhaustMap(() =>
        from(this.cloudSettings.save({registration: null}, true)).pipe(
          map(() => registrationResetComplete())
        )
      )
    )
  );

  editCard$ = createEffect(
    () => this.actions$.pipe(
      ofType(editCard),
      filter(({card}) => !!card),
      tap(({card}) => this.log.info(TAGS, `User is editing the card '${card.nickname || card.cardNumber}'.`)),
      withLatestFrom(this.store.select(currentProvider)),
      switchMap(([{card}, provider]) =>
        from(this.registration.editCard(card, provider)).pipe(
          filter(result => !!result),
          tap((editedCard) => this.log.info(TAGS, `User has edited the card '${card.nickname || card.cardNumber}'.`, editedCard)),
          map((editedCard) => cardEdited({card: editedCard}))
        )
      )
    )
  );
}
