<ion-header>
  <ion-toolbar color="appointments">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>
        {{ (!!(appointments.hasAppointment$ | async) ? 'appointments.contentTitle' : 'appointments.noAppointmentTitle')
        | transloco }}
      </h3>
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon icon="fingerprint" size="2x" [styles]="{opacity: '0.075', color: 'var(--white)'}"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <wic-card-select-toolbar
    color="appointments"
    [cards]="registration.currentCards$ | async"
    [currentCard]="registration.currentCard$ | async"
    (selected)="registration.selectCard($event)"
  ></wic-card-select-toolbar>
</ion-header>

<ion-content color="light">
  <!--  <wic-appointment-map [appointment]="appointments.appointment$ | async">-->
  <!--  </wic-appointment-map>-->
  <div class="full-map" [ngStyle]="{'background-image': 'url(' + (settings.currentProviderMap$ | async) + ')'}"></div>

  <section
    *ngIf="(appointments.isPending$ | async) || (appointments.isLoading$ | async); else currentAppointments"
    data-cy="appointment-loading"
  >
    <ion-list>
      <ion-item>
        <ion-label><h2>Loading your appointment</h2></ion-label>
        <ion-spinner slot="end" name="dots"></ion-spinner>
      </ion-item>
    </ion-list>
  </section>

  <ng-template #currentAppointments>
    <ng-container *ngIf="appointments.hasAppointment$ | async; else noAppointment">
      <wic-appointment-detail
        *ngFor="let appointment of appointments.appointmentsForCurrentCard$ | async"
        [appointment]="appointment"
        (directions)="appointments.directions($event)"
      ></wic-appointment-detail>
    </ng-container>

    <ng-template #noAppointment>
      <wic-no-appointment-warning
        (retry)="appointments.loadAppointment()"
        (changeMember)="appointments.changeAppointmentMember()"
      ></wic-no-appointment-warning>
    </ng-template>
  </ng-template>
</ion-content>
