<ion-card>
<!--  <ion-card-header color="light">-->
<!--    <ion-card-title>Scratchpad</ion-card-title>-->
<!--  </ion-card-header>-->

  <ion-item lines="none" class="item-group-header" color="light">
    <ion-label>
      <h1>{{ 'shoppingListsView.customItems' | transloco }}</h1>
    </ion-label>
  </ion-item>

  <ion-item *ngFor="let item of items; trackBy: trackByShoppingListItem">
    <ion-checkbox slot="start" [checked]="item.checkedOff" (ionChange)="checkBoxChanged($event, item)"></ion-checkbox>
    <ion-label class="ion-text-wrap">
      <h3>
        <span [ngClass]="{ 'checked-off': item.checkedOff }">{{ item.description }}</span>
      </h3>
    </ion-label>

    <ion-button slot="end" (click)="remove.emit(item.id)" fill="clear" color="danger">
      <ion-icon name="trash" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-item>

  <ion-card-content>
    <ion-fab vertical="bottom" horizontal="end">
      <ion-fab-button color="secondary" (click)="addItem.emit()">
        <ion-icon name="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-card-content>
</ion-card>
