import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LogService } from '~core/services/log.service';
import { APLMessagesFacade } from '~features/aplmessages/aplmessages.facade';
import { BannersFacade } from '~features/banners/banners.facade';
import { CalculatorsFacade } from '~features/calculators/calculators.facade';

import { HomeFacade } from '~features/home/home.facade';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { UpcFacade } from '~features/upc/upc.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { TRANSLOCO_SCOPE, TranslocoService } from '@ngneat/transloco';
import { SettingsFacade } from '~features/settings/settings.facade';

const TAGS = ['Pages', 'Benefits'];

@Component({
  selector: 'wic-benefits',
  templateUrl: './benefits.page.html',
  styleUrls: ['./benefits.page.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'subcategory-translations' }],
})
export class BenefitsPage implements OnInit {
  pendingExpirationMode$: Observable<boolean>;

  constructor(
    public home: HomeFacade,
    public activatedRoute: ActivatedRoute,
    public aplMessages: APLMessagesFacade,
    public registration: RegistrationFacade,
    public benefits: BenefitsFacade,
    public calculators: CalculatorsFacade,
    public upc: UpcFacade,
    public banners: BannersFacade,
    public enhancedMode: EnhancedModeFacade,
    public settings: SettingsFacade,
    public transloco: TranslocoService,
    private analytics: AnalyticsFacade,
    private log: LogService,
  ) {
  }

  ngOnInit() {
    this.analytics.pageView('benefits');
    this.pendingExpirationMode$ = this.activatedRoute.queryParamMap.pipe(
      tap(params => this.log.trace(TAGS, 'Query parameters are:', params)),
      filter(params => params.has('pendingExpirationMode')),
      map(params => params.get('pendingExpirationMode') as string | boolean),
      tap(mode => this.log.trace(TAGS, `Mode is ${mode}`, mode)),
      map(mode => mode === true || mode === 'true'),
      startWith(false)
    );
    this.benefits.viewBenefits();
  }
}
