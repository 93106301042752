import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'altCVVStyle'
})
export class AltCVVStylePipe implements PipeTransform {
  transform(status: string): any {
    return status === 'cvv' ? 'yes' : status;
  }
}
