import { IonContent } from '@ionic/angular';

export const scrollToEventTarget = async (event: Event): Promise<void> => {
  let total = 0;
  let container: IonContent = null;

  const calcPos = (obj) => {

    total += obj.offsetTop;
    const par = obj.offsetParent;
    if (par && par.localName !== 'ion-content') {
      calcPos(par);
    } else {
      container = par;
    }
  };

  calcPos(event.target);
  await container.scrollToPoint(0, total - 50, 400);
};
