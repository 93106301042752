import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EnrichedProduct } from '~features/products/models';
import { UPCInfo } from '~features/upc/models';

@Component({
  selector: 'wic-recent-upcs',
  templateUrl: './recent-upcs.component.html',
  styleUrls: ['./recent-upcs.component.scss'],
})
export class RecentUpcsComponent {
  @Input() recentUPCs: UPCInfo[];
  @Output() selected = new EventEmitter<string>();
}
