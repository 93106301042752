<div class="qr-code-container">
  <img
    *ngIf="imageUrl$ | async as data"
    [src]="data"
    [ngStyle]="{
      height: size,
      width: size
    }"
  />
</div>
