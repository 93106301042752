import { NgModule } from '@angular/core';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';

@NgModule({
  providers: [
    LaunchNavigator,
  ]
})
export class IonicModule {
}
