import { MonoTypeOperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

export const throwErrorIf = <T, E extends Error>(
  condition: (value: T) => boolean,
  errorFactory: (value: T) => E,
): MonoTypeOperatorFunction<T> =>
  tap((value: T) => {
    if (condition(value)) {
      throw errorFactory(value);
    }
  });
