import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { createFeatureSelector, createReducer } from '@ngrx/store';
import { SubstitutionConfig } from '~features/substitution-configs/substitution-config.entity';

export interface SubstitutionConfigsState extends IEntityState<SubstitutionConfig> {}

export const {
  actions: {
    loadAll: loadAllSubstitutionConfigs
  },
  selectors: {
    selectAll: allSubstitutionConfigs,
    selectEntities: substitutionConfigEntities
  },
  initialState: initialSubstitutionConfigsState,
  facade: SubstitutionConfigsFacadeBase,
} = buildState(SubstitutionConfig, {} as SubstitutionConfigsState);

export const substitutionConfigsReducer = createReducer(initialSubstitutionConfigsState);

export const SUBSTITUTION_CONFIGS_FEATURE_KEY = 'substitutionConfig';

export const substitutionConfigState = createFeatureSelector<SubstitutionConfigsState>(
  SUBSTITUTION_CONFIGS_FEATURE_KEY
);
