import { NgModule } from '@angular/core';
import { SubCategoriesFacade } from './subcategories.facade';


@NgModule({
  providers: [
    SubCategoriesFacade
  ]
})
export class SubCategoriesModule {
}
