import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { protocolButtonActivated } from '~features/home/home.state';
import { currentProviderId } from '~features/registration/registration.selectors';
import { loadAllSubstitutionConfigs } from '~features/substitution-configs/substitution-configs.state';

@Injectable()
export class SubstitutionConfigsEffects {
  constructor(private readonly actions$: Actions, private readonly store: Store) {}

  loadSubstitutionConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(protocolButtonActivated),
      filter(({ button }) => button.name === 'shoppingLists'),
      withLatestFrom(this.store.select(currentProviderId)),
      map(([, authorityId]) =>
        loadAllSubstitutionConfigs({
          criteria: {
            parents: {
              authorities: authorityId,
              apl: null,
            },
          },
        })
      )
    )
  );
}
