export { ComponentsModule } from './lib/components/components.module';
export { HomePageModule } from './lib/pages/home/home.module';
export { PostRegistrationPageModule } from './lib/pages/post-registration/post-registration.module';
export { RegistrationPageModule } from './lib/pages/registration/registration.module';

export { SelectWicProviderModule } from './lib/pages/select-wic-provider/select-wic-provider.module';
export { StartupPageModule } from './lib/pages/startup/startup.module';

export { HomePage } from './lib/pages/home/home.page';
export { PostRegistrationPage } from './lib/pages/post-registration/post-registration.page';
export { RegistrationPage } from './lib/pages/registration/registration.page';
export { SelectWicProviderPage } from './lib/pages/select-wic-provider/select-wic-provider.page';
export { StartupPage } from './lib/pages/startup/startup.page';
export { PipesModule } from './lib/pipes/pipes.module';



export { SettingsPageModule } from './lib/pages/settings/settings.module'
export { BenefitsPageModule } from './lib/pages/benefits/benefits.module';
export { BenefitsComponentsModule } from './lib/pages/benefits/benefits-components.module';
export { ManageCardsPageModule } from './lib/pages/manage-cards/manage-cards.module'
export { AppointmentsPageModule } from './lib/pages/appointments/appointments.module'
export { ItemDetailPageModule } from './lib/pages/item-detail/item-detail.module'
export { SearchUpcPageModule } from './lib/pages/search-upc/search-upc.module'
export { ItemListPageModule } from './lib/pages/item-list/item-list.module'
export { VendorsPageModule } from './lib/pages/vendors/vendors.module'
export { OfficesPageModule } from './lib/pages/offices/offices.module'
export { FutureBenefitsPageModule } from './lib/pages/future-benefits/future-benefits.module'
export { ReceiptsPageModule } from './lib/pages/receipts/receipts.module'
export { CantBuyThisPageModule } from './lib/pages/cant-buy-this/cant-buy-this.module'
export { BarcodeScannerPageModule } from './lib/pages/barcode-scanner/barcode-scanner.module'
export { CalculatorCVVPageModule } from './lib/pages/calculator-cvv/calculator-cvv.module'
export { CalculatorUomPageModule } from './lib/pages/calculator-uom/calculator-uom.module'
export { BlackPageModule } from './lib/pages/black/black.module'
export { QRCodePageModule } from './lib/pages/qr-code/qr-code.module';
export { RedemptionHistoryPageModule } from './lib/pages/redemption-history/redemption-history.module';
export { ShoppingListsModule } from './lib/pages/shopping-lists/shopping-lists.module';
export { EnhancedIssuesPageModule } from './lib/pages/enhanced-issues/enhanced-issues.module'
