import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { VendorFacade } from '~features/vendors/vendor.facade';
import { ShoppingList } from '~features/shopping-lists/shopping-list.entity';

@Component({
  selector: 'wic-select-vendor-modal',
  templateUrl: './select-vendor-modal.component.html',
  styleUrls: ['./select-vendor-modal.component.scss'],
})
export class SelectVendorModalComponent {
  @Input() shoppingList: ShoppingList;

  constructor(public modal: ModalController, public vendors: VendorFacade) {}
}
