import { Component, ElementRef, ViewChild } from '@angular/core';
import { LanguagesFacade } from '~features/languages/languages.facade';
import { SettingsFacade } from '~features/settings/settings.facade';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'wic-language-select-modal',
  templateUrl: './language-select-modal.component.html',
  styleUrls: ['./language-select-modal.component.scss'],
})
export class LanguageSelectModalComponent {
  @ViewChild('slides') slides?: ElementRef | undefined;
  isSelectingLang = false;

  constructor(public languages: LanguagesFacade,
              public modal: ModalController,
              public settings: SettingsFacade) {}

  async continueToSelectLang() {
    await this.slides?.nativeElement.swiper.slideNext();
    this.isSelectingLang = true;
  }
}
