import { Injectable } from '@angular/core';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  RegistrationError,
  Token,
} from '@capacitor/push-notifications';
import { from, ReplaySubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LogService } from './log.service';

const TAGS = ['Service', 'Notifications', 'Push'];

@Injectable()
export class PushNotificationsService {
  // Emits the current token registration.
  registrationToken$$ = new ReplaySubject<string>(1);
  // Emits when a push notification is received in app
  pushNotificationReceived$$ = new ReplaySubject<PushNotificationSchema>();
  // Emits when a push notification is tapped on
  pushNotificationActionPerformed$$ = new ReplaySubject<ActionPerformed>();

  constructor(private log: LogService) {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.checkPermissions().then((val) => this.log.trace(TAGS, `Permission status: ${val.receive}`));

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) =>
        FCM.getToken().then(tk => this.registrationToken$$.next(tk.token))
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: RegistrationError) => this.registrationToken$$.error(error));

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) =>
        this.pushNotificationReceived$$.next(notification)
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) =>
        this.pushNotificationActionPerformed$$.next(notification)
      );
    }
  }

  initialize() {
    return from(PushNotifications.register()).pipe(
      tap(() => console.log('Registered. Setting FCM auto init.')),
      switchMap(() => FCM.setAutoInit({ enabled: true }))
    );
  }

  async getToken() {
    const tokenRes = await FCM.getToken();
    return tokenRes?.token;
  }

  subscribe(topic: string) {
    return FCM.subscribeTo({ topic });
  }

  unsubscribe(topic: string) {
    return FCM.unsubscribeFrom({ topic });
  }

  async hasPermission() {
    const status = await PushNotifications.checkPermissions();
    return status.receive !== 'denied';
  }

  async requestPermission() {
    const status = await PushNotifications.requestPermissions();
    return status.receive !== 'denied';
  }
}
