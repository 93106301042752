import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'mask'})
export class MaskPipe implements PipeTransform {
  transform(value: string, count: number = 4, side: 's' | 'e' = 'e'): any {
    return !value ? '' : side === 's'
      ? value.substring(0, count) + '*'
      : '*' + value.substring(value.length - count);
  }
}
