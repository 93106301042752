import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createAction, createFeatureSelector, createReducer, on, props } from '@ngrx/store';
import { Card } from '../registration/models';
import { Appointment } from './models';

export interface IAppointmentState extends IEntityState<Appointment> {
  isPending: boolean;
}

export const {
  initialState,
  selectors: { selectAll: allAppointments },
  actions: {
    loadAll: loadAllAppointments,
    loadAllSuccess: loadAllAppointmentsSuccess,
    loadMany: loadManyAppointments,
    loadManySuccess: loadManyAppointmentsSuccess,
    clear: clearAppointments
  },
  facade: AppointmentsFacadeBase,
} = buildState(Appointment, {
  isPending: null,
});

export const viewAppointment = createAction(
  '[Appointments] View appointment',
  props<{ cardNumber: string; hhid?: string }>()
);

export const resetAppointments = createAction('[Appointments] Reset');

export const cardNotFoundForAppointment = createAction('[Appointments] Card Not Found');

export const loadAppointment = createAction('[Appointments] Load appointment');

export const appointmentsLoaded = createAction(
  '[Appointments] Appointments loaded',
  props<{ appointments: Appointment[] }>()
);

export const loadAppointmentForCard = createAction('[Appointments] Load appointment for card', props<{ card: Card }>());

export const listAppointments = createAction('[Appointments] List appointments', props<{ cards: Card[] }>());

export const changeAppointmentMemberInfo = createAction('[Appointments] Change Member Info');

export const demandAppointmentIdentity = createAction('[Appointments] Demand identity');

export const requestAppointment = createAction('[Appointments] Request appointment', props<{ card: Card }>());

export const launchAppointmentDirections = createAction(
  '[Appointments] Launch appointment directions',
  props<{ appointment: Appointment }>()
);

export const driveToAppointment = createAction(
  '[Appointments] Drive to address',
  props<{ appointment: Appointment; address: string }>()
);

const reduce = createReducer(
  initialState,
  on(loadAppointment, state => ({
    ...state,
    isPending: true,
  })),
  on(loadAppointmentForCard, state => ({
    ...state,
    isPending: false,
  })),
  on(resetAppointments, state => ({
    ...state,
    isPending: null
  }))
);

export function appointmentReducer(state = initialState, action: Action): IAppointmentState {
  return reduce(state, action);
}

export const appointmentsState = createFeatureSelector<IAppointmentState>('appointment');
