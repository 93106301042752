import {Injectable} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {DialogsService} from '~core/services/dialogs.service';
import {LogService} from '~core/services/log.service';
import {
  addCardRegistration,
  deleteCard,
  editCard,
  manageCards,
  registerCard,
  restoreRegistration,
  selectCard,
  selectProvider,
  skipCardRegistration
} from '~features/registration/registration.actions';
import {
  currentCard,
  currentCards,
  currentProvider,
  currentProviderId,
  currentProviderName,
  currentRegistration, enableNewHomeScreen,
  hasAppointmentReminders,
  hasCards,
  hasCurrentCard,
  hidesFutureBenefits,
  isOfflineOrVerifyOnly,
  isProvider,
  noCurrentCard,
  nonRealTimeBenefits,
  pendingCard,
  showFutureBenefits,
  showLastImport,
  showLastShoppingTrip,
} from '~features/registration/registration.selectors';

import {Authority} from '../authorities/models';
import {BenefitsFacade} from '../benefits/benefits.facade';
import {State} from '../state';
import {Card, Registration} from './models';

const TAGS = ['Facade', 'Registration'];

@Injectable()
export class RegistrationFacade {
  get currentProvider$(): Observable<Authority> {
    return this.store.select(currentProvider);
  }

  get currentProviderId$(): Observable<string | number> {
    return this.store.select(currentProviderId);
  }

  get currentProviderName$(): Observable<string> {
    return this.store.select(currentProviderName);
  }

  get isProvider$(): Observable<boolean> {
    return this.store.select(isProvider);
  }

  get hidesFutureBenefits$(): Observable<boolean> {
    return this.store.select(hidesFutureBenefits);
  }

  get hasAppointmentReminders$(): Observable<boolean> {
    return this.store.select(hasAppointmentReminders);
  }

  get showLastShoppingTrip$(): Observable<boolean> {
    return this.store.select(showLastShoppingTrip);
  }

  get showLastImport$(): Observable<boolean> {
    return this.store.select(showLastImport);
  }

  get nonRealTimeBenefits$(): Observable<boolean> {
    return this.store.select(nonRealTimeBenefits);
  }

  get enableNewHomeScreen$(): Observable<boolean> {
    return this.store.select(enableNewHomeScreen);
  }

  get isOfflineOrVerifyOnly$(): Observable<boolean> {
    return this.store.select(isOfflineOrVerifyOnly);
  }

  get currentRegistration$(): Observable<Registration> {
    return this.store.select(currentRegistration);
  }

  get currentCards$(): Observable<Card[]> {
    return this.store.select(currentCards);
  }

  get hasCards$(): Observable<boolean> {
    return this.store.select(hasCards);
  }

  get currentCard$(): Observable<Card> {
    return this.store.select(currentCard);
  }

  get hasCurrentCard$(): Observable<boolean> {
    return this.store.select(hasCurrentCard);
  }

  get noCurrentCard$(): Observable<boolean> {
    return this.store.select(noCurrentCard);
  }

  get pendingCard$(): Observable<Card> {
    return this.store.select(pendingCard);
  }

  get showFutureBenefits$(): Observable<boolean> {
    return this.store.select(showFutureBenefits);
  }

  constructor(
    private store: Store<State>,
    private benefits: BenefitsFacade,
    private dialogs: DialogsService,
    private transloco: TranslocoService,
    private log: LogService) {
  }

  manageCards(rooted = false): void {
    this.store.dispatch(manageCards({rooted}));
  }

  editCard(card: Card): void {
    this.store.dispatch(editCard({card}));
  }

  addCard(): void {
    this.store.dispatch(addCardRegistration());
  }

  selectCard(card: Card): void {
    this.store.dispatch(selectCard({card}));
  }

  deleteCard(card: Card): void {
    this.store.dispatch(deleteCard({card}));
  }

  registerCard(card: Card): void {
    this.store.dispatch(registerCard({card}));
  }

  skipCardRegistration(): void {
    this.store.dispatch(skipCardRegistration());
  }

  restoreRegistration(registration: Registration): void {
    this.log.info(TAGS, 'Restoring registration...', registration);
    this.store.dispatch(restoreRegistration({registration}));
  }

  selectProvider(authority: Authority): void {
    this.store.dispatch(selectProvider({authority}));
  }

  showCantFindAuthAlert() {
    this.dialogs.alert({
      title: this.transloco.translate('select-provider.providerMissingTitle'),
      message: this.transloco.translate('select-provider.providerMissingMessage')
    }).subscribe(() => {
    });
  }
}
