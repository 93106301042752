<ion-list *ngIf="hasHistory">
  <ion-item *ngFor="let receipt of receipts; let index=index">
    <ion-label (click)="selected.emit(receipt)">
      <h2>{{receipt.dateCaptured | date:'medium'}}</h2>
    </ion-label>
    <fa-icon
        (click)="selected.emit(receipt)"
        [icon]="selectedIndex === index ? 'angle-right' : 'receipt'" slot="start"
        [styles]="{opacity: selectedIndex === index ? '' : '0.25'}">
    </fa-icon>
    <fa-icon icon="trash" slot="end" (click)="deleted.emit(index)"></fa-icon>
  </ion-item>
</ion-list>
