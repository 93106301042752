import { createAction, props } from '@ngrx/store';
import { Category } from '../categories/models';
import { SubCategory } from '../subcategories/models';
import { BenefitCriteria, BenefitInfo, EnrichedBenefit, EnrichedBenefitInfo } from './models';

export const benefitsViewed = createAction(
  '[Benefits] Page Viewed'
);

export const criteriaRestored = createAction(
  '[Benefits] Criteria restored',
  props<{ criteria: BenefitCriteria }>()
);

export const loadBenefits = createAction(
  '[Benefits] Load',
  props<{ criteria?: BenefitCriteria; force?: boolean }>()
);

export const loadBenefitsWhenInitialized = createAction(
  '[Benefits] Load once app initialized',
  props<{ criteria?: BenefitCriteria; force?: boolean }>()
);

export const loadBenefitsForCriteria = createAction(
  '[Benefits] Load with criteria',
  props<{ criteria: BenefitCriteria }>()
);

export const viewFutureBenefits = createAction(
  '[Future Benefits] View'
);

export const backToCurrentBenefits = createAction(
  '[Future Benefits] Back to Current'
);

export const loadFutureBenefits = createAction('[Future Benefits] Load');

export const changeFutureGroup = createAction(
  '[Future Benefits] Change Group',
  props<{ direction: 'forward' | 'backward' }>()
);

export const setCurrentFutureGroupIndex = createAction(
  '[Future Benefits] Set Current Group Index',
  props<{ index: number }>()
);

export const clearFuture = createAction(
  '[Future Benefits] Clear'
);

export const loadBenefitsProgress = createAction(
  '[Benefits] Load: Progress',
  props<{ percentage: number }>()
);

export const loadBenefitsSuccess = createAction(
  '[Benefits] Loaded Successfully',
  props<{ benefitInfo: BenefitInfo; timestamp: Date }>()
);

export const nonActiveBenefitsLoaded = createAction(
  '[Benefits] Non-Active Loaded'
);

export const loadBenefitsFailure = createAction(
  '[Benefits] Load Failed',
  props<{ error: Error | any }>()
);

export const enrichBenefits = createAction(
  '[Benefits] Enrichment Initiated',
  props<{ benefitInfo: BenefitInfo; categories: Category[]; subCategories: SubCategory[] }>()
);

export const enrichBenefitsCompleted = createAction(
  '[Benefits] Enrichment Completed',
  props<{ enrichedBenefits: EnrichedBenefitInfo }>()
);

export const resetBenefits = createAction(
  '[Benefits] Reset'
);

export const benefitsResetComplete = createAction(
  '[Benefits] Reset: Complete'
);

export const viewBenefits = createAction(
  '[Benefit] View All',
  props<{ pendingExpirationMode?: boolean }>()
);

export const viewBenefit = createAction(
  '[Benefit] View',
  props<{ benefit: EnrichedBenefit }>()
);

export const openCustomBenefitLink = createAction(
  '[Benefit] Open Custom Link',
  props<{ linkUrl: string }>()
);

export const viewBenefitItems = createAction(
  '[Benefit] View: Items',
  props<{ benefit: EnrichedBenefit }>()
);

export const checkCVVBalance = createAction(
  '[Benefit] View: Check CVV Balance',
  props<{ benefit: EnrichedBenefit }>()
);

export const alertEmptyBenefit = createAction(
  '[Benefit] View: Empty Benefit',
  props<{ benefit: EnrichedBenefit }>()
);

export const selectBenefit = createAction(
  '[Benefit] Select Benefit',
  props<{ benefit: EnrichedBenefit }>()
);
