import { Pipe, PipeTransform } from '@angular/core';
import { Position  } from '@capacitor/geolocation';
import { Office } from '~features/offices/models';

@Pipe({name: 'officeAsGeoposition'})
export class OfficeAsGeopositionPipe implements PipeTransform {
  transform(office: Office): Position {
    return {
      timestamp: Date.now(),
      coords: {
        latitude: office.latitude,
        longitude: office.longitude
      }
    } as Position;
  }
}
