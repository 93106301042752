import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { BenefitError } from '~features/benefits/models/benefit-error';

@Component({
  selector: 'wic-benefit-error',
  templateUrl: './benefit-error.component.html',
  styleUrls: ['./benefit-error.component.scss'],
})
export class BenefitErrorComponent {
  @Input() error: BenefitError;

  constructor(public popover: PopoverController) {}
}
