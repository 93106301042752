import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { GeoLocationService } from '../../../core/services/geo-location.service';
import { LogService } from '~core/services/log.service';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { AppFacade } from '~features/app/app.facade';
import { VendorFacade } from '~features/vendors/vendor.facade';
import { VendorsService } from '../vendors.service';
import { Position } from '@capacitor/geolocation';

const TAGS = ['Tab', 'Vendors', 'Map'];

@Component({
  selector: 'wic-vendors-map-tab',
  templateUrl: './vendors-map-tab.component.html',
  styleUrls: ['./vendors-map-tab.component.scss'],
})
export class VendorsMapTabComponent implements OnInit {
  originalLocation$ = this.vendors.vendorUserLocation$.pipe(
    first(loc => !!loc)
  );

  constructor(
    public app: AppFacade,
    public vendors: VendorFacade,
    public vendorsSvc: VendorsService,
    public geolocation: GeoLocationService,
    private analytics: AnalyticsFacade,
    private log: LogService) {
    this.log.trace(TAGS, 'Constructing Vendors Map Tab');
  }

  ngOnInit() {
    this.analytics.pageView('vendors-map-tab');
    this.vendors.loadIfNeeded();
  }
}
