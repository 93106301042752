import { Entity, Key } from '@briebug/ngrx-auto-entity';

export enum VendorTimeSlotDays {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const TimeSlotDaysToDayMap = {
  [VendorTimeSlotDays.Sunday]: 'Sunday',
  [VendorTimeSlotDays.Monday]: 'Monday',
  [VendorTimeSlotDays.Tuesday]: 'Tuesday',
  [VendorTimeSlotDays.Wednesday]: 'Wednesday',
  [VendorTimeSlotDays.Thursday]: 'Thursday',
  [VendorTimeSlotDays.Friday]: 'Friday',
  [VendorTimeSlotDays.Saturday]: 'Saturday',
} as const

export type TimeSlotDaysToDayMapType = typeof TimeSlotDaysToDayMap
export type VendorTimeSlotDayString = TimeSlotDaysToDayMapType[keyof TimeSlotDaysToDayMapType]

export const DayToTimeSlotMap = [
  VendorTimeSlotDays.Sunday,
  VendorTimeSlotDays.Monday,
  VendorTimeSlotDays.Tuesday,
  VendorTimeSlotDays.Wednesday,
  VendorTimeSlotDays.Thursday,
  VendorTimeSlotDays.Friday,
  VendorTimeSlotDays.Saturday,
]

@Entity('VendorTimeSlot', {uriName: 'time-slots'})
export class VendorTimeSlot {
  @Key vendorId: number;
  @Key day: VendorTimeSlotDays;
  @Key hour: number;
  orderCount: number;
}
