<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button (click)="upcs.stopScanBarcode()"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content style="--background: transparent">
  <div class="scanner"></div>
</ion-content>
