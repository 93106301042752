import { createSelector } from '@ngrx/store';

import { EnrichedProduct } from './models';
import { getProductState, IProductState } from './product.state';
import { currentUPC, parsedUPC } from '../upc/upc.state';

export const mapToStatusProduct = (productState: IProductState, upc: string, parsed: string): EnrichedProduct =>
  productState.statusProduct || {
    itemNumber: upc,
    normalizedItemNumber: parsed,
    allowed: 0,
    status: 'unk'
  } as EnrichedProduct;

export const mapToStatusProductStatus = (product: EnrichedProduct): string =>
  product.status;

export const mapToProductIsKnown = (product: EnrichedProduct): boolean =>
  !!product && product.status !== 'chk' && product.status !== 'unk' && product.categoryId != null && product.subCategoryId != null;

export const statusProduct = createSelector(
  getProductState,
  currentUPC,
  parsedUPC,
  mapToStatusProduct
);

export const statusProductStatus = createSelector(
  statusProduct,
  mapToStatusProductStatus
);

export const statusProductIsKnown = createSelector(
  statusProduct,
  mapToProductIsKnown
);
