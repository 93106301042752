import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faPencilAlt, faTrash, faCreditCard, faPlus } from '@fortawesome/free-solid-svg-icons';

import { IonicModule } from '@ionic/angular';

import { ManageCardsPage } from './manage-cards.page';
import { ComponentsModule } from '../../components/components.module';
import { RegisteredCardsListComponent } from './registered-cards-list/registered-cards-list.component';
import { PipesModule } from '../../pipes/pipes.module';
import { TranslocoModule } from '@ngneat/transloco';

const routes: Routes = [
  {
    path: '',
    component: ManageCardsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    PipesModule,
    FontAwesomeModule,
    TranslocoModule
  ],
  declarations: [ManageCardsPage, RegisteredCardsListComponent]
})
export class ManageCardsPageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(faPencilAlt, faTrash, faCreditCard, faPlus);
  }
}
