import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import {NavController, Platform} from '@ionic/angular';
import {Observable, Subscription} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {LogService} from '~core/services/log.service';
import {NativePermissionsService} from '~core/services/native-permissions.service';
import {AnalyticsFacade} from '~features/analytics/analytics.facade';

import {EnhancedModeFacade} from '~features/enhanced-mode/enhanced-mode.facade';
import {MissingProductFacade} from '~features/missing-product/missing-product.facade';
import {ProductFacade} from '~features/products/product.facade';
import {uuid} from '../../util/func-util';
import { MockImageData } from './mock-image-data.model';

const TAGS = ['Page', 'Cant Buy This'];

@Component({
  selector: 'wic-cant-buy-this',
  templateUrl: './cant-buy-this.page.html',
  styleUrls: ['./cant-buy-this.page.scss'],
})
export class CantBuyThisPage implements OnInit, OnDestroy {
  productImage$: Observable<string>;
  labelImage$: Observable<string>;
  upcImage$: Observable<string>;

  form: UntypedFormGroup;
  submitted = false;

  private sub: Subscription;
  private readonly data: any;
  mockImageData = new MockImageData();

  constructor(
    public nav: NavController,
    public product: ProductFacade,
    public missingProduct: MissingProductFacade,
    public enhancedMode: EnhancedModeFacade,
    private permissions: NativePermissionsService,
    private analytics: AnalyticsFacade,
    private platform: Platform,
    private log: LogService,
    private router: Router,
    private builder: UntypedFormBuilder) {
    const navigation = router.getCurrentNavigation();
    if (navigation) {
      const state = navigation.extras.state;
      this.data = !Capacitor.isNativePlatform() ?
        {
          productImage: this.mockImageData.productImage,
          labelImage: this.mockImageData.labelImage,
          upcImage: this.mockImageData.upcImage,
          ...state
        } : state;
    }
  }

  ngOnInit() {
    this.analytics.pageView('cant-buy-this');
    this.missingProduct.displayIsThisYourStore();

    this.form = this.builder.group({
      name: [null],
      email: [null, [Validators.required, Validators.email]],
      phone: [null],
      storeName: [null],
      storeLocNum: [null],
      who: [null, Validators.required],
      brand: [null, Validators.required],
      productName: [null, Validators.required],
      packageSize: [null, Validators.required],
      productImage: [null, Validators.required],
      labelImage: [null, Validators.required],
      upcImage: [null, Validators.required],
      upc: [null, Validators.required],
      addtlInfo: [null],
      guid: uuid()
    });

    this.sub = new Subscription();

    if (this.data) {
      this.form.patchValue(this.data);
    }

    if (Capacitor.isNativePlatform()) {
      this.sub.add(this.missingProduct.productImage$.pipe(
        tap(productImage => this.log.trace(TAGS, `Preparing to update product image (image is ${productImage ? 'truthy' : 'falsy'}`)),
        tap(() => this.log.trace(TAGS, `Updating product image...`)),
        tap(productImage => this.form.patchValue({productImage}))
      ).subscribe());
    
      this.sub.add(this.missingProduct.labelImage$.pipe(
        tap(labelImage => this.log.trace(TAGS, `Preparing to update product image (image is ${labelImage ? 'truthy' : 'falsy'}`)),
        tap(() => this.log.trace(TAGS, `Updating label image...`)),
        tap(labelImage => this.form.patchValue({labelImage}))
      ).subscribe());

      this.sub.add(this.missingProduct.upcImage$.pipe(
        tap(upcImage => this.log.trace(TAGS, `Preparing to update product image (image is ${upcImage ? 'truthy' : 'falsy'}`)),
        tap(() => this.log.trace(TAGS, `Updating upc image...`)),
        tap(upcImage => this.form.patchValue({upcImage}))
      ).subscribe());
    }

    this.sub.add(this.missingProduct.currentUPC$.pipe(
      tap(upc => this.log.debug(TAGS, 'Selected missing product upc: ' + upc)),
      filter(upc => !!upc),
    ).subscribe(upc => {
      try {
        this.form.patchValue({upc});
      } catch (err) {
        this.log.error(TAGS, 'Error updating form with upc:', err);
      }
    }));

    this.sub.add(this.missingProduct.currentStore$.pipe(
      tap(vendor => this.form.patchValue({ storeName: vendor?.name }))
    ).subscribe());
  }

  ngOnDestroy(): void {
    this.missingProduct.clearCapturedPhoto();
    this.missingProduct.clearCapturedLabel();
    this.missingProduct.clearCapturedUpcPhoto();
    this.missingProduct.clearUPC();
    this.missingProduct.clearCurrentStore();
    this.sub.unsubscribe();
  }

  submit() {
    if (this.form.invalid) {
      this.submitted = true;
      return;
    }

    this.missingProduct.send(this.form.value);
  }
}
