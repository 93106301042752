<ion-header mode="ios">
  <ion-toolbar color="tufts-blue-shaded" mode="ios">
    <ion-title>{{ 'mobileOrdering.contentTitle' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="dismiss()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  
  <ion-card *ngIf="shoppingLists.currentShoppingListVendor$ | async as vendor">
    <ion-card-header translucent mode="ios">
      <ion-card-subtitle>{{ 'mobileOrdering.pickupLocation' | transloco }}</ion-card-subtitle>
      <ion-card-title>{{ vendor.name }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-label>
        <h2>
          <strong>
            {{ vendor.address1 }}
          </strong>
        </h2>
        <h2 *ngIf="!!vendor.address2">{{vendor.address2}}</h2>
        <h2>{{vendor.city}}, {{vendor.state}} {{vendor.zip}}</h2>
      </ion-label>
    </ion-card-content>
  </ion-card>

  <ion-item *ngIf="shoppingLists.current$ | async as shoppingList" lines="none">
    <ion-label *ngIf="shoppingList.vendorTimeSlot">
      <h2>{{ 'mobileOrdering.currentPickupDate' | transloco }}</h2>
      <p>{{ (shoppingList.vendorTimeSlot.hour | timeSlotHourFormat: shoppingList.orderDate) | date: dateFormat }}</p>
    </ion-label>
  </ion-item>

  <ion-item lines="none">
    <ion-label>{{ 'mobileOrdering.selectOrderDate' | transloco }}
      <p>{{ 'mobileOrdering.expiringMessage' | transloco }}</p>
    </ion-label>
  </ion-item>
  
  <ng-container *ngIf="benefits.currentBenefitsEndDate$ | async as benefitEndDate">
    <ion-item style="overflow-x: scroll;" lines="none">
      <ion-button shape="round" size="default" [fill]="option === (vendorTimeSlots.selectedDay$ | async) ? 'solid' : 'outline'" class="date-button" *ngFor="let option of vendorTimeSlots.dayOptionsForTimeSlots$ | async as daySelect"
        (click)="vendorTimeSlots.selectDay(option)"
        [disabled]="option.date > benefitEndDate">
          {{ option.date | date: 'EEE'}}<br>
          {{ option.date | date: 'MM/dd' }}
      </ion-button>
    </ion-item>
  </ng-container>

  <ion-item lines="none">
    <ion-label>{{ 'mobileOrdering.selectPickupTime' | transloco }}</ion-label>
  </ion-item>

  <ng-container  *ngIf="vendorTimeSlots.selectedDay$ | async as dayOption">
    <div class="time-button-container">
      <ion-button shape="round" [fill]="slot === (vendorTimeSlots.current$ | async) ? 'solid' : 'outline'" expand="block" class="time-button"
        *ngFor="let slot of vendorTimeSlots.timeSlotsForSelectedDay$ | async" (click)="vendorTimeSlots.select(slot)" [disabled]="slot.orderCount === 0">
        {{ slot.hour | timeSlotHourFormat : dayOption.date | date : 'shortTime' }} - {{ slot.orderCount }}
      </ion-button>
    </div>
  </ng-container>
  
  <ion-card *ngIf="vendorTimeSlots.current$ | async as timeSlot" style="margin-top: 40%">
    <ion-card-header *ngIf="vendorTimeSlots.selectedDay$ | async as dayOption">
      <ion-card-title style="font-size: 1.5em;">{{ 'mobileOrdering.selectedPickupDate' | transloco }}</ion-card-title>
      <ion-card-subtitle style="font-size: 1em;">
        <p>
          {{ dayOption.date | date }}
          <br />
          {{ timeSlot.hour | timeSlotHourFormat : dayOption.date | date : 'shortTime' }}
        </p>
      </ion-card-subtitle>
    </ion-card-header>
  </ion-card>
</ion-content>

<ion-footer *ngIf="vendorTimeSlots.current$ | async as timeSlot">
  <ion-button
    *ngIf="vendorTimeSlots.selectedDay$ | async as dayOption"
    class="ion-margin"
    color="primary"
    shape="round"
    expand="block"
    (click)="submit(dayOption, timeSlot)"
  >
    {{ 'mobileOrdering.placeOrderFor' | transloco }}
  </ion-button>
</ion-footer>
