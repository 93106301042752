import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import {
  abandonPendingRegistration,
  addCard,
  cardEdited,
  cardRegistrationCompleted,
  deleteCardConfirmed,
  providerConfirmed,
  registerPendingCard,
  registrationChanged,
  registrationMigrated,
  resetRegistration,
  restoreRegistration,
  setCurrentCard,
  skipCardRegistration
} from '~features/registration/registration.actions';
import { authorityRefreshed } from '../authorities/authorities.state';

import { Authority } from '../authorities/models';
import { Card, Registration } from './models';

export interface IRegistrationState {
  authority?: Authority;
  cards?: Card[];
  currentCard?: Card;
  pendingCard?: Card;
  migratedRegistration?: Registration;
}

export const initialRegistrationState: IRegistrationState = {};

const reduce = createReducer(
  initialRegistrationState,
  on(registrationMigrated, (state, {registration}) => ({
    ...state,
    migratedRegistration: registration
  })),
  on(registrationChanged, () => ({
    ...initialRegistrationState
  })),
  on(providerConfirmed, authorityRefreshed, (state, {authority}) => ({
    ...state,
    authority: authority || state.authority,
  })),
  on(restoreRegistration, (state, {registration}) => (!!registration && !!Object.keys(registration).length) ? {
    ...registration
  } : state),
  on(registerPendingCard, (state, {card}) => ({
    ...state,
    pendingCard: card
  })),
  on(abandonPendingRegistration, state => ({
    ...state,
    pendingCard: undefined
  })),
  on(cardRegistrationCompleted, (state) => ({
    ...state,
    cards: state.pendingCard ? [
      ...(state.cards || []),
      state.pendingCard
    ] : state.cards,
    currentCard: {
      ...(state.pendingCard ? state.pendingCard : state.currentCard)
    },
    pendingCard: undefined
  })),
  on(skipCardRegistration, state => ({
    ...state,
    pendingCard: undefined
  })),
  on(setCurrentCard, (state, {card}) => ({
    ...state,
    currentCard: {
      ...card
    }
  })),
  on(addCard, (state, {card}) => ({
    ...state,
    currentCard: {
      ...card
    },
    cards: [
      ...(state.cards || []),
      card
    ]
  })),
  on(resetRegistration, () => ({
    ...initialRegistrationState
  })),
  on(cardEdited, (state, {card}) => ({
    ...state,
    currentCard: !!state.currentCard && card.cardNumber === state.currentCard.cardNumber ? card : state.currentCard,
    cards: state.cards.map(existingCard =>
      existingCard.cardNumber === card.cardNumber ? card : existingCard
    )
  })),
  on(deleteCardConfirmed, (state, {card}) => {
    const cards = state.cards.filter(existing => existing.cardNumber !== card.cardNumber);
    return {
      ...state,
      currentCard: (!!state.currentCard && state.currentCard.cardNumber === card.cardNumber && !!cards.length)
        ? cards[0]
        : !cards.length ? null : state.currentCard,
      cards
    };
  })
);

export function registrationReducer(state = initialRegistrationState, action: Action): IRegistrationState {
  return reduce(state, action);
}

export const registrationState = createFeatureSelector<IRegistrationState>('registration')
