import { Injectable } from '@angular/core';
import { AlertController,ModalController, PopoverController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { from,Observable } from 'rxjs';

import { QRWarnModalComponent } from '../../components/qr-warn-modal/qr-warn-modal.component';
import { NoWicWarningComponent } from '../../components/no-wic-warning/no-wic-warning.component';
import { UpcFacade } from './upc.facade';

@Injectable()
export class UPCService {
  constructor(
    private alerts: AlertController,
    private modals: ModalController,
    private popovers: PopoverController,
    private transloco: TranslocoService,
    private upc: UpcFacade,
  ) {}

  warnQR(): Observable<boolean> {
    const p = async (): Promise<true> => {
      const modal = await this.modals.create({
        component: QRWarnModalComponent,
      });
      await modal.present();
      const result = await modal.onDidDismiss();
      return result.data;
    };
    return from(p());
  }

  async showNoWicWarning() {
    const popover = await this.popovers.create({
      component: NoWicWarningComponent,
      cssClass: 'no-wic-popover',
      alignment: 'center',
    })

    await popover.present();
    const result = await popover.onDidDismiss();
    return result.data;
  }
}
