import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faHeart as faHeartOpen } from '@fortawesome/free-regular-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import { Office, OfficeAndFavorites, OfficeFavorite } from '~features/offices/models';

@Component({
  selector: 'wic-offices-list',
  templateUrl: './offices-list.component.html',
  styleUrls: ['./offices-list.component.scss'],
})
export class OfficesListComponent {
  @Input() offices: Office[];
  @Input() favorites: OfficeFavorite[];

  @Output() selected = new EventEmitter<OfficeAndFavorites>();
  @Output() directions = new EventEmitter<Office>();
  @Output() favoriteToggled = new EventEmitter<OfficeAndFavorites>();
  @Output() pickToggled = new EventEmitter<OfficeAndFavorites>();

  faHeart = faHeart;
  faHeartOpen = faHeartOpen;
}
