<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button data-cy="close-button" (click)="modal.dismiss()">
        <ion-icon name="close-circle"></ion-icon>
        {{'error-modal.dismissButton' | transloco}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="warning-bg">
    <img src="/assets/warning.svg" [alt]="'error-modal.watermarkAlt' | transloco">
  </div>
  <div class="error-header">
    <h1 class="ion-text-center">{{'error-modal.subtitle' | transloco}}</h1>
    <h2 class="ion-text-center">{{'error-modal.title' | transloco}}</h2>
  </div>
  <div class="error-detail" *ngIf="!!error">
    <div class="error-detail-content">
      <h3>
        <ion-icon name="bug"></ion-icon>
        {{error.title}}
      </h3>
      <h4>{{error.detailedMessage || error.message}}</h4>

      <div *ngIf="(networkStatus.status$ | async) === false">
        <hr>
        <h5>
          <ion-icon name="wifi" size="large"></ion-icon>
          <p>{{'error-modal.noNetInfo1' | transloco}}</p>
          <p>{{'error-modal.noNetInfo2' | transloco}}</p>
        </h5>
      </div>

      <div>
        <hr>
        <h5>
          <ion-icon name="bulb" size="large"></ion-icon>
          <p>{{'error-modal.reportInfo1' | transloco}}</p>
          <p>{{'error-modal.reportInfo2' | transloco}}</p>
        </h5>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col size="6">
        <ion-button data-cy="report-button" size="large" strong="true" class="w-100" (click)="errors.reportError(error, screenshot)">
          <ion-icon name="cloud-upload" class="pr-5"></ion-icon>
          {{'error-modal.reportButton' | transloco}}
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button data-cy="support-button" size="large" strong="true" class="w-100" (click)="errors.requestSupport(error, screenshot)">
          <ion-icon name="chatboxes" class="pr-5"></ion-icon>
          {{'error-modal.supportButton' | transloco}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
