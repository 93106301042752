import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { defer, from, of, pipe } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { LogService } from '~core/services/log.service';
import { match } from '~jpma-wicshopper-imports-mono/utils/browser';
import { initializationCompleted } from '~features/app/app.actions';
import { addCard, selectCard } from '~features/registration/registration.actions';
import {
  currentCard,
  currentCards,
  currentProvider,
  hasCurrentCard
} from '~features/registration/registration.selectors';
import { State } from '../state';
import {
  appointmentsLoaded,
  cardNotFoundForAppointment, clearAppointments,
  demandAppointmentIdentity, loadAllAppointments, loadAllAppointmentsSuccess,
  loadAppointment,
  loadAppointmentForCard, loadManyAppointments, loadManyAppointmentsSuccess,
  requestAppointment, resetAppointments,
  viewAppointment
} from './appointments.state';
import { Appointment } from './models';

const TAGS = ['Effects', 'Appointment'];

export const logAppointmentRequest = (log: LogService) => pipe(
  tap(([, hasCard, card]) =>
    hasCard
      ? log.debug(TAGS, `Load appointment for card ${card.cardNumber} requested...`)
      : log.warn(TAGS, 'Load appointment requested but no card registered!')
  )
);

export const loadCardOrDemandIdentity = () => pipe(
  map(([, hasCard, card]) =>
    hasCard ? loadAppointmentForCard({card}) : demandAppointmentIdentity()
  )
);

@Injectable()
export class AppointmentEffects {
  constructor(private actions$: Actions, private store: Store<State>, private log: LogService, private nav: NavController) {
  }

  loadAppointmentForExistingRegistrations$ = createEffect(
    () => this.actions$.pipe(
      ofType(initializationCompleted),
      withLatestFrom(this.store.select(currentProvider), this.store.select(currentCards)),
      filter(([, authority, cards]) => !!cards && !!cards.length && !!authority.id),
      tap(([, authority, cards]) =>
        this.log.trace(TAGS, `Loading appointments for ${authority && authority.name} and ${cards && cards.length} cards...`)
      ),
      map(([, authority, cards]) => ({
        parents: {
          authorities: authority.id
        },
        query: {
          cardNumbers: cards.filter(card => !card.householdId).map(card => card.cardNumber).join(','),
          householdIds: cards.filter(card => !!card.householdId).map(card => card.householdId).join(',')
        }
      })),
      map(criteria => loadAllAppointments({ criteria }))
    )
  );

  viewAppointment$ = createEffect(
    () => this.actions$.pipe(
      ofType(viewAppointment),
      withLatestFrom(this.store.select(hasCurrentCard), this.store.select(currentCard), this.store.select(currentCards)),
      switchMap(([{cardNumber, hhid}, hasCurrent, card, allCards]) =>
        hasCurrent && (!!hhid || card.cardNumber === cardNumber) && (!hhid || card.householdId === hhid)
          ? of(card)
          : of(allCards.find(existingCard =>
          (!!hhid || cardNumber === existingCard.cardNumber) &&
          (!hhid || card.householdId === hhid)
          ))
      ),
      tap(foundCard => this.log.trace(TAGS, 'Found card for appointment:', foundCard)),
      tap(() => setTimeout(() => this.nav.navigateForward('/appointments'), 1000)),
      switchMap(foundCard =>
        match(
          () => foundCard,
          card => [match.isNullish, of(cardNotFoundForAppointment()).pipe(
            tap(() => this.log.trace(TAGS, 'Card not found for appointment...'))
          )],
          card => [match.anything, defer(() => from([
            selectCard({card}),
            loadAppointment()
          ]).pipe(
            tap(() => this.log.trace(TAGS, `Card found for appointment: ${foundCard && (foundCard.householdId || foundCard.cardNumber)}`))
          ))]
        )
      )
    )
  );

  loadAppointment$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadAppointment),
      withLatestFrom(this.store.select(hasCurrentCard), this.store.select(currentCard)),
      logAppointmentRequest(this.log),
      loadCardOrDemandIdentity()
    )
  );

  makeAppointmentCard$ = createEffect(
    () => this.actions$.pipe(
      ofType(requestAppointment),
      map(({card}) => addCard({card}))
    )
  );

  loadAppointmentForRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(requestAppointment),
      map(({card}) => loadAppointmentForCard({card}))
    )
  );

  loadAppointmentForCard$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadAppointmentForCard, selectCard),
      withLatestFrom(this.store.select(currentProvider)),
      map(([{card}, authority]) => ({
        parents: {
          authorities: authority.id,
          cards: authority.useHHIDForAppts ? card.householdId : card.cardNumber
        },
        query: {
          hhid: authority.useHHIDForAppts ? 'true' : 'false'
        }
      })),
      map(criteria => loadManyAppointments({ criteria }))
    )
  );

  completeAppointmentsLoading$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadAllAppointmentsSuccess, loadManyAppointmentsSuccess),
      map(({entities}) => appointmentsLoaded({appointments: entities}))
    )
  );

  resetAppointments$ = createEffect(() => this.actions$.pipe(
    ofType(resetAppointments),
    map(() => clearAppointments())
  ));
}
