import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, map, tap, switchMap } from 'rxjs/operators';

import { LogService } from '~core/services/log.service';
import { NotificationRegistration } from './models';

import { EnvironmentService } from '~features/environment.service';

const TAGS = ['Service', 'Notifications'];

@Injectable()
export class NotificationRegistrationService {
  constructor(private http: HttpClient, private log: LogService, private env: EnvironmentService) {
  }

  register(registration: NotificationRegistration): Observable<any> {
    return this.env.apiHost$.pipe(
      take(1),
      map(host => `${host}/v2/authorities/${registration.authorityId}/notifications/${registration.cardNumber}`),
      tap(url => this.log.trace(TAGS, `Set notifications registration at API: ${url}`)),
      switchMap(url =>
        this.http.put(url, registration).pipe(
          tap(reg => this.log.trace(TAGS, 'Updated notification registration.', reg)),
        )
      )
    );
  }

  unregisterToken(token: string): Observable<any> {
    return this.env.apiHost$.pipe(
      take(1),
      map(host => `${host}/v1/authorities/notifications/?token=${token}`),
      tap(url => this.log.trace(TAGS, `Delete all notifications registrations at API: ${url}`)),
      switchMap(url =>
        this.http.delete(url).pipe(
          tap(reg => this.log.trace(TAGS, 'Removed all notification registrations.', reg)),
        )
      )
    );
  }
}
