import { createSelector } from '@ngrx/store';
import { add, isAfter, parse } from 'date-fns';
import { BENEFITS_HARD_EXPIRE } from '~features/constants';
import { State } from '../state';
import { benefitsState, IBenefitsState } from './benefits.state';
import { BenefitCriteria, EnrichedBenefit, EnrichedBenefitInfo } from './models';

const INITIAL_BENEFIT_BALANCE = 0;

export const getBenefitsState = (state: State) => state.benefits;

export const mapToLastLoadedAt = (state: IBenefitsState): Date =>
  state.lastLoadedAt ? new Date(state.lastLoadedAt) : null;
export const getCurrentCriteria = (state: IBenefitsState): BenefitCriteria => state.currentCriteria;
export const getCurrentBenefits = (state: IBenefitsState): EnrichedBenefitInfo => state.currentBenefits;
export const mapToBalanceForBenefit = (
  benefits: EnrichedBenefitInfo,
  props: { categoryId: number; subCategoryId: number }
): number =>
  !!benefits && benefits.hasCurrent
    ? benefits.benefits.reduce(
        (balance, benefit) =>
          balance +
          (benefit.categoryId === props.categoryId && benefit.subCategoryId === props.subCategoryId
            ? benefit.availableQuantity
            : 0),
        INITIAL_BENEFIT_BALANCE
      )
    : 0;

export const mapToHasBenefitInfo = (state: IBenefitsState): boolean => !!state.currentBenefits;
export const mapToHasBenefits = (benefitInfo: EnrichedBenefitInfo): boolean =>
  !!benefitInfo && !!benefitInfo.benefits.length;
export const mapToHasCurrentBenefits = (benefitInfo: EnrichedBenefitInfo, has: boolean): boolean =>
  has && benefitInfo.hasCurrent;
export const mapToAreBenefitsExpired = (benefitInfo: EnrichedBenefitInfo): boolean =>
  !!benefitInfo ? benefitInfo.isExpired : false;
export const mapToHasPendingBenefits = (benefitInfo: EnrichedBenefitInfo): boolean => benefitInfo?.hasPendingBenefits;
export const mapToHasCurrentNonExpired = (hasCurrent: boolean, areExpired: boolean) => hasCurrent && !areExpired;
export const getIsLoading = (state: IBenefitsState): boolean => state.isLoading;
export const getLoadingProgress = (state: IBenefitsState): number => state.loadingProgress;
export const getLoadError = (state: IBenefitsState): Error | any => state.loadError;
export const mapToHasLoadError = (state: IBenefitsState): boolean => !!state.loadError;
export const getCurrentBenefit = (state: IBenefitsState): EnrichedBenefit => state.currentBenefit;
export const mapToRetrievedAtDate = (benefits: EnrichedBenefitInfo, has: boolean): Date =>
  has ? new Date(benefits.retrievedAt) : null;

// NOTE: DO NOT remove the `refreshedAt` parameter here! Even though it is not used in the implementation
// of the function, it is required to ensure the right things force the memoized result to be
// reevaluated and re-memoized!!!
export const mapToIsStale = (refreshedAt: Date, retrievedAt: Date): boolean =>
  retrievedAt ? isAfter(Date.now(), add(retrievedAt, { minutes: BENEFITS_HARD_EXPIRE })) : false;

export const lastLoadedAt = createSelector(benefitsState, mapToLastLoadedAt);
export const currentBenefitsCriteria = createSelector(benefitsState, getCurrentCriteria);
export const currentBenefits = createSelector(benefitsState, getCurrentBenefits);

export const currentBenefitsEndDate = createSelector(
  currentBenefits,
  (benefitInfo) => parse(benefitInfo.endDate, 'yyyyMMdd', new Date())
);
export const benefitBalance = createSelector(currentBenefits, mapToBalanceForBenefit);

export const hasBenefitInfo = createSelector(benefitsState, mapToHasBenefitInfo);
export const hasBenefits = createSelector(currentBenefits, mapToHasBenefits);
export const hasCurrentBenefits = createSelector(currentBenefits, hasBenefits, mapToHasCurrentBenefits);
export const areBenefitsExpired = createSelector(currentBenefits, mapToAreBenefitsExpired);
export const hasPendingBenefits = createSelector(currentBenefits, mapToHasPendingBenefits);
export const hasCurrentNonExpiredBenefits = createSelector(
  hasCurrentBenefits,
  areBenefitsExpired,
  mapToHasCurrentNonExpired
);
export const benefitsIsLoading = createSelector(getBenefitsState, getIsLoading);
export const benefitsLoadingProgress = createSelector(getBenefitsState, getLoadingProgress);
export const benefitsLoadError = createSelector(getBenefitsState, getLoadError);
export const benefitsHasLoadError = createSelector(getBenefitsState, mapToHasLoadError);
export const currentBenefit = createSelector(getBenefitsState, getCurrentBenefit);
export const benefitsRetrievedAt = createSelector(currentBenefits, hasCurrentBenefits, mapToRetrievedAtDate);
export const areCurrentBenefitsStale = createSelector(lastLoadedAt, benefitsRetrievedAt, mapToIsStale);
