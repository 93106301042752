<ion-header mode="ios">
  <ion-toolbar color="tufts-blue" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{ 'receipts.contentTitle' | transloco }}</h3>
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon icon="fingerprint" size="2x" [styles]="{opacity: '0.075', color: 'var(--white)'}"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-card class="receipt" *ngIf="receipts.hasReceiptImage$ | async; else noReceipt">
    <img [src]="receipts.receiptImage$ | async" alt="{{'receipts.receiptImageAlt' | transloco}}">
    <ion-card-header>
      <ion-card-subtitle>{{(receipts.currentReceipt$ | async)?.dateCaptured | date:'medium'}}</ion-card-subtitle>
      <ion-card-title>{{ 'receipts.currentReceiptTitle' | transloco}}</ion-card-title>
    </ion-card-header>
  </ion-card>

  <wic-receipt-history
      [hasHistory]="receipts.hasReceiptsHistory$ | async"
      [receipts]="receipts.receiptsHistory$ | async"
      [selectedIndex]="receipts.selectedIndex$ | async"
      (selected)="receipts.select($event)"
      (deleted)="receipts.delete($event)">
  </wic-receipt-history>

  <ng-template #noReceipt>
    <ion-card class="no-receipts">
      <ion-card-header>
        <ion-card-title>{{ 'receipts.noPriorTitle' | transloco }}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p>{{'receipts.noPriorInfo' | transloco}}</p>
      </ion-card-content>
    </ion-card>
  </ng-template>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>

<ion-footer mode="ios">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button expand="block" class="btn-misty-moss" (click)="receipts.captureReceipt()">
            <fa-icon icon="receipt" [fixedWidth]="true"></fa-icon>
            <span class="ion-text-uppercase">{{'receipts.captureButton' | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
