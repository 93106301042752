<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false)">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'debug-settings-modal.title' | transloco}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-list>
    <ion-item>
      <ion-checkbox slot="start" [checked]="log.isEnabled$ | async" (ionChange)="log.toggleLocal()"></ion-checkbox>
      <ion-label>{{'debug-settings-modal.localLogLabel' | transloco}}</ion-label>
    </ion-item>
    <ion-item>
      <ion-checkbox slot="start" [checked]="remoteLog.isShipping" (ionChange)="remoteLog.toggleShipper()"></ion-checkbox>
      <ion-label>{{'debug-settings-modal.remoteLogLabel' | transloco}}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
