import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'wic-add-item-from-popover',
  templateUrl: './add-item-from-popover.component.html',
  styleUrls: ['./add-item-from-popover.component.scss'],
})
export class AddItemFromPopoverComponent {
  constructor(public popover: PopoverController) {}
}
