import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { concatMap, filter, map, mergeMap, reduce, switchMap, take, tap, timeoutWith, windowTime } from 'rxjs/operators';
import { DeviceService } from '~core/services/device.service';

import { environment } from '~env';
import { EnvironmentService } from '~features/environment.service';
import { AdminSettingsFacade } from '~features/settings/admin-settings.facade';
import { DeviceInfo } from '~models';
import { onlyNotifications } from '../../util/rxjs-util';

export interface ShippableLogEntry {
  type: string;
  tags: string[];
  timestamp: string;
  message: string | any;
}

export interface Shipment {
  udid: string;
  sessionId: string;
  entries: ShippableLogEntry[];
}

export interface Registration {
  udid: string;
  sessionId: string;
  deviceInfo: DeviceInfo;
}

const LOG_SHIP_INTERVAL = 10000;

export const callLogSessions = (http: HttpClient) => (registration: Registration): Observable<any> =>
  http
    .post(`${EnvironmentService.apiHost || environment.hosts.api}/v1/devices/${registration.udid}/logs/sessions`, registration);

export const registerSession = (http: HttpClient) => (registration: Registration) => {
  callLogSessions(http)(registration)
    .subscribe(res => console.log(res));
};

export const deliverShipment = (http: HttpClient, trackFailedShipment: (shipment: Shipment) => void) => (shipment: Shipment) => {
  http
    .post(
      `${EnvironmentService.apiHost || environment.hosts.api}/v1/devices/${shipment.udid}/logs/sessions/${shipment.sessionId}/shipments`,
      shipment
    )
    .pipe(tap({error: () => trackFailedShipment(shipment)}))
    .subscribe(res => console.log(res), err => console.error(err));
};

@Injectable()
export class RemoteLogService {
  private log$$ = new ReplaySubject<ShippableLogEntry>(500);
  private shipper: Subscription;
  private failedShipmentContainer: Shipment[] = [];

  get log$(): Observable<ShippableLogEntry> {
    return this.log$$.asObservable();
  }

  get isShipping(): boolean {
    return !!this.shipper;
  }

  constructor(
    private http: HttpClient,
    private adminSettings: AdminSettingsFacade,
    private device: DeviceService) {
    adminSettings.logging$.pipe(
      filter(settings => !!settings),
      map(settings => settings.enableShipping),
      tap(enabled => enabled ? this.startShipper() : this.stopShipper()),
      filter(enabled => !!enabled),
      map(() => ({
        udid: this.device.uniqueId,
        deviceInfo: this.device.deviceInfo,
        sessionId: this.device.sessionId
      }))
    ).subscribe(registerSession(this.http));
  }

  ship(logEntry: ShippableLogEntry) {
    this.log$$.next({
      ...logEntry
    });
  }

  toggleShipper() {
    if (this.isShipping) {
      this.adminSettings.updateSettings({logging: {enableShipping: false}});
    } else {
      this.adminSettings.updateSettings({logging: {enableShipping: true}});
    }
  }

  startShipper() {
    if (!this.shipper) {
      console.log('[WIC] [v5] [Service] [RemoteLog] Shipper starting...');
      this.shipper = this.log$$.pipe(
        windowTime(LOG_SHIP_INTERVAL),
        mergeMap(res => res.pipe(
          reduce((acc, entry) => [...acc, entry], [])
        )),
        filter(entries => !!entries && !!entries.length),
        map(entries => ({
          udid: this.device.uniqueId,
          sessionId: this.device.sessionId,
          entries
        })),
        concatMap(shipment =>
          from(this.failedShipmentContainer.concat(shipment)).pipe(
            tap(() => this.failedShipmentContainer = [])
          )
        ),
        onlyNotifications()
      ).subscribe(deliverShipment(this.http, this.trackFailedShipment));
      console.log('[WIC] [v5] [Service] [RemoteLog] Shipper started.');
    }
  }

  stopShipper() {
    if (this.shipper) {
      console.log('[WIC] [v5] [Service] [RemoteLog] Shipper stopping...');
      this.shipper.unsubscribe();
      this.shipper = null;
      console.log('[WIC] [v5] [Service] [RemoteLog] Shipper stopped.');
    }
  }

  shipLog() {
    this.log$.pipe(
      timeoutWith(1000, of(null)),
      take(500),
      reduce((acc, entry) => entry ? [...acc, entry] : acc, []),
      map(entries => ({
        udid: this.device.uniqueId,
        sessionId: this.device.sessionId,
        entries
      })),
      switchMap(entries =>
        callLogSessions(this.http)({
          udid: this.device.uniqueId,
          deviceInfo: this.device.deviceInfo,
          sessionId: this.device.sessionId
        }).pipe(map(() => entries))
      ),
    ).subscribe(
      deliverShipment(this.http, this.trackFailedShipment),
      err => console.error('[WIC] [v5] [Service] [RemoteLog] Direct ship of log entries failed!', err)
    );
  }

  private trackFailedShipment(shipment: Shipment): void {
    this.failedShipmentContainer.push(shipment);
  }
}
