import { Injectable } from '@angular/core';
import {
  buildState,
  Clear,
  EntityActionTypes,
  IEntityState,
  LoadAllFailure,
  LoadAllSuccess,
  ofEntityType,
} from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, createAction, createReducer, createSelector, on, props, Store } from '@ngrx/store';
import { of, range } from 'rxjs';
import { concatMap, delay, map, takeUntil, tap } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { loadBenefits } from '../benefits/benefits.actions';
import { HardError } from '../error/error.state';
import { State } from '../state';
import { SubCategory } from '../subcategories/models';
import { Category } from './models';

const TAGS = ['State', 'Categories'];

export interface ICategoryState extends IEntityState<Category> {
  loadingProgress?: number;
  loadError?: Error | any;
}

export const {
  selectors: { selectAll: allCategories, selectEntities: categoryEntities },
  actions: { loadAll: loadAllCategories },
  initialState: initialCategoryState,
  facade: CategoryFacadeBase,
} = buildState(Category, {
  loadingProgress: null,
});

export const loadCategoriesProgress = createAction(
  '[Categories] Load: Progress',
  props<{ percentage: number; error?: Error | any }>()
);

export const categoriesLoaded = createAction(
  '[Categories] Loaded',
  props<{ categories: Category[]; timestamp: Date }>()
);

export const categoryLoadError = createAction('[Categories] Load: Error', props<{ error?: Error | any }>());

export const resetCategories = createAction('[Categories] Reset');

export const categoriesResetComplete = createAction('[Categories] Reset: Complete');

const reducer = createReducer(
  initialCategoryState,
  on(loadBenefits, state => ({
    ...state,
    loadingProgress: undefined,
    loadError: undefined,
  })),
  on(loadCategoriesProgress, (state, { percentage }) => ({
    ...state,
    loadingProgress: percentage,
  })),
  on(categoryLoadError, (state, { error }) => ({
    ...state,
    loadError: error,
  })),
  on(resetCategories, () => ({
    ...initialCategoryState,
  }))
);

export function categoryReducer(state = initialCategoryState, action: Action): ICategoryState {
  return reducer(state, action);
}

@Injectable()
export class CategoryEffects {
  constructor(private actions$: Actions, private log: LogService, private store: Store<State>) {}

  loadAllCategories$ = createEffect(
    () =>
      this.actions$.pipe(
        ofEntityType(Category, EntityActionTypes.LoadAll),
        tap(() =>
          range(0, 28)
            .pipe(
              concatMap(count => of(count).pipe(delay(Math.random() * 200 + 250))),
              map(count => (count > 30 ? 30 : count)),
              takeUntil(
                this.actions$.pipe(
                  ofEntityType(
                    Category,
                    EntityActionTypes.LoadAll,
                    EntityActionTypes.LoadAllSuccess,
                    EntityActionTypes.LoadAllFailure
                  )
                )
              )
            )
            .subscribe(count => this.store.dispatch(loadCategoriesProgress({ percentage: count / 30 })))
        )
      ),
    { dispatch: false }
  );

  categoriesLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(Category, EntityActionTypes.LoadAllSuccess),
      tap(() => this.log.debug(TAGS, 'Categories have been loaded')),
      map(({ entities }: LoadAllSuccess<Category>) =>
        categoriesLoaded({
          categories: entities,
          timestamp: new Date(),
        })
      )
    )
  );

  doneLoadingCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(Category, EntityActionTypes.LoadAllSuccess),
      map(() => loadCategoriesProgress({ percentage: 1 }))
    )
  );

  loadAllCategoriesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(Category, EntityActionTypes.LoadAllFailure),
      map(({ error }: LoadAllFailure<SubCategory>) =>
        categoryLoadError({
          error: new HardError(
            'Error loading product categories.',
            'Catalog Error',
            'We encountered an error loading product categories. This will ' +
              'likely affect your ability to use the app. This may be' +
              'a temporary network issue, so try again soon.',
            error
          ),
        })
      )
    )
  );

  resetCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetCategories),
      map(() => new Clear(Category))
    )
  );

  resetCategoriesComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(Category, EntityActionTypes.Clear),
      map(() => categoriesResetComplete())
    )
  );
}

export const getCategoriesState = (state: State) => state.category;

export const mapToLoadingProgress = (state: ICategoryState) => state.loadingProgress;
export const mapToLoadError = (state: ICategoryState) => state.loadError;
export const mapToHasLoadError = (state: ICategoryState) => !!state.loadError;

export const checkIfHasCategories = (state: ICategoryState) => !!state.ids && !!state.ids.length && !!state.tracking.loadedAt;

export const categoriesLoadingProgress = createSelector(getCategoriesState, mapToLoadingProgress);

export const categoriesLoadError = createSelector(getCategoriesState, mapToLoadError);

export const categoriesHasLoadError = createSelector(getCategoriesState, mapToHasLoadError);

export const hasCategories = createSelector(getCategoriesState, checkIfHasCategories);
