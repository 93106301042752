import { Component, Input, OnInit } from '@angular/core';
import { Authority } from '~features/authorities/models';

@Component({
  selector: 'wic-registration-info',
  templateUrl: './registration-info.component.html',
  styleUrls: ['./registration-info.component.scss'],
})
export class RegistrationInfoComponent implements OnInit {
  @Input() provider: Authority;
  @Input() instructions: string;

  constructor() {
  }

  ngOnInit() {
  }

}
