import { Entity, Key } from "@briebug/ngrx-auto-entity";


@Entity('VendorInventoryItem', { uriName: 'inventory-items' })
export class VendorInventoryItem {
  @Key itemNumber: string;
  exceptionId: number;
  price: number;

  salePrice: number;
  parWeight: number;
  costUnit: string;
}
