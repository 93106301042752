<ion-content [scrollY]="false">
  <div class="micro-blog-container">
    <wic-micro-blog-deck
      [microBlog]="microBlog"
      [lang]="lang"
      [showExit]="showExit"
      (slideIndexChanged)="slideIndex = $event"
      (closed)="modal.dismiss({ microBlog: microBlog })"
    ></wic-micro-blog-deck>
    <wic-micro-blog-post
      [microBlog]="microBlog"
      [lang]="lang"
      [showExit]="showExit"
      (closed)="modal.dismiss({ microBlog: microBlog })"
    ></wic-micro-blog-post>
  </div>
</ion-content>

<ion-footer *ngIf="microBlog">
  <ion-toolbar>
    <ion-button
      *ngIf="showExit && (microBlog.type === 'post' || microBlog.deck.length - 1 === slideIndex)"
      expand="block"
      color="tertiary"
      (click)="modal.dismiss({ microBlog: microBlog })"
    >
      {{ 'registration.done' | transloco }}
      <fa-icon slot="end" [fixedWidth]="true" icon="check"></fa-icon>
    </ion-button>
  </ion-toolbar>
</ion-footer>
