<ion-header mode="ios">
  <ion-toolbar color="tufts-blue-shaded" mode="ios">
    <ion-title>
      <h3>{{'shoppingLists.warnUnavailableItemsContentTitle' | transloco}}</h3>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" data-cy="home-button" (click)="modal.dismiss(null, 'cancel')">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-label>
      {{ 'shoppingLists.warnUnavailableItemsMessage' | transloco }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item *ngFor="let item of shoppingListItems">
    <ion-label>
      <h3>{{ item.description }}</h3>
      <p *ngIf="item.categoryId !== 19 && item.categoryId !== 97">
        {{ item.purchaseQuantity }} x {{ item.units }} {{ item.uom }}
      </p>
    </ion-label>
  </ion-item>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <ion-button color="danger" size="large" expand="block" (click)="modal.dismiss(null, 'cancel')">
            {{ 'buttons.cancel' | transloco }}
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button size="large" expand="block" (click)="modal.dismiss(null, 'dismiss')">
            {{ 'buttons.confirm' | transloco }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
