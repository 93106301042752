import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { webLinkOpened } from '~features/app/app.actions';
import { State } from '../state';
import {
  backToCurrentBenefits,
  benefitsViewed,
  changeFutureGroup,
  clearFuture,
  loadBenefits,
  loadFutureBenefits,
  resetBenefits,
  viewBenefit,
  viewBenefits,
  viewFutureBenefits,
} from './benefits.actions';
import {
  areBenefitsExpired,
  areCurrentBenefitsStale,
  benefitBalance,
  benefitsHasLoadError,
  benefitsIsLoading,
  benefitsLoadError,
  benefitsLoadingProgress,
  currentBenefit,
  currentBenefits, currentBenefitsEndDate,
  hasBenefits,
  hasCurrentBenefits,
  hasCurrentNonExpiredBenefits,
  lastLoadedAt,
} from './benefits.selectors';
import {
  afterFirstGroup,
  afterThirdMonth,
  atFirstGroup,
  currentFutureGroup,
  futureGroupExists, latestBenefitsEndDateFormatted,
} from './future-benefits.selectors';
import { BenefitCriteria, EnrichedBenefit, EnrichedBenefitInfo } from './models';

const TAGS = ['Facade', 'Benefits'];

@Injectable()
export class BenefitsFacade {
  get hasBenefits$(): Observable<boolean> {
    return this.store.select(hasBenefits);
  }

  get hasCurrentBenefits$(): Observable<boolean> {
    return this.store.select(hasCurrentBenefits);
  }

  get hasCurrentNonExpiredBenefits$(): Observable<boolean> {
    return this.store.select(hasCurrentNonExpiredBenefits);
  }

  get areExpired$(): Observable<boolean> {
    return this.store.select(areBenefitsExpired);
  }

  get currentBenefits$(): Observable<EnrichedBenefitInfo> {
    return this.store.select(currentBenefits);
  }

  get currentBenefitsEndDate$(): Observable<Date> {
    return this.store.select(currentBenefitsEndDate);
  }

  get currentFutureGroup$() {
    return this.store.select(currentFutureGroup);
  }

  get futureGroupExists$(): Observable<boolean> {
    return this.store.select(futureGroupExists);
  }

  get latestBenefitsEndDateFormatted$(): Observable<string> {
    return this.store.select(latestBenefitsEndDateFormatted);
  }

  get afterThirdMonth$(): Observable<boolean> {
    return this.store.select(afterThirdMonth);
  }

  get afterFirstGroup$(): Observable<boolean> {
    return this.store.select(afterFirstGroup);
  }

  get atFirstGroup$(): Observable<boolean> {
    return this.store.select(atFirstGroup);
  }

  get currentBenefit$(): Observable<EnrichedBenefit> {
    return this.store.select(currentBenefit);
  }

  get lastLoadedAt$(): Observable<Date> {
    return this.store.select(lastLoadedAt);
  }

  get loadingProgress$(): Observable<number | null> {
    return this.store.select(benefitsLoadingProgress);
  }

  get isLoading$(): Observable<boolean> {
    return this.store.select(benefitsIsLoading);
  }

  get loadError$(): Observable<Error | any> {
    return this.store.select(benefitsLoadError);
  }

  get hasLoadError$(): Observable<boolean> {
    return this.store.select(benefitsHasLoadError);
  }

  benefitBalance$(categoryId: number, subCategoryId: number): Observable<number> {
    return this.store.select(benefitBalance, { categoryId, subCategoryId });
  }

  get areCurrentBenefitsStale$(): Observable<boolean> {
    return this.store.select(areCurrentBenefitsStale);
  }

  constructor(private store: Store<State>, private log: LogService) {}

  viewBenefits(): void {
    this.store.dispatch(benefitsViewed());
  }

  load(criteria: BenefitCriteria, force = false): void {
    this.store.dispatch(loadBenefits({ criteria, force }));
  }

  viewFuture(): void {
    this.log.trace(TAGS, 'Viewing future benefits...');
    this.store.dispatch(viewFutureBenefits());
  }

  backToCurrent(): void {
    this.log.trace(TAGS, 'Back to current benefits...');
    this.store.dispatch(backToCurrentBenefits());
  }

  nextFutureGroup() {
    this.store.dispatch(changeFutureGroup({ direction: 'forward' }));
  }

  previousFutureGroup() {
    this.store.dispatch(changeFutureGroup({ direction: 'backward' }));
  }

  clearFuture(): void {
    this.store.dispatch(clearFuture());
  }

  refresh(): void {
    this.store.dispatch(loadBenefits({ force: true }));
  }

  reset(): void {
    this.store.dispatch(resetBenefits());
  }

  viewAll(): void {
    this.store.dispatch(viewBenefits({}));
  }

  view(benefit: EnrichedBenefit): void {
    this.store.dispatch(viewBenefit({ benefit }));
  }

  openLink(linkUrl: string): void {
    this.store.dispatch(webLinkOpened({ linkUrl }));
  }
}
