import { Pipe, PipeTransform } from '@angular/core';
import { MicroBlog } from '~features/micro-blogs/models/micro-blog';
import { LanguageCode } from '~features/settings/models';

@Pipe({name: 'content'})
export class ContentPipe implements PipeTransform {
  transform(blog: MicroBlog, lang: string): string {
    return (
      blog.localizedContent.find(lc => lc.lang === LanguageCode[lang]) || blog.localizedContent.find(lc => lc.lang === 'English')
    ).content || '';
  }
}
