import { NgModule } from '@angular/core';
import { BenefitsFacade } from './benefits.facade';
import { BenefitsService } from './benefits.service';


@NgModule({
  providers: [
    BenefitsFacade,
    BenefitsService
  ]
})
export class BenefitsModule {
}
