import {SubCategory} from '../../subcategories/models';
import { Key, Entity } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Category',
  uriName: 'categories'
})
export class Category {
  authorityId: string;
  @Key categoryId: number;
  description: string;
  subCategories?: SubCategory[];
}
