import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LogEntry } from '~core/services/log.service';

@Component({
  selector: 'wic-log-detail-modal',
  templateUrl: './log-detail-modal.component.html',
  styleUrls: ['./log-detail-modal.component.scss'],
})
export class LogDetailModalComponent {
  @Input() entry: LogEntry;

  constructor(public modal: ModalController) {
  }
}
