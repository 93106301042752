<ion-header>
  <ion-toolbar color="white">
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false)">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="confirm-bg">
    <img src="/assets/bigcheck.svg" [alt]="'provider-selected-modal.confirmWatermarkAlt' | transloco">
  </div>
  <div class="confirm-header">
    <h1 class="ion-text-center">{{'provider-selected-modal.verifyHeader' | transloco}}</h1>
    <h2 class="ion-text-center">{{'provider-selected-modal.confirmHeader' | transloco}}</h2>
  </div>
  <div class="confirm-detail">
    <div class="confirm-detail-content ion-text-center">
      <img src="{{env.azureGateway.baseUrl}}/get-logo/{{authority?.id}}?subscription-key={{env.azureGateway.subscriptionKey}}" [alt]="authority?.name | transloco"
           role="button"/>
      <h4>{{authority?.name | transloco}}</h4>
    </div>
  </div>
</ion-content>

<ion-footer>
  <ion-toolbar color="white">
    <ion-grid>
      <ion-row>
        <ion-col>
        <ion-button expand="block" color="tufts-blue" class="btn-tufts-blue" (click)="modal.dismiss(true)" data-cy="provider-selected-confirm">
          <ion-icon name="checkmark-circle"></ion-icon>
          <span class="ion-text-uppercase">{{'provider-selected-modal.closeButton' | transloco}}</span>
        </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
