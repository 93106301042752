import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { plural } from 'pluralize';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';

import { environment } from '~env';

import { EnvironmentService } from '~features/environment.service';
import { HardError, SilentError, SoftError } from '~features/error/error.state';

const TAGS = ['Service', 'Entity'];

export interface EntityCriteria {
  parents?: EntityParent;
  query?: QueryCriteria;
  uriName?: string;
  param?: string | number | string[] | number[];
  version?: 1 | 2 | 3;
  options?: any;
  deescalateError?: boolean;
  reuseInput?: {
    [key: string]: boolean;
  };
}

export interface EntityParent {
  [key: string]: string | number;
}

export interface QueryCriteria {
  [key: string]: string | number | string[] | number[];
}

export type EntityKey = string | number;

export const EmptyKey = null;

export const buildParentPaths = (criteria: EntityCriteria): string =>
  Object.keys((criteria && criteria.parents) || {})
    .map(parent => `/${parent}${criteria.parents[parent] === EmptyKey ? '' : `/${criteria.parents[parent]}`}`)
    .reduce((path, parent) => path + parent, '');

export const buildEntityPath = (info: IEntityInfo, key?: any, criteria?: EntityCriteria): string =>
  `/${(!!criteria ? criteria.uriName : null) || info.uriName || info.pluralName || plural(info.modelName.toLowerCase())}${
    (key != null) ? `/${key}` : (criteria && criteria.param) ? `/${criteria.param}` : ''
  }`;

export const buildQueryString = (criteria: EntityCriteria): string =>
  (criteria && criteria.query) ? Object
    .keys(criteria.query)
    .map(param => `${param}=${criteria.query[param]}`)
    .join('&')
    : '';

export const buildUrl = (info: IEntityInfo, criteria?: EntityCriteria, key: any = null, host: string = environment.hosts.api): string => {
  const parentPaths = buildParentPaths(criteria);
  const entityPath = buildEntityPath(info, key, criteria);
  const query = buildQueryString(criteria);

  const url = `${host}/v${(criteria && criteria.version) || 1}${parentPaths}${entityPath}${query ? `?${query}` : ''}`;

  return url;
};

export const HARD_ERROR_ENTITIES = [
  'Authority'
];
export const IGNORE_404_ENTITIES = [
  'Appointment'
];

export const handleHardError = (info: IEntityInfo, action: 'load' | 'create', error: any, forMany = false) =>
  throwError(new HardError(
    `Error ${action === 'load' ? 'loading' : 'creating'} ${forMany ? plural(info.modelName) : info.modelName}`,
    `Data Error`,
    `We encountered an issue trying to ${action} ${forMany ? plural(info.modelName) : info.modelName}.
    This issue will likely interfere with or prevent your use of the app. Contact JPMA for support.`,
    error
  ));

export const handleSoftError = (info: IEntityInfo, action: 'load' | 'create', error: any, forMany = false) =>
  throwError(new SoftError(
    `Error ${action === 'load' ? 'loading' : 'creating'} ${forMany ? plural(info.modelName) : info.modelName}`,
    `Data Error`,
    `We encountered an issue trying to ${action} ${forMany ? plural(info.modelName) : info.modelName}.
    This issue may or may not affect use of the app. If the issue persists, contact JPMA for support.`,
    error
  ));

export const handleSilentError = (info: IEntityInfo, action: 'load' | 'create', error: any, forMany = false) =>
  throwError(new SilentError(
    `Could not ${action === 'load' ? 'find' : 'create'} ${forMany ? plural(info.modelName) : info.modelName}`,
    error
  ));

export const is404 = (error: any) =>
  (error.originalError || error) instanceof HttpErrorResponse &&
  ((error.originalError || error || {}).error || error.originalError || error || {}).status === 404;

export const isHardErrorEntity = (info: IEntityInfo) =>
  !!HARD_ERROR_ENTITIES.find(name => name === info.modelName);

export const isIgnore404Entity = (info: IEntityInfo) =>
  !!IGNORE_404_ENTITIES.find(name => name === info.modelName);

export const handleError = (log: LogService, info: IEntityInfo, action: 'load' | 'create', error: any, forMany = false, deescalate = false) => {
  log.error(TAGS, `Entity error: `, error);
  return isHardErrorEntity(info) && !deescalate
    ? handleHardError(info, action, error, forMany)
    : (is404(error) && isIgnore404Entity(info)) || deescalate
      ? handleSilentError(info, action, error, forMany)
      : handleSoftError(info, action, error, forMany);
};

@Injectable()
export class EntityService implements IAutoEntityService<any> {
  constructor(private http: HttpClient, private log: LogService, private env: EnvironmentService) {
  }

  load(info: IEntityInfo, key: EntityKey, criteria: EntityCriteria): Observable<any> {
    return this.env.apiHost$.pipe(
      take(1),
      map(host => buildUrl(info || {} as IEntityInfo, criteria, key, host)),
      tap(url => this.log.trace(TAGS, `Load ${info.modelName} entity from API: ${url}`)),
      switchMap(url =>
        this.http.get<any>(url).pipe(
          tap(() => this.log.trace(TAGS, 'Loaded entity from API: ')),
          tap(result => this.log.local.trace(TAGS, result)),
          catchError(error => handleError(this.log, info, 'load', error))
        )
      )
    );
  }

  loadAll(info: IEntityInfo, criteria: EntityCriteria): Observable<any> {
    return this.env.apiHost$.pipe(
      take(1),
      map(host => buildUrl(info || {} as IEntityInfo, criteria, undefined, host)),
      tap(url => this.log.trace(TAGS, `Load all ${info.modelName} entities from API: ${url}`)),
      switchMap(url =>
        this.http.get<any>(url).pipe(
          tap(result => this.log.trace(TAGS, `Loaded all entities from API: ${result && result.length}`)),
          tap(result => this.log.local.trace(TAGS, result)),
          catchError(error => handleError(this.log, info, 'load', error, true, criteria?.deescalateError))
        )
      )
    );
  }

  loadMany(info: IEntityInfo, criteria: EntityCriteria): Observable<any> {
    return this.env.apiHost$.pipe(
      take(1),
      map(host => buildUrl(info || {} as IEntityInfo, criteria, undefined, host)),
      tap(url => this.log.trace(TAGS, `Load many ${info.modelName} entities from API: ${url}`)),
      switchMap(url =>
        this.http.get<any>(url).pipe(
          tap(result => this.log.trace(TAGS, `Loaded many entities from API: ${result && result.length}`)),
          tap(result => this.log.local.trace(TAGS, result)),
          catchError(error => handleError(this.log, info, 'load', error, true))
        )
      )
    );
  }

  create(info: IEntityInfo, entity: any, criteria: EntityCriteria): Observable<any> {
    return this.env.apiHost$.pipe(
      take(1),
      map(host => buildUrl(info || {} as IEntityInfo, criteria, undefined, host)),
      tap(url => this.log.trace(TAGS, `Create ${info.modelName} entities at API: ${url}`)),
      switchMap(url =>
        this.http.post<any>(url, entity).pipe(
          tap(result => this.log.trace(TAGS, `Created entity at API: ${url}`, result)),
          tap(result => this.log.local.trace(TAGS, result)),
          map(result => criteria.reuseInput
            ? {
              ...result,
              ...Object.keys(criteria.reuseInput).filter(key => criteria.reuseInput[key]).reduce(
                (overwrite, key) => (overwrite[key] = entity[key], overwrite), {}
              )
            }
            : result
          ),
          catchError(error => handleError(this.log, info, 'create', error, true))
        )
      )
    );
  }
}
