<div class="map" *ngIf="!!location">
  <google-map
    height="100%"
    width="100%"
    [center]="location | locationToLatLng"
    [zoom]="12"
    [options]="{ disableDefaultUI: true, clickableIcons: false }"
  >
    <map-marker
      *ngIf="userLocation"
      [position]="userLocation | locationToLatLng"
      [icon]="userIcon"
      data-cy="map-user-icon"
    >
    </map-marker>

    <map-marker
      *ngFor="let vendor of vendors"
      [position]="vendor | vendorAsGeoposition | locationToLatLng"
      [icon]="vendor.groupCode | groupCodedIcon : true | async"
      (mapClick)="selected.emit({ vendor: vendor, favorites: favorites })"
      [ngStyle]="{ color: vendor.groupCode | groupCodedColor }"
      data-cy="map-vendors-icon"
    >
    </map-marker>
  </google-map>
</div>
