import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import Dexie, { Transaction } from 'dexie';
import { LogService } from '~core/services/log.service';
import { getColumns, getPrimaryKey, getTable } from './database.decorators';
import { DatabaseService, DBQuery } from './database.service';

const TAGS = ['Service', 'IndexedDb'];

export const filterLike = <T>(where: DBQuery<T>) => (item: T): boolean =>
  Object.entries(where.like).every(([key, val]) =>
    typeof item[key] === 'string' ? item[key].includes(val) : item[key].toString().includes(val)
  );

export const filterIn = <T>(where: DBQuery<T>) => (item: T): boolean =>
  Object.entries(where.in).every(([key, val]: [string, any[]]) => val.includes(item[key]));

@Injectable()
export class IndexedDBService implements DatabaseService {
  db: Dexie;
  config = {
    name: 'wicshopper.db',
  };
  tables = {};

  constructor(private log: LogService) {
  }

  initialize(): Promise<void> {
    this.db = new Dexie(this.config.name);
    return Promise.resolve();
  }

  async createTable<T>(entity: new () => T): Promise<any> {
    const pk = getPrimaryKey(entity);
    const columns = getColumns(entity);
    const table = getTable(entity);
    this.tables = {...this.tables, [table]: Object.keys(columns).join()};
    this.db.version(1).stores(this.tables);
  }

  async removeTable<T>(entity: new () => T): Promise<any> {
    const table = getTable(entity);
    const remaining = this.tables[table] && delete this.tables[table];
    console.log(remaining);
    this.db.version(1).stores(this.tables).upgrade((tx) => {
      this.log.debug(TAGS, 'Remaining Stores', tx.storeNames);
    });
  }

  async getTableInfo<T>(entity: new () => T): Promise<any> {
    return {
      // eslint-disable-next-line no-underscore-dangle
      tables: Object.keys(this.db._allTables),
      // eslint-disable-next-line no-underscore-dangle
      dbSchema: this.db._dbSchema,
      name: this.db.name,
    };
  }

  async insert<T>(entity: new () => T, items: T[], tx?: Transaction): Promise<T[]> {
    const res = await this.getTable(entity, tx).bulkAdd(items);
    this.log.debug(TAGS, 'INSERT MANY RES', res);
    return items;
  }

  async insertOne<T>(entity: new () => T, item: T, tx?: Transaction): Promise<T> {
    await this.getTable(entity, tx).add(item);
    return item;
  }

  async upsert<T>(entity: new () => T, items: T[], tx?: Transaction): Promise<T[]> {
    await this.getTable(entity, tx).bulkPut(items);
    return items;
  }

  async upsertOne<T>(entity: new () => T, item: T, tx?: Transaction): Promise<T> {
    await this.getTable(entity, tx).put(item);
    return item;
  }

  async update<T>(entity: new () => T, items: Partial<T>[], tx?: Transaction): Promise<T[]> {
    const pk = getPrimaryKey(entity);
    const table = this.getTable(entity, tx);
    const updates = await Promise.all(items.map(item => table.update(item[pk], item)));
    const sum = updates.reduce((prev, cur) => prev + cur, 0);
    this.log.debug(TAGS, 'DEXIE UPDATED', sum);

    const res = table.where(pk).anyOf(items.map(item => item[pk])).toArray();
    this.log.trace(TAGS, 'UPDATE RESULT', res);
    return res;
  }

  async updateOne<T>(entity: new () => T, item: Partial<T>, tx?: Transaction): Promise<T> {
    await this.getTable(entity, tx).update(getPrimaryKey(entity), item);
    return this.getTable(entity, tx).where(item).first();
  }

  async removeMany<T>(entity: new () => T, items: Partial<T>[], tx?: Transaction): Promise<T[]> {
    const pk = getPrimaryKey(entity);
    const table = this.getTable(entity, tx);

    const query = table.where(pk).anyOf(items.map(item => item[pk]));
    const res = await query.toArray();
    this.log.trace(TAGS, 'DELETE RESULT', res);
    const deleted = await query.delete();
    this.log.debug(TAGS, 'DELETE COUNT', deleted);
    return res;
  }

  async remove<T>(entity: new () => T, item: Partial<T>, tx?: Transaction): Promise<T[]> {
    const query = this.getTable(entity, tx).where(item);
    const res = await query.toArray();
    const count = await query.delete();
    this.log.debug(TAGS, 'DELETE COUNT', count);
    this.log.trace(TAGS, 'DELETE RESULT', res);
    return res;
  }

  async removeAll<T>(entity: new () => T, tx?: Transaction): Promise<number> {
    const count = await this.getTable(entity, tx).count();
    this.log.debug(TAGS, 'DELETE ALL COUNT', count);
    await this.getTable(entity, tx).clear();
    return count;
  }

  // Possibly just use simple filter and test each object directly.
  // Would be slower but less complex for browser usage.
  select<T>(entity: new () => T, query?: DBQuery<T>): Promise<T[]> {
    const col = query && query.where ? this.getTable(entity).where(query.where) : this.getTable(entity).toCollection();
    const inRes = query && query.in ? col.and(filterIn(query)) : col;
    const likeRes = query && query.like ? inRes.filter(filterLike(query)) : inRes;
    return likeRes.toArray();
  }

  selectProducts<T>(entity: new () => T, query: DBQuery<T>): Promise<T[]> {
    const now = format(Date.now(), 'yyyyMMdd');
    const time = parseInt(now, 10);
    const col = query && query.where ? this.getTable(entity).where(query.where) : this.getTable(entity).toCollection();
    /* eslint-disable @typescript-eslint/dot-notation */
    const available = col
      .filter((item: T) => item['effectiveDate'] <= time || !item['effectiveDate'] || item['effectiveDate'] === '00000000')
      .filter((item: T) => item['endDate'] >= time || !item['endDate'] || item['endDate'] === '00000000');
    const likeRes = query && query.like ? available.filter(filterLike(query)) : available;
    return likeRes.toArray();
  }

  getTable<T>(entity: new () => T, tx?: Transaction) {
    return tx ? tx.table(getTable(entity)) : this.db.table(getTable(entity));
  }

  createTransaction<U>(callback: (tx: Transaction) => Promise<U> | U): Promise<U> {
    return this.db.transaction('readwrite', this.db.tables.map(t => t.name), (tx) => callback(tx));
  }
}
