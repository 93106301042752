import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { GeoLocationService } from '~core/services/geo-location.service';
import { LogService } from '~core/services/log.service';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { AppFacade } from '~features/app/app.facade';
import { HomeFacade } from '~features/home/home.facade';
import { OfficesFacade } from '~features/offices/offices.facade';
import { OfficesService } from '../offices.service';
import { Position } from '@capacitor/geolocation';

const TAGS = ['Tab', 'Offices', 'Map'];

@Component({
  selector: 'wic-offices-map-tab',
  templateUrl: './offices-map-tab.component.html',
  styleUrls: ['./offices-map-tab.component.scss'],
})
export class OfficesMapTabComponent implements OnInit {
  originalLocation$ = this.offices.officeUserLocation$.pipe(first(loc => !!loc));

  constructor(
    public app: AppFacade,
    public home: HomeFacade,
    public offices: OfficesFacade,
    public officesSvc: OfficesService,
    public geolocation: GeoLocationService,
    private analytics: AnalyticsFacade,
    private log: LogService) {
    this.log.trace(TAGS, 'Constructing Offices Map Tab');
  }

  ngOnInit() {
    this.analytics.pageView('offices-map-tab');
    this.offices.loadIfNeeded();
  }
}
