import { NgModule } from '@angular/core';
import { AppointmentsFacade } from './appointments.facade';


@NgModule({
  providers: [
    AppointmentsFacade
  ]
})
export class AppointmentsModule {
}
