import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'wic-future-benefits-button',
  templateUrl: './future-benefits-button.component.html',
  styleUrls: ['./future-benefits-button.component.scss'],
})
export class FutureBenefitsButtonComponent {
  @Output() clicked = new EventEmitter();
}
