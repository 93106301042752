import { createSelector } from '@ngrx/store';
import { currentShoppingListKey } from '../shopping-lists.state';
import { allShoppingListItems, shoppingListItemEntities, shoppingListItemsState } from './shopping-list-items.state';
import { vendorInventoryItemEntities } from '~features/vendor-inventory-items/vendor-inventory-items.state';
import { allSubstitutions } from '~features/shopping-lists/substitutions/substitutions.state';

export const quantityEditId = createSelector(shoppingListItemsState, state => state.quantityEditId);
export const currentShoppingListItemProduct = createSelector(shoppingListItemsState, state => state.currentProduct);

export const quantityEditItem = createSelector(shoppingListItemEntities, quantityEditId, (entities, id) =>
  id && entities?.[id]
);

export const shoppingListItemsWithSubstitutions = createSelector(
  allShoppingListItems,
  allSubstitutions,
  (shoppingListItems, substitutions) => shoppingListItems.map((item) => ({
    ...item,
    substitutions: substitutions?.filter(sub => sub.mobileOrderItemId === item.id) || []
  }))
)

export const itemsForCurrentShoppingList = createSelector(
  shoppingListItemsWithSubstitutions,
  currentShoppingListKey,
  (items, shoppingListId) => items.filter(item => item.shoppingListId === shoppingListId)
);

export const customItemsForCurrentShoppingList = createSelector(
  itemsForCurrentShoppingList,
  items => items?.filter(item => !!item.customItem)
);

export const nonCustomItemsForCurrentShoppingList = createSelector(
  itemsForCurrentShoppingList,
  items => items?.filter(item => !item.customItem)
);

export const itemsNotInVendorsInventory = createSelector(
  nonCustomItemsForCurrentShoppingList,
  vendorInventoryItemEntities,
  (listItems, inventoryItems) =>
    listItems.filter(item =>
      !inventoryItems?.[item.itemNumber]
    )
)

export const hasCheckedOffItem = createSelector(
  itemsForCurrentShoppingList,
  (items) => !!items?.some(item => item.checkedOff)
);
