import { Component } from '@angular/core';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { HostsSettingsMode } from '~models';

@Component({
  selector: 'wic-enhanced-mode-fab',
  templateUrl: './enhanced-mode-fab.component.html',
  styleUrls: ['./enhanced-mode-fab.component.scss'],
})
export class EnhancedModeFabComponent {
  hostsSettingsMode = HostsSettingsMode;
  constructor(public enhancedMode: EnhancedModeFacade) {
  }
}
