import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '~env';

@Injectable()
export class GoogleMapsApiLoaderService {
  constructor(private httpClient: HttpClient){}

  loadGoogleMapsApi() {
    return google.maps.importLibrary("marker");
    // return this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.mapping.apiKey}`, 'callback')
    //   .pipe(
    //     map(() => true),
    //     catchError(() => of(false)),
    //   );
  }
}
