import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { cloudSettingsLoaded } from '~features/app/app.actions';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { migratedRegistration } from '~features/registration/registration.selectors';
import { SettingsFacade } from '~features/settings/settings.facade';
import { initialSettingsState, migratedUserSettings } from '~features/settings/settings.state';
import { State } from '~features/state';
import { neverCompletes } from '../../util/rxjs-util';

const TAGS = ['Effects', 'Cloud Settings Init'];

@Injectable()
export class CloudSettingsInitEffects {
  constructor(
    private actions$: Actions,
    private log: LogService,
    private registrationFacade: RegistrationFacade,
    private settingsFacade: SettingsFacade,
    private store: Store<State>
  ) {
  }

  migrateOrRestoreCloudSettings$ = createEffect(
    () => this.actions$.pipe(
      ofType(cloudSettingsLoaded),
      withLatestFrom(
        this.store.select(migratedRegistration),
        this.store.select(migratedUserSettings)
      ),
      tap(([{settings}]) => this.log.info(TAGS, 'Cloud settings loaded! Current settings are as follows:', settings)),
      tap(([, legacyRegistration]) =>
        !!legacyRegistration
          ? this.log.info([...TAGS, 'Migration'], 'Migrated registration found! Overriding any existing cloud registration.',
          legacyRegistration)
          : false
      ),
      tap(([, , legacyUserSettings]) =>
        !!legacyUserSettings
          ? this.log.info([...TAGS, 'Migration'], 'Migrated settings found! Overriding any existing cloud settings.',
          legacyUserSettings)
          : false
      ),
      map(([{settings}, legacyRegistration, legacyUserSettings]) => {
        const newSettings = {
          ...settings,
          registration: legacyRegistration ||
            ((settings.registration && !!Object.keys(settings.registration).length)
                ? settings.registration
                : undefined
            ),
          user: legacyUserSettings ||
            ((settings.user && !!Object.keys(settings.user).length)
                ? settings.user
                : undefined
            ),
          admin: settings.admin || initialSettingsState.admin
        };
        return newSettings;
      }),
      tap(settings => this.registrationFacade.restoreRegistration(settings.registration)),
      tap(settings => this.settingsFacade.setAdminSettings(settings.admin)),
      tap(settings => this.settingsFacade.setUserSettings(settings.user)),
      tap({
        error: err => this.log.error([...TAGS, 'Migration'], 'Error occurred migrating legacy data: ', err),
        complete: () => this.log.warn([...TAGS, 'Migration'], 'Unexpected completion!')
      }),
      neverCompletes()
    ),
    {dispatch: false}
  );
}
