import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EnhancedDevice } from '~features/enhanced-mode/models';


@Component({
  selector: 'wic-enhanced-mode-registration-modal',
  templateUrl: './enhanced-mode-registration-modal.component.html',
  styleUrls: ['./enhanced-mode-registration-modal.component.scss'],
})
export class EnhancedModeRegistrationModalComponent {
  @Input() registration: EnhancedDevice;

  constructor(public modal: ModalController) {
  }
}
