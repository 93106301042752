import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Observable, combineLatest, map } from "rxjs";
import { Vendor } from "~features/vendors/models";
import { VendorFacade } from "~features/vendors/vendor.facade";

@Component({
  selector: 'wic-check-vendor-modal',
  templateUrl: './check-vendor-modal.component.html',
  styleUrls: ['./check-vendor-modal.component.scss'],
})
export class CheckVendorModalComponent implements OnInit {
  
  get isLoading$(): Observable<boolean> {
    return combineLatest([
      this.vendors.total$,
      this.vendors.error$,
      this.vendors.isLoading$
    ]).pipe(
      map(([count, error, isLoading]) => (!count && !error) || isLoading)
    );
  }

  constructor(
    private modal: ModalController,
    public vendors: VendorFacade,
  ) {}

  ngOnInit(): void {
    this.vendors.loadIfNeeded();
  }

  async no() {
    return this.modal.dismiss(null, 'no');
  }

  async yes(vendor: Vendor) {
    return this.modal.dismiss(vendor, 'yes');
  }
}