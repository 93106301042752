import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wic-whoops',
  templateUrl: './whoops.component.html',
  styleUrls: ['./whoops.component.scss'],
})
export class WhoopsComponent {
  @Input() message: string;
  @Output() refreshed = new EventEmitter();

  refreshCountdown = 0;

  constructor() { }

  refresh() {
    if (this.refreshCountdown < 1) {
      this.refreshed.emit();
      this.refreshCountdown = 30;
      const handle = setInterval(() => {
        this.refreshCountdown = this.refreshCountdown - 1;
        if (this.refreshCountdown < 1) {
          clearInterval(handle);
        }
      }, 1000);
    }
  }
}
