import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CalculatorEntry } from '~features/calculators/models';

import {
  CalculatorBenefitBalanceModalComponent,
} from '../../components/calculator-benefit-balance-modal/calculator-benefit-balance-modal.component';
import {
  EditCalculatorEntryModalComponent,
} from '../../components/edit-calculator-entry-modal/edit-calculator-entry-modal.component';
import { CalculatorBalance } from './models/calculator-balance';

@Injectable()
export class CalculatorsService {
  constructor(private modals: ModalController) {
  }

  async showEditCalculatorEntry(
    item: CalculatorEntry, remainingBalance: number, isAdding = true
  ): Promise<CalculatorEntry> {
    const modal = await this.modals.create({
      component: EditCalculatorEntryModalComponent,
      id: 'edit-calc-entry-modal',
      componentProps: {
        item,
        remainingBalance,
        isAdding
      }
    });

    await modal.present();
    const result = await modal.onDidDismiss();
    return result.role === 'ok' ? result.data : null;
  }

  async showEditCalculatorBalance(
    balance: CalculatorBalance,
    color: string
  ): Promise<CalculatorBalance> {
    const modal = await this.modals.create({
      component: CalculatorBenefitBalanceModalComponent,
      id: 'calc-benefit-balance-modal',
      componentProps: {
        balance,
        color
      }
    });

    await modal.present();
    const result = await modal.onDidDismiss();
    return result.role === 'ok' ? result.data : null;
  }
}
