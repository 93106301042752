import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createAction, createReducer, createSelector, props } from '@ngrx/store';
import { LogService, StaticLog } from '~core/services/log.service';
import { currentRegistration } from '~features/registration/registration.selectors';
import { EnvironmentService } from '../environment.service';
import { Registration } from '../registration/models';
import { HomeButton, MenuButton } from './models';
import { buildStandardButtons, mergeMenuButtons, sortAndFilterMenu } from './util';

const TAGS = ['State', 'Menu'];

export const menuReset = createAction(
  '[Home] Menu reset'
);

export const menuResetComplete = createAction(
  '[Home] Menu reset complete'
);

export const homePageLoading = createAction(
  '[Home] Loading'
);

export const homePageReloading = createAction(
  '[Home] Re-Loading'
);

export const homePageReloadCompleting = createAction(
  '[Home] Re-load Completing'
);

export const homePageReloadCompleted = createAction(
  '[Home] Re-load Completed'
);

export const buttonActivationFailed = createAction(
  '[Home] Button activation failed',
  props<{ error: any }>()
);

export const dynamicButtonActivated = createAction(
  '[Home] Dynamic Button Activated',
  props<{ button: HomeButton }>()
);

export const shortCodeButtonActivated = createAction(
  '[Home] Page Link Button Activated (wicshopper_)',
  props<{ button: HomeButton }>()
);

export const protocolButtonActivated = createAction(
  '[Home] Protocol Button Activated (wic://)',
  props<{ button: HomeButton }>()
);

export const httpsButtonActivated = createAction(
  '[Home] Protocol Button Activated (^)',
  props<{ button: HomeButton }>()
);

export const functionProtocolButtonActivated = createAction(
  '[Home] Function Protocol Button Activated (wic$://)',
  props<{ button: HomeButton }>()
);

export const unknownButtonActivated = createAction(
  '[Home] Unknown button activated'
);

export const navigateForward = createAction(
  '[Home] Navigate forward to route',
  props<{ route: string }>()
);

export const openHttpsLink = createAction(
  '[Home] Navigate to https route',
  props<{ route: string }>()
);

export const webButtonActivated = createAction(
  '[Home] Web Button Activated',
  props<{ button: HomeButton }>()
);

export const predefinedButtonActivated = createAction(
  '[Home] Pre-Defined App Button Activated',
  props<{ button: HomeButton }>()
);

export const scanButtonActivated = createAction(
  '[Home] Scan Barcode Button Activated'
);

export const upcButtonActivated = createAction(
  '[Home] Enter UPC Code Button Activated'
);

export const appointmentsButtonActivated = createAction(
  '[Home] Appointments Button Activated'
);

export const benefitsButtonActivated = createAction(
  '[Home] Benefits Button Activated'
);

export const receiptsButtonActivated = createAction(
  '[Home] Receipts Button Activated'
);

export const cardsButtonActivated = createAction(
  '[Home] Cards Button Activated'
);

export const vendorsButtonActivated = createAction(
  '[Home] Vendors Button Activated'
);

export const officesButtonActivated = createAction(
  '[Home] Offices Button Activated'
);

export const navigateHome = createAction(
  '[Home] Navigate back home'
);


export type IMenuButtonState = IEntityState<MenuButton>;

export const {selectors: {selectAll}, initialState: initialMenuButtonState, facade: HomeFacadeBase} = buildState(MenuButton);

const reducer = createReducer(
  initialMenuButtonState
);

export function menuButtonReducer(state = initialMenuButtonState, action: Action): IMenuButtonState {
  return reducer(state, action);
}


export const mapToFullMenu = (customButtons: MenuButton[], registration: Registration) => {
  try {
    const standardButtons = buildStandardButtons(registration.authority, registration.cards);
    StaticLog.log.debug(TAGS, `Standard buttons: `, standardButtons);
    const trueCustomButtons = customButtons.filter(custom => !standardButtons.find(standard => standard.linkUrl === custom.url));
    StaticLog.log.debug(TAGS, `True Custom buttons: `, trueCustomButtons);
    const adjustedStandardButtons = standardButtons
      .map(standard => [standard, customButtons.find(custom => custom.url === standard.linkUrl) || {}])
      .map(([standard, custom]: [HomeButton, MenuButton]) => ({
        ...standard,
        show: custom.isActive == null ? !!standard.show : custom.isActive && (standard.show == null ? true : standard.show),
        order: custom.order == null ? standard.order : custom.order,
        label: custom.text == null ? standard.label : custom.text,
        linkId: custom.linkId,
      }));

    StaticLog.log.debug(TAGS, `Adjusted Standard buttons: `, adjustedStandardButtons);

    const allButtons = mergeMenuButtons(EnvironmentService.azureGateway, adjustedStandardButtons, trueCustomButtons);

    StaticLog.log.debug(TAGS, `All buttons: `, allButtons);

    const sortedFiltered = sortAndFilterMenu(allButtons);

    StaticLog.log.debug(TAGS, `Sorted and Filtered buttons: `, sortedFiltered);

    return sortedFiltered;
  } catch (err) {
    StaticLog.log.error(TAGS, `Error building home menu!`, err);
    return [];
  }
};

export const homeMenu = createSelector(
  selectAll,
  currentRegistration,
  mapToFullMenu
);
