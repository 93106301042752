import { Component, OnDestroy } from '@angular/core';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { RegistrationFacade } from '~features/registration/registration.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';

@Component({
  selector: 'wic-future-benefits',
  templateUrl: './future-benefits.page.html',
  styleUrls: ['./future-benefits.page.scss'],
})
export class FutureBenefitsPage implements OnDestroy {
  constructor(
    public registration: RegistrationFacade,
    public benefits: BenefitsFacade,
    public enhancedMode: EnhancedModeFacade,
    private analytics: AnalyticsFacade
  ) {
  }

  ionViewDidLeave() {
    this.benefits.clearFuture();
  }

  ngOnDestroy(): void {
    this.analytics.pageView('future-benefits');
    this.benefits.clearFuture();
  }
}
