<ion-card>
  <ion-card-header>
    <ion-card-title>{{'whoops.title' | transloco}}</ion-card-title>
    <ion-card-subtitle>{{'whoops.subtitle' | transloco}}</ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          {{message | transloco}}
        </ion-col>
      </ion-row>
      <ion-row class="pt-20">
        <ion-col>
          <ion-button expand="block" (click)="refresh()" [disabled]="!!refreshCountdown" data-cy="whoops-retry-button">
            <ion-icon name="refresh" class="pr-5"></ion-icon> {{'whoops.retryLabel' | transloco}} <span *ngIf="refreshCountdown" class="pl-5">({{refreshCountdown | translocoDecimal}})</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
