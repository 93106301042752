import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createAction, createFeatureSelector, createReducer, on, props } from '@ngrx/store';
import { LocationSearchCriteria } from '../../models/location-search-criteria';

import { Vendor, VendorFavorite } from './models';
import { lastLoadedAt } from '../benefits/benefits.selectors';
import { Position } from '@capacitor/geolocation';

export const {
  initialState,
  facade: VendorFacadeBase,
  actions: {
    loadAll: loadAllVendors,
    loadAllSuccess: loadAllVendorsSuccess,
    loadAllIfNecessary: loadAllVendorsIfNecessary,
    clear: clearVendors
  },
  selectors: {
    selectAll: allVendors,
    selectEntities: vendorEntities,
    selectLoadedAt: vendorsLoadedAt,
  }
} = buildState(Vendor, {
  searchCriteria: {
    radius: 5,
    anywhere: false,
    onlyFavorites: false,
    highlightTopPick: true,
    groupCodeFilter: 0
  } as LocationSearchCriteria,
  filterCriteria: null as string,
  favorites: [] as VendorFavorite[],
} as IVendorState);

export const vendorsViewed = createAction(
  '[Vendors] Viewed'
);

export const configureVendorSearch = createAction(
  '[Vendors] Configure search'
);

export const applyVendorSearch = createAction(
  '[Vendor Search] Apply search criteria',
  props<{ criteria: LocationSearchCriteria }>()
);

export const vendorDetailViewed = createAction(
  '[Vendors] View detail',
  props<{ vendor: Vendor; favorites: VendorFavorite[] }>()
);

export const vendorFavoriteToggled = createAction(
  '[Vendors] Favorite Toggled',
  props<{ vendor: Vendor; favorites: VendorFavorite[] }>()
);

export const vendorFavorited = createAction(
  '[Vendors] Favorited',
  props<{ vendor: Vendor }>()
);

export const vendorUnfavorited = createAction(
  '[Vendors] Unfavorited',
  props<{ vendor: Vendor }>()
);

export const setVendorsFilter = createAction(
  '[Vendors] Set filter',
  props<{ criteria: string }>()
);

export const vendorsLoaded = createAction(
  '[Vendors] Loaded'
);

export const vendorLoadFailed = createAction(
  '[Vendors] Load failed',
  props<{ error?: any }>()
);

export const driveToVendor = createAction(
  '[Vendors] Drive to location',
  props<{vendor: Vendor; latitude: number; longitude: number}>()
);

export const loadIfNeeded = createAction(
  '[Vendors] Load If Necessary Init',
  props<{ force: boolean }>()
);

export const requestLocationPermission = createAction(
  '[Vendors] Request Location Permission'
);

export const loadVendors = createAction(
  '[Vendors] Load Vendors'
);

export const vendorUserLocationChanged = createAction(
  '[Vendors] User Location Changed',
  props<{ location: Position }>()
);

export const returnToHome = createAction(
  '[Vendors] Return to Home'
);

export const resetVendors = createAction(
  '[Vendors] Reset Vendors'
);

export interface IVendorState extends IEntityState<Vendor> {
  searchCriteria: LocationSearchCriteria;
  pendingCriteria?: LocationSearchCriteria;
  filterCriteria?: string;
  favorites: VendorFavorite[];
  error?: any;
  location?: Position
}

export const createFavorite = (vendor: Vendor): VendorFavorite => ({
  entryId: vendor.entryId,
  dateAdded: new Date().toISOString()
});

export const isMatchingVendor = (a: VendorFavorite) => (b: VendorFavorite) =>
  a.entryId === b.entryId;

export const isNotMatchingVendor = (a: VendorFavorite) => (b: VendorFavorite) =>
  !isMatchingVendor(a)(b);

export const setSearchCriteriaInState = (state, {criteria}) => ({
  ...state,
  searchCriteria: criteria
});

export const setFilterInState = (state, {criteria}) => ({
  ...state,
  filterCriteria: criteria
});

export const addVendorFavoriteToState = (state, {vendor}) => ({
  ...state,
  favorites: [
    createFavorite(vendor),
    ...state.favorites.filter(isNotMatchingVendor(vendor))
  ]
});

export const removeVendorFavoriteFromState = (state, {vendor}) => ({
  ...state,
  favorites: state.favorites.filter(isNotMatchingVendor(vendor))
});

export const clearError = (state) => ({
  ...state,
  error: null
});

export const trackVendorError = (state, {error}: { error?: any }) => ({
  ...state,
  error: error || true
});

export const clearVendorState = (state) => ({
  ...state,
  searchCriteria: {
    radius: 5,
    anywhere: false,
    onlyFavorites: false,
    highlightTopPick: true,
    groupCodeFilter: 0
  },
  filterCriteria: null,
  favorites: []
});

const reduce = createReducer(
  initialState,
  on(applyVendorSearch, setSearchCriteriaInState),
  on(setVendorsFilter, setFilterInState),
  on(vendorFavorited, addVendorFavoriteToState),
  on(vendorUnfavorited, removeVendorFavoriteFromState),
  on(vendorsLoaded, clearError),
  on(vendorLoadFailed, trackVendorError),
  on(clearVendors, clearVendorState),
  on(vendorUserLocationChanged, (state, {location}) => ({...state, location})),
);

export function vendorReducer(state = initialState, action: Action): IVendorState {
  return reduce(state, action);
}

export const getVendorState = createFeatureSelector<IVendorState>('vendor');
