import { createAction, props } from '@ngrx/store';

import { Authority } from '../authorities/models';
import { Card } from '../registration/models';

export const appPreInitialized = createAction('[Application] Pre-Initialized');
export const appInitialized = createAction('[Application] Initialized');
export const appResumed = createAction('[Application] Resumed');

export const googleMapsApiLoaded = createAction('[App] (GoogleMaps) API Loaded');

export const initializationCompleted = createAction(
  '[Application] Initialization completed',
  props<{ authority: Authority; pendingCard: Card }>()
);

export const cloudSettingsLoaded = createAction(
  '[Application] Cloud Settings: Loaded',
  props<{ settings: any }>()
);

export const cloudSettingsError = createAction(
  '[Application] Cloud Settings: Load Error',
  props<{ error: Error | any }>()
);

export const cloudSettingsResetComplete = createAction(
  '[Application] Cloud Settings: Reset Complete',
  props<{ wasSuccessful: boolean }>()
);

export const networkStatusChanged = createAction(
  '[Network] Status changed',
  props<{ status: boolean }>()
);

export const openNetworkSettings = createAction(
  '[Network] Open Settings'
);

export const webLinkOpened = createAction(
  '[Browser] Web Link Opened',
  props<{ linkUrl: string }>()
);

export const softReset = createAction(
  '[Application] Soft reset: Initiated'
);

export const hardReset = createAction(
  '[Application] Hard reset: Initiated'
);

export const resetComplete = createAction(
  '[Application] Reset: Complete'
);
