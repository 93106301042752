import { createAction, props } from '@ngrx/store';

export const driveToLocation = createAction(
  '[Directions] Drive to location',
  props<{ latitude: number; longitude: number }>()
);

export const driveToAddress = createAction(
  '[Directions] Drive to address',
  props<{ address: string }>()
);

export const viewWebSite = createAction(
  '[Browser] View web site',
  props<{ webAddress: string }>()
);

export const launchWicSmartApp = createAction(
  '[WICSmart] Launch App'
);

export const wicSmartLaunched = createAction(
  '[WICSmart] App Launched'
);

export const wicSmartLaunchError = createAction(
  '[WICSmart] Launch Error',
  props<{ error: any }>()
);

export const openExternalBrowser = createAction(
  '[Browser] Open Browser',
  props<{ url: string }>()
);
