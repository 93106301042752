import { NgModule } from '@angular/core';

import { ReceiptsFacade } from './receipts.facade';

@NgModule({
  providers: [
    ReceiptsFacade,
  ]
})
export class ReceiptsModule {
}
