<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false)">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ 'notification-investigator-modal.title' | transloco }}
    </ion-title>
  </ion-toolbar>
  <wic-card-select-toolbar
    [cards]="registration.currentCards$ | async"
    [currentCard]="registration.currentCard$ | async"
    (selected)="registration.selectCard($event)"
  ></wic-card-select-toolbar>
</ion-header>

<ion-content fullscreen>
  <ng-container *ngIf="cardNotifications$ | async as card">
    <wic-notifications-detail
      title="Benefit Notifications"
      [notifications]="card.benefitNotifications"
    ></wic-notifications-detail>

    <wic-notifications-detail
      title="Appointment Notifications"
      [notifications]="card.appointmentNotifications"
    ></wic-notifications-detail>
  </ng-container>
</ion-content>
