<ion-card #card *ngIf="!!appointment" data-cy="appointment-details">
  <!--  Use tabs to separate by card numbers. -->

  <ion-card-header translucent mode="ios">
    <ion-card-title style="display: flex; justify-content: space-between; padding-bottom: 8px;">
      <span>
        {{ appointment.appointmentTime | date: 'MMM dd' }}
      </span>
      <span>
        {{ appointment.appointmentTime | date: 'h:mm a' }}
      </span>
    </ion-card-title>
    <ion-card-subtitle style="font-size: 1rem;">{{ appointment.locationName | transloco }}</ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <ion-item *ngIf="appointment.aptType" lines="none">
      <ion-label>
        <h2>
          <strong>{{ 'appointments.appointmentTypeLabel' | transloco }}</strong>
        </h2>
        <h2>{{ appointment.aptType }}</h2>
      </ion-label>
    </ion-item>
    <ion-item lines="none" button detail="false" (click)="directions.emit(appointment)">
      <ion-label>
        <h2>
          <strong>{{ appointment.address1 }}</strong>
        </h2>
        <h2 *ngIf="!!appointment.address2">{{ appointment.address2 }}</h2>
        <h2>{{ appointment.city | titlecase }}, {{ appointment.state }} {{ appointment.zip }}</h2>
      </ion-label>
      <ion-icon slot="end" color="appointments" icon="navigate"></ion-icon>
    </ion-item>

    <ion-item *ngIf="!!appointment.phone" lines="none" button detail="false" href="tel:{{ appointment.phone }}">
      <ion-label>{{ appointment.phone }}</ion-label>
      <ion-icon slot="end" color="appointments" icon="call"></ion-icon>
    </ion-item>
    <ion-item *ngIf="!!appointment.email" lines="none" button detail="false" href="mailto:{{ appointment.email }}">
      <ion-label class="ion-text-wrap appt-email">{{ appointment.email | lowercase }}</ion-label>
      <ion-icon slot="end" color="appointments" icon="mail"></ion-icon>
    </ion-item>

    <ion-accordion-group *ngIf="!!appointment.apptRequirements">
      <ion-accordion value="details">
        <ion-item slot="header">
          <ion-label>Additional Details</ion-label>
        </ion-item>
        <ion-list slot="content">
          <ion-item lines="none" class="ion-text-wrap">
            <markdown [data]="appointment.apptRequirements"></markdown>
          </ion-item>
        </ion-list>
      </ion-accordion>
    </ion-accordion-group>
  </ion-card-content>
</ion-card>
