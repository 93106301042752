import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, from, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, take, tap, timeoutWith } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { match } from '~jpma-wicshopper-imports-mono/utils/browser';
import { appInitialized, appPreInitialized } from '~features/app/app.actions';
import { allAuthorities, preloadAuthorities } from '~features/authorities/authorities.state';
import { Authority } from '~features/authorities/models';
import { Card } from '~features/registration/models';
import { registrationMigrated } from '~features/registration/registration.actions';
import { initialSettingsState, userSettingsMigrated } from '~features/settings/settings.state';
import { State } from '~features/state';
import { KeyboardService } from '~core/services/keyboard.service';
import { onNativePlatformOnly } from '../../util/rxjs-util';

const TAGS = ['Effects', 'Pre-Init'];

const LEGACY_CACHE_PREFIX = 'wic_cache_';

@Injectable()
export class PreInitializationEffects {
  constructor(
    private actions$: Actions,
    private log: LogService,
    private storage: Storage,
    private keyboard: KeyboardService,
    private store: Store<State>
  ) {
  }

  checkStorageDriver$ = createEffect(
    () => this.actions$.pipe(
      ofType(appPreInitialized),
      tap(() => this.log.info(TAGS, `The Ionic Storage driver after cordova initialization is: ${this.storage.driver}`))
    ),
    {dispatch: false}
  );

  logLegacyStorageKeys$ = createEffect(
    () => this.actions$.pipe(
      ofType(appPreInitialized),
      tap(() => this.log.warn([...TAGS, 'Migration'], 'Finding legacy storage data..:')),
      switchMap(() => from(this.storage.keys())),
      tap(keys => this.log.warn([...TAGS, 'Migration'], 'Current keys in storage:', keys)),
      map(keys => keys.filter(key => key.startsWith(LEGACY_CACHE_PREFIX))),
      tap(keys => this.log.warn([...TAGS, 'Migration'], 'Legacy keys storage:', keys)),
      switchMap((keys: string[]) =>
        from(Promise.all(keys.map(
          key => this.storage.get(key)
        ))).pipe(
          tap(value => this.log.trace(TAGS, 'Value from legacy storage:', JSON.stringify(value))
          )
        )
      ),
      catchError(() => EMPTY)
    ),
    {dispatch: false}
  );

  preloadAuthorities$ = createEffect(
    () => this.actions$.pipe(
      ofType(appPreInitialized),
      tap(() => this.log.debug(TAGS, 'Triggering authority preload...')),
      map(() => preloadAuthorities())
    )
  );

  // Migrated to Capacitor Config. Commented for testing. Can be removed if
  // setKeyboardResizeMethod$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(appPreInitialized),
  //     onNativePlatformOnly(),
  //     tap(() => this.log.debug(TAGS, 'Setting Keyboard resize method...')),
  //     switchMap(() => this.keyboard.setResizeMode())
  //   ),
  //   {dispatch: false}
  // );

  restoreLegacyData$ = createEffect(
    () => this.actions$.pipe(
      ofType(appPreInitialized),
      // switchMap(() => from(Promise.all([
      //   this.storage.set(LEGACY_CACHE_PREFIX + 'current_authority', {
      //     key: LEGACY_CACHE_PREFIX + 'current_authority',
      //     data: {
      //       id: 15,
      //       name: 'COLORADO',
      //       shortName: 'COLORADO WIC',
      //       identityName: 'colorado',
      //       type: 2,
      //       flags: 1610620996,
      //       bannerPlaylistId: 43,
      //       isActive: true,
      //       isProvider: true,
      //       isOfflineOrVerifyOnly: false,
      //       showScan: true,
      //       allowScan: true,
      //       itemLkupReqLoc: false,
      //       benefitLkupReqLoc: true,
      //       showOffices: true,
      //       showVendors: true,
      //       benefitsHaveNoExpiration: false,
      //       hasApptReminders: true,
      //       hideFutureBenefits: false,
      //       showLastShoppingTrip: false,
      //       showLastImport: false,
      //       useHHIDForAppts: false,
      //       disableCVVCalc: false,
      //       disableCerealCalc: false,
      //       disableGrainCalc: true,
      //       hasLocations: true,
      //       allowCaptureBenefits: false,
      //       binCodes: ['610188'],
      //       integration: 'cdp'
      //     }
      //   }),
      //   this.storage.set(LEGACY_CACHE_PREFIX + 'current_card', {
      //     key: LEGACY_CACHE_PREFIX + 'current_card',
      //     data: {
      //       cardNumber: '6101889999999999'
      //     }
      //   }),
      //   this.storage.set(LEGACY_CACHE_PREFIX + 'cards', {
      //     key: LEGACY_CACHE_PREFIX + 'cards',
      //     data: [{
      //       cardNumber: '6101889999999999'
      //     }]
      //   }),
      //   this.storage.set(LEGACY_CACHE_PREFIX + 'settings', {
      //     key: LEGACY_CACHE_PREFIX + 'settings',
      //     data: {
      //       admin: {},
      //       notifications: {
      //         benefit: {
      //           enable: true,
      //           daysBefore: 6,
      //           hour: 11,
      //           minute: 23
      //         },
      //         appointment: {
      //           enable: false,
      //           daysBefore: 3,
      //           hour: 8,
      //           minute: 18
      //         }
      //       },
      //       language: 'es'
      //     }
      //   })
      // ])).pipe(
      // )),
      tap(() => this.log.debug([...TAGS, 'Migration'], 'Checking for legacy configuration to migrate...')),
      switchMap(() => Promise.all([
        this.storage.get(LEGACY_CACHE_PREFIX + 'current_authority'),
        this.storage.get(LEGACY_CACHE_PREFIX + 'current_card'),
        this.storage.get(LEGACY_CACHE_PREFIX + 'cards'),
        this.storage.get(LEGACY_CACHE_PREFIX + 'settings'),
      ])),
      map(([wrapper1, wrapper2, wrapper3, wrapper4]): [
        Authority, Card & { houseHoldId?: string }, Array<Card & { houseHoldId?: string }>, any
      ] => [
        (wrapper1 || {}).data,
        (wrapper2 || {}).data,
        (wrapper3 || {}).data,
        (wrapper4 || {}).data
      ]),
      tap(([authority, card, cards, settings]) =>
        this.log.trace([...TAGS, 'Migration'], 'Legacy data identified:', authority, card, cards, settings)
      ),
      map(([authority, card, cards, settings]) => ({
        authority,
        card: card || (cards && cards.length ? cards[0] : null),
        cards,
        settings
      })),
      map(({authority, card, cards, settings}) => ({
        registration: !authority ? undefined : {
          authority,
          currentCard: card ? {
            nickname: card.nickname,
            cardNumber: card.cardNumber,
            householdId: card.houseHoldId
          } : null,
          cards: cards ? cards.map(altCard => ({
            nickname: altCard.nickname,
            cardNumber: altCard.cardNumber,
            householdId: altCard.houseHoldId
          })) : [],
        },
        user: !settings ? undefined : {
          lang: settings ? settings.language || initialSettingsState.user.lang : initialSettingsState.user.lang,
          benefits: settings && settings.notifications && settings.notifications.benefit
            ? {
              allowAlerts: settings.notifications.benefit.enable,
              daysBefore: settings.notifications.benefit.daysBefore,
              hour: settings.notifications.benefit.hour,
              minute: settings.notifications.benefit.minute
            }
            : initialSettingsState.user.benefits,
          appointments: settings && settings.notifications && settings.notifications.appointment
            ? {
              allowAlerts: settings.notifications.appointment.enable,
              daysBefore: settings.notifications.appointment.daysBefore,
              hour: settings.notifications.appointment.hour,
              minute: settings.notifications.appointment.minute
            }
            : initialSettingsState.user.appointments,
        }
      })),
      tap(
        legacy => (legacy.registration || legacy.user)
          ? this.log.info([...TAGS, 'Migration'], 'Found legacy data to migrate...', legacy.registration, legacy.user)
          : this.log.info([...TAGS, 'Migration'], 'Did not find any legacy data to migrate.'),
        err => this.log.error([...TAGS, 'Migration'], 'Error identifying legacy data to migrate', err)
      ),
      switchMap(legacy =>
        match(
          () => !!legacy.registration,
          found => [true, this.store.select(allAuthorities).pipe(first(authorities => !!authorities && !!authorities.length))],
          found => [false, of(null)]
        ).pipe(
          tap(authorities => this.log.debug([...TAGS, 'Migration'], `Found ${(authorities || []).length} latest authorities...`)
          ),
          switchMap(authorities => [
            registrationMigrated({
              registration: !!legacy.registration ? {
                ...legacy.registration,
                authority: legacy.registration.authority
                  ? (authorities || []).find(authority => authority.id === legacy.registration.authority.id)
                  : legacy.registration.authority
              } : legacy.registration
            }),
            userSettingsMigrated({settings: legacy.user})
          ]),
          tap({error: err => this.log.error([...TAGS, 'Migration'], 'Error normalizing legacy data to migrate', err)}),
        )
      ),
    )
  );

  initializeApp$ = createEffect(
    () => forkJoin([
      this.actions$.pipe(
        timeoutWith(5000, of(registrationMigrated({registration: null}))),
        ofType(registrationMigrated),
        take(1),
        tap(result => result.registration === null
          ? this.log.warn([...TAGS, 'Migration'], 'Migration of registration failed to complete in 10 seconds')
          : !!result.registration
            ? this.log.warn([...TAGS, 'Migration'], 'A legacy registration was found. Migrating registration!',
              JSON.stringify(result.registration)
            )
            : this.log.info([...TAGS, 'Migration'], 'No legacy registration was found.')
        )
      ),
      this.actions$.pipe(
        timeoutWith(5000, of(userSettingsMigrated({settings: null}))),
        ofType(userSettingsMigrated),
        take(1),
        tap(result => result.settings === null
          ? this.log.warn([...TAGS, 'Migration'], 'Migration of user settings failed to complete in 10 seconds')
          : !!result.settings
            ? this.log.warn([...TAGS, 'Migration'], 'Legacy user settings were found. Migrating user settings!',
              JSON.stringify(result.settings)
            )
            : this.log.warn([...TAGS, 'Migration'], 'No legacy user settings were found.')
        )
      )
    ]).pipe(
      map(() => appInitialized())
    )
  );

  removeLegacyCachedData$ = createEffect(
    () => this.actions$.pipe(
      ofType(appInitialized),
      switchMap(() => this.storage.keys()),
      map(keys => keys.filter(key => key.startsWith(LEGACY_CACHE_PREFIX))),
      filter(keys => !!keys && !!keys.length),
      tap(keys => this.log.warn([...TAGS, 'Migration'], 'Removing old cached data from legacy v4 app cache...', keys)),
      switchMap(keys => Promise.all(keys.map(
        key => this.storage.remove(key)
      ))),
      tap({
        next: () => this.log.info([...TAGS, 'Migration'], 'Old cached data from legacy v4 app cache removed.'),
        error: err => this.log.error([...TAGS, 'Migration'], 'Error removing old cached v4 app data:.', err),
      }),
      catchError(() => EMPTY)
    ),
    {dispatch: false}
  );
}
