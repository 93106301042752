import { NgModule } from '@angular/core';
import { OfficesFacade } from '~features/offices/offices.facade';


@NgModule({
  providers: [
    OfficesFacade
  ]
})
export class OfficesModule {
}
