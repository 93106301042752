<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false)">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{'debugging-modal.title' | transloco}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="enhancedMode.showDebugSettings()">
        <ion-icon name="settings"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <wic-log-viewer></wic-log-viewer>
</ion-content>
