import { NgModule } from '@angular/core';
import { EnvironmentService } from '~features/environment.service';

@NgModule({
  providers: [
    EnvironmentService
  ]
})
export class EnvModule {
}
