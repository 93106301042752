import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LanguageSelectModalComponent } from '../../components/language-select-modal/language-select-modal.component';

@Injectable()
export class SettingsUIService {
  constructor(private modals: ModalController) {}

  async showSelectInitialLanguageModal() {
    const modal = await this.modals.create({
      component: LanguageSelectModalComponent,
    });

    await modal.present();

    return modal.onDidDismiss();
  }
}
