import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import IMask from 'imask';
import { Authority } from '~features/authorities/models';
import { Card } from '~features/registration/models';
import { BBValidators } from '~validators';

@Component({
  selector: 'wic-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit, OnChanges {
  @Input() authority: Authority;
  @Input() pendingCard: Card;
  @Output() cardChanged = new EventEmitter<Card>();
  @Output() focused = new EventEmitter<Event>();

  mask = '0000 0000 0000 0000';

  LEN_CARD_DIGITS = 16;
  MIN_HHID_DIGITS = 1;
  MAX_HHID_DIGITS = 10;

  form: UntypedFormGroup;
  submitted = false;

  constructor(private builder: UntypedFormBuilder) {}

  readonly cardMask: MaskitoOptions = {
    mask: [
      ...Array(4).fill(/\d/),
      ' ',
      ...Array(4).fill(/\d/),
      ' ',
      ...Array(4).fill(/\d/),
      ' ',
      ...Array(4).fill(/\d/),
    ],
  };

  readonly maskPredicate: MaskitoElementPredicate = async (el) => (el as HTMLIonInputElement).getInputElement();

  ngOnInit() {
    const requireHHID = !!this.authority && this.authority.integration === 'wem';

    this.form = this.builder.group({
      cardNumber: [
        // this.authority?.binCodes[0],
        '',
        [
          BBValidators.compareEqual('cardNumberConfirm', true),
          Validators.required,
          BBValidators.exactLength(16, this.mask),
        ],
      ],
      cardNumberConfirm: [
        // this.authority?.binCodes[0],
        '',
        [BBValidators.compareEqual('cardNumber', false), Validators.required, BBValidators.exactLength(16, this.mask)],
      ],
      householdId: [
        null,
        [
          BBValidators.compareEqual('householdIdConfirm', true),
          Validators.minLength(this.MIN_HHID_DIGITS),
          Validators.maxLength(this.MAX_HHID_DIGITS),
          requireHHID ? Validators.required : Validators.nullValidator,
        ],
      ],
      householdIdConfirm: [
        null,
        [
          BBValidators.compareEqual('householdId', false),
          Validators.minLength(this.MIN_HHID_DIGITS),
          Validators.maxLength(this.MAX_HHID_DIGITS),
          requireHHID ? Validators.required : Validators.nullValidator,
        ],
      ],
    });
    this.populateWithPending();
  }

  ngOnChanges(): void {
    this.populateWithPending();
  }

  populateWithPending() {
    if (this.pendingCard && this.form) {
      const maskResolver = IMask.createMask({ mask: this.mask });

      this.form.patchValue({
        cardNumber: maskResolver.resolve(this.pendingCard.cardNumber),
        cardNumberConfirm: maskResolver.resolve(this.pendingCard.cardNumber),
        householdId: this.pendingCard.householdId,
        householdIdConfirm: this.pendingCard.householdId,
      });
    }
  }

  isFieldValid(form: UntypedFormGroup, fieldName: string, errorName: string) {
    const control = form.controls[fieldName];
    const show = control.getError(errorName) && (!control.pristine || this.submitted);
    return show;
  }

  getCard(): Card | null {
    const maskResolver = IMask.createMask({ mask: this.mask });
    maskResolver.resolve(this.form.value.cardNumber);

    return this.form.valid
      ? {
          cardNumber: maskResolver.unmaskedValue,
          householdId: this.form.value.householdId,
        }
      : null;
  }

  getIonInput(ionInput: IonInput): any {
    return (elRef, dirRef) => ionInput.getInputElement();
  }
}
