import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { Subject } from 'rxjs';
import { LogService } from './log.service';

const TAGS = ['Service', 'Keyboard'];

@Injectable()
export class KeyboardService {
  // Emits the keyboard height once shown.
  keyboardDidShow$ = new Subject<number>();

  constructor(private log: LogService) {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardDidShow', ({ keyboardHeight }) => this.keyboardDidShow$.next(keyboardHeight));
    }
  }

  async setResizeMode() {
    if( Capacitor.isNativePlatform()) {
      return Keyboard.setResizeMode({ mode: KeyboardResize.Ionic });
    }
  }
}
