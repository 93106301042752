import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnrichedBenefitInfo } from '~features/benefits/models';
import { Registration } from '~features/registration/models';

@Component({
  selector: 'wic-benefit-header',
  templateUrl: './benefit-header.component.html',
  styleUrls: ['./benefit-header.component.scss'],
})
export class BenefitHeaderComponent {
  @Input() registration: Registration;
  @Input() benefitInfo: EnrichedBenefitInfo;
  @Input() pendingExpirationMode = false;
  @Input() isStale = false;
  @Output() refreshed = new EventEmitter();
}
