import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { APLMessage } from '~features/aplmessages/models';

import { SubCatConfig } from '~features/subcat-configs/models';
import { EnrichedBenefit } from '~features/benefits/models';
import { Authority } from '~features/authorities/models';

const CALC_CATEGORIES: Array<{ id: number; flag: string }> = [
  {id: 5, flag: 'disableCerealCalc'},
  {id: 16, flag: 'disableGrainCalc'},
  {id: 19, flag: 'disableCVVCalc'},
  {id: 97, flag: 'disableCVVCalc'},
];


@Component({
  selector: 'wic-benefit-item',
  templateUrl: './benefit-item.component.html',
  styleUrls: ['./benefit-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BenefitItemComponent {
  @Input() authority: Authority;
  @Input() aplMessage: APLMessage;
  @Input() benefit: EnrichedBenefit;
  @Input() config?: SubCatConfig;
  @Input() pendingExpirationMode = false;
  @Input() nonInteractive = false;
  @Input() isFuture = false;
  @Input() currentLanguage: string;
  @Output() viewed = new EventEmitter<EnrichedBenefit>();
  @Output() messageViewed = new EventEmitter<APLMessage>();
  @Output() linkOpened = new EventEmitter<string>();
  @Output() calcOpened = new EventEmitter<EnrichedBenefit>();

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  createIconUrl(dataUrl): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(dataUrl);
  }

  hasCalcs(benefit: EnrichedBenefit) {
    return CALC_CATEGORIES.some(({id, flag}) => benefit.categoryId === id && !this.authority[flag]);
  }

  isCalc(benefit: EnrichedBenefit) {
    return CALC_CATEGORIES.some(({id}) => benefit.categoryId === id);
  }
}
