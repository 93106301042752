import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '~env';

import { State } from '~features/state';
import { apiHost, cdnHost, azureGateway } from '~features/settings/settings.state';


const TAGS = ['Service', 'Environment'];
interface AzureGateway {
  baseUrl: string;
  subscriptionKey: string;
}
@Injectable()
export class EnvironmentService {
  static azureGateway: AzureGateway = environment.hosts.azureGateway;
  static apiHost: string = environment.hosts.api;
  static cdnHost: string = environment.hosts.cdn;

  private apiHost$$ = new BehaviorSubject(EnvironmentService.apiHost);
  private cdnHost$$ = new BehaviorSubject(EnvironmentService.cdnHost);
  private azureGateway$$ = new BehaviorSubject(EnvironmentService.azureGateway);

  get azureGateway$(): Observable<AzureGateway> {
    return this.azureGateway$$;
  }

  get azureGateway(): AzureGateway {
    return this.azureGateway$$.getValue();
  }

  get apiHost$(): Observable<string> {
    return this.apiHost$$;
  }

  get apiHost(): string {
    return this.apiHost$$.getValue();
  }

  get cdnHost$(): Observable<string> {
    return this.cdnHost$$;
  }

  get cdnHost(): string {
    return this.cdnHost$$.getValue();
  }

  get environment() {
    return environment;
  }

  constructor(store: Store<State>) {
    // Wrap underlying apiHost selector in a behavior subject to allow
    // easy and guaranteed async and sync access to current API root host
    store
      .pipe(
        select(apiHost),
        tap((url) => (EnvironmentService.apiHost = url))
      )
      .subscribe(this.apiHost$$);

    store
      .pipe(
        select(cdnHost),
        tap((url) => (EnvironmentService.cdnHost = url))
      )
      .subscribe(this.cdnHost$$);

    store
      .pipe(
        select(azureGateway),
        tap((az) => (EnvironmentService.azureGateway = az))
      )
      .subscribe(this.azureGateway$$);
  }
}
