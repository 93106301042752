import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { EnrichedBenefit } from '~features/benefits/models';
import { ShoppingList } from './shopping-list.entity';
import { addItem } from './shopping-lists.actions';

export interface ShoppingListsState extends IEntityState<ShoppingList> {
  currentBenefit: EnrichedBenefit;
}

export const {
  actions: {
    loadSuccess: loadShoppingListSuccess,
    updateSuccess: updateShoppingListSuccess,
    createSuccess: createShoppingListSuccess,
    deleteSuccess: deleteShoppingListSuccess,
    select: selectShoppingList,
    selected: shoppingListSelected,
  },
  selectors: {
    selectCurrentEntity: currentShoppingList,
    selectCurrentEntityKey: currentShoppingListKey,
    selectAll: allShoppingLists,
  },
  initialState: initialShoppingListsState,
  facade: ShoppingListsFacadeBase,
} = buildState(ShoppingList, {} as ShoppingListsState);

export const shoppingListsReducer = createReducer(
  initialShoppingListsState,
  on(addItem, (state, { benefit }) => ({ ...state, currentBenefit: benefit }))
);

export const SHOPPING_LISTS_FEATURE_KEY = 'shoppingList';

export const shoppingListsState = createFeatureSelector<ShoppingListsState>(
  SHOPPING_LISTS_FEATURE_KEY
);
