import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'wic-icbt-image-component',
  templateUrl: './icbt-image.component.html',
  styleUrls: ['./icbt-image.component.scss']
})

export class ICBTImageComponent {
  @Input() image: string;
  @Input() imageAlt: string;
  @Input() capturePlaceholder: string;
  @Input() captureButton: string;
  @Input() clearButton: string;

  @Output() clearImage = new EventEmitter<void>();
  @Output() captureImage = new EventEmitter<void>();
}
