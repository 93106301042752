<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <img src="assets/images/img_main.png" alt="{{'common.wicshopper' | transloco}}" height="50">
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <ion-icon icon="finger-print" size="large" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" #content>
  <ion-card>
    <wic-provider-card
        [provider]="registration.currentProvider$ | async">
    </wic-provider-card>
    <ion-card-header>
      <ion-card-subtitle>{{'registration.subTitle' | transloco}}</ion-card-subtitle>
      <ion-card-title>{{'registration.title' | transloco}}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <wic-registration-form #registrationForm
          [authority]="registration.currentProvider$ | async"
          [pendingCard]="registration.pendingCard$ | async"
          (focused)="scrollIntoView($event)">
      </wic-registration-form>
    </ion-card-content>
  </ion-card>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>

<ion-footer [translucent]="true" mode="ios">
  <ion-toolbar>
    <ion-row class="ion-wrap center">
      <ion-col [size]="6">
        <ion-button expand="block" class="btn-maroon"
                    (click)="registration.skipCardRegistration()"
                    data-cy="registration-card-skip">
          <ion-icon name="close-circle" class="mr-5"></ion-icon>
          <span>{{'registration.skipButton' | transloco}}</span>
        </ion-button>
      </ion-col>
      <ion-col [size]="6">
        <ion-button expand="block" class="btn-register btn-green"
                    [disabled]="registrationForm?.form?.invalid"
                    (click)="registration.registerCard(registrationForm.getCard())"
                    data-cy="card-confirm-button">
          <fa-icon icon="check" class="mr-5"></fa-icon>
          <span>{{'registration.registerButton' | transloco}}</span>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>
