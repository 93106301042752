import { Injectable } from '@angular/core';
import {
  buildState,
  EntityActionTypes,
  IEntityState,
  LoadAll,
  LoadAllSuccess,
  ofEntityType
} from '@briebug/ngrx-auto-entity';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, createReducer, createSelector } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import { appInitialized, cloudSettingsLoaded } from '../app/app.actions';
import { selectedLanguage, startEditingUserSettings } from '../settings/settings.state';
import { Language } from './models/language';

export const {
  initialState,
  selectors: {
    selectEntities: languageEntities
  },
  facade: LanguagesFacadeBase
} = buildState(Language);

export type LanguageState = IEntityState<Language>;

const reduce = createReducer(
  initialState
);

export function languageReducer(state: LanguageState, action: Action): LanguageState {
  return reduce(state, action);
}

@Injectable()
export class LanguagesEffects {
  constructor(private actions$: Actions, private transloco: TranslocoService) {
  }

  loadLanguages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cloudSettingsLoaded),
      map(() =>
        new LoadAll(Language)
      )
    )
  );

  setAvailableLangs$ = createEffect(() =>
      this.actions$.pipe(
        ofEntityType(Language, EntityActionTypes.LoadAllSuccess),
        tap(({ entities }: LoadAllSuccess<Language>) => this.transloco.setAvailableLangs(entities.map(ent => ent.code)))
      )
    , { dispatch: false }
  );
}

export const selectedLanguageEntity = createSelector(
  languageEntities,
  selectedLanguage,
  (languages, selected) => languages[selected]
);
