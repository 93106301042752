import { CommonModule } from '@angular/common';
import { NgModule, SecurityContext } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBuilding,
  faCompass,
  faDirections,
  faEnvelope,
  faEnvelopeOpen,
  faIdCard,
  faInfoCircle,
  faPhone,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { MarkdownModule } from 'ngx-markdown';
import { ComponentsModule } from '../../components/components.module';
import { PipesModule } from '../../pipes/pipes.module';
import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import { AppointmentHeaderComponent } from './appointment-header/appointment-header.component';
import { AppointmentsEffects } from './appointments.effects';
import { AppointmentsPage } from './appointments.page';
import { AppointmentsService } from './appointments.service';
import { NoAppointmentWarningComponent } from './no-appointment-warning/no-appointment-warning.component';
import { TruncatePipe } from './truncate.pipe';

const routes: Routes = [
  {
    path: '',
    component: AppointmentsPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MarkdownModule.forRoot({ sanitize: SecurityContext.STYLE  }),
    RouterModule.forChild(routes),
    EffectsModule.forFeature([AppointmentsEffects]),
    FontAwesomeModule,
    ComponentsModule,
    TranslocoModule,
    PipesModule,
  ],
  declarations: [
    AppointmentsPage,
    AppointmentDetailComponent,
    AppointmentHeaderComponent,
    NoAppointmentWarningComponent,
    TruncatePipe,
  ],
  providers: [AppointmentsService, AppointmentsEffects],
})
export class AppointmentsPageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(
      faEnvelopeOpen,
      faPhone,
      faCompass,
      faEnvelope,
      faBuilding,
      faRedo,
      faIdCard,
      faInfoCircle,
      faDirections
    );
  }
}
