import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { sortByDistance } from '../../../util/model-comparers';
import { calculateDistance } from '../../../util/model-transformers';

@Entity({
  modelName: 'Office',
  comparer: sortByDistance,
  transform: [calculateDistance]
})
export class Office {
  @Key locationId: number;
  authorityId: number;
  clinicGroup: number;
  status: number;
  isActive: boolean;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber1: string;
  phoneNumber2: string;
  emailAddress: string;
  webAddress: string;
  longitude: number;
  latitude: number;

  locationDetails?: string;

  distance?: number;
}
