import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'wic-key-enter-upc',
  templateUrl: './key-enter-upc.component.html',
  styleUrls: ['./key-enter-upc.component.scss'],
})
export class KeyEnterUpcComponent {
  @Input() form : UntypedFormGroup;
  @Output() focused = new EventEmitter<Event>();

  

}
