import { NgModule } from '@angular/core';
import { LegacyReportingService } from '~features/reporting/legacy-reporting.service';
import { ReportingService } from '~features/reporting/reporting.service';

@NgModule({
  providers: [
    ReportingService,
    LegacyReportingService
  ]
})
export class ReportingModule {
}
