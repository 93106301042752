import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { DialogsService } from '~core/services/dialogs.service';
import { LogService } from '~core/services/log.service';
import { DeviceService } from '~core/services/device.service';
import { neverCompletes } from '../../util/rxjs-util';

import { EnhancedDeviceService } from './enhanced-device.service';
import { EnhancedModeService } from './enhanced-mode.service';
import {
  enhancedDeviceLoaded,
  enhancedDeviceRegistered,
  enhancedDeviceRegistrationError,
  loadEnhancedDevice,
  registerEnhancedDevice,
  showAdminTools,
  showDebuggingTools,
  showDebugSettings,
  showHostSettings, showNotificationInvestigator
} from './enhanced-device.state';

const TAGS = ['State', 'Enhanced Mode'];

@Injectable()
export class EnhancedDeviceEffects {
  constructor(
    private actions$: Actions,
    private dialogs: DialogsService,
    private enhancedDevice: EnhancedDeviceService,
    private enhancedMode: EnhancedModeService,
    private log: LogService,
    private device: DeviceService) {
  }

  registerEnhancedDevice$ = createEffect(
    () => this.actions$.pipe(
      ofType(registerEnhancedDevice),
      withLatestFrom(this.device.deviceInfo$),
      tap(() => this.log.debug(TAGS, 'Device is being registered for enhanced mode.')),
      switchMap(([, device]) =>
        this.enhancedDevice.register(device).pipe(
          map(registration => enhancedDeviceRegistered({registration})),
          catchError(error => of(enhancedDeviceRegistrationError({error})))
        )
      )
    )
  );

  enhancedDeviceRegistered$ = createEffect(
    () => this.actions$.pipe(
      ofType(enhancedDeviceRegistered),
      tap(() => this.log.debug(TAGS, 'Device has been registered for enhanced mode.')),
      exhaustMap(({registration}) =>
        this.enhancedMode.showRegistrationResult(registration)
      )
    ),
    {dispatch: false}
  );

  loadEnhancedDevice$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadEnhancedDevice),
      withLatestFrom(this.device.uniqueId$),
      tap(([, udid]) => this.log.debug(TAGS, `Loading enhanced mode registration for ${udid}...`)),
      switchMap(([, udid]) =>
        this.enhancedDevice.load(udid).pipe(
          tap({
            next: registration => this.log.debug(TAGS, `Enhanced mode registration has been loaded for ${registration.udid}.`),
            error: err => this.log.warn(TAGS, `Error loading enhanced mode registration.`, err)
          }),
          map(registration => enhancedDeviceLoaded({registration})),
          catchError(() => of(enhancedDeviceLoaded({registration: null})))
        )
      )
    )
  );

  enhancedDeviceRegistrationError$ = createEffect(
    () => this.actions$.pipe(
      ofType(enhancedDeviceRegistrationError),
      switchMap(() => this.dialogs.alert({
        message: 'Sorry, we were unable to register your device for enhanced functionality. Please try again later.',
        title: 'Enhanced Mode Registration'
      })),
      neverCompletes(),
      tap(() => this.log.warn(TAGS, 'Unable to register device for enhanced mode!'))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  showDebuggingTools$ = createEffect(
    () => this.actions$.pipe(
      ofType(showDebuggingTools),
      exhaustMap(() => this.enhancedMode.showDebugging()),
      neverCompletes()
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  showDebugSettings$ = createEffect(
    () => this.actions$.pipe(
      ofType(showDebugSettings),
      exhaustMap(() => this.enhancedMode.showDebugSettings()),
      neverCompletes()
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  showHostSettings$ = createEffect(
    () => this.actions$.pipe(
      ofType(showHostSettings),
      exhaustMap(({mode}) => this.enhancedMode.showHostSettings(mode)),
      neverCompletes()
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  showNotificationInvestigator$ = createEffect(
    () => this.actions$.pipe(
      ofType(showNotificationInvestigator),
      exhaustMap(() => this.enhancedMode.showNotificationInvestigator()),
      neverCompletes()
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  showAdminTools$ = createEffect(
    () => this.actions$.pipe(
      ofType(showAdminTools),
      exhaustMap(() => this.enhancedMode.showAdminTools()),
      neverCompletes()
    ),
    {dispatch: false, resubscribeOnError: true}
  );
}
