import { Component, Input } from '@angular/core';
import { ErrorsFacade } from '~features/error/errors.facade';
import { NetworkStatusService } from '~core/services/network-status.service';
import { HardError, SoftError } from '~features/error/error.state';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'wic-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  @Input() error: SoftError | HardError;
  @Input() screenshot?: string;

  constructor(public modal: ModalController, public errors: ErrorsFacade, public networkStatus: NetworkStatusService) { }
}
