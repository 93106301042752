import { Component, Input } from '@angular/core';
import { EnvironmentService } from '~features/environment.service';
import { Authority } from '~features/authorities/models';

@Component({
  selector: 'wic-authority-logo',
  templateUrl: './authority-logo.component.html',
  styleUrls: ['./authority-logo.component.scss'],
})
export class AuthorityLogoComponent {
  @Input() authority: Authority;

  constructor(public env: EnvironmentService) { }
}
