import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, from, of, switchMap } from 'rxjs';
import { catchError, exhaustMap, filter, first, map, tap, withLatestFrom } from 'rxjs/operators';

import { DialogsService } from '~core/services/dialogs.service';
import { LogService } from '~core/services/log.service';
import { registrationResetComplete } from '~features/registration/registration.actions';

import { appInitialized, initializationCompleted } from '~features/app/app.actions';
import { checkNotificationPermissions } from '~features/app/permissions.actions';
import { SettingsUIService } from '~features/settings/settings-ui.service';
import { State } from '../state';
import { CloudSettingsService } from './cloud-settings-storage.service';
import {
  adminSettings,
  adminSettingsChanged,
  currentLanguage,
  editAppointmentOptions,
  editBenefitOptions,
  editedUserSettings,
  hasSelectedInitialLanguage,
  initialLanguageSelected,
  languageReset,
  languageResetComplete,
  saveEditedUserSettings,
  selectLanguage,
  settingsReset,
  settingsResetComplete,
  showSelectInitialLanguage,
  userSettingsChanged,
  userSettingsEdited,
} from './settings.state';
import { DeviceService } from '~core/services/device.service';

const TAGS = ['State', 'Settings'];

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private dialogs: DialogsService,
    private device: DeviceService,
    private log: LogService,
    private transloco: TranslocoService,
    private settingsUI: SettingsUIService,
    private store: Store<State>,
    private cloudSettings: CloudSettingsService
  ) {}

  checkSelectInitialLanguage$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(initializationCompleted)),
      this.transloco.events$.pipe(first()),
    ]).pipe(
      withLatestFrom(this.store.select(hasSelectedInitialLanguage)),
      tap(() => this.log.trace(TAGS, 'Checking to see if should select language')),
      map(([, hasSelected]) =>
        hasSelected ? checkNotificationPermissions() : showSelectInitialLanguage()
      )
    )
  );

  trackInitialLanguageSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showSelectInitialLanguage),
      tap(() => this.log.trace(TAGS, 'Showing Select initial language')),
      withLatestFrom(this.store.select(editedUserSettings)),
      map(([, settings]) =>
        userSettingsEdited({ settings: { ...settings, hasSelectedInitialLanguage: true } })
      )
    )
  );

  setDefaultInitialLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showSelectInitialLanguage),
      switchMap(() => this.device.getDefaultLanguageInfo()),
      map(({ langCode, langTag }) =>
        this.transloco.isLang(langTag)
          ? langTag
          : this.transloco.isLang(langCode)
            ? langCode
            : this.transloco.getActiveLang()
      ),
      tap(lang => this.transloco.setActiveLang(lang)),
      map(lang => selectLanguage({ lang })),
      catchError((err) => 
        of(null).pipe(
          tap(() => this.log.error(TAGS, `Error setting initial lang from device preferences: ${err.message}`))
        )
      ),
      filter(val => !!val)
    )
  );

  showSelectInitialLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showSelectInitialLanguage),
      switchMap(() => this.settingsUI.showSelectInitialLanguageModal()),
      map(() => initialLanguageSelected())
    )
  );

  saveInitialLanguageSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initialLanguageSelected),
      switchMap(() => [saveEditedUserSettings(), checkNotificationPermissions()])
    )
  );

  languageReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(languageReset, adminSettingsChanged),
      withLatestFrom(this.store.select(currentLanguage)),
      map(([_, lang]) => this.transloco.setActiveLang(lang)),
      tap(lang => this.log.trace(TAGS, `Setting active language to ${lang}...`)),
      map(() => languageResetComplete())
    )
  );

  settingsReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsReset),
      tap(() => this.log.warn(TAGS, 'Settings have been reset!')),
      exhaustMap(() =>
        from(this.cloudSettings.save({ user: {}, admin: {} }, true)).pipe(
          tap(() => this.log.warn(TAGS, 'Reset admin and user settings in cloud settings!')),
          map(() => settingsResetComplete())
        )
      )
    )
  );

  registrationReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registrationResetComplete),
        tap(() => this.log.warn(TAGS, 'Registration has been reset!')),
        exhaustMap(() =>
          from(this.cloudSettings.save({ registration: {} }, true)).pipe(
            tap(() => this.log.warn(TAGS, 'Cleared registration in cloud settings!'))
          )
        )
      ),
    { dispatch: false }
  );

  adminSettingsChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adminSettingsChanged),
        tap(() => this.log.trace(TAGS, 'Admin settings changed')),
        withLatestFrom(this.store.select(adminSettings)),
        tap(([, settings]) => this.cloudSettings.save({ admin: settings }))
      ),
    { dispatch: false }
  );

  userSettingsChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userSettingsChanged),
        tap(() => this.log.trace(TAGS, 'User settings changed')),
        tap(({ settings }) => this.cloudSettings.save({ user: settings }))
      ),
    { dispatch: false }
  );

  setLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userSettingsChanged),
        map(({ settings }) => settings.lang),
        filter(lang => lang !== this.transloco.getActiveLang()),
        tap(lang => this.log.info(TAGS, `Setting active language to ${lang}...`)),
        tap(lang => this.transloco.setActiveLang(lang)),
        tap(lang => this.log.trace(TAGS, `Active language set to ${lang}.`)),
        tap(() =>
          this.dialogs.toast({
            message: this.transloco.translate('settings.settingsSavedConfirmation'),
            cssClass: 'toast-success',
          })
        )
      ),
    { dispatch: false }
  );

  selectLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectLanguage),
      tap(() => this.log.trace(TAGS, 'Language selection changed')),
      withLatestFrom(this.store.select(editedUserSettings)),
      tap(([{ lang }]) => this.log.debug(TAGS, 'Selected language: ', lang)),
      map(([{ lang }, settings]) => userSettingsEdited({ settings: { ...settings, lang } }))
    )
  );

  editBenefitOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editBenefitOptions),
      tap(() => this.log.trace(TAGS, 'Benefit options edited')),
      withLatestFrom(this.store.select(editedUserSettings)),
      tap(([{ options }]) => this.log.debug(TAGS, 'Benefit options: ', options)),
      map(([{ options }, settings]) =>
        userSettingsEdited({ settings: { ...settings, benefits: options } })
      )
    )
  );

  editAppointmentOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editAppointmentOptions),
      tap(() => this.log.trace(TAGS, 'Appointment options edited')),
      withLatestFrom(this.store.select(editedUserSettings)),
      tap(([{ options }]) => this.log.debug(TAGS, 'Appointment options: ', options)),
      map(([{ options }, settings]) =>
        userSettingsEdited({ settings: { ...settings, appointments: options } })
      )
    )
  );

  saveEditedUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveEditedUserSettings),
      tap(() => this.log.trace(TAGS, 'Save User Settings')),
      withLatestFrom(this.store.select(editedUserSettings)),
      tap(([, settings]) => this.log.info(TAGS, 'New user settings: ', settings)),
      map(([, settings]) => userSettingsChanged({ settings }))
    )
  );
}
