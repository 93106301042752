import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { add, parseISO } from 'date-fns';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import {
  backToCurrentBenefits,
  changeFutureGroup,
  clearFuture,
  loadBenefitsForCriteria,
  loadFutureBenefits,
  setCurrentFutureGroupIndex,
  viewFutureBenefits,
} from '~features/benefits/benefits.actions';
import { currentBenefitsCriteria, hasBenefits } from '~features/benefits/benefits.selectors';
import { BenefitCriteria } from '~features/benefits/models';
import { Registration } from '~features/registration/models';
import { currentRegistration } from '~features/registration/registration.selectors';
import { State } from '~features/state';
import { formatBenefitDate } from './benefit-utils';
import {
  atFirstGroup,
  currentFutureGroupIndex,
  futureBenefitGroupsKeys,
  futureBenefitsCache,
  latestBenefitsEndDate,
} from './future-benefits.selectors';

const TAGS = ['Effects', 'Future Benefits'];

@Injectable()
export class FutureBenefitsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private nav: NavController,
    private log: LogService
  ) {}

  clearFutureBenefits$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearFuture),
        tap(() => this.log.trace(TAGS, 'Future benefits have been cleared.'))
      ),
    { dispatch: false }
  );

  backToCurrentBenefits$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(backToCurrentBenefits),
        tap(() => this.log.trace(TAGS, 'Back to current benefits...')),
        tap(() => this.nav.pop())
      ),
    { dispatch: false }
  );

  viewFutureBenefits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewFutureBenefits),
      tap(() => this.nav.navigateForward('/future-benefits')),
      withLatestFrom(this.store.select(futureBenefitGroupsKeys)),
      map(([, keys]) => (!keys.length ? loadFutureBenefits() : setCurrentFutureGroupIndex({ index: 0 })))
    )
  );

  changeFutureBenefitsGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeFutureGroup),
      map(({ direction }) => (direction === 'forward' ? 1 : -1)),
      withLatestFrom(this.store.select(futureBenefitGroupsKeys), this.store.select(currentFutureGroupIndex)),
      map(([direction, keys, index]) => ({ nextIndex: index + direction, keys })),
      map(({ nextIndex, keys }) =>
        nextIndex + 1 > keys.length
          ? loadFutureBenefits()
          : nextIndex < 0
          ? backToCurrentBenefits()
          : setCurrentFutureGroupIndex({ index: nextIndex })
      )
    )
  );

  loadFutureBenefits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFutureBenefits),
      tap(() => this.log.trace(TAGS, `Looking up future benefits...`)),
      withLatestFrom(
        this.store.select(currentBenefitsCriteria),
        this.store.select(currentRegistration),
        this.store.select(latestBenefitsEndDate),
        this.store.select(hasBenefits),
        this.store.select(atFirstGroup)
      ),
      tap({ error: err => this.log.error(TAGS, 'Error selecting additional criteria, dates and benefit info', err) }),
      map(([action, currentCriteria, registration, endDate, hasCurrent, firstFutureGroup]) => ({
        ...(currentCriteria || {}),
        registration,
        endDate: endDate ? parseISO(endDate) : new Date(),
        loadWide: !hasCurrent && firstFutureGroup,
      })),
      tap(data => this.log.trace(TAGS, 'Future Benefits Data: ', data)),
      map((criteria: BenefitCriteria & { registration: Registration; endDate: Date; loadWide: boolean }) => ({
        ...criteria,
        registration: undefined,
        baseDate: undefined,
        authority: criteria.authority || criteria.registration.authority,
        cardNumber: criteria.cardNumber || criteria.registration.currentCard.cardNumber,
        householdId: criteria.householdId || criteria.registration.currentCard.householdId,
        startDate: add(criteria.endDate, { days: 1 }),
      })),
      map(({ loadWide, ...criteria }) => ({
        ...criteria,
        startDate: formatBenefitDate(criteria.startDate),
        endDate: formatBenefitDate(loadWide ? add(criteria.startDate, { days: 30 }) : criteria.startDate),
        // endDate: formatBenefitDate(criteria.startDate),
        isFuture: true,
      })),
      map((criteria: BenefitCriteria) => loadBenefitsForCriteria({ criteria }))
    )
  );

  futureBenefitsChanged$ = createEffect(
    () =>
      this.store.pipe(
        select(futureBenefitsCache),
        tap(() => this.log.trace(TAGS, 'Future benefits have changed!'))
      ),
    { dispatch: false }
  );
}
