import { Component, Input, Output, EventEmitter } from '@angular/core';
import { addHours, parseISO, subHours } from 'date-fns';
import { IonDatetime, ModalController } from '@ionic/angular';

import { AlertOptions } from '~features/settings/models';
import { ScheduleChooserModalComponent } from '../schedule-chooser-modal/schedule-chooser-modal.component';

@Component({
  selector: 'wic-notification-chooser',
  templateUrl: './notification-chooser.component.html',
  styleUrls: ['./notification-chooser.component.scss'],
})
export class NotificationChooserComponent {
  @Input() title: string;
  @Input() alert: AlertOptions;
  @Output() changed = new EventEmitter<AlertOptions>();

  constructor(private modals: ModalController) {
  }

  toggle(alert) {
    this.changed.emit({
      ...alert,
      allowAlerts: !alert.allowAlerts
    });
  }

  setTime(time: string | string[], alert) {
    if (!time ) {
      return;
    }

    if(typeof time === 'string') {
      const parsed = parseISO(time);
      this.changed.emit({
        ...alert,
        hour: parsed.getHours(),
        minute: parsed.getMinutes()
      });
    }
  }

  async confirm(datetime: IonDatetime) {
    await datetime.confirm(true);
    this.setTime(datetime.value, this.alert);
  }

  async chooseSchedule(alert: AlertOptions) {
    const modal = await this.modals.create({
      component: ScheduleChooserModalComponent,
      componentProps: {
        currentDays: alert.daysBefore
      }
    });

    await modal.present();
    const result = await modal.onDidDismiss();
    if (result.role === 'ok') {
      this.changed.emit({
        ...alert,
        daysBefore: result.data
      });
    }
  }
}
