import { concatMap, delay, MonoTypeOperatorFunction, of, pipe, scan } from 'rxjs';

export const rateLimit = <T>(count: number, period: number): MonoTypeOperatorFunction<T> =>
  pipe(
    scan((records, value) => {
      const now = Date.now();
      const since = now - period;

      // Keep a record of all values received within the last period.
      records = records.filter(record => record.until > since);

      if (records.length >= count) {

        const firstRecord = records[0];
        const lastRecord = records[records.length - 1];
        const offsetRecord = records[records.length - count + Math.floor(records.length / count) - 1];

        const offsetRecordUntilDiff = offsetRecord.until - firstRecord.until;

        const until = firstRecord.until + period  + offsetRecordUntilDiff - offsetRecord.delay;
        const untilOffsetTime = until - now;
        const untilDelay = until - lastRecord.until;

        const newRecord = {
          delay: lastRecord.until < now ? untilOffsetTime : untilDelay,
          until,
          value,
        }


        // console.log(value)
        // console.log(lastRecord.until < now, untilOffsetTime, untilDelay, offsetRecordUntilDiff);
        // console.table([ firstRecord, offsetRecord, lastRecord, newRecord ])
        // console.log('------\n');

        records.push(newRecord);
      } else {
        records.push({
          delay: 0,
          until: now,
          value,
        });
      }
      return records;
    }, []),
    concatMap(records => {
      const lastRecord = records[records.length - 1];
      return lastRecord.delay
        ? of(lastRecord.value).pipe(delay(lastRecord.delay))
        : of(lastRecord.value);
    })
  );

