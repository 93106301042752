import { NgModule } from '@angular/core';
import { EnhancedModeFacade } from './enhanced-mode.facade';
import { EnhancedDeviceService } from './enhanced-device.service';
import { EnhancedModeService } from './enhanced-mode.service';


@NgModule({
  providers: [
    EnhancedModeFacade,
    EnhancedDeviceService,
    EnhancedModeService
  ]
})
export class EnhancedModeModule {
}
