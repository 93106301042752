import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  SUBSTITUTION_CONFIGS_FEATURE_KEY,
  substitutionConfigsReducer,
} from '~features/substitution-configs/substitution-configs.state';
import { EffectsModule } from '@ngrx/effects';
import { EntityService } from '~core/services/entity.service';
import { SubstitutionConfig } from '~features/substitution-configs/substitution-config.entity';
import { SubstitutionConfigsEffects } from '~features/substitution-configs/substitution-configs.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(SUBSTITUTION_CONFIGS_FEATURE_KEY, substitutionConfigsReducer),
    EffectsModule.forFeature([SubstitutionConfigsEffects])
  ],
  providers: [
    { provide: SubstitutionConfig, useClass: EntityService },

  ]
})
export class SubstitutionConfigsStateModule {}
