<ion-header>
  <ion-toolbar color="tufts-blue-shaded">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>{{'qrCode.contentTitle' | transloco}}</ion-title>
  </ion-toolbar>
  <wic-card-select-toolbar
    color="tufts-blue-shaded"
    [cards]="registration.currentCards$ | async"
    [currentCard]="registration.currentCard$ | async"
    (selected)="registration.selectCard($event)"
  ></wic-card-select-toolbar>
</ion-header>

<ion-content>
  <wic-no-cards-warning
    *ngIf="(registration.hasCurrentCard$ | async) === false"
    [hasCard]="registration.hasCurrentCard$ | async"
    [hasCards]="registration.hasCards$ | async"
  >
    <ng-container ngProjectAs="[slot=info]">
      <p>
        {{ ((registration.hasCurrentCard$ | async) ?
        'benefits.noBenefitsFoundInfo' : (registration.hasCards$ | async) ?
        'benefits.noCurrentCardInfo' : 'benefits.noCardsRegisteredInfo') |
        transloco }}
      </p>
    </ng-container>
    <ng-container ngProjectAs="[slot=buttons]">
      <ion-row>
        <ion-col>
          <ion-button
            (click)="registration.manageCards(true)"
            class="btn-green"
            expand="block"
          >
            <fa-stack>
              <fa-icon icon="credit-card" stackItemSize="1x"></fa-icon>
              <fa-icon
                icon="asterisk"
                stackItemSize="1x"
                transform="shrink-4 right-8 down-10"
                style="color: dimgray"
              ></fa-icon>
              <fa-icon
                icon="asterisk"
                stackItemSize="1x"
                transform="shrink-4 right-7 down-9"
                style="color: gold"
              ></fa-icon>
            </fa-stack>
            <span class="ion-text-uppercase"
              >{{ 'buttons.manageCards' | transloco }}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ng-container>
  </wic-no-cards-warning>
  <ion-grid *ngIf="(registration.hasCurrentCard$ | async) === true">
    <ion-row>
      <ion-text>
        {{ 'registration.cardSectionLabel' | transloco }} {{ (registration.currentCard$ | async)?.cardNumber | mask }}
      </ion-text>
    </ion-row>
    <ion-row>
        <ion-card [ngStyle]="{
          height: expand ? sizeLarge : sizeDefault,
          width: expand ? sizeLarge : sizeDefault
        }" (click)="expand = !expand" class="ion-activatable">
          <wic-card-qr-code
            [cardNumber]="(registration.currentCard$ | async)?.cardNumber"
            [authority]="registration.currentProvider$ | async"
            [size]="expand ? sizeLarge : sizeDefault"
          ></wic-card-qr-code>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-card>
    </ion-row>
    <ion-row>
      <ion-text class="ion-text-wrap ion-text-center">{{ 'qrCodes.instructionMessage' | transloco }}</ion-text>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer> 
  <ion-toolbar>
    <ion-button expand="block" (click)="nav.back()">{{ 'buttons.close' | transloco }}</ion-button>
  </ion-toolbar>
</ion-footer>