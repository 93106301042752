import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LogService } from '~core/services/log.service';
import { RemoteLogService } from '~core/services/remote-log.service';

@Component({
  selector: 'wic-debug-settings-modal',
  templateUrl: './debug-settings-modal.component.html',
  styleUrls: ['./debug-settings-modal.component.scss'],
})
export class DebugSettingsModalComponent {
  constructor(public log: LogService, public modal: ModalController, public remoteLog: RemoteLogService) {
  }
}
