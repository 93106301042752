import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Create, CreateSuccess, EntityActionTypes, ofEntityType} from '@briebug/ngrx-auto-entity';
import {NavController, Platform} from '@ionic/angular';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {defer, from, iif, of} from 'rxjs';
import {catchError, debounceTime, delay, exhaustMap, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import { BarcodeScannerService } from '~core/services/barcode-scanner.service';
import { CameraService } from '~core/services/camera.service';
import {DialogsService} from '~core/services/dialogs.service';
import {EmptyKey} from '~core/services/entity.service';
import {LogService} from '~core/services/log.service';
import {NativePermissionsService} from '~core/services/native-permissions.service';
import {SoftError} from '~features/error/error.state';

import {
  captureMissingProductPhoto,
  icbtPhotoCaptureError,
  isScanning,
  labelPhotoCaptured,
  missingProductBarcodeScanCancelled,
  missingProductBarcodeScanError,
  missingProductBarcodeScanned,
  noPhotoCaptured,
  productPhotoCaptured,
  reportMissingProduct,
  scanMissingProductBarcode,
  submitMissingProductReport,
  upcPhotoCaptured
} from '~features/missing-product/missing-product.state';
import {MissingProduct} from '~features/missing-product/models';
import {currentCard, currentProviderId} from '~features/registration/registration.selectors';
import {State} from '~features/state';

const TAGS = ['Effects', 'Missing Product'];


const compareProductReports = ({missing, lastReport}) =>
  (
    // Loose filtering ensures null is compared to undefined properly, As well as increases chance for duplicates to be filtered out.
    /* eslint-disable */
    lastReport.name == missing.name &&
    lastReport.email == missing.email &&
    lastReport.phone == missing.phone &&
    lastReport.storeName == missing.storeName &&
    lastReport.storeLocNum == missing.storeLocNum &&
    lastReport.who == missing.who &&
    lastReport.brand == missing.brand &&
    lastReport.productName == missing.productName &&
    lastReport.packageSize == missing.packageSize &&
    lastReport.upc == missing.upc &&
    lastReport.addtlInfo == missing.addtlInfo
    /* eslint-enable */
  );

@Injectable()
export class MissingProductEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private camera: CameraService,
    private platform: Platform,
    private dialogs: DialogsService,
    private scanner: BarcodeScannerService,
    private permissions: NativePermissionsService,
    private log: LogService,
    private router: Router,
    private nav: NavController) {
  }

  reportProduct$ = createEffect(
    () => this.actions$.pipe(
      ofType(reportMissingProduct),
      tap(({product}) => this.nav.navigateForward(['/cant-buy-this'], {
        state: {
          upc: product.itemNumber
        }
      }))
    ),
    {dispatch: false}
  );

  private lastReport = null;

  duplicateReportWarning$ = createEffect(
    () => this.actions$.pipe(
      ofType(submitMissingProductReport),
      filter(() => !!this.lastReport),
      // Only show warning if user manually sent report (avoids other dup issues)
      filter(({missing}) => missing.guid === this.lastReport.guid),
      filter(({missing}) => compareProductReports({missing, lastReport: this.lastReport})),
      tap(({missing}) => this.log.info(TAGS, 'Duplicate missing product report identified...', missing)),
      exhaustMap(() =>
        this.dialogs.alert({message: 'It looks like you have already reported this product. Please only report each product once.'})
      ),
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  submitReport$ = createEffect(
    () => this.actions$.pipe(
      ofType(submitMissingProductReport),
      debounceTime(100),
      tap(({missing}) => this.log.info(TAGS, 'User submitted missing product report to JPMA:', missing)),
      filter(({missing}) =>
        !this.lastReport || missing.guid !== this.lastReport.guid || !compareProductReports({missing, lastReport: this.lastReport})
      ),
      tap(({missing}) => this.lastReport = missing), // Save the last report if it doesn't match new one.
      switchMap(({missing}) =>
        this.dialogs.showLoading({message: 'Please wait...'}).pipe(
          tap((res) => this.log.trace(TAGS, 'Created Loading Indicator')),
          map(() => missing),
        )
      ),
      withLatestFrom(
        this.store.select(currentProviderId),
        this.store.select(currentCard)
      ),
      map(([missing, authorityId, card]) => new Create(MissingProduct, {
        ...missing,
        timestamp: new Date(Date.now()).toISOString(),
        authorityId: +authorityId,
        cardNumber: card ? '************' + card.cardNumber.slice(-4) : '',
      }, {
        parents: {
          support: EmptyKey,
          products: EmptyKey
        },
        reuseInput: {
          productImage: true,
          labelImage: true
        }
      })),
      tap({
        next: () => this.log.trace(TAGS, 'Successfully sent missing product report!'),
        error: err => this.log.error(TAGS, `Error sending missing product report: ${JSON.stringify(err)}`, err)
      }),
    )
  );

  thankUser$ = createEffect(
    () => this.actions$.pipe(
      ofEntityType<MissingProduct, CreateSuccess<MissingProduct>>(MissingProduct, EntityActionTypes.CreateSuccess),
      switchMap(() => this.dialogs.dismissLoading()),
      exhaustMap(() => this.dialogs.alert({message: 'Thank you for notifying us about this product!'})),
      tap(() => this.router.url === '/cant-buy-this' ? this.nav.back() : false)
    ),
    {dispatch: false}
  );

  warnUser$ = createEffect(
    () => this.actions$.pipe(
      ofEntityType(MissingProduct, EntityActionTypes.CreateFailure),
      tap(() => this.lastReport = null), // If Submitting Report Fails, then all user to re-trigger process
      switchMap(() => this.dialogs.dismissLoading()),
      switchMap(() =>
        this.dialogs.alert({message: 'We encountered an error submitting this missing product report! Try again later.'})
      )
    ),
    {dispatch: false}
  );

  capturePhoto$ = createEffect(
    () => this.actions$.pipe(
      ofType(captureMissingProductPhoto),
      switchMap(({isLabel, isUpc}) =>
        from(this.permissions.hasCameraPermission()).pipe(
          switchMap(has => has ? of(true) : this.permissions.explainCameraPermission('gold', 'orange', true)),
          // TODO: Storage Permissions May be built in at this point, maybe remove
          // switchMap(() => this.permissions.hasStoragePermissions()),
          // switchMap(has => has ? of(true) : this.permissions.explainStoragePermission('gold', 'orange'))
        ).pipe(map(() => ({isLabel, isUpc})))
      ),
      tap(({isLabel, isUpc}) => this.log.trace(TAGS, `Capturing missing ${isLabel ? 'label' : isUpc ? 'upc' : 'product'} photo...`)),
      switchMap(({isLabel, isUpc}) =>
        from(this.camera.getPicture({
          quality: 60,
          width: 600,
          height: 600,
        })).pipe(
          tap({
            next: (photo) => this.log.trace(TAGS, 'Captured photo', photo),
            error: err => this.log.error(TAGS, 'Error capturing photo:', err)
          }),
          map(photo => isLabel
            ? labelPhotoCaptured({capturedLabel: photo})
            : isUpc ? upcPhotoCaptured({capturedUpc: photo}) : productPhotoCaptured({capturedPhoto: photo})
          ),
          catchError(error => error === 'No Image Selected'
            ? of(noPhotoCaptured())
            : of(icbtPhotoCaptureError({error}))
          )
        )
      )
    )
  );

  showPhotoCaptureError$ = createEffect(
    () => this.actions$.pipe(
      ofType(icbtPhotoCaptureError),
      exhaustMap(({error}) => this.dialogs.alert({
        title: 'Error capturing photo',
        message: `${error}`
      }))
    ),
    {dispatch: false}
  );

  // scanBarcodeBrowser$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(scanMissingProductBarcode),
  //     filter(() => !Capacitor.isNativePlatform()),
  //     tap(() => this.log.trace(TAGS, 'Faking barcode scan...')),
  //     map(() => missingProductBarcodeScanned({upc: '801541508174', upcType: 'UPC-A'}))
  //   )
  // );

  scanBarcode$ = createEffect(
    () => this.actions$.pipe(
      ofType(scanMissingProductBarcode),
      switchMap(() => this.scanner.hasPermission()),
      switchMap(granted =>
        // Check permission, if granted continue, else friendly permission
        iif(() => granted,
          of(true),
          defer(() =>
            this.permissions.explainCameraPermission('gold', 'orange')
          ).pipe(
            switchMap(() => this.scanner.hasPermission()),
            switchMap(hasPermission =>
              // If permission denied, show settings, if not denied or asked, prompt user
              hasPermission === false ? this.scanner.openSettings() : this.scanner.askPermission()
            ),
          )
        )
      ),
      filter(v => !!v),
      switchMap(() => this.scanner.prepare()),
      switchMap(() => this.nav.navigateForward('/cant-buy-this/barcode-scanner')),
      switchMap(() =>
        from(this.scanner.startScan()).pipe(
          filter(result => !!result),
          tap(
            result => this.log.debug(TAGS, `Scanned barcode: ${result}` ),
            err => this.log.error(TAGS, 'Error scanning missing product barcode:', err)
          ),
          map(result => missingProductBarcodeScanned({upc: result, upcType: '' })),
          catchError(error =>
            of(missingProductBarcodeScanError({
              error: new SoftError(
                error && (error.message || error),
                'Error scanning barcode',
                `There was an error scanning a barcode. The scanner returned: ${error && (error.message || error)}`,
                error)
            }))
          )
        )
      ),
      tap(
        () => this.log.debug(TAGS, `Missing product barcode scanning completed.`),
        err => this.log.error(TAGS, 'Error encountered scanning barcode for missing product:', err)
      ),
      catchError(error =>
        of(missingProductBarcodeScanError({
          error: new SoftError(
            error && (error.message || error),
            'Error scanning barcode',
            `There was an error scanning a barcode. The scanner returned: ${error && (error.message || error)}`,
            error)
        }))
      )
    )
  );

  backOutOfBlankScreen$ = createEffect(
    () => this.actions$.pipe(
      ofType(missingProductBarcodeScanCancelled, missingProductBarcodeScanned),
      delay(150),
      tap(() => this.nav.back())
    ),
    {dispatch: false}
  );
}
