import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleRight, faFingerprint, faReceipt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';

import { ComponentsModule } from '../../components/components.module';
import { ReceiptHistoryComponent } from './receipt-history/receipt-history.component';

import { ReceiptsPage } from './receipts.page';

const routes: Routes = [
  {
    path: '',
    component: ReceiptsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    FontAwesomeModule,
    TranslocoModule,
    ComponentsModule
  ],
  declarations: [ReceiptsPage, ReceiptHistoryComponent],
})
export class ReceiptsPageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(faReceipt, faAngleRight, faFingerprint, faTrash);
  }
}
