import { Injectable } from '@angular/core';
import {
  buildState,
  Clear,
  EntityActionTypes,
  IEntityState, LoadAll,
  LoadAllFailure,
  LoadAllSuccess,
  ofEntityType,
} from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, createAction, createReducer, createSelector, on, props, Store } from '@ngrx/store';
import { of, range } from 'rxjs';
import { concatMap, delay, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { EmptyKey } from '~core/services/entity.service';
import { LogService } from '~core/services/log.service';
import { loadBenefits, loadBenefitsSuccess } from '../benefits/benefits.actions';
import { HardError } from '../error/error.state';
import { registerPendingCard, registrationRestored } from '../registration/registration.actions';
import { currentProvider } from '../registration/registration.selectors';
import { State } from '../state';
import { SubCategory } from './models';

const TAGS = ['State', 'SubCategories'];

export interface ISubCategoryState extends IEntityState<SubCategory> {
  loadingProgress?: number;
  loadError?: Error | any;
}

export const {
  selectors: { selectAll: allSubCategories, selectEntities: subCategoryEntities },
  actions: { loadAll: loadAllSubCategories },
  initialState: initialSubCategoryState,
  facade: SubCategoryFacadeBase,
} = buildState(SubCategory, {
  loadingProgress: null,
});

export const loadSubCategoriesProgress = createAction(
  '[SubCategories] Load: Progress',
  props<{ percentage: number }>()
);

export const subCategoriesLoaded = createAction(
  '[SubCategories] Loaded',
  props<{ subCategories: SubCategory[]; timestamp: Date }>()
);

export const subCategoryLoadError = createAction('[SubCategories] Load: Error', props<{ error?: Error | any }>());

export const resetSubCategories = createAction('[SubCategories] Reset');

export const subCategoriesResetComplete = createAction('[Categories] Reset: Complete');

const reducer = createReducer(
  initialSubCategoryState,
  on(loadBenefits, state => ({
    ...state,
    loadingProgress: undefined,
    loadError: undefined,
  })),
  on(loadSubCategoriesProgress, (state, { percentage }) => ({
    ...state,
    loadingProgress: percentage,
  })),
  on(subCategoryLoadError, (state, { error }) => ({
    ...state,
    loadError: error,
  })),
  on(resetSubCategories, () => ({
    ...initialSubCategoryState,
  }))
);

export function subCategoryReducer(state = initialSubCategoryState, action: Action): ISubCategoryState {
  return reducer(state, action);
}

@Injectable()
export class SubCategoryEffects {
  constructor(private actions$: Actions, private log: LogService, private store: Store<State>) {}

  loadSubCategoriesForProvider$ = createEffect(
    () => this.actions$.pipe(
      ofType(registrationRestored, loadBenefitsSuccess),
      withLatestFrom(this.store.select(currentProvider)),
      tap(([, authority]) => this.log.debug(TAGS, `Loading subcategories for authority ${authority.name}`)),
      delay(1000),
      map(([, {id}]) => new LoadAll(SubCategory, {
        parents: {
          authorities: id,
          apl: EmptyKey,
          categories: EmptyKey
        }
      }))
    )
  );

  loadAllCategories$ = createEffect(
    () =>
      this.actions$.pipe(
        ofEntityType(SubCategory, EntityActionTypes.LoadAll),
        tap(() =>
          range(0, 28)
            .pipe(
              concatMap(count => of(count).pipe(delay(Math.random() * 210 + 270))),
              map(count => (count > 30 ? 30 : count)),
              takeUntil(
                this.actions$.pipe(
                  ofEntityType(
                    SubCategory,
                    EntityActionTypes.LoadAll,
                    EntityActionTypes.LoadAllSuccess,
                    EntityActionTypes.LoadAllFailure
                  )
                )
              )
            )
            .subscribe(count => this.store.dispatch(loadSubCategoriesProgress({ percentage: count / 30 })))
        )
      ),
    { dispatch: false }
  );

  subCategoriesLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(SubCategory, EntityActionTypes.LoadAllSuccess),
      tap(() => this.log.debug(TAGS, 'SubCategories have been loaded')),
      map(({ entities }: LoadAllSuccess<SubCategory>) =>
        subCategoriesLoaded({
          subCategories: entities,
          timestamp: new Date(),
        })
      )
    )
  );

  doneLoadingSubCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(SubCategory, EntityActionTypes.LoadAllSuccess),
      map(() => loadSubCategoriesProgress({ percentage: 1 }))
    )
  );

  loadAllSubCategoriesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(SubCategory, EntityActionTypes.LoadAllFailure),
      map(({ error }: LoadAllFailure<SubCategory>) =>
        subCategoryLoadError({
          error: new HardError(
            'Error loading product categories.',
            'Catalog Error',
            'We encountered an error loading product categories. This will ' +
              'likely affect your ability to use the app. This may be' +
              'a temporary network issue, so try again soon.',
            error
          ),
        })
      )
    )
  );

  resetSubCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetSubCategories),
      map(() => new Clear(SubCategory))
    )
  );

  resetSubCategoriesComplete = createEffect(() =>
    this.actions$.pipe(
      ofEntityType(SubCategory, EntityActionTypes.Clear),
      map(() => subCategoriesResetComplete())
    )
  );
}

export const getSubCategoriesState = (state: State) => state.subCategory;

export const mapToCategoriesLoadingProgress = (state: ISubCategoryState) => state.loadingProgress;
export const mapToLoadError = (state: ISubCategoryState) => state.loadError;
export const mapToHasLoadError = (state: ISubCategoryState) => !!state.loadError;

export const checkIfHasSubCategories = (state: ISubCategoryState) =>
  !!state.ids && !!state.ids.length && !!state.tracking.loadedAt;

export const subCategoriesLoadingProgress = createSelector(getSubCategoriesState, mapToCategoriesLoadingProgress);

export const subCategoriesLoadError = createSelector(getSubCategoriesState, mapToLoadError);

export const subCategoriesHasLoadError = createSelector(getSubCategoriesState, mapToHasLoadError);

export const hasSubCategories = createSelector(getSubCategoriesState, checkIfHasSubCategories);
