import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { restoreRegistration } from '~features/registration/registration.actions';

import {
  appResumed,
  cloudSettingsError,
  cloudSettingsLoaded,
  googleMapsApiLoaded,
  networkStatusChanged
} from '../app/app.actions';
import { getProductState, IProductState } from '../products/product.state';
import { State } from '../state';

export interface IAppState {
  initialization: {
    cloudSettings: boolean | Error;
    registration: boolean | Error;
  };
  networkStatus?: boolean;
  mapsApiLoaded: boolean;
}

const initialState: IAppState = {
  initialization: {
    cloudSettings: false,
    registration: false
  },
  mapsApiLoaded: false,
};

const reducer = createReducer(
  initialState,
  on(appResumed, (state) => ({
    ...state,
    initialization: {
      ...state.initialization,
      cloudSettings: false
    }
  })),
  on(cloudSettingsLoaded, (state) => ({
    ...state,
    initialization: {
      ...state.initialization,
      cloudSettings: true
    }
  })),
  on(cloudSettingsError, (state) => ({
    ...state,
    initialization: {
      ...state.initialization,
      cloudSettings: true
    }
  })),
  on(restoreRegistration, (state) => ({
    ...state,
    initialization: {
      ...state.initialization,
      registration: true
    }
  })),
  on(networkStatusChanged, (state, {status}) => ({
    ...state,
    networkStatus: status
  })),
  on(googleMapsApiLoaded, state => ({ ...state, mapsApiLoaded: true }))
);

export function appReducer(state = initialState, action: Action): IAppState {
  return reducer(state, action);
}

export const getAppState = (state: State) => state.app;

export const mapToIsInitialized = (appState: IAppState, productState: IProductState) =>
  appState.initialization.cloudSettings === true &&
  appState.initialization.registration === true &&
  productState.dbInitialized === true;

export const mapToNetworkStatus = (state: IAppState) =>
  state.networkStatus === true;

export const isInitialized = createSelector(
  getAppState,
  getProductState,
  mapToIsInitialized
);

export const networkStatus = createSelector(
  getAppState,
  mapToNetworkStatus
);

export const mapsApiLoaded = createSelector(
  getAppState,
  state => state.mapsApiLoaded
);
