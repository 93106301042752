import { NgModule } from '@angular/core';
import { NGRX_AUTO_ENTITY_APP_STORE, NgrxAutoEntityModule } from '@briebug/ngrx-auto-entity';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '~env';
import { APLMessageEffects } from '~features/aplmessages/aplmessages.state';
import { CloudSettingsInitEffects } from '~features/app/cloud-settings-init.effects';
import { PermissionsEffects } from '~features/app/permissions.effects';
import { PreInitializationEffects } from '~features/app/pre-initialization.effects';
import { RegistrationChangeEffects } from '~features/app/registration-change.effects';
import { ResetUIService } from '~features/app/reset-ui.service';
import { ResetEffects } from '~features/app/reset.effects';
import { AuthoritiesEffects } from '~features/authorities/authorities.effects';
import { BannersEffects } from '~features/banners/banners.effects';
import { FutureBenefitsEffects } from '~features/benefits/future-benefits.effects';
import { CalculatorsEffects } from '~features/calculators/calculators.effects';
import { MicroBlogsEffects } from '~features/micro-blogs/micro-blogs.effects';
import { MissingProductEffects } from '~features/missing-product/missing-product.effects';
import { LocalNotificationsEffects } from '~features/notifications/local-notifications.effects';
import { NotificationsEffects } from '~features/notifications/notifications.effects';
import { PushNotificationsEffects } from '~features/notifications/push-notifications.effects';
import { OfficesEffects } from '~features/offices/offices.effects';
import {
  applySubCategoryProductsUpdates,
  populateSubCategoryProducts,
  retrieveSubCategoryProducts,
  retrieveSubCategoryProductsWithLoadDate, subCategoryProductsLoaded,
  subCategoryProductsRetrieved,
} from '~features/products/product.state';
import { LegacyReportingEffects } from '~features/reporting/legacy-reporting.effects';
import { ReportingEffects } from '~features/reporting/reporting.effects';
import { VendorsEffects } from '~features/vendors/vendors.effects';
import { AnalyticsEffects } from './analytics/analytics.effects';
import { AppEffects } from './app/app.effects';
import { AppointmentEffects } from './appointments/appointments.effects';
import { BenefitsEffects } from './benefits/benefits.effects';
import { CategoryEffects } from './categories/categories.state';
import { EnhancedDeviceEffects } from './enhanced-mode/enhanced-mode.effects';
import { ErrorEffects } from './error/error.effects';
import { HomeEffects } from './home/home.effects';
import { IonicEffects } from './ionic/ionic.effects';
import { LanguagesEffects } from './languages/languages.state';
import { ProductSelectionEffects } from './products/product-selection.effects';
import { ProductEffects } from './products/product.effects';
import { ReceiptsEffects } from './receipts/receipts.effects';
import { RedemptionsEffects } from './redemptions/redemptions.effects';
import { RegistrationEffects } from './registration/registration.effects';
import { SettingsEffects } from './settings/settings.effects';
import { metaReducers, reducers } from './state';
import { SubCatConfigEffects } from './subcat-configs/subcat-configs.state';
import { SubCategoryEffects } from './subcategories/subcategories.state';
import { UpcEffects } from './upc/upc.effects';
import { StorageSyncModule } from '@jpma-wicshopper-imports-mono/mobile/storage-sync';
import { ShoppingListsStateModule } from '~features/shopping-lists/shopping-lists-state.module';
import { EnhancedIssuesEffects } from './enhanced-issues/enhanced-issues.effects';
import { VendorInventoryItemsStateModule } from '~features/vendor-inventory-items/vendor-inventory-items-state.module';
import { VendorTimeSlotsStateModule } from '~features/vendor-time-slots/vendor-time-slots-state.module';
import { SubstitutionConfigsStateModule } from '~features/substitution-configs/substitution-configs-state.module';

export function provideAppStore(store: Store) {
  return store;
}

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      // NOTE: The following order of effects is EXPLICIT! DO NOT REORDER!!
      /*01*/ AnalyticsEffects,
      /*02*/ PreInitializationEffects,
      /*03*/ AppEffects,
      /*04*/ AuthoritiesEffects,
      /*05*/ CloudSettingsInitEffects,
      /*06*/ PermissionsEffects,
      /*07*/ ResetEffects,
      /*08*/ RegistrationChangeEffects,
      /*09*/ IonicEffects,
      /*10*/ NotificationsEffects,
      /*11*/ LocalNotificationsEffects,
      /*12*/ PushNotificationsEffects,
      /*13*/ RegistrationEffects,
      /*14*/ SettingsEffects,
      /*15*/ BenefitsEffects,
      /*16*/ FutureBenefitsEffects,
      /*17*/ CategoryEffects,
      /*18*/ SubCategoryEffects,
      /*19*/ SubCatConfigEffects,
      /*20*/ EnhancedDeviceEffects,
      /*21*/ ErrorEffects,
      /*22*/ HomeEffects,
      /*23*/ ProductEffects,
      /*24*/ ProductSelectionEffects,
      /*25*/ AppointmentEffects,
      /*26*/ UpcEffects,
      /*27*/ VendorsEffects,
      /*28*/ OfficesEffects,
      /*29*/ ReceiptsEffects,
      /*30*/ BannersEffects,
      /*31*/ CalculatorsEffects,
      /*32*/ APLMessageEffects,
      /*33*/ MissingProductEffects,
      /*34*/ MicroBlogsEffects,
      /*35*/ LanguagesEffects,
      /*36*/ RedemptionsEffects,
      /*37*/ EnhancedIssuesEffects,

      /*98*/ ReportingEffects,
      /*99*/ LegacyReportingEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 100,
      logOnly: environment.production,
      actionsBlocklist: [
        retrieveSubCategoryProducts.type,
        retrieveSubCategoryProductsWithLoadDate.type,
        subCategoryProductsRetrieved.type,
        populateSubCategoryProducts.type,
        applySubCategoryProductsUpdates.type,
        subCategoryProductsLoaded.type
      ],
    }),
    NgrxAutoEntityModule.forRoot(),
    StoreRouterConnectingModule.forRoot(),
    StorageSyncModule.forRoot({
      keys: {
        shoppingList: {
          entities: true,
          ids: true,
        },
        shoppingListItem: {
          entities: true,
          ids: true,
        },
        mobileOrderItemSubstitution: {
          entities: true,
          ids: true,
        },
        vendor: {
          favorites: true
        },
        office: {
          favorites: true
        }

      }
    }),
    ShoppingListsStateModule,
    SubstitutionConfigsStateModule,
    VendorInventoryItemsStateModule,
    VendorTimeSlotsStateModule,
  ],
  providers: [
      ResetUIService,
      {
        provide: NGRX_AUTO_ENTITY_APP_STORE,
        useFactory: provideAppStore,
        deps: [Store]
      }
    ],
})
export class StateModule {}
