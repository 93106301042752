<ion-app>
  <ion-split-pane contentId="main">
    <ion-menu contentId="main" type="overlay">
      <ion-header>
        <ion-toolbar>
          <ion-title>{{'common.menu' | transloco}}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false" data-cy="menu">
            <ion-item routerDirection="root" [routerLink]="['/home']">
              <fa-icon slot="start" [fixedWidth]="true" icon="home"></fa-icon>
              <ion-label>{{'buttons.home' | transloco}}</ion-label>
            </ion-item>
            <ion-item [routerDirection]="!!(registration.currentProviderId$ | async) ? 'forward' : 'root'"
                      [routerLink]="['/select-provider']" data-cy="menu-current-provider">
              <ion-img slot="start"
                       src="{{env.azureGateway.baseUrl}}/get-logo/{{registration.currentProviderId$ | async}}?subscription-key={{env.azureGateway.subscriptionKey}}">
              </ion-img>
              <ion-label>{{registration.currentProviderName$ | async }}</ion-label>
            </ion-item>
            <ion-item routerDirection="forward" [routerLink]="['/manage-cards']"
                      *ngIf="registration.isProvider$ | async" data-cy="menu-manage-cards">
              <fa-icon slot="start" [fixedWidth]="true" icon="credit-card"></fa-icon>
              <ion-label>{{'buttons.manageCards' | transloco}}</ion-label>
            </ion-item>
            <ion-item routerDirection="forward" [routerLink]="['/appointments']"
                      *ngIf="registration.hasAppointmentReminders$ | async">
              <fa-icon slot="start" [fixedWidth]="true" icon="calendar"></fa-icon>
              <ion-label>{{'buttons.myAppointments' | transloco}}</ion-label>
            </ion-item>
            <ion-item routerDirection="forward" [routerLink]="['/settings']" data-cy="menu-settings">
              <fa-icon slot="start" [fixedWidth]="true" icon="cog"></fa-icon>
              <ion-label>{{'buttons.settings' | transloco}}</ion-label>
            </ion-item>
            <ion-item (click)="appFacade.openLink(env.environment.support.partnersUrl)">
              <fa-icon slot="start" [fixedWidth]="true" icon="handshake"></fa-icon>
              <ion-label>{{'buttons.partners' | transloco}}</ion-label>
            </ion-item>
            <ion-item (click)="appFacade.openLink(env.environment.support.aboutUrl)">
              <fa-icon slot="start" [fixedWidth]="true" icon="info-circle"></fa-icon>
              <ion-label>{{'buttons.about' | transloco}}</ion-label>
            </ion-item>
            <ion-item *ngIf="enhancedMode.isEnhanced$ | async" (click)="enhancedMode.showTools()">
              <fa-icon slot="start" [fixedWidth]="true" icon="fingerprint"></fa-icon>
              <ion-label>{{'buttons.adminTools' | transloco}}</ion-label>
            </ion-item>
            <ion-item (click)="enhancedMode.showDebugging()" lines="none">
              <fa-icon slot="start" [fixedWidth]="true" icon="fingerprint" [styles]="{ color: 'var(--ion-color-light)' }"></fa-icon>
              <ion-label color="light">{{'App Log' | transloco}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
