import { Component, Input } from '@angular/core';
import { BenefitsFacade } from '~features/benefits/benefits.facade';
import { CategoriesFacade } from '~features/categories/categories.facade';
import { SubCategoriesFacade } from '~features/subcategories/subcategories.facade';

@Component({
  selector: 'wic-benefits-loading-progress',
  templateUrl: './benefits-loading-progress.component.html',
  styleUrls: ['./benefits-loading-progress.component.scss'],
})
export class BenefitsLoadingProgressComponent {
  @Input() findBuffer = 0.0;

  constructor(
    public benefits: BenefitsFacade,
    public categories: CategoriesFacade,
    public subCategories: SubCategoriesFacade
  ) {
  }
}
