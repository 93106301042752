import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { currentProviderId } from '~features/registration/registration.selectors';
import {
  vendorChangedForShoppingList,
  viewShoppingList,
} from '~features/shopping-lists/shopping-lists.actions';
import { loadAllVendorInventoryItems, loadAllVendorInventoryItemsIfNecessary } from './vendor-inventory-items.state';
import { unavailableItemsCanceled } from '~features/shopping-lists/shopping-list-items/shopping-list-items.actions';

@Injectable()
export class VendorInventoryItemsEffects {
  constructor(private readonly actions$: Actions, private readonly store: Store) {}

  loadInventoryItemsOnVendorChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vendorChangedForShoppingList),
      withLatestFrom(this.store.select(currentProviderId)),
      map(([{ vendor }, authorityId]) =>
        loadAllVendorInventoryItems({
          criteria: {
            parents: {
              authorities: authorityId,
              vendors: vendor.entryId,
            },
          },
        })
      )
    )
  );

  loadInventoryItemsOnVendorChangeCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unavailableItemsCanceled),
      withLatestFrom(this.store.select(currentProviderId)),
      map(([{ previousVendorId }, authorityId]) =>
        loadAllVendorInventoryItems({
          criteria: {
            parents: {
              authorities: authorityId,
              vendors: previousVendorId,
            },
          },
        })
      )
    )
  );

  loadInventoryItemsOnShoppingListView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewShoppingList),
      filter(({ shoppingList }) => !!shoppingList.vendorId),
      withLatestFrom(this.store.select(currentProviderId)),
      map(([{ shoppingList }, authorityId]) =>
        loadAllVendorInventoryItems({
          criteria: {
            parents: {
              authorities: authorityId,
              vendors: shoppingList.vendorId,
            },
          },
        })
      )
    )
  );
}
