import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { LogService } from '~core/services/log.service';

import { appInitialized, softReset, hardReset, webLinkOpened, appPreInitialized } from '../app/app.actions';
import { isInitialized, mapsApiLoaded } from '../app/app.reducer';
import { State } from '../state';
import { networkStatus } from './app.reducer';

const TAGS = ['Facade', 'App'];

@Injectable()
export class AppFacade {
  mapsApiLoaded$ = this.store.select(mapsApiLoaded);
  networkStatus$ = this.store.select(networkStatus);

  get isInitialized$(): Observable<boolean> {
    return this.store.pipe(select(isInitialized));
  }

  constructor(private store: Store<State>, private log: LogService) {
  }

  initialize() {
    this.log.debug(TAGS, 'Dispatching: App pre-initialization...');
    this.store.dispatch(appPreInitialized());
    this.log.info(TAGS, 'Dispatched: App pre-initialization.');
  }

  softReset() {
    this.log.debug(TAGS, 'Dispatching: App soft reset...');
    this.store.dispatch(softReset());
  }

  hardReset() {
    this.log.debug(TAGS, 'Dispatching: App hard reset...');
    this.store.dispatch(hardReset());
  }

  openLink(linkUrl: string) {
    this.log.debug(TAGS, 'Dispatching: Open web link...');
    this.store.dispatch(webLinkOpened({linkUrl}));
  }
}
