import { Pipe, PipeTransform } from '@angular/core';
import * as fuzzy from 'fuzzy';

@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {
  transform(values: any[], criteria: string, props: string[]): any {
    // const compare = new RegExp(`.*${criteria}.*`, 'ig').compile();
    return !!criteria ? values.filter(value =>
      props.some(prop =>
        !!value[prop] &&
        fuzzy.test(criteria, value[prop].toString())
        // compare.test(value[prop].toString())
      )
    ) : values;
  }
}
