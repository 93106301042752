import { Pipe, PipeTransform } from '@angular/core';
import { Vendor } from '~features/vendors/models';
import { Position } from '@capacitor/geolocation';

@Pipe({name: 'vendorAsGeoposition'})
export class VendorAsGeopositionPipe implements PipeTransform {
  transform(vendor: Vendor): Position {
    return {
      timestamp: Date.now(),
      coords: {
        latitude: vendor.latitude,
        longitude: vendor.longitude
      }
    } as Position;
  }
}
