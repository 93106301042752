<ion-card data-cy="missing-data">
  <ion-card-header>
    <ion-card-subtitle>{{ 'common.missingData' | transloco }}</ion-card-subtitle>
    <ion-card-title>
      {{ title }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col>
          <p>{{ info }}</p>
        </ion-col>
      </ion-row>
      <ion-row class="pt-20">
        <ion-col>
          <ion-button expand="block" (click)="retry.emit()">
            <ion-icon name="refresh" class="pr-5"></ion-icon>
            {{'buttons.retry' | transloco}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
