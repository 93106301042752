import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SubCategory } from './models';
import { State } from '../state';
import {
  subCategoriesLoadingProgress,
  subCategoriesLoadError,
  subCategoriesHasLoadError,
  SubCategoryFacadeBase, hasSubCategories
} from './subcategories.state';

@Injectable()
export class SubCategoriesFacade extends SubCategoryFacadeBase {
  get loadingProgress$(): Observable<number> {
    return this.store.select(subCategoriesLoadingProgress);
  }

  get loadError$(): Observable<Error | any> {
    return this.store.select(subCategoriesLoadError);
  }

  get hasLoadError$(): Observable<Error | any> {
    return this.store.select(subCategoriesHasLoadError);
  }

  get hasSubCategories$(): Observable<boolean> {
    return this.store.select(hasSubCategories);
  }

  constructor(private store: Store<State>) {
    super(SubCategory, store);
  }
}
