import { Action, createAction, createReducer, createSelector, on, props } from '@ngrx/store';
import { MicroBlog } from '~features/micro-blogs/models/micro-blog';
import { State } from '~features/state';

export const latestMicroBlogsLoaded = createAction(
  '[Micro Blog] Latest Loaded',
  props<{ microBlog: MicroBlog }>()
);

export const noMicroBlogFound = createAction(
  '[Micro Blogs] Nothing Found'
);

export const microBlogViewed = createAction(
  '[Micro Blogs] Viewed',
  props<{ microBlog: MicroBlog }>()
);

export interface IMicroBlogState {
  blogs: {
    entities: {
      [key: string]: MicroBlog;
    };
    ids: string[];
  };
  currentBlogId?: string;
}

export const initialMicroBlogState: IMicroBlogState = {
  blogs: {
    entities: {},
    ids: []
  }
};

const reduce = createReducer(
  initialMicroBlogState,
  on(latestMicroBlogsLoaded, (state, {microBlog}) => ({
    ...state,
    blogs: {
      entities: {
        ...state.blogs.entities,
        // eslint-disable-next-line no-underscore-dangle
        [microBlog._id]: microBlog
      },
      // eslint-disable-next-line no-underscore-dangle
      ids: [microBlog._id, ...state.blogs.ids.filter(id => id !== microBlog._id)]
    },
    // eslint-disable-next-line no-underscore-dangle
    currentBlogId: microBlog._id
  }))
);

export function microBlogReducer(state: IMicroBlogState, action: Action): IMicroBlogState {
  return reduce(state, action);
}

export const getMicroBlogState = (state: State) =>
  state.microBlog;

export const mapToCurrentBlog = (state: IMicroBlogState) =>
  (state.blogs && state.currentBlogId) ? state.blogs.entities[state.currentBlogId] : null;

export const currentBlog = createSelector(
  getMicroBlogState,
  mapToCurrentBlog
);
