import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { MobileOrderItemSubstitution } from './substitution.entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';

export interface SubstitutionsState extends IEntityState<MobileOrderItemSubstitution> {
  quantityEditId?: string;
}

export const {
  actions: {
    createSuccess: createSubstitutionSuccess,
    updateSuccess: updateSubstitutionSuccess,
    updateManySuccess: updateManySubstitutionsSuccess,
    deleteByKeySuccess: deleteSubstitutionByKeySuccess,
    deleteManySuccess: deleteManySubstitutionsSuccess
  },
  selectors: {
    selectAll: allSubstitutions,
    selectEntities: substitutionEntities
  },
  initialState: initialSubstitutionsState,
  facade: SubstitutionsFacadeBase
} = buildState(MobileOrderItemSubstitution, { quantityEditId: null } as SubstitutionsState);

export const substitutionsReducer = createReducer(
  initialSubstitutionsState,
);

export const SUBSTITUTIONS_FEATURE_KEY = 'mobileOrderItemSubstitution';

export const substitutionsState = createFeatureSelector<SubstitutionsState>(SUBSTITUTIONS_FEATURE_KEY);
