<!--Container to show in content if modal, or without if popover-->
<ion-content *ngIf="!popover; else Template">
  <ng-container *ngTemplateOutlet="Template"></ng-container>
</ion-content>

<ng-template #Template>
  <img *ngIf="headerImage" src="{{ '/assets/images/permissions/' + headerImage }}"/>
  <ion-grid>
    <ion-row *ngIf="icon">
      <ion-col class="ion-text-center">
        <ion-icon [name]="icon" style="font-size: 64px;"></ion-icon>
      </ion-col>
    </ion-row>
    <ion-row class="ion-padding-vertical">
      <ion-col>
        <ion-label>
          <ion-text [color]="textColor">{{ message }}</ion-text>
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button
                [color]="buttonColor"
                size="large"
                strong="true"
                expand="block"
                (click)="popover ? popovers.dismiss() : modal.dismiss()"
        >
          {{ 'explain-permissions.continueButton' | transloco }}
          <fa-icon icon="arrow-right" class="pl-5"></fa-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>
