import { HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebCacheConfig, WebCacheConfigGroup } from '~core/interceptors/CachingInterceptor';
import { WEB_CACHE_CONFIG } from '~core/interceptors/config-token';
import { ConfigService } from '~core/config/config.service';

export interface WebCacheEntry {
  group: string;
  url: string;
  body: any;
  cachedAt: number;
  expiresAt?: number;
}

@Injectable({providedIn: 'root'})
export class WebCacheService {
  private config$$ = new BehaviorSubject<WebCacheConfig>(null);
  public configGroups$ = this.config$$.asObservable().pipe(
    map(config => Object.keys(config).map(key => ({
      key, config: config[key]
    })))
  );

  constructor(private appConfig: ConfigService, private storage: Storage, @Inject(WEB_CACHE_CONFIG) @Optional() config: WebCacheConfig) {
    this.config$$.next(config);
  }

  async purgeAll() {
    const keys = await this.storage.keys();
    keys
      .filter(key => key.startsWith(`${this.appConfig.webCacheKey}::`))
      .forEach(key => this.storage.remove(key));
  }

  async purge(groupName: string) {
    const keys = await this.storage.keys();
    keys
      .filter(key => key.startsWith(`${this.appConfig.webCacheKey}::${groupName}`))
      .forEach(key => this.storage.remove(key));
  }

  async get(req: HttpRequest<any>, config: WebCacheConfigGroup): Promise<WebCacheEntry> {
    const url = config.ignoreQueryString ? req.url : req.urlWithParams;
    const entry = await this.storage.get(`${this.appConfig.webCacheKey}::${config.name}:${url}`);
    return entry; // TODO: Handle expiration dates? Cache eviction strategies?
  }

  async set(req: HttpRequest<any>, config: WebCacheConfigGroup, body: any): Promise<WebCacheEntry> {
    const url = config.ignoreQueryString ? req.url : req.urlWithParams;
    const entry = {
      group: config.name,
      url,
      body,
      cachedAt: Date.now(),
      expiresAt: config.maxAge ? Date.now() + (config.maxAge * 1000) : undefined
    };
    await this.storage.set(`${this.appConfig.webCacheKey}::${config.name}:${url}`, entry);
    return entry;
  }
}
