import { Component, OnInit } from '@angular/core';
import { LogService } from '~core/services/log.service';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { HomeFacade } from '~features/home/home.facade';
import { OfficesFacade } from '~features/offices/offices.facade';
import { OfficesService } from '../offices.service';
import { AppFacade } from '~features/app/app.facade';

const TAGS = ['Tab', 'Offices', 'List'];

@Component({
  selector: 'wic-offices-list-tab',
  templateUrl: './offices-list-tab.component.html',
  styleUrls: ['./offices-list-tab.component.scss'],
})
export class OfficesListTabComponent implements OnInit {
  constructor(
    public app: AppFacade,
    public home: HomeFacade,
    public offices: OfficesFacade,
    public officesSvc: OfficesService,
    private analytics: AnalyticsFacade,
    private log: LogService) {
    this.log.trace(TAGS, 'Constructing Offices List Tab');
  }

  ngOnInit() {
    this.analytics.pageView('offices-list-tab');
    this.offices.loadIfNeeded();
  }
}
