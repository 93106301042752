<ion-header mode="ios">
  <ion-toolbar [color]="product.statusProductStatus$ | async | statusToColor" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>Product Check</h3>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="home.goHome()" data-cy="home-button">
        <ion-icon name="home"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen #content>
  <wic-item-status
      [authority]="registration.currentProvider$ | async"
      [aplMessage]="aplMessages.current$ | async"
      [product]="product.statusProduct$ | async"
      [benefit]="benefit$ | async"
      [config]="subCategory.current$ | async"
      [currentLanguage]="settings.selectedLanguage$ | async"
      (cantBuyThis)="missingProduct.reportProduct($event)"
      (messageViewed)="aplMessages.view($event)"
      (warningViewed)="upc.warnNoWicProduce()"
      (addedToCalc)="calculators.addToCalculator($event)"
      (viewCalc)="calculators.viewCalculator($event)">
  </wic-item-status>

  <wic-product-list
      *ngIf="product.statusProductIsKnown$ | async"
      [hideTitle]="true"
      [benefit]="benefit$ | async"
      [products]="product.currentProducts$ | async"
      (selected)="product.checkProduct($event); content.scrollToTop()">
  </wic-product-list>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>

<ion-footer translucent mode="ios">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col *ngIf="registration.isProvider$ | async">
          <ion-button expand="block" class="btn-deep-spring-bud" (click)="benefits.viewAll()" data-cy="benefits-button">
            <fa-icon icon="list-ul" [fixedWidth]="true"></fa-icon>
            <span class="ion-text-uppercase">{{'buttons.myBenefits' | transloco}}</span>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button expand="block" class="btn-maroon" (click)="upc.scanBarcode()" data-cy="search-upc-button">
            <ion-icon icon="barcode"></ion-icon>
            <span class="ion-text-uppercase">{{'buttons.scanBarcode' | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
