<ion-list class="pt-10" *ngIf="!!authorities && !!authorities.length">
  <ion-item *ngFor="let authority of authorities"
            (click)="selected.emit(authority)" data-cy="authority">
    <ion-thumbnail slot="start">
      <wic-authority-logo [authority]="authority">
      </wic-authority-logo>
    </ion-thumbnail>
    <h4>{{authority.name | transloco}}</h4>
  </ion-item>
</ion-list>
