import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { isProduct } from '~features/products/models';
import { SubCatConfig } from '~features/subcat-configs/models';
import { SubCatConfigFacade } from '~features/subcat-configs/subcat-config.facade';

export interface SimpleProduct {
  itemNumber: string;
  categoryId: number;
  subCategoryId: number;
}

@Component({
  selector: 'wic-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss'],
})
export class ProductImageComponent {
  currentProduct: Partial<SimpleProduct>;

  @Input() set product(product: Partial<SimpleProduct>) {
    if (this.currentProduct?.itemNumber !== product.itemNumber) {
      this.config$ = null;
    }

    this.currentProduct = product;
  }
  get product() {
    return this.currentProduct;
  }

  @Input() size = '';

  errorUrl = null;
  config$: Observable<SubCatConfig>;

  constructor(private subCatConfig: SubCatConfigFacade) {}

  productImageError(ev) {
    console.warn(ev);
    this.config$ = isProduct(this.product) ? this.subCatConfig.findForAny(this.product) : null;
  }
}
