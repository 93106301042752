import { Injectable } from '@angular/core';
import { ActionPerformed, LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import { addSeconds } from 'date-fns';
import { ReplaySubject } from 'rxjs';
import { LogService } from './log.service';

const TAGS = ['Service', 'Notifications', 'Local'];

@Injectable()
export class LocalNotificationsService {
  // Emits when a local notification is received in app
  localNotificationReceived$$ = new ReplaySubject<LocalNotificationSchema>();
  // Emits when a local notification is tapped on
  localNotificationActionPerformed$$ = new ReplaySubject<ActionPerformed>();

  constructor(private log: LogService) {
    this.log.trace(TAGS, 'Enabling Local');

    LocalNotifications.checkPermissions().then((val) => this.log.trace(TAGS, `Permission status: ${val.display}`));

    // Show us the notification payload if the app is open on our device
    LocalNotifications.addListener('localNotificationReceived', (notification: LocalNotificationSchema) =>
      this.localNotificationReceived$$.next(notification)
    );

    // Method called when tapping on a notification
    LocalNotifications.addListener(
      'localNotificationActionPerformed',
      (notification: ActionPerformed) => this.localNotificationActionPerformed$$.next(notification)
    );
  }

  schedule(...notifications: LocalNotificationSchema[]) {
    return LocalNotifications.schedule({
      notifications,
    });
  }

  cancel(...ids: number[]) {
    return LocalNotifications.cancel({ notifications: ids.map(id => ({ id })) });
  }

  async getPending() {
    const pending = await LocalNotifications.getPending();
    return pending.notifications;
  }

  async hasPermission() {
    const status = await LocalNotifications.checkPermissions();
    return status.display !== 'denied';
  }

  async requestPermission() {
    const status = await LocalNotifications.requestPermissions();
    return status.display !== 'denied';
  }
}
