import {DbTransaction} from '@awesome-cordova-plugins/sqlite';
import {Transaction} from 'dexie';

export interface Comparator<T> {
  $gt?: T;
  $lte?: T;
}

export interface DBQuery<T> {
  /*Selects by strict property matches*/
  where?: Partial<T>;
  /*Selects where value is one of the provided*/
  in?: {
    [P in keyof T]?: T[P][];
  };
  /*Selects where value contains provided*/
  like?: {
    [P in keyof T]?: T[P];
  };
  /*Selects where value meets comparator */
  or?: { [P in keyof T]?: (T[P] | null | Comparator<T[P]>)[] };
}

export abstract class DatabaseService {
  initialize(): Promise<void> {
    throw new Error('Not Implemented In Abstract');
  }

  createTable<T>(entity: new () => T): Promise<any> {
    throw new Error('Not Implemented In Abstract');
  }

  removeTable<T>(entity: new () => T): Promise<any> {
    throw new Error('Not Implemented In Abstract');
  }

  getTableInfo<T>(entity: new () => T): Promise<any> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Inserts multiple entities */
  insert<T>(entity: new () => T, items: T[], tx?: DbTransaction | Transaction): Promise<T[]> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Inserts one entity. */
  insertOne<T>(entity: new () => T, item: T, tx?: DbTransaction | Transaction): Promise<T> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Upserts multiple entities based on PrimaryKey conflicts */
  upsert<T>(entity: new () => T, items: T[], tx?: DbTransaction | Transaction): Promise<T[]> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Upserts one entity based on PrimaryKey conflicts. */
  upsertOne<T>(entity: new () => T, item: T, tx?: DbTransaction | Transaction): Promise<T> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Updates multiple partial entities based on PrimaryKey */
  update<T>(entity: new () => T, items: Partial<T>[], tx?: DbTransaction | Transaction): Promise<T[]> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Updates one partial entity based on PrimaryKey */
  updateOne<T>(entity: new () => T, item: Partial<T>, tx?: DbTransaction | Transaction): Promise<T> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Selects based on QueryObject or returns all if Omitted
  @Param(query) Complex Query object
  *  */
  select<T>(entity: new () => T, query?: DBQuery<T>): Promise<T[]> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Selects a WIC APL Item, Used to patch emergency */
  selectProducts<T>(entity: new () => T, query?: DBQuery<T>): Promise<T[]> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Removes multiple entities based on multiple where statements */
  removeMany<T>(entity: new () => T, wheres: Partial<T>[], tx?: DbTransaction | Transaction): Promise<T[]> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Removes on single where statement */
  remove<T>(entity: new () => T, where: Partial<T>, tx?: DbTransaction | Transaction): Promise<T[]> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Removes all entries for given entity */
  removeAll<T>(entity: new () => T, tx?: DbTransaction | Transaction): Promise<number> {
    throw new Error('Not Implemented In Abstract');
  }

  /* Creates a transaction to run operations inside of. */
  createTransaction<U>(callback: (tx: DbTransaction | Transaction) => Promise<U>): Promise<U> {
    throw new Error('Not Implemented In Abstract');
  }
}
