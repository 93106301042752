import { Entity, Key } from '@briebug/ngrx-auto-entity';


@Entity('MobileOrderItemSubstitution')
export class MobileOrderItemSubstitution {
  @Key id: string;
  mobileOrderItemId: string;

  // TODO: Check Length
  //       Not all of these IDs might be needed
  productId: string;
  itemId: string; // Might be number
  itemNumber: string;

  purchaseQuantity: number;
  uom: string;
  units: number;
  description: string;

  price: number;
  salePrice?: number;
  parWeight?: number;
  costUnit?: string;
}
