import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import {
  changeAppointmentMemberInfo,
  demandAppointmentIdentity,
  requestAppointment,
} from '~features/appointments/appointments.state';
import { currentProvider } from '~features/registration/registration.selectors';
import { State } from '~features/state';
import { AppointmentsService } from './appointments.service';

const TAGS = ['Effects', 'Appointment'];

@Injectable()
export class AppointmentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private appointments: AppointmentsService,
    private log: LogService
  ) {}

  demandAppointmentIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(demandAppointmentIdentity, changeAppointmentMemberInfo),
      tap(() => this.log.debug(TAGS, `Demanding appointment identity...`)),
      withLatestFrom(this.store.select(currentProvider)),
      switchMap(([, authority]) => from(this.appointments.showDemandAppointmentIdentity(authority))),
      filter(request => !!request),
      map(request => ({
        nickname: '[Appointments Card]',
        cardNumber: request.cardNumber,
        householdId: request.hhid ? request.cardNumber : undefined,
      })),
      tap(card => this.log.debug(TAGS, `Appointment identity is ${card.cardNumber}`)),
      map(card => requestAppointment({ card }))
    )
  );
}
