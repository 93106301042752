<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'mobileOrdering.substitutionInfo' | transloco }}</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" (click)="modal.dismiss(null, 'cancel')">
        <ion-icon name="close-circle" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item lines="none">
    <ion-label *ngIf="substitutionType === 0" class="ion-text-wrap">
      <h3>{{ 'mobileOrdering.disabledSubstitutionInfoTitle' | transloco }}</h3>
      <p>{{ 'mobileOrdering.disabledSubstitutionInfoMessage' | transloco }}</p>
    </ion-label>

    <ion-label *ngIf="substitutionType === 2" class="ion-text-wrap">
      <h3>{{ 'mobileOrdering.autoSubstitutionInfoTitle' | transloco }}</h3>
      <p>{{ 'mobileOrdering.autoSubstitutionInfoMessage' | transloco }}</p>
    </ion-label>
  </ion-item>
</ion-content>
