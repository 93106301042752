import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { createFeatureSelector, createReducer } from '@ngrx/store';
import { VendorInventoryItem } from './vendor-inventory-item.entity';

export interface VendorInventoryItemsState extends IEntityState<VendorInventoryItem> {}

export const {
  actions: {
    loadAll: loadAllVendorInventoryItems,
    loadAllSuccess: loadAllVendorInventoryItemsSuccess,
    loadAllIfNecessary: loadAllVendorInventoryItemsIfNecessary,
  },
  selectors: {
    selectEntities: vendorInventoryItemEntities
  },
  initialState: initialVendorInventoryItemsState,
  facade: VendorInventoryItemsFacadeBase
} = buildState(VendorInventoryItem, {} as VendorInventoryItemsState);

export const vendorInventoryItemsReducer = createReducer(initialVendorInventoryItemsState);

export const VENDOR_INVENTORY_ITEMS_FEATURE_KEY = 'vendorInventoryItem';

export const vendorInventoryState = createFeatureSelector<VendorInventoryItemsState>(
  VENDOR_INVENTORY_ITEMS_FEATURE_KEY
);
