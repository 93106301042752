import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { FutureBenefitsPage } from './future-benefits.page';
import { FutureBenefitsHeaderComponent } from './future-benefits-header/future-benefits-header.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ComponentsModule } from '../../components/components.module';
import { TranslocoModule } from '@ngneat/transloco';
import { NoFutureBenefitsWarningComponent } from './no-future-benefits-warning/no-future-benefits-warning.component';

const routes: Routes = [
  {
    path: '',
    component: FutureBenefitsPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslocoModule,
    ComponentsModule,
    FontAwesomeModule
  ],
  declarations: [FutureBenefitsPage, FutureBenefitsHeaderComponent, NoFutureBenefitsWarningComponent]
})
export class FutureBenefitsPageModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faArrowRight, faArrowLeft);
  }
}
