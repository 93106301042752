<swiper-container
  #ionSlides
  pager="true"
  (swiperslidechange)="slideDidChange()"
  *ngIf="microBlog?.deck && microBlog?.type === 'deck'"
>
  <swiper-slide
    *ngFor="let slide of microBlog.deck; let i = index"
    [ngStyle]="{ 'background-color': slide.bgColor || '#f5f5f5' }"
  >
    <div class="blog-container deck">
      <img class="blog-image" [src]="slide.image" />
    </div>
  </swiper-slide>
</swiper-container>
