<ion-header translucent>
  <ion-toolbar color="med-gray">
    <ion-buttons slot="start">
      <ion-button (click)="modal.dismiss(false, 'cancel')">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "settings.scheduleChooserTitle" | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        {{ "Ok" | transloco }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <ion-list data-cy="notification-day-options">
    <ion-list-header>
      {{ "settings.whenHeader" | transloco }}
    </ion-list-header>
    <ion-radio-group [allowEmptySelection]="true" [formControl]="currentDaysControl" (click)="useCustomControl.setValue(false)">
      <ion-item>
        <ion-radio
          slot="start"
          [value]="1"
          (click)="currentDays = 1"
        ></ion-radio>
        <ion-label>
          <h2>{{ "settings.firstOption" | transloco }}</h2>
        </ion-label>
        <ion-label slot="end">
          <div class="alert-cal">
            <div
              class="cal cal-1"
              inlineSVG="/assets/images/settings/calendar.svg"
            ></div>
          </div>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-radio
          slot="start"
          [value]="5"
          (click)="currentDays = 5"
        ></ion-radio>
        <ion-label>
          <h2>{{ "settings.secondOption" | transloco }}</h2>
        </ion-label>
        <ion-label slot="end">
          <div class="alert-cal">
            <div
              class="cal cal-5"
              inlineSVG="/assets/images/settings/calendar.svg"
            ></div>
          </div>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-radio
          slot="start"
          [value]="7"
          (click)="currentDays = 7"
        ></ion-radio>
        <ion-label>
          <h2>{{ "settings.thirdOption" | transloco }}</h2>
        </ion-label>
        <ion-label slot="end">
          <div class="alert-cal">
            <div
              class="cal cal-7"
              inlineSVG="/assets/images/settings/calendar.svg"
            ></div>
          </div>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-radio
          slot="start"
          [value]="14"
          (click)="currentDays = 14"
        ></ion-radio>
        <ion-label>
          <h2>{{ "settings.fourthOption" | transloco }}</h2>
        </ion-label>
        <ion-label slot="end">
          <div class="alert-cal">
            <div
              class="cal cal-14"
              inlineSVG="/assets/images/settings/calendar.svg"
            ></div>
          </div>
        </ion-label>
      </ion-item>
    </ion-radio-group>

    <ion-radio-group [allowEmptySelection]="true" [formControl]="useCustomControl">
      <ion-item (click)="useCustomControl.setValue(true); this.currentDaysControl.setValue(-1)">
        <ion-radio slot="start" [value]="true"></ion-radio>
        <ion-label>
          <h2>{{ "settings.customOption" | transloco }}</h2>
        </ion-label>
        <ion-input type="number" [formControl]="customCurrentDaysControl"></ion-input>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button
            class="btn-green"
            (click)="dismiss()"
            expand="block"
            data-cy="notification-confirm-button"
          >
            <span class="ion-text-uppercase">{{ "Ok" | transloco }}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
