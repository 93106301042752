import { Action, createAction, createReducer, createSelector, on, props } from '@ngrx/store';
import { UPCInfo } from '~features/upc/models';
import { State } from '../state';
import { isUPCAInfo, ParsedUPCInfo, parseUPC } from './upc-util.service';

export const RECENT_UPCS_KEY = 'wic::recent_upcs';

export const scanBarcode = createAction(
  '[UPC] Scan barcode',
  props<{ options?: { showTorchButton: boolean; torchOn: boolean; formats: string } }>()
);

export const initiateBarcodeScan = createAction('[UPC] Initiate Barcode Scan');

export const stopBarcodeScan = createAction('[UPC] Stop Barcode Scan');

export const barcodeScanReceived = createAction('[UPC] Barcode Scan Received', props<{ content: string }>());

export const barcodeQRScanned = createAction('[UPC] QR Code scanned');

export const resetRecentUPCs = createAction('[UPC] Reset recent UPCs');

export const resetRecentUPCsComplete = createAction('[UPC] Recent UPCs reset: Complete');

export const barcodeScanError = createAction('[UPC] Barcode scanning error', props<{ error: Error | any }>());

export const keyEnterUPC = createAction('[UPC] Key enter UPC', props<{ upc: string; upcType?: string }>());

export const restoreRecentUPCs = createAction('[UPC] Restore recent UPCs', props<{ recentUPCs: UPCInfo[] }>());

export const warnNoWicProduce = createAction('[UPC] Warn Produce');

export const navToKeyEnterUPC = createAction('[UPC] Nav to Key Enter UPC');

export interface IUPCState {
  currentUPC?: string;
  parsedUPCInfo?: ParsedUPCInfo;
  lookupMethod?: 'scan' | 'keyEnter' | null;
  recentUPCs?: UPCInfo[];
  scannedUPC?: string;
  isProcessingScan: boolean;
}

export const initialUPCState: IUPCState = {
  isProcessingScan: false,
};

const reducer = createReducer(
  initialUPCState,
  on(initiateBarcodeScan, state => ({ ...state, scannedUPC: null, isProcessingScan: true })),
  on(stopBarcodeScan, state => ({ ...state, scannedUPC: null, isProcessingScan: false })),
  on(barcodeScanReceived, (state, { content }) => ({ ...state, scannedUPC: content, isProcessingScan: false })),
  on(keyEnterUPC, (state, { upc, upcType }) => ({
    ...state,
    currentUPC: upc,
    parsedUPCInfo: parseUPC(upc, upcType),
    isScanning: 0,
    recentUPCs: (state.recentUPCs || []).find(info => info.upc === upc)
      ? [{ upc, dateChecked: Date.now() }, ...state.recentUPCs.filter(recent => recent.upc !== upc)]
      : [{ upc, dateChecked: Date.now() }, ...(state.recentUPCs || []).slice(0, 36)],
  })),
  // eslint-disable-next-line @typescript-eslint/no-shadow
  on(restoreRecentUPCs, (state, { recentUPCs }) => ({
    ...state,
    recentUPCs,
  })),
  on(resetRecentUPCs, state => ({
    ...state,
    recentUPCs: null,
  }))
);

export function upcReducer(state = initialUPCState, action: Action): IUPCState {
  return reducer(state, action);
}

export const getUPCState = (state: State) => state.upc;

export const mapToCurrentUPC = (state: IUPCState): string => state.currentUPC;

export const mapToParsedUPCInfo = (state: IUPCState): ParsedUPCInfo => state.parsedUPCInfo;

export const mapToScannedUPC = (state: IUPCState): string => state.scannedUPC;

export const mapToRecentUPCs = (state: IUPCState): UPCInfo[] => state.recentUPCs;

export const currentUPC = createSelector(getUPCState, mapToCurrentUPC);

export const parsedUPCInfo = createSelector(getUPCState, mapToParsedUPCInfo);

export const scannedUPC = createSelector(getUPCState, mapToScannedUPC);

export const recentUPCs = createSelector(getUPCState, mapToRecentUPCs);

export const parsedUPC = createSelector(parsedUPCInfo, info => info?.upc);
export const isParsedUPCType2A = createSelector(
  parsedUPCInfo,
  info => info && isUPCAInfo(info) && info.subType === 'Type2'
);
