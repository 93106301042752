import { Pipe, PipeTransform } from '@angular/core';
import { LogEntry } from '~core/services/log.service';

@Pipe({name: 'filterByTags'})
export class FilterByTagsPipe implements PipeTransform {
  transform(entries: LogEntry[], tags: string[]): LogEntry[] {
    return (!!tags && !!tags.length)
      ? entries.filter(entry =>
        tags.every(tag => entry.tags.includes(tag))
      )
      : entries;
  }
}
