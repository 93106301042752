import {Injectable} from '@angular/core';
import {format} from 'date-fns';
import Dexie, {Collection, Table, Transaction} from 'dexie';
import {LogService} from '~core/services/log.service';
import {getColumns, getPrimaryKey, getTable} from '~features/database/database.decorators';
import {Product} from './models';
import {Tracking} from './models/tracking';
import {ProductsDbService} from './products-db.service';

const TAGS = ['Service', 'Products', 'Products-IndexedDb'];

@Injectable()
export class ProductsIndexedDBService extends ProductsDbService {
  db: Dexie;
  config = {
    name: 'wicshopper.db',
  };
  tables = {};

  constructor(private log: LogService) {
    super();
  }

  override initialize(): Promise<void> {
    this.db = new Dexie(this.config.name);
    return Promise.resolve();
  }

  override async createTable<T>(entity: new () => T): Promise<any> {
    this.log.debug(TAGS, `Creating table...`);
    const table = getTable(entity);
    this.log.debug(TAGS, `Creating table ${table}:`);
    const columns = getColumns(entity);
    this.log.debug(TAGS, `With columns ${columns}:`);
    const pkColumnName = getPrimaryKey(entity);
    this.log.debug(TAGS, `Having pk ${pkColumnName}:`);
    this.tables = {...this.tables, [table]: Object.keys(columns).join()};
    this.log.info(TAGS, 'Total Tables', this.tables);
    return this.db.version(1).stores(this.tables);
  }

  override async removeTable<T>(entity: new () => T): Promise<any> {
    this.log.debug(TAGS, `Removing table...`);
    const table = getTable(entity);
    this.log.debug(TAGS, `Removing table ${table}:`);
    const remaining = this.tables[table] && delete this.tables[table];
    console.log(remaining);
    return this.db.version(1).stores(this.tables).upgrade((tx) => {
      this.log.debug(TAGS, 'Remaining Stores', tx.storeNames);
    });
  }

  override async getTableInfo<T>(entity: new () => T): Promise<any> {
    return {
      // eslint-disable-next-line no-underscore-dangle
      tables: Object.keys(this.db._allTables),
      // eslint-disable-next-line no-underscore-dangle
      dbSchema: this.db._dbSchema,
      name: this.db.name,
    };
  }

  filterIneffectiveProducts(col: Table | Collection, allowed?: number) {
    const now = format(Date.now(), 'yyyyMMdd');
    const time = parseInt(now, 10);
    const filtered = col
      .filter((item: Product) => +item.effectiveDate <= time || !item.effectiveDate || item.effectiveDate === '00000000')
      .filter((item: Product) => +item.endDate >= time || !item.endDate || item.endDate === '00000000');
    return allowed === 0 || allowed > 0
      ? filtered.filter((item: Product) => (allowed / (item.size / item.packageSize)) >= 1)
      : filtered;
  }

  override lookup(upc: string) {
    return this.filterIneffectiveProducts(this.getTable(Product)).filter((item: Product) =>
      item.itemNumber.includes(upc)
    ).toArray();
  }

  override async selectTotalCount(categoryId: number, subCategoryId: number): Promise<number> {
    return this.filterIneffectiveProducts(this.getTable(Product).where({categoryId, subCategoryId})).count();
  }

  override select(categoryId: number, subCategoryId: number, allowed: number): Promise<Product[]> {
    return this.filterIneffectiveProducts(this.getTable(Product).where({categoryId, subCategoryId}), allowed).toArray();
  }

  override selectAllTotalCount(): Promise<number> {
    return this.filterIneffectiveProducts(this.getTable(Product)).count();
  }

  override selectAll(): Promise<Product[]> {
    return this.filterIneffectiveProducts(this.getTable(Product)).toArray();
  }

  override updateProducts(categoryId: number, subCategoryId: number, products: Product[]): Promise<void> {
    this.log.trace(TAGS, `Creating Transaction To Update Products For: ${categoryId}:${subCategoryId}`);

    const deleted = products.filter(product => !!product.deletedAt);
    this.log.trace(TAGS, `Deleting These Items: `, deleted);

    const added = products.filter(product => !product.updatedAt && !product.deletedAt);
    this.log.trace(TAGS, `Adding Items For: `, added);

    const updated = products.filter(product => !!product.updatedAt && !product.deletedAt);
    this.log.trace(TAGS, `Updating Items For: `, updated);

    return this.createTransaction(async (tx) => {
      await this.remove(deleted, tx);
      await this.upsert(added, tx);
      await this.upsert(updated, tx);

      await this.track(categoryId, subCategoryId, tx);
    });
  }

  private track(categoryId: number, subCategoryId: number, tx?: Transaction, date = new Date()) {
    return this.getTable(Tracking, tx).put({
      id: ((categoryId * 1000) + subCategoryId) + '',
      lastLoadDate: date.toISOString(),
    });
  }

  override async populateProducts(categoryId: number, subCategoryId: number, products: Product[]) {
    return this.createTransaction(async (tx) => {
      await this.remove(products, tx);
      await this.upsert(products, tx);

      await this.track(categoryId, subCategoryId, tx);
    });
  }

  override async clearProducts(): Promise<number> {
    const count = await this.getTable(Product).count();
    await this.getTable(Product).clear();
    return count;
  }

  override async clearProductTracking(): Promise<number> {
    const count = await this.getTable(Tracking).count();
    await this.getTable(Tracking).clear();
    return count;
  }

  override async getProductUpdate(categoryId: number, subCategoryId: number): Promise<Tracking> {
    return this.getTable(Tracking).where({id: (categoryId * 1000 + subCategoryId).toString()}).first();
  }

  override async setProductUpdateTime(categoryId: number, subCategoryId: number, date: Date): Promise<Tracking> {
    return this.track(categoryId, subCategoryId, null, date);
  }

  override async getAllProductUpdates(): Promise<Tracking[]> {
    return this.getTable(Tracking).orderBy('lastLoadDate').toArray();
  }

  remove(products: Product[], tx: Transaction) {
    return this.getTable(Product, tx).bulkDelete(products.map(p => p.id));
  }

  upsert(products: Product[], tx: Transaction) {
    return this.getTable(Product, tx).bulkPut(products);
  }

  getTable<T>(entity: new () => T, tx?: Transaction) {
    return tx ? tx.table(getTable(entity)) : this.db.table(getTable(entity));
  }

  createTransaction<U>(callback: (tx: Transaction) => Promise<U> | U): Promise<U> {
    return this.db.transaction('readwrite', this.db.tables.map(t => t.name), (tx) => callback(tx));
  }
}
