<ion-header mode="ios">
  <ion-toolbar mode="ios" color="tufts-blue-shaded">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>{{'shoppingLists.contentTitle' | transloco}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <wic-shopping-list-group
      [groupLabel]="'shoppingLists.contentTitle' | transloco"
      [shoppingLists]="shoppingLists.standardShoppingLists$ | async"
      (selected)="shoppingLists.viewShoppingList($event)"
      (removed)="shoppingLists.removeShoppingList($event)"
    ></wic-shopping-list-group>

    <wic-shopping-list-group
      [groupLabel]="'mobileOrdering.mobileOrdersList' | transloco"
      [shoppingLists]="shoppingLists.mobileOrderShoppingLists$ | async"
      (selected)="shoppingLists.viewShoppingList($event)"
      (removed)="shoppingLists.removeShoppingList($event)"
    ></wic-shopping-list-group>
  </ion-list>


  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button (click)="newListPopover.present()" color="success">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-popover #newListPopover class="new-shopping-list-entry">
    <ng-template>
      <ion-content [scrollY]="false">
        <ion-card-header>
          <ion-card-subtitle>{{ 'shoppingLists.addShoppingListTitle' | transloco }}</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <ion-item mode="md" fill="outline">
            <ion-label position="floating">{{ 'shoppingLists.addShoppingListLabel' | transloco }}</ion-label>
            <ion-input #nameInput [placeholder]="'shoppingLists.addShoppingListPlaceholder' | transloco"></ion-input>
          </ion-item>

          <ion-item lines="none" class="ion-margin-top">
            <ion-checkbox #mobileOrderCheckbox slot="start"></ion-checkbox>
            <ion-label>{{ 'shoppingLists.isMobileOrder' | transloco }}</ion-label>
          </ion-item>

          <div style="display: flex;" class="ion-justify-content-between ion-margin-top">
            <ion-button shape="round" color="danger" (click)="newListPopover.dismiss()">{{ 'Cancel' | transloco }}</ion-button>
            <ion-button shape="round" (click)="newListPopover.dismiss(); shoppingLists.addShoppingList(nameInput.value, mobileOrderCheckbox.checked)">{{ 'Ok' | transloco }}</ion-button>
          </div>
        </ion-card-content>
      </ion-content>
    </ng-template>
  </ion-popover>
</ion-content>
