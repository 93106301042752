import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faPrescriptionBottle,
  faShoppingBasket,
  faShoppingCart,
  faTractor,
} from '@fortawesome/free-solid-svg-icons';
import { from, Observable, of, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { getGroupCodeColor } from './group-coded-color.pipe';
import { ConfigService } from '~core/config/config.service';

const GROUP_CODE_TO_ICON_MAP_DEF = {
  0: faShoppingCart,
  1: faShoppingCart,
  2: faShoppingCart,
  3: faTractor,
  4: faPrescriptionBottle,
  5: faShoppingBasket,
};

const GROUP_CODE_TO_ICON_MAP = {
  0: 'shopping-cart',
  1: 'shopping-cart',
  2: 'shopping-cart',
  3: 'tractor',
  4: 'prescription-bottle',
  5: 'shopping-basket',
};

const GROUP_CODES_WITH_ASSET_ICONS = [6, 7, 101, 102];

const GROUP_CODE_ASSET_ICON_MAP = {
  6: 'delivery',
  7: 'star',
  101: 'stand',
  102: 'bee',
};

const getIconData = (groupCode: number, rawSvg = false) => {
  if (rawSvg) {
    const icon: IconDefinition =
      GROUP_CODE_TO_ICON_MAP_DEF[groupCode] || GROUP_CODE_TO_ICON_MAP_DEF[0];
    return icon.icon[4];
  } else {
    return GROUP_CODE_TO_ICON_MAP[groupCode] || GROUP_CODE_TO_ICON_MAP[0];
  }
};

@Pipe({ name: 'groupCodedIcon' })
export class GroupCodedIconPipe implements PipeTransform {
  constructor(private config: ConfigService, private http: HttpClient) {
  }

  transform(groupCode: number): string;
  transform(groupCode: number, rawSvg: true): Observable<string | google.maps.Symbol | google.maps.Icon>;
  transform(groupCode: number, rawSvg = false): string | Observable<string | google.maps.Symbol | google.maps.Icon> {
    if (!rawSvg) {
      return GROUP_CODES_WITH_ASSET_ICONS.includes(groupCode)
        ? `/assets/images/vendors/${GROUP_CODE_ASSET_ICON_MAP[groupCode]}.svg`
        : getIconData(groupCode, rawSvg);
    }
    return of(GROUP_CODES_WITH_ASSET_ICONS.includes(groupCode)).pipe(
      switchMap(isLocal =>
        isLocal
          ? this.http
              .get(`/assets/images/vendors/${GROUP_CODE_ASSET_ICON_MAP[groupCode]}.svg`, {
                responseType: 'text',
              }).pipe(
                map(res => 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(res.replace('currentColor', getGroupCodeColor(groupCode, this.config.vendorColors)))),
            )
          : of({
            fillColor: getGroupCodeColor(groupCode, this.config.vendorColors),
            fillOpacity: 1,
            scale: 0.06,
            path: getIconData(groupCode, rawSvg),
          })
      )
    );
  }
}
