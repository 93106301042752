<ion-header>
  <ion-toolbar color="offices"></ion-toolbar>
</ion-header>

<ion-content>
  <wic-custom-error-toolbar *ngIf="((app.networkStatus$ | async) === false && (offices.hasFiltered$ | async) === false)"
    [title]="('offices.noNetworkTitle' | transloco)" 
    [message]="('offices.noNetworkNoCacheMessage' | transloco)"
  >  
  </wic-custom-error-toolbar>

  <wic-custom-error-toolbar *ngIf="((app.networkStatus$ | async) === false && offices.hasFiltered$ | async)"
    [title]="('offices.noNetworkTitle' | transloco)" 
    [message]="('offices.noNetworkMessage' | transloco)"
  >  
  </wic-custom-error-toolbar>

  <ion-list *ngIf="officesSvc.isLoading$ | async; else list" data-cy="loading">
    <ion-item>
      <ion-label><h2>{{'offices.loadingLabel' | transloco}}</h2></ion-label>
      <ion-spinner slot="end" name="dots"></ion-spinner>
    </ion-item>
  </ion-list>

  <ng-template #list>
    <wic-offices-list
        *ngIf="offices.hasFiltered$ | async; else missing"
        [offices]="offices.filtered$ | async"
        [favorites]="offices.favorites$ | async"
        (selected)="offices.viewDetail($event)"
        (favoriteToggled)="offices.toggleFavorite($event)"
        (directions)="offices.getDirections($event)">
    </wic-offices-list>

    <ng-template #missing>
      <wic-missing-data-warning
          *ngIf="offices.searchCriteria$ | async as searchCriteria"
          [title]="'offices.missingTitle' | transloco"
          [info]="(searchCriteria.anywhere ? 'offices.missingAnywhereInfo' : 'offices.missingRadiusInfo') | transloco:{
            radius: searchCriteria.radius,
            anywhere: searchCriteria.anywhere ? 'true' : 'false',
            favorites: searchCriteria.onlyFavorites ? 'true' : 'false'
          }"
          (retry)="officesSvc.loadIfNecessary(true)">
      </wic-missing-data-warning>
    </ng-template>
  </ng-template>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-searchbar data-cy="search"
        [value]="offices.filterCriteria$ | async"
        [placeholder]="'offices.filterLabel' | transloco"
        (ionChange)="offices.setFilter($event.target.value)">
    </ion-searchbar>
  </ion-toolbar>
</ion-footer>
