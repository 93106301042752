import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, first, map, tap, withLatestFrom } from 'rxjs/operators';
import { AnalyticsFacade } from '~features/analytics/analytics.facade';
import { AppFacade } from '~features/app/app.facade';
import { BannersFacade } from '~features/banners/banners.facade';
import { EnhancedModeFacade } from '~features/enhanced-mode/enhanced-mode.facade';
import { HomeFacade } from '~features/home/home.facade';
import { HomeButton } from '~features/home/models';
import { RegistrationFacade } from '~features/registration/registration.facade';

@Component({
  selector: 'wic-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit, AfterViewInit {
  menu$: Observable<HomeButton[]>;
  @ViewChild('bannersComponent') bannersComponent: ElementRef;
  @ViewChild(IonContent) ionContent: IonContent;
  observer: IntersectionObserver;
  private topBannersVisible$ = new BehaviorSubject(true);
  hideBottom$ = this.topBannersVisible$.pipe(
    debounceTime(100),
    withLatestFrom(this.registration.enableNewHomeScreen$),
    map(([visible, enabled]) => enabled && visible),
  );

  private reloadEvent = null;

  constructor(
    private app: AppFacade,
    public home: HomeFacade,
    public enhancedMode: EnhancedModeFacade,
    public banners: BannersFacade,
    public registration: RegistrationFacade,
    private analytics: AnalyticsFacade
  ) {}

  ngOnInit() {
    this.analytics.pageView('home');
    this.menu$ = this.home.menu$.pipe(
      tap(() => this.reloadEvent && this.reloadEvent.target.complete()),
      tap(() => (this.reloadEvent = null))
    );

    this.app.isInitialized$.pipe(first(is => is)).subscribe(() => {
      this.home.loading();
    });
  }

  ngAfterViewInit() {
    if (this.bannersComponent && this.ionContent) {
      this.observer = new IntersectionObserver(
        (entries, observer) => {
          if (entries.length === 1) {
            this.topBannersVisible$.next(entries[0].isIntersecting);
          }
        },
        {
          root: (this.ionContent as any).el,
          threshold: 0.05,
        }
      );

      this.observer.observe(this.bannersComponent.nativeElement);
    }
  }

  reload(event) {
    this.reloadEvent = event;
    this.home.reload();
  }
}
