import { Injectable, NgZone } from '@angular/core';
import { Clear, EntityActionTypes, LoadAll, ofEntityType } from '@briebug/ngrx-auto-entity';
import { NavController, Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, defer, iif, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { WebCacheService } from '~core/interceptors/web-cache.service';
import { LogService } from '~core/services/log.service';
import { match } from '~jpma-wicshopper-imports-mono/utils/browser';
import { loadBenefitsFailure } from '~features/benefits/benefits.actions';
import { BARCODE_TYPES } from '~features/constants';
import { HardError, SoftError } from '~features/error/error.state';
import { HomeButton } from '~features/home/models';
import {
  cardRegistrationCompleted,
  nonProviderRegistrationCompleted,
  skipCardRegistration
} from '~features/registration/registration.actions';
import { currentProvider, currentProviderId } from '~features/registration/registration.selectors';
import { webLinkOpened } from '../app/app.actions';
import { State } from '../state';
import { scanBarcode } from '../upc/upc.state';
import { launchWicSmartApp, openExternalBrowser } from './../ionic/ionic.actions';
import {
  appointmentsButtonActivated,
  benefitsButtonActivated,
  buttonActivationFailed,
  cardsButtonActivated,
  dynamicButtonActivated,
  functionProtocolButtonActivated,
  homePageLoading,
  homePageReloadCompleting,
  homePageReloading,
  httpsButtonActivated,
  menuReset,
  menuResetComplete,
  navigateForward,
  navigateHome,
  officesButtonActivated,
  predefinedButtonActivated,
  protocolButtonActivated,
  receiptsButtonActivated,
  scanButtonActivated,
  shortCodeButtonActivated,
  unknownButtonActivated,
  upcButtonActivated,
  vendorsButtonActivated,
  webButtonActivated
} from './home.state';
import { MenuButton } from './models';

const TAGS = ['State', 'Home'];
const SHORT_CODE_PREFIX = 'wicshopper_';
const PAGE_LINK_PROTOCOL = 'wic:/';
const FUNCTION_PROTOCOL = 'wic$:/';
const ESCALATE_PROTOCOL = '^';

const SHORT_CODE_MAP = {
  wicshopper_cvv_calc: '/calculators/cvv',
  wicshopper_oz_calc: '/calculators/cereal',
  wicshopper_cereal_calc: '/calculators/cereal',
  wicshopper_grain_all_calc: '/calculators/grains',
  wicshopper_grain_calc: '/calculators/grains',
};

export interface Options {
  [name: string]: string;
}

export const addOption = (options: Options, [key, value]: string[]): Options =>
  !!key ? {
    ...options,
    [key]: value
  } : options;

export const parseOption = (options: Options, pair: string): Options =>
  pair ? addOption(options, pair.split('=')) : options;

export const mapPairsToOptions = (pairs: string[]): Options =>
  pairs ? pairs.filter(pair => !!pair).reduce(parseOption, {}) : {};

export const parseQueryString = ([, query]: string[]): Options =>
  !!query ? mapPairsToOptions(query.split('&')) : {};

export const parseUrlOptions = (url: string): Options =>
  !!url ? parseQueryString(url.split('?')) : null;

@Injectable()
export class HomeEffects {
  constructor(
    private actions$: Actions,
    private platform: Platform,
    private log: LogService,
    private nav: NavController,
    private webCache: WebCacheService,
    private zone: NgZone,
    private store: Store<State>) {
  }

  homePageLoading$ = createEffect(
    () => this.actions$.pipe(
      ofType(homePageLoading),
      tap(() => this.log.debug(TAGS, 'Home page is loading...')),
      withLatestFrom(this.store.select(currentProvider)),
      filter(([, authority]) => !!authority),
      tap(([, authority]) => this.log.info(TAGS, 'Loading menu for authority ' + authority.name)),
      map(([, {id}]) => new LoadAll(MenuButton, {
        parents: {
          authorities: id
        },
        version: 3
      }))
    )
  );

  menuReset$ = createEffect(
    () => this.actions$.pipe(
      ofType(menuReset),
      tap(() => this.log.info(TAGS, 'Menu is being reset. Purging cache and clearing state...')),
      delay(10),
      switchMap(() => this.webCache.purge('menu')),
      map(() => new Clear(MenuButton))
    )
  );

  completeMenuReset$ = createEffect(
    () => combineLatest([
      this.actions$.pipe(ofType(menuReset), map(() => new Date())),
      this.actions$.pipe(ofEntityType(MenuButton, EntityActionTypes.Clear), map(() => new Date()))
    ]).pipe(
      filter(([d1, d2]) => d2 > d1),
      tap(() => this.log.info(TAGS, 'Menu has been reset.')),
      map(() => menuResetComplete())
    )
  );

  homePageReloadClearMenuCache$ = createEffect(
    () => this.actions$.pipe(
      ofType(homePageReloading),
      tap(() => this.log.debug(TAGS, 'Clearing home page menu cache...')),
      tap(() => this.webCache.purge('menu')),
      map(() => homePageReloadCompleting())
    )
  );

  reloadHomeMenu$ = createEffect(
    () => this.actions$.pipe(
      ofType(homePageReloadCompleting, cardRegistrationCompleted, skipCardRegistration, nonProviderRegistrationCompleted),
      tap(() => this.log.debug(TAGS, 'Home page menu is re-loading...')),
      withLatestFrom(this.store.select(currentProvider)),
      filter(([, authority]) => !!authority),
      tap(([, authority]) => this.log.info(TAGS, 'Loading menu for authority ' + authority.name)),
      map(([, {id}]) =>
        new LoadAll(MenuButton, {
          parents: {
            authorities: id
          },
          version: 3
        })
      )
    )
  );

  dynamicButtonActivated$ = createEffect(
    () => this.actions$.pipe(
      ofType(dynamicButtonActivated),
      filter(({button}) => button.name === 'dynamic'),
      tap(({button}) => this.log.debug(TAGS, `Dynamic button activated: ${button.label}`)),
      switchMap(({button}) =>
        match(
          () => button.linkUrl,
          linkUrl => [() => linkUrl.startsWith(SHORT_CODE_PREFIX), of(shortCodeButtonActivated({button}))],
          linkUrl => [() => linkUrl.startsWith(PAGE_LINK_PROTOCOL), of(protocolButtonActivated({button}))],
          linkUrl => [() => linkUrl.startsWith(ESCALATE_PROTOCOL), of(httpsButtonActivated({button}))],
          linkUrl => [match.anything, of(webButtonActivated({button}))]
        )
      )
    )
  );

  handleHttpsButton$ = createEffect(
    () => this.actions$.pipe(
      ofType(httpsButtonActivated),
      filter(({button}) => !!button.linkUrl),
      tap(({button}) => this.log.debug(TAGS, `Https code button activated: ${button.linkUrl}`)),
      map(({button}) => button.linkUrl.substr(ESCALATE_PROTOCOL.length)),
      tap((url) => this.log.trace(TAGS, `extracted URL: ${url}`)),
      switchMap((url) =>
        of({
          params: url.substr(0, url.indexOf('http')),
          url: url.substr(url.indexOf('http'))
        }).pipe(
          catchError(() => of({
            params: null,
            url
          }))
        )
      ),
      tap(({params, url}) => this.log.trace(TAGS, `Revised extracted URL and params: ${url}, ${params}`)),
      switchMap(({params, url}) =>
        match(
          () => (params || '').toLowerCase(),
          () => ['', of(openExternalBrowser({url}))],
          () => ['(i)', iif(
            () => this.platform.is('ios'),
            of(openExternalBrowser({url})),
            of(webButtonActivated({button: {linkUrl: url} as HomeButton}))
          )],
          () => ['(a)', iif(
            () => this.platform.is('android'),
            of(openExternalBrowser({url})),
            of(webButtonActivated({button: {linkUrl: url} as HomeButton}))
          )],
          () => [match.anything, of(openExternalBrowser({url}))],
        )
      ),
      tap(({type}) => this.log.trace(TAGS, `Action type: ${type}`))
    )
  );

  handleShortCodeButton$ = createEffect(
    () => this.actions$.pipe(
      ofType(shortCodeButtonActivated),
      tap(({button}) => this.log.debug(TAGS, `Short Code button activated: ${button.linkUrl}`)),
      map(({button}) => SHORT_CODE_MAP[button.linkUrl]),
      switchMap(value =>
        match(
          () => ({type: typeof value, value}),
          shortCode => [{type: 'string', value: match.startsWith('/')}, of(navigateForward({route: value}))],
          shortCode => [{type: 'function'}, defer(() => of(value()))], // Assume any function value is action to dispatch
          shortCode => [match.anything, defer(() => of(buttonActivationFailed({
            error: new SoftError(
              'Invalid button configuration.',
              'Button Error',
              'The button you have tapped has an invalid configuration. Please report this error to let JPMA know about this issue.'
            )
          })))]
        )
      )
    )
  );

  handleProtocolButton$ = createEffect(
    () => this.actions$.pipe(
      ofType(protocolButtonActivated),
      tap(({button}) => this.log.debug(TAGS, `Protocol button activated: ${button.linkUrl}`)),
      map(({button}) => button.linkUrl.substr(PAGE_LINK_PROTOCOL.length)),
      map(route => navigateForward({route}))
    )
  );

  handleFunctionButton$ = createEffect(
    () => this.actions$.pipe(
      ofType(functionProtocolButtonActivated),
      tap(({button}) => this.log.debug(TAGS, `Function protocol button activated: ${button.linkUrl}`)),
      map(({button}) => button.linkUrl.substr(FUNCTION_PROTOCOL.length)),
      tap(route => this.log.debug(TAGS, `Function protocol route: ${route}`)),
      switchMap(route =>
        match(
          () => ({
            path: route.substring(0, route.indexOf('?') >= 0 ? route.indexOf('?') : route.length),
            query: route.includes('?')
              ? JSON.parse(`{${route
                .substring(route.indexOf('?'))
                .split('&')
                .map(param => param.split('='))
                .reduce((json, param) => json ? json + `, ${param[0]}: ${param[1]}` : `${param[0]}: ${param[1]}`, '')
              }}`)
              : undefined
          }),
          option => [{path: '/scan-barcode'}, defer(() => of(scanBarcode({
            options: {
              showTorchButton: true,
              torchOn: false,
              formats: BARCODE_TYPES,
              ...(option.query || {})
            }
          })))],
          option => [{path: '/launch-wicsmart'}, defer(() => of(launchWicSmartApp()))],
          option => [{path: '/force-error'}, defer(() => of(loadBenefitsFailure({
            error: new HardError('Test hard error', 'Error', 'This is a test hard error to validate the get support feature.')
          })))],
          option => [match.anything, defer(() => of(unknownButtonActivated()).pipe(tap(() =>
            this.log.warn(TAGS, 'Unknown protocol button activated: ', option)
          )))]
        )
      )
    )
  );

  navigateForward$ = createEffect(
    () => this.actions$.pipe(
      ofType(navigateForward),
      tap(({route}) => this.nav.navigateForward(route))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  openWebLink$ = createEffect(
    () => this.actions$.pipe(
      ofType(webButtonActivated),
      filter(({button}) => !!button.linkUrl),
      tap(({button}) => this.log.debug(TAGS, `Web link activated: ${button.label}`, `Web link: ${button.linkUrl}`)),
      map(({button: {linkUrl}}) => webLinkOpened({linkUrl}))
    )
  );

  matchPredefinedButton$ = createEffect(
    () => this.actions$.pipe(
      ofType(predefinedButtonActivated),
      filter(({button}) => button.name !== 'dynamic'),
      switchMap(({button}) =>
        match(
          () => button.name,
          name => ['scan', of(scanButtonActivated())],
          name => ['upc', of(upcButtonActivated())],
          name => ['appointments', of(appointmentsButtonActivated())],
          name => ['benefits', of(benefitsButtonActivated())],
          name => ['receipts', of(receiptsButtonActivated())],
          name => ['cards', of(cardsButtonActivated())],
          name => ['vendors', of(vendorsButtonActivated())],
          name => ['offices', of(officesButtonActivated())],
        )
      )
    )
  );

  cardsButtonActivated$ = createEffect(
    () => this.actions$.pipe(
      ofType(cardsButtonActivated),
      tap(() => this.log.debug(TAGS, 'Navigating to manage cards...')),
      map(() => this.nav.navigateForward('/manage-cards'))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  appointmentsButtonActivated$ = createEffect(
    () => this.actions$.pipe(
      ofType(appointmentsButtonActivated),
      tap(() => this.log.debug(TAGS, 'Navigating to appointments...')),
      map(() => this.nav.navigateForward('/appointments'))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  scanButtonActivated$ = createEffect(
    () => this.actions$.pipe(
      ofType(scanButtonActivated),
      tap(() => this.log.debug(TAGS, 'Launching barcode scanner...')),
      map(() => scanBarcode({}))
    )
  );

  upcButtonActivatred$ = createEffect(
    () => this.actions$.pipe(
      ofType(upcButtonActivated),
      tap(() => this.log.debug(TAGS, 'Launching upc search...')),
      map(() => this.nav.navigateForward('/search-upc'))
    ),
    {dispatch: false, resubscribeOnError: true}
  );


  benefitsButtonActivated$ = createEffect(
    () => this.actions$.pipe(
      ofType(benefitsButtonActivated),
      tap(() => this.log.debug(TAGS, 'Navigating to benefits...')),
      tap(() => this.nav.navigateForward('/benefits'))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  receiptsButtonActivated$ = createEffect(
    () => this.actions$.pipe(
      ofType(receiptsButtonActivated),
      tap(() => this.log.debug(TAGS, 'Navigating to receipts...')),
      tap(() => this.nav.navigateForward('/receipts'))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  navigateHome$ = createEffect(
    () => this.actions$.pipe(
      ofType(navigateHome),
      tap(() => this.log.debug(TAGS, 'Navigating back to home...')),
      tap(() => this.nav.navigateRoot('/home'))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  vendorsButtonActivated$ = createEffect(
    () => this.actions$.pipe(
      ofType(vendorsButtonActivated),
      tap(() => this.log.debug(TAGS, 'Navigating to vendors...')),
      tap(() => this.nav.navigateForward('/vendors'))
    ),
    {dispatch: false, resubscribeOnError: true}
  );

  officesButtonActivated$ = createEffect(
    () => this.actions$.pipe(
      ofType(officesButtonActivated),
      withLatestFrom(this.store.select(currentProviderId)),
      tap(() => this.log.debug(TAGS, 'Navigating to offices...')),
      tap(([, id]) => this.nav.navigateForward('/offices'))
    ),
    {dispatch: false, resubscribeOnError: true}
  );
}
