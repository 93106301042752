<ion-header>
  <ion-toolbar color="vendors"></ion-toolbar>
</ion-header>

<ion-content>
  <wic-custom-error-toolbar *ngIf="((app.networkStatus$ | async) === false && (vendors.hasFiltered$ | async) === false)"
    [title]="('vendors.noNetworkTitle' | transloco)"
    [message]="('vendors.noNetworkNoCacheMessage' | transloco)"
  >
  </wic-custom-error-toolbar>

  <wic-custom-error-toolbar *ngIf="((app.networkStatus$ | async) === false && vendors.hasFiltered$ | async)"
    [title]="('vendors.noNetworkTitle' | transloco)"
    [message]="('vendors.noNetworkMessage' | transloco)"
  >
  </wic-custom-error-toolbar>

  <ion-list *ngIf="vendors.isLoadingOrEmpty$ | async" data-cy="loading">
    <ion-item>
      <ion-label><h2>{{'vendors.loadingLabel' | transloco}}</h2></ion-label>
      <ion-spinner slot="end" name="dots"></ion-spinner>
    </ion-item>
  </ion-list>

  <ng-container #list *ngIf="(vendors.isLoadingOrEmpty$ | async) === false">
    <wic-vendors-list 
        *ngIf="vendors.hasFiltered$ | async; else missing"
        [vendors]="vendors.filtered$ | async"
        [favorites]="vendors.favorites$ | async"
        (selected)="vendors.viewDetail($event)"
        (favoriteToggled)="vendors.toggleFavorite($event)"
        (directions)="vendors.getDirections($event)">
    </wic-vendors-list>

    <ng-template #missing>
      <wic-missing-data-warning
          *ngIf="vendors.searchCriteria$ | async as searchCriteria"
          [title]="'vendors.missingTitle' | transloco"
          [info]="(searchCriteria.anywhere ? 'vendors.missingAnywhereInfo' : 'vendors.missingRadiusInfo') | transloco:{
            radius: searchCriteria.radius,
            anywhere: searchCriteria.anywhere ? 'true' : 'false',
            favorites: searchCriteria.onlyFavorites ? 'true' : 'false',
            groupcode: searchCriteria.groupCodeFilter
          }"
          (retry)="vendors.loadIfNeeded(true)">
      </wic-missing-data-warning>
    </ng-template>
  </ng-container>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>

<ion-footer>
  <ion-toolbar>
    <ion-searchbar data-cy="search"
        [value]="vendors.filterCriteria$ | async"
        [placeholder]="'vendors.filterLabel' | transloco"
        (ionChange)="vendors.setFilter($event.target.value)">
    </ion-searchbar>
  </ion-toolbar>
</ion-footer>
