import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'APLMessage',
  uriName: 'messages'
})
export class APLMessage {
  @Key authorityId: number;
  @Key categoryId: number;
  @Key subCategoryId: number;
  isActive: boolean;
  onBenefits: boolean;
  onDetail: boolean;
  icon?: string;
  title: string;
  message: string;
}
