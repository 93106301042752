import { Action, createAction, createReducer, createSelector, on, props } from '@ngrx/store';

import { Registration } from '../registration/models';
import { UserSettings } from '../settings/models';
import { State } from '../state';
import { NotificationRegistration } from './models';

export const APPT_RMD_KIND = 'ApptRmd';
export const BENE_EXP_KIND = 'BeneExp';

export const registerNotificationsForCard = createAction(
  '[Notifications] Register Notifications',
  props<{ registration: NotificationRegistration }>()
);

export const notificationBasisChanged = createAction(
  '[Notifications] Basis for notifications changed',
  props<{ settings: UserSettings; registration: Registration; token: string }>()
);

export const apnsTokenAcquired = createAction(
  '[Notifications] APNS token acquired',
  props<{ token: string }>()
);

export const firebaseTokenAcquired = createAction(
  '[Notifications] Firebase token acquired',
  props<{ token: string }>()
);

export const firebaseTokenRefreshed = createAction(
  '[Notifications] Firebase token refreshed',
  props<{ token: string }>()
);

export interface INotificationState {
  token?: string;
}

export const initialNotificationState: INotificationState = {};

const reduce = createReducer(
  initialNotificationState,
  on(firebaseTokenAcquired, firebaseTokenRefreshed, (state, {token}) => ({
    ...state,
    token
  }))
);

export function notificationsReducer(state = initialNotificationState, action: Action): INotificationState {
  return reduce(state, action);
}

export const getNotificationState = (state: State): INotificationState => state.notifications;

export const mapToToken = (state: INotificationState): string => state.token;

export const firebaseToken = createSelector(
  getNotificationState,
  mapToToken
);
