import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { LogService } from '~core/services/log.service';
import { State } from '~features/state';
import { searchCriteria } from '~features/vendors/vendors.selectors';
import { VendorsService } from './vendors.service';
import { applyVendorSearch, configureVendorSearch, vendorDetailViewed } from '~features/vendors/vendors.state';
import { NavController } from '@ionic/angular';
import { returnToHome } from '../../features/vendors/vendors.state';
import { NativePermissionsService } from '~core/services/native-permissions.service';

@Injectable()
export class VendorsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private log: LogService,
    private vendors: VendorsService,
    private navcontroller: NavController,
    private permissions: NativePermissionsService) {
  }

  showSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configureVendorSearch),
      withLatestFrom(
        this.store.select(searchCriteria),
      ),
      exhaustMap(([, currentCriteria]) =>
        from(this.vendors.showSearch(currentCriteria)).pipe(
          map(criteria => applyVendorSearch({criteria})),
          catchError(() => of(applyVendorSearch({criteria: currentCriteria})))
        )
      )
    )
  );

  viewDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(vendorDetailViewed),
        exhaustMap(({ vendor, favorites }) => from(this.vendors.showDetailModal(vendor, favorites)))
      ),
    { dispatch: false }
  );

  returnToHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(returnToHome),
      tap(() => this.permissions.openLocationSettings()),
      tap(() => this.navcontroller.back())
    ), {dispatch: false}
  );

}
