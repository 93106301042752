<ion-header mode="ios">
  <ion-toolbar color="tufts-blue-shaded" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{'item-list.contentTitle' | transloco}}</h3>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="home.goHome()" data-cy="home-button">
        <ion-icon name="home"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar color="tufts-blue-shaded">
    <ion-searchbar
      class="searchbar"
      data-cy="item-list-search-bar"
      placeholder="{{'item-list.searchPlaceholder' | transloco}}"
      [debounce]="700"
      (ionChange)="product.setProductFilter($event.target.value)"
      (ionClear)="product.setProductFilter(null)"
      (ionCancel)="product.setProductFilter(null)"
    >
    </ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <wic-item-list-header data-cy="current-benefit" [benefit]="benefits.currentBenefit$ | async"> </wic-item-list-header>

  <section *ngIf="product.isSelecting$ | async; else currentItems" data-cy="products-loading">
    <ion-list>
      <ion-item>
        <ion-label><h2>{{'item-list.selectingLabel' | transloco}}</h2></ion-label>
        <ion-spinner slot="end" name="dots"></ion-spinner>
      </ion-item>
    </ion-list>
  </section>

  <ng-template #currentItems>
    <wic-item-loading-error *ngIf="product.isSQLiteError$ | async"></wic-item-loading-error>

    <wic-product-list
      *ngIf="(product.isSQLiteError$ | async) === false"
      [hideTitle]="true"
      [showNoProductsWarning]="true"
      [hasFilterCriteria]="product.hasFilterCriteria$ | async"
      [totalCount]="product.totalAvailableCount$ | async"
      [products]="product.currentProductsFiltered$ | async"
      [benefit]="benefits.currentBenefit$ | async"
      (retry)="product.selectProducts($event.categoryId, $event.subCategoryId)"
      (selected)="product.checkProduct($event, true)"
    ></wic-product-list>
  </ng-template>
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>
