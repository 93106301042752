<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <img src="assets/images/img_main.png" alt="{{'common.wicshopper' | transloco}}" height="50">
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <ion-icon icon="finger-print" size="large" color="light"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <img class="header-image" src="/assets/images/img_loading_benefits.png" [alt]="'loadingImageAlt' | transloco">
  <ion-card>
<!--    <ion-card-header class="loading-header">-->
<!--      <div class="loading-clip">-->
<!--        <div class="loading-img">-->
<!--        </div>-->
<!--      </div>-->
<!--    </ion-card-header>-->
    <ion-card-header *ngIf="(benefits.hasCurrentBenefits$ | async) === false">
      <ion-card-subtitle>{{'post-registration.loadingSubtitle' | transloco}}</ion-card-subtitle>
      <ion-card-title>{{'post-registration.loadingTitle' | transloco}}</ion-card-title>
    </ion-card-header>

    <ion-card-header *ngIf="(benefits.hasCurrentBenefits$ | async) && (categories.hasCategories$ | async) && (subCategories.hasSubCategories$ | async)">
      <ion-card-subtitle>{{'post-registration.loadedSubtitle' | transloco}}</ion-card-subtitle>
      <ion-card-title>{{'post-registration.loadedTitle' | transloco}}</ion-card-title>
    </ion-card-header>
  </ion-card>

  <wic-benefits-loading-progress [findBuffer]="findBuffer">
  </wic-benefits-loading-progress>

  <!--  <ion-list>-->
  <!--    <ion-list-header>-->
  <!--      <ion-label><span>Loading</span> Breads</ion-label>-->
  <!--    </ion-list-header>-->
  <!--    <ion-progress-bar value="0.27"></ion-progress-bar>-->
  <!--  </ion-list>-->
  <!--  <ion-list>-->
  <!--    <ion-list-header>-->
  <!--      <ion-label><span>Loading</span> Cereals</ion-label>-->
  <!--    </ion-list-header>-->
  <!--    <ion-progress-bar value="0.59"></ion-progress-bar>-->
  <!--  </ion-list>-->
  <!--  <ion-list>-->
  <!--    <ion-list-header>-->
  <!--      <ion-label><span>Loading</span> Eggs</ion-label>-->
  <!--    </ion-list-header>-->
  <!--    <ion-progress-bar value="0.43"></ion-progress-bar>-->
  <!--  </ion-list>-->
</ion-content>

<wic-enhanced-mode-fab></wic-enhanced-mode-fab>
