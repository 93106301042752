import { NgModule } from '@angular/core';
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { LogService } from '~core/services/log.service';
import { DatabaseService } from './database.service';
import { IndexedDBService } from './indexedDB.service';
import { SQLiteService } from './sqlite.service';

// export const DB_SERVICE = new InjectionToken<DatabaseService>('ProductsDbService');

const dbServiceFactory = (platform: Platform, sqlite: SQLite, log: LogService) =>
  platform.is('cordova') ? new SQLiteService(sqlite, log) : new IndexedDBService(log);

@NgModule({
  providers: [
    {
      provide: DatabaseService,
      useFactory: dbServiceFactory,
      deps: [Platform, SQLite, LogService]
    },
    SQLite
  ]
})
export class DatabaseModule {
}
