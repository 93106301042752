import { Component } from '@angular/core';
import { ShoppingListsFacade } from '~features/shopping-lists/shopping-lists.facade';

@Component({
  selector: 'wic-shopping-lists',
  templateUrl: './shopping-lists.page.html',
  styleUrls: ['./shopping-lists.page.scss'],
})
export class ShoppingListsPage {
  constructor(public readonly shoppingLists: ShoppingListsFacade) {}
}
