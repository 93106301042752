import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EnrichedBenefit } from '~features/benefits/models';
import { ShoppingList } from './shopping-list.entity';
import {
  addItem,
  addShoppingList, changeOrderDate,
  openAddCustomItem,
  openSelectBenefitSubCategory,
  showSelectVendorForShoppingList, submitMobileOrder,
  viewShoppingList,
} from './shopping-lists.actions';
import {
  benefitsWithShoppingListItems,
  currentShoppingListVendor,
  filteredBenefitsWithShoppingListItems,
  hasItemsInShoppingList, isShoppingListMobileOrder,
  mobileOrderShoppingLists,
  quantityEditBenefitShoppingListGroup,
  standardShoppingLists,
} from './shopping-lists.selectors';
import { deleteShoppingListSuccess, ShoppingListsFacadeBase } from './shopping-lists.state';
import { ShoppingListItem } from '~features/shopping-lists/shopping-list-items/shopping-list-item.entity';
import { promptSubstitution, showSubstitutionInfo } from '~features/shopping-lists/substitutions/substitution.actions';
import { SubstitutionType } from '~features/substitution-configs/substitution-config.entity';
import { redemptionForCurrentBenefitSubCatForVendor, redemptionSubCategoriesForCurrentBenefit } from './shopping-list-redemptions.selectors';

@Injectable()
export class ShoppingListsFacade extends ShoppingListsFacadeBase {
  standardShoppingLists$ = this.store.select(standardShoppingLists);
  mobileOrderShoppingLists$ = this.store.select(mobileOrderShoppingLists);
  isShoppingListMobileOrder$ = this.store.select(isShoppingListMobileOrder);

  benefitsWithShoppingListItems$ = this.store.select(benefitsWithShoppingListItems);
  filteredBenefitsWithShoppingListItems$ = this.store.select(filteredBenefitsWithShoppingListItems);
  hasItemsInShoppingList$ = this.store.select(hasItemsInShoppingList);
  quantityEditBenefitShoppingListGroup$ = this.store.select(quantityEditBenefitShoppingListGroup);
  redemptionSubCategoriesForCurrentBenefit$ = this.store.select(
    redemptionSubCategoriesForCurrentBenefit
  );
  redemptionForCurrentBenefitSubCatForVendor$ = this.store.select(redemptionForCurrentBenefitSubCatForVendor);
  currentShoppingListVendor$ = this.store.select(currentShoppingListVendor);

  constructor(private store: Store) {
    super(ShoppingList, store);
  }

  viewShoppingList(shoppingList: ShoppingList) {
    this.store.dispatch(viewShoppingList({ shoppingList }));
  }

  showSelectVendor(shoppingList: ShoppingList) {
    this.store.dispatch(showSelectVendorForShoppingList({ shoppingList }));
  }

  addItem(benefit: EnrichedBenefit) {
    this.store.dispatch(addItem({ benefit }));
  }

  addCustomItem() {
    this.store.dispatch(openAddCustomItem());
  }

  openSelectBenefitSubCategory() {
    this.store.dispatch(openSelectBenefitSubCategory());
  }

  addShoppingList(name: string, isMobileOrder: boolean) {
    this.store.dispatch(addShoppingList({ name, isMobileOrder }));
  }

  removeShoppingList(shoppingList: ShoppingList) {
    this.store.dispatch(deleteShoppingListSuccess({ entity: shoppingList }));
  }

  submitMobileOrder() {
    this.store.dispatch(submitMobileOrder())
  }

  changeOrderDate() {
    this.store.dispatch(changeOrderDate())
  }

  addSubstitution(shoppingListItem: ShoppingListItem, benefit: EnrichedBenefit) {
    this.store.dispatch(promptSubstitution({ shoppingListItem, benefit }))
  }

  showSubstitutionInfo(substitutionType: SubstitutionType) {
    this.store.dispatch(showSubstitutionInfo({ substitutionType }))
  }
}
