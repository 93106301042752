import { Component, Input } from '@angular/core';
import { Appointment } from '~features/appointments/models';

@Component({
  selector: 'wic-appointment-header',
  templateUrl: './appointment-header.component.html',
  styleUrls: ['./appointment-header.component.scss'],
})
export class AppointmentHeaderComponent {
  @Input() appointment: Appointment;
}
