<ion-header mode="ios">
  <ion-toolbar color="vivid-orange-peel" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h3>{{'search-upc.contentTitle' | transloco}}</h3>
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon icon="fingerprint" size="2x" [styles]="{opacity: '0.075', color: 'var(--white)'}"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content fullscreen>
  <ng-container *ngIf="registration.currentProvider$ | async as authority">
    <ng-container *ngIf="authority.enableAplSearchNoBenefits">
      <ion-toolbar color="vivid-orange-peel">
        <ion-searchbar
          class="searchbar"
          data-cy="item-list-search-bar"
          placeholder="{{'item-list.searchPlaceholder' | transloco}}"
          [debounce]="700"
          color="light"
          [formControl]="textSearchForm"
          (ionChange)="products.setProductFilter($event.target.value)"
          (ionClear)="products.setProductFilter(null)"
          (ionCancel)="products.setProductFilter(null)"
        >
        </ion-searchbar>
      </ion-toolbar>

      <section *ngIf="products.isSelecting$ | async; else currentItems" data-cy="products-loading">
        <ion-list>
          <ion-item>
            <ion-label><h2>{{'item-list.selectingLabel' | transloco}}</h2></ion-label>
            <ion-spinner slot="end" name="dots"></ion-spinner>
          </ion-item>
        </ion-list>
      </section>

      <ng-template #currentItems>
        <wic-item-loading-error *ngIf="products.isSQLiteError$ | async"></wic-item-loading-error>

        <cdk-virtual-scroll-viewport itemSize="80" minBufferPx="800" maxBufferPx="1600">
          <ion-item *cdkVirtualFor="let product of products.currentProductsFiltered$ | async" (click)="products.checkProduct(product, true); products.setProductFilter(null)" data-cy="product-list-item">
            <wic-product-image slot="start" [product]="product"></wic-product-image>
            <ion-label>
              <h2>{{product.description}}</h2>
              <h3 class="addtl-info">
                <span class="text-muted">{{product.itemNumber}}</span>
                  <!-- <span class="allowed" [ngClass]="getAllowedStatus(benefit, product)">{{benefit.quantity | computeAllowed:product.size:product.packageSize}}-{{'product-list.allowedLabel' | transloco}}</span> -->
              </h3>
            </ion-label>
          </ion-item>
        </cdk-virtual-scroll-viewport>
        <wic-enhanced-mode-fab></wic-enhanced-mode-fab>
      </ng-template>
    
    </ng-container>  
    <div *ngIf="!authority.enableAplSearchNoBenefits">
      <ion-card>
        <img src="/assets/images/upc/manual_barcode.png">
      </ion-card>

      <wic-key-enter-upc [form]="form" (focused)="scrollIntoView($event)"></wic-key-enter-upc>

      <wic-recent-upcs [recentUPCs]="upc.recentUPCs$ | async" (selected)="upc.lookUpUPC($event)"></wic-recent-upcs>

    </div>
  </ng-container>
</ion-content>
<ion-footer mode="ios" *ngIf="registration.currentProvider$ | async as authority">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-button (click)="upc.scanBarcode()" class="btn-maroon" data-cy="scan-upc-button">
            <ion-icon name="barcode"></ion-icon>
            <span>{{'buttons.scanBarcode' | transloco}}</span>
          </ion-button>
        </ion-col>

        <ion-col *ngIf="!authority.enableAplSearchNoBenefits">
          <ion-button (click)="upc.lookUpUPC(formValue)" class="btn-green" [disabled]="!formValue" data-cy="upc-lookup-button">
            <ion-icon class="search-icon" name="search"></ion-icon>
            <span>{{'search-upc.searchButton' | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>

