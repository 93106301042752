import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShoppingListItem } from '../../../../features/shopping-lists/shopping-list-items/shopping-list-item.entity';
import { CheckboxChangeEventDetail } from '@ionic/core/dist/types/components/checkbox/checkbox-interface';

@Component({
  selector: 'wic-shopping-list-custom-items-card',
  templateUrl: './shopping-list-custom-items-card.component.html',
  styleUrls: ['./shopping-list-custom-items-card.component.scss'],
})
export class ShoppingListCustomItemsCardComponent {
  @Input() items: ShoppingListItem[];
  @Output() checkOff = new EventEmitter<ShoppingListItem>();
  @Output() remove = new EventEmitter<string>();
  @Output() addItem = new EventEmitter<string>();

  trackByShoppingListItem(index: number, item: ShoppingListItem) {
    return item.id;
  }

  checkBoxChanged(event: CustomEvent<CheckboxChangeEventDetail>, item: ShoppingListItem) {
    if (event.detail.checked !== item.checkedOff) {
      this.checkOff.emit(item);
    }
  }
}
