import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class MapNavigatorService {
  constructor() {}

  navigate(address: string | [lat: number, long: number]): void {
    if (!Capacitor.isNativePlatform() || Capacitor.getPlatform() === 'android') {
      const encoded = encodeURIComponent(
        typeof address === 'string' ? address : `${address[0]},${address[1]}`
      );

      const mapsLink = `https://www.google.com/maps/search/?api=1&query=${encoded}`;
      window.open(mapsLink, '_system');
    } else if (Capacitor.getPlatform() === 'ios') {
      const encoded =
        typeof address === 'string' ? encodeURIComponent(address) : `${address[0]},${address[1]}`;

      const mapsLink = `https://maps.apple.com/?q=${encoded}`;
      window.open(mapsLink, '_system');
    }
  }
}
