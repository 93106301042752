import { Pipe, PipeTransform } from '@angular/core';
import { ShoppingListBenefit } from '../../features/shopping-lists/models/shopping-list-benefit.model';
import { calculateShoppingListBenefitTotal } from './utils';

@Pipe({ name: 'isBenefitFilled' })
export class IsBenefitFilledPipe implements PipeTransform {
  transform(benefit: ShoppingListBenefit, overageOnly: boolean = false): boolean {
    return overageOnly
      ? calculateShoppingListBenefitTotal(benefit) > benefit.availableQuantity
      : calculateShoppingListBenefitTotal(benefit) >= benefit.availableQuantity;
  }
}
