import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SubCatConfig } from './models';
import { Benefit } from '../benefits/models';

import { State } from '../state';
import { SubCatConfigFacadeBase } from './subcat-configs.state';

@Injectable()
export class SubCatConfigFacade extends SubCatConfigFacadeBase {
  constructor(store: Store<State>) {
    super(SubCatConfig, store);
  }

  findForAny(item: { categoryId: number; subCategoryId: number }): Observable<SubCatConfig> {
    return this.entities$.pipe(
      map(configs =>
        configs[`${item.categoryId}_${item.subCategoryId}`] || configs[`${item.categoryId}_0`]
      )
    );
  }

  findForBenefit(benefit: Benefit): Observable<SubCatConfig> {
    return this.findForAny(benefit);
  }
}
