import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Card } from '~features/registration/models';

@Component({
  selector: 'wic-card-select-toolbar',
  templateUrl: './card-select-toolbar.component.html',
  styleUrls: ['./card-select-toolbar.component.scss'],
})
export class CardSelectToolbarComponent {
  @Input() color?: string;
  @Input() cards: Card[];
  @Input() currentCard: Card;
  @Output() selected = new EventEmitter<Card>();

  selectCard(event) {
    this.selected.emit(this.cards.find(card => card.cardNumber === event.detail.value));
  }
}
