export type MonadFunction<T, U> = (value: T) => U;

export function compose<T, U>(fn: MonadFunction<T, U>): MonadFunction<T, U>;
export function compose<T, T1, U>(
  fn: MonadFunction<T, T1>,
  fn1: MonadFunction<T1, U>
): MonadFunction<T, U>;
export function compose<T, T1, T2, U>(
  fn: MonadFunction<T, T1>,
  fn1: MonadFunction<T1, T2>,
  fn2: MonadFunction<T2, U>
): MonadFunction<T, U>;
export function compose<T, T1, T2, T3, U>(
  fn: MonadFunction<T, T1>,
  fn1: MonadFunction<T1, T2>,
  fn2: MonadFunction<T2, T3>,
  fn3: MonadFunction<T3, U>
): MonadFunction<T, U>;
export function compose<T, T1, T2, T3, T4, U>(
  fn: MonadFunction<T, T1>,
  fn1: MonadFunction<T1, T2>,
  fn2: MonadFunction<T2, T3>,
  fn3: MonadFunction<T3, T4>,
  fn4: MonadFunction<T4, U>
): MonadFunction<T, U>;
export function compose<T, T1, T2, T3, T4, T5, U>(
  fn: MonadFunction<T, T1>,
  fn1: MonadFunction<T1, T2>,
  fn2: MonadFunction<T2, T3>,
  fn3: MonadFunction<T3, T4>,
  fn4: MonadFunction<T4, T5>,
  fn5: MonadFunction<T5, U>
): MonadFunction<T, U>;
export function compose<T, T1, T2, T3, T4, T5, T6, U>(
  fn: MonadFunction<T, T1>,
  fn1: MonadFunction<T1, T2>,
  fn2: MonadFunction<T2, T3>,
  fn3: MonadFunction<T3, T4>,
  fn4: MonadFunction<T4, T5>,
  fn5: MonadFunction<T5, T6>,
  fn6: MonadFunction<T6, U>
): MonadFunction<T, U>;

export function compose<T, U>(
  ...fns: [...MonadFunction<any, any>[], MonadFunction<any, U>]
): MonadFunction<T, U> {
  return data => fns.reduce((v, fn) => fn(v), data);
}
