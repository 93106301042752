import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { createSubstitutionSuccess } from '~features/shopping-lists/substitutions/substitutions.state';
import { uuid } from '../../../util/func-util';
import {
  currentShoppingListItemKey,
  selectShoppingListItem,
} from '../shopping-list-items/shopping-list-items.state';
import { ShoppingListsUIService } from '../shopping-lists-ui.service';
import {
  addSubstitutionItem,
  promptSubstitution,
  showSubstitutionInfo,
} from './substitution.actions';
import { MobileOrderItemSubstitution } from './substitution.entity';

@Injectable()
export class SubstitutionsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly shoppingListsUI: ShoppingListsUIService
  ) {}

  // checkIfSubstitutionAllowed$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(addItemToShoppingList),
  //     withLatestFrom(this.store.select(currentShoppingList), this.store.select(allSubstitutionConfigs)),
  //     filter(([, shoppingList]) => shoppingList.isMobileOrder),
  //     filter(([{ benefit }, shoppingList, substitutionConfigs]) =>
  //       (substitutionConfigs.find(config => config.category === benefit.categoryId)?.substitutionType ?? SubstitutionType.Allowed) === SubstitutionType.Allowed
  //     ),
  //     map(([{ product, benefit, purchaseQuantity, units }]) =>
  //       promptSubstitution({ product, benefit, purchaseQuantity, units, index: 0 })
  //     )
  //   )
  // );

  // TODO: See if auto re-prompt is needed, or if it should be a manual process
  // rePromptSubstitution$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(addSubstitutionItem),
  //     filter(({ index }) => index === 0 ),
  //     map(({ product, index }) => promptSubstitution({ product, index: index + 1 }))
  //   )
  // );

  showSubstitutionInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSubstitutionInfo),
        switchMap(({ substitutionType }) =>
          from(this.shoppingListsUI.openSubstitutionInfoModal(substitutionType))
        )
      ),
    { dispatch: false }
  );

  selectShoppingListItemForSubstitution$ = createEffect(() =>
    this.actions$.pipe(
      ofType(promptSubstitution),
      map(({ shoppingListItem }) => selectShoppingListItem({ entity: shoppingListItem }))
    )
  );

  openAddSubstitutionItemModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(promptSubstitution),
      switchMap(({ benefit }) =>
        from(this.shoppingListsUI.openAddItemModal(benefit)).pipe(
          filter(({ role }) => role === 'dismiss'),
          map(({ data }) => addSubstitutionItem({ product: data }))
        )
      )
    )
  );

  addSubstitutionItemToShoppingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSubstitutionItem),
      withLatestFrom(this.store.select(currentShoppingListItemKey)),
      map(
        ([{ product }, shoppingListItemId]): MobileOrderItemSubstitution => ({
          ...product,
          id: uuid(),
          mobileOrderItemId: shoppingListItemId + '',
          itemId: product.itemId + '',
          productId:
            product.id || `${product.categoryId}_${product.subCategoryId}_${product.itemNumber}`,
          purchaseQuantity: 1,
          units: product.size / product.packageSize,
        })
      ),
      map(entity => createSubstitutionSuccess({ entity }))
    )
  );
}
