<ion-list-header>{{'log-viewer.levelFilterHeader' | transloco}}</ion-list-header>
<p class="filteredTags">
  <ion-chip
      *ngFor="let level of levels"
      (click)="toggleLevel(level)"
      [color]="level | ifIncluded:filteredLevels | levelToColor">
    <ion-label class="ion-text-capitalize">
      <em *ngIf="!(level | ifIncluded:filteredLevels)">{{'log-viewer.level' | transloco:{level: level} }}</em>
      <strong *ngIf="level | ifIncluded:filteredLevels">{{'log-viewer.level' | transloco:{level: level} }}</strong>
    </ion-label>
  </ion-chip>
</p>

<ion-list-header *ngIf="filteredTags && filteredTags.length">{{'log-viewer.tagFilterHeader' | transloco}}</ion-list-header>
<p class="filteredTags">
  <ion-chip
      *ngFor="let tag of filteredTags"
      outline color="medium">
    <ion-label>{{tag}}</ion-label>
    <ion-icon name="close-circle" (click)="removeTag(tag)"></ion-icon>
  </ion-chip>
</p>

<ion-list>
  <ion-item *ngFor="let entry of log.localLog$ | async | filterByTags:filteredTags | filterByLevel:filteredLevels" data-cy="log-entry">
    <ion-badge slot="start" [color]="entry.level | levelToColor">{{entry.level | levelToName}}</ion-badge>
    <ion-label>
      <h2>{{entry.message}}</h2>
      <p>
        <ion-badge color="light"><ion-text color="medium">{{entry.timestamp | date:'hh:mm'}}</ion-text></ion-badge>&nbsp;
        <span *ngFor="let tag of entry.tags"><ion-badge color="light" (click)="addTag(tag)">{{tag}}</ion-badge>&nbsp;</span>
      </p>
    </ion-label>
    <ion-icon slot="end" name="eye" (click)="viewLogEntry(entry)" data-cy="view-log-button"></ion-icon>
  </ion-item>
</ion-list>
