import { CvvCalculatorCommandsComponent } from './cvv-calculator-commands/cvv-calculator-commands.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBarcode, faFingerprint, faHandHoldingUsd, faList, faTrash } from '@fortawesome/free-solid-svg-icons';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { ComponentsModule } from '../../components/components.module';

import { CalculatorCVVPage } from './calculator-cvv.page';

const routes: Routes = [
  {
    path: '',
    component: CalculatorCVVPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslocoModule,
    FontAwesomeModule,
    ComponentsModule
  ],
  declarations: [CalculatorCVVPage, CvvCalculatorCommandsComponent]
})
export class CalculatorCVVPageModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(faFingerprint, faTrash, faList, faBarcode, faHandHoldingUsd);
  }
}
