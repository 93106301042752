import { Pipe, PipeTransform } from '@angular/core';
import { ShoppingListBenefit } from '../../features/shopping-lists/models/shopping-list-benefit.model';
import { calculateShoppingListBenefitTotal } from './utils';

@Pipe({ name: 'benefitShoppingListTotal' })
export class BenefitShoppingListTotalPipe implements PipeTransform {
  transform(benefit: ShoppingListBenefit): number {
    return calculateShoppingListBenefitTotal(benefit);
  }
}
