<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modal.dismiss()">
        <ion-icon name="close-circle"></ion-icon>
        {{'get-support-modal.dismissButton' | transloco}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <div class="warning-bg">
    <img src="/assets/warning.svg" [alt]="'error-modal.watermarkAlt' | transloco">
  </div>
  <div class="error-header">
    <h2 class="ion-text-center">{{'get-support-modal.subtitle' | transloco}}</h2>
  </div>
  <div class="error-detail">
    <div class="error-detail-content">
      <h3>
        <ion-icon name="bug"></ion-icon>
        {{'get-support-modal.title' | transloco}}
      </h3>
      <h4>{{'get-support-modal.infoMessage' | transloco}}</h4>
    </div>
  </div>
  <form [formGroup]="form">
    <ion-list>
      <ion-item>
        <ion-input
            type="text" placeholder="{{'get-support-modal.namePlaceholder' | transloco}}"
            formControlName="name" [autofocus]="true"></ion-input>
      </ion-item>
      <ion-item>
        <ion-input
            type="text" placeholder="{{'get-support-modal.emailPlaceholder' | transloco}}"
            formControlName="email"></ion-input>
      </ion-item>
      <ion-item>
        <ion-textarea
            [rows]="5"
            type="text" placeholder="{{'get-support-modal.messagePlaceholder' | transloco}}"
            formControlName="message"></ion-textarea>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col size="6">
        <ion-button size="large" strong="true" class="w-100" (click)="modal.dismiss()">
          <ion-icon name="close-circle" class="pr-5"></ion-icon>
          {{'get-support-modal.cancelButton' | transloco}}
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button size="large" strong="true" class="w-100" (click)="submit()">
          <ion-icon name="cloud-upload" class="pr-5"></ion-icon>
          {{'get-support-modal.submitButton' | transloco}}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
