import { createSelector } from '@ngrx/store';
import { currentBenefits } from '~features/benefits/benefits.selectors';
import { redemptionsWithConfigs } from '~features/redemptions/redemptions.selectors';
import { currentProviderId } from '~features/registration/registration.selectors';
import { subCatConfigEntities } from '~features/subcat-configs/subcat-configs.state';
import { SubstitutionType } from '~features/substitution-configs/substitution-config.entity';
import { substitutionConfigEntities } from '~features/substitution-configs/substitution-configs.state';
import { vendorInventoryItemEntities } from '~features/vendor-inventory-items/vendor-inventory-items.state';
import { vendorEntities } from '~features/vendors/vendors.state';
import { ShoppingListBenefit } from './models/shopping-list-benefit.model';
import {
  itemsForCurrentShoppingList,
  quantityEditId,
} from './shopping-list-items/shopping-list-items.selectors';
import { allShoppingLists, currentShoppingList, shoppingListsState } from './shopping-lists.state';
import { productsInVendorsInventory } from '~features/vendor-inventory-items/vendor-inventory-items.selectors';

export const currentShoppingListBenefit = createSelector(
  shoppingListsState,
  state => state.currentBenefit
);

export const standardShoppingLists = createSelector(allShoppingLists, lists =>
  lists.filter(list => !list.isMobileOrder)
);

export const mobileOrderShoppingLists = createSelector(allShoppingLists, lists =>
  lists.filter(list => list.isMobileOrder)
);

export const currentShoppingListVendor = createSelector(
  currentShoppingList,
  vendorEntities,
  (shoppingList, vendors) => vendors?.[shoppingList.vendorId]
);

export const isShoppingListMobileOrder = createSelector(
  currentShoppingList,
  shoppingList => shoppingList.isMobileOrder
);



export const benefitsWithConfigs = createSelector(
  currentBenefits,
  subCatConfigEntities,
  substitutionConfigEntities,
  currentProviderId,
  (benefitInfo, subCatConfigs, substitutionConfigs, authorityId) =>
    benefitInfo?.benefits.map(
      (benefit): Omit<ShoppingListBenefit, 'shoppingListItems'> => ({
        ...benefit,
        config: subCatConfigs[`${benefit.categoryId}_${benefit.subCategoryId}`],
        substitutionConfig: substitutionConfigs[`${authorityId}_${benefit.categoryId}`] || {
          authorityId: +authorityId,
          category: benefit.categoryId,
          substitutionType: SubstitutionType.Allowed,
        },
      })
    ) || []
);

export const benefitsWithShoppingListItems = createSelector(
  benefitsWithConfigs,
  itemsForCurrentShoppingList,
  (benefits, shoppingListItems) =>
    benefits.map(
      (benefit): ShoppingListBenefit => ({
        ...benefit,
        shoppingListItems: shoppingListItems.filter(
          item =>
            item.categoryId === benefit.categoryId && item.subCategoryId === benefit.subCategoryId
        ),
      })
    )
);

export const filteredBenefitsWithShoppingListItems = createSelector(
  benefitsWithShoppingListItems,
  benefits => benefits.filter(benefit => benefit.shoppingListItems.length > 0)
);

export const hasItemsInShoppingList = createSelector(
  filteredBenefitsWithShoppingListItems,
  benefits => !!benefits?.length
);

export const quantityEditBenefitShoppingListGroup = createSelector(
  filteredBenefitsWithShoppingListItems,
  quantityEditId,
  (benefits, id) =>
    id && benefits.find(benefit => benefit.shoppingListItems.some(item => item.id === id))
);
