<ion-header mode="ios">
  <ion-toolbar color="green" mode="ios">
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      <h4>{{'calculator-uom.pageTitle' | transloco}}</h4>
    </ion-title>
    <ion-buttons slot="end" aria-hidden="true">
      <ion-button fill="clear" (click)="enhancedMode.secretButton()">
        <fa-icon icon="fingerprint" size="2x" [styles]="{opacity: '0.025', color: 'var(--white)'}"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar *ngIf="category$ | async as category" color="green">
    <ion-title>
      <h4>{{category.description | transloco}}</h4>
    </ion-title>
  </ion-toolbar>
  <ion-toolbar color="green">
    <ion-grid>
      <ion-row>
        <ion-col>
          <h1 class="m-0 p-0 text-nowrap text-monospaced text-white ion-text-center" data-cy="balance">
            <span class="currentBalance">{{calculators.currentBalance$(uomCategory) | async | number:'1.0-1'}}{{uom$ | async | lowercase}}</span> -
            <span class="totalCost">{{calculators.selectedTotalQuantity$(uomCategory) | async | number:'1.0-1'}}{{uom$ | async | lowercase}}</span> =
            <span class="remainingBalance" [class.empty]="calculators.remainingQuantityEmpty$(uomCategory) | async">
              {{calculators.remainingQuantity$(uomCategory) | async | number:'1.0-1'}}{{uom$ | async | lowercase}}
            </span>
          </h1>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="text-white text-small ion-text-center">
          {{'calculator-uom.balanceLabel' | transloco}}
        </ion-col>
        <ion-col class="text-white text-small ion-text-center">
          {{'calculator-uom.usageLabel' | transloco}}
        </ion-col>
        <ion-col class="text-white text-small ion-text-center">
          {{'calculator-uom.remainingLabel' | transloco}}
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>

<ng-template #uomLb>{{uom$ | async | lowercase}}</ng-template>

<ion-content fullscreen>
  <div class="mb-10"></div>
  <wic-calculator-entry-list
    #entryList
    *ngIf="calculators.hasEntries$(uomCategory) | async"
    [entries]="calculators.entries$(uomCategory) | async"
    [remaining]="calculators.remainingQuantity$(uomCategory) | async"
    (toggled)="calculators.toggle($event)"
    (incremented)="calculators.increment($event)"
    (decremented)="calculators.decrement($event)"
    (edited)="calculators.edit($event)"
    (removed)="calculators.remove($event)"
    [uom]="uomLb">
  </wic-calculator-entry-list>
  <wic-uom-calculator-commands 
    [authority]="registration.currentProvider$ | async"
    [balanceEmpty]="calculators.remainingQuantityEmpty$(uomCategory) | async"
    (added)="calculators.createAdHocEntry(uomCategory)"
    (balanceSet)="calculators.setBenefitBalance(uomCategory)">
  </wic-uom-calculator-commands>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col class="ion-text-right">
          <span (click)="entryList.deleteMode = !entryList.deleteMode"
                *ngIf="calculators.hasEntries$(uomCategory) | async" data-cy="secondary-delete-toggle">
            <fa-icon icon="trash" [styles]="{ color: 'var(--med-grey)'}"></fa-icon>
            <span class="ml-5 text-muted">{{'calculators.deleteIcon' | transloco}}</span>
          </span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button
              expand="block" color="lava" data-cy="clear-button"
              [disabled]="calculators.noEntries$(uomCategory) | async"
              (click)="calculators.clearCategoryEntries(uomCategory)">
            <fa-icon icon="trash"></fa-icon>
            <span class="pl-5">{{ 'calculators.clearButton' | transloco}}</span>
          </ion-button>
        </ion-col>
        <ion-col   *ngIf="registration.isProvider$ | async">
          <ion-button expand="block" color="tufts-blue" (click)="benefits.viewAll()" data-cy="benefits-button">
            <fa-icon icon="list"></fa-icon>
            <span class="pl-5">{{ 'calculators.benefitsButton' | transloco}}</span>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button expand="block" color="dark-red" (click)="upc.scanBarcode()" data-cy="search-upc-button">
            <fa-icon icon="barcode"></fa-icon>
            <span class="pl-5">{{ 'calculators.scanButton' | transloco}}</span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
