import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertTimePipe } from './alert-time.pipe';
import { FilterByTagsPipe } from './filter-by-tags.pipe';
import { HostSettingsModeFilterPipe } from './host-settings-mode-filter.pipe';
import { LevelToColorPipe } from './level-to-color.pipe';
import { LevelToNamePipe } from './level-to-name.pipe';
import { LocationToLatLngPipe } from './location-to-lat-lng.pipe';
import { MaskPipe } from './mask.pipe';
import { FormatQuantityPipe } from './format-quantity.pipe';
import { FormatImageUrlPipe } from './format-image-url.pipe';
import { ComputeAllowedPipe } from './compute-allowed.pipe';
import { FilterByLevelsPipe } from './filter-by-levels-pipe.pipe';
import { IfIncludedPipe } from './if-included.pipe';
import { DistanceGradePipe } from './distance-grade.pipe';
import { FilterPipe } from './filter.pipe';
import { PrettyDistancePipe } from './pretty-distance.pipe';



@NgModule({
  declarations: [
    AlertTimePipe,
    ComputeAllowedPipe,
    DistanceGradePipe,
    FilterByLevelsPipe,
    FilterByTagsPipe,
    FilterPipe,
    FormatImageUrlPipe,
    FormatQuantityPipe,
    FormatQuantityPipe,
    HostSettingsModeFilterPipe,
    IfIncludedPipe,
    LevelToColorPipe,
    LevelToNamePipe,
    LocationToLatLngPipe,
    MaskPipe,
    PrettyDistancePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AlertTimePipe,
    ComputeAllowedPipe,
    DistanceGradePipe,
    FilterByLevelsPipe,
    FilterByTagsPipe,
    FilterPipe,
    FormatImageUrlPipe,
    FormatQuantityPipe,
    FormatQuantityPipe,
    HostSettingsModeFilterPipe,
    IfIncludedPipe,
    LevelToColorPipe,
    LevelToNamePipe,
    LocationToLatLngPipe,
    MaskPipe,
    PrettyDistancePipe,
  ]
})
export class PipesModule { }
