import { Injectable } from '@angular/core';
import { AppLauncher } from '@capacitor/app-launcher';

@Injectable()
export class AppLauncherService {
  constructor() {}

  async canLaunch(url: string) {
    const { value } = await AppLauncher.canOpenUrl({ url  });
    return value;
  }

  async launch(url: string) {
    const { completed } = await AppLauncher.openUrl({ url  });
    return completed;
  }
}
